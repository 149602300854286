import React from "react";
import { withRouter } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Cancel from "@mui/icons-material/Cancel";
import Delete from "@mui/icons-material/Delete";
import Edit from "@mui/icons-material/Edit";
import FileCopyOutlined from "@mui/icons-material/FileCopyOutlined";
import MoreVert from "@mui/icons-material/MoreVert";

import { employerJobActionMenuStyles } from "views/Employer/helpers";
import Can from "components/Authorization/Can";
import { JobPermissions } from "helpers/constants/permissions";

class EmployerJobActionMenu extends React.Component {
    static defaultProps = {
        canEnable: false,
        canDelete: false,
        canCancel: false,
    };

    state = {
        anchorEl: null,
    };

    handleClick = event => {
        this.setState({ anchorEl: event.currentTarget });
    };

    handleClose = () => {
        this.setState({ anchorEl: null });
    };

    handleDuplicate = () => {
        const { history, jobId } = this.props;
        this.handleClose();
        history.push(`/jobs/post?jobId=${jobId}&type=duplicate`);
    };

    handleEdit = () => {
        const { history, jobId } = this.props;
        this.handleClose();
        history.push(`/jobs/post?jobId=${jobId}&type=edit`);
    };

    handleDelete = () => {
        const { jobId } = this.props;
        this.props.onDelete(jobId);
        this.handleClose();
    };

    handleCancel = () => {
        const { jobId } = this.props;
        this.props.onCancel(jobId);
        this.handleClose();
    };

    handleAdjustFinance = () => {
        this.handleClose();
    };

    render() {
        const { anchorEl } = this.state;
        const { classes } = this.props;

        const id = `action-menu-${this.props.jobId}`;

        return (
            <React.Fragment>
                <IconButton
                    aria-owns={anchorEl ? id : undefined}
                    aria-haspopup="true"
                    disableRipple
                    onClick={this.handleClick}
                    size="large">
                    <MoreVert color="primary" />
                </IconButton>
                {anchorEl ? (
                    <Menu
                        id={id}
                        anchorEl={anchorEl}
                        open={Boolean(anchorEl)}
                        onClose={this.handleClose}
                        disableAutoFocusItem>
                        <Can perform={JobPermissions.create.duplicate.value}>
                            {allowed => {
                                if (!allowed) {
                                    return null;
                                }
                                return <MenuItem onClick={this.handleDuplicate}>
                                    <FileCopyOutlined className={classes.icon} color="action" />
                                    Duplicate Job
                                </MenuItem>
                            }}
                        </Can>
                        <Can perform={JobPermissions.update.detail.value}>
                            {allowed => {
                                if (!allowed) {
                                    return null;
                                }
                                return (this.props.canEdit && (
                                    <MenuItem onClick={this.handleEdit}>
                                        <Edit className={classes.icon} color="action" />
                                        Edit Job
                                    </MenuItem>
                                ))
                            }}
                        </Can>
                        {this.props.canDelete && (
                            <MenuItem onClick={this.handleDelete}>
                                <Delete className={classes.icon} color="action" />
                                Delete Job
                            </MenuItem>
                        )}
                        <Can perform={JobPermissions.update.cancel.value}>
                            {allowed => {
                                if (!allowed) {
                                    return null;
                                }
                                return (this.props.canCancel && (
                                    <MenuItem onClick={this.handleCancel}>
                                        <Cancel className={classes.icon} color="action" />
                                        Cancel Job
                                    </MenuItem>
                                )
                                )
                            }}
                        </Can>
                        {/* {this.props.canAdjustFinance && (
                            <MenuItem onClick={this.handleAdjustFinance}>
                                <LocalAtm className={classes.icon} color="action" />
                                Make Adjustment
                            </MenuItem>
                        )} */}
                    </Menu>
                ) : null}
            </React.Fragment>
        );
    }
}

export default employerJobActionMenuStyles(withRouter(EmployerJobActionMenu));
