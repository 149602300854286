/* eslint-disable react/prop-types */
import React from "react";
import { connect } from "react-redux";
import SwipeableViews from "react-swipeable-views";
import CircularProgress from "@mui/material/CircularProgress";
import withStyles from '@mui/styles/withStyles';
import { Add, CloudUpload, DateRange, Event, History, Lock, PendingActions, Schedule, VisibilityOff } from "@mui/icons-material";
import { DashboardViewsPermissions, JobPermissions } from "helpers/constants/permissions";
import Can from "components/Authorization/Can";
import Button from "components/Button/Button";
import MainContent from "components/Containers/MainContent";
import SubNav from "components/Navigation/SubNav";
import SubNavTab from "components/Navigation/SubNavTab";
import { hasPermission } from "utils/authorize";
import { actions as JobActions, selectors as JobSelectors } from "store/modules/jobs";
import DraftsTable from "./components/tables/DraftsTable";
import HistoryTable from "./components/tables/HistoryTable";
import TodayTab from "./components/TodayTab";
import TomorrowTab from "./components/TomorrowTab";
import UpcomingTab from "./components/UpcomingTab";
import PrivateJob from "./components/tables/PrivateJob";
import UploadPrivateJobCSV from "./components/UploadPrivateJobCSV";
import QueueTable from "./components/tables/QueueTable";

const styles = {
  progress: {
    color: "#11b2a8",
    position: "absolute",
    top: "50%",
    left: "45%",
  },
  createButton: {
    display: 'flex',
    justifyContent: 'flex-end'
  }
};

class JobOverview extends React.Component {
  state = {
    value: 0,
    isLoading: true,
    numLoaded: 0,
    open: false
  };

  timeframes = ["Today", "Tomorrow", "Upcoming"];

  componentDidMount() {
    if (this.props.history.location.search) {
      const params = new URLSearchParams(this.props.history.location.search);
      const timeframe = parseInt(params.get("timeframe"), 10);
      if (!isNaN(timeframe)) {
        this.setState({ value: parseInt(timeframe, 10) });
      }
    } else {
      this.props.history.push(`/jobs/overview?timeframe=${this.state.value}`);
    }

    Promise.all([
      this.props.fetchTimeframeStats().then(this.increaseProgress),
      // this.props.fetchListStats(this.timeframes[0]).then(this.increaseProgress),
      // this.props.fetchTimeframeStats().then(this.increaseProgress),
      // ...this.timeframes.map(timeframe => {
      //   return this.props.fetchListStats(timeframe).then(this.increaseProgress);
      // }),
      // this.props.fetchTodayTotalJobs(this.props.todayTotalPageInfo.currentPage).then(this.increaseProgress),
      // this.props.fetchTodayActiveJobs(this.props.todayActivePageInfo.currentPage).then(this.increaseProgress),
      // this.props.fetchTodayUnfilledJobs(this.props.todayUnfilledPageInfo.currentPage).then(this.increaseProgress),
      // this.props.fetchTodayFilledJobs(this.props.todayFilledPageInfo.currentPage).then(this.increaseProgress),
      // this.props.fetchTodayCompletedJobs(this.props.todayCompletedPageInfo.currentPage).then(this.increaseProgress),
      // this.props.fetchTomorrowUnfilledJobs(this.props.tomorrowUnfilledPageInfo.currentPage).then(this.increaseProgress),
      // this.props.fetchTomorrowFilledJobs(this.props.tomorrowFilledPageInfo.currentPage).then(this.increaseProgress),
      // this.props.fetchTomorrowTotalJobs(this.props.tomorrowTotalPageInfo.currentPage).then(this.increaseProgress),
      // this.props.fetchUpcomingUnfilledJobs(this.props.upcomingUnfilledPageInfo.currentPage).then(this.increaseProgress),
      // this.props.fetchUpcomingFilledJobs(this.props.upcomingFilledPageInfo.currentPage).then(this.increaseProgress),
      // this.props.fetchUpcomingTotalJobs(this.props.upcomingTotalPageInfo.currentPage).then(this.increaseProgress),
      // this.props.fetchHistoryJobs(this.props.historyPageInfo.currentPage).then(this.increaseProgress),
      // this.props.fetchDraftsJobs(this.props.draftPageInfo.currentPage).then(this.increaseProgress),
    ]).then(this.toggleIsLoading);
  }

  toggleIsLoading = () => {
    this.setState(state => ({
      isLoading: !state.isLoading,
    }));
  };

  increaseProgress = () => {
    this.setState(state => ({
      numLoaded: state.numLoaded + 1,
    }));
  };

  createJob = () => {
    this.props.history.push("/jobs/post");
  };

  uploadCSV = () => {
    this.props.openUploadPrivateJobCSVDialog(true)
  };

  closeDialog = () => {
    this.props.openUploadPrivateJobCSVDialog(false)
  };

  handleChange = (event, value) => {
    // this.props.fetchTimeframeStats();
    if (value < 3) {
      this.props.fetchListStats(this.timeframes[value]);
    } else if (value === 3) {
      this.props.fetchHistoryJobs();
    } else if (value === 4) {
      this.props.fetchQueuedJobs();
    }
     else if (value === 5) {
      this.props.fetchDraftsJobs();
    }

    if (this.props.history.location.search) {
      const params = new URLSearchParams(this.props.history.location.search);
      params.append("timeframe", value);
      this.props.history.push(`/jobs/overview?${params.toString()}`);
    }
    this.props.history.push(`/jobs/overview?timeframe=${value}`);
    this.setState({ value });
  };

  get tabs() {
    const tabs = [];

    if (hasPermission(DashboardViewsPermissions.job.today.value)) {
      tabs.push(<SubNavTab label="Today" icon={<Schedule />} count={this.props.today} />);
    }

    if (hasPermission(DashboardViewsPermissions.job.tomorrow.value)) {
      tabs.push(
        <SubNavTab key="tomorrow" label="Tomorrow" icon={<Event />} count={this.props.tomorrow} />
      );
    }

    if (hasPermission(DashboardViewsPermissions.job.upcoming.value)) {
      tabs.push(
        <SubNavTab
          key="upcoming"
          label="Upcoming"
          icon={<DateRange />}
          count={this.props.upcoming}
        />
      );
    }

    if (hasPermission(DashboardViewsPermissions.job.history.value)) {
      tabs.push(<SubNavTab key="history" label="History" icon={<History />} />);
    }

    //Queued job
    if (hasPermission(DashboardViewsPermissions.job.draft.value)) {
      tabs.push(<SubNavTab key="queued" label="Queue" icon={<PendingActions />} />);
    }

    if (hasPermission(DashboardViewsPermissions.job.draft.value)) {
      tabs.push(<SubNavTab key="drafts" label="Drafts" icon={<VisibilityOff />} />);
    }

    if (hasPermission(DashboardViewsPermissions.job.draft.value)) {
      tabs.push(<SubNavTab key="private-job" label="Private Job" icon={<Lock />} />);
    }

    return tabs;
  }

  render() {
    const { classes } = this.props;

    if (this.state.isLoading) {
      const progress = (this.state.numLoaded / 14) * 100;
      return (
        <CircularProgress
          classes={{ root: classes.progress }}
          variant="determinate"
          value={progress}
        />
      );
    }

    return (
      <React.Fragment>
        <SubNav value={this.state.value} onChange={this.handleChange} tabs={this.tabs} />
        <MainContent>
          <div className={classes.createButton}>
            {this.state.value === 6 && (
              <Can perform={JobPermissions.create.new.value}>
                {allowed => {
                  if (!allowed) {
                    return null;
                  }

                  return (
                    <Button
                      label="Upload CSV"
                      icon={<CloudUpload />}
                      alignIcon="left"
                      onClick={this.uploadCSV}
                    />
                  );
                }}
              </Can>
            )}
            <Can perform={JobPermissions.create.new.value}>
              {allowed => {
                if (!allowed) {
                  return null;
                }
                return (
                  <Button
                    label="Create a Job"
                    icon={<Add />}
                    alignIcon="left"
                    onClick={this.createJob}
                  />
                );
              }}
            </Can>
          </div>
          <SwipeableViews axis="x" index={this.state.value}>
            <Can perform={DashboardViewsPermissions.job.today.value}>
              {allowed => (allowed && this.state.value === 0 ? <TodayTab history={this.props.history} /> : null)}
            </Can>
            <Can perform={DashboardViewsPermissions.job.tomorrow.value}>
              {allowed => (allowed && this.state.value === 1 ? <TomorrowTab history={this.props.history} /> : null)}
            </Can>
            <Can perform={DashboardViewsPermissions.job.upcoming.value}>
              {allowed => (allowed && this.state.value === 2 ? <UpcomingTab history={this.props.history} /> : null)}
            </Can>
            <Can perform={DashboardViewsPermissions.job.history.value}>
              {allowed => (allowed && this.state.value === 3 ? <HistoryTable history={this.props.history} /> : null)}
            </Can>


            {/* Queue job */}
            <Can perform={DashboardViewsPermissions.job.draft.value}>
              {allowed => (allowed && this.state.value === 4 ? <QueueTable history={this.props.history} /> : null)}
            </Can>


            <Can perform={DashboardViewsPermissions.job.draft.value}>
              {allowed => (allowed && this.state.value === 5 ? <DraftsTable history={this.props.history} /> : null)}
            </Can>
            <Can perform={DashboardViewsPermissions.job.draft.value}>
              {allowed => (allowed && this.state.value === 6 ? <PrivateJob history={this.props.history} /> : null)}
            </Can>
          </SwipeableViews>
        </MainContent>
        <UploadPrivateJobCSV
          open={this.props.isOpenUploadCSVDialog}
          onCancel={this.closeDialog}
          onClose={this.closeDialog}
        />
      </React.Fragment>
    );
  }
}

const mapState = state => {
  return {
    historyPageInfo: JobSelectors.getHistoryPaginationData(state),
    isOpenUploadCSVDialog: JobSelectors.isOpenUploadPrivateJobCSVDialog(state),
    draftPageInfo: JobSelectors.getDraftsPaginationData(state),
    todayTotalPageInfo: JobSelectors.getTodayTotalPaginationData(state),
    todayActivePageInfo: JobSelectors.getTodayActivePaginationData(state),
    todayFilledPageInfo: JobSelectors.getTodayFilledPaginationData(state),
    todayUnfilledPageInfo: JobSelectors.getTodayUnfilledPaginationData(state),
    todayCompletedPageInfo: JobSelectors.getTodayCompletedPaginationData(state),
    tomorrowFilledPageInfo: JobSelectors.getTomorrowFilledPaginationData(state),
    tomorrowTotalPageInfo: JobSelectors.getTomorrowTotalPaginationData(state),
    tomorrowUnfilledPageInfo: JobSelectors.getTomorrowUnfilledPaginationData(state),
    upcomingTotalPageInfo: JobSelectors.getUpcomingTotalPaginationData(state),
    upcomingFilledPageInfo: JobSelectors.getUpcomingFilledPaginationData(state),
    upcomingUnfilledPageInfo: JobSelectors.getUpcomingUnfilledPaginationData(state),
    ...JobSelectors.getTimeframeStats(state),
  };
};

const mapActions = {
  fetchTimeframeStats: JobActions.fetchTimeframeStats,
  fetchListStats: JobActions.fetchListStats,
  fetchTodayTotalJobs: JobActions.fetchTodayTotalJobs,
  fetchTodayActiveJobs: JobActions.fetchTodayActiveJobs,
  fetchTodayUnfilledJobs: JobActions.fetchTodayUnfilledJobs,
  fetchTodayFilledJobs: JobActions.fetchTodayFilledJobs,
  fetchTodayCompletedJobs: JobActions.fetchTodayCompletedJobs,
  fetchTomorrowUnfilledJobs: JobActions.fetchTomorrowUnfilledJobs,
  fetchTomorrowFilledJobs: JobActions.fetchTomorrowFilledJobs,
  fetchTomorrowTotalJobs: JobActions.fetchTomorrowTotalJobs,
  fetchUpcomingUnfilledJobs: JobActions.fetchUpcomingUnfilledJobs,
  fetchUpcomingFilledJobs: JobActions.fetchUpcomingFilledJobs,
  fetchUpcomingTotalJobs: JobActions.fetchUpcomingTotalJobs,
  fetchHistoryJobs: JobActions.fetchHistoryJobs,
  fetchDraftsJobs: JobActions.fetchDraftsJobs,
  fetchQueuedJobs: JobActions.fetchQueuedJobs,
  openUploadPrivateJobCSVDialog: JobActions.openUploadPrivateJobCSVDialog,
};

export default connect(mapState, mapActions)(withStyles(styles)(JobOverview));
