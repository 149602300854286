/* eslint-disable react/display-name */
/* eslint-disable react/prop-types */
import React from "react";

import Grid from "@mui/material/Grid";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableHead from "@mui/material/TableHead";
import Typography from "@mui/material/Typography";

import { makeDate, upOrDown } from "helpers";
import { CustomTableCell, CustomTableCellLast, CustomTableRow } from "views/Finance/helpers";
import { formatMoney } from "utils/stringUtils";

const WeeklyCard = props => {
  return (
    <React.Fragment>
      <Typography variant="subtitle1">
        {`Billing Week ${props.week.week} Breakdown`}
        {upOrDown(props.lastWeek.billableTotalHours, props.calculated.billableTotalHours)}
      </Typography>
      <Grid item xs={12}>
        <Typography variant="subtitle2">{makeDate(Number(props.week.week), Number(props.week.year))}</Typography>
      </Grid>
      <Table size="small">
        <TableHead>
          <CustomTableRow>
            <CustomTableCell>Type</CustomTableCell>
            <CustomTableCell>Hours</CustomTableCell>
            <CustomTableCell>Billing Rate</CustomTableCell>
            <CustomTableCellLast>Total</CustomTableCellLast>
          </CustomTableRow>
        </TableHead>
        <TableBody>
          <CustomTableRow>
            <CustomTableCell>Regular</CustomTableCell>

            <CustomTableCell>
              {props.week.regularHoursWorked}
              {upOrDown(props.lastWeek.regularHoursWorked, props.week.regularHoursWorked)}
            </CustomTableCell>
            <CustomTableCell> $21.95</CustomTableCell>
            <CustomTableCellLast>{props.calculated.billableRegularHours}</CustomTableCellLast>
          </CustomTableRow>
          <CustomTableRow>
            <CustomTableCell>Overtime</CustomTableCell>
            <CustomTableCell>
              {props.week.overtimeHoursWorked}
              {upOrDown(props.lastWeek.overtimeHoursWorked, props.week.overtimeHoursWorked)}
            </CustomTableCell>
            <CustomTableCell>{formatMoney(22.95 * 1.5)}</CustomTableCell>
            <CustomTableCellLast>{props.calculated.billableOvertimeHours}</CustomTableCellLast>
          </CustomTableRow>
          <CustomTableRow>
            <CustomTableCell>Doubletime</CustomTableCell>
            <CustomTableCell>
              {props.week.doubletimeHoursWorked}
              {upOrDown(props.lastWeek.doubletimeHoursWorked, props.week.doubletimeHoursWorked)}
            </CustomTableCell>
            <CustomTableCell>{formatMoney(22.95 * 2)}</CustomTableCell>
            <CustomTableCellLast>{props.calculated.billableDoubletimeHours}</CustomTableCellLast>
          </CustomTableRow>
          <CustomTableRow>
            <CustomTableCell> </CustomTableCell>
            <CustomTableCell> </CustomTableCell>
            <CustomTableCell> </CustomTableCell>
            <CustomTableCellLast>
              {formatMoney(props.calculated.billableTotalHours)}{" "}
            </CustomTableCellLast>
          </CustomTableRow>
        </TableBody>
      </Table>
    </React.Fragment>
  );
};

export default WeeklyCard;
