import React from "react";
import moment from "moment-timezone";
import { Typography } from "@mui/material";
import withStyles from '@mui/styles/withStyles';
import Down from "@mui/icons-material/ArrowDownward";
import Up from "@mui/icons-material/ArrowUpwardSharp";
import { formatNumber } from "utils/stringUtils";
import { getWeekDates } from "utils/time";
const isMobile = false;
const styles = ({ spacing, breakpoints }) => ({
  title: {
    marginTop: isMobile ? spacing(1) : spacing(3),
    [breakpoints.down("sm")]: { fontSize: 12 },
  },
});
const classes = withStyles(styles);

export const makeDate = (week, year) => {
  const startEndDate = getWeekDates(week, year);
  return (
    <Typography variant="body2">
      {moment(startEndDate.start).format("ddd MMM Do")}-
      {moment(startEndDate.end).format("ddd MMM Do")}
    </Typography>
  );
};


export const makeHeaderDate = week => (
  <Typography variant="subtitle1" className={classes.title}>
    {`Week ${week} -
        ${moment()
          .week(week + 0)
          .startOf("week")
          .format("ddd MMM Do")}
        -
        ${moment()
          .week(week + 0)
          .endOf("week")
          .format("ddd MMM Do, YYYY")}`}
  </Typography>
);

export const upOrDown = (oldVal, newVal, styles = { fontSize: 12, marginLeft: 5 }) => {
  const { fontSize = 12, marginLeft = 10 } = styles;

  let diff = 100 * Math.abs((oldVal - newVal) / ((oldVal + newVal) / 2));

  return (
    <span
      style={{
        fontSize: fontSize ? fontSize : 12,
        color: oldVal < newVal ? "#268c52" : "red",
        marginLeft: marginLeft,
      }}
    >
      {oldVal < newVal ? (
        <Up style={{ fontSize: fontSize ? fontSize : 12 }} />
      ) : (
        <Down style={{ fontSize: fontSize ? fontSize : 12 }} />
      )}
      {formatNumber(diff)}%
    </span>
  );
};
export const sum = (Array.prototype.sum = function(prop) {
  var total = 0;
  for (var i = 0, _len = this.length; i < _len; i++) {
    total += this[i][prop];
  }
  return formatNumber(total);
});

// .ReactTable .rt-tbody .rt-tr-group {
//     min-height: 30px;
// }
