import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Avatar, CircularProgress, DialogTitle } from "@mui/material";
import MUIDialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Typography from "@mui/material/Typography";
import {
  Cancel,
  CheckCircle,
  Create,
  Delete,
  Edit,
  History,
  PersonAdd,
  QueryBuilderTwoTone,
  RemoveCircle,
  UpdateSharp,
  VisibilityOff,
} from "@mui/icons-material";
import Timeline from "@mui/lab/Timeline";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineOppositeContent from "@mui/lab/TimelineOppositeContent";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import { makeStyles } from "@mui/styles";
import { formatInitials } from "utils/stringUtils";
import { convertUTCToTimezoneWithOffset } from "utils/time";
import {
  actions as JobDetailActions,
  selectors as JobDetailSelectors,
} from "store/modules/jobDetails";
import Button from "components/Button/Button";

const useStyle = makeStyles(({ palette, customPalette }) => {
  return {
    oppContent: {
      flex: "none",
      padding: "0px 10px 6px 0px",
      width: "110px",
      alignItems: "center",
      display: "flex",
    },
    content: {
      minHeight: "80px",
    },
    progress: {
      color: palette.secondary.light,
      position: "absolute",
      top: "50%",
      left: "45%",
    },
    error: {
      borderColor: customPalette.red[600],
    },
    action: {
      borderColor: customPalette.grey[700],
    },
    yellow: {
      borderColor: customPalette.yellow[700],
      color: customPalette.yellow[700],
    },
    blue: {
      borderColor: customPalette.blue[400],
      color: customPalette.blue[400],
    },
    profileImage: {
      width: "25px",
      height: "25px",
      padding: "2px",
    },
  };
});

export const eventStatus = (eventSlug, classes, worker) => {
  const eventIcons = {
    job_create: (
      <TimelineDot color="secondary" variant="outlined">
        <Create color="secondary" className={classes.profileImage} />
      </TimelineDot>
    ),
    job_cancelled: (
      <TimelineDot className={classes.error} variant="outlined">
        <Cancel color="error" className={classes.profileImage} />
      </TimelineDot>
    ),
    job_edit: (
      <TimelineDot className={classes.blue} variant="outlined">
        <Edit color="blue" className={classes.profileImage} />
      </TimelineDot>
    ),
    job_delete: (
      <TimelineDot className={classes.error} variant="outlined">
        <Delete color="error" className={classes.profileImage} />
      </TimelineDot>
    ),
    worker_removed: (
      <TimelineDot className={classes.error} variant="outlined">
        {worker && (
          <Avatar src={worker.signedProfileImageUrl} className={classes.profileImage}>
            {formatInitials(worker.firstName, worker.lastName, "")}
          </Avatar>
        )}
      </TimelineDot>
    ),
    worker_added: (
      <TimelineDot color="secondary" variant="outlined">
        {worker && (
          <Avatar src={worker.signedProfileImageUrl} className={classes.profileImage}>
            {formatInitials(worker.firstName, worker.lastName, "")}
          </Avatar>
        )}
      </TimelineDot>
    ),
    no_show_and_ban: (
      <TimelineDot className={classes.error} variant="outlined">
        {worker && (
          <Avatar src={worker.signedProfileImageUrl} className={classes.profileImage}>
            {formatInitials(worker.firstName, worker.lastName, "")}
          </Avatar>
        )}
      </TimelineDot>
    ),
    no_show: (
      <TimelineDot className={classes.action} variant="outlined">
        {worker && (
          <Avatar src={worker.signedProfileImageUrl} className={classes.profileImage}>
            {formatInitials(worker.firstName, worker.lastName, "")}
          </Avatar>
        )}
      </TimelineDot>
    ),
    job_activate: (
      <TimelineDot className={classes.yellow} variant="outlined">
        <CheckCircle color="yellow" className={classes.profileImage} />
      </TimelineDot>
    ),
    check_in: (
      <TimelineDot color="secondary" variant="outlined">
        {worker && (
          <Avatar src={worker.signedProfileImageUrl} className={classes.profileImage}>
            {formatInitials(worker.firstName, worker.lastName, "")}
          </Avatar>
        )}
      </TimelineDot>
    ),
    check_out: (
      <TimelineDot className={classes.blue} variant="outlined">
        {worker && (
          <Avatar src={worker.signedProfileImageUrl} className={classes.profileImage}>
            {formatInitials(worker.firstName, worker.lastName, "")}
          </Avatar>
        )}
      </TimelineDot>
    ),
    ban_worker: (
      <TimelineDot className={classes.error} variant="outlined">
        {worker && (
          <Avatar src={worker.signedProfileImageUrl} className={classes.profileImage}>
            {formatInitials(worker.firstName, worker.lastName, "")}
          </Avatar>
        )}
      </TimelineDot>
    ),
    suspend_worker: (
      <TimelineDot className={classes.yellow} variant="outlined">
        {worker && (
          <Avatar src={worker.signedProfileImageUrl} className={classes.profileImage}>
            {formatInitials(worker.firstName, worker.lastName, "")}
          </Avatar>
        )}
      </TimelineDot>
    ),
  };
  return eventIcons[eventSlug];
};
function JobTimelineDialog(props) {
  const dispatch = useDispatch();
  const classes = useStyle();
  const { onClose, open } = props;
  const data = useSelector(JobDetailSelectors.getJobTimelineData);
  const isLoading = useSelector(JobDetailSelectors.getJobTimelineLoading);

  useEffect(() => {
    dispatch(JobDetailActions.fetchJobTimeline());
  }, []);

  return (
    <MUIDialog
      open={open}
      size="sm"
      maxWidth="sm"
      fullWidth
      disableBackdropClick={false}
      onClose={onClose}
    >
      <DialogTitle id="alert-dialog-title">
        {"Job Timeline"}
        <Button
          style={{ float: "right", marginRight: -15 }}
          label="X"
          size="small"
          onClick={onClose}
          color="transparent"
        />
      </DialogTitle>
      <DialogContent className={classes.content}>
        {isLoading && <CircularProgress classes={{ root: classes.progress }} />}
        {(!data || data.length == 0) && !isLoading && <p>No data found</p>}
        {data &&
          data.length > 0 && (
            <Timeline>
              {data.map(n => (
                <TimelineItem key={n.id}>
                  <TimelineOppositeContent className={classes.oppContent} color="text.secondary">
                    <div>
                      {convertUTCToTimezoneWithOffset(n.createdAt, "", "MMM Do YYYY h:mm a")}
                    </div>
                  </TimelineOppositeContent>
                  <TimelineSeparator>
                    <TimelineConnector />
                    {(n.eventSlug ? eventStatus(n.eventSlug, classes, n.worker) : "") || (
                      <TimelineDot color="primary" variant="outlined">
                        <History color="primary" className={classes.profileImage} />
                      </TimelineDot>
                    )}
                    <TimelineConnector />
                  </TimelineSeparator>
                  <TimelineContent>
                    <Typography variant="subtitle1" component="span">
                      {n.eventName}
                    </Typography>
                    <Typography>{n.notes}</Typography>
                    <Typography>
                      <b>Platform: </b> {n.userType}
                      <div>
                        <b>Activity By: </b>
                        {n.createdBy}
                      </div>
                    </Typography>
                  </TimelineContent>
                </TimelineItem>
              ))}
            </Timeline>
          )}
      </DialogContent>
    </MUIDialog>
  );
}

export default JobTimelineDialog;
