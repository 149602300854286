import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    Button,
    DialogActions,
    Grid,
    TextField,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Form, Formik } from "formik";
import { isEmpty } from "lodash";
import * as yup from "yup";
import { actions as AdminActions, selectors as AdminSelectors } from "store/modules/admins";
const useStyles = makeStyles(() => ({
    footer: {
        margin: 0,
        textAlign: "center",
        justifyContent: "center",
    },
}));
function ResetPassword({ hideResetDialog, data }) {
    const dispatch = useDispatch();
    const classes = useStyles();
    const isLoading = useSelector(AdminSelectors.getIsSaveAdminsLoading);
    const controlPadding = {
        leftColumn: { paddingRight: 4, textAlign: "left" },
        rightColumn: { paddingLeft: 4 },
    };

    const onSubmit = values => {
        if (data.id && values) {
            dispatch(AdminActions.resetAdminPassword({ ...values, id: data.id }));
        }
    };

    return (
        <React.Fragment>
            <Grid
                container
                item
                xs={12}
                alignItems="flex-start"
                style={{ paddingLeft: 0, paddingRight: 0 }}
            >
                <Formik
                    initialValues={{
                        password: "",
                        confimrPassword: ""
                    }}
                    onSubmit={(values, actions) => {
                        values.profileImage = onSubmit(values);
                        actions.setSubmitting(false);
                    }}
                    validationSchema={yup.object().shape({
                        password: yup.string().required("Required field").min(8, 'Must be at least 8 character'),
                        confimrPassword: yup.string().required('Required field').oneOf(
                            [yup.ref('password'), null],
                            "Passwords must match"
                        )
                    })}
                >
                    {props => {
                        const {
                            values,
                            touched,
                            errors,
                            dirty,
                            handleChange,
                            handleBlur,
                        } = props;

                        return (
                            <Form>
                                <Grid item container direction="row" xs={12}>
                                    <Grid item xs={12} md={12} style={controlPadding.rightColumn}>
                                        <TextField
                                            id="password"
                                            name="password"
                                            label="New Password"
                                            type="password"
                                            className={classes.textField}
                                            value={values.password}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            fullWidth
                                            error={errors.password && touched.password}
                                            helperText={errors.password && touched.password && errors.password}
                                            margin="normal"
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={12} style={controlPadding.rightColumn}>
                                        <TextField
                                            id="confimrPassword"
                                            name="confimrPassword"
                                            label="Confirm Password"
                                            type="password"
                                            className={classes.textField}
                                            value={values.confimrPassword}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            fullWidth
                                            error={errors.confimrPassword && touched.confimrPassword}
                                            helperText={errors.confimrPassword && touched.confimrPassword && errors.confimrPassword}
                                            margin="normal"
                                        />
                                    </Grid>
                                </Grid>
                                <DialogActions className={classes.footer}>
                                    <Button type="button" className="outline" onClick={hideResetDialog}>
                                        Close
                                    </Button>
                                    <Button
                                        variant="contained"
                                        color="secondary"
                                        type="submit"
                                        disabled={!dirty || !isEmpty(errors) || isLoading}
                                    >
                                        Save
                                    </Button>
                                </DialogActions>
                            </Form>
                        );
                    }}
                </Formik>
            </Grid>
        </React.Fragment>
    );
}

export default ResetPassword;
