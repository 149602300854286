import { gql } from "@apollo/client";

export default gql`
  mutation addEmployerNote($data: EmployerNoteInput!) {
    addEmployerNote(data: $data) {
      id
      note
      adminId
      employerId
      adminName
      adminEmail
      createdAt
    }
  }
`;
