import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import withStyles from '@mui/styles/withStyles';

const styles = ({ palette }) => ({
    placeholderLine: {
        width: "80%",
        height: "4px",
        backgroundColor: palette.grey[200],
    },
});

function Line({ classes, className }) {
    return <div className={classNames(classes.placeholderLine, className)} />;
}

Line.propTypes = {
    classes: PropTypes.object.isRequired,
    className: PropTypes.string,
};

Line.defaultProps = {
    className: "",
};

export default withStyles(styles)(Line);
