import Callback from "Callback.jsx";
import Dashboard from "layouts/Dashboard.jsx";
import EmailVerifySuccess from "../views/Email/EmailVerifySuccess";

var indexRoutes = [
  { path: "/callback", component: Callback },
  { path: "/email-verified", component: EmailVerifySuccess },
  { path: "/", name: "Home", component: Dashboard },
];

export default indexRoutes;
