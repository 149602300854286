import React, { Fragment, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import makeStyles from '@mui/styles/makeStyles';
import Tooltip from "@mui/material/Tooltip";
import ArrowDropDown from "@mui/icons-material/ArrowDropDown";
import ArrowRight from "@mui/icons-material/ArrowRight";
import MoreHoriz from "@mui/icons-material/MoreHoriz";
import CreateDcCustomerDialog from "views/Finance/FinanceOverview/components/Dialogs/CreateDcCustomerDialog";
import { verifyEmail } from "utils/stringUtils";
import { createDcCustomer, updateDcCustomer } from "store/modules/finance/actions";
import { selectors as JobDetailSelectors } from "store/modules/jobDetails";
import { workerEmailTaken } from "store/modules/workers/actions";
import useAddedBy from "./columns/addedBy";
import useAppliedDate from "./columns/appliedDate";
import useAvatar from "./columns/avatar";
import useBonus from "./columns/bonus";
import useBreakMins from "./columns/breakMins";
import useCheckIn from "./columns/checkIn";
import useCheckInBy from "./columns/checkInBy";
import useCheckOut from "./columns/checkOut";
import useCheckOutBy from "./columns/checkOutBy";
import useLocation from "./columns/location";
import useName from "./columns/name";
import usePayRate from "./columns/payRate";
import usePayStatus from "./columns/payStatus";
import usePositionMenu from "./columns/positionMenu";
import PositionPayrollTable from "./columns/PositionPayrollTable";
import useShiftDuration from "./columns/shiftDuration";
import useTags from "./columns/tags";
import ReactTableSimple from "components/ReactTable/ReactTableSimple";

const useStyles = makeStyles(({ shape, typography, spacing }) => ({
    table: {
        flex: 1,
        border: "none",
        borderRadius: shape.borderRadius / 2,
        width: "100%",
    },
    icon: {
        fontSize: typography.pxToRem(20),
        marginRight: spacing(2),
    },
    paymentTableContainer: {
        padding: spacing(3),
        backgroundColor: "#f8f9fa",
    },
}));
function CompletedTable(props) {
    const classes = useStyles();
    const [expanded, setExpanded] = useState();
    const [worker, setWorker] = useState();
    const dispatch = useDispatch();
    const isLoading = useSelector(JobDetailSelectors.getIsUpdatingPositions);
    const positions = useSelector(JobDetailSelectors.getPositions);
    const jobDetails = useSelector(JobDetailSelectors.getJobDetails);
    const [openCreateDcCustomerDialog, setOpenCreateDcCustomerDialog] = useState(false);
    const [dcEmail, setDcEmail] = useState("");
    const [dcEmailError, setDcEmailError] = useState("");
    const [emailValid, setEmailValid] = useState(false);
    const [emailEditable, setEmailEditable] = useState(false);
    const hiddenColumns = []
    if (!jobDetails.isPrivate) hiddenColumns.push("tags")

    const batchActionDisable =
        props.isEditingRatesAll || props.isCheckingInAll || props.isCheckingOutAll;

    // function onExpandedChange(expanded, index) {
    //     const { payroll } = positions[index];
    //     if (payroll && payroll.length !== 0) {
    //         if (payroll.some(({ payments }) => payments.length !== 0)) {
    //             setExpanded(expanded);
    //         }
    //     }
    // }

    const columns = [
        useAvatar(batchActionDisable),
        useName(batchActionDisable),
        useLocation(batchActionDisable),
        useAppliedDate(batchActionDisable),
        {
            //   width: 75,
            sortable: false,
            id: "dcCustomerNumber",
            style: { alignItems: "left" },
            Header: "DC Bank Id",
            // eslint-disable-next-line react/display-name
            Cell: ({ row: { original } }) => {
                return original.dcCustomerNumber ? (
                    <div>
                        <Tooltip title={`Create payroll & Send eTransfer for this worker.`} placement="top">
                            <div>
                                {original.dcCustomerNumber}
                            </div>
                        </Tooltip>
                    </div>
                ) : (
                    <div style={{ textAlign: "left" }}>
                        <Tooltip title={`Create payroll & Send eTransfer for this worker.`} placement="top">
                            <Button onClick={() => handleShowDcCustomer(original)}> Setup Payroll </Button>
                        </Tooltip>
                    </div>
                );
            },
        },
        useTags(batchActionDisable, jobDetails.isPrivate),
        useCheckIn(props.isCheckingInAll, batchActionDisable, jobDetails.timezone),
        useCheckInBy(batchActionDisable),
        useCheckOut(props.isCheckingOutAll, batchActionDisable, jobDetails.timezone),
        useCheckOutBy(batchActionDisable),
        useBreakMins(props.isCheckingOutAll, batchActionDisable),
        useShiftDuration(props.isCheckingOutAll, batchActionDisable),
        usePayRate(props.isEditingRatesAll, batchActionDisable),
        // useBillingRate(props.isEditingRatesAll, batchActionDisable),
        useBonus(batchActionDisable),
        usePayStatus(batchActionDisable),
        // {
        //     width: 75,
        //     sortable: false,
        //     style: { alignItems: "center" },
        //     Header: "Issues",
        //     id: "issues",
        //     show: false,
        //     Cell: ({ row: { original } }) => {
        //         return (
        //             <IconButton edge="end" onClick={() => handleShowDcCustomer(original)} size="large">
        //                 <Tooltip title={`Create payroll & Send eTransfer for this worker.`} placement="top">
        //                     <MoreHoriz className={classes.payIcon} />
        //                 </Tooltip>
        //             </IconButton>
        //         );
        //     },
        // },
        useAddedBy(batchActionDisable),
        {
            expander: true,
            width: 30,
            id: "expander",
            /* eslint-disable react/display-name, react/prop-types */
            Cell: ({ row: { original, isExpanded, ...row }, column }) => {
                const { payroll } = original;
                if (payroll && payroll.length > 0) {
                    if (payroll.some(({ payments }) => payments.length !== 0)) {
                        return (
                            <IconButton {...row.getToggleRowExpandedProps()}>
                                {isExpanded ? <ArrowDropDown /> : <ArrowRight />}
                            </IconButton>
                        );
                    };
                }
                return null;
            }
            /* eslint-enable react/display-name, react/prop-types */
            // Cell: null,
        },
        usePositionMenu({
            isWorkerPickerOpen: props.isWorkerPickerOpen,
            onChooseWorker: props.onChooseWorker,
            disabled: batchActionDisable,
        }),
    ];
    const handleWorkerEmailTaken = dcEmail => {
        if (verifyEmail(dcEmail) && dcEmail !== worker.email) {
            dispatch(workerEmailTaken(dcEmail)).then(result => {
                result.workerEmailTaken ? setEmailValid(false) : setEmailValid(true);
                result.workerEmailTaken ? setDcEmailError("Email already taken.") : setDcEmailError("");

                setDcEmail(dcEmail);
            });
        } else if (!verifyEmail(dcEmail)) {
            setEmailValid(false);
            setDcEmailError("Email is not valid.");
            setDcEmail(dcEmail);
        } else if (verifyEmail(dcEmail) && dcEmail === worker.email) {
            setEmailValid(true);
            setDcEmail(dcEmail);
            setDcEmailError("");
        } else setDcEmail(dcEmail);
    };
    const handleShowDcCustomer = original => {
        verifyEmail(original.email) ? setEmailValid(true) : setEmailValid(false);
        setOpenCreateDcCustomerDialog(!openCreateDcCustomerDialog);
        setWorker(original);
        setDcEmail(original.email);
    };

    const handleCreateDcCustomer = () => {
        dispatch(createDcCustomer(worker.workerId, dcEmail)).then(status => {
            return status;
        });
    };

    const handleUpdateDcCustomer = () => {
        dispatch(updateDcCustomer(worker.workerId, dcEmail));
    };

    const RenderShowDcCustomer = () => {
        return (
            <Fragment>
                <CreateDcCustomerDialog
                    openCreateDcCustomerDialog={openCreateDcCustomerDialog}
                    handleShowDcCustomer={handleShowDcCustomer}
                    data={worker}
                    emailValid={emailValid}
                    setEmailValid={setEmailValid}
                    dcEmail={dcEmail}
                    setDcEmail={setDcEmail}
                    dcEmailError={dcEmailError}
                    handleWorkerEmailTaken={handleWorkerEmailTaken}
                    emailEditable={emailEditable}
                    setEmailEditable={setEmailEditable}
                    handleCreateDcCustomer={handleCreateDcCustomer}
                    handleUpdateDcCustomer={handleUpdateDcCustomer}
                />
            </Fragment>
        );
    };

    return (
        <Fragment>
            <RenderShowDcCustomer />
            <ReactTableSimple
                showFooter
                classes={classes.table}
                minRows={0}
                data={positions}
                loading={isLoading}
                columns={columns}
                hiddenColumns={hiddenColumns}
                // onExpandedChange={onExpandedChange}
                expandView={props => <PositionPayrollTable jobDetails={jobDetails} {...props} />}
            // SubComponent={PositionPayrollTable}
            />
        </Fragment>
    );
}

CompletedTable.propTypes = {
    onChooseWorker: PropTypes.func.isRequired,
    isWorkerPickerOpen: PropTypes.bool.isRequired,
    isRatingAll: PropTypes.bool.isRequired,
    isCheckingInAll: PropTypes.bool.isRequired,
    isCheckingOutAll: PropTypes.bool.isRequired,
    isEditingRatesAll: PropTypes.bool.isRequired,
};

export default CompletedTable;
