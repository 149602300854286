import React from "react";
import PropTypes from "prop-types";
import withStyles from '@mui/styles/withStyles';
import Typography from "@mui/material/Typography";

const styles = theme => ({
    container: {
        display: "inline-flex",
        flex: 1,
        alignItems: "center",
        "& svg": {
            fontSize: "18px",
        },
    },
    label: {
        padding: `0px ${theme.spacing(2)}`,
    },
});

function SubNavLabel({ classes, icon, label, count }) {
    return (
        <div className={classes.container}>
            {icon}
            <Typography className={classes.label} variant="body1">
                {label}
            </Typography>
            {count !== undefined ? (
                <Typography variant="caption" color="textSecondary">
                    {count}
                </Typography>
            ) : null}
        </div>
    );
}

SubNavLabel.propTypes = {
    classes: PropTypes.object.isRequired,
    label: PropTypes.node.isRequired,
    icon: PropTypes.element,
    count: PropTypes.number,
};

SubNavLabel.defaultProps = {
    icon: null,
    count: null,
};

export default withStyles(styles)(SubNavLabel);
