import gql from "graphql-tag";

export const adminsByIdQuery = gql`
  query($id : ID!){
    adminById(id : $id){
      id
      email
      firstName
      lastName
      title
      role
      addedBy
      signedProfileImageUrl
    }
  }
`;
export default adminsByIdQuery;
