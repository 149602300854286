import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Avatar } from "@mui/material";
import Button from "components/Button/Button";
import MainContent from "components/Containers/MainContent";
import Dialog from "components/Dialog/Dialog";
import { sharedReactTableProps } from "utils/constant";
import { formatInitials } from "utils/stringUtils";
import { actions as AdminActions, selectors as AdminSelectors } from "store/modules/admins";

import AddAdmin from "./AddAdmin";
import AdminActionMenu from "./AdminActionMenu";
import ResetPassword from "./ResetPassword";
import Can from "components/Authorization/Can";
import { AdminPermissions } from "helpers/constants/permissions";
import ReactTableSimple from "components/ReactTable/ReactTableSimple";
import { hasPermission } from "utils/authorize";

function AdminTable({ data }) {
    const dispatch = useDispatch();
    const [adminDetail, setAdminDetail] = useState({});
    const adminList = useSelector(AdminSelectors.getAdminList);
    const isLoading = useSelector(AdminSelectors.getIsAdminsLoading);
    const isOpenAddDialog = useSelector(AdminSelectors.getIsOpenAddDialog);
    const isOpenResetPasswordDialog = useSelector(AdminSelectors.getIsOpenResetPasswordDialog);
    useEffect(() => {
        dispatch(AdminActions.fetchAdminData());
    }, []);

    const onEdit = data => {
        if (data) {
            setAdminDetail({
                ...data,
            });
        } else {
            setAdminDetail({});
        }

        dispatch(AdminActions.setIsOpenAddDialog(true));
    };
    const hideAddDialog = () => {
        dispatch(AdminActions.setIsOpenAddDialog(false));
    };

    const onResetPassword = data => {
        console.log(data);
        if (data) {
            setAdminDetail({
                ...data,
            });
        } else {
            setAdminDetail({});
        }

        dispatch(AdminActions.setIsOpenResetPasswordDialog(true));
    };
    const hideResetDialog = () => {
        dispatch(AdminActions.setIsOpenResetPasswordDialog(false));
    };

    const column = [
        {
            Header: "",
            id: "profileImage",
            disableSortBy: true,
            disableFilters: true,
            width: 80,
            Cell: ({ row }) => (
                <Avatar src={row.original.signedProfileImageUrl}>
                    {formatInitials(row.original.firstName, row.original.lastName, "")}
                </Avatar>
            ),
        },
        {
            Header: "First Name",
            id: "firstName",
            disableSortBy: true,
            accessor: ({ firstName }) => firstName,
            className: "-cursor-pointer",
        },
        {
            Header: "Last Name",
            id: "lastName",
            disableSortBy: true,
            accessor: ({ lastName }) => lastName,
            className: "-cursor-pointer",
        },
        {
            Header: "Email",
            id: "email",
            disableSortBy: true,
            accessor: ({ email }) => email,
            className: "-cursor-pointer",
        },
        {
            Header: "Role",
            id: "role",
            disableSortBy: true,
            accessor: ({ role }) => role,
            className: "-cursor-pointer",
        },
        {
            Header: "Title",
            disableSortBy: true,
            id: "title",
            accessor: ({ title }) => title,
            className: "-cursor-pointer",
        },
        {
            Header: () => (
                <div className="text-right">
                    <Can perform={AdminPermissions.create.new.value}>
                        {allowed => {
                            if (!allowed) {
                                return null;
                            }
                            return <Button label="Add" onClick={() => onEdit()} />;
                        }}
                    </Can>
                </div>
            ),
            id: "actions",
            disableSortBy: true,
            disableFilters: true,
            width: 100,
            className: "text-right",
            Cell: ({ row: { original } }) => {
                const { id } = original;
                const allPermissions = [
                    AdminPermissions.update.profile.value,
                    AdminPermissions.read.assignpermissions.value,
                    AdminPermissions.update.admin.value,
                    AdminPermissions.delete.hard.value,
                ]
                if (allPermissions.some(p => hasPermission(p))) {
                    return (
                        <AdminActionMenu
                            data={original}
                            onEdit={onEdit}
                            onResetPassword={onResetPassword}
                        // onCancel={this.props.handleCancelManager}
                        />
                    );
                }
                return null;
            },
        },
    ];
    return (
        <React.Fragment>
            <MainContent>
                <ReactTableSimple
                    {...sharedReactTableProps}
                    classes="-highlight"
                    data={adminList}
                    loading={isLoading}
                    filterable={false}
                    columns={column}
                />
            </MainContent>
            <Dialog
                open={isOpenAddDialog}
                title="Add Admin"
                description={null}
                onClose={hideAddDialog}
                onConfirm={hideAddDialog}
                onCancel={hideAddDialog}
                modalType="editEmployer"
                alertType="form"
                size="sm"
                hideActions
            >
                <AddAdmin
                    hideAddDialog={hideAddDialog}
                    data={adminDetail}
                />
            </Dialog>
            <Dialog
                open={isOpenResetPasswordDialog}
                title="Reset Password"
                description={null}
                onClose={hideResetDialog}
                onConfirm={hideResetDialog}
                onCancel={hideResetDialog}
                modalType="editEmployer"
                alertType="form"
                size="sm"
                hideActions
            >
                <ResetPassword
                    hideResetDialog={hideResetDialog}
                    data={adminDetail}
                />
            </Dialog>
        </React.Fragment>
    );
}

export default AdminTable;
