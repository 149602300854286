import React, { useState } from "react";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import Dialog from "components/Dialog/Dialog";
import Rating from "components/Rating/Rating";
import { actions as JobDetailActions } from "store/modules/jobDetails";

function CheckInDialog(props) {
  const { open, onClose, onCancel, position } = props;
  const dispatch = useDispatch();
  const [rating, setValue] = useState(position.rating || 3);

  const onRatingChange = value => {
    setValue(value);
  };
  const handleAddRating = () => {
    // toggleAddBonus();
    dispatch(JobDetailActions.rateWorker(position.workerId, rating));
  };
  return (
    <Dialog
      open={open}
      title={`Rate ${position.fullName}?`}
      description="Rate workers based off of their performance on the job."
      onClose={onClose}
      onConfirm={handleAddRating}
      onCancel={onCancel}
      alertType="question"
      icon="star">
      <Rating size={45} value={rating} onChange={onRatingChange} />
    </Dialog>
  );
}

CheckInDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  position: PropTypes.object,
};

export default CheckInDialog;
