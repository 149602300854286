import React from "react";

import Avatar from "@mui/material/Avatar";
import Chip from "@mui/material/Chip";
import withStyles from '@mui/styles/withStyles';
import Tooltip from "@mui/material/Tooltip";

import AccessTime from "@mui/icons-material/AccessTime";

import Cancel from "@mui/icons-material/Cancel";
import CancelScheduleSend from "@mui/icons-material/CancelScheduleSend";
import Check from "@mui/icons-material/Check";
import CheckCircleOutlined from "@mui/icons-material/CheckCircleOutlined";
import CloudUpload from "@mui/icons-material/CloudUpload";
import HelpOutlineOutlined from "@mui/icons-material/HelpOutlineOutlined";
import LowPriority from "@mui/icons-material/LowPriority";
import SmsFailed from "@mui/icons-material/SmsFailed";
import SpeakerNotes from "@mui/icons-material/SpeakerNotes";
import SpeakerNotesOff from "@mui/icons-material/SpeakerNotesOff";

import Textsms from "@mui/icons-material/Textsms";
import Warning from "@mui/icons-material/Warning";

import { PaymentStatusCode } from "helpers/constants/index";

function Badge({ classes, Icon, showLabel, label }) {
  if (!showLabel) {
    return (
      <Tooltip title={label} placement="top">
        <Avatar className={classes.iconRoot}>
          <Icon fontSize="default" />
        </Avatar>
      </Tooltip>
    );
  }

  return (
    <Chip icon={<Icon className={classes.icon} />} title={label} className={classes.chipRoot} />
  );
}

const styleFactory = color => ({ customPalette, spacing }) => ({
  chipRoot: {
    backgroundColor: customPalette[color][100],
    // ...typography.body1,
    color: customPalette[color][900],
    padding: `${spacing(1)} ${spacing(0.5)}`,
    textAlign: "center",
  },
  iconRoot: {
    backgroundColor: customPalette[color][100],
    // ...typography.body1,
    margin: "auto",
    color: customPalette[color][700],
    textAlign: "center",
  },
  icon: {
    // ...typography.body1,
    color: customPalette[color][700],
    textAlign: "center",

    // marginRight: -spacing(0.5),
  },
});

export const MissingInfoBadge = withStyles(styleFactory("red"))(props => (
  <Badge Icon={Warning} label="sxsxs" {...props} />
));

export const QueuedBadge = withStyles(styleFactory("blue"))(props => (
  <Badge Icon={AccessTime} label="eTransfer Queued" {...props} />
));

export const SendingBadge = withStyles(styleFactory("blue"))(props => (
  <Badge Icon={CloudUpload} label="Sending eTransfer " {...props} />
));

export const AwaitingResponseBadge = withStyles(styleFactory("blue"))(props => (
  <Badge Icon={Textsms} label="Awaiting Response" {...props} />
));

export const DeliveredBadge = withStyles(styleFactory("green"))(props => (
  <Badge Icon={CheckCircleOutlined} label="eTransfer Delivered" {...props} />
));

export const AcceptedBadge = withStyles(styleFactory("green"))(props => (
  <Badge Icon={Check} label="eTransfer Accepted" {...props} />
));

export const DeclinedBadge = withStyles(styleFactory("red"))(props => (
  <Badge Icon={SpeakerNotesOff} label="eTransfer Declined" {...props} />
));

export const FailedBadge = withStyles(styleFactory("red"))(props => (
  <Badge Icon={SmsFailed} label="eTransfer Failed" {...props} />
));

export const CancelledBadge = withStyles(styleFactory("red"))(props => (
  <Badge Icon={Cancel} label="eTransfer Cancelled" {...props} />
));

export const DeferredBadge = withStyles(styleFactory("yellow"))(props => (
  <Badge Icon={LowPriority} label="eTransfer Deferred" {...props} />
));

export const ResolvedBadge = withStyles(styleFactory("yellow"))(props => (
  <Badge Icon={CancelScheduleSend} label="eTransfer Manually Resolved" {...props} />
));

export const UnknownBadge = withStyles(styleFactory("red"))(props => (
  <Badge Icon={HelpOutlineOutlined} label="Unknown" {...props} />
));

//
//
//

export const ProcessingResponseBadge = withStyles(styleFactory("blue"))(props => (
  <Badge Icon={SpeakerNotes} label="Processing Response" {...props} />
));

const paymentStatusCodeBadgeMap = {
  U: AwaitingResponseBadge,
  P: ProcessingResponseBadge,
  S: DeliveredBadge,
  C: AcceptedBadge,
  RJ: DeclinedBadge,
  E: FailedBadge,
  V: CancelledBadge,
  BL: CancelledBadge,
  IB: CancelledBadge,
  DF: DeferredBadge,
  MR: ResolvedBadge,
  [PaymentStatusCode.Unknown]: UnknownBadge,
};
export function DcTransferStatusBadge({ code, ...props }) {
  const PaymentStatusBadge = paymentStatusCodeBadgeMap[code];
  return <PaymentStatusBadge iconOnly {...props} />;
}
