import React, { useState } from "react";
import { useSelector } from "react-redux";
import Typography from "@mui/material/Typography";
import Button from "components/Button/Button";
import Dialog from "components/Dialog/Dialog";

import { selectors as WorkerDetailSelectors } from "store/modules/workerDetails";
import EditWorkerProfileTabs from "./Tabs/EditWorkerProfileTabs";

const EditWorkerDialog = props => {
  const [, setEnableSubmit] = useState(false);
  let worker = useSelector(WorkerDetailSelectors.getWorkerDetails).worker;
  const {
    firstName,
    lastName,
    sin,
    phone,
    email,
    street,
    city,
    region,
    country,
    postalCode,
    timezone,
    dcCustomerNumber,
    travelRange,
    employmentType,
    isEmailVerified,
    isSuspended,
    isStudent,
    vaccinationStatus,
    isActive,
    isApproved,
    isOnboarded,
    workTypePreferences,
    transportationPreferences,
  } = worker;
  const {
    handleSetCanEdit,

    onClose,
    onConfirm,
    onCancel,
    open,
  } = props;
  const checkSubmit = val => setEnableSubmit(val);

  return (
    <>
      {email && (
        <Dialog
          open={open}
          title={
            <React.Fragment>
              <Typography
                component="span"
                style={{ width: "100%", fontSize: 18 }}>
                {`Manage Worker Settings`}
                <Button
                  style={{ float: "right", marginRight: -30 }}
                  label="X"
                  size="small"
                  onClick={() => handleSetCanEdit()}
                  color="transparent"
                />
              </Typography>
            </React.Fragment>
          }
          description={null}
          confirmText="Update Worker Settings"
          disableBackdropClick={true}
          fullWidth={true}
          hideActions={true}
          onClose={onClose}
          onConfirm={onConfirm}
          onCancel={onCancel}
          size="md"
          alertType="form"
          modalType="editEmployer"
          //   disabled={!enableSubmit}
        >
          <EditWorkerProfileTabs
            firstName={firstName}
            lastName={lastName}
            checkSubmit={checkSubmit}
            email={email}
            phone={phone}
            street={street}
            city={city}
            region={region}
            country={country}
            postalCode={postalCode}
            isActive={isActive}
            isStudent={isStudent}
            vaccinationStatus={vaccinationStatus}
            travelRange={travelRange}
            isSuspended={isSuspended}
            isOnboarded={isOnboarded}
            dcCustomerNumber={dcCustomerNumber}
            employmentType={employmentType}
            timezone={timezone}
            sin={sin}
            isEmailVerified={isEmailVerified}
            isApproved={isApproved}
            setCanEdit={handleSetCanEdit}
            transportationPreferences={transportationPreferences.map(t => ({
              id: t.transportation.id,
              label: t.transportation.label,
            }))}
            workTypePreferences={workTypePreferences.map(t => ({
              id: t.workType.id,
              label: t.workType.label,
            }))}
          />
        </Dialog>
      )}
    </>
  );
};

export default EditWorkerDialog;
