import _ from "lodash";
import ApolloClient from "utils/apollo";
import { flattenGraphQLArray } from "utils/graphql";
import * as pagination from "utils/graphql-pagination";
import { actions as MetaActions } from "store/modules/meta";
import { fetchWorkTypes } from "../../posting/actions";
import { paginationSettings } from "../constants";
import {
    setWorkerTransportationPreferencesMutation,
    setWorkerWorkTypePreferencesMutation,
    UpdateWorkerEmailMutation,
    UpdateWorkerProfileMutation,
    uploadFileToS3,
} from "../graphql/mutations";
import ActivateWorkerMutation from "../graphql/mutations/activate-worker";
import AddWorkerNoteMutation from "../graphql/mutations/add-worker-note";
import AddWorkerTagsMutation from "../graphql/mutations/add-worker-tags";
import BanWorkerMutation from "../graphql/mutations/ban-worker";
import DeactivateWorkerMutation from "../graphql/mutations/deactivate-worker";
import PreferWorkerMutation from "../graphql/mutations/prefer-worker";
import RemoveWorkerMutation from "../graphql/mutations/remove-worker";
import RemoveWorkerTagsMutation from "../graphql/mutations/remove-worker-tags";
import SuspendWorkerMutation from "../graphql/mutations/suspend-worker";
import UnsuspendWorkerMutation from "../graphql/mutations/unsuspend-worker";
import UnverifyWorkerMutation from "../graphql/mutations/unverify-worker";
import VerifyWorkerMutation from "../graphql/mutations/verify-worker";
import { findWorkerEtransfersQuery, getTransportationListQuery } from "../graphql/queries";
import FetchWorkerBannedFromQuery from "../graphql/queries/fetch-employer-exceptions";
import FetchWorkerNotesQuery from "../graphql/queries/fetch-notes";
import FetchPositionsQuery from "../graphql/queries/fetch-positions";
import FetchWorkerQuery from "../graphql/queries/fetch-worker";
import FetchWorkerDCTrancation from "../graphql/queries/fetch-worker-dc-transaction";
import { getPositionsPaginationData, getWorkerBannedFromPaginationData, getWorkerDetails, getWorkerNotesPaginationData } from "../selectors";
import types from "../types";
import { sanitizePayload } from "./helpers";

const setLoadingState = key => value => ({
  type: types.SET_LOADING_STATE,
  payload: { key, value },
});

const setTransportation = data => ({
  type: types.SET_TRANSPORTATION_TYPES,
  payload: data,
});
const setWorkerEtransfers = data => ({
  type: types.SET_WORKER_ETRANSFERS,
  payload: data,
});

const setDCTransactionList = data => ({
  type: types.SET_WORKER_DC_TRANSACTION_LIST,
  payload: data,
});

const setIsPageLoading = setLoadingState("page");
const setIsPositionsLoading = setLoadingState("positions");
const setIsWorkerNotesLoading = setLoadingState("workerNotes");
const setIsWorkerBannedFromLoading = setLoadingState("WorkerBannedFrom");
const setIsApplyingAction = setLoadingState("actions");
const setIsDCTransactionLoading = setLoadingState("DCTransaction");
const getPositionsPagingVars = pagination.pagingVarsFactory(paginationSettings.positions);
const getWorkerNotesPagingVars = pagination.pagingVarsFactory(paginationSettings.workerNotes);
const getBannedFromPagingVars = pagination.pagingVarsFactory(paginationSettings.workerBannedFrom);

export const findWorkerEtransfers = args => dispatch => {
  const payload = {
    type: args.type || null,
    amount: args.amount || null,
    customerNumber: args.customerNumber || null,
    maxExpiryDate: args.maxExpiryDate || null,
    maxDate: args.maxDate || null,
    minDate: args.minDate || null,
    minExpiryDate: args.minExpiryDate || null,
    transactionId: Number(args.transactionId) || null,
  };

  return ApolloClient.query({ query: findWorkerEtransfersQuery, variables: { ...payload } })
    .then(({ data }) => dispatch(setWorkerEtransfers(data.findEtransferTransactions)))
    .catch(() => dispatch(MetaActions.errorToast(`Failed find Etransfers`)));
};

export const clearWorkerUpdateData = () => ({
  type: types.CLEAR_UPDATE_UPDATE_WORKER_DATA,
  payload: {},
});

export const fetchTransportation = () => async dispatch => {
  return ApolloClient.query({
    query: getTransportationListQuery,
    variables: {},
  })
    .then(({ data: { transportation } }) => {
      //
      dispatch(setTransportation(transportation));
      // return transportation;
    })
    .catch(e => {
      dispatch(MetaActions.errorToast(`Could not get position information: ${e.message}`));
    });
};

export const setUpdateWorkerData = (field, value) => {
  if (field === "phone" || field === "sin") {
    value = value.replace(/\D/g, "");
  }

  return {
    type: types.SET_UPDATE_UPDATE_WORKER_DATA,
    payload: { field, value },
  };
};

export const saveWorkerWorkTypes = data => (dispatch, getState) => {
  const { worker } = getState().workerDetails;

  const { workTypePreferences, transportationPreferences, timezone, travelRange, vaccinationStatus } = data;
  let workTypeIds = [];
  let transportationIds = [];
  workTypePreferences.map(e => workTypeIds.push(e.id ? e.id : e.value));
  transportationPreferences.map(e => transportationIds.push(e.id ? e.id : e.value));

  const payload = sanitizePayload({ timezone, travelRange, vaccinationStatus });

  return ApolloClient.mutate({
    mutation: setWorkerWorkTypePreferencesMutation,
    variables: { data: { workerId: worker.id, workTypeIds } },
  })
    .then(() =>
      ApolloClient.mutate({
        mutation: setWorkerTransportationPreferencesMutation,
        variables: { data: { workerId: worker.id, transportationIds } },
      })
        .then(() =>
          ApolloClient.mutate({
            mutation: UpdateWorkerProfileMutation,
            variables: { data: { id: worker.id, ...payload } },
          })
            .then(() =>{
                dispatch(MetaActions.successToast(`${worker.firstName} ${worker.lastName}'s profile has been updated.`))
                dispatch(fetchWorker(worker.id))
            })
            .catch(e =>
              dispatch(MetaActions.errorToast(`Updating ${worker.firstName} has failed. ${e}`)),
            ),
        )
        .catch(e =>
          dispatch(MetaActions.errorToast(`Updating ${worker.firstName} has failed. ${e}`)),
        ),
    )
    .catch(e => dispatch(MetaActions.errorToast(`Updating ${worker.firstName} has failed. ${e}`)));
};
export const saveWorkerSetting = data => (dispatch, getState) => {
  const { worker } = getState().workerDetails;

  const payload = sanitizePayload(data);

  if (_.isEmpty(data)) {
    return dispatch(MetaActions.successToast(`Nothing to update.`));
  }

  if (_.isEmpty(payload)) {
    return dispatch(MetaActions.successToast(`Nothing to update.`));
  }

  return ApolloClient.mutate({
    mutation: UpdateWorkerProfileMutation,
    variables: { data: { id: worker.id, ...payload } },
  })
    .then(({ data }) => {
      dispatch(
        MetaActions.successToast(
          `${worker.firstName} ${worker.lastName}'s profile has been updated.`,
        ),
      );
      return data.editWorker;
    })
    .catch(e => {
      dispatch(setUpdateWorkerData());
      dispatch(MetaActions.errorToast(`Updating ${worker.firstName} has failed. ${e}`));
    });
};
export const saveWorkerProfile = data => (dispatch, getState) => {
  const { worker, updateWorkerData } = getState().workerDetails;

  const payload = sanitizePayload(data);

  if (_.isEmpty(data)) {
    // dispatch(clearWorkerUpdateData());
    return dispatch(MetaActions.successToast(`Nothing to update.`));
  }

  if (data.emails) {
    ApolloClient.mutate({
      mutation: UpdateWorkerEmailMutation,
      variables: { data: { workerId: worker.id, email: data.email } },
    })
      .then(({ data }) => console.log("UpdateWorkerEmailMutation", data))
      .catch(e =>
        dispatch(MetaActions.errorToast(`Updating ${worker.firstName} has failed. ${e}`)),
      );
  }
  if (_.isEmpty(payload)) {
    // dispatch(clearWorkerUpdateData());
    return dispatch(MetaActions.successToast(`Nothing to update.`));
  }
  if (updateWorkerData.transType) {
    delete updateWorkerData.transType;
  }

  if (updateWorkerData.data) {
    delete updateWorkerData.data;
  }

  return ApolloClient.mutate({
    mutation: UpdateWorkerProfileMutation,
    variables: { data: { id: worker.id, ...payload } },
  })
    .then(({ data }) => {
      //   dispatch(setUpdateWorkerData());
      dispatch(fetchWorker(worker.id));
      dispatch(
        MetaActions.successToast(
          `${worker.firstName} ${worker.lastName}'s profile has been updated.`,
        ),
      );
      return data.editWorker;
    })
    .catch(e => {
      dispatch(setUpdateWorkerData());
      dispatch(MetaActions.errorToast(`Updating ${worker.firstName} has failed. ${e}`));
    });
};

export const updateWorkerProfile = () => (dispatch, getState) => {
  const { worker, updateWorkerData } = getState().workerDetails;
  const payload = sanitizePayload(updateWorkerData);
  if (_.isEmpty(updateWorkerData)) {
    dispatch(clearWorkerUpdateData());
    return dispatch(MetaActions.successToast(`Nothing to update.`));
  }

  if (updateWorkerData.email) {
    ApolloClient.mutate({
      mutation: UpdateWorkerEmailMutation,
      variables: { data: { workerId: worker.id, email: updateWorkerData.email } },
    })
      .then(({ data }) => console.log("UpdateWorkerEmailMutation", data))
      .catch(e =>
        dispatch(MetaActions.errorToast(`Updating ${worker.firstName} has failed. ${e}`)),
      );
  }
  if (_.isEmpty(payload)) {
    dispatch(clearWorkerUpdateData());
    return dispatch(MetaActions.successToast(`Nothing to update.`));
  }
  if (updateWorkerData.transType) {
    delete updateWorkerData.transType;
  }

  if (updateWorkerData.data) {
    delete updateWorkerData.data;
  }

  return ApolloClient.mutate({
    mutation: UpdateWorkerProfileMutation,
    variables: { data: { id: worker.id, ...payload } },
  })
    .then(({ data }) => {
      dispatch(setUpdateWorkerData());
      dispatch(fetchWorker(worker.id));
      dispatch(
        MetaActions.successToast(
          `${worker.firstName} ${worker.lastName}'s profile has been updated.`,
        ),
      );
      return data.editWorker;
    })
    .catch(e => {
      dispatch(setUpdateWorkerData());
      dispatch(MetaActions.errorToast(`Updating ${worker.firstName} has failed. ${e}`));
    });
};

const setWorker = worker => ({ type: types.SET_WORKER, payload: { worker } });
const setPositionListData = (data, part) => ({
  type: types.SET_POSITIONS_LIST_DATA,
  payload: { data, part },
});
const setWorkerNotesListData = data => ({
  type: types.SET_WORKER_NOTES_LIST_DATA,
  payload: { data },
});
const setWorkerBannedFromListData = data => ({
  type: types.SET_WORKER_BANNED_FROM_LIST_DATA,
  payload: { data },
});

export const loadWorkerNotes = id => (dispatch, getState) => {
  const state = getState();
  const pageInfo = getWorkerNotesPaginationData(state);
  const pagingVars = dispatch(getWorkerNotesPagingVars(pageInfo, 0));

  dispatch(setIsWorkerNotesLoading(true));
  return ApolloClient.query({
    query: FetchWorkerNotesQuery,
    variables: {
      workerId: id,
      ...pagingVars,
    },
  })
    .then(({ data: { workerNotes } }) => {
      dispatch(setWorkerNotesListData(flattenGraphQLArray(workerNotes), "data"));
      dispatch(
        pagination.updatePageInfo(paginationSettings.workerNotes, {
          ...workerNotes.pageInfo,
          totalCount: workerNotes.totalCount,
        }),
      );
      dispatch(pagination.doneLoading(paginationSettings.workerNotes));
      dispatch(setIsWorkerNotesLoading(false));
    })
    .catch(e => {
      dispatch(setIsWorkerNotesLoading(false));
      dispatch(MetaActions.errorToast(`Could not get position information: ${e.message}`));
    });
};

export const fetchWorkerNotes = pageIndex => (dispatch, getState) => {
  const state = getState();
  const pageInfo = getWorkerNotesPaginationData(state);
  const pagingVars = dispatch(getWorkerNotesPagingVars(pageInfo, pageIndex));

  dispatch(setIsWorkerNotesLoading(true));
  return ApolloClient.query({
    query: FetchWorkerNotesQuery,
    variables: {
      workerId: state.workerDetails.worker.id,
      ...pagingVars,
    },
  })
    .then(({ data: { workerNotes } }) => {
      dispatch(setWorkerNotesListData(flattenGraphQLArray(workerNotes), "data"));

      dispatch(
        pagination.updatePageInfo(paginationSettings.workerNotes, {
          ...workerNotes.pageInfo,
          totalCount: workerNotes.totalCount,
        }),
      );
      dispatch(pagination.doneLoading(paginationSettings.workerNotes));
      dispatch(setIsWorkerNotesLoading(false));
    })
    .catch(e => {
      dispatch(setIsWorkerNotesLoading(false));
      dispatch(MetaActions.errorToast(`Could not get position information: ${e.message}`));
    });
};


export const fetchWorkerBannedFrom = (pageIndex, workerId) => (dispatch, getState) => {
  const state = getState();
  const pageInfo = getWorkerBannedFromPaginationData(state);
  const pagingVars = dispatch(getBannedFromPagingVars(pageInfo, pageIndex));
  dispatch(setIsWorkerBannedFromLoading(true));
  return ApolloClient.query({
    query: FetchWorkerBannedFromQuery,
    variables: {
      bannedWorkerId: workerId,
      ...pagingVars,
    },
  })
    .then(({ data: { employers } }) => {
      dispatch(setWorkerBannedFromListData(flattenGraphQLArray(employers), "data"));

      dispatch(
        pagination.updatePageInfo(paginationSettings.workerBannedFrom, {
          ...employers.pageInfo,
          totalCount: employers.totalCount,
        }),
      );
      dispatch(pagination.doneLoading(paginationSettings.workerBannedFrom));
      dispatch(setIsWorkerBannedFromLoading(false));
    })
    .catch(e => {
      dispatch(setIsWorkerBannedFromLoading(false));
      dispatch(MetaActions.errorToast(`Could not get employer information: ${e.message}`));
    });
};

export const fetchPositionsList = pageIndex => (dispatch, getState) => {
  const state = getState();
  const { worker } = getWorkerDetails(state);
  const pageInfo = getPositionsPaginationData(state);
  const pagingVars = dispatch(getPositionsPagingVars(pageInfo, pageIndex));
  dispatch(setIsPositionsLoading(true));
  return ApolloClient.query({
    query: FetchPositionsQuery,
    variables: {
      workerId: worker.id,
      ...pagingVars,
    },
  })
    .then(({ data: { positions } }) => {
      dispatch(setPositionListData(flattenGraphQLArray(positions), "data"));
      dispatch(
        pagination.updatePageInfo(paginationSettings.positions, {
          ...positions.pageInfo,
          totalCount: positions.totalCount,
        }),
      );
      dispatch(pagination.doneLoading(paginationSettings.positions));
      dispatch(setIsPositionsLoading(false));
    })
    .catch(e => {
      dispatch(setIsPositionsLoading(false));
      dispatch(MetaActions.errorToast(`Could not get position information: ${e.message}`));
    });
};

export const fetchWorker = id => (dispatch, getState) => {
  const state = getState();
  const pageInfo = getPositionsPaginationData(state);
  const pagingVars = dispatch(getPositionsPagingVars(pageInfo, 0));
  // dispatch(setIsPageLoading(true));
  dispatch(setIsDCTransactionLoading(true));
  return ApolloClient.query({
    query: FetchWorkerQuery,
    variables: {
      id: id,
      ...pagingVars,
    },
  })
    .then(({ data: { worker, positions } }) => {
      const flattenedPositions = flattenGraphQLArray(positions);
      if(worker.dcCustomerNumber){
        dispatch(fetchWorkerDCTransaction(worker.dcCustomerNumber))
      } else {
        dispatch(setIsDCTransactionLoading(false));
      }
      dispatch(setWorker(worker));
      dispatch(fetchWorkTypes());
      dispatch(fetchTransportation());
      dispatch(setPositionListData(flattenedPositions, "data"));
      dispatch(
        pagination.updatePageInfo(paginationSettings.positions, {
          ...positions.pageInfo,
          totalCount: positions.totalCount,
        }),
      );
      dispatch(pagination.doneLoading(paginationSettings.positions));
      dispatch(setIsPageLoading(false));
    })
    .catch(e => {
      dispatch(setIsPageLoading(false));
      dispatch(setIsDCTransactionLoading(false));
      dispatch(MetaActions.errorToast(`Could not get worker information: ${e.message}`));
    });
};

export const setPositionListSort = sort => dispatch => {
  dispatch({
    type: types.SET_POSITIONS_LIST_DATA,
    payload: {
      data: sort,
      part: "sort",
    },
  });

//   return dispatch(fetchPositionsList());
};

export const setPositionListFilter = filter => dispatch => {
  dispatch(setPositionListData(filter, "filter"));
//   dispatch(fetchPositionsList());
};

export const updateWorkerNote = note => ({ type: types.SET_WORKER_NOTE, payload: note });
export const resetWorkerNote = () => ({ type: types.RESET_WORKER_NOTE });

export const saveWorkerNote = () => (dispatch, getState) => {
  const state = getState();
  const { workerNote, worker } = getWorkerDetails(state);
  dispatch(setIsApplyingAction(true));
  return ApolloClient.mutate({
    mutation: AddWorkerNoteMutation,
    variables: {
      data: {
        workerId: worker.id,
        note: workerNote,
      },
    },
  })
    .then(() => {
      dispatch(fetchWorkerNotes());
      dispatch(
        MetaActions.successToast(`A custom note has been added to ${worker.firstName}'s profile.`),
      );
    })
    .catch(() => {
      dispatch(
        MetaActions.errorToast(`Could not add a custom note to ${worker.firstName}'s profile`),
      );
    })
    .then(() => {
      dispatch(setIsApplyingAction(false));
      dispatch(resetWorkerNote());
    });
};

export const updateWorkerTags = note => ({ type: types.SET_WORKER_TAGS, payload: note });
export const resetWorkerTags = () => ({ type: types.RESET_WORKER_TAGS });
export const saveWorkerTags = () => (dispatch, getState) => {
  const state = getState();
  const { workerTags, worker } = getWorkerDetails(state);
  dispatch(setIsApplyingAction(true));
  return ApolloClient.mutate({
    mutation: AddWorkerTagsMutation,
    variables: {
      workerId: worker.id,
      tags: workerTags,
    },
  })
    .then(({ data, errors }) => {
      if (!data && errors.length > 0) {
        throw new Error(errors[0].message);
      } else {
        dispatch(fetchWorker(worker.id));
        dispatch(
          MetaActions.successToast(`A tag has been added to ${worker.firstName}'s profile.`),
        );
      }
    })
    .catch((e) => {
      dispatch(
        MetaActions.errorToast(`${e.message}`),
      );
    })
    .then(() => {
      dispatch(setIsApplyingAction(false));
      dispatch(resetWorkerNote());
    });
};
export const removeWorkerTags = (tag) => (dispatch, getState) => {
  const state = getState();
  const { worker } = getWorkerDetails(state);
  dispatch(setIsApplyingAction(true));
  return ApolloClient.mutate({
    mutation: RemoveWorkerTagsMutation,
    variables: {
      workerId: worker.id,
      tags: [tag],
    },
  })
    .then(() => {
      dispatch(fetchWorker(worker.id));
      dispatch(
        MetaActions.successToast(`A tag has been remove from ${worker.firstName}'s profile.`),
      );
    })
    .catch(() => {
      dispatch(
        MetaActions.errorToast(`Could not remove a tags to ${worker.firstName}'s profile`),
      );
    })
    .then(() => {
      dispatch(setIsApplyingAction(false));
      dispatch(resetWorkerNote());
    });
};

export const onDeleteWorkerAccount = () => (dispatch, getState) => {
  const state = getState();
  const { worker } = getWorkerDetails(state);
  dispatch(setIsApplyingAction(true));
  return ApolloClient.mutate({
    mutation: RemoveWorkerMutation,
    variables: {
      id: worker.id
    },
  })
    .then(({ data, errors }) => {
      if (!data && errors.length > 0) {
        throw new Error(errors[0].message);
      } else {
        dispatch(
          MetaActions.successToast(`${worker.firstName}'s account deleted successfully.`),
        );
        dispatch(setIsApplyingAction(false));
        return true;
      }
    })
    .catch((e) => {
      dispatch(setIsApplyingAction(false));
      dispatch(
        MetaActions.errorToast(e.message),
      );
    })
};

export const suspendWorker = (duration, reason) => (dispatch, getState) => {
  const state = getState();
  const { worker } = getWorkerDetails(state);
  dispatch(setIsApplyingAction(true));
  return ApolloClient.mutate({
    mutation: SuspendWorkerMutation,
    variables: {
      data: {
        workerId: worker.id,
        duration,
        reason,
      },
    },
  })
    .then(({ data: { suspendWorker } }) => {
      dispatch(setWorker(suspendWorker));
      dispatch(setIsApplyingAction(false));
      dispatch(fetchWorkerNotes());
      dispatch(
        MetaActions.successToast(`${worker.firstName} has been suspended for ${duration} days`),
      );
    })
    .catch(e => {
      dispatch(setIsApplyingAction(false));
      dispatch(
        MetaActions.errorToast(
          `Could not suspend ${worker.firstName} for ${duration} days: ${e.message}`,
        ),
      );
    });
};

export const unsuspendWorker = reason => (dispatch, getState) => {
  const state = getState();
  const { worker } = getWorkerDetails(state);
  dispatch(setIsApplyingAction(true));
  return ApolloClient.mutate({
    mutation: UnsuspendWorkerMutation,
    variables: {
      data: { workerId: worker.id, reason },
    },
  })
    .then(({ data: { unsuspendWorker } }) => {
      dispatch(setWorker(unsuspendWorker));
      dispatch(setIsApplyingAction(false));
      dispatch(fetchWorkerNotes());
      dispatch(MetaActions.successToast(`${worker.firstName}'s suspension has been removed`));
    })
    .catch(e => {
      dispatch(setIsApplyingAction(false));
      dispatch(
        MetaActions.errorToast(`Could not remove ${worker.firstName}'s suspension: ${e.message}`),
      );
    });
};

export const verifyWorker = () => (dispatch, getState) => {
  const state = getState();
  const { worker } = getWorkerDetails(state);
  dispatch(setIsApplyingAction(true));
  return ApolloClient.mutate({
    mutation: VerifyWorkerMutation,
    variables: {
      data: { workerId: worker.id },
    },
  })
    .then(({ data: { verifyWorker } }) => {
      dispatch(setWorker(verifyWorker));
      dispatch(setIsApplyingAction(false));
      dispatch(fetchWorkerNotes());
      dispatch(MetaActions.successToast(`${worker.firstName} has been verified.`));
    })
    .catch(e => {
      dispatch(setIsApplyingAction(false));
      dispatch(MetaActions.errorToast(`Could not verify ${worker.firstName}: ${e.message}`));
    });
};

export const unverifyWorker = reason => (dispatch, getState) => {
  const state = getState();
  const { worker } = getWorkerDetails(state);
  dispatch(setIsApplyingAction(true));
  return ApolloClient.mutate({
    mutation: UnverifyWorkerMutation,
    variables: {
      data: { workerId: worker.id, reason },
    },
  })
    .then(({ data: { unverifyWorker } }) => {
      dispatch(setWorker(unverifyWorker));
      dispatch(setIsApplyingAction(false));
      dispatch(fetchWorkerNotes());
      dispatch(MetaActions.successToast(`${worker.firstName}'s information has been unverified.`));
    })
    .catch(e => {
      dispatch(setIsApplyingAction(false));
      dispatch(MetaActions.errorToast(`Could not unverify ${worker.firstName}: ${e.message}`));
    });
};

export const activateWorker = reason => (dispatch, getState) => {
  const state = getState();
  const { worker } = getWorkerDetails(state);
  dispatch(setIsApplyingAction(true));
  return ApolloClient.mutate({
    mutation: ActivateWorkerMutation,
    variables: {
      data: { workerId: worker.id, reason },
    },
  })
    .then(({ data: { activateWorker } }) => {
      dispatch(setWorker(activateWorker));
      dispatch(setIsApplyingAction(false));
      dispatch(fetchWorkerNotes());
      dispatch(
        MetaActions.successToast(
          `${worker.firstName} has been activated. They are now able to work.`,
        ),
      );
    })
    .catch(e => {
      dispatch(setIsApplyingAction(false));
      dispatch(MetaActions.errorToast(`Could not activate ${worker.firstName}: ${e.message}`));
    });
};

export const deactivateWorker = reason => (dispatch, getState) => {
  const state = getState();
  const { worker } = getWorkerDetails(state);
  dispatch(setIsApplyingAction(true));
  return ApolloClient.mutate({
    mutation: DeactivateWorkerMutation,
    variables: {
      data: { workerId: worker.id, reason },
    },
  })
    .then(({ data: { deactivateWorker } }) => {
      dispatch(setWorker(deactivateWorker));
      dispatch(setIsApplyingAction(false));
      dispatch(fetchWorkerNotes());
      dispatch(MetaActions.successToast(`${worker.firstName} has been deactivated.`));
    })
    .catch(e => {
      dispatch(setIsApplyingAction(false));
      dispatch(MetaActions.errorToast(`Could not deactivate ${worker.firstName}: ${e.message}`));
    });
};

export const preferWorker = (employerId, companyName) => (dispatch, getState) => {
  const state = getState();
  const { worker } = getWorkerDetails(state);
  dispatch(setIsApplyingAction(true));
  return ApolloClient.mutate({
    mutation: PreferWorkerMutation,
    variables: {
      data: { workerId: worker.id, employerId },
    },
  })
    .then(() => {
      dispatch(setIsApplyingAction(false));
      dispatch(fetchWorkerNotes());
      dispatch(
        MetaActions.successToast(
          `${worker.firstName} has been added to ${companyName}'s preferred list.`,
        ),
      );
    })
    .catch(e => {
      dispatch(setIsApplyingAction(false));
      dispatch(
        MetaActions.errorToast(
          `Could not add ${worker.firstName} to ${companyName}'s preferred list: ${e.message}`,
        ),
      );
    });
};

export const banWorker = (employerId, companyName, reason) => (dispatch, getState) => {
  const state = getState();
  const { worker } = getWorkerDetails(state);
  dispatch(setIsApplyingAction(true));
  return ApolloClient.mutate({
    mutation: BanWorkerMutation,
    variables: {
      data: {
        workerId: worker.id,
        employerId,
        reason,
      },
    },
  })
    .then(() => {
      dispatch(setIsApplyingAction(false));
      dispatch(
        MetaActions.successToast(`${worker.firstName} has been banned from ${companyName}.`),
      );
      dispatch(fetchWorkerBannedFrom(0, worker.id));
      dispatch(loadWorkerNotes(worker.id));
    })
    .catch(e => {
      dispatch(setIsApplyingAction(false));
      dispatch(
        MetaActions.errorToast(
          `Could not add ${worker.firstName} to ${companyName}'s banned list: ${e.message}`,
        ),
      );
    });
};

export const uploadWorkerProfileImage = (file, isProfile = true) => (dispatch, getState) => {
  const { worker } = getState().workerDetails;

  return ApolloClient.mutate({
    mutation: uploadFileToS3,
    variables: {
      isProfile,
      data: { workerId: worker.id },
      file: file,
    },
  })
    .then(() => {
      dispatch(fetchWorker(worker.id));
      dispatch(MetaActions.successToast(`${worker.firstName} has been updated.`));
      return; // data.createCustomRate;
    })
    .catch(e => {
      dispatch(MetaActions.errorToast(`Updating ${worker.firstName} has failed. ${e}`));
    });
};
export const fetchWorkerDCTransaction = (dcCustomerNumber) => async dispatch => {
  dispatch(setIsDCTransactionLoading(true));
  return ApolloClient.query({
    query: FetchWorkerDCTrancation,
    variables: {
      dcCustomerNumber
    }
  })
    .then(({ data, errors }) => {
      if (!data && errors.length > 0) {
        throw new Error(errors[0].message);
      }
      dispatch(setIsDCTransactionLoading(false));
      dispatch(setDCTransactionList(data.getWorkerDCTrancation));
    })
    .catch((error) => {
      dispatch(setIsDCTransactionLoading(false));
      console.log(error)
    });
};
