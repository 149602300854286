import { verifyEmail, verifyPassword } from "utils/stringUtils";

export const isAuthenticated = ({ login }) =>
  !!login.accessToken && new Date().getTime() < login.expiresAt;

export const getProfile = ({ login }) => login.profile;

export const getisValidLoginData = ({ login }) =>
  verifyEmail(login.email) && verifyPassword(login.password);

export const getRoles = ({ login }) => login.roles;
export const getTimezoneList = ({ login }) => login.timezoneList;
export const getPermissions = ({ login }) => login.permissions;
export const getMe = ({ login }) => login.me;
