import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { Redirect, Route, Switch } from "react-router-dom";
import PropTypes from "prop-types";
import withStyles from '@mui/styles/withStyles';
import "perfect-scrollbar/css/perfect-scrollbar.css";
import dashboardRoutes from "routes/dashboard.jsx";
import Header from "components/Header/Header.jsx";
import Sidebar from "components/Sidebar/Sidebar.jsx";
import { selectors as LoginSelectors } from "store/modules/login";
import LoginView from "../views/Login/LoginView";
import CanWrapper from "components/Authorization/CanWrapper";
class Dashboard extends React.Component {
    state = {
        openMenu: false
    }
    handleDrawerToggle = (data) => {
        this.setState({ openMenu: true });
    }
    handleClose = (data) => {
        this.setState({ openMenu: false });
    }
    render() {
        // eslint-disable-next-line react/prop-types
        const { classes, isAuthenticated, timezoneList, ...rest } = this.props;
        if (!isAuthenticated) {
            return <LoginView />;
        }
        // if (isAuthenticated && !isSubscribed) {
        //   subscribeToNotifications();

        return (
            <div className={classes.wrapper}>
                <Sidebar
                    routes={dashboardRoutes}
                    logo="https://s3-us-west-2.amazonaws.com/grizzly-force-public-assets/logo.png"
                    open={this.state.openMenu}
                    handleDrawerToggle={this.handleClose}
                    color="green"
                    bgColor="black"
                    timezoneList={timezoneList}
                    {...rest}
                />
                <div className={classes.mainPanel}>
                    <Header routes={dashboardRoutes} handleDrawerToggle={this.handleDrawerToggle} {...rest} />
                    <div className={classes.content}>
                        <div className={classes.container}>
                            <Switch>
                                {dashboardRoutes.map((prop, key) => {
                                    if (prop.redirect)
                                        return <Redirect from={prop.path} to={prop.pathTo} key={key} />;
                                    if (prop.collapse)
                                        return prop.views.map((prop, key) => {
                                            return <Route path={prop.path} render={(p) => (<CanWrapper perform={prop.permission}>
                                                <prop.component {...p} />
                                            </CanWrapper>
                                            )} key={key} />;
                                        });
                                    return <Route path={prop.path} render={(p) => (<CanWrapper perform={prop.permission}>
                                        <prop.component {...p} />
                                    </CanWrapper>
                                    )} key={key} />;
                                })}
                            </Switch>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

Dashboard.propTypes = {
    classes: PropTypes.object.isRequired,
    isAuthenticated: PropTypes.bool.isRequired,
};

const mapState = state => ({
    isAuthenticated: LoginSelectors.isAuthenticated(state),
    timezoneList: state.login.timezoneList,
});

const mapActions = {};

export default connect(
    mapState,
    mapActions
)(
    withStyles(theme => ({
        wrapper: {
            position: "relative",
            top: "0",
            height: "100vh",
            "&:after": {
                display: "table",
                clear: "both",
                content: '" "',
            },
        },
        mainPanel: {
            transitionProperty: "top, bottom, width",
            transitionDuration: ".2s, .2s, .35s",
            transitionTimingFunction: "linear, linear, ease",
            [theme.breakpoints.up("md")]: {
                width: "calc(100% - 220px)",
            },
            overflow: "auto",
            position: "relative",
            float: "right",
            transition: "all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1)",
            height: "100%",
            width: "100%",
            overflowScrolling: "touch",
        },
        content: {
            marginTop: "80px",
            minHeight: "calc(100vh - 80px)",
        },
        container: {
            marginRight: "auto",
            marginLeft: "auto",
            "&:before,&:after": {
                display: "table",
                content: '" "',
            },
            "&:after": {
                clear: "both",
            },
            minHeight: "calc(100vh - 80px)",
            display: "flex",
            flexDirection: "column",
        },
        map: {
            marginTop: "70px",
        },
        mainPanelWithPerfectScrollbar: {
            overflow: "hidden !important",
        },
    }))(withRouter(Dashboard))
);
