export default {
  RESET: "employerDetails/RESET",
  SET_LOADING_STATE: "employerDetails/SET_LOADING_STATE",
  SET_EMPLOYER: "employerDetails/SET_EMPLOYER",
  SET_MANAGER: "employerDetails/SET_MANAGER",
  SET_HOURS_TREND_DATA: "employerDetails/SET_HOURS_TREND_DATA",
  SET_WORKER_EXCEPTIONS_LIST_DATA: "employerDetails/SET_WORKER_EXCEPTIONS_LIST_DATA",
  SET_JOBS_LIST_DATA: "employerDetails/SET_JOBS_LIST_DATA",
  SET_EMPLOYER_NOTE: "employerDetails/SET_EMPLOYER_NOTE",
  RESET_EMPLOYER_NOTE: "employerDetails/RESET_EMPLOYER_NOTE",
  ADD_CHANGE: "employerDetails/ADD_CHANGE",
  EDIT_FORM_PENDING: "employerDetails/EDIT_FORM_PENDING",
  EDIT_FORM_SUCCESS: "employerDetails/EDIT_FORM_SUCCESS",
  SET_UP_EDIT_FORM: "employerDetails/SET_UP_EDIT_FORM",
  SET_UPDATE_UPDATE_EMPLOYER_DATA: "employerDetails/SET_UPDATE_UPDATE_EMPLOYER_DATA",
  SET_WORKER_PICKER_LIST_DATA: "employerDetails/SET_WORKER_PICKER_LIST_DATA",
  CLEAR_UPDATE_UPDATE_EMPLOYER_DATA: "employerDetails/CLEAR_UPDATE_UPDATE_EMPLOYER_DATA",
  SET_EMPLOYER_CUSTOM_RATES: "employerDetails/SET_EMPLOYER_CUSTOM_RATES",
  SET_UPDATE_EMPLOYER_CUSTOM_RATE_DATA: "employerDetails/SET_UPDATE_EMPLOYER_CUSTOM_RATE_DATA",
  SET_NEW_EMPLOYER_CUSTOM_RATE_DATA: "employerDetails/SET_NEW_EMPLOYER_CUSTOM_RATE_DATA",
  SET_EDIT_EMPLOYER_CUSTOM_RATE_DATA: "employerDetails/SET_EDIT_EMPLOYER_CUSTOM_RATE_DATA",
  CLEAR_UPDATE_EMPLOYER_CUSTOM_RATE_DATA: "employerDetails/CLEAR_UPDATE_EMPLOYER_CUSTOM_RATE_DATA",
  CLEAR_CREATE_EMPLOYER_CUSTOM_RATE_DATA: "employerDetails/CLEAR_CREATE_EMPLOYER_CUSTOM_RATE_DATA",
};
