import React, { Fragment, useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import moment from "moment-timezone";
import classNames from "classnames";
import Avatar from "@mui/material/Avatar";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import InfoOutlined from "@mui/icons-material/InfoOutlined";
import MonetizationOnTwoTone from "@mui/icons-material/MonetizationOnTwoTone";
import Remove from "@mui/icons-material/Remove";
import Skeleton from '@mui/material/Skeleton';
import { transferActionDialogStyles } from "views/Finance/helpers";
import Button from "components/Button/Button";
import { formatMoney } from "utils/stringUtils";
import { formatDurationFromMins } from "utils/time";
import { selectors as FinanceSelectors } from "store/modules/finance";
import { selectors as JobDetailSelectors } from "store/modules/jobDetails";
import ReactTableSimple from "components/ReactTable/ReactTableSimple";

function WarningTitle({ values }) {
  return (
    <>
      {values.map((v, i) => (
        <p key={i}>{v}</p>
      ))}
    </>
  );
}
function TransferActionDialog({ visible, onCancel, transferType }) {
  const classes = transferActionDialogStyles();
  const positions = useSelector(JobDetailSelectors.getPositions);
  const isInitializingPayroll = useSelector(JobDetailSelectors.getIsInitializingJobPayroll);
  const isPayingPosition = useSelector(FinanceSelectors.getIsSendingPay);
  let payrollSummary = useSelector(JobDetailSelectors.getPayrollSummary);
  const [, setIsPayingPayroll] = useState(isPayingPosition);
  if (isInitializingPayroll) {
    if (positions.length > 15) {
      payrollSummary = [{}, {}, {}, {}, {}];
    }
  }

  useEffect(() => {
    setIsPayingPayroll(isPayingPosition);
  }, [isPayingPosition]);

  const getWarningProps = useCallback(
    (_, { original }, column) => {
      const warning = original.warnings?.some(node => node.field === column.id);
      if (warning) {
        return { className: classes.warning };
      }
      return {};
    },
    [classes]
  );

  async function handleCancel() {
    // await dispatch(JobDetailActions.uninitializeJobPayroll());
    onCancel();
  }

  async function payPosition() {
    await onCancel();
  }

  const columns = [
    {
      // eslint-disable-next-line react/display-name, react/prop-types
      Header: () => {
        if (isInitializingPayroll) {
          return <Skeleton variant="text" />;
        }
        return null;
      },
      id: "profileImage",
      // eslint-disable-next-line react/display-name, react/prop-types
      accessor: ({ signedProfileImageUrl }) => {
        if (isInitializingPayroll) {
          return <Skeleton variant="circular" height={40} width={40} />;
        }
        return <Avatar src={signedProfileImageUrl} />;
      },
      disableSortby:true,
      disableFilter:true,
      width: 80,
    },
    {
      Header: () => {
        if (isInitializingPayroll) {
          return <Skeleton variant="text" />;
        }
        return "Worker";
      },
      id: "fullName",
      accessor: ({ fullName }) => {
        if (isInitializingPayroll) {
          return <Skeleton variant="text" />;
        }
        return fullName;
      },
      sortable: !isInitializingPayroll,
    },
    {
      Header: () => {
        if (isInitializingPayroll) {
          return <Skeleton variant="text" />;
        }
        return "Checked In";
      },
      id: "startShift",
      width: 150,
      accessor: ({ startShift, warnings }) => {
        if (isInitializingPayroll) {
          return <Skeleton variant="text" />;
        }
        const startWarning = warnings.find(node => node.field === "startShift");
        const start = moment(startShift).format("MMM Do h:mm a");
        if (startWarning) {
          return (
            <Tooltip title={<WarningTitle {...startWarning} />} placement="top">
              <span className={classes.warningContainer}>
                <InfoOutlined className={classes.warningIcon} fontSize="small" />
                {start}
              </span>
            </Tooltip>
          );
        }
        return start;
      },
      getProps: getWarningProps,
      sortable: !isInitializingPayroll,
    },
    {
      Header: () => {
        if (isInitializingPayroll) {
          return <Skeleton variant="text" />;
        }
        return "Checked Out";
      },
      id: "endShift",
      width: 160,
      accessor: ({ endShift, warnings }) => {
        if (isInitializingPayroll) {
          return <Skeleton variant="text" />;
        }
        const endWarning = warnings.find(node => node.field === "endShift");
        const end = moment(endShift).format("MMM Do h:mm a");
        if (endWarning) {
          return (
            <Tooltip title={<WarningTitle {...endWarning} />} placement="top">
              <span className={classes.warningContainer}>
                <InfoOutlined className={classes.warningIcon} fontSize="small" />
                {end}
              </span>
            </Tooltip>
          );
        }
        return end;
      },
      getProps: getWarningProps,
      sortable: !isInitializingPayroll,
    },
    {
      Header: () => {
        if (isInitializingPayroll) {
          return <Skeleton variant="text" />;
        }
        return "Break";
      },
      id: "breakMins",
      width: 90,
      accessor: ({ breakMins, warnings }) => {
        if (isInitializingPayroll) {
          return <Skeleton variant="text" />;
        }
        const breakWarning = warnings.find(node => node.field === "breakMins");
        const breakLabel =
          breakMins === null || breakMins === undefined ? "-" : `${breakMins} mins`;
        if (breakWarning) {
          return (
            <Tooltip title={<WarningTitle {...breakWarning} />} placement="top">
              <span className={classes.warningContainer}>
                <InfoOutlined className={classes.warningIcon} fontSize="small" />
                {breakLabel}
              </span>
            </Tooltip>
          );
        }
        return breakLabel;
      },
      getProps: getWarningProps,
      sortable: !isInitializingPayroll,
    },
    {
      Header: () => {
        if (isInitializingPayroll) {
          return <Skeleton variant="text" />;
        }
        return "Duration";
      },
      id: "durationMins",
      width: 130,
      accessor: ({ durationMins, warnings }) => {
        if (isInitializingPayroll) {
          return <Skeleton variant="text" />;
        }
        const durationWarning = warnings.find(node => node.field === "durationMins");
        const duration = !durationMins
          ? "-"
          : formatDurationFromMins(durationMins, {
              hourLabel: "hrs",
              minuteLabel: "mins",
              separator: "",
            });
        if (durationWarning) {
          return (
            <Tooltip title={<WarningTitle {...durationWarning} />} placement="top">
              <span className={classes.warningContainer}>
                <InfoOutlined className={classes.warningIcon} fontSize="small" />
                {duration}
              </span>
            </Tooltip>
          );
        }
        return duration;
      },
      getProps: getWarningProps,
      sortable: !isInitializingPayroll,
    },
    {
      Header: () => {
        if (isInitializingPayroll) {
          return <Skeleton variant="text" />;
        }
        return "Rate";
      },
      id: "payRate",
      width: 80,
      accessor: ({ payRate, warnings }) => {
        if (isInitializingPayroll) {
          return <Skeleton variant="text" />;
        }
        const rateWarning = warnings.find(node => node.field === "payRate");
        if (rateWarning) {
          return (
            <Tooltip title={<WarningTitle {...rateWarning} />} placement="top">
              <span className={classes.warningContainer}>
                <InfoOutlined className={classes.warningIcon} fontSize="small" />
                {formatMoney(payRate)}
              </span>
            </Tooltip>
          );
        }
        return formatMoney(payRate);
      },
      getProps: getWarningProps,
      sortable: !isInitializingPayroll,
    },
    {
      Header: () => {
        if (isInitializingPayroll) {
          return <Skeleton variant="text" />;
        }
        return "Bonus";
      },
      id: "bonus",
      width: 100,
      accessor: ({ bonus, warnings }) => {
        if (isInitializingPayroll) {
          return <Skeleton variant="text" />;
        }
        const bonusWarning = warnings.find(node => node.field === "bonus");
        const bonusLabel = !bonus ? <Remove style={{ marginLeft: 14 }} /> : formatMoney(bonus);
        if (bonusWarning) {
          return (
            <Tooltip title={<WarningTitle {...bonusWarning} />} placement="top">
              <span className={classes.warningContainer}>
                <InfoOutlined className={classes.warningIcon} fontSize="small" />
                {bonusLabel}
              </span>
            </Tooltip>
          );
        }
        return bonusLabel;
      },
      getProps: getWarningProps,
      //   show: showBonusColumn,
      sortable: !isInitializingPayroll,
    },
    {
      Header: () => {
        if (isInitializingPayroll) {
          return <Skeleton variant="text" />;
        }
        return "Gross";
      },
      id: "grossEarnings",
      width: 100,
      accessor: ({ grossEarnings, warnings }) => {
        if (isInitializingPayroll) {
          return <Skeleton variant="text" />;
        }
        const earningsWarning = warnings.find(node => node.field === "grossEarnings");
        if (earningsWarning) {
          return (
            <Tooltip title={<WarningTitle {...earningsWarning} />} placement="top">
              <span className={classes.warningContainer}>
                <InfoOutlined className={classes.warningIcon} fontSize="small" />
                {formatMoney(grossEarnings)}
              </span>
            </Tooltip>
          );
        }
        return formatMoney(grossEarnings);
      },
      sortable: !isInitializingPayroll,
      getProps: getWarningProps,
    },
    {
      Header: () => {
        if (isInitializingPayroll) {
          return <Skeleton variant="text" />;
        }
        return "Net";
      },
      id: "netEarnings",
      width: 100,
      accessor: ({ netEarnings, warnings }) => {
        if (isInitializingPayroll) {
          return <Skeleton variant="text" />;
        }
        const earningsWarning = warnings.find(node => node.field === "netEarnings");
        if (earningsWarning) {
          return (
            <Tooltip title={<WarningTitle {...earningsWarning} />} placement="top">
              <span className={classes.warningContainer}>
                <InfoOutlined className={classes.warningIcon} fontSize="small" />
                {formatMoney(netEarnings)}
              </span>
            </Tooltip>
          );
        }
        return formatMoney(netEarnings);
      },
      sortable: !isInitializingPayroll,
      getProps: getWarningProps,
    },
    // {
    //   Header: () => {
    //     if (isInitializingPayroll) {
    //       return <Skeleton variant="text" />;
    //     }
    //     return "Issues";
    //   },
    //   id: "positionId",
    //   show: false,
    //   width: 50,
    //   accessor: () => {
    //     if (isInitializingPayroll) {
    //       return <Skeleton variant="circular" />;
    //     }
    //     return (
    //       <Fragment>
    //         <Tooltip title={`Create payroll & Send eTransfer for this worker.`} placement="top">
    //           <MonetizationOnTwoTone className={classes.payIcon} />
    //         </Tooltip>
    //       </Fragment>
    //     );
    //   },
    //   sortable: false,
    // },
    {
      Header: () => {
        return "";
      },
      id: "positionId",
      width: 50,
      accessor: () => {
        if (isInitializingPayroll) {
          return <Skeleton variant="text" />;
        }
        return (
          <Fragment>
            <IconButton edge="end" onClick={() => payPosition()} size="large">
              <Tooltip title={`Create payroll & Send eTransfer for this worker.`} placement="top">
                <MonetizationOnTwoTone className={classes.payIcon} />
              </Tooltip>
            </IconButton>
          </Fragment>
        );
      },
      disableSortby:true,
    },
  ];
  return (
    <Fragment>
      <Dialog fullWidth maxWidth="md" classes={{ paper: classes.modal }} open={visible}>
        <DialogTitle className={classes.title}>
          <Grid container justifyContent="space-between">
            <Grid item>
              <Typography variant="h5" align="left">
                {`${
                  transferType.charAt(0).toUpperCase() + transferType.slice(1)
                } Payroll eTransfers `}
              </Typography>
              <Typography variant="h4" align="left">
                {/* {companyName} */}
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="subtitle1" align="right">
                {moment().format("dddd, MMMM Do, YYYY")}
              </Typography>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent style={{ padding: 8 }}>
          {payrollSummary ? (
            <ReactTableSimple
              classes={classNames("-striped", classes.table)}
              showPaginationBottom={false}
              filterable={false}
              showPageJump={false}
              showPaginationTop={false}
              resizable={false}
              showPageSizeOptions={false}
              minRows={0}
              defaultPageSize={105}
              data={payrollSummary}
              columns={columns}
            />
          ) : (
            "yo!"
          )}
        </DialogContent>

        <DialogActions className={classes.footer}>
          <Button label="Close" onClick={handleCancel} color="transparent" />
        </DialogActions>
      </Dialog>
    </Fragment>
  );
}
TransferActionDialog.propTypes = {
  visible: PropTypes.bool,
  onCancel: PropTypes.func,
  onConfirm: PropTypes.func,
};
TransferActionDialog.defaultProps = {
  visible: false,
  onCancel: () => {},
  onConfirm: () => {},
};
export default TransferActionDialog;
