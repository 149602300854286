import gql from "graphql-tag";

export default gql`
mutation($data: EditJobTemplateInput!) {
    editJobTemplate(data: $data) {
      id
      templateName
      isActive
      id
      templateName
      employerId
      managerId
      workTypeId
      peopleNeeded
      street
      city
      region
      unit
      floor
      description
      postalCode
      isActive
      breakMins
      latitude
      longitude
      locationInstruction
      gears
      skills
      employer {
        id
        firstName
        lastName
      }
      manager {
        id
        firstName
        lastName
      }
      workType {
        id
        label
      }
    }
  }
` 