// HelperMessage
import React from "react";
import PropTypes from "prop-types";
import ChevronRight from "@mui/icons-material/ChevronRight";
import { summaryFooterStyles } from "views/Employer/helpers";
import Button from "components/Button/Button";

function SummaryFooter({ canSubmitNewEmployerForm, registerEmployer, classes, isInfoLoading }) {
  return (
    <div className={classes.footer}>
      <Button
        className={classes.button}
        // icon={<ChevronRight />}
        label="Create"
        alignIcon="right"
        onClick={registerEmployer}
        disabled={!canSubmitNewEmployerForm}
        isLoading={isInfoLoading}
      />
    </div>
  );
}

SummaryFooter.propTypes = {
  classes: PropTypes.object.isRequired,
  registerEmployer: PropTypes.func,
  totalCost: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  registerWorker: PropTypes.func,
  canSubmitNewEmployerForm: PropTypes.bool,
};

SummaryFooter.defaultProps = {
  totalCost: 0,
  registerWorker: () => {},
  canSubmitNew: false,
};

export default summaryFooterStyles(SummaryFooter);
