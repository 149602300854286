import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Grid from "@mui/material/Grid";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { jobsWithPendingPayStyles } from "views/Finance/helpers";
import Loader from "components/Loader/Loader";

import { selectors as FinanceSelectors } from "store/modules/finance";
import OverviewTableActions from "../../../Menus/OverviewTableActions";
import { convertUTCToLocal, convertUTCToTimezoneWithOffset } from "../../../../../../../utils/time";
import ReactTableSimple from "components/ReactTable/ReactTableSimple";

function JobsWithPendingPayTable({
    //   paymentsOverview,
    toggleReviewingPayroll,
}) {
    const classes = jobsWithPendingPayStyles();

    const [, setIsReviewingPayroll] = useState(true);

    const paymentsOverview = useSelector(FinanceSelectors.getPaymentsOverview);
    useEffect(() => { }, [paymentsOverview]);

    const RenderJobsWithPendingTable = () => {
        const columns = [
            {
                Header: "Employer",
                sortable: true,
                id: "CompanyName",
                accessor: ({ CompanyName }) => CompanyName,
                styles: { cursor: "pointer", minWidth: 160, },
                Cell: ({ row: { original: { CompanyName } } }) => CompanyName,
            },
            {
                Header: "Job",
                show: true,
                accessor: ({ jobId }) => jobId,
                sortable: true,
                id: "jobId",
                styles: { cursor: "pointer", minWidth: 80, },
                Cell: ({ row: { original: { jobId } } }) => jobId,
            },
            {
                Header: "Paid",
                sortable: true,
                filterable: false,
                show: true,
                id: "paid",
                headerStyle: { cursor: "pointer", textAlign: "center" },
                styles: { cursor: "pointer", textAlign: "center", minWidth: 80, },
                accessor: ({ paid }) => paid,
                Cell: ({ row: { original: { paid } } }) => (
                    <Tooltip title="Job positions already paid.">
                        <Typography variant="body2" color={"inherit"}>
                            {paid}
                        </Typography>
                    </Tooltip>
                ),
            },
            {
                Header: "Ready",
                sortable: true,
                filterable: false,
                id: "ready",
                headerStyle: { cursor: "pointer", textAlign: "center" },
                styles: { cursor: "pointer", textAlign: "center", minWidth: 60, },
                accessor: ({ ready }) => ready,
                Cell: ({ row: { original: { ready } } }) => (
                    <Tooltip title="Job positions that are ready for payment.">
                        <Typography variant="body2" color={"inherit"}>
                            {ready}
                        </Typography>
                    </Tooltip>
                ),
            },
            {
                Header: "Date",
                sortable: true,
                id: "StartDateUtc",
                accessor: ({ StartDateUtc }) => StartDateUtc,
                styles: { cursor: "pointer", minWidth: 125, },
                Cell: ({ row: { original: { StartDateUtc } } }) => {
                    return (
                        <Tooltip title="The date of the job.">
                            <Typography variant="body2" color={"inherit"}>
                                {convertUTCToTimezoneWithOffset(StartDateUtc, '', 'MMM Do, YYYY')}
                            </Typography>
                        </Tooltip>
                    )
                },
            },
            {
                Header: "Shift",
                sortable: true,
                id: "startDate",
                accessor: ({ StartDateUtc }) => StartDateUtc,
                styles: { minWidth: 125, cursor: "pointer" },
                Cell: ({ row: { original: { StartDateUtc, EndDateUtc } } }) =>
                    `${convertUTCToLocal(StartDateUtc, "h:mm a")} - ${convertUTCToTimezoneWithOffset(EndDateUtc, '', "h:mm a")}`,
            },

            {
                Header: "",
                id: "actions",
                collapse: true,
                styles: { cursor: "pointer" },
                Cell: ({ row: { original } }) => (
                    <OverviewTableActions
                        position={original}
                        toggleReviewingPayroll={toggleReviewingPayroll}
                        setIsReviewingPayroll={setIsReviewingPayroll}
                        showPayrollSummary={true}
                    />
                ),

            },
        ];
        const RenderTdProps = () => ({
            onClick: (e, handleOriginal) => {
                if (handleOriginal) {
                    handleOriginal();
                }
            },
            onMouseEnter: (e, handleOriginal) => {
                if (handleOriginal) {
                    handleOriginal();
                }
            },
            onMouseLeave: (e, handleOriginal) => {
                if (handleOriginal) {
                    handleOriginal();
                }
            },
        });

        return (
            <ReactTableSimple
                showPaginationBottom
                filterable={false}
                showPageJump={false}
                showPaginationTop={false}
                resizable={false}
                classes={classes.table}
                minRows={0}
                defaultPageSize={10}
                data={paymentsOverview.jobsPending}
                getTdProps={(state, row, col) => RenderTdProps(state, row, col)}
                defaultFilterMethod={(filter, row) => String(row[filter.id]) === filter.value}
                NoDataComponent={() => (
                    <Typography
                        variant="subtitle2"
                        className="rt-noData"
                        style={{
                            marginTop: 10,
                            width: "100%",
                            backgroundColor: "#fff",
                            textAlign: "center",
                        }}>{`No Results Found`}</Typography>
                )}
                //   SubComponent={row => <RenderSubComponent data={row.original} />}
                columns={columns}
            />
        );
    };

    const RenderJobsWithPendingPay = useCallback(
        () => (
            <Grid item xs={12} className={classes.container}>
                <Grid container justifyContent="flex-start">
                    <Grid item xs={12}>
                        <Grid container direction="row" justifyContent="flex-start" style={{ marginBottom: 6 }}>
                            <Typography variant="h6" className={classes.mobileH5}>
                                Jobs With Pending Payroll
                            </Typography>
                            <Typography variant="caption">
                                Jobs with positions that are completed and are waiting for payment.
                            </Typography>
                        </Grid>
                        <Grid container direction="row" justifyContent="flex-start">
                            {paymentsOverview.jobsPending ? <RenderJobsWithPendingTable /> : <Loader />}
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        ),
        [paymentsOverview.jobsPending, classes.container, classes.mobileH5]
    );

    return <RenderJobsWithPendingPay />;
}

export default JobsWithPendingPayTable;
