import { gql } from "@apollo/client";
import EmployerFields from "../fragments/employer-fragment";
import JobFields from "../fragments/job-fragment";
// import PositionFields from "../fragments/position-fragment";

export default gql`
  query getJob($id: ID!, $timezone: String!) {
    job(id: $id, timezone: $timezone) {
      ...JobFields
      employer {
        ...EmployerFields
      }
      positions {
        id
        isEditable
        isPayable
        isDeletable
        checkedInBy
        checkedOutBy
        addedBy
        payrollStatus
        payroll {
          id
          createdAt
          payrollTypeId
          positionId
          taxRegionId
          employmentStandardsRegionId
          rate
          checkIn
          checkOut
          breakDeductionMins
          bonus
          totalUnits
          payableUnits
          payrollType {
            id
            name
            code
          }
          payments {
            paymentStatus {
              id
              code
              description
            }
            id
            createdAt
            sentAt
            responseAt
            workerId
            payrollId
            positionId
            retryPaymentId
            paymentStatusId
            # dcTransferId
            paymentProviderReferenceCode
            email
            securityQuestion
            securityAnswer
            # amount
            errorCode
            errorDescription
            notes
          }
          earnings {
            id
            payrollId
            payrollEarningsTypeId
            rate
            units
            amount
          }
          wageEarnings {
            id
            payrollId
            payrollEarningsTypeId
            rate
            units
            amount
          }
          additionalEarnings {
            id
            payrollId
            payrollEarningsTypeId
            rate
            units
            amount
          }
          employeeDeductions {
            id
            payrollId
            amount
            payrollDeductionsTypeId
          }
          employerDeductions {
            id
            payrollId
            amount
            payrollDeductionsTypeId
          }
          totalEmployeeDeductions
          totalEmployerDeductions
          grossWages
          grossEarnings
          netEarnings
        }
        createdAt
        billingRate
        payRate
        createdAtUtc
        employerRating
        workerRating
        startShift
        endShift
        startShiftUtc
        endShiftUtc
        workerId
        defaultWorkerRating
        breakMins
        durationMins
        bonus
        confirmedAt
        confirmedAtUtc
        isReconfirmed
        isConfirmed
        reconfirmedAt
        reconfirmedAtUtc
      }
    }
  }
  ${JobFields}
  ${EmployerFields}
`;
// #   ${PositionFields}
