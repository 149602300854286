import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import makeStyles from '@mui/styles/makeStyles';
import { actions as FaqActions, selectors as FaqSelectors } from "store/modules/faqs";
const useStyles = makeStyles(() => ({
  label: {
    marginLeft: 0,
    color: '#000',
    justifyContent: 'start'
  },
}));
function AdminSetting() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const adminSettingData = useSelector(FaqSelectors.getAdminSetting);
  const isLoading = useSelector(FaqSelectors.getIsAdminSettingSaveLoading);
  useEffect(() => {
    dispatch(FaqActions.fetchAdminSetting());
  }, []);

  const handleChange = (id, event) => {
    // adminSettingData.forEach(n => {
    //   if(n.id == id) {
    //     n.value = event.target.checked
    //   }
    // });
    dispatch(FaqActions.updateAdminSettings(id, event.target.checked));
  }

  return (
    <React.Fragment>
      {adminSettingData.map(n => {
        if(n.slug === 'onboarding') {
          return (
            <FormControlLabel
              className={classes.label}
              key={n.id}
              disabled={isLoading}
              control={<Switch checked={n.value} onChange={(event) => handleChange(n.id, event)} name="antoine" />}
              label="Required Onboarding"
              labelPlacement="start"
            />
            )
        }
      })}

    </React.Fragment>
  );
}

export default AdminSetting;