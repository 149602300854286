import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import CircularProgress from "@mui/material/CircularProgress";
import Grid from "@mui/material/Grid";
import withStyles from '@mui/styles/withStyles';
import Typography from "@mui/material/Typography";

const styles = () => ({
  header: {},
  progress: {
    color: "#11b2a8",
    position: "absolute",
    top: "32%",
    left: "37%",
  },
});

function WaitlistTabButton({ classes, className, isLoading, title, count, headerClass }) {
  return (
    <Grid
      className={className}
      container
      direction="column"
      alignItems="flex-start"
      style={{ height: 72 }}>
      <Typography
        className={classNames(classes.header, headerClass)}
        variant="overline"
        color="textSecondary"
        style={{ display: "block" }}>
        {title}
      </Typography>
      <Typography variant="h4">
        {isLoading && title !== "Total" ? (
          <CircularProgress classes={{ root: classes.progress }} />
        ) : (
          count
        )}
      </Typography>
    </Grid>
  );
}

WaitlistTabButton.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  headerClass: PropTypes.string,
  title: PropTypes.string,
  date: PropTypes.string,
  count: PropTypes.node,
  isLoading: PropTypes.bool,
};

WaitlistTabButton.defaultProps = {
  className: null,
  headerClass: null,
  labelClass: null,
  title: "",
  count: "",
  isLoading: false,
};

export default withStyles(styles)(WaitlistTabButton);
