export default {
  SET_FAQS: "settings/SET_FAQS",
  SET_FAQ_LIST: "settings/SET_FAQ_LIST",
  UPDATE_FAQ: "settings/UPDATE_FAQ",
  UPDATE_FAQ_EDIT_DATA: "settings/UPDATE_FAQ_EDIT_DATA",
  CREATE_FAQ: "settings/CREATE_FAQ",
  IS_LOADING_INFO: "settings/IS_LOADING_INFO",
  IS_LOADING_TERMS_OF_SERVICE: "settings/IS_LOADING_TERMS_OF_SERVICE",
  SET_FAQ_EDIT_DATA: "settings/SET_FAQ_EDIT_DATA",
  SET_FAQ_CREATE_DATA: "settings/SET_FAQ_CREATE_DATA",
  RESET_FAQ_CREATE_DATA: "settings/RESET_FAQ_CREATE_DATA",
  SET_TERMS_OF_SERVICE_DATA: "settings/SET_TERMS_OF_SERVICE_DATA",
  SET_LOADING_STATE: "settings/SET_LOADING_STATE",
  SET_ADMIN_SETTING: "settings/SET_ADMIN_SETTING",
};
