import React from "react";
import { connect } from "react-redux";
import CircularProgress from "@mui/material/CircularProgress";
import withStyles from '@mui/styles/withStyles';
import TextField from "@mui/material/TextField";
import { Add, Contacts, Policy, RecentActors, Settings } from "@mui/icons-material";
import Button from "components/Button/Button";
import MainContent from "components/Containers/MainContent";
import Dialog from "components/Dialog/Dialog";
import SubNav from "components/Navigation/SubNav";
import SubNavTab from "components/Navigation/SubNavTab";
import { actions as FaqActions } from "store/modules/faqs";
import FaqTab from "./FaqView/components/FaqTab";
import TermsOfService from "./TermsOfService/TermsOfService";
import AdminSetting from "./components/AdminSetting";

const style = ({ spacing, palette }) => ({
  container: {
    marginTop: spacing(2),
  },
  progress: {
    color: palette.secondary.light,
    position: "absolute",
    top: "50%",
    left: "45%",
  },
});

class SettingsOverview extends React.Component {
  state = {
    value: 0,
    deleteFaqId: null,
    editFaqId: null,
    showCreateFaqDialog: false,
    showDeleteFaqDialog: false,
    showEditFaqDialog: false,
  };

  componentDidMount() {
    this.props.fetchFaqs();
  }

  handleTabChange = (event, value) => {
    this.setState({ value });
  };

  // Create dialog
  showCreateFaq = () => {
    this.setState({
      showCreateFaqDialog: true,
    });
  };

  hideCreateFaq = () => {
    this.setState({
      showCreateFaqDialog: false,
    });
    this.props.resetFaqCreateData();
  };

  // Delete dialog
  showDeleteFaqDialog = deleteFaqId => {
    this.setState({
      showDeleteFaqDialog: true,
      deleteFaqId,
    });
  };

  hideDeleteFaqDialog = () => {
    this.setState({
      showDeleteFaqDialog: false,
      deleteFaqId: null,
    });
  };

  handleDeleteFaq = () => {
    const faqId = this.state.deleteFaqId;
    this.setState(
      {
        showDeleteFaqDialog: false,
        deleteFaqId: null,
      },
      () => {
        this.props.deleteFaq(faqId, this.faqType);
      }
    );
  };

  // Edit dialog
  showEditFaqDialog = (editFaqId, faqListType) => {
    this.props.setFaqEditableData(editFaqId, faqListType);

    this.setState({
      showEditFaqDialog: true,
      editFaqId,
    });
  };

  hideEditFaqDialog = () => {
    this.setState({
      showEditFaqDialog: false,
      editFaqId: null,
    });
  };

  handleEditFaq = () => {
    const faqId = this.state.editFaqId;
    this.setState(
      {
        showEditFaqDialog: false,
        editFaqId: null,
      },
      () => {
        this.props.updateFaq(faqId);
      }
    );
  };

  handleSort = (id, toggledValue, faqListType) => {
    this.props.toggleFaqSortOrder(id, toggledValue, faqListType);
  };

  getCreateInputProps = field => ({
    value: this.props.newFaqData[field],
    onChange: event => {
      this.props.updateCreateFaqData(field, event.target.value);
    },
  });

  getEditInputProps = field => ({
    value: this.props.editFaqData[field],
    onChange: event => {
      this.props.updateFaqData(field, event.target.value);
    },
  });

  createFaq = () => {
    this.setState(
      {
        showCreateFaqDialog: false,
      },
      () => {
        this.props.createFaq({ type: this.state.value ? "Employer" : "Worker" });
      }
    );
  };

  render() {
    const { classes, employerFaqs, workerFaqs, canCreateFaq, canEditFaq, isLoading } = this.props;

    const viewableList = this.state.value === 0 ? workerFaqs : employerFaqs;
    if (isLoading && !viewableList.length) {
      return <CircularProgress classes={{ root: classes.progress }} />;
    }

    return (
      <React.Fragment>
        <SubNav
          value={this.state.value}
          onChange={this.handleTabChange}
          tabs={[
            <SubNavTab key="workerFaqs" label="Worker FAQs" icon={<RecentActors />} count={null} />,
            <SubNavTab key="employerFaqs" label="Employer FAQs" icon={<Contacts />} count={null} />,
            <SubNavTab key="tc" label="Terms of Service" icon={<Policy />} count={null} />,
            // <SubNavTab key="settings" label="Settings" icon={<Settings />} count={null} />,
          ]}
        />

        <MainContent>
          {this.state.value < 2 && (
            <Button
              style={{ alignSelf: "flex-end", marginBottom: 24 }}
              label="New FAQ"
              icon={<Add />}
              alignIcon="left"
              onClick={this.showCreateFaq}
            />
          )}
          {(this.state.value === 0 || this.state.value === 1) && (
            <FaqTab
            faqListType={this.state.value === 0 ? "workerFaqs" : "employerFaqs"}
            faqs={viewableList}
            onDelete={this.showDeleteFaqDialog}
            onEdit={this.showEditFaqDialog}
            onStatusToggle={this.props.toggleFaqStatus}
            onSort={this.handleSort}
            tabIndex={this.state.value}
          />
          )}
          {this.state.value === 2 && <TermsOfService />}
          {this.state.value === 3 && <AdminSetting />}
        </MainContent>
        <Dialog
          open={this.state.showCreateFaqDialog}
          title={`Add ${this.state.value ? "Employer" : "Worker"} FAQ`}
          description={null}
          confirmText="Add FAQ"
          onClose={this.hideCreateFaq}
          onConfirm={this.createFaq}
          onCancel={this.hideCreateFaq}
          alertType="form"
          disabled={!canCreateFaq}>
          <div className={classes.container}>
            <TextField
              id="question"
              label="Question"
              margin="normal"
              placeholder="Type your question..."

              fullWidth
              multiline
              rows="3"
              {...this.getCreateInputProps("question")}
            />
            <TextField
              id="answer"
              label="Answer"
              margin="normal"
              placeholder="Type your answer..."
              fullWidth

              multiline
              rows="4"
              {...this.getCreateInputProps("answer")}
            />
          </div>
        </Dialog>
        <Dialog
          open={this.state.showDeleteFaqDialog}
          icon="help_outline"
          title="Are You Sure?"
          description="You are about to delete this FAQ?"
          confirmText="Yes"
          onClose={this.hideDeleteFaqDialog}
          onConfirm={this.handleDeleteFaq}
          onCancel={this.hideDeleteFaqDialog}
          alertType={"warning"} />
        <Dialog
          open={this.state.showEditFaqDialog}
          icon="help_outline"
          title={`Edit ${this.state.value ? "Employer" : "Worker"} FAQ`}
          description={null}
          confirmText="Update FAQ"
          onClose={this.hideEditFaqDialog}
          onConfirm={this.handleEditFaq}
          onCancel={this.hideEditFaqDialog}
          alertType="form"
          disabled={!canEditFaq}>
          <div className={classes.container}>
            <TextField
              id="question"
              label="Question"
              margin="normal"
              placeholder="Type your question..."

              fullWidth
              multiline
              rows="3"
              {...this.getEditInputProps("question")}
            />
            <TextField
              id="answer"
              label="Answer"
              margin="normal"
              placeholder="Type your answer..."
              fullWidth
              multiline

              rows="4"
              {...this.getEditInputProps("answer")}
            />
          </div>
        </Dialog>
      </React.Fragment>
    );
  }
}

const mapState = state => {
  const {
    employerFaqs,
    workerFaqs,
    newFaqData,
    editFaqData,
    isInfoLoading: isLoading,
  } = state.faqs;

  const canCreateFaq = newFaqData.question && newFaqData.question.length >= 1 && newFaqData.answer && newFaqData.answer.length >= 1;
  const canEditFaq = editFaqData.question && editFaqData.question.length >= 1 && editFaqData.answer && editFaqData.answer.length >= 1;
  return {
    isLoading,
    employerFaqs,
    workerFaqs,
    newFaqData,
    canCreateFaq,
    editFaqData,
    canEditFaq,
  };
};

const mapActions = {
  fetchFaqs: FaqActions.fetchFaqs,
  setFaqEditableData: FaqActions.setFaqEditableData,
  updateCreateFaqData: FaqActions.updateCreateFaqData,
  resetFaqCreateData: FaqActions.resetFaqCreateData,
  createFaq: FaqActions.createFaq,
  toggleFaqSortOrder: FaqActions.toggleFaqSortOrder,
  updateFaqData: FaqActions.updateFaqData,
  updateFaq: FaqActions.updateFaq,
  deleteFaq: FaqActions.deleteFaq,
  toggleFaqStatus: FaqActions.toggleFaqStatus,
};

export default connect(mapState, mapActions)(withStyles(style)(SettingsOverview));
