import { gql } from "@apollo/client";

// first is zero because we only want to return the totals and no specific info
export default gql`
  fragment StatsFields on JobStats {
    count
    bookings
    revenue
    hours
  }

  query getJobListStats($timeframe: Timeframes!, $timezone: String) {
    total: jobsStats(timeframe: $timeframe, isActive: true, timezone: $timezone) {
      ...StatsFields
    }
    active: jobsStats(
      timeframe: $timeframe
      status: InProgress
      isActive: true
      timezone: $timezone
    ) {
      ...StatsFields
    }
    unfilled: jobsStats(
      timeframe: $timeframe
      status: NotStarted
      isActive: true
      filled: false
      timezone: $timezone
    ) {
      ...StatsFields
    }
    filled: jobsStats(
      timeframe: $timeframe
      status: NotStarted
      isActive: true
      filled: true
      timezone: $timezone
    ) {
      ...StatsFields
    }
    completed: jobsStats(
      timeframe: $timeframe
      status: Completed
      isActive: true
      timezone: $timezone
    ) {
      ...StatsFields
    }
  }
`;
