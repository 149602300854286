import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Typography, Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { isEmpty } from "lodash";
import WorkerAutocomplete from "components/Autocomplete/WorkerAutocomplete";
import Button from "components/Button/Button";
import Dialog from "components/Dialog/Dialog";
import FadeHide from "components/Transitions/FadeHide";
import { actions as JobActions, selectors as JobSelectors } from "store/modules/jobs";
import ReactTableSimple from "components/ReactTable/ReactTableSimple";

const useStyles = makeStyles(({ spacing }) => ({
  dialogTitle: {
    width: "100%",
    fontSize: 18,
    textAlign: "left",
  },
  container: {
    textAlign: "left",
    display: "flex",
  },
  detailContant: {
    minHeight: "110px",
    marginTop: "20px",
    textAlign: "left",
  },
  addButton: {
    float: "left",
    paddingLeft: "0px",
    width: 'fit-content'
  },
}));

function FillPositionDialog({ tags, open, onCancel, addWorker }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [selectdeWorker, setSelectedWorker] = useState({});
  const isLoading = useSelector(JobSelectors.getFillPositionLoading);
  const errorList = useSelector(JobSelectors.getfillPositionError);
  useEffect(() => {
    if(!open) {
      setSelectedWorker({});
    }
  }, [open])
  const closeDialog = () => {
    onCancel();
  };

  const columns = [
    {
      Header: "Job Id",
      id: "jobId",
      accessor: ({ jobId }) => jobId,
      className: "-cursor-pointer",
    },
    {
      Header: "Error",
      id: "error",
      accessor: ({ error }) => error,
      className: "-cursor-pointer",
      Cell: ({ row:{original} }) => {
        return <div style={{ color: "red" }}>{original.error}</div>;
      },
    },
  ];

  return (
    <Dialog
      title={
        <React.Fragment>
          <Typography component="h5" className={classes.dialogTitle}>
            {`Fill Position`}
            <Button
              style={{ float: "right", marginRight: -30 }}
              label="X"
              size="small"
              onClick={closeDialog}
              color="transparent"
            />
          </Typography>
        </React.Fragment>
      }
      open={open}
      description={null}
      onClose={closeDialog}
      onCancel={closeDialog}
      size="lg"
      hideActions
      alertType="form"
      modalType="editEmployer"
    >
      <React.Fragment>
        <div className={classes.container} style={{ marginTop: "25px" }}>
          <WorkerAutocomplete style={{ width: "400px" }} tags={tags} onChange={setSelectedWorker} />
        </div>
        {!isEmpty(selectdeWorker) && (
          <>
            <FadeHide in={!isEmpty(selectdeWorker)}>
              <Grid container className={classes.detailContant}>
                <Grid container item xs={12}>
                  <Grid item xs={12}>
                    <Typography variant="h5">{selectdeWorker.data.fullName}</Typography>
                  </Grid>
                </Grid>
                <Grid container item xs={12}>
                  <Grid item xs={12}>
                    <Typography variant="body1">{selectdeWorker.data.email}</Typography>
                    <Typography variant="body1">{selectdeWorker.data.phone}</Typography>
                    <Typography variant="body1">
                      {!isEmpty(selectdeWorker)
                        ? `${selectdeWorker.data.street || ""}, ${selectdeWorker.data.city ||
                            ""}, ${selectdeWorker.data.region || ""}, ${selectdeWorker.data
                            .postalCode || ""}`
                        : null}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </FadeHide>
            <Button
              className={classes.addButton}
              buttonClassName={classes.addButton}
              label="Add"
              size="small"
              disabled={isEmpty(selectdeWorker)}
              onClick={() => addWorker(selectdeWorker.data.id)}
              color="secondary"
              isLoading={isLoading}
            />
          </>
        )}
        <br />
        <br />
        <div>
          {!isEmpty(selectdeWorker) &&
            errorList &&
            errorList.length > 0 && (
              <div style={{ textAlign: "left" }}>
                <h3>We found below errors in fill position</h3>
                <ReactTableSimple
                  classes="-highlight"
                  style={{ width: "100%", marginTop: "16px" }}
                  data={errorList}
                  columns={columns}
                />
              </div>
            )}
        </div>
      </React.Fragment>
    </Dialog>
  );
}

export default FillPositionDialog;
