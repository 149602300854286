import { gql } from "@apollo/client";
import EmployerFields from "../fragments/employer-fragment";

export default gql`
  mutation activateEmployer($data: EmployerInput!) {
    activateEmployer(data: $data) {
      ...EmployerFields
    }
  }
  ${EmployerFields}
`;
