import React, { useState } from 'react'
import MoreVert from "@mui/icons-material/MoreVert";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import IconButton from "@mui/material/IconButton";

function JobTemplateActionMenu({ id, onDelete, onEdit }) {

    const [anchorEl, setanchorEl] = useState(null);

    const handleClick = event => {
        setanchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setanchorEl(null);
    };

    const handleJobTemplateEdit = () => {
        handleClose();
        onEdit(id);
    }

    const handleJobTemplateDelete = () => {
        handleClose();
        onDelete(id);
    };


    return (
        <React.Fragment>
            <IconButton aria-haspopup="true" disableRipple onClick={handleClick} size="large">
                <MoreVert color="primary" />
            </IconButton>
            {anchorEl ? (
                <Menu
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                    disableAutoFocusItem
                >
                    <MenuItem onClick={handleJobTemplateEdit}>
                        Edit
                    </MenuItem>
                    <MenuItem onClick={handleJobTemplateDelete}>
                        Delete
                    </MenuItem>
                </Menu>
            ) : null}
        </React.Fragment>
    );
}

export default JobTemplateActionMenu
