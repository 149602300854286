import React,{ useState } from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVert from "@mui/icons-material/MoreVert";
import IconButton from "@mui/material/IconButton";
import Delete from "@mui/icons-material/Delete";

function RequiredGearActionMenu({ id, onDelete }) {

    const [anchorEl, setanchorEl] = useState(null);

    const handleClick = event => {
        setanchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setanchorEl(null);
    };

    const handleGearDelete = () => {
        handleClose();
        onDelete(id);
    }

    return (
        <React.Fragment>
            <IconButton aria-haspopup="true" disableRipple onClick={handleClick}>
                <MoreVert color="primary" />
            </IconButton>
            {anchorEl ? (
                <Menu
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                    disableAutoFocusItem
                >
                    <MenuItem onClick={handleGearDelete}>
                       <Delete color="action" style={{ marginRight: "10px"}}/>
                        Delete
                    </MenuItem>
                </Menu>
            ) : null}

        </React.Fragment>
    );
}

export default RequiredGearActionMenu
