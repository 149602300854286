import { gql } from "@apollo/client";

export default gql`
  mutation suspendWorker($data: SuspendWorkerInput!) {
    suspendWorker(data: $data) {
      id
      isSuspended
    }
  }
`;
