import React, { useCallback, useState } from "react";

import * as moment from "moment-timezone";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";

import Typography from "@mui/material/Typography";
import { AccountBalanceWalletTwoTone, MoreVert, Receipt } from "@mui/icons-material";
import { baseStyles, CustomTableCellStyles, reversalsTabTableActionsStyles } from "views/Finance/helpers";
import Dialog from "components/Dialog/PaymentDialog";
import { formatMoney } from "utils/stringUtils";

const CustomTableCell = CustomTableCellStyles(TableCell);

function ReversalsTabTableActions({
  handleViewProfile,
  reversal,
  handleClickOpenCancelDialog,
  handleClickOpenResendDialog,
  handleClickOpenResolveDialog,
}) {
  const { workerId, status, dcTransferId } = reversal;
  const classes = reversalsTabTableActionsStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [openReversalDialog, setOpenReversalDialog] = useState(false);
  const menuId = `action-menu-`;
  const openMenu = useCallback(
    e => {
      setAnchorEl(e.currentTarget);
    },
    [anchorEl]
  );
  const closeMenu = useCallback(() => {
    setAnchorEl(null);
  }, [anchorEl]);
  const closePayroll = () => {
    setOpenReversalDialog(false);
  };

  const RenderReversalCard = () => {
    const {
      amount,
      reversedDcTransferId,
      dcTransferId,
      paymentId,
      payrollId,
      status,
      id,
      notes,
    } = reversal;
    return (
      <Grid container alignItems="flex-start" item xs={12}>
        <Typography
          variant="h6"
          align="left"
          className={baseStyles.container100}
          style={{ width: "100%" }}>
          Reversal Request Details
        </Typography>

        <Grid item xs={12} md={5}>
          <TableContainer component={Paper} elevation={0}>
            <Table size="small" className={classes.table} aria-label="simple table">
              <TableBody>
                <TableRow>
                  <CustomTableCell component="th" scope="row">
                    Sent
                  </CustomTableCell>
                  <CustomTableCell align="right">
                    {moment(reversal.sentAt).format("MMM Do, YYYY")}
                  </CustomTableCell>
                </TableRow>
                <TableRow>
                  <CustomTableCell component="th" scope="row">
                    Amount
                  </CustomTableCell>

                  <CustomTableCell align="right">{formatMoney(amount) || ""}</CustomTableCell>
                </TableRow>
                <TableRow>
                  <CustomTableCell component="th" scope="row">
                    Reversal Id
                  </CustomTableCell>
                  <CustomTableCell align="right">{id || ""}</CustomTableCell>
                </TableRow>
                <TableRow>
                  <CustomTableCell component="th" scope="row">
                    Payment Id
                  </CustomTableCell>
                  <CustomTableCell align="right">{paymentId || ""}</CustomTableCell>
                </TableRow>
                <TableRow>
                  <CustomTableCell component="th" scope="row">
                    TransferId
                  </CustomTableCell>
                  <CustomTableCell align="right">{dcTransferId || ""}</CustomTableCell>
                </TableRow>
                <TableRow>
                  <CustomTableCell component="th" scope="row">
                    Reversed DcTransfer Id
                  </CustomTableCell>
                  <CustomTableCell align="right">{reversedDcTransferId || ""}</CustomTableCell>
                </TableRow>
                <TableRow>
                  <CustomTableCell component="th" scope="row">
                    Status
                  </CustomTableCell>
                  <CustomTableCell align="right">
                    {status ? status.description : "Unknown"}
                  </CustomTableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>

        <Grid item xs={12} md={5}>
          <TableContainer component={Paper} elevation={0}>
            <Table size="small" className={classes.table} aria-label="simple table">
              <TableBody>
                <TableRow>
                  <CustomTableCell component="th" scope="row">
                    Notes:
                  </CustomTableCell>
                </TableRow>

                <TableRow>
                  <CustomTableCell align="left">{notes || "No notes found."}</CustomTableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    );
  };

  const RenderDialog = () => {
    return (
      <Dialog
        open={openReversalDialog}
        title={`Generate New Timesheet?`}
        confirmText="Save & Generate Timesheet"
        onClose={() => closePayroll()}
        onConfirm={() => setOpenReversalDialog(false)}
        onCancel={() => closePayroll()}
        maxWidth={`md`}
        alertType="form"
        fullWidth={true}
        icon="attach_file"
        size="md"
        table={
          <Grid container alignItems="flex-start" item xs={12} className={baseStyles.container100}>
            {reversal ? (
              <Grid container alignItems="flex-start" item xs={12} direction="row">
                <RenderReversalCard />
                {/* <RenderEffectedTransferCard /> */}
              </Grid>
            ) : (
              ""
            )}
          </Grid>
        } />
    );
  };

  return (
    <>
      <RenderDialog />
      <IconButton
        aria-controls={menuId}
        aria-haspopup="true"
        onClick={openMenu}
        size="small"
        disableRipple={false}>
        <MoreVert color="primary" />
      </IconButton>
      {status && (
        <Menu
          id={menuId}
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={closeMenu}>
          <MenuItem
            onClick={() => {
              handleViewProfile(workerId);
              closeMenu();
            }}>
            <Receipt className={classes.icon} color="action" />
            View Worker Profile
          </MenuItem>
          <MenuItem
            onClick={() => {
              setOpenReversalDialog(true);
              closeMenu();
            }}>
            <Receipt className={classes.icon} color="action" />
            View Reversal Details
          </MenuItem>

          <MenuItem
            disabled={[13, 8, 5].includes(reversal.paymentStatusId)}
            onClick={() => {
              handleClickOpenResolveDialog(dcTransferId);
              closeMenu();
            }}>
            <AccountBalanceWalletTwoTone className={classes.icon} color="action" />
            Resolve Reversal
          </MenuItem>

          <MenuItem
            disabled={![11, 7, 8].includes(reversal.paymentStatusId)}
            onClick={() => {
              handleClickOpenResendDialog(dcTransferId);
              closeMenu();
            }}>
            <Receipt className={classes.icon} color="action" />
            Resend Reversal
          </MenuItem>

          <MenuItem
            hidden
            disabled={[13, 11, 7, 8, 5].includes(reversal.paymentStatusId)}
            onClick={() => {
              handleClickOpenCancelDialog(reversal);
              closeMenu();
            }}>
            <Receipt className={classes.icon} color="action" />
            Cancel Reversal
          </MenuItem>
        </Menu>
      )}
    </>
  );
}

export default ReversalsTabTableActions;
