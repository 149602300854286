/* eslint-disable react/display-name */
/* eslint-disable react/prop-types */
import React from "react";

import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { Email, Phone } from "@mui/icons-material";
import { upOrDown } from "helpers";
import { employerPopoverStyles } from "views/Finance/helpers";
import { formatMoney, formatNumber } from "utils/stringUtils";

import QbDialog from "../QbDialog";

export const EmployerPopoverContent = props => {
  const classes = employerPopoverStyles();

  const {
    companyName,
    city,
    street,
    region,
    postalCode,
    country,
    firstName,
    lastName,
    email,
    title,
    businessPhone,
    employerWeekInvoices,
  } = props.employerData;

  return (
    <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start">
      <Card className={classes.root}>
        <CardContent className={classes.content}>
          {companyName && (
            <Typography component="h5" variant="h5">
              {companyName}
              <QbDialog invoices={employerWeekInvoices} employer={props.employerData} />
            </Typography>
          )}
          <Typography gutterBottom variant="h6" />
          <Grid container direction="row" justifyContent="flex-start">
            <Typography gutterBottom variant="caption">
              {companyName && `${street}, ${city},`}
            </Typography>
          </Grid>
          <Grid container direction="row" justifyContent="flex-start">
            <Typography gutterBottom variant="caption">
              {companyName && `${region}, ${country}, ${postalCode}`}
            </Typography>
          </Grid>

          {companyName && (
            <Grid container direction="row" justifyContent="flex-start">
              <Typography variant="subtitle2" color="textSecondary" style={{ width: "100%" }}>
                {firstName} {lastName} - {title}
              </Typography>

              <Typography variant="caption" color="textSecondary" style={{ width: "100%" }}>
                  <Email fontSize="small" color="secondary" /> <span className={classes.email}>{email}</span>
              </Typography>
              <Typography variant="body2" color="textSecondary" style={{ width: "100%" }}>
                  <Phone fontSize="small" color="secondary" /> <span className={classes.phone}>{businessPhone}</span>
              </Typography>
            </Grid>
          )}
        </CardContent>
      </Card>
    </Grid>
  );
};

export const JobsPopover = props => {
  const { jobs } = props;

  return (
    <React.Fragment>
      {/* <Grid item xs={12}>
                <Typography variant="subtitle2">{makeDate(Number(employerStats.count))}</Typography>
            </Grid> */}

      <Grid container direction="row" justifyContent="flex-start" alignItems="baseline">
        <Grid item xs={6}>
          <Grid item xs={12} style={{ padding: "0px !important" }}>
            <Grid container item xs={12} direction="column" alignItems="flex-start">
              <Typography variant="caption" gutterBottom>
                This Week
              </Typography>
              <Typography variant="h6" gutterBottom>
                {formatNumber(jobs.total)}
                {upOrDown(jobs.previousTotal, jobs.total)}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={6}>
          <Grid item xs={12} style={{ padding: "0px !important" }}>
            <Grid container item xs={12} direction="column" alignItems="flex-start">
              <Typography variant="caption" gutterBottom>
                Last Week
              </Typography>
              <Typography variant="h6" gutterBottom>
                {formatNumber(jobs.previousTotal)}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};
export const ShiftsPopover = props => {
  const { shifts } = props;

  return (
    <React.Fragment>
      {/* <Grid item xs={12}>
                <Typography variant="subtitle2">{makeDate(Number(employerStats.count))}</Typography>
            </Grid> */}

      <Grid container direction="row" justifyContent="flex-start" alignItems="baseline">
        <Grid item xs={6}>
          <Grid item xs={12} style={{ padding: "0px !important" }}>
            <Grid container item xs={12} direction="column" alignItems="flex-start">
              <Typography variant="caption" gutterBottom>
                This Week
              </Typography>
              <Typography variant="h6" gutterBottom>
                {formatNumber(shifts.total)}
                {upOrDown(shifts.previousTotal, shifts.total)}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={6}>
          <Grid item xs={12} style={{ padding: "0px !important" }}>
            <Grid container item xs={12} direction="column" alignItems="flex-start">
              <Typography variant="caption" gutterBottom>
                Last Week
              </Typography>
              <Typography variant="h6" gutterBottom>
                {formatNumber(shifts.previousTotal)}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export const HoursPopover = props => {
  const { hours } = props;

  return (
    <React.Fragment>
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="baseline"
        style={{ padding: "20px !important" }}>
        <Grid item xs={5}>
          <Grid item xs={12} style={{ padding: "0px !important" }}>
            <Grid container item xs={12} direction="column" alignItems="flex-start">
              <Typography variant="caption" gutterBottom>
                Regular
              </Typography>
              <Typography variant="subtitle1" gutterBottom>
                {formatNumber(hours.regularHours || 0)}
                {upOrDown(hours.previousRegularHours, hours.regularHours)}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={4}>
          <Grid item xs={12} style={{ padding: "0px !important" }}>
            <Grid container item xs={12} direction="column" alignItems="flex-start">
              <Typography variant="caption" gutterBottom>
                Overtime
              </Typography>
              <Typography variant="subtitle1" gutterBottom>
                {formatNumber(hours.overtime || 0)}
                {upOrDown(hours.previousOvertime, hours.overtime)}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={3}>
          <Grid item xs={12} style={{ padding: "0px !important" }}>
            <Typography variant="caption" gutterBottom>
              Doubletime
            </Typography>
            <Typography variant="subtitle1" gutterBottom>
              {formatNumber(hours.doubletime || 0) || 0}
              {upOrDown(hours.previousdoubletime || 0, hours.doubletime || 0)}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export const TotalBillablePopover = props => {
  const { billing } = props;

  const newTotal = billing.overtime + billing.regular + billing.doubletime;
  const total = billing.previousOvertime + billing.previousRegular + billing.previousDoubletime;
  return (
    <React.Fragment>
      {/* <Grid item xs={12}>
                <Typography variant="subtitle2">{makeDate(Number(employerStats.count))}</Typography>
            </Grid> */}

      <Grid container direction="row" justifyContent="flex-start" alignItems="baseline">
        <Grid item xs={6}>
          <Grid item xs={12} style={{ padding: "0px !important" }}>
            <Grid container item xs={12} direction="column" alignItems="flex-start">
              <Typography variant="caption" gutterBottom>
                This Week
              </Typography>
              <Typography variant="h6" gutterBottom>
                {formatMoney(newTotal)}
                {upOrDown(total, newTotal)}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={6}>
          <Grid item xs={12} style={{ padding: "0px !important" }}>
            <Grid container item xs={12} direction="column" alignItems="flex-start">
              <Typography variant="caption" gutterBottom>
                Last Week
              </Typography>
              <Typography variant="h6" gutterBottom>
                {formatMoney(total)}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default EmployerPopoverContent;
