/* eslint-disable react/prop-types */
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";

import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { upOrDown } from "helpers";
import { billingSummaryStatsCardStyles } from "views/Finance/helpers";
import { formatMoney, formatNumber } from "utils/stringUtils";
import { actions as BillingActions } from "store/modules/finance";

const BillingSummaryStatsCard = props => {
  const { classes, data } = props;
  const { employerStats, shifts, hours, billing } = data;
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(BillingActions.fetchEmployerBillingYearSummary());
  }, [dispatch]);

  const RenderEmployerCard = () => (
    <Grid item md={3} xs={6} className={classes.container}>
      <Grid item xs={12} className={classes.card} style={{ padding: "0px 10px !important" }}>
        <Grid container item xs={12} alignItems="flex-start">
          <Grid container direction="row" justifyContent="flex-start" alignItems="baseline">
            <Grid item xs={12} style={{ padding: "0px  !important", marginBottom: 10 }}>
              <Typography style={{ fontSize: 18, fontWeight: 700 }}>Employers</Typography>
            </Grid>
            <Grid item xs={12}>
                <div className={classes.leftSection}>
                  <Grid item xs={12} style={{ padding: "0px !important" }}>
                    <Grid container item xs={12} direction="column" alignItems="flex-start">
                      <Typography variant="caption">This Week</Typography>
                      <Typography variant="h6">
                        {formatNumber(employerStats.count)}
                        {upOrDown(employerStats.previousCount, employerStats.count)}
                      </Typography>
                    </Grid>
                  </Grid>
                </div>
                <div className={classes.rightSection}>
                    <Grid item xs={12} style={{ padding: "0px !important" }}>
                        <Grid container item xs={12} direction="column" alignItems="flex-start">
                            <Typography variant="caption">Last Week</Typography>
                            <Typography variant="h6">{formatNumber(employerStats.previousCount)}</Typography>
                        </Grid>
                    </Grid>
                </div>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );

  const RenderShiftsCard = () => (
    <Grid item md={3} xs={6} className={classes.container}>
      <Grid item xs={12} className={classes.card} style={{ padding: "0px 10px !important" }}>
        <Grid container item xs={12} alignItems="flex-start">
          <Grid container direction="row" justifyContent="flex-start" alignItems="baseline">
            <Grid item xs={12} style={{ padding: "0px  !important", marginBottom: 10 }}>
              <Typography style={{ fontSize: 18, fontWeight: 700 }}>Shifts</Typography>
            </Grid>
            <Grid item xs={12}>
                <div className={classes.leftSection}>
                  <Grid item xs={12} style={{ padding: "0px !important" }}>
                    <Grid container item xs={12} direction="column" alignItems="flex-start">
                      <Typography variant="caption">This Week</Typography>

                      <Typography variant="h6">
                        {formatNumber(shifts.total)}
                        <span>
                          {upOrDown(shifts.previousTotal, shifts.total, {})}
                        </span>
                      </Typography>
                    </Grid>
                  </Grid>
                </div>
                <div className={classes.rightSection}>
                    <Grid item xs={12} style={{ padding: "0px !important" }}>
                        <Grid container item xs={12} direction="column" alignItems="flex-start">
                            <Typography variant="caption">Last Week</Typography>
                            <Typography variant="h6">{formatNumber(shifts.previousTotal)}</Typography>
                        </Grid>
                    </Grid>
                </div>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );

  const RenderHoursCard = () => (
    <Grid item md={3} xs={6} className={classes.container}>
      <Grid item xs={12} className={classes.card} style={{ padding: "0px 10px !important" }}>
        <Grid container item xs={12} alignItems="flex-start">
          <Grid container direction="row" justifyContent="flex-start" alignItems="baseline">
            <Grid item xs={12} style={{ padding: "0px  !important", marginBottom: 10 }}>
              <Typography style={{ fontSize: 18, fontWeight: 700 }}>Hours</Typography>
            </Grid>
            <Grid item xs={12}>
                <div className={classes.leftSection}>
                  <Grid item xs={12} style={{ padding: "0px !important" }}>
                    <Grid container item xs={12} direction="column" alignItems="flex-start">
                      <Typography variant="caption">This Week</Typography>
                      <Typography variant="h6">
                        {formatNumber(hours.regularHours)}
                        <span>
                          {upOrDown(hours.previousRegularHours, hours.regularHours, {})}
                        </span>
                      </Typography>
                    </Grid>
                  </Grid>
                </div>
                <div className={classes.rightSection}>
                    <Grid item xs={12} style={{ padding: "0px !important" }}>
                        <Grid container item xs={12} direction="column" alignItems="flex-start">
                            <Typography variant="caption">Last Week</Typography>
                            <Typography variant="h6">{formatNumber(hours.previousRegularHours)}</Typography>
                        </Grid>
                    </Grid>
                </div>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );

  const RenderBillingCard = () => (
    <Grid item md={3} xs={6} className={classes.container}>
      <Grid item xs={12} className={classes.card} style={{ padding: "0px 10px !important" }}>
        <Grid container item xs={12} alignItems="flex-start">
          <Grid container direction="row" justifyContent="flex-start" alignItems="baseline">
            <Grid item xs={12} style={{ padding: "0px  !important", marginBottom: 10 }}>
              <Typography style={{ fontSize: 18, fontWeight: 700 }}> Total Billable</Typography>
            </Grid>
            <Grid item xs={12}>
                <div className={classes.leftSection}>
                    <Grid item xs={12} style={{ padding: "0px !important" }}>
                        <Grid container item xs={12} direction="column" alignItems="flex-start">
                            <Typography variant="caption">This Week</Typography>
                            <Typography variant="h6">
                                {formatMoney(billing.totalBilling)}
                                {upOrDown(billing.previousTotalBilling, billing.totalBilling)}
                            </Typography>
                        </Grid>
                    </Grid>
                </div>
                <div className={classes.rightSection}>
                    <Grid item xs={12} style={{ padding: "0px !important" }}>
                        <Grid container item xs={12} direction="column" alignItems="flex-start">
                            <Typography variant="caption">Last Week</Typography>
                            <Typography variant="h6">{formatMoney(billing.previousTotalBilling)}</Typography>
                        </Grid>
                    </Grid>
                </div>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );

  return (
    <Grid container direction="row" justifyContent="flex-start" alignItems="baseline">
      <RenderEmployerCard />
      <RenderShiftsCard />
      <RenderHoursCard />
      <RenderBillingCard />
    </Grid>
  );
};

BillingSummaryStatsCard.propTypes = {
  classes: PropTypes.object.isRequired,
  rating: PropTypes.number,
  availability: PropTypes.array,
  positionCount: PropTypes.number,
  preferCount: PropTypes.number,
  noShowCount: PropTypes.number,
  banCount: PropTypes.number,
};

BillingSummaryStatsCard.defaultProps = {
  rating: 0,
  availability: [],
  positionCount: 0,
  preferCount: 0,
  noShowCount: 0,
  banCount: 0,
};

export default billingSummaryStatsCardStyles(BillingSummaryStatsCard);
