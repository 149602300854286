import React from "react";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { Delete, NoteAdd } from "@mui/icons-material";
import AddCircleOutline from "@mui/icons-material/AddCircleOutline";
import Cancel from "@mui/icons-material/Cancel";
import CheckCircle from "@mui/icons-material/CheckCircle";
import MoreHoriz from "@mui/icons-material/MoreHoriz";
import Security from "@mui/icons-material/Security";
import VerifiedUser from "@mui/icons-material/VerifiedUser";

import { employerActionMenuStyles } from "views/Employer/helpers";

class EmployerActionMenu extends React.Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    canEnableMassCheckin: PropTypes.bool,
    canDisableMassCheckin: PropTypes.bool,
    canVerify: PropTypes.bool,
    canUnverify: PropTypes.bool,
    canActivate: PropTypes.bool,
    canDeactivate: PropTypes.bool,
  };

  static defaultProps = {
    canEnableMassCheckin: true,
    canDisableMassCheckin: true,
    canVerify: false,
    canUnverify: false,
    canActivate: false,
    canDeactivate: false,
  };

  state = {
    anchorEl: null,
  };

  handleClick = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  handleEdit = () => {
    // this.props.onEdit();
    this.handleClose();
  };

  handlePostJob = () => {
    this.props.onPostJob();
    this.handleClose();
  };
  handleVerify = () => {
    this.props.onVerify();
    this.handleClose();
  };

  handleUnverify = () => {
    this.props.onUnverify();
    this.handleClose();
  };

  handleActivate = () => {
    this.props.onActivate();
    this.handleClose();
  };

  handleDeactivate = () => {
    this.props.onDeactivate();
    this.handleClose();
  };

  handleEnableMassCheckin = () => {
    // this.props.onEnableMassCheckin();
    this.handleClose();
  };

  handleDisableMassCheckin = () => {
    // this.props.onDisableMassCheckin();
    this.handleClose();
  };

  handleAddManager = () => {
    // this.props.onAddManager();
    this.handleClose();
  };
  handleAddEmployerNote = () => {
    this.props.onAddEmployerNote();
    this.handleClose();
  };

  handleDeleteAccount = () => {
    this.props.onDeleteAccount();
    this.handleClose();
  };
  render() {
    const { anchorEl } = this.state;
    const {
      classes,
      // canEnableMassCheckin,
      // canDisableMassCheckin,
      setCanEdit,
      canVerify,
      canUnverify,
      canActivate,
      canDeactivate,
    } = this.props;

    const id = `action-menu-worker`;

    return (
      <React.Fragment>
        <IconButton
          aria-owns={anchorEl ? id : undefined}
          aria-haspopup="true"
          disableRipple
          onClick={this.handleClick}
          size="large">
          <MoreHoriz color="primary" />
        </IconButton>
        {anchorEl ? (
          <Menu
            id={id}
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={this.handleClose}
            disableAutoFocusItem>
            {/* <MenuItem onClick={this.handleEdit}>
                            <Help className={classes.icon} color="action" />
                            Edit Employer Info
                        </MenuItem> */}
            <MenuItem onClick={() => {
                setCanEdit();
                this.handleClose();
            }}>
              <AddCircleOutline className={classes.icon} color="action" />
              Edit Profile
            </MenuItem>
            <MenuItem onClick={this.handlePostJob}>
              <AddCircleOutline className={classes.icon} color="action" />
              Create a job
            </MenuItem>
            {canVerify && (
              <MenuItem onClick={this.handleVerify}>
                <VerifiedUser className={classes.icon} color="action" />
                Verify
              </MenuItem>
            )}
            {canUnverify && (
              <MenuItem onClick={this.handleUnverify}>
                <Security className={classes.icon} color="action" />
                Unverify
              </MenuItem>
            )}
            {canActivate && (
              <MenuItem onClick={this.handleActivate}>
                <CheckCircle className={classes.icon} color="action" />
                Activate
              </MenuItem>
            )}
            {canDeactivate && (
              <MenuItem onClick={this.handleDeactivate}>
                <Cancel className={classes.icon} color="action" />
                Deactivate
              </MenuItem>
            )}
            <MenuItem onClick={this.handleAddEmployerNote}>
              <NoteAdd className={classes.icon} color="action" />
              Add a note
            </MenuItem>
            <MenuItem onClick={this.handleDeleteAccount}>
              <Delete className={classes.icon} color="action" />
              Delete Account
            </MenuItem>
            {/* {canEnableMassCheckin && (
                            <MenuItem onClick={this.handleEnableMassCheckin}>
                                <AddToHomeScreen className={classes.icon} color="action" />
                                Enable Mass Check-in
                            </MenuItem>
                        )}
                        {canDisableMassCheckin && (
                            <MenuItem onClick={this.handleDisableMassCheckin}>
                                <PhonelinkErase className={classes.icon} color="action" />
                                Disable Mass Check-in
                            </MenuItem>
                        )}
                        <MenuItem onClick={this.handleAddManager}>
                            <GroupAdd className={classes.icon} color="action" />
                            Add a Manager
                        </MenuItem> */}
          </Menu>
        ) : null}
      </React.Fragment>
    );
  }
}

export default employerActionMenuStyles(withRouter(EmployerActionMenu));
