/* eslint-disable react/jsx-key */
/* eslint-disable react/jsx-no-undef */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */

import React from "react";
import moment from "moment-timezone";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import AttachFile from "@mui/icons-material/AttachFile";
import { invoiceCardStyles } from "views/Finance/helpers";
import DeletedInvoiceAction from "./InvoiceCardActions/DeletedInvoiceAction";

function DeletedInvoiceCard(props) {
  const { classes, employer, week, deletedInvoices } = props;

  const DeletedInvoiceCard = ({ invoice, index }) => {
    const { createdAt, invoiceId,  } = invoice;

    return (
      <Grid item xs={12} md={6} lg={6}  className={classes.deletedContainer} elevation={5}>
        <Grid item xs={12} className={classes.deletedCard}>
          <Card className={classes.cardRoot}>
            <CardHeader
              className={classes.cardRoot.root}
              titleTypographyProps={{ className: classes.title }}
              subheaderTypographyProps={{ className: classes.subHeader }}
              avatar={<AttachFile className={classes.deletedAvatar} />}
              action={
                  <div style={{ textAlign: "right", justify: "right", alignItems: "flex-end" }}>
                    <DeletedInvoiceAction
                      invoice={invoice}
                      week={week}
                      employer={employer}
                    />
                  </div>
              }
              title={
                <React.Fragment>
                  <Typography style={{ fontWeight: 600, fontSize: 14, fontFamily: "Roboto" }}>
                    {`Invoice Id: ${invoiceId}`}
                  </Typography>
                </React.Fragment>
              }
              subheader={
                <React.Fragment>
                  <Typography
                    style={{
                      color: "#aebecd",
                      fontWeight: 500,
                      fontSize: 12,
                      fontFamily: "Roboto",
                    }}>{`Deleted ${moment(createdAt).format(
                    "MMM DD, YYYY h:mm a  z"
                  )}`}</Typography>
                </React.Fragment>
              }
            />
          </Card>
        </Grid>
      </Grid>
    );
  };

  return (
    <React.Fragment>
      {deletedInvoices && deletedInvoices.length && (
        <>
          <Grid container justifyContent="flex-start">
            {deletedInvoices && deletedInvoices.length && 
              deletedInvoices.map((invoice, i) => (
                <DeletedInvoiceCard
                  key={i}
                  index={i}
                  invoice={invoice}
                />
              )
            )}
          </Grid>
        </>
      )}
    </React.Fragment>
  );
}

export default invoiceCardStyles(DeletedInvoiceCard);
