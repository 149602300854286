/* eslint-disable react/prop-types */
import React from "react";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Contacts from "@mui/icons-material/Contacts";
import MoreVert from "@mui/icons-material/MoreVert";

import { workerActionMenuStyles } from "views/Finance/helpers";

class WorkerActionMenu extends React.Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
  };

  state = {
    anchorEl: null,
  };

  handleClick = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  handleViewJob = () => {
    this.props.history.push(`/jobs/${this.props.jobId}`);
    this.handleClose();
  };

  handleViewEmployer = (employer, week, year) => {
    this.props.history.push(`/finance/billingdetails/employer/${employer}/${week}/${year}`);
    this.handleClose();
  };

  render() {
    const { anchorEl } = this.state;
    const { classes, employerId, week, match } = this.props;
    const id = `action-menu-worker`;

    return (
      <React.Fragment>
        <IconButton
          aria-owns={anchorEl ? id : undefined}
          aria-haspopup="true"
          disableRipple
          onClick={this.handleClick}
          size="large">
          <MoreVert color="primary" />
        </IconButton>
        {anchorEl ? (
          <Menu
            id={id}
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={this.handleClose}
            disableAutoFocusItem>
            <MenuItem onClick={() => this.handleViewEmployer(employerId, week, match.params.year)}>
              <Contacts className={classes.icon} color="action" />
              View Employer
            </MenuItem>
          </Menu>
        ) : null}
      </React.Fragment>
    );
  }
}

export default workerActionMenuStyles(withRouter(WorkerActionMenu));
