import types from "./types";
import { createReducer } from "utils/redux";

const initialLoadingState = {
    tableLoading: true,
};

const initialDialogState = {
    removeDialog: false,
};

const initialState = {
    loading: { ...initialLoadingState },
    dialog: { ...initialDialogState },
    jobTemplates: [],
    jobTypeList: [],
    jobTemplate: {},
};

const actionsMap = {
    [types.SET_LOADING_STATE]: (state, { payload }) => {
        const { key, value } = payload;
        return {
            ...state,
            loading: {
                ...state.loading,
                [key]: value,
            },
        };
    },
    [types.SET_JOB_TEMPLATE_DATA]: (state, { payload }) => {
        const { jobTemplates } = payload;
        return {
            ...state,
            jobTemplates,
        };
    },
    [types.SET_JOB_TYPE_LIST]: (state, { payload }) => {
        const { jobTypeList } = payload;
        return {
            ...state,
            jobTypeList,
        };
    },
    [types.SET_DIALOG_STATE]: (state, { payload }) => {
        const { key, value } = payload;
        return {
            ...state,
            dialog: {
                ...state.dialog,
                [key]: value,
            },
        };
    },
    [types.SET_JOB_TEMPLATE]: (state, { payload }) => {
        const { jobTemplate } = payload;
        return {
            ...state,
            jobTemplate,
        };
    },
    [types.RESET_FORM_DATA]: (state) => {
        return {
            ...state,
            ...initialState,
            jobTemplate: {}
        };
    },
}

export default createReducer(initialState, actionsMap);