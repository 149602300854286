function formatPerm(value, description) {
    return {
        value,
        description,
    };
}

export const DashboardViewsPermissions = {
    job: {
        base: formatPerm("job:view:base", "View job base screen"),
        today: formatPerm("job:view:today", "View job today tab screen"),
        tomorrow: formatPerm("job:view:tomorrow", "View job tomorrow tab screen"),
        upcoming: formatPerm("job:view:upcoming", "View job upcoming tab screen"),
        history: formatPerm("job:view:history", "View job history tab screen"),
        draft: formatPerm("job:view:draft", "View job draft tab screen"),
        post: formatPerm("job:view:post", "View post job screen"),
        detail: formatPerm("job:view:detail", "View job details"),
    },
    worker: {
        base: formatPerm("worker:view:base", "View worker base screen"),
        list: formatPerm("worker:view:list", "View worker list tab screen"),
        waitlist: formatPerm("worker:view:waitlist", "View worker waitlist tab screen"),
        create: formatPerm("worker:view:create", "View create worker screen"),
        detail: formatPerm("worker:view:detail", "View worker details"),
    },
    employer: {
        base: formatPerm("employer:view:base", "View employer base screen"),
        create: formatPerm("employer:view:create", "View create employer screen"),
        detail: formatPerm("employer:view:detail", "View employer details"),
    },
    finance: {
        base: formatPerm("finance:view:base", "View finance base screen"),
    },
    report: {
        base: formatPerm("report:view:base", "View finance base screen"),
    },
    master: {
        base: formatPerm("master:view:base", "View finance base screen"),
    },
    settings: {
        base: formatPerm("settings:view:base", "View settings base screen"),
        workerFaq: formatPerm("settings:view:worker-faq", "View worker FAQ tab screen"),
        employerFaq: formatPerm("settings:view:employer-faq", "View employer FAQ tab screen"),
    },
};

export const WorkerPermissions = {
    create: {
        new: formatPerm("worker:create:new", "Create a new worker"),
    },
    read: {
        list: formatPerm("worker:read:list", "Read list and filter all workers"),
        systemStats: formatPerm("worker:read:system-stats", "Read all worker counts and breakdowns"),
        profile: formatPerm("worker:read:profile", "Read a workers profile"),
        sin: formatPerm("worker:read:sin", "Read the workers truncated SIN"),
        authProfile: formatPerm("worker:read:auth-profile", "Read workers auth provider profile"),
        authLogs: formatPerm("worker:read:auth-logs", "Read a workers auth provider logs"),
        financials: formatPerm("worker:read:financials", "Read a workers financials"),
        positions: formatPerm("worker:read:positions", "Read all positions a worker is/has been on"),
        positionStats: formatPerm("worker:read:positions-stats", "Read position history for a worker"),
        jobAvailability: formatPerm(
            "worker:read:job-availability",
            "Check whether a worker is available for a job",
        ),
    },
    update: {
        suspend: formatPerm("worker:update:suspend", "Suspend a worker"),
        profile: formatPerm("worker:update:profile", "Update a workers profile"),
        authSettings: formatPerm(
            "worker:update:auth-settings",
            "Update a workers auth provider settings",
        ),
    },
    action: {
        accountVerify: formatPerm(
            "worker:action:send-account-verify",
            "Send an account verification email to a worker",
        ),
        passwordReset: formatPerm(
            "worker:action:send-passsword-reset",
            "Send a worker a password reset email",
        ),
    },
};

export const JobPermissions = {
    create: {
        new: formatPerm("job:create:new", "Create a new job"),
        duplicate: formatPerm("job:create:duplicate", "Duplicate an existing job"),
    },
    read: {
        list: formatPerm("job:read:list", "Read jobs lists (UI only)"),
        available: formatPerm("job:read:available", "Read jobs that are available"),
        positions: formatPerm("job:read:positions", "Read position information for jobs"),
        financials: formatPerm("job:read:financials", "Read financial information for jobs"),
        systemStats: formatPerm("job:read:system-stats", "Read all job counts and breakdowns"),
        systemFinancials: formatPerm(
            "job:read:system-financials",
            "Read system financial information for jobs",
        ),
        checkInCount: formatPerm("job:read:check-in-count", "Read check in counts"),
        checkOutCount: formatPerm("job:read:check-out-count", "Read check out counts"),
    },
    update: {
        detail: formatPerm("job:update:detail", "Edit a jobs details"),
        activate: formatPerm("job:update:activate", "Activate a job"),
        cancel: formatPerm("job:update:cancel", "Cancel a cancel a job"),
        increasePositions: formatPerm("job:update:increase-positions", "Increase available positions for a job"),
    },
    delete: {
        hard: formatPerm("job:delete:hard", "Hard delete a job"),
    },
    action: {
        payWorkers: formatPerm("job:action:pay-workers", "Queue workers to be paid"),
        selectWorkers: formatPerm("job:action:select-workers", "Select workers and add them to a job"),
    },
};

export const FinancePermissions = {
    create: {
        fill: formatPerm("finance:create:fill", "Fill a position with a worker"),
        replace: formatPerm("finance:create:replace", "Replace/Swap out worker"),
    },
    read: {
        base: formatPerm("finance:view:base", "View finance base screen"),
        list: formatPerm("finance:read:list", "List and query all positions"),
        worker: formatPerm("finance:read:worker", "Read the worker information for a given position"),
    },
    update: {
        cancel: formatPerm("finance:update:cancel", "Cancel a worker for a position"),
        noShow: formatPerm("finance:update:no-show", "Mark a worker as a no show"),
        rateAll: formatPerm("finance:update:rate-all", "Batch rate all workers"),
        checkInAll: formatPerm("finance:update:check-in-all", "Batch check in all workers"),
        checkOutAll: formatPerm("finance:update:check-out-all", "Batch check out all workers"),
    },
    delete: {
        hard: formatPerm("finance:delete:hard", "Hard delete a positions data"),
    },
};

export const PositionPermissions = {
    create: {
        fill: formatPerm("positions:create:fill", "Fill a position with a worker"),
        replace: formatPerm("positions:create:replace", "Replace/Swap out worker"),
    },
    read: {
        list: formatPerm("positions:read:list", "List and query all positions"),
        worker: formatPerm("positions:read:worker", "Read the worker information for a given position"),
    },
    update: {
        cancel: formatPerm("positions:update:cancel", "Cancel a worker for a position"),
        noShow: formatPerm("positions:update:no-show", "Mark a worker as a no show"),
        rateAll: formatPerm("positions:update:rate-all", "Batch rate all workers"),
        checkIn: formatPerm("positions:update:check-in", "Check in worker"),
        checkOut: formatPerm("positions:update:check-out", "Check out worker"),
        checkInAll: formatPerm("positions:update:check-in-all", "Batch check in all workers"),
        checkOutAll: formatPerm("positions:update:check-out-all", "Batch check out all workers"),
    },
    delete: {
        hard: formatPerm("positions:delete:hard", "Hard delete a positions data"),
    },
};

export const AdminPermissions = {
    create: {
        new: formatPerm("admin:create:new", "Create new Admin"),
    },
    read: {
        list: formatPerm("admin:read:list", "List all admins"),
        allpermissions: formatPerm("admin:read:allpermissions", "List all permissions"),
        assignpermissions: formatPerm("admin:read:assignpermissions", "List all assign permissions"),
        unassignpermissions: formatPerm("admin:read:unassignpermissions", "List all unassign permissions"),
    },
    update: {
        profile: formatPerm("admin:update:profile", "Update admin profile"),
        admin: formatPerm("admin:update:admin", "Update admin"),
        permissions: formatPerm("admin:update:permissions", "Update admin permission"),
    },
    delete: {
        hard: formatPerm("admin:delete:hard", "Hard delete a admin"),
        permissions: formatPerm("admin:delete:permissions", "Delete a admin permission"),
    },
};

export default {
    WorkerPermissions,
    JobPermissions,
    PositionPermissions,
    DashboardViewsPermissions,
    FinancePermissions,
    AdminPermissions
};
