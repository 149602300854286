import React from "react";
import { connect } from "react-redux";
import Grid from "@mui/material/Grid";
import * as moment from "moment";
import MainContent from "components/Containers/MainContent";
import FadeHide from "components/Transitions/FadeHide";
import { getTimeZone } from "utils/time";
import posting, { actions as PostingActions, selectors as PostingSelectors } from "store/modules/posting";
import Dialog from "../../../components/Dialog/Dialog";
import EmployerSection from "./components/EmployerSection";
import JobDetailsSection from "./components/JobDetailsSection";
import Stage from "./components/Stage";
import SummaryFooter from "./components/SummaryFooter";
import AnnouncementSection from "./components/AnnouncementSection";
import ManagerSection from "./components/ManagerSection";
import LocationSection from "./components/LocationSection";
import GearsAndSkillsSection from "./components/GearsAndSkillsSection";
import DateTimeSection from "./components/DateTimeSection";

class PostJobView extends React.Component {
    state = {
        showTimezoneDialog: false,
        showIncludeWorkerDialog: false,
        skill: ""
    };
    params = new URLSearchParams(this.props?.history?.location?.search);
    jobType = this.params?.get?.("type");

    async componentDidMount() {
        const { history, onFetchEditableJob, onInit, doneLoading, onPrefillEmployer } = this.props;
        await onInit();
        if (history.location.search) {
            const params = new URLSearchParams(history.location.search);
            const employerId = params.get("employerId");
            if (employerId) {
                onPrefillEmployer(employerId);
            } else {
                const jobId = params.get("jobId");
                const type = params.get("type");

                onFetchEditableJob(jobId, type);
            }
        } else {
            doneLoading();
        }
    }

    hideTimezoneDialog = () => {
        this.setState({ showTimezoneDialog: false });
    };
    confirmTimezoneDialog = (isIncludeWorker = false) => {
        const { history, onNext } = this.props;
        let jobId;
        let type;
        this.setState({ showTimezoneDialog: false });
        if (history.location.search) {
            const params = new URLSearchParams(history.location.search);
            jobId = parseInt(params.get("jobId"), 10);
            type = params.get("type");
        }

        return onNext(jobId, type, isIncludeWorker)
            .then(({ id }) => history.push(`/jobs/${id}`))
            .catch(() => {
                /* Catch rethrow, used to stop the routing */
            });
    };

    handleIncludeWorker = () => {
        const { history } = this.props;
        let type;
        if (history.location.search) {
            const params = new URLSearchParams(history.location.search);
            type = params.get("type");
        }
        if (type === 'duplicate' && this.props.positions && this.props.positions.length > 0) {
            this.setState({ showIncludeWorkerDialog: true });
        } else {
            this.confirmTimezoneDialog();
        }
    }

    cancelIncludeWorker = () => {
        this.setState({ showIncludeWorkerDialog: false })
        this.confirmTimezoneDialog();
    }

    confirmIncludeWorker = () => {
        this.setState({ showIncludeWorkerDialog: false })
        this.confirmTimezoneDialog(true);
    }

    handleNext = () => {
        const selectedTimezone = this.props.timezoneList.find(n => n.timezoneName === getTimeZone());
        if (
            !this.props.location.utc_offset_minutes ||
            this.props.location.utc_offset_minutes ===
            moment().utcOffset(selectedTimezone.offset).utcOffset()
        ) {
            this.confirmTimezoneDialog();
        } else {
            this.setState({ showTimezoneDialog: true });
        }
    };

    render() {
        const { employer, isLoading, isEditing } = this.props;
        const employerInfo = employer || {};

        return (
            <MainContent isLoading={isLoading}>
                <Grid>
                    <Stage
                        title="Employer"
                        description="Select the Employer you'd like to post a job for."
                        style={employer ? { borderBottom: "1px solid #aebecd" } : {}}
                        contentSize={6}>
                        <EmployerSection
                            {...employerInfo}
                            isEditing={this.props.isEditing}
                            showInfo={!!employer}
                            selectedEmployer={this.props.selectedEmployer}
                            onEmployerChange={this.props.onEmployerChange}
                        />
                    </Stage>
                    <FadeHide in={!!employer}>
                        <div>
                            <Stage
                                title="Job Details"
                                description="Input all of the relevant information for your job."
                                style={{ borderBottom: "1px solid #aebecd" }}
                            >
                                <JobDetailsSection
                                    // isPast={this.props.isPast}
                                    jobTypeList={this.props.jobTypeList}
                                    jobType={this.props.jobType}
                                    description={this.props.description}
                                    isPrivate={this.props.isPrivate}
                                    canPostPrivateJob={employerInfo.canPostPrivateJob}
                                    tags={this.props.tags}
                                />
                            </Stage>

                            <Stage
                                title="Gears and Skills"
                                description="Add required gears and skills."
                                style={{ borderBottom: "1px solid #aebecd" }}
                            >
                                <GearsAndSkillsSection
                                    gears={this.props.gears}
                                    skill={this.state.skill}
                                    skills={this.props.skills}
                                    isEditing={this.props.isEditing}
                                />
                            </Stage>

                            <Stage
                                title="Job Location"
                                description="Input address information for your job."
                                style={{ borderBottom: "1px solid #aebecd",zIndex:999,position:"relative" }}
                            >
                                <LocationSection
                                    selectedLocation={this.props.selectedLocation}
                                    floor={this.props.floor}
                                    unit={this.props.unit}
                                    locationInstruction={this.props.locationInstruction}
                                />
                            </Stage>

                            <Stage
                                title="Date and Time"
                                description="Input date information for your job."
                                style={{ borderBottom: "1px solid #aebecd" }}
                            >
                                <DateTimeSection
                                    date={this.props.date}
                                    announcement={this.props.announcement}
                                    startTime={this.props.startTime}
                                    endTime={this.props.endTime}
                                    requiresBreak={this.props.requiresBreak}
                                    breakTypeList={this.props.breakTypeList}
                                    breakType={this.props.breakType}
                                    breakMins={this.props.breakMins}
                                    recurringType={this.props.recurringType}
                                    repeatEvery={this.props.repeatEvery}
                                    recurringEndDate={this.props.recurringEndDate}
                                    weekDays={this.props.weekDays}
                                    monthDate={this.props.monthDate}
                                    formattedDuration={this.props.formattedDuration}
                                    isEditing={this.props.isEditing}
                                    isRecurring={this.props.isRecurring}
                                    isLessThanFour={this.props.isLessThanFour}
                                    isGreaterThanTen={this.props.isGreaterThanTen}
                                    isValidStartTime={this.props.isValidStartTime}
                                    isValidEndTime={this.props.isValidEndTime}
                                    isValidDate={this.props.isValidDate}
                                    isValidRecurringEndDate={this.props.isValidRecurringEndDate}
                                />
                            </Stage>

                            {employer &&
                                <Stage
                                    title="Assign Project Roles"
                                    description="Select an user role and name for this specific job."
                                    style={isEditing === false ? { borderBottom: "1px solid #aebecd" } : {}}
                                >
                                    <ManagerSection
                                        {...employerInfo}
                                        employer={employer}
                                        workersNeeded={this.props.workersNeeded}
                                        positions={this.props.positions}
                                    />
                                </Stage>
                            }

                            {(!isEditing || this.jobType === "duplicate") &&
                                <Stage
                                    title="Announcement"
                                    description="Add an announcement that will notify the workers."
                                >
                                    <AnnouncementSection
                                        date={this.props.date}
                                        announcement={this.props.announcement}
                                        isRecurring={this.props.isRecurring}
                                        recurringEndDate={this.props.recurringEndDate}
                                        startTime={this.props.startTime}
                                    />
                                </Stage>
                            }

                            <SummaryFooter
                                totalCost={this.props.totalCost}
                                canPost={this.props.canPost}
                                onNext={this.handleIncludeWorker}
                            />
                        </div>
                    </FadeHide>
                </Grid>
                <Dialog
                    open={this.state.showTimezoneDialog}
                    title="Timezone"
                    description="Your timezone is different then address. Are you sure to continue?"
                    onClose={this.hideTimezoneDialog}
                    onConfirm={this.confirmTimezoneDialog}
                    onCancel={this.hideTimezoneDialog}
                    alertType="warning"
                />
                <Dialog
                    open={this.state.showIncludeWorkerDialog}
                    title="Do you want to include the workers as well?"
                    onConfirm={this.confirmIncludeWorker}
                    onCancel={this.cancelIncludeWorker}
                    cancelText="No"
                    alertType="question"
                    icon="group_add"
                />
            </MainContent>
        );
    }
}

const mapState = state => {
    const formattedAddress = PostingSelectors.getFormattedAddress(state);
    const formattedDuration = PostingSelectors.getFormattedDuration(state);
    return {
        ...state.posting,
        formattedAddress,
        formattedDuration,
        canPost: PostingSelectors.getCanPost(state),
        totalCost: PostingSelectors.getTotalCost(state),
        announcement: PostingSelectors.getAnnouncement(state),
        timezoneList: state.login.timezoneList,
    };
};

const mapActions = dispatch => ({
    onInit: () => {
        dispatch(PostingActions.resetForm());
        return dispatch(PostingActions.fetchWorkTypes());
    },
    onPrefillEmployer: employerId => dispatch(PostingActions.fetchPrefillEmployer(employerId)),
    onFetchEditableJob: (jobId, type) => dispatch(PostingActions.fetchEditableJob(jobId, type)),
    onEmployerChange: employer => dispatch(PostingActions.setEmployer(employer)),
    onFatchManager: managers => dispatch(PostingActions.fetchManagers(managers)),
    onNext: (id, editType, isIncludeWorker = false) => dispatch(PostingActions.postJob(id, editType, isIncludeWorker)),
    doneLoading: () => dispatch(PostingActions.setIsLoading(false)),
});

export default connect(mapState, mapActions)(PostJobView);
