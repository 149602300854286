import React, { useCallback } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import Avatar from "@mui/material/Avatar";
import withStyles from '@mui/styles/withStyles';
import { ChevronRight } from "@mui/icons-material";
import _debounce from "lodash/debounce";
import Button from "components/Button/Button";
import { formatInitials } from "utils/stringUtils";
import { actions as WorkerActions } from "store/modules/workers";
import { tabStyles } from "./helpers/styles";
import { sharedProps } from "./helpers/tableProps";
import ReactTableCustom from "components/ReactTable";
import { MenuItem, Select } from "@mui/material";

export const formatPhone = input => {
    if (input) {
        const phone = input.replace(/\D/g, "").match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
        return !phone[2] ? phone[1] : `(${phone[1]}) ${phone[2]}${phone[3] ? `-${phone[3]}` : ""}`;
    }
    return;
};
const EditWorkerButton = () => {
    return <Button icon={<ChevronRight />} color="transparent" size="small" onClick={() => { }} />;
};

const TableWrapper = (props) => {
    const {
        list,
        isLoading,
        totalCount,
        currentPage,
        onPageSizeChange,
        onPageChange,
        onFilteredChange,
        onSortedChange,
        pageFilter,
        PageSize
    } = props;

    const columns = [
        {
            Header: "",
            id: "profileImage",
            disableSortBy: true,
            disableFilters: true,
            width: 80,
            Cell: ({ row }) => (
                <Avatar src={row.original.signedProfileImageUrl}>
                    {formatInitials(row.original.firstName, row.original.lastName, "")}
                </Avatar>
            ),
        },
        {
            Header: "First Name",
            id: "firstName",
            disableSortBy: true,
            disableFilters: true,
            accessor: ({ firstName }) => firstName || <i> Empty </i>,
        },
        {
            Header: "Last Name",
            id: "lastName",
            disableSortBy: true,
            disableFilters: true,
            accessor: ({ lastName }) => lastName || <i> Empty </i>,
        },
        {
            Header: "Email",
            id: "email",
            accessor: ({ email }) => email || <i> Empty </i>,
            minWidth: 200,
            disableSortBy: true,
            disableFilters: true,
        },
        {
            Header: "Phone",
            disableSortBy: true,
            disableFilters: true,
            id: "phone",
            accessor: ({ phone }) => formatPhone(phone) || <i> Empty </i>,
        },

        {
            Header: "Rating",
            id: "rating",
            minWidth: 100,
            disableSortBy: true,
            accessor: ({ rating }) => {
                if (!rating) {
                    return <i>Not rated.</i>;
                }

                return rating;
            },
            Filter: ({ column, setFilter }) => (
                <div>
                    <Select
                        disableUnderline={false}
                        variant={'standard'}
                        onChange={event => setFilter(column.id, event.target.value.trim())}
                        value={column.filterValue ? column.filterValue || " " : " "}>
                        <MenuItem value=" ">All</MenuItem>
                        <MenuItem value="5">5</MenuItem>
                        <MenuItem value="4">4 +</MenuItem>
                        <MenuItem value="3">3 +</MenuItem>
                        <MenuItem value="2">2 +</MenuItem>
                    </Select>
                </div>
            ),
            style: {
                textAlign: "center",
            },
            headerStyle: {
                textAlign: "center",
            },
        },
        {
            Header: "Sin",
            id: "sin",
            accessor: ({ sin }) => (sin ? "*** *** " + sin.substr(sin.length - 3) : <i> Empty </i>),
            disableSortBy: true,
            disableFilters: true,
        },
        {
            Header: "Location",
            id: "city",
            accessor: ({ city, region }) => (city ? city + ", " + region : <i> Empty </i>),
        },
        {
            Header: "",
            id: "actions",
            accessor: ({ actions }) => actions,
            collapse: true,
            style: { textAlign: "right" },
            disableSortBy: true,
            disableFilters: true,
        },
    ];
    const customTableCellProps = (row, cell, e) => {
        if (row && row.original && cell.column.id !== "actions" && cell.column.id !== "badge") {
            props.history.push("/workers/" + row.original.id);
        }
        return;
    };

    const fetchData = useCallback(({ pageIndex, pageSize, filters, sortBy }) => {
        onSortedChange(sortBy)
        onFilteredChange(filters)
        onPageSizeChange(pageSize)
        onPageChange(pageIndex)
    }, []);

    return (
        <ReactTableCustom
            fetchData={fetchData}
            loading={isLoading}
            data={list}
            pageCount={totalCount}
            defaultFilter={pageFilter}
            currentPage={currentPage}
            defaultPageSize={PageSize}
            {...sharedProps}
            columns={columns}
            onRowClick={customTableCellProps}
        />
    );
}

class WaitlistTable extends React.Component {
    render() {
        return (
            <TableWrapper {...this.props} />
        );
    }
}

const mapState = (state, ownProps) => {
    const {
        waitlistToday,
        waitlistTomorrow,
        waitlistTodayPageInfo,
        waitlistTomorrowPageInfo,
        waitlistTomorrowCriteria,
        waitlistTodayCriteria,
    } = state.workers;
    const listType = ownProps.listType;
    const todayInfo = waitlistTodayPageInfo;

    return {
        list: listType
            ? Object.keys(waitlistTomorrow).map(id => {
                return {
                    ...waitlistTomorrow[id],
                    actions: <EditWorkerButton disabled worker={waitlistTomorrow[id]} />,
                };
            })
            : Object.keys(waitlistToday).map(id => {
                return {
                    ...waitlistToday[id],
                    actions: <EditWorkerButton worker={waitlistToday[id]} />,
                };
            }),
        totalCount: listType ? waitlistTomorrowPageInfo.totalCount : todayInfo.totalCount,
        PageSize: listType ? waitlistTomorrowPageInfo.pageSize : todayInfo.pageSize,
        PageSort: listType ? waitlistTomorrowPageInfo.pageSort : todayInfo.pageSort,
        currentPage: listType ? waitlistTomorrowPageInfo.currentPage : todayInfo.currentPage,
        isLoading: listType ? waitlistTomorrowPageInfo.isLoading : todayInfo.isLoading,
        listType,
        waitlistTomorrowCriteria,
        waitlistTodayCriteria,
    };
};

const mapActions = (dispatch, ownProps) => {
    const setTabType = () => {
        switch (ownProps.listType) {
            case 0:
                return "waitlistToday";
            case 1:
                return "waitlistTomorrow";
            default:
                return "waitlistToday";
        }
    };
    return {
        onPageSizeChange: size => dispatch(WorkerActions.changePageSize(size, setTabType())),
        onPageChange: idx => {
            return dispatch(WorkerActions.fetchTableData(idx, setTabType()));
        },
        onSortedChange: sort => dispatch(WorkerActions.changeSort(sort, setTabType())),
        onFilteredChange: data => {
            return dispatch(WorkerActions.changeFilter(data, setTabType()));
        },
        onCriteriaChange: data => {
            return dispatch(WorkerActions.changeCriteria(data, setTabType()));
        },
    };
};
export default connect(mapState, mapActions)(withStyles(tabStyles)(withRouter(WaitlistTable)));
