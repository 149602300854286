import React, { useCallback, useState } from "react";

import * as moment from "moment-timezone";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Paper from "@mui/material/Paper";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";

import Typography from "@mui/material/Typography";
import { AccountBalanceWalletTwoTone, MoreVert, Receipt } from "@mui/icons-material";
import { CustomTableCell, reversalsTabTableActionsStyles } from "views/Finance/helpers";
import Dialog from "components/Dialog/PaymentDialog";
import { formatMoney } from "utils/stringUtils";

function ReversalsTabTableActions({
  record,
  reversal,
  handleClickOpenCancelDialog,
  handleClickOpenResolveReversalDialog,
  handleClickOpenResendReversalDialog,
}) {
  const { status } = reversal;
  const classes = reversalsTabTableActionsStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [openReversalDialog, setOpenReversalDialog] = useState(false);
  const menuId = `action-menu-`;
  const openMenu = useCallback(
    e => {
      setAnchorEl(e.currentTarget);
    },
    [anchorEl]
  );

  const closeMenu = useCallback(() => {
    setAnchorEl(null);
  }, [anchorEl]);
  const closePayroll = () => {
    setOpenReversalDialog(false);
  };

  const RenderReversalCard = () => {
    const { amount, reversedDcTransferId, dcTransferId, paymentId, payrollId, status, id } = record;
    return (
      <Grid container alignItems="flex-start" item xs={12} md={6}>
        <Typography variant="h6" align="left" style={{ width: "100%" }}>
          Reversal Request Details
        </Typography>

        <Grid item xs={12} md={12}>
          <TableContainer component={Paper} elevation={0}>
            <Table size="small" className={classes.table} aria-label="simple table">
              <TableBody>
                <TableRow>
                  <CustomTableCell component="th" scope="row">
                    Sent
                  </CustomTableCell>
                  <CustomTableCell align="right">
                    {moment(record.sentAt).format("MMM Do, YYYY")}
                  </CustomTableCell>
                </TableRow>
                <TableRow>
                  <CustomTableCell component="th" scope="row">
                    Amount
                  </CustomTableCell>

                  <CustomTableCell align="right">{formatMoney(amount) || ""}</CustomTableCell>
                </TableRow>
                <TableRow>
                  <CustomTableCell component="th" scope="row">
                    Payroll Id
                  </CustomTableCell>
                  <CustomTableCell align="right">{payrollId || ""}</CustomTableCell>
                </TableRow>
                <TableRow>
                  <CustomTableCell component="th" scope="row">
                    Reversal Id
                  </CustomTableCell>
                  <CustomTableCell align="right">{id || ""}</CustomTableCell>
                </TableRow>
                <TableRow>
                  <CustomTableCell component="th" scope="row">
                    Payment Id
                  </CustomTableCell>
                  <CustomTableCell align="right">{paymentId || ""}</CustomTableCell>
                </TableRow>
                <TableRow>
                  <CustomTableCell component="th" scope="row">
                    TransferId
                  </CustomTableCell>
                  <CustomTableCell align="right">{dcTransferId || ""}</CustomTableCell>
                </TableRow>
                <TableRow>
                  <CustomTableCell component="th" scope="row">
                    Reversed DcTransfer Id
                  </CustomTableCell>
                  <CustomTableCell align="right">{reversedDcTransferId || ""}</CustomTableCell>
                </TableRow>
                <TableRow>
                  <CustomTableCell component="th" scope="row">
                    Status
                  </CustomTableCell>
                  <CustomTableCell align="right">
                    {status ? status.description : "Unknown"}
                  </CustomTableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    );
  };

  const RenderEffectedTransferCard = () => {
    const { name, amount, dcStatus, interacStatus, eTransferHistory } = record.reversedDcTransfer;
    const transferHistory =
      eTransferHistory && eTransferHistory.length ? eTransferHistory[0] : null;
    return (
      <Grid container item xs={12} md={6} alignItems="flex-start">
        <Typography variant="h6" align="left" style={{ width: "100%" }}>
          Effected Transfer Details
        </Typography>

        <Grid item xs={12} md={12}>
          <TableContainer component={Paper} elevation={0}>
            <Table size="small" className={classes.table} aria-label="simple table">
              <TableBody>
                <TableRow>
                  <CustomTableCell component="th" scope="row">
                    Sent
                  </CustomTableCell>
                  <CustomTableCell align="right">
                    {moment(record.sentAt).format("MMM Do, YYYY")}
                  </CustomTableCell>
                </TableRow>
                <TableRow>
                  <CustomTableCell component="th" scope="row">
                    Recipient
                  </CustomTableCell>
                  <CustomTableCell align="right">{name || ""}</CustomTableCell>
                </TableRow>
                <TableRow>
                  <CustomTableCell component="th" scope="row">
                    Amount
                  </CustomTableCell>
                  <CustomTableCell align="right">{formatMoney(amount) || ""}</CustomTableCell>
                </TableRow>
                <TableRow>
                  <CustomTableCell component="th" scope="row">
                    Status
                  </CustomTableCell>

                  <CustomTableCell align="right">{dcStatus || ""}</CustomTableCell>
                </TableRow>
                <TableRow>
                  <CustomTableCell component="th" scope="row">
                    Interac Status
                  </CustomTableCell>
                  <CustomTableCell align="right">{interacStatus || ""}</CustomTableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
        <Grid item xs={12} md={12}>
          <TableContainer component={Paper} elevation={0}>
            <Table size="small" className={classes.table} aria-label="simple table">
              <TableBody>
                <TableRow>
                  <CustomTableCell component="th" scope="row">
                    Status Updated
                  </CustomTableCell>
                  <CustomTableCell align="right">
                    {(transferHistory &&
                      transferHistory.responseAt &&
                      moment(transferHistory.responseAt).format("MMM Do, YYYY")) ||
                      ""}
                  </CustomTableCell>
                </TableRow>

                <TableRow>
                  <CustomTableCell colSpan={2} component="th" scope="row">
                    Notes
                  </CustomTableCell>
                </TableRow>
                <TableRow>
                  <CustomTableCell colSpan={2} align="left">
                    {(transferHistory && transferHistory.notes) || ""}
                  </CustomTableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    );
  };

  const RenderDialog = () => {
    return (
      <Dialog
        open={openReversalDialog}
        title={`Generate New Timesheet?`}
        confirmText="Save & Generate Timesheet"
        onClose={() => closePayroll()}
        onConfirm={() => setOpenReversalDialog(false)}
        onCancel={() => closePayroll()}
        maxWidth={`md`}
        alertType="form"
        fullWidth={true}
        icon="attach_file"
        size="md"
        table={
          <Grid
            container
            alignItems="flex-start"
            item
            xs={12}
            style={{ width: "100%", display: "block" }}>
            {record ? (
              <Grid container alignItems="flex-start" item xs={12} direction="row">
                <RenderReversalCard />
                <RenderEffectedTransferCard />
              </Grid>
            ) : (
              ""
            )}
          </Grid>
        } />
    );
  };

  return (
    <>
      <RenderDialog />

      <IconButton
        aria-controls={menuId}
        aria-haspopup="true"
        onClick={openMenu}
        size="small"
        disableRipple={false}>
        <MoreVert color="primary" />
      </IconButton>
      {status && (
        <Menu
          id={menuId}
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={closeMenu}>
          {/* <MenuItem
            onClick={() => {
              handleViewProfile(workerId);
              closeMenu();
            }}>
            <Receipt className={classes.icon} color="action" />
            View Worker Profile
          </MenuItem> */}

          {/**/}
          <MenuItem
            onClick={() => {
              setOpenReversalDialog(true);
              closeMenu();
            }}>
            <Receipt className={classes.icon} color="action" />
            View Reversal Details
          </MenuItem>
          <MenuItem
            disabled={![7, 8, 10].includes(record.paymentStatusId)}
            onClick={() => {
              handleClickOpenResolveReversalDialog(record);
              closeMenu();
            }}>
            <AccountBalanceWalletTwoTone className={classes.icon} color="action" />
            Resolve Reversal
          </MenuItem>

          <MenuItem
            disabled={![7, 8, 10, 11, 13].includes(record.paymentStatusId)}
            onClick={() => {
              handleClickOpenResendReversalDialog(record);
              closeMenu();
            }}>
            <Receipt className={classes.icon} color="action" />
            Resend Reversal
          </MenuItem>

          <MenuItem
            hidden
            disabled={[13, 11, 7, 8].includes(record.paymentStatusId)}
            onClick={() => {
              handleClickOpenCancelDialog(record);
              closeMenu();
            }}>
            <Receipt className={classes.icon} color="action" />
            Cancel Reversal
          </MenuItem>
        </Menu>
      )}
    </>
  );
}

export default ReversalsTabTableActions;
