import React from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Dialog from "components/Dialog/Dialog";
import { formatMoney } from "utils/stringUtils";
import { selectors as JobDetailSelectors } from "store/modules/jobDetails";

function IncreasePositionsDialog(props) {
  const { open, onClose, onCancel, onConfirm, value, onChange } = props;
  const billingRate = useSelector(JobDetailSelectors.getEmployerBillingRate);
  const durationMinsLessBreak = useSelector(JobDetailSelectors.getJobDurationMinsLessBreak);

  const increaseBilling = (durationMinsLessBreak / 60) * billingRate * parseInt(value, 10);

  return (
    <Dialog
      open={open}
      title="How many more positions?"
      description={null}
      onClose={onClose}
      onConfirm={onConfirm}
      onCancel={onCancel}
      alertType="question"
      icon="exposure_plus_1">
      <Grid container direction="column" alignItems="center">
        <Typography variant="body1" align="center">
          {value !== ""
            ? `The bill will increase by ${formatMoney(increaseBilling)}`
            : `This action will affect billing.`}
        </Typography>
        <TextField
          placeholder="Increase by..."
          value={value}
          onChange={onChange}
          type="number"
          margin="normal"
          inputProps={{ min: 1 }}
        />
      </Grid>
    </Dialog>
  );
}

IncreasePositionsDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
};

export default IncreasePositionsDialog;
