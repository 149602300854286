// Loading selectors
export const getLoadingStateFactory = loader => state => {
    return state.jobTemplate.loading[loader] || false;
};

export const getIsTableLoading = getLoadingStateFactory("tableLoading");

// Dialog selectors
export const getDialogStateFactory = loader => state => {
    return state.jobTemplate.dialog[loader] || false;
};

export const getIsRemoveDialog = getDialogStateFactory("removeDialog");

// Job Template selectors

export const getJobTemplateList = state => {
    return state.jobTemplate.jobTemplates;
};

export const getJobTemplate = state => {
    return state.jobTemplate.jobTemplate;
};

export const getJobTypeList = state => {
    const { jobTypeList } = state.jobTemplate;
    return jobTypeList;
};


