import { gql } from "@apollo/client";
import EmployerFields from "../fragments/employer-fragment";

export default gql`
  query getEmployer($id: ID!) {
    employer(id: $id) {
      profileImageUrl
      signedProfileImageUrl
      logoImageUrl
      signedLogoImageUrl
      ...EmployerFields
    }
    hoursTrendData: getHoursTrendByEmployer(employerId: $id) {
      monthTimestamp
      hoursWorked
    }
  }
  ${EmployerFields}
`;
