import { emphasize } from '@mui/material/styles';

export default theme => ({
  input: {
    display: "flex",
    padding: "8px 8px",
  },
  valueContainer: {
    display: "flex",
    flexWrap: "wrap",
    flex: 1,
    alignItems: "center",
    overflow: "hidden",
  },
  chip: {
    margin: `${theme.spacing(0.5)} ${theme.spacing(0.25)}`,
  },
  chipFocused: {
    backgroundColor: emphasize(
      theme.palette.mode === "light" ? theme.palette.grey[300] : theme.palette.grey[700],
      0.08,
    ),
  },
  noOptionsMessage: {
    padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
  },
  singleValue: {
    fontSize: 16,
    lineHeight: 1
  },
  placeholder: {
    position: "absolute",
    left: 10,
    fontSize: 14,
    color: '#a4a7ab'
  },
  paper: {
    position: "absolute",
    zIndex: 1,
    marginTop: theme.spacing(1),
    left: 0,
    right: 0,
  },
});
