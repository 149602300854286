import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import withStyles from '@mui/styles/withStyles';
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { ChevronRight, Visibility, VisibilityOff } from "@mui/icons-material";
import LoginImage from "assets/img/gf-spark-illustration.svg";
import logo from "assets/img/grizzly-force-logo-horizontal-white.svg";
import Button from "components/Button/Button";
import Link from "components/CustomButtons/Button.jsx";
import Dialog from "components/Dialog/Dialog";
import { actions as LoginActions, selectors as LoginSelectors } from "store/modules/login";
import LoginStyles from "./styles";

class LoginPage extends Component {
  constructor(props) {
    super(props);
    this.state = { showPassword: false, showPasswordReset: false };
  }

  handleClickShowPassword = () => {
    this.setState(state => ({ showPassword: !state.showPassword }));
  };

  showPasswordReset = () => {
    this.setState({ showPasswordReset: true });
  };

  hidePasswordReset = () => {
    this.setState({ showPasswordReset: false });
  };

  handlePasswordReset = () => {
    this.setState({ showPasswordReset: false }, this.props.sendPasswordReset);
  };

  handleEmailChange = event => {
    this.props.onEmailChange(event.target.value);
  };

  handleSubmit = event => {
    event.preventDefault();
    if (this.props.isValidLoginData) {
      this.props.login();
    }
  };

  handlePasswordChange = event => {
    this.props.onPasswordChange(event.target.value);
  };

  render() {
    const {
      classes,
      email,
      password,
      onEmailChange,
      rememberUser,
      forgetUser,
      isValidLoginData,
      login,
      onPasswordChange,
    } = this.props;

    return (
      <Grid container className={classes.pageContainer}>
        <Grid
          container
          item
          md={6}
          justifyContent="center"
          alignContent="center"
          className={classes.loginContainer}>
          <Grid
            container
            justifyContent="center"
            alignContent="center"
            item
            md={6}
            className={classes.loginContainer}>
            <form onSubmit={this.handleSubmit}>
              <Grid item className={classes.logo}>
                <img alt="Grizzly Force Logo" src={logo} />
              </Grid>

              <Grid item md={12} className={classes.loginItemContainer}>
                {!rememberUser ? (
                  <TextField
                    fullWidth
                    id="email"
                    InputLabelProps={{
                      style: { color: "#929FB0" },
                    }}
                    InputProps={{
                      classes: {
                        root: classes.inputStyle,
                      },
                    }}
                    label="Email"
                    value={email}
                    margin="normal"
                    autoFocus
                    onChange={this.handleEmailChange}
                    onKeyPress={event => {
                      if (event.key === "Enter") {
                        return this.handleSubmit(event);
                      }
                    }}
                  />
                ) : (
                  <Grid item container xs={12} justifyContent="center" alignContent="center">
                    <Grid item xs={12} className={classes.labelStyle}>
                      <Typography variant="body1" className={classes.labelStyle}>
                        Email
                      </Typography>
                    </Grid>
                    <Grid item xs={9}>
                      <Typography
                        variant="subtitle2"
                        style={{ flex: 1, minHeight: 34 }}
                        className={classes.emailStyle}>
                        {email}
                      </Typography>
                    </Grid>
                    <Grid item xs={3}>
                      <Link link className={classes.linkStyle} size="sm" onClick={forgetUser}>
                        change user
                      </Link>
                    </Grid>
                  </Grid>
                )}
              </Grid>

              <Grid item md={12} className={classes.loginItemContainer}>
                <TextField
                  fullWidth
                  id="password"
                  label="Password"
                  value={password}
                  margin="normal"
                  onChange={event => {
                    onPasswordChange(event.target.value);
                  }}
                  onKeyPress={event => {
                    if (event.key === "Enter") {
                      return this.handleSubmit(event);
                    }
                  }}
                  type={this.state.showPassword ? "text" : "password"}
                  InputLabelProps={{
                    style: { color: "#929FB0" },
                  }}
                  InputProps={{
                    classes: {
                      root: classes.inputStyle,
                    },
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={this.handleClickShowPassword} size="large">
                          {this.state.showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  autoComplete="current-password"
                  helperText={
                    <Link
                      color="info"
                      link
                      size="sm"
                      onClick={this.showPasswordReset}
                      className={classes.linkStyle}>
                      forget password?
                    </Link>
                  }
                />
              </Grid>
              <Grid item md={12} className={classes.buttonContainer}>
                <Button
                  label="Login"
                  icon={<ChevronRight />}
                  alignIcon="right"
                  size="medium"
                  type="submit"
                  onClick={login}
                  disabled={!isValidLoginData}
                />
              </Grid>
            </form>
          </Grid>
        </Grid>

        <Grid item lg={6} className={classes.imageContainer}>
          <img alt="Grizzly Force Login" src={LoginImage} />
        </Grid>

        <Dialog
          open={this.state.showPasswordReset}
          title="Send Password Reset Email"
          description="Send a password reset email to the address below:"
          confirmText="Send Email"
          onClose={this.hidePasswordReset}
          onConfirm={this.handlePasswordReset}
          onCancel={this.hidePasswordReset}
          alertType="form">
          <TextField
            fullWidth
            value={email}
            onChange={event => onEmailChange(event.target.value)}
          />
        </Dialog>
      </Grid>
    );
  }
}

LoginPage.propTypes = {
  classes: PropTypes.object.isRequired,
  isValidLoginData: PropTypes.bool.isRequired,
  rememberUser: PropTypes.bool.isRequired,
  email: PropTypes.string.isRequired,
  password: PropTypes.string.isRequired,
  onEmailChange: PropTypes.func.isRequired,
  onPasswordChange: PropTypes.func.isRequired,
  login: PropTypes.func.isRequired,
  sendPasswordReset: PropTypes.func.isRequired,
  forgetUser: PropTypes.func.isRequired,
};

const mapState = state => {
  const { rememberUser, email, password } = state.login;
  return {
    isValidLoginData: LoginSelectors.getisValidLoginData(state),
    rememberUser,
    email,
    password,
  };
};

const mapActions = {
  onEmailChange: LoginActions.setEmail,
  onPasswordChange: LoginActions.setPassword,
  login: LoginActions.login,
  sendPasswordReset: LoginActions.sendPasswordReset,
  forgetUser: LoginActions.forgetUser,
};

export default connect(mapState, mapActions)(withStyles(LoginStyles)(LoginPage));
