import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    Button,
    DialogActions,
    FormControlLabel,
    Checkbox,
    CircularProgress,
    Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { remove, xorBy } from "lodash";
import { actions as AdminActions, selectors as AdminSelectors } from "store/modules/admins";
import classNames from "classnames";

const useStyles = makeStyles((theme) => ({
    mainSection: {
        textAlign: 'left'
    },
    checkboxLabel: {
        border: "1px solid",
        paddingRight: "10px",
        borderRadius: "5px",
        marginBottom: "10px",
    },
    footer: {
        margin: 0,
        textAlign: "center",
        justifyContent: "center",
    },
    loader: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: theme.spacing(2)
    },
    verticalePadding: {
        padding: theme.spacing(2, 0)
    }
}));
function AssignPermission({ hideAssignDialog, permissionList, data }) {
    const dispatch = useDispatch();
    const classes = useStyles();
    const [selected, setSelected] = useState([]);
    // const [otherPermission, setOtherPermission] = useState([]);
    const isLoading = useSelector(AdminSelectors.getIsUpdatePermissionLoading);
    const unAssignedAdminPermissions = useSelector(AdminSelectors.getUnAssignedAdminPermissionList);
    const getUnAssignedPermissionsLoading = useSelector(AdminSelectors.getUnAssignedPermissionsLoading);

    useEffect(() => {
        dispatch(AdminActions.fetchUnAssignedAdminPermissions(data.id));
    }, []);
    // useEffect(() => {
    //   setOtherPermission(xorBy(
    //     unAssignedAdminPermissions.map(n => ({ permission_name: n.value })),
    //     permissionList.map(n => ({ permission_name: n.permission_name })),
    //     "permission_name",
    //   ));
    // }, [unAssignedAdminPermissions])
    const onSubmit = () => {
        if (selected.length > 0) {
            dispatch(AdminActions.updateAdminPermissions(data.id, selected.map(p => ({ resource_server_identifier: p.resource_server_identifier, permission_name: p.permission_name }))));
        }
    };

    const changeCheckbox = (data, event) => {
        const temp = [...selected];
        data.resource_server_identifier = process.env.REACT_APP_AUTH0_AUDIENCE;
        if (event.target.checked) {
            temp.push(data)
        } else {
            remove(temp, n => n.permission_name === data.permission_name)
        }
        setSelected(temp);
    }

    return (
        getUnAssignedPermissionsLoading ?
            <div className={classes.loader}>
                <CircularProgress size={20} />
            </div>
            : (<React.Fragment>
                {unAssignedAdminPermissions.length ? <div className={classNames(classes.mainSection, classes.verticalePadding)}>
                    {unAssignedAdminPermissions.map(n => (
                        <FormControlLabel
                            className={classes.checkboxLabel}
                            key={n.permission_name}
                            control={<Checkbox onChange={(e) => changeCheckbox(n, e)} name="permission" />}
                            label={n.permission_name}
                        />
                    ))}
                </div> :
                    <Typography className={classes.verticalePadding}>There is no unassigned permission for this user</Typography>}
                <DialogActions className={classes.footer}>
                    <Button type="button" className="outline" onClick={hideAssignDialog}>
                        Close
                    </Button>
                   {!!unAssignedAdminPermissions.length && <Button
                        variant="contained"
                        color="secondary"
                        type="button"
                        onClick={onSubmit}
                        disabled={selected.length === 0 || isLoading}
                    >
                        Save
                    </Button>}
                </DialogActions>
            </React.Fragment>)
    );
}

export default AssignPermission;
