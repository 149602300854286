/* eslint-disable react/prop-types */
// findPayrollByPositionId
import React from "react";
import moment from "moment-timezone";
import Grid from "@mui/material/Grid";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import { invoiceTableStyles } from "views/Finance/helpers";
import { formatMoney, formatNumber } from "utils/stringUtils";
const isMobile = false;
function InvoiceTable(props) {
  const { payroll, classes } = props;

  const lastRow = {
    paddingLeft: 2,
    border: isMobile ? "inherit" : "0px solid transparent",
    borderBottom: isMobile ? "1px solid rgb(224 224 224)" : "inherit",
  };

  const RenderPayrollAdjustments = () => {
    return (
      <React.Fragment>
        <Grid container item xs={12} style={{ padding: 0 }}>
          {payroll &&
            payroll.map((adjustments, i) => (
              <Grid container key={i} item xs={12} style={{ padding: 0 }}>
                <Grid container item xs={12} style={{ padding: 0 }}>
                  <Typography variant="h6" style={{ fontSize: 14, marginTop: 15, width: "100%" }}>
                    {adjustments.adjustmentType === "wage"
                      ? `Wage Adjustment`
                      : adjustments.adjustmentType === "bonus"
                      ? `Bonus Adjustment`
                      : adjustments.adjustmentType === "break"
                      ? `Break Adjustment`
                      : ``}
                    <span style={{ fontSize: 11, float: "right" }}>
                      {" "}
                      {moment(adjustments.createdAt).format("MMM Do, YYYY h:mm a")}
                    </span>
                  </Typography>
                </Grid>
                <Grid container item xs={12} style={{ padding: 0 }}>
                  <Typography variant="h6" style={{ fontSize: 13, marginTop: 1, width: "100%" }}>
                    {adjustments.companyName}
                    <span style={{ fontSize: 11, marginLeft: 10 }}>
                      {" "}
                      {moment(adjustments.jobDate).format("MMM Do, YYYY h:mm a")}
                    </span>
                  </Typography>
                </Grid>
                <Grid item xs={12} md={6} style={{ padding: 0 }}>
                  <Table
                    className={classes.table.root}
                    aria-labelledby="tableTitle2"
                    size={"small"}
                    aria-label="enhanced table2">
                    <TableBody>
                      <React.Fragment>
                        <TableRow>
                          <TableCell style={lastRow}>Worker</TableCell>
                          <TableCell style={lastRow} align="right">
                            {adjustments.firstName} {adjustments.lastName}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell style={lastRow}>Payroll Id</TableCell>
                          <TableCell style={lastRow} align="right">
                            {adjustments.payrollId}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell style={lastRow}>Position Id</TableCell>
                          <TableCell style={lastRow} align="right">
                            {adjustments.positionId}
                          </TableCell>
                        </TableRow>
                      </React.Fragment>
                      {adjustments.adjustmentType === "wage" && (
                        <React.Fragment>
                          <TableRow>
                            <TableCell style={lastRow}>Initial Rate</TableCell>
                            <TableCell style={lastRow} align="right">
                              {formatMoney(adjustments.hourlyPayRate)}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell style={lastRow}>Difference</TableCell>
                            <TableCell style={lastRow} align="right">
                              {formatMoney(adjustments.hourlyPayRate - adjustments.hourlyPayRate)}
                            </TableCell>
                          </TableRow>
                        </React.Fragment>
                      )}

                      {adjustments.adjustmentType === "bonus" && (
                        <React.Fragment>
                          <TableRow>
                            <TableCell style={lastRow}>Original Pay Rate</TableCell>
                            <TableCell style={lastRow} align="right">
                              {formatMoney(adjustments.hourlyPayRate)}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell style={lastRow}>Billable Hours</TableCell>
                            <TableCell style={lastRow} align="right">
                              {formatNumber(adjustments.hoursWorkedLessBreak)} Hrs
                            </TableCell>
                          </TableRow>
                        </React.Fragment>
                      )}

                      {adjustments.adjustmentType === "break" && (
                        <React.Fragment>
                          <TableRow>
                            <TableCell style={lastRow}>Break</TableCell>
                            <TableCell style={lastRow} align="right">
                              {adjustments.breakValue
                                ? formatNumber(adjustments.breakValue)
                                : "No Break"}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell style={lastRow}>Billable Hours</TableCell>
                            <TableCell style={lastRow} align="right">
                              {formatNumber(adjustments.hoursWorkedLessBreak)} Hrs
                            </TableCell>
                          </TableRow>
                        </React.Fragment>
                      )}

                      <TableRow>
                        <TableCell style={lastRow}>Gross Adjustment</TableCell>
                        <TableCell style={lastRow} align="right">
                          {formatMoney(adjustments.grossPay)}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </Grid>
                <Grid item xs={12} md={6} style={{ padding: 0 }}>
                  <Table
                    className={classes.table.root}
                    aria-labelledby="tableTitle2"
                    size={"small"}
                    aria-label="enhanced table2">
                    <TableBody>
                      <React.Fragment>
                        <TableRow>
                          <TableCell style={lastRow}>Email</TableCell>
                          <TableCell style={lastRow} align="right">
                            {adjustments.email}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell style={lastRow}>Job Id</TableCell>
                          <TableCell style={lastRow} align="right">
                            {adjustments.jobId}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell style={lastRow}>Adjusted Payroll Id</TableCell>
                          <TableCell style={lastRow} align="right">
                            {adjustments.adjustedPayrollId}
                          </TableCell>
                        </TableRow>
                      </React.Fragment>

                      {adjustments.adjustmentType === "wage" && (
                        <React.Fragment>
                          <TableRow>
                            <TableCell style={lastRow}>New Hourly Rate</TableCell>
                            <TableCell style={lastRow} align="right">
                              {formatMoney(adjustments.hourlyPayRate)}
                            </TableCell>
                          </TableRow>
                        </React.Fragment>
                      )}

                      {adjustments.adjustmentType === "bonus" && (
                        <TableRow>
                          <TableCell style={lastRow}>Original Pay Rate</TableCell>
                          <TableCell style={lastRow} align="right">
                            {formatMoney(adjustments.hourlyPayRate)}
                          </TableCell>
                        </TableRow>
                      )}

                      {adjustments.adjustmentType === "break" && (
                        <TableRow>
                          <TableCell style={lastRow}>Break</TableCell>
                          <TableCell style={lastRow} align="right">
                            {adjustments.breakValue
                              ? formatNumber(adjustments.breakValue)
                              : "No Break"}
                          </TableCell>
                        </TableRow>
                      )}

                      <TableRow>
                        <TableCell style={lastRow}>Billable Hours</TableCell>
                        <TableCell style={lastRow} align="right">
                          {formatNumber(adjustments.hoursWorkedLessBreak)} Hrs
                        </TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell style={lastRow}>Net Adjustment</TableCell>
                        <TableCell style={lastRow} align="right">
                          {formatMoney(adjustments.netPay)}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </Grid>
              </Grid>
            ))}
        </Grid>
      </React.Fragment>
    );
  };

  const RenderPayrollDetails = () => {
    return (
      <React.Fragment>
        <Grid container item xs={12} style={{ padding: 0 }}>
          <Typography variant="h6" style={{ fontSize: 14, width: "100%" }}>
            Payroll Details
          </Typography>
        </Grid>
      </React.Fragment>
    );
  };

  return (
    <Grid container item xs={12} style={{ padding: 0 }}>
      <RenderPayrollDetails />
      <RenderPayrollAdjustments />
    </Grid>
  );
}

export default invoiceTableStyles(InvoiceTable);
