import { gql } from "@apollo/client";
import PositionFields from "../fragments/position-fragment";
import PositionWorkerFields from "../fragments/position-worker-fragment";

export default gql`
  mutation confirmWorker($employerId: String!, $data: ConfirmInput!) {
    confirmWorker(data: $data) {
      ...PositionFields
      worker {
        ...PositionWorkerFields
        positionCountByEmployer(employerId: $employerId)
      }
    }
  }
  ${PositionFields}
  ${PositionWorkerFields}
`;
