import { gql } from "@apollo/client";

export default gql`
  fragment PaymentFields on Payment {
    id
    email
    paymentProviderReferenceCode
    # amount
    errorCode

    errorDescription
    paymentStatus {
      id
      code
      description
    }
    payroll {
      id
      createdAt
      payrollTypeId
      positionId
      taxRegionId
      employmentStandardsRegionId
      rate
      checkIn
      checkOut
      # checkOutUtc
      checkInUtc
      breakDeductionMins
      bonus
      totalUnits
      payableUnits

      payrollType {
        id
        name
        code
      }
      payments {
        id
        email
        paymentProviderReferenceCode
        # amount
        errorCode

        errorDescription
        paymentStatus {
          id
          code
          description
        }
        payroll {
          id
          positionId
          rate
          checkIn
          checkOut
          # checkOutUtc
          checkInUtc
          breakDeductionMins
          totalUnits
          payableUnits
          grossWages
          grossEarnings
          netEarnings
          totalEmployeeDeductions
          payrollType {
            id
            name
            code
          }
          position {
            id
            jobId
            worker {
              id
              fullName
              phone
              street
              city
              region
              country
              postalCode
              dcCustomerNumber
            }
            lastAcceptedPayroll {
              id
              positionId
              rate
              checkIn
              checkOut
              # checkOutUtc
              checkInUtc
              breakDeductionMins
              totalUnits
              payableUnits
              grossWages
              grossEarnings
              netEarnings
              totalEmployeeDeductions
              payrollType {
                id
                name
                code
              }
              wageEarnings {
                id
                payrollId
                payrollEarningsTypeId
                rate
                units
                amount
              }
              additionalEarnings {
                id
                payrollId
                payrollEarningsTypeId
                rate
                units
                amount
              }
              earnings {
                id
                payrollId
                payrollEarningsTypeId
                rate
                units
                amount
              }
              employeeDeductions {
                id
                payrollId
                payrollDeductionsTypeId
                amount
              }
            }
          }
          wageEarnings {
            id

            payrollId
            payrollEarningsTypeId
            rate
            units
            amount
          }
          additionalEarnings {
            id

            payrollId
            payrollEarningsTypeId
            rate
            units
            amount
          }
          earnings {
            id

            payrollId
            payrollEarningsTypeId
            rate
            units
            amount
          }
          employeeDeductions {
            id
            payrollId
            amount
            payrollDeductionsTypeId
          }
        }
      }
      earnings {
        id

        payrollId
        payrollEarningsTypeId
        rate
        units
        amount
      }
      wageEarnings {
        id
        payrollId
        payrollEarningsTypeId
        rate
        units
        amount
      }
      additionalEarnings {
        id
        payrollId
        payrollEarningsTypeId
        rate
        units
        amount
      }
      employeeDeductions {
        id
        payrollId
        amount
        payrollDeductionsTypeId
      }
      employerDeductions {
        id
        payrollId
        amount
        payrollDeductionsTypeId
      }
      totalEmployeeDeductions
      totalEmployerDeductions
      grossWages
      grossEarnings
      netEarnings
    }
  }
`;
