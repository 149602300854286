import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import "moment-timezone";
import moment from "moment-timezone";
import Grid from "@mui/material/Grid";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { ArrowDropDown, ArrowRight, CancelScheduleSend, Notes, Remove } from "@mui/icons-material";
import { reversalsTabStyles } from "views/Finance/helpers";
import { DcTransferStatusBadge } from "components/Payments/DcBadge";
import { formatMoney } from "utils/stringUtils";
import { actions as FinanceActions, selectors as FinanceSelectors } from "store/modules/finance";
import CancelTransferDialog from "../../Dialogs/CancelTransferDialog";
import ResendDialog from "../../Dialogs/ResendDialog";
import DcReversalsHeader from "../../helpers/DcReversalsHeader";
import { RenderWorkerReversalAvatar } from "../../helpers/WorkerAvatar";
import ReversalsTabTableActions from "./ReversalsTabTableActions";
import ReactTableSimple from "components/ReactTable/ReactTableSimple";
const isMobile = false;
function ReversalsTab({ toggleOpenDcReversal, toggleReviewingPayroll }) {
    const classes = reversalsTabStyles();
    const dispatch = useDispatch();
    const history = useHistory();
    const reversals = useSelector(FinanceSelectors.getReversals);
    const isCancelLoading = useSelector(FinanceSelectors.getIsCancelTransaction);

    const [transferData, setTransferData] = useState();
    const [pmt, setPmt] = useState();
    const [pmtId, setPmtId] = useState(null);
    const [openConfirmResendDialog, setOpenConfirmResendDialog] = useState(false);
    const [openConfirmCancelDialog, setOpenConfirmCancelDialog] = useState(false);
    const [openResolveDialog, setOpenResolveDialog] = useState(false);
    const [, setOpen] = React.useState(false);
    const [, setIsReviewingPayroll] = useState(true);

    const [, setOpenPayrollDialog] = useState(false);
    const [, setOpenConfirmDialog] = useState(false);
    const handleClose = () => {
        setOpen(false);
        setOpenPayrollDialog(false);
        setOpenConfirmDialog(false);
        setOpenConfirmCancelDialog(false);
        setOpenConfirmResendDialog(false);
        setOpenResolveDialog(false);
    };

    const RenderTdProps = () => ({
        onClick: (e, handleOriginal) => {
            if (handleOriginal) {
                handleOriginal();
            }
        },
    });

    const handleViewProfile = workerId => history.push(`/workers/${workerId}`);

    const handleResendReversal = () => {
        dispatch(FinanceActions.retryReversal(pmtId));
        setOpenConfirmResendDialog(false);
    };

    const handleClickOpenResendDialog = dcTransferId => {
        setPmtId(dcTransferId);
        setOpenConfirmResendDialog(true);
    };

    const handleClickOpenResolveDialog = dcTransferId => {
        setPmtId(dcTransferId);
        setOpenResolveDialog(true);
    };

    const handleSetReversalResolved = () => {
        dispatch(FinanceActions.markReversalResolved(pmtId));
        setOpenResolveDialog(false);
    };

    const handleClickOpenCancelDialog = item => {
        setPmt({
            transactionId: parseInt(item.dcTransferId),
            customerNumber: item.dcCustomerNumber,
            reason: "Test reversal request",
        });
        setTransferData({ ...item });
        setOpenConfirmCancelDialog(true);
    };

    const handleCancelTransfer = (reason, amount) => {
        const payload = {
            ...pmt,
            reason,
            amount,
        };
        dispatch(FinanceActions.setIsCancelTransaction(true))
        dispatch(FinanceActions.cancelReversal(payload)).then(({ result }) => {
            result && result === "success" && setOpenConfirmCancelDialog(false);
        });
        dispatch(FinanceActions.setIsCancelTransaction(false))
        setOpenConfirmCancelDialog(false);
    };

    const RenderReversalsTable = () => {
        const columns = [
            {
                Header: "",
                id: "profileImage",
                Cell: ({ row: { original } }) => (
                    <RenderWorkerReversalAvatar code={original.status.daCode} data={original} />
                ),
                sortable: false,
                filterable: false,
                width: 100,
            },
            {
                Header: "Worker",
                sortable: true,
                minWidth: 150,
                id: "worker",
                accessor: ({ worker }) => worker,
                headerStyle: { cursor: "pointer", textAlign: "left" },
                style: { cursor: "pointer", textAlign: "left" },
                Cell: ({ row: { original: { worker } } }) => worker,
            },
            {
                Header: " Status ",
                sortable: true,
                filterable: false,
                minWidth: 100,
                id: "paymentStatusId",
                headerStyle: { cursor: "pointer", textAlign: "left" },
                style: { cursor: "pointer", textAlign: "left" },
                accessor: ({ paymentStatusId }) => paymentStatusId,
                Cell: ({ row: { original: { status } } }) => (
                    <Tooltip title={`${status.description}`}>
                        <Typography variant="body2" color={"inherit"}>
                            {status.description}
                        </Typography>
                    </Tooltip>
                ),
            },
            {
                Header: " Status ",
                sortable: true,
                filterable: false,
                minWidth: 80,
                id: "paymentStatusIdIcon",
                headerStyle: { cursor: "pointer", textAlign: "center" },
                style: { cursor: "pointer", textAlign: "center" },
                accessor: ({ paymentStatusId }) => paymentStatusId,
                Cell: ({ row: { original: { status } } }) => <DcTransferStatusBadge code={status.dcCode} iconOnly />,
            },
            {
                Header: "Sent",
                sortable: true,
                id: "sentAt",
                minWidth: 100,
                accessor: ({ sentAt }) => sentAt,
                headerStyle: { cursor: "pointer", textAlign: "left" },
                style: { cursor: "pointer", textAlign: "left" },
                Cell: ({ row: { original: { sentAt } } }) => (
                    <Tooltip title={`Payment sent ${moment(sentAt).format("MMM DD, YYYY h:mm a")}`}>
                        <Typography
                            style={{ width: "100%", paddingLeft: sentAt ? 0 : 20 }}
                            variant="body2"
                            color={"inherit"}>
                            {sentAt ? moment(sentAt).format("MMM DD, YYYY h:mm a") : <Remove />}
                        </Typography>
                    </Tooltip>
                ),
            },
            {
                Header: "Updated",
                sortable: true,
                id: "responseAt",
                minWidth: 100,
                accessor: ({ responseAt }) => responseAt,
                headerStyle: { cursor: "pointer", textAlign: "left" },
                style: { cursor: "pointer", textAlign: "left" },
                Cell: ({ row: { original: { responseAt } } }) => (
                    <Typography
                        variant="body2"
                        color={"inherit"}
                        style={{ width: "100%", paddingLeft: responseAt ? 0 : 30 }}>
                        {responseAt ? moment(responseAt).format("MMM DD, YYYY h:mm a") : <Remove />}
                    </Typography>
                ),
            },
            {
                Header: "Amount",
                show: true,
                minWidth: 100,
                accessor: ({ amount }) => amount,
                sortable: true,
                id: "amount",
                headerStyle: { cursor: "pointer", textAlign: "left" },
                style: { cursor: "pointer", textAlign: "left" },
                Cell: ({ row: { original: { amount } } }) => formatMoney(amount),
            },
            {
                Header: "Reversal Id",
                show: true,
                minWidth: 100,
                accessor: ({ dcTransferId }) => dcTransferId,
                sortable: true,
                id: "dcTransferId",
                headerStyle: { cursor: "pointer", textAlign: "left" },
                style: { cursor: "pointer", textAlign: "left" },
                Cell: ({ row: { original: { dcTransferId } } }) => dcTransferId,
            },
            {
                Header: "Effected Transfer",
                show: true,
                minWidth: 150,
                accessor: ({ reversedDcTransferId }) => reversedDcTransferId,
                sortable: true,
                id: "reversedDcTransferId",
                headerStyle: { cursor: "pointer", textAlign: "left" },
                style: { cursor: "pointer", textAlign: "left" },
                Cell: ({ row: { original: { reversedDcTransferId } } }) => reversedDcTransferId,
            },

            // {
            //     Header: "Payment Id ",
            //     sortable: true,
            //     show: false,
            //     filterable: false,
            //     minWidth: 0,
            //     id: "paymentId",
            //     headerStyle: { cursor: "pointer", textAlign: "left" },
            //     style: { cursor: "pointer", textAlign: "left" },
            //     accessor: ({ paymentId }) => paymentId,
            //     Cell: ({ row: { original: { paymentId } } }) => (
            //         <Tooltip title="Job positions that are ready for payment.">
            //             <Typography variant="body2" color={"inherit"}>
            //                 {paymentId}
            //             </Typography>
            //         </Tooltip>
            //     ),
            // },
            {
                Header: "Notes",
                sortable: false,

                id: "notes",
                disableSortBy: true,
                minWidth: 50,
                accessor: ({ notes }) => notes,
                headerStyle: { cursor: "pointer", textAlign: "center" },
                style: {
                    cursor: "pointer",
                    textAlign: "center",
                    flexDirection: "inherit",
                    alignItems: "center",
                },
                Cell: ({ row: { original: { notes } } }) => (
                    <Tooltip
                        title={`${notes}`}
                        style={{ justifyItems: "center", flexDirection: "inherit", textAlign: "center" }}>
                        <Notes color="secondary" />
                    </Tooltip>
                ),
            },
            // {
            //     Header: " ",
            //     sortable: false,
            //     expander: false,
            //     show: false,
            //     id: "notes1",
            //     Expander: ({ isExpanded }) => (isExpanded ? <ArrowDropDown /> : <ArrowRight />),
            //     width: 40,
            //     accessor: ({ notes }) => notes,
            //     headerStyle: { cursor: "pointer", textAlign: "center" },
            //     style: {
            //         cursor: "pointer",
            //         textAlign: "center",
            //         flexDirection: "inherit",
            //         alignItems: "center",
            //     },
            // },

            {
                // Header: () => (
                //   <Tooltip title="Request funds be sent back via Interac Transfer.">
                //     {/* <AccountBalanceWallet color="secondary" onClick={() => toggleOpenDcReversal()} /> */}
                //     <AccountBalanceWallet color="secondary" onClick={() => handleFindRequestForFunds()} />
                //   </Tooltip>
                // ),

                id: "actions",
                sortable: false,
                collapse: true,
                // headerStyle: { cursor: "pointer", textAlign: "left", paddingTop: 10 },
                style: { cursor: "pointer", textAlign: "right" },
                Cell: ({ row: { original } }) => (
                    <ReversalsTabTableActions
                        reversal={original}
                        toggleReviewingPayroll={toggleReviewingPayroll}
                        setIsReviewingPayroll={setIsReviewingPayroll}
                        handleViewProfile={handleViewProfile}
                        handleClickOpenCancelDialog={handleClickOpenCancelDialog}
                        handleClickOpenResendDialog={handleClickOpenResendDialog}
                        handleClickOpenResolveDialog={handleClickOpenResolveDialog}
                        toggleOpenDcReversal={toggleOpenDcReversal}
                        showPayrollSummary={true}
                    />
                ),
            },
        ];
        const tableProps = {
            manual: false,
            minRows: 0,
            resizable: false,
            showPageSizeOptions: isMobile ? false : true,
            showPageJump: isMobile ? false : true,
            showPaginationTop: !isMobile ? false : true,
            showPaginationBottom: isMobile ? false : true,

            billingRateFilter: "all",
            breakLengthFilter: "all", // pageSize: 20, // page: 0,
            defaultPageSize: 5,
            filterable: false,
            defaultSorted: [
                {
                    id: "sentAt",
                    desc: true,
                },
                {
                    id: "responseAt",
                    desc: true,
                },
                {
                    id: "dcTransferId",
                    desc: true,
                },
            ],
            defaultSortMethod: (a, b) => {
                // force null and undefined to the bottom
                a = a === null || a === undefined ? -Infinity : a;
                b = b === null || b === undefined ? -Infinity : b;
                // force any string values to lowercase
                a = typeof a === "string" ? a.toLowerCase() : a;
                b = typeof b === "string" ? b.toLowerCase() : b;
                // Return either 1 or -1 to indicate a sort priority
                if (a > b) {
                    return 1;
                }
                if (a < b) {
                    return -1;
                }
                // returning 0 or undefined will use any subsequent column sorting methods or the row index as a tiebreaker
                return 0;
            },
            getTdProps: (state, row, col) => RenderTdProps(state, row, col),
            defaultFilterMethod: (filter, row) => String(row[filter.id]) === filter.value,
            NoDataComponent: () => (
                <Typography
                    variant="subtitle2"
                    className="rt-noData"
                    style={{
                        marginTop: 10,
                        width: "100%",
                        backgroundColor: "#fff",
                        textAlign: "center",
                    }}>{`No Results Found.`}</Typography>
            ),
        };
        return (
            <ReactTableSimple
                columns={columns}
                loading={!reversals}
                classes={`-highlight ${classes.table}`}
                data={reversals}
                {...tableProps}
            />
        );
    };

    return (
        <React.Fragment>
            <Grid container item xs={12}>
                <CancelTransferDialog
                    open={openConfirmCancelDialog}
                    title={`Cancel Reversal Request?`}
                    description={`Are you sure you want to cancel this request?`}
                    confirmText="Cancel Request"
                    onClose={() => handleClose()}
                    onConfirm={handleCancelTransfer}
                    onCancel={() => handleClose()}
                    maxWidth="md"
                    alertType="save"
                    fullWidth={false}
                    pmt={pmt}
                    transferData={transferData}
                    iconFontSize={94}
                    isCancelLoading={isCancelLoading}
                    icon={<CancelScheduleSend color="error" style={{ fontSize: 96 }} />}
                    size="md"
                />
                <ResendDialog
                    open={openConfirmResendDialog}
                    title={`Resend Reversal Request`}
                    description={`Resend this cancelled transfer reversal request?`}
                    confirmText="Resend Request"
                    onClose={() => handleClose()}
                    onConfirm={handleResendReversal}
                    onCancel={() => handleClose()}
                    maxWidth="md"
                    pmt={pmt}
                    alertType="save"
                    fullWidth={false}
                    iconFontSize={94}
                    icon={<CancelScheduleSend color="secondary" style={{ fontSize: 96 }} />}
                    size="md"
                />

                <ResendDialog
                    open={openResolveDialog}
                    title={`Resolve Payroll`}
                    description={`Resolve this cancelled transfer to the worker?`}
                    confirmText="Resolve Transfer"
                    onClose={() => handleClose()}
                    onConfirm={handleSetReversalResolved}
                    onCancel={() => handleClose()}
                    maxWidth="md"
                    pmt={pmt}
                    alertType="save"
                    fullWidth={false}
                    iconFontSize={94}
                    icon={<CancelScheduleSend color="secondary" style={{ fontSize: 96 }} />}
                    size="md"
                />
                <DcReversalsHeader />
                <RenderReversalsTable />
            </Grid>
        </React.Fragment>
    );
}

export default ReversalsTab;
