// Loading selectors
export const getLoadingStateFactory = loader => state => {
  return state.profile.loading[loader] || false;
};

export const getIsSaveProfileLoading = getLoadingStateFactory("profileSave");

/*
Profile Data
*/

export const getProfileDetail = state => state.profile.profileDetail;

