import React from "react";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

function StageLabel({ title, description }) {
    return (
        <Grid container direction="column">
            <Typography variant="subtitle1">{title}</Typography>
            <Typography variant="caption" color="textSecondary">
                {description}
            </Typography>
        </Grid>
    );
}

StageLabel.propTypes = {
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
};

export default StageLabel;
