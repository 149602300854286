import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import classNames from "classnames";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import Grid from "@mui/material/Grid";
import withStyles from '@mui/styles/withStyles';
import { actions as WorkerActions } from "store/modules/workers";

const styles = ({ spacing }) => ({
  header: {},
  label: {
    lineHeight: 1,
    "& + span": {
      fontWeight: 500,
    },
  },
  filterRow: {
    marginTop: spacing(6),
    marginBottom: spacing(-4),
  },
});

class WaitlistFilters extends React.Component {
  state = {
    today: {
      onWaitlist: "Today",
      canDrive: true,
      canWorkMorning: true,
      canWorkEvening: true,
    },
    tomorrow: {
      onWaitlist: "Tomorrow",
      canDrive: true,
      canWorkMorning: true,
      canWorkEvening: true,
    },
  };

  handleTodayCriteriaChange = event => {
    this.setState({
      today: {
        ...this.state.today,
        [event.target.value]: event.target.checked,
      },
    });
    this.props.onCriteriaChange(
      {
        ...this.state.today,
        [event.target.value]: event.target.checked,
      },
      this.props.listType
    );
  };

  handleTomorrowCriteriaChange = event => {
    this.setState({
      tomorrow: {
        ...this.state.tomorrow,
        [event.target.value]: event.target.checked,
      },
    });
    this.props.onCriteriaChange(
      {
        ...this.state.tomorrow,
        [event.target.value]: event.target.checked,
      },
      this.props.listType
    );
  };

  render() {
    const { classes, className } = this.props;
    return (
      <Grid
        className={className}
        container
        direction="column"
        alignItems="flex-end"
        style={{ marginBottom: 15 }}>
        {this.props.listType ? (
          <FormGroup row>
            <FormControlLabel
              control={
                <Checkbox
                  checked={this.state.tomorrow.canDrive}
                  onChange={this.handleTomorrowCriteriaChange}
                  value="canDrive"
                  color="secondary"
                  className={classNames(classes.checkbox)}
                />
              }
              label="Drives"
              classes={{ label: classes.checkboxLabel }}
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={this.state.tomorrow.canWorkEvening}
                  onChange={this.handleTomorrowCriteriaChange}
                  value="canWorkEvening"
                  color="secondary"
                  className={classNames(classes.checkbox)}
                />
              }
              label="Evenings"
              classes={{ label: classes.checkboxLabel }}
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={this.state.tomorrow.canWorkMorning}
                  onChange={this.handleTomorrowCriteriaChange}
                  value="canWorkMorning"
                  color="secondary"
                  className={classNames(classes.checkbox)}
                />
              }
              label="Mornings "
              classes={{ label: classes.checkboxLabel }}
            />
          </FormGroup>
        ) : (
          <FormGroup row style={{ float: "right", textAlign: "right" }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={this.state.today.canDrive}
                  onChange={this.handleTodayCriteriaChange}
                  value="canDrive"
                  color="secondary"
                  className={classNames(classes.checkbox)}
                />
              }
              label="Drives"
              classes={{ label: classes.checkboxLabel }}
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={this.state.today.canWorkEvening}
                  onChange={this.handleTodayCriteriaChange}
                  value="canWorkEvening"
                  color="secondary"
                  className={classNames(classes.checkbox)}
                />
              }
              label="Evenings"
              classes={{ label: classes.checkboxLabel }}
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={this.state.today.canWorkMorning}
                  onChange={this.handleTodayCriteriaChange}
                  value="canWorkMorning"
                  color="secondary"
                  className={classNames(classes.checkbox)}
                />
              }
              label="Mornings "
              classes={{ label: classes.checkboxLabel }}
            />
          </FormGroup>
        )}
      </Grid>
    );
  }
}

const mapState = state => {
  const {
    waitlistFilter,
    waitlistTodayFilter,
    waitlistTomorrowCriteria,
    waitlistTodayCriteria,
  } = state.workers;
  return {
    waitlistFilter,
    waitlistTodayFilter,
    waitlistTodayCriteria,
    waitlistTomorrowCriteria,
  };
};

const mapActions = {
  onCriteriaChange: WorkerActions.changeCriteria,
};

WaitlistFilters.propTypes = {
  className: PropTypes.node,
  classes: PropTypes.object.isRequired,
  onCriteriaChange: PropTypes.func,
  listType: PropTypes.number,
};

WaitlistFilters.defaultProps = {
  waitlistTodayFilter: null,
  waitlistFilter: null,
  classes: null,
};
export default connect(mapState, mapActions)(withStyles(styles)(WaitlistFilters));
