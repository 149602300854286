import { gql } from "@apollo/client";

export default gql`
  query {
    timezones {
      id
      timezoneName
      offset
      isActive
    }
  }
`;
