import _ from "lodash";
import isFunction from "lodash/isFunction";
import isPlainObject from "lodash/isPlainObject";
import ApolloClient from "utils/apollo";
import { flattenGraphQLArray } from "utils/graphql";
import * as pagination from "utils/graphql-pagination";
import { actions as MetaActions } from "store/modules/meta";
import { paginationSettings } from "./constants";
import {
    CreateEmployerCustomRateMutation,
    UpdateEmployerCustomRateMutation,
    uploadEmployerFileToS3Mutation,
} from "./graphql/mutations";
import ActivateEmployerMutation from "./graphql/mutations/activate-employer";
import AddEmployerNoteMutation from "./graphql/mutations/add-employer-note";
import AllowVaccinationStatusMutation from "./graphql/mutations/allow-vaccination-status";
import AllowPrivateJobStatusMutation from "./graphql/mutations/allow-private-job-status";
import BanWorkerMutation from "./graphql/mutations/ban-worker";
import EditEmployerMutation from "./graphql/mutations/edit-employer";
import RemoveEmployerMutation from "./graphql/mutations/remove-employer";
import CancelJobMutation from "./graphql/mutations/cancel-job";
import ClearExceptionMutation from "./graphql/mutations/clear-exception";
import DeactivateEmployerMutation from "./graphql/mutations/deactivate-employer";
import JobReminderMutation from "./graphql/mutations/job-reminder";
import RemoveManagerMutation from "./graphql/mutations/remove-manager";
import DeleteJobMutation from "./graphql/mutations/delete-job";
import PreferWorkerMutation from "./graphql/mutations/prefer-worker";
import UnverifyEmployerMutation from "./graphql/mutations/unverify-employer";
import UpdateEmployerProfileMutation from "./graphql/mutations/update-employer";
import VerifyEmployerMutation from "./graphql/mutations/verify-employer";
import { getEmployerBillingRatesQuery } from "./graphql/queries";
import FetchEmployerQuery from "./graphql/queries/fetch-employer";
import FetchEmployerJobsQuery from "./graphql/queries/fetch-jobs";
import FetchManagerQuery from "./graphql/queries/fetch-manager";
import FetchWorkerExceptionsQuery from "./graphql/queries/fetch-worker-exceptions";
import FetchWorkerPickerQuery from "./graphql/queries/fetch-workers-picker";
import {
    getBannedWorkersPaginationData,
    getEmployerDetails,
    getEmployerEditingCustomRates,
    getJobsListData,
    getJobsPaginationData,
    getPreferredWorkersPaginationData,
    getWorkerExceptionsListData,
    getWorkerPickerListData,
    getWorkerPickerPaginationData,
} from "./selectors";
import types from "./types";

const setLoadingState = key => value => ({
    type: types.SET_LOADING_STATE,
    payload: { key, value },
});
const setIsPageLoading = setLoadingState("page");
const setIsApplyingAction = setLoadingState("actions");

const setEmployerCustomRates = rates => ({
    type: types.SET_EMPLOYER_CUSTOM_RATES,
    payload: rates,
});
export const updateEmployerNote = note => ({ type: types.SET_EMPLOYER_NOTE, payload: note });
export const resetEmployerNote = () => ({ type: types.RESET_EMPLOYER_NOTE });

export const saveEmployerNote = () => (dispatch, getState) => {
    const state = getState();
    const { employer, employerNotes } = getState().employerDetails;

    dispatch(setIsApplyingAction(true));
    return ApolloClient.mutate({
        mutation: AddEmployerNoteMutation,
        variables: {
            data: {
                employerId: employer.id,
                note: employerNotes,
            },
        },
    })
        .then(({ errors, data }) => {
            if (!data && errors.length > 0) {
                throw new Error(errors[0].message);
            } else {
                dispatch(fetchEmployer(employer.id, false));
                dispatch(
                    MetaActions.successToast(`Note has been added to ${employer.firstName}'s profile.`),
                );
            }
        })
        .catch(() => {
            dispatch(
                MetaActions.errorToast(`Could not add a note to ${employer.firstName}'s profile`),
            );
        })
        .then(() => {
            dispatch(setIsApplyingAction(false));
            dispatch(resetEmployerNote());
        });
};

export const onDeleteEmployerAccount = () => (dispatch, getState) => {
    const state = getState();
    const { employer } = state.employerDetails;
    dispatch(setIsApplyingAction(true));
    return ApolloClient.mutate({
        mutation: RemoveEmployerMutation,
        variables: {
            id: employer.id
        },
    })
        .then(({ data, errors }) => {
            if (!data && errors.length > 0) {
                throw new Error(errors[0].message);
            } else {
                dispatch(
                    MetaActions.successToast(`${employer.firstName}'s account deleted successfully.`),
                );
                dispatch(setIsApplyingAction(false));
                return true;
            }
        })
        .catch((e) => {
            dispatch(setIsApplyingAction(false));
            dispatch(
                MetaActions.errorToast(e.message),
            );
        })
};

export const saveEmployerProfile = data => (dispatch, getState) => {
    const { employer } = getState().employerDetails;
    if (data.email) {
        delete data.email;
    }

    if (data.payRate) {
        data.payRate = parseFloat(data.payRate);
    }
    if (data.billingRate) {
        data.billingRate = parseFloat(data.billingRate);
    }

    return ApolloClient.mutate({
        mutation: UpdateEmployerProfileMutation,
        variables: {
            data: {
                id: employer.id,
                ...data,
            },
        },
    })
        .then(({ data }) => {
            //   dispatch(setUpdateEmployerData());
            dispatch(fetchEmployer(employer.id, false));
            // dispatch(setEmployer(data.editEmployer));
            dispatch(MetaActions.successToast(`${employer.companyName} has been updated.`));
            return data.editEmployer;
        })
        .catch(e => {
            //   dispatch(setUpdateEmployerData());
            dispatch(MetaActions.errorToast(`Updating ${employer.companyName} has failed. ${e}`));
        });
};

export const fetchEmployerCustomRates = employerId => dispatch => {
    return ApolloClient.query({
        query: getEmployerBillingRatesQuery,
        variables: { employerId },
    })
        .then(({ data }) => {
            dispatch(setEmployerCustomRates(data.employerBillingRates));
        })
        .catch(() => { });
};

export const clearEmployerUpdateData = () => ({
    type: types.CLEAR_UPDATE_UPDATE_EMPLOYER_DATA,
    payload: {},
});
export const clearUpdateEmployerCustomRateData = () => ({
    type: types.CLEAR_UPDATE_EMPLOYER_CUSTOM_RATE_DATA,
    payload: {},
});
export const clearCreateEmployerCustomRateData = () => ({
    type: types.CLEAR_CREATE_EMPLOYER_CUSTOM_RATE_DATA,
    payload: {},
});
export const setUpdateEmployerCustomRateData = (field, value) => () => {
    const payload = {
        type: types.SET_UPDATE_EMPLOYER_CUSTOM_RATE_DATA,
        payload: { field, value },
    };

    return payload;
};
export const setEditEmployerCustomRateData = (field, value) => ({
    type: types.SET_EDIT_EMPLOYER_CUSTOM_RATE_DATA,
    payload: { field, value },
});

export const newEditEmployerCustomRateData = () => async (dispatch, getState) => {
    const state = getState();

    const rates = getEmployerEditingCustomRates(state);

    return await rates;
};
export const setNewEmployerCustomRateData = (field, value) => {
    if (field === "phone") {
        value = value.replace(/\D/g, "");
    }

    return {
        type: types.SET_NEW_EMPLOYER_CUSTOM_RATE_DATA,
        payload: { field, value },
    };
};

export const setUpdateEmployerData = (field, value) => {
    if (field === "phone") {
        value = value.replace(/\D/g, "");
    }

    return {
        type: types.SET_UPDATE_UPDATE_EMPLOYER_DATA,
        payload: { field, value },
    };
};

export const uploadEmployerLogoImage = file => (dispatch, getState) => {
    const { employer } = getState().employerDetails;

    return ApolloClient.mutate({
        mutation: uploadEmployerFileToS3Mutation,
        variables: {
            data: { employerId: employer.id },
            isProfile: false,
            file,
        },
    })
        .then(() => {
            dispatch(fetchEmployer(employer.id));
            dispatch(MetaActions.successToast(`${employer.companyName} has been updated.`));
            // data.createCustomRate;
        })
        .catch(e => {
            dispatch(MetaActions.errorToast(`Updating ${employer.companyName} has failed. ${e}`));
        });
};
export const uploadEmployerProfileImage = file => (dispatch, getState) => {
    const { employer } = getState().employerDetails;

    return ApolloClient.mutate({
        mutation: uploadEmployerFileToS3Mutation,
        variables: {
            data: { employerId: employer.id },
            isProfile: true,
            file,
        },
    })
        .then(() => {
            dispatch(fetchEmployer(employer.id));
            dispatch(MetaActions.successToast(`${employer.companyName} has been updated.`));
            // data.createCustomRate;
        })
        .catch(e => {
            dispatch(MetaActions.errorToast(`Updating ${employer.companyName} has failed. ${e}`));
        });
};
export const createEmployerCustomRate = () => (dispatch, getState) => {
    const { employer, newEmployerCustomRate } = getState().employerDetails;

    if (_.isEmpty(newEmployerCustomRate)) {
        dispatch(clearEmployerUpdateData());
        return dispatch(MetaActions.successToast(`Nothing to update.`));
    }

    if (newEmployerCustomRate.baseRate) {
        newEmployerCustomRate.baseRate = parseFloat(newEmployerCustomRate.baseRate);
    }
    if (newEmployerCustomRate.baseBillingRate) {
        newEmployerCustomRate.baseBillingRate = parseFloat(newEmployerCustomRate.baseBillingRate);
    }

    if (newEmployerCustomRate.wageMultiplier) {
        newEmployerCustomRate.wageMultiplier = parseFloat(newEmployerCustomRate.wageMultiplier);
    }
    if (newEmployerCustomRate.billingRateMultiplier) {
        newEmployerCustomRate.billingRateMultiplier = parseFloat(
            newEmployerCustomRate.billingRateMultiplier,
        );
    }

    return ApolloClient.mutate({
        mutation: CreateEmployerCustomRateMutation,
        variables: {
            data: {
                ...newEmployerCustomRate,
            },
        },
    })
        .then(({ data }) => {
            dispatch(setUpdateEmployerData());
            dispatch(fetchEmployerCustomRates(employer.id));
            dispatch(MetaActions.successToast(`${employer.companyName} has been updated.`));
            return data.createCustomRate;
        })
        .catch(e => {
            dispatch(setUpdateEmployerData());
            dispatch(MetaActions.errorToast(`Updating ${employer.companyName} has failed. ${e}`));
        });
};

export const updateEmployerCustomRate = () => (dispatch, getState) => {
    const { employer, editEmployerCustomRateData } = getState().employerDetails;
    const rateId = editEmployerCustomRateData.id;

    if (_.isEmpty(editEmployerCustomRateData)) {
        dispatch(clearUpdateEmployerCustomRateData());
        return dispatch(MetaActions.successToast(`Nothing to update yo.`));
    }

    if (editEmployerCustomRateData.baseRate) {
        editEmployerCustomRateData.baseRate = parseFloat(editEmployerCustomRateData.baseRate);
    }
    if (editEmployerCustomRateData.baseBillingRate) {
        editEmployerCustomRateData.baseBillingRate = parseFloat(
            editEmployerCustomRateData.baseBillingRate,
        );
    }

    if (editEmployerCustomRateData.wageMultiplier) {
        editEmployerCustomRateData.wageMultiplier = parseFloat(
            editEmployerCustomRateData.wageMultiplier,
        );
    }
    if (editEmployerCustomRateData.billingRateMultiplier) {
        editEmployerCustomRateData.billingRateMultiplier = parseFloat(
            editEmployerCustomRateData.billingRateMultiplier,
        );
    }
    delete editEmployerCustomRateData.id;

    return ApolloClient.mutate({
        mutation: UpdateEmployerCustomRateMutation,
        variables: { id: rateId, data: { ...editEmployerCustomRateData } },
    })
        .then(({ data }) => {
            dispatch(clearUpdateEmployerCustomRateData());
            dispatch(clearCreateEmployerCustomRateData());
            dispatch(fetchEmployerCustomRates(employer.id));

            dispatch(MetaActions.successToast(`${employer.companyName} has been updated.`));
            return data.editCustomRate;
        })
        .catch(e => {
            dispatch(clearUpdateEmployerCustomRateData());
            dispatch(MetaActions.errorToast(`Updating ${employer.companyName} has failed. ${e}`));
        });
};

export const updateEmployerProfile = () => (dispatch, getState) => {
    const { employer, updateEmployerData } = getState().employerDetails;

    if (_.isEmpty(updateEmployerData)) {
        dispatch(clearEmployerUpdateData());
        return dispatch(MetaActions.successToast(`Nothing to update. profile`));
    }

    if (updateEmployerData.payRate) {
        updateEmployerData.payRate = parseFloat(updateEmployerData.payRate);
    }
    if (updateEmployerData.billingRate) {
        updateEmployerData.billingRate = parseFloat(updateEmployerData.billingRate);
    }

    return ApolloClient.mutate({
        mutation: UpdateEmployerProfileMutation,
        variables: {
            data: {
                id: employer.id,
                ...updateEmployerData,
            },
        },
    })
        .then(({ data }) => {
            dispatch(setUpdateEmployerData());
            dispatch(setEmployer(data.editEmployer));
            dispatch(MetaActions.successToast(`${employer.companyName} has been updated.`));
            return data.editEmployer;
        })
        .catch(e => {
            dispatch(setUpdateEmployerData());
            dispatch(MetaActions.errorToast(`Updating ${employer.companyName} has failed. ${e}`));
        });
};

const setEmployer = employer => ({
    type: types.SET_EMPLOYER,
    payload: { employer },
});

const setManager = managers => ({
    type: types.SET_MANAGER,
    payload: { managers },
});

const setHoursTrendData = hoursTrendData => ({
    type: types.SET_HOURS_TREND_DATA,
    payload: { hoursTrendData },
});

export const fetchEmployer = (id, indicateLoading = true) => dispatch => {
    if (indicateLoading) {
        dispatch(setIsPageLoading(true));
    }
    return ApolloClient.query({
        query: FetchEmployerQuery,
        variables: {
            id: id,
        },
    })
        .then(({ data: { employer, hoursTrendData } }) => {
            dispatch(setEmployer(employer));
            dispatch(setHoursTrendData(hoursTrendData));
            if (indicateLoading) {
                dispatch(setIsPageLoading(false));
            }
            dispatch(fetchEmployerCustomRates(id));
        })
        .catch(e => {
            if (indicateLoading) {
                dispatch(setIsPageLoading(false));
            }
            dispatch(MetaActions.errorToast(`Could not get employer information: ${e.message}`));
        });
};

export const fetchManager = () => (dispatch, getState) => {
    const { employer } = getState().employerDetails;
    return ApolloClient.query({
        query: FetchManagerQuery,
        variables: {
            employerId: employer.id,
            isAll: true
        },
    })
        .then(({ data: { managerByEmployerId } }) => {
            dispatch(setManager(managerByEmployerId));
        })
        .catch(e => {
            dispatch(MetaActions.errorToast(`Could not get manager: ${e.message}`));
        });
};

export const fetchEmployerJobs = pageIndex => (dispatch, getState) => {
    const state = getState();
    const { employer } = state.employerDetails;
    const pageInfo = getJobsPaginationData(state);
    const pagingVars = dispatch(
        pagination.pagingVarsFactory(paginationSettings.employerJobs)(pageInfo, pageIndex),
    );

    const jobListInfo = getJobsListData(state);

    return ApolloClient.query({
        query: FetchEmployerJobsQuery,
        variables: {
            ...pagingVars,
            employerId: employer.id,
            order: jobListInfo.sort.map(sort => {
                return {
                    field: sort.id,
                    direction: sort.desc ? "DESC" : "ASC",
                };
            }),
            like: jobListInfo.filter.map(filter => {
                return {
                    field: filter.id,
                    value: filter.value,
                };
            }),
        },
    })
        .then(({ data: { jobs } }) => {
            const flattenedJobs = flattenGraphQLArray(jobs);
            const paginationData = {
                ...jobs.pageInfo,
                totalCount: jobs.totalCount,
            };

            dispatch({
                type: types.SET_JOBS_LIST_DATA,
                payload: {
                    data: flattenedJobs,
                    part: "data",
                },
            });
            dispatch(pagination.updatePageInfo(paginationSettings.employerJobs, paginationData));
            dispatch(pagination.doneLoading(paginationSettings.employerJobs));
        })
        .catch(() => {
            dispatch(pagination.doneLoading(paginationSettings.employerJobs));
        });
};

export const setEmployerJobSort = sort => dispatch => {
    dispatch({
        type: types.SET_JOBS_LIST_DATA,
        payload: {
            data: sort,
            part: "sort",
        },
    });
    //   return dispatch(fetchEmployerJobs());
};

export const setEmployerJobFilter = filter => dispatch => {
    dispatch({
        type: types.SET_JOBS_LIST_DATA,
        payload: {
            data: filter,
            part: "filter",
        },
    });
    //   return dispatch(fetchEmployerJobs());
};

export const fetchWorkerPicker = pageIndex => (dispatch, getState) => {
    const state = getState();
    const pageInfo = getWorkerPickerPaginationData(state);
    const pagingVars = dispatch(
        pagination.pagingVarsFactory(paginationSettings.workerPicker)(pageInfo, pageIndex),
    );

    const workPickerListInfo = getWorkerPickerListData(state);

    return ApolloClient.query({
        query: FetchWorkerPickerQuery,
        variables: {
            ...pagingVars,
            employerId: state.employerDetails.employer.id,
            order: workPickerListInfo.sort.map(sort => {
                return {
                    field: sort.id,
                    direction: sort.desc ? "DESC" : "ASC",
                };
            }),
            like: workPickerListInfo.filter.map(filter => {
                return {
                    field: filter.id,
                    value: filter.value,
                };
            }),
        },
    })
        .then(({ data: { workers } }) => {
            const flattenedWorkers = flattenGraphQLArray(workers);
            const paginationData = {
                ...workers.pageInfo,
                totalCount: workers.totalCount,
            };

            dispatch({
                type: types.SET_WORKER_PICKER_LIST_DATA,
                payload: {
                    data: flattenedWorkers,
                    part: "data",
                },
            });
            dispatch(pagination.updatePageInfo(paginationSettings.workerPicker, paginationData));
            dispatch(pagination.doneLoading(paginationSettings.workerPicker));
        })
        .catch(() => {
            dispatch(pagination.doneLoading(paginationSettings.workerPicker));
        });
};

export const setWorkerPickerSort = sort => dispatch => {
    dispatch({
        type: types.SET_WORKER_PICKER_LIST_DATA,
        payload: {
            data: sort,
            part: "sort",
        },
    });
    //   return dispatch(fetchWorkerPicker());
};

export const setWorkerPickerFilter = filter => dispatch => {
    dispatch({
        type: types.SET_WORKER_PICKER_LIST_DATA,
        payload: {
            data: filter,
            part: "filter",
        },
    });
    //   return dispatch(fetchWorkerPicker());
};

export const fetchWorkersListFactory = config => pageIndex => (dispatch, getState) => {
    const state = getState();
    const pageInfo = config.getPageInfo(state);
    const pagingVars = dispatch(config.getPagingVars(pageInfo, pageIndex));

    let extraVars = {};

    if (isFunction(config.variables)) {
        extraVars = config.variables(state);
    }

    if (isPlainObject(config.variables)) {
        extraVars = config.variables;
    }

    return ApolloClient.query({
        query: FetchWorkerExceptionsQuery,
        variables: {
            ...pagingVars,
            ...extraVars,
            employerId: state.employerDetails.employer.id,
            order: getWorkerExceptionsListData(state, { list: config.list, part: "sort" }).map(sort => {
                return {
                    field: sort.id,
                    direction: sort.desc ? "DESC" : "ASC",
                };
            }),
            like: getWorkerExceptionsListData(state, { list: config.list, part: "filter" }).map(
                filter => {
                    return {
                        field: filter.id,
                        value: filter.value,
                    };
                },
            ),
        },
    })
        .then(({ data: raw }) => {
            const { paginationData, data } = config.processData(raw);

            dispatch({
                type: types.SET_WORKER_EXCEPTIONS_LIST_DATA,
                payload: {
                    data,
                    list: config.list,
                    part: "data",
                },
            });
            dispatch(pagination.updatePageInfo(config.paginationSetting, paginationData));
            dispatch(pagination.doneLoading(config.paginationSetting));
        })
        .catch(() => {
            dispatch(pagination.doneLoading(config.paginationSetting));
        });
};

export const setSortFactory = config => sort => dispatch => {
    dispatch({
        type: types.SET_WORKER_EXCEPTIONS_LIST_DATA,
        payload: {
            data: sort,
            list: config.list,
            part: "sort",
        },
    });
    if (config.refresh)
        return dispatch(config.refresh());
};

export const setFilterFactory = config => filter => dispatch => {
    dispatch({
        type: types.SET_WORKER_EXCEPTIONS_LIST_DATA,
        payload: {
            data: filter,
            list: config.list,
            part: "filter",
        },
    });
    if (config.refresh)
        return dispatch(config.refresh());
};

export const setPageSizeFactory = config => pageSize => dispatch => {
    dispatch(pagination.updatePageInfo(config.paginationSetting, { pageSize }));
    return dispatch(config.refresh());
};

// Preferred workers
export const fetchPreferredWorkers = fetchWorkersListFactory({
    getPageInfo: getPreferredWorkersPaginationData,
    getPagingVars: pagination.pagingVarsFactory(paginationSettings.preferredWorkers),
    variables: state => ({
        preferredBy: state.employerDetails.employer.id,
    }),
    paginationSetting: paginationSettings.preferredWorkers,
    list: "preferred",
    processData: ({ workers }) => {
        return {
            paginationData: {
                ...workers.pageInfo,
                totalCount: workers.totalCount,
            },
            data: flattenGraphQLArray(workers),
        };
    },
});

export const setPreferredWorkersSort = setSortFactory({
    list: "preferred",
    // refresh: fetchPreferredWorkers,
});

export const setPreferredWorkersFilter = setFilterFactory({
    list: "preferred",
    // refresh: fetchPreferredWorkers,
});

// Banned workers
export const fetchBannedWorkers = fetchWorkersListFactory({
    getPageInfo: getBannedWorkersPaginationData,
    getPagingVars: pagination.pagingVarsFactory(paginationSettings.bannedWorkers),
    variables: state => ({
        bannedBy: state.employerDetails.employer.id,
    }),
    paginationSetting: paginationSettings.bannedWorkers,
    list: "banned",
    processData: ({ workers }) => {
        return {
            paginationData: {
                ...workers.pageInfo,
                totalCount: workers.totalCount,
            },
            data: flattenGraphQLArray(workers),
        };
    },
});

export const setBannedWorkersSort = setSortFactory({
    list: "banned",
    // refresh: fetchBannedWorkers,
});

export const setBannedWorkersFilter = setFilterFactory({
    list: "banned",
    //   refresh: fetchBannedWorkers,
});

export const editEmployer = (allowPreferredOnly) => (dispatch, getState) => {
    const state = getState();
    const { employer } = getEmployerDetails(state);
    dispatch(setIsApplyingAction(true));
    dispatch(setLoadingState(true));
    return ApolloClient.mutate({
        mutation: EditEmployerMutation,
        variables: {
            data: { id: employer.id, allowPreferredOnly },
        },
    })
        .then(({ data: { editEmployer } }) => {
            dispatch(setEmployer(editEmployer));
            dispatch(setIsApplyingAction(false));
            dispatch(setLoadingState(false));
            dispatch(MetaActions.successToast(`${employer.companyName} status has updated.`));
        })
        .catch(e => {
            dispatch(setIsApplyingAction(false));
            dispatch(
                MetaActions.errorToast(`Could not update ${employer.companyName}: ${e.message}`),
            );
        });
};

export const verifyEmployer = () => (dispatch, getState) => {
    const state = getState();
    const { employer } = getEmployerDetails(state);
    dispatch(setIsApplyingAction(true));
    return ApolloClient.mutate({
        mutation: VerifyEmployerMutation,
        variables: {
            data: { employerId: employer.id },
        },
    })
        .then(({ data: { verifyEmployer } }) => {
            dispatch(setEmployer(verifyEmployer));
            dispatch(setIsApplyingAction(false));
            dispatch(MetaActions.successToast(`${employer.companyName} has been verified.`));
        })
        .catch(e => {
            dispatch(setIsApplyingAction(false));
            dispatch(MetaActions.errorToast(`Could not verify ${employer.companyName}: ${e.message}`));
        });
};

export const unverifyEmployer = () => (dispatch, getState) => {
    const state = getState();
    const { employer } = getEmployerDetails(state);
    dispatch(setIsApplyingAction(true));
    return ApolloClient.mutate({
        mutation: UnverifyEmployerMutation,
        variables: {
            data: { employerId: employer.id },
        },
    })
        .then(({ data: { unverifyEmployer } }) => {
            dispatch(setEmployer(unverifyEmployer));
            dispatch(setIsApplyingAction(false));
            dispatch(
                MetaActions.successToast(`${employer.companyName}'s information has been unverified.`),
            );
        })
        .catch(e => {
            dispatch(setIsApplyingAction(false));
            dispatch(MetaActions.errorToast(`Could not unverify ${employer.companyName}: ${e.message}`));
        });
};

export const activateEmployer = () => (dispatch, getState) => {
    const state = getState();
    const { employer } = getEmployerDetails(state);
    dispatch(setIsApplyingAction(true));
    return ApolloClient.mutate({
        mutation: ActivateEmployerMutation,
        variables: {
            data: { employerId: employer.id },
        },
    })
        .then(({ data: { activateEmployer } }) => {
            dispatch(setEmployer(activateEmployer));
            dispatch(setIsApplyingAction(false));
            dispatch(
                MetaActions.successToast(
                    `${employer.companyName} has been activated. They are now able to post jobs.`,
                ),
            );
        })
        .catch(e => {
            dispatch(setIsApplyingAction(false));
            dispatch(MetaActions.errorToast(`Could not activate ${employer.companyName}: ${e.message}`));
        });
};

export const deactivateEmployer = () => (dispatch, getState) => {
    const state = getState();
    const { employer } = getEmployerDetails(state);
    dispatch(setIsApplyingAction(true));
    return ApolloClient.mutate({
        mutation: DeactivateEmployerMutation,
        variables: {
            data: { employerId: employer.id },
        },
    })
        .then(({ data: { deactivateEmployer } }) => {
            dispatch(setEmployer(deactivateEmployer));
            dispatch(setIsApplyingAction(false));
            dispatch(MetaActions.successToast(`${employer.companyName} has been deactivated.`));
        })
        .catch(e => {
            dispatch(setIsApplyingAction(false));
            dispatch(
                MetaActions.errorToast(`Could not deactivate ${employer.companyName}: ${e.message}`),
            );
        });
};

export const deactivateManager = (employerId, managerId) => (dispatch) => {
    ApolloClient.mutate({
        mutation: RemoveManagerMutation,
        variables: {
            employerId: employerId,
            managerId: managerId
        },
    }).then(({ data }) => {
        dispatch(fetchManager());
        dispatch(MetaActions.successToast(`Manager has been deleted.`));
    })
        .catch(e => {
            console.log(e)
            dispatch(
                MetaActions.errorToast(`Could not deactivate: ${e.message}`),
            );
        });
};

export const setEmployerJobReminder = (id) => (dispatch) => {
    ApolloClient.mutate({
        mutation: JobReminderMutation,
        variables: {
            data: { employerId: id },
        },
    }).then(({ data }) => {
        dispatch(MetaActions.successToast(`Reminder updated successfully`));
        return dispatch(fetchEmployer(id, false))
    })
        .catch(e => {
            console.log(e)
            dispatch(
                MetaActions.errorToast(`${e.message}`),
            );
        });
};

export const setEmployerVaccinationStatus = (id, allowVaccinatedOnly) => (dispatch) => {
    ApolloClient.mutate({
        mutation: AllowVaccinationStatusMutation,
        variables: {
            data: { employerId: id, allowVaccinatedOnly },
        },
    }).then(({ data }) => {
        dispatch(MetaActions.successToast(`Vaccination status updated successfully`));
        return dispatch(fetchEmployer(id, false))
    })
        .catch(e => {
            console.log(e)
            dispatch(
                MetaActions.errorToast(`${e.message}`),
            );
        });
};

export const setEmployerPrivateJobStatus = (id, canPostPrivateJob) => (dispatch) => {
    ApolloClient.mutate({
        mutation: AllowPrivateJobStatusMutation,
        variables: {
            data: { employerId: id, canPostPrivateJob },
        },
    }).then(({ data }) => {
        dispatch(MetaActions.successToast(`Private job status updated successfully`));
        return dispatch(fetchEmployer(id, false))
    })
        .catch(e => {
            console.log(e)
            dispatch(
                MetaActions.errorToast(`${e.message}`),
            );
        });
};

const removeJobFactory = (mutation, type) => jobId => (dispatch, getState) => {
    const { employer } = getEmployerDetails(getState());
    dispatch(setIsApplyingAction(true));
    return ApolloClient.mutate({
        mutation,
        variables: {
            data: {
                employerId: employer.id,
                jobId,
            },
        },
    })
        .then(() => dispatch(fetchEmployerJobs()))
        .then(() => {
            dispatch(setIsApplyingAction(false));
            let message;
            if (type === "delete") {
                message = "Job was successfully deleted.";
            }

            if (type === "cancel") {
                message = "This job will be cancelled and deleted. All workers will be notified";
            }
            dispatch(MetaActions.successToast(message));
        })
        .catch(e => {
            dispatch(setIsApplyingAction(false));
            dispatch(MetaActions.errorToast(`Failed to ${type} job. (${e.message})`));
            throw e;
        });
};

export const deleteJob = removeJobFactory(DeleteJobMutation, "delete");
export const cancelJob = removeJobFactory(CancelJobMutation, "cancel");

export const preferWorker = worker => (dispatch, getState) => {
    const { employer } = getEmployerDetails(getState());
    dispatch(setIsApplyingAction(true));
    return (
        ApolloClient.mutate({
            mutation: PreferWorkerMutation,
            variables: {
                data: {
                    workerId: worker.id,
                    employerId: employer.id,
                },
            },
        })
            // TODO: Eventually change to not re-fetch the whole world
            .then(() =>
                Promise.all([
                    dispatch(fetchPreferredWorkers()),
                    dispatch(fetchEmployer(employer.id, false)),
                ]),
            )
            .then(() => {
                dispatch(setIsApplyingAction(false));
                dispatch(
                    MetaActions.successToast(
                        `${worker.fullName} has been preferred by ${employer.companyName}`,
                    ),
                );
            })
            .catch(e => {
                dispatch(setIsApplyingAction(false));
                dispatch(MetaActions.errorToast(`Failed to prefer ${worker.fullName}. (${e.message})`));
            })
    );
};

export const banWorker = (worker, reason) => (dispatch, getState) => {
    const { employer } = getEmployerDetails(getState());
    dispatch(setIsApplyingAction(true));
    return (
        ApolloClient.mutate({
            mutation: BanWorkerMutation,
            variables: {
                data: {
                    workerId: worker.id,
                    employerId: employer.id,
                    reason,
                },
            },
        })
            // TODO: Eventually change to not re-fetch the whole world
            .then(() =>
                Promise.all([dispatch(fetchBannedWorkers()), dispatch(fetchEmployer(employer.id, false))]),
            )
            .then(() => {
                dispatch(setIsApplyingAction(false));
                dispatch(
                    MetaActions.successToast(`You've banned ${worker.fullName} from ${employer.companyName}`),
                );
            })
            .catch(e => {
                dispatch(setIsApplyingAction(false));
                dispatch(MetaActions.errorToast(`Failed to ban ${worker.fullName}. (${e.message})`));
            })
    );
};

export const clearException = (worker, type) => (dispatch, getState) => {
    const { employer } = getEmployerDetails(getState());
    dispatch(setIsApplyingAction(true));
    return (
        ApolloClient.mutate({
            mutation: ClearExceptionMutation,
            variables: {
                data: {
                    workerId: worker.id,
                    employerId: employer.id,
                },
            },
        })
            // TODO: Eventually change to not re-fetch the whole world
            .then(() => {
                const promises = [dispatch(fetchEmployer(employer.id, false))];
                if (type === "banned") {
                    promises.push(dispatch(fetchBannedWorkers()));
                }

                if (type === "preferred") {
                    promises.push(dispatch(fetchPreferredWorkers()));
                }

                return Promise.all(promises);
            })
            .then(() => {
                dispatch(setIsApplyingAction(false));
                dispatch(MetaActions.successToast(`${worker.fullName} is no longer ${type}`));
            })
            .catch(e => {
                dispatch(setIsApplyingAction(false));
                dispatch(MetaActions.errorToast(`${worker.fullName} is still ${type}. (${e.message})`));
            })
    );
};
