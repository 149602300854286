import ReactTableSimple from 'components/ReactTable/ReactTableSimple';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from "react-redux";
import JobTemplateActionMenu from "./JobTemplateActionMenu";
import { fetchJobTemplates } from "store/modules/jobTemplate/actions";
import { ordinalSuffixOf } from "utils/stringUtils";
import { actions as JobTemplateActions, selectors as JobTemplateSelectors } from "store/modules/jobTemplate";

function JobTemplateTable({ onDelete, onEdit }) {

    const dispatch = useDispatch();
    const data = useSelector(JobTemplateSelectors.getJobTemplateList);
    const isLoading = useSelector(JobTemplateSelectors.getIsTableLoading);

    useEffect(() => {
        dispatch(fetchJobTemplates());
    }, [])


    return (
        <ReactTableSimple
            classes="-highlight"
            loading={isLoading}
            data={data}
            columns={[
                {
                    Header: "Template Name",
                    id: "templateName",
                    width:'auto',
                    accessor: ({ templateName }) => templateName,
                },
                {
                    Header: "Work Type",
                    id: "workType",
                    width:'auto',
                    accessor: ({ workType }) => workType.label,
                },
                {
                    Header: "Address",
                    id: "Address",
                    width:'auto',
                    accessor: ({ unit, floor, street, city, region, country, postalCode }) => {
                        const streetName = street ? (floor ? ', ' : '') + street : ''
                        const cityName = city ? ', ' + city : ''
                        const regionName = region ? ', ' + region : ''
                        const unitValue = unit ? unit + '#- ' : ''
                        const floorValue = floor ? ordinalSuffixOf(floor) + ' Floor' : ''
                        const countryName = country ? ', ' + country : ''
                        const postalCodeNo = postalCode ? ', ' + postalCode : ''
                        return `${unitValue} ${floorValue} ${streetName} ${cityName} ${regionName} ${countryName} ${postalCodeNo}`;
                    },
                },
                {
                    id: "actions",
                    disableSortBy: true,
                    collapse: true,
                    disableFilters: true,
                    Cell: ({ row: { original } }) => {
                        const { id } = original;
                        return (
                            <JobTemplateActionMenu
                                id={id}
                                onDelete={onDelete}
                                onEdit={onEdit}
                            />
                        );
                    },
                },
            ]}
        />
    )
}

export default JobTemplateTable;
