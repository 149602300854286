import { gql } from "@apollo/client";

export default gql`
  mutation updateFaq($data: UpdateFAQInput!) {
    updateFaq(data: $data) {
      id
      question
      answer
      type
      isActive
      order
    }
  }
`;
