import React from "react";
import { DragDropContext } from "react-beautiful-dnd";
import withStyles from '@mui/styles/withStyles';
import Switch from "@mui/material/Switch";
import Typography from "@mui/material/Typography";
import { ArrowDropDown, ArrowRight, Menu } from "@mui/icons-material";
import FaqListItemMenu from "./FaqListItemMenu";
import ReactTableDraggable from "components/ReactTable/ReactTableDraggable";

const styles = () => ({
    order: {
        color: "#aebecd",
    },
    // switchIcon: {
    //     boxShadow: "none",
    //     color: "#FFFFFF !important",
    //     transform: "translateX(-2px)!important",
    //     width: 14,
    //     height: 14,
    //     border: "1px solid white",
    //     marginTop: 1,
    // },
    // switchChecked: {
    //     transform: "translateX(0px)!important",
    // },
    // switchBar: {
    //     width: 32,
    //     borderRadius: 16,
    //     opacity: "1 !important",
    //     backgroundColor: "#aebecd ",
    //     height: 16,
    // },
    answerStyle: {
        padding: "24px 180px 24px 200px",
        backgroundColor: "#FFF",
    },
});


class FaqTab extends React.Component {
    state = {
        expanded: true,
    };

    handleDragStart = () => {
        this.setState({ expanded: false });
    };

    handleDragEnd = ({ destination, source }) => {
        if (!destination) {
            return;
        }
        this.setState({ expanded: true });
        this.props.onSort(source.index, destination.index, this.props.faqListType);
    };

    render() {
        const { classes, faqs, onStatusToggle } = this.props;
        return (
            <DragDropContext onDragEnd={this.handleDragEnd} onDragStart={this.handleDragStart}>
                <ReactTableDraggable
                    classes="-highlight"
                    data={faqs}
                    expandView={this.state.expanded ? row => {
                        return (
                            <div className={classes.answerStyle}>
                                <Typography variant="body1">{row.original.answer}</Typography>
                            </div>
                        );
                    } : undefined}
                    columns={[
                        {
                            disableSortBy: true,
                            disableFilters: true,
                            id: "sortt",
                            width: 80,
                            Cell: <Menu className={classes.order} />,
                        },
                        {
                            disableSortBy: true,
                            disableFilters: true,
                            Header: "State",
                            id: "isActive",
                            width: 120,
                            Cell: ({ row }) => (
                                <Switch
                                    checked={row.original.isActive}
                                    onChange={() => {
                                        const { id, isActive } = row.original;
                                        onStatusToggle(id, !isActive);
                                    }}
                                    color="secondary"
                                    value={row.original.isActive}
                                    classes={{
                                        switchBase: classes.switchBase,
                                        // icon: classes.switchIcon,
                                        // iconChecked: classes.switchChecked,
                                        // bar: classes.switchBar,
                                    }}
                                />
                            ),
                        },
                        {
                            Header: "Question",
                            id: "question",
                            accessor: "question",
                            width: "auto",
                            disableSortBy: true,
                        },
                        {
                            expander: true,
                            id: "expander",
                            width: 60,
                            getProps: (state, rowInfo) => {
                                if (rowInfo && !rowInfo.original.answer) {
                                    return {
                                        onClick: () => { },
                                    };
                                }
                                return { className: "show-pointer" };
                            },
                            Cell: ({ row: { original, isExpanded, ...row }, column }) => {
                                if (original.answer)
                                    return (
                                        isExpanded ? <ArrowDropDown {...row.getToggleRowExpandedProps()} /> : <ArrowRight {...row.getToggleRowExpandedProps()} />
                                    )
                                return null;
                            },
                        },
                        {
                            disableSortBy: true,
                            disableFilters: true,
                            width: 80,
                            maxWidth: 80,
                            id: "actions",
                            Cell: ({ row }) => (
                                <FaqListItemMenu
                                    faqId={row.original.id}
                                    faqListType={this.props.faqListType}
                                    faq={row.original}
                                    onEdit={this.props.onEdit}
                                    onDelete={this.props.onDelete}
                                />
                            ),
                        },
                    ]}
                />
            </DragDropContext>
        );
    }
}

export default withStyles(styles)(FaqTab);
