import moment from "moment-timezone";
import * as pagination from "utils/graphql-pagination";
import { createReducer } from "utils/redux";
import { paginationSettings } from "./constants";
import types from "./types";
import { orderBy } from "lodash";

const { duration } = moment;
const initialListState = {
  sort: [],
  filter: [],
  data: [],
};

const initialLoadingState = {
  fetchingBatch: true,
  actions: false,
  //   ...initialStateBilling,
};

const initialState = {
  dcReversals: [],
  dcTransfers: {
      data: [],
  },
  dcTransferParams: {},
  dcTransferFilter: [],
  dcTransferTotal: 0,
  paymentsOverview: {},
  payrollByStatus: [],

  reversals: [],
  qbTerms: [],
  qbPaymentMethods: [],
  qbEmployerUpdateData: {},
  qbEmployerCreateData: {},
  generatedBillingSummary: {},
  dcAccount: {},

  dcPayrollByStatus: [],
  dcPayrollIssues: [],
  qbInvoiceAttachments: [],
  qbEmployerPayments: [],
  qbItems: [],
  qbPreferences: {},
  qbInvoiceNote: { note: "reduc note", attach: true },

  qbInvoicePdf: "",
  invoiceItemPayroll: [],
  generatedInvoices: [],
  qbCompanyInfo: {},
  dcPayroll: {},
  qbInvoice: {},
  qbEmployer: {},

  activeInvoice: {},
  isLoading: false,
  canSaveQb: true,

  deletedInvoices:[],
  employerWeekSummary: {},
  activeInvoiceItems: [],
  employerQbInvoices: [],
  employerInvoiceItems: [],
  employerInvoices: [],
  employerInvoice: {},
  employerBillingWeekSummary: [],
  previousBillingWeekSummary: [],
  billingWeekSummary: [],
  billingYearSummary: [],
  loading: { ...initialLoadingState },
  batches: { ...initialListState },
  individualTransfers: { ...initialListState },
  queuedBatch: null,
  batchDetails: {},
  ...pagination.initialState(paginationSettings),

  weeklyBilling: [],
};

const actionsMap = {
  //

  [types.SET_DC_PAYROLL_ISSUES]: (state, { payload }) => {
    return { ...state, dcPayrollIssues: payload };
  },
  [types.SET_CANCEL_TRANSFER_REASON]: (state, { payload }) => {
      return { ...state, cancelTransferReason: payload };
  },
  [types.SET_DC_STATUS_TRANSFERS]: (state, { payload }) => {
    return { ...state, payrollByStatus: payload, dcTransfers: payload,  ...pagination.createPaginationActions(paginationSettings), };
  },
  [types.SET_DC_TRANSFERS_PARAMS]: (state, { payload }) => {
    return { ...state, ...payload };
  },
  [types.SET_TRANSFER_TOTAL_COUNT]: (state, { payload }) => {
    return { ...state, dcTransferTotal: payload };
  },
  [types.SET_PAYMENT_OVERVIEW]: (state, { payload }) => {
    return { ...state, paymentsOverview: payload };
  },
  [types.SET_DC_REVERSALS]: (state, { payload }) => {
    return { ...state, reversals: payload };
  },
  [types.SET_PAYMENT_BILLING_COUNT]: (state, { payload }) => {
      return { ...state, paymentAndBillingCount: payload };
  },
  [types.SET_DC_REQUEST_FOR_FUNDS]: (state, { payload }) => {
    return { ...state, dcReversals: payload };
  },
  [types.SET_QB_PAYMENT_METHODS]: (state, { payload }) => {
    return { ...state, qbPaymentMethods: payload };
  },

  [types.SET_DC_ACCOUNT]: (state, { payload }) => {
    return { ...state, dcAccount: payload };
  },

  [types.SET_QB_TERMS]: (state, { payload }) => {
    return { ...state, qbTerms: payload };
  },

  [types.SET_INIT_QB_EMPLOYER_DATA]: (state, { payload }) => {    
    return { ...state, qbEmployerCreateData: { ...payload } };
  },

  [types.SET_UPDATE_QB_EMPLOYER_DATA]: (state, { payload }) => {
    const { field, value } = payload;
    const load = {
      ...state,
      qbEmployerUpdateData: { ...state.qbEmployerUpdateData, [field]: value },
    };

    // for (var key in updatedVal) {
    //   if (updatedVal[key] === " " || updatedVal[key] === "") {
    //     console.log(key + " is blank");
    //     //    delete data[key]
    //     load.canSaveQb = false;
    //   }
    //   //   else    load.canSaveQb = true;
    // }
    // console.log("canSaveQb", load.canSaveQb);

    return load;
  },

  [types.SET_CREATE_QB_EMPLOYER_DATA]: (state, { payload }) => {
    const { field, value } = payload;
    return { ...state, qbEmployerCreateData: { ...state.qbEmployerCreateData, [field]: value } };
  },

  [types.SET_NEW_QB_INVOICE_NOTE]: (state, { payload }) => {
    return { ...state, qbInvoiceNote: { ...state.qbInvoiceNote, ...payload } };
  },
  [types.SET_QB_INVOICE_ATTACHMENTS]: (state, { payload }) => {
    return { ...state, qbInvoiceAttachments: [...payload[0].items] };
  },

  [types.SET_QB_EMPLOYER_PAYMENTS]: (state, { payload }) => {
    return { ...state, qbEmployerPayments: payload };
  },

  [types.SET_QB_PREFERENCES]: (state, { payload }) => {
    return { ...state, qbPreferences: payload };
  },

  [types.SET_QB_ITEMS]: (state, { payload }) => {
    return { ...state, qbItems: payload };
  },
  //
  [types.SET_DC_TRANSFERS]: (state, { payload }) => {
    return { ...state, dcTransfers: payload };
  },
  //
  [types.SET_DC_PAYROLL_BY_STATUS]: (state, { payload }) => {
    return { ...state, dcPayrollByStatus: [...payload] };
  },
  //
  [types.SET_DC_PAYROLL]: (state, { payload }) => {
    return { ...state, dcPayroll: { ...payload } };
  },
  //
  [types.UPDATE_EMPLOYER_BILLING_WEEK_INVOICE_DATA]: (state, { payload }) => {
    // state.employerBillingWeekSummary.find(shift=>shift.jobWorker)

    const newState = state.activeInvoiceItems.map(shift => {
      if (shift.positionId === payload.positionId) {
        shift = payload;
        shift.total =
          ((duration(moment(shift.checkOutUtc).diff(moment(shift.checkInUtc))).asMinutes() -
            shift.breakDeductionMins) /
            60) *
          shift.rate;
        shift.duration =
          (duration(moment(shift.checkOutUtc).diff(moment(shift.checkInUtc))).asMinutes() -
            shift.breakDeductionMins) /
          60;
      }
      return shift;
    });
    // workerId;
    return { ...state, activeInvoiceItems: [...newState] };
  },

  [types.SET_INVOICE_ITEM_PAYROLL]: (state, { payload }) => {
    return {
      ...state,
      invoiceItemPayroll: payload,
    };
  },
  //
  [types.SET_GENERATED_BILLING]: (state, { payload }) => {
    console.log("SET_GENERATED_BILLING", payload);
    return {
      ...state,
      generatedBillingSummary: { ...payload },
      isLoading: false,
    };
  },
  //
  [types.SET_GENERATED_BILLING_LOADING]: state => {
    return {
      ...state,

      isLoading: true,
    };
  },
  //
  [types.SET_QB_INVOICE]: (state, { payload }) => {
    return {
      ...state,
      qbInvoice: { ...payload },
    };
  },
  //
  [types.SET_QB_INVOICE_PDF]: (state, { payload }) => {
    return {
      ...state,
      qbInvoicePdf: `data:application/pdf;base64,${payload}`,
    };
  },
  //
  [types.SET_QB_EMPLOYER]: (state, { payload }) => {
    return {
      ...state,
      qbEmployer: { ...payload },
    };
  },
  //
  [types.SET_QB_COMPANY_INFO]: (state, { payload }) => {
    return {
      ...state,
      qbCompanyInfo: { ...payload },
    };
  },
  //
  [types.SET_EMPLOYER_QB_INVOICES]: (state, { payload }) => {
    return {
      ...state,
      employerQbInvoices: [...payload],
    };
  },
  //
  [types.SET_EMPLOYER_INVOICEITEMS]: (state, { payload }) => ({
    ...state,
    employerInvoiceItems: [...payload],
  }),
  [types.SET_EMPLOYER_WEEK_SUMMERY]: (state, { payload }) => ({
    ...state,
    employerWeekSummary: payload,
  }),
  [types.SET_EMPLOYER_INVOICES]: (state, { payload }) => {
    const aInv = payload.length
      ? payload.reduce((prev, current) => (prev.id > current.id ? prev : current))
      : {};
    const activeInvoiceItems = aInv ? aInv.invoiceItems : [];
    if(activeInvoiceItems && activeInvoiceItems.length > 0){
        activeInvoiceItems.forEach(n => n.total = n.rate * n.invoicableUnits);
    }

    return {
      ...state,
      employerInvoices: orderBy(payload, ['id'], ['asc']),
      activeInvoice: aInv,
      activeInvoiceItems: activeInvoiceItems || [],
    };
  },
  [types.SET_EMPLOYER_INVOICE]: (state, { payload }) => ({
    ...state,
    employerInvoice: [...payload],
  }),

  [types.SET_DELETED_INVOICES]: (state, { payload }) => ({
    ...state,
    deletedInvoices: payload,
  }),

  //
  [types.SET_EMPLOYER_BILLING_WEEK_SUMMARY]: (state, { payload }) => {
    return {
      ...state,
      employerBillingWeekSummary: payload,
    };
  },

  [types.SET_BILLING_YEAR_SUMMARY]: (state, { payload }) => ({
    ...state,
    billingYearSummary: payload,
  }),

  [types.SET_LOADING_STATE]: (state, { payload }) => {
      const { key, value } = payload;
      return {
          ...state,
          loading: {
              ...state.loading,
              [key]: value,
          },
      };
  },

  [types.SET_LOADING_STATE_BILLING]:(state, { payload }) => {
      const { isLoading } = payload;
      return {
          ...state,
          isLoading: isLoading,
      };
  },

  [types.UPDATE_EMPLOYER_BILLING_WEEK_DATA]: (state, { payload }) => {
    // state.employerBillingWeekSummary.find(shift=>shift.jobWorker)

    const newState = state.employerBillingWeekSummary.map(shift => {
      if (shift.jobWorkerId === payload.jobWorkerId) {
        shift = payload;
        shift.total =
          ((duration(moment(shift.checkOutUtc).diff(moment(shift.checkInUtc))).asMinutes() -
            shift.breakValue) /
            60) *
          shift.billingRate;
        shift.duration =
          (duration(moment(shift.checkOutUtc).diff(moment(shift.checkInUtc))).asMinutes() -
            shift.breakValue) /
          60;
      }
      return shift;
    });
    // workerId;
    return { ...state, employerBillingWeekSummary: [...newState] };
  },

  [types.SET_PAYMENT_BATCHES_LIST_DATA]: (state, { payload }) => {
    const { data, part } = payload;

    return {
      ...state,
      batches: {
        ...state.batches,
        [part]: data,
      },
    };
  },

  [types.SET_BILLING_WEEK_SUMMARY]: (state, { payload }) => ({
    ...state,
    billingWeekSummary: payload,
  }),
  [types.SET_PREVIOUS_BILLING_WEEK_SUMMARY]: (state, { payload }) => ({
    ...state,
    previousBillingWeekSummary: payload,
  }),

  [types.SET_WEEKLY_BILLING_PERFORMANCE]: (state, { payload }) => ({
    ...state,
    weeklyBilling: payload,
  }),

  [types.SET_QUEUED_PAYMENT_BATCH]: (state, { payload: { queuedPaymentBatch } }) => {
    // const val = [...state.billingWeeks];
    // val.push({ week: 1 });
    return { ...state, queuedBatch: queuedPaymentBatch };
  },

  [types.SET_BATCH_DETAILS]: (state, { payload: { paymentBatch } }) => {
    return {
      ...state,
      batchDetails: paymentBatch,
    };
  },
  ...pagination.createPaginationActions(paginationSettings),
};

export default createReducer(initialState, actionsMap);
