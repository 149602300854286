import React from "react";
import { withRouter } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVert from "@mui/icons-material/MoreVert";
import { employerJobActionMenuStyles } from "views/Employer/helpers";
import history from "utils/history";

class PrivateJobActionMenu extends React.Component {
  state = {
    anchorEl: null,
  };

  handleClick = event => {
      const { id } = this.props;
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  viewJob = () => {
    history.push(`/private-job/${this.props.data.id}`)
    this.handleClose();
  };

  render() {
    const { anchorEl } = this.state;

    const id = `action-menu-${this.props.jobId}`;

    return (
      <React.Fragment>
        <IconButton
          aria-owns={anchorEl ? id : undefined}
          aria-haspopup="true"
          disableRipple
          onClick={this.handleClick}
          size="large">
          <MoreVert color="primary" />
        </IconButton>
        {anchorEl ? (
          <Menu
            id={id}
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={this.handleClose}
            disableAutoFocusItem>
              <MenuItem onClick={this.viewJob}>
                View Jobs
              </MenuItem>
          </Menu>
        ) : null}
      </React.Fragment>
    );
  }
}

export default employerJobActionMenuStyles(withRouter(PrivateJobActionMenu));
