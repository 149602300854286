import gql from "graphql-tag";

export default gql`
mutation($data: CreateWorkTypeInput!) {
    createWorkType(data: $data){
        id
        createdAt
        updatedAt
        isDeleted
        label
        order
        isActive
        baseRate
        baseBillingRate
        bannerImageUrl
        bannerImageUrl2x
        bannerImageUrl3x
        createdAtUtc
        updatedAtUtc
    }
}
` 