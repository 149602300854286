import { gql } from "@apollo/client";

export default gql`
  mutation preferWorker($data: PreferWorkerInput!) {
    preferWorker(data: $data) {
      id
      employerId
      workerId
      type
      createdAt
      createdAtUtc
      reason
      isNotified
      notifyAfter
      notifyAfterUtc
    }
  }
`;
