import React from "react";
import { withRouter } from "react-router-dom";
import moment from "moment-timezone";
import withStyles from '@mui/styles/withStyles';
import { Document, Image, Link, Page, PDFViewer, Text, View } from "@react-pdf/renderer";
import Logo from "assets/img/logo.png";
import { formatMoney, formatNumber } from "utils/stringUtils";
import { pdfPayStubTableStyles, pdfTemplateStyles, styles, viewerStyle } from "./helpers";

class PayStubPdfService extends React.Component {
  constructor() {
    super();
    this.state = {
      defaultDocumentProps: {
        title: "Grizzly Force Payroll Receipt",
        subject: "Payroll Receipt",
        keywords: "Payroll Receipt",
        creator: "Payroll Receipt",
        producer: "Payroll Receipt",
      },
    };
  }

  renderPdfPayStub = props => {
    const { position, payroll, jobDetails } = props;

    const {
      table,
      tableHeaderRow,

      tableRow,
      tableHeaderCell,
      tableCell,
      tableColRight,
      tableColLeft,
      contentRight,
      contentLeft,
      tableColEarningsType,
      tableColEarnings,
      workerName,
      workerAddress,
      innerContent,
      header,
    } = pdfPayStubTableStyles;

    const HeaderComponent = () => (
      <View style={header}>
        <View style={pdfTemplateStyles.leftBlock}>
          <Image style={pdfTemplateStyles.logo} src={Logo} />
        </View>
        <View style={pdfTemplateStyles.rightBlock}>
          <Text style={pdfTemplateStyles.billingAddressLg}>Grizzly Force Labour Corp.</Text>
          <Text style={pdfTemplateStyles.billingAddress}>PO BOX 57875 Postal Station A</Text>
          <Text style={pdfTemplateStyles.billingAddress}> Toronto, ON. M5W 5M5</Text>
          <Link style={pdfTemplateStyles.billingAddress}> support@grizzlyforce.ca</Link>
        </View>
      </View>
    );

    const RenderWorkerDetailsComponent = () => (
      <View style={pdfTemplateStyles.content}>
        <View style={pdfTemplateStyles.leftBlock}>
          <Text style={workerName}>Payroll Receipt</Text>
        </View>
        <View style={pdfTemplateStyles.rightBlock}>
          <Text style={workerName}>{position.fullName}</Text>

          <Text style={workerAddress}>{position.street},</Text>
          <Text style={workerAddress}>
            {position.city}, {position.region}. {position.postalCode}
          </Text>
          <Text style={workerAddress}>{position.email}</Text>
        </View>
      </View>
    );

    let totalAdditionalEarnings = 0;
    const RenderWageEarnings = () => (
      <View style={table}>
        <View style={tableHeaderRow}>
          <View style={{ width: "100%" }}>
            <Text style={tableHeaderCell}>Earnings</Text>
          </View>
        </View>
        <View style={tableHeaderRow}>
          <View style={tableColEarningsType}>
            <Text style={tableHeaderCell}>Type</Text>
          </View>
          <View style={tableColEarnings}>
            <Text style={tableHeaderCell}>Hours </Text>
          </View>
          <View style={tableColEarnings}>
            <Text style={tableHeaderCell}>Rate</Text>
          </View>
          <View style={{ ...tableColEarnings, textAlign: "right" }}>
            <Text style={tableHeaderCell}>Total </Text>
          </View>
        </View>
        {payroll.wageEarnings.map((wage, i) => {
          return (
            <View key={i} style={tableRow}>
              <View style={tableColEarningsType}>
                <Text style={tableCell}>{wage.earningsType && wage.earningsType.name}</Text>
              </View>
              <View style={tableColEarnings}>
                <Text style={tableCell}>{formatNumber(wage.units)} </Text>
              </View>
              <View style={tableColEarnings}>
                <Text style={tableCell}>{formatMoney(wage.rate)}</Text>
              </View>
              <View style={{ ...tableColEarnings, textAlign: "right" }}>
                <Text style={tableCell}>{formatMoney(wage.amount)} </Text>
              </View>
            </View>
          );
        })}
        <View style={tableRow}>
          <View style={{ ...tableColLeft, width: "70%" }}>
            <Text style={tableCell}>Total</Text>
          </View>
          <View style={{ ...tableColRight, width: "30%" }}>
            <Text style={tableCell}>{formatMoney(payroll.grossWages)}</Text>
          </View>
        </View>
      </View>
    );

    const RenderAdditionalEarnings = () => (
      <View style={table}>
        <View style={tableHeaderRow}>
          <View style={{ ...tableColLeft, width: "70%" }}>
            <Text style={tableHeaderCell}>Additional Earnings</Text>
          </View>

          <View style={{ ...tableColRight, width: "30%" }}>
            <Text style={tableHeaderCell}> </Text>
          </View>
        </View>
        {payroll.additionalEarnings.map((wage, i) => {
          totalAdditionalEarnings = totalAdditionalEarnings + wage.amount;

          return (
            <View key={i} style={tableRow}>
              <View style={{ ...tableColLeft, width: "70%" }}>
                <Text style={tableCell}>{wage.earningsType && wage.earningsType.name}</Text>
              </View>

              <View style={{ ...tableColRight, width: "30%" }}>
                <Text style={tableCell}>{formatMoney(wage.amount)} </Text>
              </View>
            </View>
          );
        })}
        <View style={tableRow}>
          <View style={{ ...tableColLeft, width: "70%" }}>
            <Text style={tableCell}>Total</Text>
          </View>
          <View style={{ ...tableColRight, width: "30%" }}>
            <Text style={tableCell}>{formatMoney(totalAdditionalEarnings)}</Text>
          </View>
        </View>
      </View>
    );

    const RenderDeductions = () => (
      <View style={table}>
        <View style={tableHeaderRow}>
          <View style={tableColLeft}>
            <Text style={tableHeaderCell}>Deductions</Text>
          </View>
          <View style={tableColRight}>
            <Text style={tableHeaderCell}> </Text>
          </View>
        </View>
        {payroll.employeeDeductions.map((deduction, i) => (
          <View key={i} style={tableRow}>
            <View style={{ ...tableColLeft, width: "70%" }}>
              <Text style={tableCell}>{deduction.deductionType && deduction.deductionType.longName}</Text>
            </View>
            <View style={{ ...tableColRight, width: "30%" }}>
              <Text style={tableCell}>{formatMoney(deduction.amount)}</Text>
            </View>
          </View>
        ))}
        <View style={tableRow}>
          <View style={{ ...tableColLeft, width: "70%" }}>
            <Text style={tableCell}>Total Deductions</Text>
          </View>
          <View style={{ ...tableColRight, width: "30%" }}>
            <Text style={tableCell}>{formatMoney(payroll.totalEmployeeDeductions)}</Text>
          </View>
        </View>
      </View>
    );

    const RenderTotals = () => (
      <View style={table}>
        <View style={tableHeaderRow}>
          <View style={tableColLeft}>
            <Text style={tableHeaderCell}>Summary</Text>
          </View>
          <View style={tableColRight}>
            <Text style={tableHeaderCell}> </Text>
          </View>
        </View>

        <View style={tableRow}>
          <View style={{ ...tableColLeft, width: "70%" }}>
            <Text style={tableCell}>Wages</Text>
          </View>
          <View style={{ ...tableColRight, width: "30%" }}>
            <Text style={tableCell}>{formatMoney(payroll.grossWages)}</Text>
          </View>
        </View>
        <View style={tableRow}>
          <View style={{ ...tableColLeft, width: "70%" }}>
            <Text style={tableCell}>Additional</Text>
          </View>
          <View style={{ ...tableColRight, width: "30%" }}>
            <Text style={tableCell}>{formatMoney(totalAdditionalEarnings)}</Text>
          </View>
        </View>
        <View style={tableRow}>
          <View style={{ ...tableColLeft, width: "70%" }}>
            <Text style={tableCell}>Gross Earnings</Text>
          </View>
          <View style={{ ...tableColRight, width: "30%" }}>
            <Text style={tableCell}>{formatMoney(payroll.grossEarnings)}</Text>
          </View>
        </View>
        <View style={tableRow}>
          <View style={{ ...tableColLeft, width: "70%" }}>
            <Text style={tableCell}>Deductions</Text>
          </View>
          <View style={{ ...tableColRight, width: "30%" }}>
            <Text style={tableCell}>{formatMoney(payroll.totalEmployeeDeductions)}</Text>
          </View>
        </View>
        <View style={tableRow}>
          <View style={{ ...tableColLeft, width: "70%" }}>
            <Text style={tableCell}>Net Earned</Text>
          </View>
          <View style={{ ...tableColRight, width: "30%" }}>
            <Text style={tableCell}>{formatMoney(payroll.netEarnings)}</Text>
          </View>
        </View>
      </View>
    );

    const PayrollComponent = () => {
      return (
        <View style={innerContent}>
          <RenderWageEarnings />
          <RenderAdditionalEarnings />
          <RenderDeductions />
          <RenderTotals />
        </View>
      );
    };
    const JobDetailsComponent = () => {
      return (
        <View style={innerContent}>
          <View style={tableHeaderRow}>
            <View style={tableColLeft}>
              <Text style={tableHeaderCell}>Job Details</Text>
            </View>
            <View style={tableColRight}>
              <Text style={tableHeaderCell}> </Text>
            </View>
          </View>

          <View style={tableRow}>
            <View style={{ ...tableColLeft, width: "50%" }}>
              <Text style={tableCell}>Employer</Text>
            </View>
            <View style={{ ...tableColRight, width: "50%" }}>
              <Text style={tableCell}>{jobDetails.employer && jobDetails.employer.companyName}</Text>
            </View>
          </View>
          <View style={tableRow}>
            <View style={{ ...tableColLeft, width: "50%" }}>
              <Text style={tableCell}>Location</Text>
            </View>
            <View style={{ ...tableColRight, width: "50%" }}>
              <Text style={tableCell}>{jobDetails.street}</Text>
              <Text style={tableCell}>
                {jobDetails.city}, {jobDetails.region}, Canada
              </Text>
            </View>
          </View>
          <View style={tableRow}>
            <View style={{ ...tableColLeft, width: "30%" }}>
              <Text style={tableCell}>Shift</Text>
            </View>
            <View style={{ ...tableColRight, width: "70%" }}>
              <Text style={tableCell}>
                {moment(jobDetails.start).format("MMM Do, YYYY")}
                {moment(jobDetails.start).format("h:mm a")} -
                {moment(jobDetails.end).format("h:mm a")}
              </Text>
            </View>
          </View>
          <View style={tableRow}>
            <View style={{ ...tableColLeft, width: "30%" }}>
              <Text style={tableCell}>Checkin</Text>
            </View>
            <View style={{ ...tableColRight, width: "70%" }}>
              <Text style={tableCell}> {moment(position.startShift).format("MMM Do, h:mm a")}</Text>
            </View>
          </View>
          <View style={tableRow}>
            <View style={{ ...tableColLeft, width: "30%" }}>
              <Text style={tableCell}>Checkout</Text>
            </View>
            <View style={{ ...tableColRight, width: "70%" }}>
              <Text style={tableCell}> {moment(position.endShift).format("MMM Do, h:mm a")}</Text>
            </View>
          </View>
        </View>
      );
    };

    // <View style={workerBlock}>
    //   <Text style={workerName}>{jobDetails.employer.companyName}</Text>
    //   <Text style={workerAddress}>{jobDetails.street},</Text>
    //   <Text style={workerAddress}>
    //     {jobDetails.city}, {jobDetails.region}, Canada
    //   </Text>
    //   <Text style={workerAddress}>
    //     {moment(jobDetails.start).format("MMM Do, YYYY")}
    //     {moment(jobDetails.start).format("h:mm a")} - {moment(jobDetails.end).format("h:mm a")}
    //   </Text>
    //   <Text style={workerAddress}>
    //     Checkin Time: {moment(position.startShift).format("MMM Do, h:mm a")}
    //   </Text>
    //   <Text style={workerAddress}>
    //     Checkout Time: {moment(position.endShift).format("MMM Do, h:mm a")}
    //   </Text>
    // </View>

    const RenderBody = () => (
      <View style={pdfTemplateStyles.content}>
        <View style={contentLeft}>
          <JobDetailsComponent />
        </View>
        <View style={contentRight}>
          <PayrollComponent />
        </View>
      </View>
    );

    const renderedDoc = (
      <Document {...this.state.defaultDocumentProps}>
        <Page size="LETTER" wrap={true}>
          <HeaderComponent />
          <RenderWorkerDetailsComponent />
          <RenderBody />
        </Page>
      </Document>
    );

    return renderedDoc;
  };

  render = () => (
    <PDFViewer style={viewerStyle}>{this.renderPdfPayStub({ ...this.props })}</PDFViewer>
  );
}

export default withStyles(styles)(withRouter(PayStubPdfService));
