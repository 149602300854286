import gql from "graphql-tag";

export const adminsQuery = gql`
  query admins{
    admins{
      id
      email
      firstName
      lastName
      title
      role
      addedBy
      signedProfileImageUrl
    }
  }
`;

export const adminsByIdQuery = gql`
  query($id : ID!){
    adminById(id : $id){
      id
      email
      firstName
      lastName
      title
      role
      addedBy
      signedProfileImageUrl
    }
  }
`;

export const permissionByAdminIdQuery = gql`
  query($id: ID!, $perPage: Int!, $page: Int!) {
    getAdminPermissions(id: $id, perPage: $perPage, page: $page) {
      permissions{
        permission_name
        description
        resource_server_name
        resource_server_identifier
        sources
      }
      start
      limit
      total
    }
  }
`;

export const getAllAdminPermissionQuery = gql`
  query {
    getAllAdminPermissions {
      value
      description
    }
  }
`;

export const getUnAssignAdminPermissionQuery = gql`
  query($id: ID!) {
    getUnAssignAdminPermissions(id: $id)
  }
`;

export default adminsQuery;
