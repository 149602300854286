import React from "react";
import NumberFormat from "react-number-format";
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import InfoIcon from "@mui/icons-material/InfoOutlined";
import AddressAutocomplete from "components/Autocomplete/AddressAutocomplete";
import HelperMessage from "./HelperMessage";

function WorkerDetailsSection(props) {
    const {
        setFirstName,
        setEmail,
        emailIsAvailable,
        emailIsValid,
        setSin,
        checkSin,
        sinIsValid,
        sinIsTaken,
        onAddressChange,
        setPhone,
        selectedLocation,
        phone,
    } = props;

    return (
        <React.Fragment>
            <Grid container item xs={12}>
                <Grid container item xs={4} direction="column" style={{ paddingRight: 4 }}>
                    <FormControl fullWidth margin="normal">
                        <TextField
                            id="firstName"
                            label="First Name"
                            onChange={setFirstName}
                            type="text"
                            margin="normal"
                            inputProps={{ min: 1 }}
                        />
                    </FormControl>
                </Grid>
                <Grid container item xs={4} direction="column" style={{ paddingLeft: 4 }}>
                    <FormControl fullWidth margin="normal">
                        <TextField
                            id="lastName"
                            label="Last Name"
                            onChange={setFirstName}
                            type="text"
                            margin="normal"
                            inputProps={{ min: 1 }}
                        />
                    </FormControl>
                </Grid>
            </Grid>

            <Grid container item xs={12}>
                <Grid container item xs={8} direction="column" style={{ paddingLeft: 4 }}>
                    <FormControl fullWidth margin="normal">
                        <TextField
                            id="email"
                            label=" Email Address"
                            onChange={setEmail}
                            type="email"
                            margin="normal"
                            inputProps={{ min: 1 }}
                        />
                    </FormControl>
                </Grid>

                <Grid container item xs={4} direction="column" style={{ paddingLeft: 4 }}>
                    {emailIsValid === false && (
                        <HelperMessage
                            icon={<InfoIcon color="error" />}
                            message="Email is invalid."
                        />
                    )}
                    {emailIsAvailable === false && (
                        <HelperMessage
                            icon={<InfoIcon color="error" />}
                            message="Email is already taken."
                        />
                    )}
                </Grid>

                <Grid container item xs={8} direction="column" style={{ paddingRight: 4 }}>
                    <FormControl fullWidth margin="normal">
                        <NumberFormat
                            id="phone"
                            label="Phone"
                            required
                            // InputLabelProps={{
                            //     shrink: true,
                            // }}
                            onChange={setPhone}
                            margin="normal"
                            fullWidth
                            customInput={TextField}
                            format="(###) ###-####"
                            mask="#"
                        />
                    </FormControl>
                </Grid>
                <Grid container item xs={4} direction="column" style={{ paddingLeft: 4 }}>
                    {phone && phone.length < 10 && (
                        <HelperMessage
                            icon={<InfoIcon color="error" />}
                            message="Phone number is invalid."
                        />
                    )}
                </Grid>

                <Grid container item xs={8} direction="column" style={{ paddingRight: 4 }}>
                    <FormControl fullWidth margin="normal">
                        <TextField
                            id="unit"
                            label="Apt."
                            onChange={setFirstName}
                            type="text"
                            margin="normal"
                            inputProps={{
                                min: 1,
                            }}
                        />
                    </FormControl>
                </Grid>
                <Grid container item xs={12}>
                    <Grid container item xs={8} direction="column" style={{ paddingRight: 4 }}>
                        <AddressAutocomplete value={selectedLocation} onChange={onAddressChange} />
                    </Grid>
                </Grid>
                <Grid container item xs={12}>
                    <Grid container item xs={8} direction="column" style={{ paddingRight: 4 }}>
                        <FormControl fullWidth margin="normal">
                            <NumberFormat
                                id="sin"
                                label=" Social Insurance Number (SIN)"
                                InputLabelProps={{ shrink: true }}
                                onChange={setSin}
                                onBlur={checkSin}
                                margin="normal"
                                inputProps={{ min: 1 }}
                                fullWidth
                                customInput={TextField}
                                format="###-###-###"
                                mask=""
                            />
                        </FormControl>
                    </Grid>

                    <Grid container item xs={4} direction="column" style={{ paddingLeft: 4 }}>
                        {sinIsTaken && (
                            <HelperMessage
                                icon={<InfoIcon color="error" />}
                                message="SIN number is already taken."
                            />
                        )}
                        {sinIsValid === false && (
                            <HelperMessage
                                icon={<InfoIcon color="error" />}
                                message="SIN number is invalid."
                            />
                        )}
                    </Grid>
                </Grid>
            </Grid>
        </React.Fragment>
    );
}

WorkerDetailsSection.defaultProps = {
    selectedLocation: null,
};

export default WorkerDetailsSection;
