import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Grid from "@mui/material/Grid";
import makeStyles from '@mui/styles/makeStyles';
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import Star from "@mui/icons-material/Star";
import { formatMapUrl } from "utils/map";
import { calculateMargins } from "utils/money";
import { formatMoney, formatPhone } from "utils/stringUtils";
import { selectors as JobDetailSelectors, actions as JobDetailActions } from "store/modules/jobDetails";
import { FormControlLabel, Switch } from "@mui/material";

const useStyles = makeStyles(({ shape, spacing, palette }) => ({
    container: {
        borderRight: `1px solid ${palette.grey[500]}`,
        paddingRight: spacing(3),
        "@media (max-width: 991px)": {
            borderRight: "none",
            paddingRight: 0,
            borderBottom: `1px solid ${palette.grey[500]}`,
            paddingBottom: spacing(3),
        },
    },
    underlineHover: {
        textDecoration: "none",
        "&:hover": {
            textDecoration: "underline",
        },
    },
    map: {
        width: "350px",
        height: "120px",
        border: `1px solid ${palette.grey[500]}`,
        borderRadius: shape.borderRadius / 2,
        marginTop: spacing(2),
        "@media (min-width: 601px) and (max-width: 1200px)": {
            width: "80%"
        },
        "@media (max-width: 600px)": {
            width: "100%"
        },
    },
    billing: {
        "@media (max-width: 600px)": {
            marginTop: spacing(2)
        },
    },
    caption: {
        display: "block",
    },
    rating: {
        display: "flex",
        alignItems: "center",
    },
    ratesLine: {
        maxWidth: 100,
        height: 1,
        backgroundColor: palette.grey[500],
        marginTop: spacing(1),
        marginBottom: spacing(1),
    },
    ratesContainer: {
        cursor: "default",
    },
}));

function EmployerInfo() {
    const classes = useStyles();
    const employer = useSelector(JobDetailSelectors.getEmployer);
    const employerId = useSelector(JobDetailSelectors.getEmployerId);
    const companyName = useSelector(JobDetailSelectors.getCompanyName);
    const employerContact = useSelector(JobDetailSelectors.getEmployerContact);
    const employerEmail = useSelector(JobDetailSelectors.getEmployerEmail);
    const employerMobilePhone = useSelector(JobDetailSelectors.getEmployerMobilePhone);
    const jobAddress = useSelector(JobDetailSelectors.getJobAddress);
    const jobStreet = useSelector(JobDetailSelectors.getJobStreet);
    const jobUnit = useSelector(JobDetailSelectors.getJobUnit);
    const jobCity = useSelector(JobDetailSelectors.getJobCity);
    const jobId = useSelector(JobDetailSelectors.getJobId);
    const notifyEmployer = useSelector(JobDetailSelectors.getNotifyEmployer);
    const jobRegion = useSelector(JobDetailSelectors.getJobRegion);
    const employerRating = useSelector(JobDetailSelectors.getEmployerRating);
    const employerJobCount = useSelector(JobDetailSelectors.getEmployerJobCount);
    const employerBillingRate = useSelector(JobDetailSelectors.getEmployerBillingRate);
    const employerPayRate = useSelector(JobDetailSelectors.getEmployerPayRate);
    const { locationInstruction, floor } = useSelector(JobDetailSelectors.getJobDetails);
    const dispatch = useDispatch();
    const handleChangeReminders = () => {
        dispatch(JobDetailActions.setEmployerJobReminder(jobId))
    }

    const { percentage, dollars } = calculateMargins(employerPayRate, employerBillingRate);
    const tooltip = `Margin/hr: ${formatMoney(dollars)} (${percentage}%)`;
    return (
        <Grid container item xs={12} md={6} className={classes.container}>
            <Grid container item xs={12} sm={9} direction="column" justifyContent="space-between">
                <div>
                    <Link to={`/employers/${employer.id}`} className={classes.underlineHover}>
                        <Typography variant="h4">{companyName}</Typography>
                    </Link>
                    <Typography variant="body1" gutterBottom>
                        {employerContact}
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        {employerEmail}
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        {employerMobilePhone}
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        {jobUnit ? `${jobUnit} - ` : ''}{jobAddress}
                    </Typography>
                    {floor ?
                        <Typography variant="body1" gutterBottom>
                            <b>Floor:</b> {floor}
                        </Typography> :
                        null
                    }
                    {locationInstruction ?
                        <Typography variant="body1" gutterBottom>
                            <b>Location instructions:</b> {locationInstruction}
                        </Typography> :
                        null
                    }
                    <FormControlLabel
                        control={
                            <Switch
                                id="notifyEmployer"
                                defaultChecked={notifyEmployer}
                                onChange={handleChangeReminders}
                                name="notifyEmployer"
                                color="secondary"
                            />
                        }
                        label="Reminders for the job"
                        style={{ color: "rgb(33 42 52)" }}
                    />
                </div>
                <img
                    alt="Job Location Map"
                    className={classes.map}
                    src={formatMapUrl(jobStreet, jobCity, jobRegion, "350x120")}
                />
            </Grid>
            <Grid container item xs={12} sm={3} direction="column" alignItems="flex-end" justifyContent="space-between" className={classes.billing}>
                <div>
                    <Typography
                        color="textSecondary"
                        variant="caption"
                        align="right"
                        className={classes.caption}>
                        Rating
                    </Typography>
                    <Typography variant="h6" align="right" gutterBottom>
                        {employerRating ? (
                            <span className={classes.rating}>
                                {employerRating}
                                <Star fontSize="small" />
                            </span>
                        ) : (
                            <i>Not Rated</i>
                        )}
                    </Typography>
                    <Typography
                        color="textSecondary"
                        variant="caption"
                        align="right"
                        className={classes.caption}>
                        Jobs
                    </Typography>
                    <Typography variant="h6" align="right">
                        {employerJobCount}
                    </Typography>
                </div>
                <Tooltip title={tooltip} placement="top">
                    <div className={classes.ratesContainer}>
                        <Typography
                            color="textSecondary"
                            variant="caption"
                            align="right"
                            className={classes.caption}>
                            Base Pay
                        </Typography>
                        <Typography variant="h6" align="right">
                            {`${formatMoney(employerPayRate)}/hr`}
                        </Typography>
                        <div className={classes.ratesLine} />
                        <Typography
                            color="textSecondary"
                            variant="caption"
                            align="right"
                            className={classes.caption}>
                            Base Billable
                        </Typography>
                        <Typography variant="h6" align="right">
                            {`${formatMoney(employerBillingRate)}/hr`}
                        </Typography>
                    </div>
                </Tooltip>
            </Grid>
        </Grid>
    );
}

export default EmployerInfo;
