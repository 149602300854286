import { gql } from "@apollo/client";

export default gql`
  query faqs {
    faqs {
      edges {
        node {
          id
          question
          answer
          isActive
          type
        }
      }
    }
  }
`;
