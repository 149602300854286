/* eslint-disable react/prop-types */
// findPayrollByPositionId
import React, { useState } from "react";
import moment from "moment-timezone";
import Collapse from "@mui/material/Collapse";
import { yellow } from "@mui/material/colors";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Typography from "@mui/material/Typography";
import CheckCircle from "@mui/icons-material/CheckCircle";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import RemoveCircle from "@mui/icons-material/RemoveCircle";
import Warning from "@mui/icons-material/Warning";
import { invoiceModalTableStyles, StyledTableCell } from "views/Finance/helpers";
import { formatMoney, formatNumber } from "utils/stringUtils";
import { convertUTCToTimezoneWithOffset } from "../../../../../../../utils/time";

function InvoiceModalTable(props) {
  const { invoice, classes } = props;
  const { invoiceItems = [] } = invoice;
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("calories");
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [dense] = useState(false);

  const [rowsPerPage, setRowsPerPage] = useState(15);
  const emptyRows = rowsPerPage - Math.min(rowsPerPage, invoiceItems.length - page * rowsPerPage);

  const Row = props => {
    const { row, isItemSelected } = props;
    const [open, setOpen] = React.useState(false);
    const duration =
      (moment.duration(moment(row.checkOutUtc).diff(moment(row.checkInUtc))).asMinutes() -
        row.breakDeductionMins) /
      60;
    const total = duration * row.rate;

    const renderCollapse = () => (
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={9}>
          <Collapse in={open} timeout="auto" mountOnEnter unmountOnExit>
            <div style={{ minHeight: 200 }}>
              <Grid container>
                {/* <Grid item md={4} xs={12}>
                  <Table className={classes.table} aria-labelledby="tableTitle2" size={dense ? "small" : "small"} aria-label="enhanced table2">
                    <TableHead>
                      <TableRow>
                        <TableCell>Date</TableCell>
                        <TableCell>Customer</TableCell>
                        <TableCell align="right">Amount</TableCell>
                        <TableCell align="right">Total price ($)</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCell component="th" scope="row">
                          cc
                        </TableCell>
                        <TableCell>cc</TableCell>
                        <TableCell align="right">cc</TableCell>
                        <TableCell align="right">cc</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell component="th" scope="row">
                          cc
                        </TableCell>
                        <TableCell>cc</TableCell>
                        <TableCell align="right">cc</TableCell>
                        <TableCell align="right">cc</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell component="th" scope="row">
                          cc
                        </TableCell>
                        <TableCell>cc</TableCell>
                        <TableCell align="right">cc</TableCell>
                        <TableCell align="right">cc</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell component="th" scope="row">
                          cc
                        </TableCell>
                        <TableCell>cc</TableCell>
                        <TableCell align="right">cc</TableCell>
                        <TableCell align="right">cc</TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </Grid> */}
                {row.payroll &&
                  row.payroll.map((payroll, i) => (
                    <Grid key={i} item xs={6} md={4}>
                      <Table
                        className={classes.table}
                        aria-labelledby="tableTitle2"
                        size={dense ? "small" : "small"}
                        aria-label="enhanced table2">
                        <TableHead>
                          <TableRow>
                            <StyledTableCell colSpan={2}>
                              <Typography variant="h6">Payroll Details</Typography>
                            </StyledTableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          <TableRow>
                            <TableCell component="th" variant="head">
                              Worker
                            </TableCell>
                            <TableCell align="right">
                              {payroll.position.worker.firstName} {payroll.position.worker.lastName}
                            </TableCell>
                          </TableRow>

                          <TableRow>
                            <TableCell component="th" variant="head">
                              Hours Worked
                            </TableCell>
                            <TableCell align="right"> {formatNumber(payroll.totalUnits)} hrs</TableCell>
                          </TableRow>

                          <TableRow>
                            <TableCell component="th" variant="head">
                              Break Value
                            </TableCell>
                            <TableCell align="right"> {payroll.breakDeductionMins ? formatNumber(payroll.breakDeductionMins) + ' mins' : 'No Break'}</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell component="th" variant="head">
                              Billable Hours
                            </TableCell>
                            <TableCell align="right">
                              {formatNumber(payroll.payableUnits)} hrs
                            </TableCell>
                          </TableRow>

                          <TableRow>
                            <TableCell component="th" variant="head">
                              Hourly PayRate
                            </TableCell>
                            <TableCell align="right">
                              {formatMoney(payroll.rate)}
                            </TableCell>
                          </TableRow>

                          <TableRow>
                            <TableCell component="th" variant="head">
                              Gross Pay
                            </TableCell>
                            <TableCell align="right"> {formatMoney(payroll.grossEarnings)}</TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </Grid>
                  ))}
              </Grid>
            </div>
          </Collapse>
        </TableCell>
      </TableRow>
    );
    const renderRow = () => (
      <TableRow
        hover
        onClick={() => {
            if (row.payroll && row.payroll.length > 0) {
                setOpen(!open);
            }
        }}
        role="checkbox"
        aria-checked={isItemSelected}
        tabIndex={-1}>
        <TableCell size="small">
            {row.payroll && row.payroll.length > 0 && (
                <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
                    {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                </IconButton>
            )}
        </TableCell>

        <TableCell align="left" size="small" padding={"none"}>
          {row.payroll && row.payroll.length > 1 ? <Warning style={{ color: yellow[800] }} /> : ""}
          {row.positionId}
        </TableCell>
        <TableCell align="left" size="small" padding={"none"}>
          {row.worker}
        </TableCell>
        <TableCell align="left" style={{ width: "25% !important" }}>
          {/*<div> {moment.utc(row.checkIn).format("MMM Do h:mm a")}</div>*/}
            {convertUTCToTimezoneWithOffset(row.checkInUtc, '', "MMM Do h:mm a")}
        </TableCell>
        <TableCell align="left" style={{ width: "25% !important" }}>
          {convertUTCToTimezoneWithOffset(row.checkOutUtc, '', "MMM Do h:mm a")}
        </TableCell>
        <TableCell align="right" size="small">
          {row.breakDeductionMins ? formatNumber(row.breakDeductionMins) + ' mins' : 'No Break'}
        </TableCell>
        <TableCell align="right" size="small">
          {formatNumber(duration)} hrs
        </TableCell>
        <TableCell align="right" size="small">
          {formatMoney(row.rate)}
        </TableCell>
        <TableCell align="right" size="small">
          {formatMoney(total)}
        </TableCell>
        <TableCell align="right" size="small">
          {row.isInvoicable ? <CheckCircle color="secondary" /> : <RemoveCircle color="error" />}
        </TableCell>
      </TableRow>
    );
    return (
      <React.Fragment>
        {renderRow(props)}
        {row.payroll && renderCollapse(props)}
      </React.Fragment>
    );
  };

  const RenderTable = () => {
    const descendingComparator = (a, b, orderBy) => {
      if (b[orderBy] < a[orderBy]) {
        return -1;
      }
      if (b[orderBy] > a[orderBy]) {
        return 1;
      }
      return 0;
    };

    const getComparator = (order, orderBy) => {
      return order === "desc"
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
    };

    const stableSort = (array, comparator) => {
      const stabilizedThis = array.map((el, index) => [el, index]);
      stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
      });
      return stabilizedThis.map(el => el[0]);
    };

    const EnhancedTableHead = props => {
      const { order, orderBy, onRequestSort } = props;
      const createSortHandler = property => event => {
        onRequestSort(event, property);
      };
      const headCells = [
        {
          id: "positionId",
          numeric: false,
          disablePadding: true,
          label: "Position",
          size: "small",
        },
        { id: "worker", numeric: false, disablePadding: false, label: "Worker" },
        { id: "checkIn", numeric: false, disablePadding: false, label: "CheckIn" },
        { id: "checkOut", numeric: false, disablePadding: false, label: "CheckOut" },
        { id: "break", numeric: true, disablePadding: false, label: "Break", size: "small" },
        { id: "duration", numeric: true, disablePadding: false, label: "Duration", size: "small" },
        { id: "rate", numeric: true, disablePadding: false, label: "Rate", size: "small" },
        { id: "total", numeric: true, disablePadding: false, label: "Total", size: "small" },
        { id: "isInvoicable", numeric: true, disablePadding: false, label: "Ready", size: "small" },
      ];
      return (
        <TableHead>
          <TableRow>
            <TableCell size="small" />
            {headCells.map(headCell => (
              <TableCell
                size={headCell.size ? headCell.size : "medium"}
                key={headCell.id}
                align={headCell.numeric ? "right" : "left"}
                padding={headCell.disablePadding ? "none" : "default"}
                sortDirection={orderBy === headCell.id ? order : false}>
                <TableSortLabel
                  active={orderBy === headCell.id}
                  direction={orderBy === headCell.id ? order : "asc"}
                  onClick={createSortHandler(headCell.id)}>
                  {headCell.label}
                </TableSortLabel>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
      );
    };

    const handleRequestSort = (event, property) => {
      const isAsc = orderBy === property && order === "asc";
      setOrder(isAsc ? "desc" : "asc");
      setOrderBy(property);
    };

    const handleSelectAllClick = event => {
      if (event.target.checked) {
        const newSelecteds = invoiceItems.map(n => n.positionId);
        setSelected(newSelecteds);
        return;
      }
      setSelected([]);
    };

    const handleChangePage = (event, newPage) => {
      setPage(newPage);
    };

    const handleChangeRowsPerPage = event => {
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(0);
    };

    const isSelected = positionId => selected.indexOf(positionId) !== -1;

    return (
      <React.Fragment>
        <Paper className={classes.paper} style={{ marginLeft: -30, marginRight: -30 }}>
          {/* <EnhancedTableToolbar numSelected={selected.length} /> */}

          <TableContainer>
            <Table
              className={classes.table}
              aria-labelledby="tableTitle"
              size={dense ? "small" : "medium"}
              aria-label="enhanced table">
              <EnhancedTableHead
                classes={classes}
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={invoiceItems.length}
              />
              <TableBody>
                {stableSort(invoiceItems, getComparator(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, i) => {
                    const isItemSelected = isSelected(row.positionId);
                    return (
                      <Row
                        key={i}
                        payroll={row.payroll || []}
                        row={row}
                        isItemSelected={isSelected(row.id)}
                        labelId={`enhanced-table-checkbox-${i}`}
                        selected={isItemSelected}
                      />
                    );
                  })}

                {emptyRows > 0 && (
                  <TableRow style={{ height: (dense ? 33 : 53) * emptyRows }}>
                    <TableCell colSpan={7} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
            <TablePagination
              rowsPerPageOptions={[15]}
              component="div"
              count={invoiceItems.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </TableContainer>
        </Paper>
      </React.Fragment>
    );
  };

  return <RenderTable />;
}

export default invoiceModalTableStyles(InvoiceModalTable);
