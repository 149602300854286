/* eslint-disable react/jsx-key */
/* eslint-disable react/display-name */
/* eslint-disable react/prop-types */
import React from "react";
import moment from "moment-timezone";
import Avatar from "@mui/material/Avatar";
import Chip from "@mui/material/Chip";
import EventBusy from "@mui/icons-material/EventBusy";
import Tooltip from "@mui/material/Tooltip";
import WorkOff from "@mui/icons-material/WorkOff";
import { formatInitials } from "utils/stringUtils";
import { convertUTCToLocal, getTimeZone } from "utils/time";
import TagsInput from "components/TagsInput";
import { isEqual } from "lodash";
import { Info } from "@mui/icons-material";
import { MenuItem, Select } from "@mui/material";
import NotVerifiedIcon from "assets/img/not_verified.svg";
import VerifiedIcon from "assets/img/verified.svg";


moment.tz.setDefault(getTimeZone());

export const formatPhone = input => {
    if (input) {
        const phone = input.replace(/\D/g, "").match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
        return !phone[2] ? phone[1] : `(${phone[1]}) ${phone[2]}${phone[3] ? `-${phone[3]}` : ""}`;
    }
    return;
};

export const numberWithCommas = num => {
    return num ? num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 0;
};

export const sharedProps = {
    showPageSizeOptions: true,
    manual: true,
    showPaginationBottom: true,
    disableFilters: true,
    showPageJump: false,
    showPaginationTop: false,
    disableSortBy: true,
    searchable: false,
    defaultPageSize: 10,
    resizable: false,
    classes: "-highlight",
    minRows: 0,
    pageSizeOptions: [10, 20, 40, 100],
};

export const sharedWorkerColumns = (tabIndex, onFutureJobClick) => {
    return [
        {
            Header: "",
            id: "profileImage",
            disableSortBy: true,
            disableFilters: true,

            width: 80,
            Cell: ({ row }) => (
                <Avatar src={row.original.signedProfileImageUrl} style={row.original.vaccinationStatus === 'Approved' ? { border: '3px solid #3e938b' } : {}}>
                    {formatInitials(row.original.firstName, row.original.lastName, "")}
                </Avatar>
            ),
        },
        {
            Header: "First Name",
            id: "firstName",
            accessor: ({ firstName }) => firstName || <i> Empty </i>,
        },
        {
            Header: "Last Name",
            id: "lastName",
            accessor: ({ lastName }) => lastName || <i> Empty </i>,
            disableSortBy: true,
        },
        {
            Header: "Email",
            id: "email",
            minWidth: 200,
            accessor: ({ email }) => email || <i> Empty </i>,
            disableSortBy: true,
        },
        {
            Header: "Phone",
            id: "phone",
            accessor: ({ phone }) => formatPhone(phone) || <i> Empty </i>,
            disableSortBy: true,
        },
        {
            Header: "Rating",
            id: "rating",
            width: 120,
            disableSortBy: true,
            accessor: ({ rating }) => {
                if (!rating) {
                    return <i>Not rated.</i>;
                }

                return rating;
            },
            Filter: ({ setFilter, column }) => (
                <div
                    style={{
                        textAlign: "center",
                    }}>
                    <Select
                        disableUnderline={false}
                        variant={'standard'}
                        onChange={event => setFilter(column.id, event.target.value.trim())}
                        value={column.filterValue ? column.filterValue || " " : " "}>
                        <MenuItem value=" ">All</MenuItem>
                        <MenuItem value="5">5</MenuItem>
                        <MenuItem value="4">4 +</MenuItem>
                        <MenuItem value="3">3 +</MenuItem>
                        <MenuItem value="2">2 +</MenuItem>
                    </Select>
                </div>
            ),
            style: {
                textAlign: "center",
            },
            headerStyle: {
                textAlign: "center",
            },
        },
        {
            Header: "Sin",
            id: "sin",
            width: 120,
            accessor: ({ sin }) => (sin ? sin : <i> -- </i>),
            disableSortBy: true,
        },
        {
            Header: "City",
            id: "city",
            accessor: ({ city }) => city || <i> -- </i>,
            disableSortBy: true,
        },
        {
            Header: "Province",
            id: "region",
            accessor: ({ region }) => region || <i> -- </i>,
            disableSortBy: true,
        },
        // {
        //   Header: "Location",
        //   id: "location",
        //   accessor: ({ city, region }) => city + ", " + region || <i> Empty </i>,
        //   disableSortBy:true,
        // },
        {
            Header: "Jobs Worked",
            id: "positionCount",
            accessor: ({ positionCount }) => positionCount,
            width: 100,
            disableFilters: true,
            style: {
                textAlign: 'center'
            }
        },
        {
            Header: "Future Jobs",
            id: "futureJobCount",
            accessor: ({ futureJobCount }) => futureJobCount,
            width: 100,
            disableFilters: true,
            Cell: ({ row: { original } }) => <div style={{ display: 'flex', justifyContent: "center" }}>
                <div>{original.futureJobCount}</div>
                {original.futureJobCount > 0 && <Info
                    style={{
                        color: 'gray',
                        top: '-2px',
                        position: 'relative',
                        left: '5px',
                        cursor: 'pointer'
                    }}
                    onClick={() => onFutureJobClick(original)}
                />}
            </div>
        },
        {
            Header: "Created",
            id: "createdAtUtc",
            disableFilters: true,
            accessor: ({ createdAtUtc }) => {
                return createdAtUtc;
            },
            Cell:({row:{original}})=>convertUTCToLocal(original.createdAtUtc).fromNow()
        },
        // {
        //     Header: "Vaccination Status",
        //     id: "vaccinationStatus",
        //     width: 120,
        //     disableSortBy: true,
        //     accessor: ({ vaccinationStatus }) => {
        //         if (vaccinationStatus === 'NotVerified') {
        //             return 'Not Verified'
        //         }
        //         if (vaccinationStatus === 'PendingApproval') {
        //             return 'Pending Approval'
        //         }
        //         return vaccinationStatus
        //     },
        //     Filter: ({ setFilter, column }) => (
        //         <div
        //             style={{
        //                 textAlign: "center",
        //             }}>
        //             <Select
        //                 disableUnderline={false}
        //                 variant="standard"
        //                 fullWidth
        //                 onChange={event => setFilter(column.id, event.target.value.trim())}
        //                 value={column.filterValue ? column.filterValue || " " : " "}>
        //                 <MenuItem value=" ">All</MenuItem>
        //                 <MenuItem value="NotVerified">Not Verified</MenuItem>
        //                 <MenuItem value="PendingApproval">Pending Approval</MenuItem>
        //                 <MenuItem value="Approved">Approved</MenuItem>
        //                 <MenuItem value="Decline">Decline</MenuItem>
        //             </Select>
        //         </div>
        //     ),
        //     style: {
        //         textAlign: "center",
        //     },
        //     headerStyle: {
        //         textAlign: "center",
        //     },
        // },
        {
            Header: tabIndex === 0 ? "Status" : null,
            id: "workerStatus",
            disableSortBy: true,
            width: 60,
            accessor: ({ workerStatus }) => {
                if (!workerStatus) {
                    return null;
                }
                return <div style={{ textAlign: "center", paddingLeft: 0 }}>{workerStatus}</div>;
            },
            Filter: ({ column, setFilter }) => {
                if (tabIndex === 0) {
                    return (
                        <div
                            style={{
                                textAlign: "center",
                            }}>
                            {(!column.filterValue || column.filterValue === "Verified") && (
                                <img
                                    alt="Verified Badge"
                                    src={VerifiedIcon}
                                    onClick={() => setFilter(column.id, "Unverified")}
                                    title="Verified"
                                    style={{
                                        width: 21,
                                        height: 24,
                                        cursor: "pointer",
                                    }}
                                />
                            )}
                            {column.filterValue && column.filterValue === "Unverified" && (
                                <img
                                    alt="Not Verified Badge"
                                    src={NotVerifiedIcon}
                                    onClick={() => setFilter(column.id, "Suspended")}
                                    title="Unverified"
                                    style={{
                                        width: 21,
                                        height: 24,
                                        cursor: "pointer",
                                    }}
                                />
                            )}
                            {column.filterValue && column.filterValue === "Deactivated" && (
                                <span title="Deactivated">
                                    <WorkOff
                                        color="disabled"
                                        onClick={() => setFilter(column.id, "Verified")}
                                        style={{ cursor: "pointer" }}
                                    />
                                </span>
                            )}
                            {column.filterValue && column.filterValue === "Suspended" && (
                                <span title="Suspended">
                                    <EventBusy
                                        color="disabled"
                                        onClick={() => setFilter(column.id, "Deactivated")}
                                        style={{ cursor: "pointer" }}
                                    />
                                </span>
                            )}
                        </div>
                    );
                }
                return "";
            },
            style: {
                textAlign: "center",
            },
            headerStyle: {
                textAlign: "center",
            },
        },
        {
            Header: "Tag",
            id: "tags",
            width: 120,
            disableSortBy: true,
            accessor: ({ tags }) => {
                return tags
            },
            Filter: ({ column, setFilter }) => {
                return (
                    <div>
                        <TagsInput
                            selectedTags={(data) => {
                                if (data) {
                                    if (column) {
                                        if (!isEqual(column.filterValue, data)) {
                                            setFilter(column.id, data)
                                        }
                                    } else {
                                        if (data.length == 0 && column) {
                                            setFilter(column.id, data)
                                        } else if (data.length > 0) {
                                            setFilter(column.id, data)
                                        }
                                    }
                                }
                            }}
                            fullWidth
                            defaultValue={column.filterValue ? column.filterValue : []}
                            isFilterField
                            variant="standard"
                            id="tags"
                            name="tags"
                        />
                    </div>
                )
            },
            Cell: ({ row }) => {
                return (
                    <div>
                        {row.original.tags && row.original.tags.map((n, index) => {
                            if (index < 2) {
                                return <Chip
                                    size="small"
                                    style={{ margin: '1px' }}
                                    label={n}
                                />
                            }
                        })}
                        {row.original.tags && row.original.tags.length > 2 && (
                            <Tooltip title={row.original.tags.map((n, index) => `${index > 1 ? n + (index + 1 != row.original.tags.length ? ', ' : '') : ''}`)} placement="top">
                                <Chip
                                    size="small"
                                    style={{ margin: '1px' }}
                                    label={'More'}
                                />
                            </Tooltip>
                        )}
                    </div>
                )
            },
            style: {
                textAlign: "center",
            },
            headerStyle: {
                textAlign: "center",
            },
        },
        {
            Header: "",
            id: "actions",
            accessor: ({ actions }) => actions,
            collapse: true,
            style: { textAlign: "right" },
            disableFilters:true,
            disableSortBy:true,
        },
    ];
};

export const sharedColumns = () => {
    return [
        {
            Header: "",
            id: "profileImage",
            disableFilters: true,
            width: 120,
            Cell: ({ row }) => (
                <Avatar src={row.original.worker.signedProfileImageUrl}>
                    {formatInitials(row.original.worker.firstName, row.original.worker.lastName, "")}
                </Avatar>
            ),
        },
        {
            Header: "First Name",
            id: "firstName",
            accessor: ({ worker }) => worker.firstName || <i> Empty </i>,
        },
        {
            Header: "Last Name",
            id: "lastName",
            accessor: ({ worker }) => worker.lastName || <i> Empty </i>,
        },
        {
            Header: "Email",
            id: "email",
            accessor: ({ worker }) => worker.email || <i> Empty </i>,
        },
        {
            Header: "Phone",
            id: "phone",
            accessor: ({ worker }) => formatPhone(worker.phone) || <i> Empty </i>,
        },
        {
            Header: "Rating",
            id: "rating",
            accessor: ({ worker }) => worker.rating || <i> Empty </i>,
        },

        {
            Header: "Sin",
            id: "sin",
            accessor: ({ worker }) =>
                worker.sin ? "*** *** " + worker.sin.substr(worker.sin.length - 3) : <i> Empty </i>,
        },
        {
            Header: "Location",
            id: "city",
            accessor: ({ worker }) => (worker.city ? worker.city + ", " + worker.region : <i> Empty </i>),
        },

        {
            Header: "",
            id: "actions",
            accessor: ({ actions }) => actions,
            width: 50,
        },
    ];
};
