import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import FormRow from "./FormRow";
import { actions as PostingActions, selectors as PostingSelectors } from "store/modules/posting";
import { fetchManagers } from "store/modules/posting/actions";
import ErrorIcon from "@mui/icons-material/Error";
import HelperMessage from "./HelperMessage";
import { find } from "lodash";
import InfoIcon from "@mui/icons-material/InfoOutlined";
import PropTypes from "prop-types";
import { Grid, TextField, FormControl, MenuItem, InputLabel, Select } from "@mui/material";

function ManagerSection(props) {

    const {
        employer,
        positions,
        workersNeeded,
    } = props;

    const dispatch = useDispatch();
    const managerList = useSelector(PostingSelectors.getManagers);
    const siteManager = useSelector(PostingSelectors.getSiteManager);
    const checkInManager = useSelector(PostingSelectors.getCheckInManager);
    const checkOutManager = useSelector(PostingSelectors.getCheckOutManager);
    const managers = Array.from(managerList, (manager) => manager.manager);

    useEffect(() => {
        if (employer.id) {
            dispatch(fetchManagers(employer.id))
        }
    }, []);

    const validateWorkersNeeded = () => {
        if (workersNeeded < 1) {
            onWorkersNeededChange(1);
        }
    };

    const workersNeededHelperProps = {
        icon: <InfoIcon color="disabled" />,
        message: `${positions.length} worker(s) will be requested back`,
    };

    if (positions.length > workersNeeded) {
        workersNeededHelperProps.icon = <ErrorIcon color="error" />;
        workersNeededHelperProps.message = `Minimum ${positions.length} worker(s) required`;
        workersNeededHelperProps.caption = `${positions.length} worker(s) will be requested back`;
    }

    const onWorkersNeededChange = (event) => {
        dispatch(PostingActions.setWorkersNeeded(event.target.value));
    }

    const onSiteManagerChange = (e) => {
        const selectedSiteManager = find(managers, n => n.id == e.target.value);
        dispatch(PostingActions.setSiteManager(selectedSiteManager));
    }

    const oncheckInManagerChange = (e) => {
        const selectedCheckInManager = find(managers, n => n.id == e.target.value);
        dispatch(PostingActions.setCheckInManager(selectedCheckInManager));
    }

    const oncheckOutManagerChange = (e) => {
        const selectedCheckOutManager = find(managers, n => n.id == e.target.value);
        dispatch(PostingActions.setCheckOutManager(selectedCheckOutManager));
    }

    return (
        <React.Fragment>
            <FormRow helper={positions.length > 0 && <HelperMessage {...workersNeededHelperProps} />} style={{ marginTop: 12 }}>
                <FormControl fullWidth>
                    <TextField
                        id="positions"
                        label="Workers Needed"
                        value={workersNeeded}
                        onChange={onWorkersNeededChange}
                        onBlur={validateWorkersNeeded}
                        type="number"
                        margin="none"
                        inputProps={{
                            min: 1,
                        }}
                    />
                </FormControl>
            </FormRow>

            <FormRow style={{ marginTop: 32 }}>
                <Grid container item xs={12}>
                    <Grid container item xs={4} style={{ paddingRight: 4 }}>
                        <FormControl fullWidth>
                            <InputLabel id="site-manager-select-label">Site manager</InputLabel>
                            <Select
                                displayEmpty
                                labelId="site-manager-select-label"
                                id="site-manager-select-label"
                                value={(siteManager ? siteManager.id || " " : " ")}
                                onChange={onSiteManagerChange}
                            >
                                <MenuItem value=" ">Select site manager</MenuItem>
                                {managers &&
                                    managers.map(n => (
                                        <MenuItem key={n.id} value={n.id}>
                                            {n.firstName} {n.lastName}
                                        </MenuItem>
                                    ))}
                            </Select>
                        </FormControl>
                    </Grid>

                    <Grid container item xs={4} direction="column" style={{ paddingRight: 4 }}>
                        <FormControl fullWidth>
                            <InputLabel id="checkin-manager-select-label">Check-in manager</InputLabel>
                            <Select
                                displayEmpty
                                labelId="checkin-manager-select-label"
                                id="checkin-manager-select-label"
                                value={checkInManager ? checkInManager.id || " " : " "}
                                onChange={oncheckInManagerChange}
                            >
                                <MenuItem value=" ">Select check-in manager</MenuItem>
                                {managers &&
                                    managers.map(n => (
                                        <MenuItem key={n.id} value={n.id}>
                                            {n.firstName} {n.lastName}
                                        </MenuItem>
                                    ))}
                            </Select>
                        </FormControl>
                    </Grid>

                    <Grid container item xs={4} direction="column" style={{ paddingRight: 4 }}>
                        <FormControl fullWidth>
                            <InputLabel id="checkout-manager-select-label">Check-out manager</InputLabel>
                            <Select
                                displayEmpty
                                labelId="checkout-manager-select-label"
                                id="checkout-manager-select-label"
                                value={checkOutManager ? checkOutManager.id || " " : " "}
                                onChange={oncheckOutManagerChange}
                            >
                                <MenuItem value=" ">Select check-out manager</MenuItem>
                                {managers &&
                                    managers.map(n => (
                                        <MenuItem key={n.id} value={n.id}>
                                            {n.firstName} {n.lastName}
                                        </MenuItem>
                                    ))}
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>
            </FormRow>
        </React.Fragment >
    )
}

ManagerSection.propTypes = {
    positions: PropTypes.array,
    workersNeeded: PropTypes.number.isRequired,
}

export default ManagerSection
