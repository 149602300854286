import React, { useState, useEffect } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import Stage from "./components/Stage";
import { Grid } from "@mui/material";
import { withRouter } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import SummaryFooter from "./components/SummaryFooter";
import AddJobTemplateView from "./components/AddJobTemplateView";
import MainContent from "components/Containers/MainContent";
import { fetchJobTypeList } from "store/modules/jobTemplate/actions";
import { actions as MetaActions } from "store/modules/meta";
import { actions as JobTemplateActions, selectors as JobTemplateSelectors } from "store/modules/jobTemplate";
import { fetchGears } from "store/modules/requiredGear/actions";
import { actions as requiredGearActions, selectors as requiredGearSelectors } from "store/modules/requiredGear";

function PostJobTemplateView({ history }) {

  const dispatch = useDispatch();

  const JobTemplate = useSelector(JobTemplateSelectors.getJobTemplate)
  const { street, region, city, country, latitude, longitude, gears } = JobTemplate
  const setAddress = { street, region, city, country, latitude, longitude }
  const JobTemplateId = JobTemplate.id
  const [selectedLocation, setSelectedLocation] = useState({});
  const [location, setLocation] = useState();
  const [countedGears, setCountedGears] = useState(0);
  const [countedSkills, setCountedSkills] = useState(0);
  const [changedAddress, setChangedAddress] = useState(false);
  const [changedGears, setChangedGears] = useState(false);
  const [changedSkills, setChangedSkills] = useState(false);
  const [changedWorkType, setChangedWorkType] = useState(false);
  const [changedBreakMin, setChangedBreakMin] = useState(false);
  const [changedBreakType, setChangedBreakType] = useState(false);

  useEffect(() => {
    dispatch(fetchJobTypeList());
    dispatch(fetchGears());
  }, [])

  useEffect(() => {
    const address = (JobTemplate.street + ' ' + JobTemplate.city + ' ' + JobTemplate.region + ' ' + JobTemplate.country);
    setSelectedLocation({ value: undefined, label: address });
  }, [])

  function onKeyDown(keyEvent) {
    if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
      keyEvent.preventDefault();
    }
  }

  const validationSchema = Yup.object().shape({
    templateName: Yup.string().required("Please Enter Template Name").max(50),
    peopleNeeded: Yup.number().required("Required").positive("Needed people must be a positive number").integer(),
    unit: Yup.number().typeError("Unit value must be a number").positive("Please enter valid unit").integer(),
    floor: Yup.number().typeError("Floor value must be a number").positive("Please enter valid floor").integer(),
    description: Yup.string().required("Please Enter Description").max(250),
    locationInstruction: Yup.string().required("Please Enter Location Instruction").max(100)
  })

  const CreateJobTemplate = (values) => {
    dispatch(JobTemplateActions.postJobTemplate(values))
      .then(() => {
        history.push("/job-template/overview");
      })
  }

  const editJobTemplate = (values) => {
    dispatch(JobTemplateActions.editJobTemplate(JobTemplateId, values))
      .then(() => {
        history.push("/job-template/overview");
      })
  }

  const formik = useFormik({
    initialValues: {
      templateName: JobTemplate.templateName ? JobTemplate.templateName : "",
      peopleNeeded: JobTemplate.peopleNeeded ? JobTemplate.peopleNeeded : 1,
      address: JobTemplate ? setAddress : location,
      unit: JobTemplate.unit ? JobTemplate.unit : "",
      floor: JobTemplate.floor ? JobTemplate.floor : "",
      description: JobTemplate.description ? JobTemplate.description : "",
      isBreakPaid: JobTemplate.isBreakPaid ? JobTemplate.isBreakPaid : false,
      locationInstruction: JobTemplate.locationInstruction ? JobTemplate.locationInstruction : "",
      breakMins: JobTemplate.breakMins ? JobTemplate.breakMins : 30,
      gears: JobTemplate.gears ? JobTemplate.gears : [],
      workType: {
        value: JobTemplate.workTypeId ? JobTemplate.workTypeId : 2
      },
      skills: JobTemplate.skills ? JobTemplate.skills : []
    },

    validationSchema: validationSchema,

    onSubmit: (values, actions) => {
      if (JobTemplateId === undefined) {
        CreateJobTemplate(values)
        actions.setSubmitting(false);
      }
      else {
        editJobTemplate(values)
        actions.setSubmitting(false);
      }
    }
  });

  const jobTypeList = useSelector(JobTemplateSelectors.getJobTypeList);
  const gearList = useSelector(requiredGearSelectors.getrequiredGearsList);
  const [jobType, setJobType] = useState(formik.values.workType.value);
  const [breakMins, setBreakMins] = useState(formik.values.breakMins);
  const [breakType, setBreakType] = useState(formik.values.isBreakPaid);
  const [skillArray, setSkillArray] = useState(JobTemplate.skills ? JobTemplate.skills : []);
  const [skill, setSkill] = useState("");
  const seleted = [...skillArray];
  const address = formik.values.address;
  const isValidGears = (countedGears <= 0);
  const isValidSkills = (countedSkills <= 0);

  useEffect(() => {
    setCountedSkills(formik.values.skills.length);
  }, [])

  const onAddTagsChange = (data) => {
    formik.values.gears = data;
    setCountedGears(formik.values.gears.length);
  }

  const onJobTypeChange = (event) => {
    setJobType(event.target.value);
    formik.values.workType.value = event.target.value;
    setChangedWorkType(true);
  };

  const onAddressChange = (selectedLocation, location) => {
    setSelectedLocation(selectedLocation);
    formik.values.address = location;
    setLocation(formik.values.address);
    setChangedAddress(true);
  }

  const onbreakMinsChange = (event) => {
    if (event.target.value <= 60) {
      const breakMinutes = parseInt(event.target.value)
      setBreakMins(breakMinutes);
      formik.values.breakMins = breakMinutes;
      setChangedBreakMin(true);
    }
  };

  const onBreakTypeChange = (event) => {
    setBreakType(event.target.value);
    formik.values.isBreakPaid = event.target.value;
    setChangedBreakType(true);
  }

  const handleChangeSkill = e => {
    if (e.target.value.length <= 50) {
      setSkill(e.target.value);
    }
  };

  const addSkill = () => {
    if (seleted.indexOf(skill) < 0) {
      if (skill.replace(/^\s+|\s+$/gm, '')) {
        seleted.push(skill);
        setSkill("");
        setSkillArray(seleted);
        formik.values.skills = seleted;
        setCountedSkills(formik.values.skills.length);
        setChangedSkills(true);
      }
    }
    else {
      dispatch(MetaActions.errorToast(`Skill already added`));
    }
  };

  const handleDeleteSkill = value => {
    seleted.splice(skillArray.indexOf(value), 1);
    setSkillArray(seleted);
    formik.values.skills = seleted;
    setCountedSkills(formik.values.skills.length);
    setChangedSkills(true);
  };

  const canSubmitForm = () => {

    if (JobTemplateId) {
      if (gears.length < countedGears) {
        setChangedGears(true)
      }
      else if (gears.length > countedGears) {
        setChangedGears(true)
      }
      else {
        setChangedGears(false)
      }
    }

    if (!(formik.isValid && formik.dirty) || formik.isSubmitting || (!address) || isValidGears || isValidSkills) {
      if (JobTemplateId) {
        if ((changedAddress && address) || changedWorkType || changedBreakMin || changedBreakType || changedSkills || changedGears) {
          if (isValidGears || isValidSkills || (!address) || !(formik.isValid)) {
            return true;
          }
          else {
            return false
          }
        }
        else {
          return true
        }
      }
      else {
        return true
      }
    }
    else {
      return false
    }
  }

  return (
    <MainContent>
      <Grid container>
        <Stage
          title="Job Template"
          description="Input all of the information for job template."
          contentSize={8}>
          <Grid container item xs={12}>
            <Grid container item xs={8} direction="column" >
              <form onSubmit={formik.handleSubmit} onKeyDown={onKeyDown}>
                <AddJobTemplateView
                  formik={formik}
                  jobType={jobType}
                  breakMins={breakMins}
                  breakType={breakType}
                  jobTypeList={jobTypeList}
                  gearList={gearList}
                  selectedLocation={selectedLocation}
                  JobTemplateId={JobTemplateId}
                  JobTemplate={JobTemplate}
                  skill={skill}
                  skillArray={skillArray}
                  gears={gears}
                  onAddTagsChange={onAddTagsChange}
                  onJobTypeChange={onJobTypeChange}
                  onAddressChange={onAddressChange}
                  onbreakMinsChange={onbreakMinsChange}
                  onBreakTypeChange={onBreakTypeChange}
                  handleChangeSkill={handleChangeSkill}
                  addSkill={addSkill}
                  handleDeleteSkill={handleDeleteSkill}
                />
                <SummaryFooter
                  canSubmitForm={canSubmitForm}
                />
              </form>
            </Grid>
          </Grid>
        </Stage>
      </Grid>
    </MainContent>
  )
}

export default (withRouter(PostJobTemplateView))
