import * as pagination from "utils/graphql-pagination";
import { createReducer } from "utils/redux";
import { paginationSettings } from "./constants";
import types from "./types";
const initialLoadingState = {
  admins: false,
  adminsSave: false,
  isOpenAddDialog: false,
  isOpenResetPasswordDialog: false,
  adminPermissionUpdate: false,
  adminPermission: false,
  unAssignedPermissions: false,
  isOpenAssignPermissionDialog: false
};
const initialState = {
  adminList: [],
  adminDetail: {},
  adminPermissions: [],
  allPermissions: [],
  unAssignedAdminPermissions: [],
  loading: { ...initialLoadingState },
  ...pagination.initialState(paginationSettings),
};

const actionsMap = {
  [types.SET_LOADING_STATE]: (state, { payload }) => {
    const { key, value } = payload;
    return {
      ...state,
      loading: {
        ...state.loading,
        [key]: value,
      },
    };
  },
  [types.SET_ADMIN_DATA]: (state, { payload }) => {
    const { data } = payload;
    return {
      ...state,
      adminList: [...data],
    };
  },
  [types.SET_ADMIN_DETAIL]: (state, { payload }) => {
    const { data } = payload;
    return {
      ...state,
      adminDetail: {...data},
    };
  },
  [types.SET_ADMIN_PERMISSION_DATA]: (state, { payload }) => {
    const { data } = payload;
    return {
      ...state,
      adminPermissions: [...data],
    };
  },
  [types.SET_ALL_PERMISSION_DATA]: (state, { payload }) => {
    const { data } = payload;
    return {
      ...state,
      allPermissions: [...data],
    };
  },
  [types.SET_UNASSIGNED_ADMIN_PERMISSION_DATA]: (state, { payload }) => {
    const { data } = payload;
    return {
      ...state,
      unAssignedAdminPermissions: [...data],
    };
  },
  ...pagination.createPaginationActions(paginationSettings),
};

export default createReducer(initialState, actionsMap);
