import { createReducer } from "utils/redux";
import types from "./types";

const initialFaqItemState = {
  question: "",
  answer: "",
};
const initialLoadingState = {
  page: false,
  adminSettingSave: false
};

const initialState = {
  adminSettings: [],
  workerFaqs: [],
  employerFaqs: [],
  isInfoLoading: false,
  isTermsOfServiceLoading: false,
  loading: { ...initialLoadingState },
  editFaqData: { ...initialFaqItemState },
  newFaqData: { ...initialFaqItemState },
  termsOfService: ''
};

const actionsMap = {
  [types.SET_LOADING_STATE]: (state, { payload }) => {
    const { key, value } = payload;
    return {
      ...state,
      loading: {
        ...state.loading,
        [key]: value,
      },
    };
  },
  [types.IS_LOADING_INFO]: (state, { payload }) => {
    const { isInfoLoading } = payload;
    return {
      ...state,
      isInfoLoading,
    };
  },
  [types.IS_LOADING_TERMS_OF_SERVICE]: (state, { payload }) => {
    const { isTermsOfServiceLoading } = payload;
    return {
      ...state,
      isTermsOfServiceLoading,
    };
  },
  [types.SET_FAQS]: (state, { payload }) => {
    const { faqs } = payload;
    return {
      ...state,
      employerFaqs: faqs.filter(function(faq) {
        return faq.type === 1;
      }),
      workerFaqs: faqs.filter(function(faq) {
        return faq.type === 0;
      }),
    };
  },
  [types.SET_FAQ_LIST]: (state, { payload }) => {
    const { type, list } = payload;
    return {
      ...state,
      [type]: list,
    };
  },
  [types.SET_FAQ_EDIT_DATA]: (state, { payload }) => {
    const { faqId, type } = payload;

    const faq = state[type].find(({ id }) => id === faqId);
    return {
      ...state,
      editFaqData: { ...faq },
    };
  },

  [types.UPDATE_FAQ_EDIT_DATA]: (state, { payload }) => {
    const { field, value } = payload;
    return {
      ...state,
      editFaqData: {
        ...state.editFaqData,
        [field]: value,
      },
    };
  },
  [types.SET_FAQ_CREATE_DATA]: (state, { payload }) => {
    const { field, value } = payload;
    return {
      ...state,
      newFaqData: {
        ...state.newFaqData,
        [field]: value,
      },
    };
  },
  [types.RESET_FAQ_CREATE_DATA]: state => {
    return {
      ...state,
      newFaqData: { ...initialFaqItemState },
    };
  },
  [types.SET_TERMS_OF_SERVICE_DATA]: (state, { payload }) => {
    const { termsOfService } = payload;
    return {
      ...state,
      termsOfService,
    };
  },
  [types.SET_ADMIN_SETTING]: (state, { payload }) => {
    const { setting } = payload;
    return {
      ...state,
      adminSettings: setting,
    };
  },
  
};

export default createReducer(initialState, actionsMap);
