export const paginationSettings = {
  paymentBatches: {
    prefix: "pagination_PaymentBatches",
    defaultPageSize: 20,
  },
  transfers: {
    prefix: "pagination_Transfers",
    defaultPageSize: 10,
  },
};
