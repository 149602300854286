import React from "react";
import PropTypes from "prop-types";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";

function CurrencyTextField({ minValue, minValueHelperText, label, ...props }) {
    let error = false;
    let helperText = "";
    const value = parseFloat(props.value);
    if (minValue && value < minValue) {
        error = true;
        helperText = minValueHelperText || null;
    }

    const fieldProps = {};
    if (label) {
        fieldProps.label = label;
    }

    return (
        <TextField
            {...fieldProps}
            error={error}
            helperText={helperText}
            InputProps={{
                startAdornment: <InputAdornment position="start">$</InputAdornment>,
            }}
            inputProps={{
                ...props,
            }}
        />
    );
}

CurrencyTextField.propTypes = {
    label: PropTypes.string,
    minValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    minValueHelperText: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};

CurrencyTextField.defaultProps = {
    label: null,
    minValue: null,
    minValueHelperText: null,
};

export default CurrencyTextField;
