// HelperMessage
import React from "react";
import PropTypes from "prop-types";
import withStyles from '@mui/styles/withStyles';
import { Button } from "@mui/material";

const styles = theme => ({
  footer: {
    position: "fixed",
    bottom: theme.spacing(4),
    right: theme.spacing(4),
    zIndex: 200,
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
  },
  button: {
    marginTop: "8px",
  },
});

function SummaryFooter({ classes, canSubmitForm }) {
  return (
    <div className={classes.footer}>
      <Button
        className={classes.button}
        color="secondary"
        variant="contained"
        type="submit"
        disabled={canSubmitForm()}
      >
        Save
      </Button>
    </div>
  );
}

SummaryFooter.propTypes = {
  classes: PropTypes.object.isRequired,
};


export default withStyles(styles)(SummaryFooter);
