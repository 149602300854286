import gql from "graphql-tag";

export default gql`
  query($jobId: Int!, $isFuture: Boolean){
    recurringJobsByJobId(jobId: $jobId, isFuture: $isFuture) {
      id
      startUtc
      endUtc
      timezone
    }
  }
`