import { gql } from "@apollo/client";
import PositionFields from "../fragments/position-fragment";
import WorkerFields from "../fragments/worker-fragment";

export default gql`
  query getWorker($id: String!, $first: Int, $last: Int, $after: String, $before: String) {
    worker(id: $id) {
      ...WorkerFields
    }
    positions(workerId: $id, first: $first, last: $last, after: $after, before: $before) {
      totalCount
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        node {
          ...PositionFields
        }
      }
    }
  }
  ${PositionFields}
  ${WorkerFields}
`;
