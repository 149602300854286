import React from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import makeStyles from '@mui/styles/makeStyles';
import { selectors as JobDetailSelectors } from "store/modules/jobDetails";
import useAddedBy from "./columns/addedBy";
import useAppliedDate from "./columns/appliedDate";
import useAvatar from "./columns/avatar";
import useBreakMins from "./columns/breakMins";
import useCheckIn from "./columns/checkIn";
import useCheckOut from "./columns/checkOut";
import useConfirmed from "./columns/confirmed";
import useName from "./columns/name";
import usePayRate from "./columns/payRate";
import usePositionMenu from "./columns/positionMenu";
import useShiftDuration from "./columns/shiftDuration";
import useLocation from "./columns/location";
import useReConfirmed from "./columns/reconfirmed";
import useCheckOutBy from "./columns/checkOutBy";
import useCheckInBy from "./columns/checkInBy";
import useTags from "./columns/tags";
import ReactTableSimple from "components/ReactTable/ReactTableSimple";

const useStyles = makeStyles(({ shape, typography, spacing }) => ({
  table: {
    flex: 1,
    border: "none",
    borderRadius: shape.borderRadius / 2,
    width: "100%",
  },
  icon: {
    fontSize: typography.pxToRem(20),
    marginRight: spacing(2),
  },
}));

function InProgressTable(props) {
  const classes = useStyles();
  const isLoading = useSelector(JobDetailSelectors.getIsUpdatingPositions);
  const positions = useSelector(JobDetailSelectors.getPositions);
  const jobDetails = useSelector(JobDetailSelectors.getJobDetails);
  const hiddenColumns = []
  if (!jobDetails.isPrivate) hiddenColumns.push("tags")

  const batchActionDisable =
    props.isEditingRatesAll || props.isCheckingInAll || props.isCheckingOutAll;

  const columns = [
    useAvatar(batchActionDisable),
    useName(batchActionDisable),
    useLocation(batchActionDisable),
    useAppliedDate(batchActionDisable),
    useTags(batchActionDisable, jobDetails.isPrivate),
    useConfirmed(batchActionDisable),
    useReConfirmed(batchActionDisable),
    useCheckIn(props.isCheckingInAll, batchActionDisable, jobDetails.timezone),
    useCheckInBy(batchActionDisable),
    useCheckOut(props.isCheckingOutAll, batchActionDisable, jobDetails.timezone),
    useCheckOutBy(batchActionDisable),
    useBreakMins(props.isCheckingOutAll, batchActionDisable),
    useShiftDuration(props.isCheckingOutAll, batchActionDisable),
    usePayRate(props.isEditingRatesAll, batchActionDisable),
    // useBillingRate(props.isEditingRatesAll, batchActionDisable),
    useAddedBy(batchActionDisable),
    usePositionMenu({
      isWorkerPickerOpen: props.isWorkerPickerOpen,
      onChooseWorker: props.onChooseWorker,
      disabled: batchActionDisable,
    }),
  ];

  return (
    <ReactTableSimple
      classes={classes.table}
      minRows={0}
      data={positions}
      loading={isLoading}
      columns={columns}
      hiddenColumns={hiddenColumns}
      showFooter

    />
  );
}

InProgressTable.propTypes = {
  onChooseWorker: PropTypes.func.isRequired,
  isWorkerPickerOpen: PropTypes.bool.isRequired,
  isRatingAll: PropTypes.bool.isRequired,
  isCheckingInAll: PropTypes.bool.isRequired,
  isCheckingOutAll: PropTypes.bool.isRequired,
  isEditingRatesAll: PropTypes.bool.isRequired,
};

export default InProgressTable;
