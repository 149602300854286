import React, { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import makeStyles from '@mui/styles/makeStyles';
import { actions as JobDetailActions, selectors as JobDetailSelectors } from "store/modules/jobDetails";
import PositionTable from "./components/PositionTable/PositionTable";
import PositionTableHeader from "./components/PositionTableHeader/PositionTableHeader";

const useStyles = makeStyles(({ spacing, palette, shape }) => ({
  root: {
    backgroundColor: palette.grey[100],
    border: `1px solid ${palette.grey[500]}`,
    borderRadius: shape.borderRadius / 2,
    marginTop: spacing(2),
  },
}));

function useEditTableField(saveFn) {
  const dispatch = useDispatch();
  const [isEditable, setIsEditable] = useState(false);
  const onCancel = useCallback(() => {
    setIsEditable(false);
    dispatch(JobDetailActions.resetPositionUpdates());
  }, [dispatch]);
  const handleEdit = useCallback(() => setIsEditable(true), []);
  const onSave = useCallback(() => {
    dispatch(saveFn());
    setIsEditable(false);
  }, [dispatch, saveFn]);

  return [isEditable, handleEdit, onCancel, onSave];
}

function JobPositions({ onChooseWorker, isWorkerPickerOpen }) {
  const classes = useStyles();
  const [isRatingAll, handleRatingAll, onCancelRatingAll, onSaveRatingAll] = useEditTableField(
    JobDetailActions.rateAllWorkers
  );
  const [
    isCheckingInAll,
    handleCheckingInAll,
    onCancelCheckingInAll,
    onSaveCheckingInAll,
  ] = useEditTableField(JobDetailActions.checkinAllWorkers);
  const [
    isCheckingOutAll,
    handleCheckingOutAll,
    onCancelCheckingOutAll,
    onSaveCheckingOutAll,
  ] = useEditTableField(JobDetailActions.checkoutAllWorkers);
  const [
    isEditingRatesAll,
    handleEditingRatesAll,
    onCancelEditingRatesAll,
    onSaveEditingRatesAll,
  ] = useEditTableField(JobDetailActions.editAllPositionRates);

  const isValidPayRates = useSelector(JobDetailSelectors.getIsValidPayRates);
  const isValidBillingRates = useSelector(JobDetailSelectors.getIsValidBillingRates);

  const isEditing = isRatingAll || isCheckingInAll || isCheckingOutAll || isEditingRatesAll;
  let onCancelEdit = null;
  let onSaveEdit = null;
  let canSaveEdit = true;
  let editErrorMessage = null;
  if (isRatingAll) {
    onCancelEdit = onCancelRatingAll;
    onSaveEdit = onSaveRatingAll;
  } else if (isCheckingInAll) {
    onCancelEdit = onCancelCheckingInAll;
    onSaveEdit = onSaveCheckingInAll;
  } else if (isCheckingOutAll) {
    onCancelEdit = onCancelCheckingOutAll;
    onSaveEdit = onSaveCheckingOutAll;
  } else if (isEditingRatesAll) {
    onCancelEdit = onCancelEditingRatesAll;
    onSaveEdit = onSaveEditingRatesAll;
    canSaveEdit = isValidPayRates;
    if (!canSaveEdit && !isValidPayRates) {
      editErrorMessage = "Minimum wage requirement not met.";
    // } else if (!canSaveEdit && !isValidBillingRates) {
    //   editErrorMessage = "Minimum margin requirement not met.";
    }
  }

  return (
    <Grid container item className={classes.root} direction="column">
      <PositionTableHeader
        isEditing={isEditing}
        onEditSave={onSaveEdit}
        onEditCancel={onCancelEdit}
        canSaveEdit={canSaveEdit}
        editErrorMessage={editErrorMessage}
        handleRatingAll={handleRatingAll}
        handleCheckingInAll={handleCheckingInAll}
        handleCheckingOutAll={handleCheckingOutAll}
        handleEditingRatesAll={handleEditingRatesAll}
        handleAddWorker={onChooseWorker}
      />
      <PositionTable
        isWorkerPickerOpen={isWorkerPickerOpen}
        isRatingAll={isRatingAll}
        isCheckingInAll={isCheckingInAll}
        isCheckingOutAll={isCheckingOutAll}
        isEditingRatesAll={isEditingRatesAll}
        onChooseWorker={onChooseWorker}
      />
    </Grid>
  );
}

JobPositions.propTypes = {
  onChooseWorker: PropTypes.func.isRequired,
  isWorkerPickerOpen: PropTypes.bool.isRequired,
};

export default JobPositions;
