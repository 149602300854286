import { gql } from "@apollo/client";

export default gql`
  query adminSettings {
    adminSettings{
      id
      name
      description
      value
      service
      slug
      balance
      intValue
      walletId
      wallet
    }
  }
`;