import React, { useState } from "react";
import CurrencyFormat from "react-currency-format";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import Popover from "@mui/material/Popover";
import Button from "components/Button/Button";
import CurrencyTextField from "./CurrencyTextField";

function BatchCurrencyInput({ label, minValue, defaultValue, minValueHelperText, onApply }) {
  const [value, setValue] = useState(defaultValue);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setValue(defaultValue);
    setAnchorEl(null);
  };

  const handleBatchChange = values => {
    const { floatValue } = values;
    setValue(floatValue);
  };

  const handleApply = () => {
    onApply(value);
    handleClose();
  };

  const handleKeyDown = e => {
    if (e.key === "ArrowUp") {
      e.preventDefault();
      const newValue = +(parseFloat(value) + 0.05).toFixed(2);
      setValue(newValue);
    } else if (e.key === "ArrowDown") {
      e.preventDefault();
      const newValue = +(parseFloat(value) - 0.05).toFixed(2);
      setValue(newValue);
    }
  };

  const open = Boolean(anchorEl);
  const id = open ? "rate-popover" : undefined;

  return (
    <>
      <Button aria-describedby={id} label="Set all" onClick={handleClick} color="transparent" />
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        style={{ padding: 24 }}>
        <Grid container style={{ padding: 16 }} direction="column">
          <Grid item style={{ height: 66 }}>
            <CurrencyFormat
              minValue={minValue}
              minValueHelperText={minValueHelperText}
              label={label}
              isNumericString
              allowNegative
              decimalScale={2}
              fixedDecimalScale
              customInput={CurrencyTextField}
              value={value}
              onValueChange={handleBatchChange}
              onKeyDown={handleKeyDown}
              isAllowed={({ floatValue }) => floatValue < 1000}
            />
          </Grid>
          <Grid container item direction="row" justifyContent="space-between" style={{ marginTop: 12 }}>
            <Button label="Cancel" onClick={handleClose} color="transparent" />
            <Button label="Apply" onClick={handleApply} disabled={value < minValue} />
          </Grid>
        </Grid>
      </Popover>
    </>
  );
}

BatchCurrencyInput.propTypes = {
  label: PropTypes.string.isRequired,
  minValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  minValueHelperText: PropTypes.string,
  onApply: PropTypes.func.isRequired,
};

BatchCurrencyInput.defaultProps = {
  minValue: null,
  defaultValue: null,
  minValueHelperText: null,
};

export default BatchCurrencyInput;
