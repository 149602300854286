import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { actions as ReportsActions, selectors as ReportsSelectors } from "store/modules/reports";
import { convertUTCToTimezoneWithOffset } from "../../../../utils/time";
import { formatMoney } from "utils/stringUtils";
import ReactTableSimple from "components/ReactTable/ReactTableSimple";

function DCTransactionTable() {
    const dispatch = useDispatch();
    const dcTransactionData = useSelector(ReportsSelectors.getDCBankTransactionReportData)
    const isLoading = useSelector(ReportsSelectors.getIsDCReportLoading)

    useEffect(() => {
        dispatch(ReportsActions.fetchDCBankTransactionTableData())
    }, [])
    const columns = [
        {
            Header: "Worker Name",
            disableSortBy: true,
            accessor: "ContactName",
            Cell: ({ row: { original } }) => original.ContactName
        },
        {
            Header: "Customer Number",
            disableSortBy: true,
            accessor: "CustomerNumber",
            Cell: ({ row: { original } }) => original.CustomerNumber
        },
        {
            Header: "Transaction Id",
            disableSortBy: true,
            accessor: "TransactionId",
            Cell: ({ row: { original } }) => original.TransactionId
        },
        {
            Header: "Description",
            disableSortBy: true,
            accessor: "Description",
            Cell: ({ row: { original } }) => original.Description
        },
        {
            Header: "Client Reference Number",
            disableSortBy: true,
            accessor: "ClientReferenceNumber",
            Cell: ({ row: { original } }) => original.ClientReferenceNumber
        },
        {
            Header: "Transaction Status",
            disableSortBy: true,
            accessor: "TransactionStatusDescription",
            Cell: ({ row: { original } }) => original.TransactionStatusDescription
        },
        {
            Header: "Date Of Transaction",
            disableSortBy: true,
            filterable: false,
            width: 115,
            accessor: "DateOfFunds",
            Cell: ({ row: { original } }) => convertUTCToTimezoneWithOffset(original.DateOfFunds, "", "MMM Do, YYYY h:mm a")
        },
        {
            Header: <div className="text-right">Amount</div>,
            disableSortBy: true,
            accessor: "Amount",
            className: 'text-right',
            Cell: ({ row: { original } }) => formatMoney(original.Amount)
        },
    ];
    return (
        <React.Fragment>
            <ReactTableSimple
                hasFilter={false}
                data={dcTransactionData}
                loading={isLoading}
                columns={columns}
            />
        </React.Fragment>
    );
}

export default DCTransactionTable;
