import { createNetworkTypes } from "utils/redux";

export const networkTypes = {
  FETCH_TIMEFRAME_STATS: "FETCH_TIMEFRAME_STATS",
  FETCH_LIST_STATS: "FETCH_LIST_STATS",
};

export default {
  ...createNetworkTypes(networkTypes.FETCH_TIMEFRAME_STATS, "jobs"),
  ...createNetworkTypes(networkTypes.FETCH_LIST_STATS, "jobs"),
  SET_LIST_DATA: "jobs/SET_LIST_DATA",
  SET_LOADING_STATE: "jobs/SET_LOADING_STATE",
  SET_IMPORTED_PRIVATE_JOB_DATA: "jobs/SET_IMPORTED_PRIVATE_JOB_DATA",
  SET_PRIVATE_JOB_POSITIONS_DATA: "jobs/SET_PRIVATE_JOB_POSITIONS_DATA",
  SET_IMPORTED_PRIVATE_JOB_DETAIL: "jobs/SET_IMPORTED_PRIVATE_JOB_DETAIL",
  SET_IMPORT_CSV_ERROR_DATA: "jobs/SET_IMPORT_CSV_ERROR_DATA",
  SET_UPDATE_PRIVATE_JOB_ERROR_DATA: "jobs/SET_UPDATE_PRIVATE_JOB_ERROR_DATA",
  SET_DELETE_PRIVATE_JOB_POSITION_ERROR_DATA: "jobs/SET_DELETE_PRIVATE_JOB_POSITION_ERROR_DATA",
  SET_FILL_POSITION_ERROR_DATA: "jobs/SET_FILL_POSITION_ERROR_DATA",
};
