import { gql } from "@apollo/client";
import PositionFields from "../fragments/position-fragment";
import PositionWorkerFields from "../fragments/position-worker-fragment";

export default gql`
  query getJobPositions($jobId: ID!, $employerId: String!) {
    job(id: $jobId) {
      positions {
        ...PositionFields
        worker {
          ...PositionWorkerFields
          positionCountByEmployer(employerId: $employerId)
        }
      }
    }
  }
  ${PositionFields}
  ${PositionWorkerFields}
`;
