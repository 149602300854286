/* eslint-disable react/prop-types */
import React from "react";
import { withRouter } from "react-router-dom";
import { Button, DialogContent } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";

import { invoicePdfDialogStyles } from "views/Finance/helpers";
import PdfService from "./PdfService";

const dialogProps = {
  PaperProps: {
    style: {
      padding: 15,
      paddingBottom: 5,
      fontSize: 12,
      color: "#fff",
      fontWeight: 700,
      backgroundColor: "transparent",
      boxShadow: "none",
    },
  },
  maxWidth: "md",
  disableBackdropClick: false,
  fullWidth: true,
};

class InvoicePdfDialog extends React.Component {
  state = { anchorEl: null, open: true };

  render = () => {
    const { open, handleClose, classes, shifts, invoice, adjustments } = this.props;
    // const adjustments = [shifts[0]];

    // console.info("adjustments.  props", adjustments);

    const renderDialogContent = () => (
      <DialogContent className={classes.dialogContent} style={{ width: "100%" }}>
        <PdfService
          type="invoice"
          //   data={data}
          employer={invoice.employer}
          invoice={invoice}
          shifts={shifts}
          adjustments={adjustments}
        />
      </DialogContent>
    );
    const renderDialogActions = () => (
      <DialogActions className={classes.dialogActions}>
        <Button autoFocus onClick={handleClose} variant="contained" color="primary">
          Cancel
        </Button>
        <Button onClick={handleClose} variant="contained" color="secondary">
          Ok
        </Button>
      </DialogActions>
    );

    return (
      <Dialog {...dialogProps} onClose={handleClose} open={open}>
        {/* {renderDialogTop()} */}
        {renderDialogContent()} {renderDialogActions()}
      </Dialog>
    );
  };
}

export default invoicePdfDialogStyles(withRouter(InvoicePdfDialog));
