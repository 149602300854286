import React from "react";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import withStyles from '@mui/styles/withStyles';
import Delete from "@mui/icons-material/Delete";
import Edit from "@mui/icons-material/Edit";
import MoreVert from "@mui/icons-material/MoreVert";

const styles = ({ typography, spacing }) => ({
  icon: {
    fontSize: typography.pxToRem(20),
    marginRight: spacing(2),
  },
});

class FaqListItemMenu extends React.Component {
  state = {
    anchorEl: null,
  };

  handleClick = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  handleEdit = () => {
    this.props.onEdit(this.props.faqId, this.props.faqListType);
    this.handleClose();
  };

  handleDelete = () => {
    this.props.onDelete(this.props.faqId);
    this.handleClose();
  };

  render() {
    const { anchorEl } = this.state;
    const { classes, faqId } = this.props;

    const id = `action-menu-${faqId}`;

    return (
      <React.Fragment>
        <IconButton
          aria-owns={anchorEl ? id : undefined}
          aria-haspopup="true"
          disableRipple
          onClick={this.handleClick}
          size="large">
          <MoreVert />
        </IconButton>
        {anchorEl ? (
          <Menu
            id={id}
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={this.handleClose}
            disableAutoFocusItem>
            <MenuItem onClick={this.handleEdit}>
              <Edit className={classes.icon} color="action" />
              Edit
            </MenuItem>
            <MenuItem onClick={this.handleDelete}>
              <Delete className={classes.icon} color="action" />
              Delete
            </MenuItem>
          </Menu>
        ) : null}
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(FaqListItemMenu);
