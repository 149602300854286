import { Typography } from "@mui/material";
import React from "react";
import Can from "./Can";


const CanWrapper = (props) => {
    return <Can perform={props.perform}>
        {allowed => {
            if (!allowed) {
                return <div style={{ flex: 1, display: "flex", justifyContent: "center", alignItems: "center", height: "100%", width: "100%" }}>
                    <Typography align="center">{"Sorry, you don't have access to this section of the admin panel, please contact an admin to be granted access"}</Typography>
                </div>;
            }
            return props.children;
        }}
    </Can>
}

export default CanWrapper;
