import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import Grid from "@mui/material/Grid";
import InputAdornment from "@mui/material/InputAdornment";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import Dialog from "components/Dialog/Dialog";
import { actions as EmployerActions } from "store/modules/employerDetails";
const isMobile = false;
function EditCustomRateDialog(props) {
  const { open, onClose, onCancel, item } = props;

  const [isBaseRateValid, setIsBaseRateValid] = useState(true);
  const [isBaseBillingRateValid, setIsBaseBillingRateValid] = useState(true);
  const [isNameValid] = useState(true);

  const [isLabelValid, setIsLabelValid] = useState(true);
  const [isDescriptionValid, setIsDescriptionValid] = useState(true);
  const [isWageMultiplierValid, setIsWageMultiplierValid] = useState(true);
  const [isBillingMultiplierValid, setIsBillingMultiplierValid] = useState(true);

  const [isRateTypeValid, setIsRateTypeValid] = useState(true);
  const dispatch = useDispatch();

  useEffect(() => {
    // dispatch(EmployerActions.clearUpdateEmployerCustomRateData());
    // dispatch(EmployerActions.clearCreateEmployerCustomRateData());
    // dispatch(EmployerActions.setEditEmployerCustomRateData("id", item.id));
    // setNewBaseRate(parseFloat(item.baseRate).toFixed(2));
    // setNewData({ ...item });
  }, [item]);

  const controlPadding = {
    leftColumn: { paddingRight: isMobile ? 0 : 4 },
    rightColumn: { paddingLeft: isMobile ? 0 : 4 },
  };

  const setEditEmployerCustomRateData = ({ target: { name, value } }) => {
    dispatch(EmployerActions.setEditEmployerCustomRateData(name, value));
  };

  const setBaseRate = ({ target: { name, value } }) => {
    setIsBaseRateValid(value && value >= 14 && value <= 100 ? true : false);
    dispatch(EmployerActions.setEditEmployerCustomRateData(name, parseFloat(value).toFixed(2)));
  };
  const setWageMultiplier = ({ target: { name, value } }) => {
    setIsWageMultiplierValid(value && value >= 1 && value <= 100 ? true : false);
    dispatch(EmployerActions.setEditEmployerCustomRateData(name, value));
  };
  const setBillingRateMultiplier = ({ target: { name, value } }) => {
    setIsBillingMultiplierValid(value && value >= 1 && value <= 100 ? true : false);
    dispatch(EmployerActions.setEditEmployerCustomRateData(name, value));
  };
  const setRateType = ({ target: { value } }) => {
    setIsRateTypeValid(value && value.length >= 2 ? true : false);
    dispatch(EmployerActions.setEditEmployerCustomRateData(value));
  };
  const setBillingRate = ({ target: { name, value } }) => {
    setIsBaseBillingRateValid(value && value >= 22 && value <= 124 ? true : false);
    dispatch(EmployerActions.setEditEmployerCustomRateData(name, parseFloat(value).toFixed(2)));
  };
  const setRateName = ({ target: { name, value } }) => {
    // setIsNameValid(value && value.length >= 2 ? true : false);

    if (value && value.length >= 2) {
      dispatch(EmployerActions.setEditEmployerCustomRateData(name, value));
    } else dispatch(EmployerActions.setEditEmployerCustomRateData(name, item.name));
  };
  const setRateDescription = ({ target: { name, value } }) => {
    setIsDescriptionValid(value && value.length >= 5 ? true : false);
    dispatch(EmployerActions.setEditEmployerCustomRateData(name, value));
  };
  const setRateLabel = ({ target: { name, value } }) => {
    setIsLabelValid(value && value.length >= 2 && value.length <= 100 ? true : false);
    dispatch(EmployerActions.setEditEmployerCustomRateData(name, value));
  };

  function limitDecimalPlaces(e, count) {
    if (e.target.value.indexOf(".") === -1) {
      return;
    }
    if (e.target.value.length - e.target.value.indexOf(".") > count) {
      e.target.value = parseFloat(e.target.value).toFixed(count);
    }
  }
  const RenderBaseRateInput = () => (
    <Grid container item xs={12} md={4} style={controlPadding.leftColumn}>
      <TextField
        id="baseRate"
        name="baseRate"
        fullWidth
        label="Base Hourly Pay Rate"
        onBlur={setBaseRate}
        margin="normal"
        type="number"
        error={!isBaseRateValid}
        min={14.0}
        step={"0.05"}
        onInput={e => limitDecimalPlaces(e, 2)}
        InputProps={{
          startAdornment: <InputAdornment position="start">$</InputAdornment>,
          onInput: e => limitDecimalPlaces(e, 2),
        }}
        inputProps={{
          onInput: e => limitDecimalPlaces(e, 2),
          step: 0.05,
          type: "number",
        }}
        // InputProps={{ min: 14, step: "0.05" }}
        defaultValue={item.baseRate}
        //   value={baseRate}
      />
    </Grid>
  );
  const RenderBaseBillingRateInput = () => (
    <Grid container item xs={12} md={4} style={controlPadding.rightColumn}>
      <TextField
        id="baseBillingRate"
        name="baseBillingRate"
        fullWidth
        label="Base Billing Rate"
        onChange={setBillingRate}
        type="number"
        error={!isBaseBillingRateValid}
        min={14.0}
        step={"0.05"}
        margin="normal"
        onInput={e => limitDecimalPlaces(e, 2)}
        InputProps={{
          startAdornment: <InputAdornment position="start">$</InputAdornment>,
        }}
        inputProps={{
          onInput: e => limitDecimalPlaces(e, 2),
          step: 0.05,
          type: "number",
        }}
        defaultValue={item.baseBillingRate}
      />
    </Grid>
  );
  const RenderWageMultiplier = () => (
    <Grid container item xs={12} md={4} style={controlPadding.rightColumn}>
      <TextField
        id="wageMultiplier"
        name="wageMultiplier"
        fullWidth
        error={!isWageMultiplierValid}
        label="Wage Multiplier"
        onChange={setWageMultiplier}
        margin="normal"
        defaultValue={item.wageMultiplier}
      />
    </Grid>
  );
  //   const RenderDescriptionInput = () =>
  //   const RenderDescriptionInput = () =>
  //   const RenderDescriptionInput = () =>
  const RenderDescriptionInput = () => (
    <Grid container item xs={12}>
      <TextField
        id="description"
        name="description"
        error={!isDescriptionValid}
        label="description"
        onChange={setRateDescription}
        type="text"
        margin="normal"
        defaultValue={item.description}
        fullWidth
        required
        multiline
        rows={4}
      />
    </Grid>
  );
  const handleUpdateEmployerCustomRate = () => {
    dispatch(EmployerActions.updateEmployerCustomRate());
    onCancel();
  };

  const RenderEditRateForm = () => (
    <Grid container item xs={12} style={{ width: "100%", marginTop: 0 }}>
      <Grid container item xs={12} md={6} style={controlPadding.leftColumn}>
        <TextField
          id="name"
          name="name"
          label="Rate Name"
          onChange={setRateName}
          margin="normal"
          defaultValue={item.name}
          fullWidth
          required
          error={!isNameValid}

          //   min={2}
        />
      </Grid>
      <Grid container item xs={12} md={6} style={controlPadding.rightColumn}>
        <TextField
          id="billingLabel"
          name="billingLabel"
          label="Rate Billing Label"
          onChange={setRateLabel}
          margin="normal"
          defaultValue={item.billingLabel}
          fullWidth
          error={!isLabelValid}
          required
          //   min={2}
        />
      </Grid>
      <RenderDescriptionInput />
      <RenderBaseRateInput />
      <RenderBaseBillingRateInput />
      <RenderWageMultiplier />

      <Grid container item xs={12} md={4} style={controlPadding.leftColumn}>
        <TextField
          select
          name="rateType"
          id="rateType"
          label="Rate Type"
          onChange={setRateType}
          type="text"
          fullWidth
          margin="normal"
          defaultValue={item.rateType}>
          <MenuItem value={"REG"}>Regular Rate</MenuItem>
          <MenuItem value={"OT"}>Overtime Rate</MenuItem>
          <MenuItem value={"DT"}>Doubletime Rate</MenuItem>
        </TextField>
      </Grid>
      <Grid
        container
        item
        xs={12}
        md={4}
        style={{ ...controlPadding.rightColumn, ...controlPadding.leftColumn }}>
        <TextField
          select
          name="isActive"
          id="isActive"
          label="Status"
          onChange={setEditEmployerCustomRateData}
          type="text"
          fullWidth
          margin="normal"
          defaultValue={item.isActive}>
          <MenuItem value={true}>Active</MenuItem>
          <MenuItem value={false}>Non Taxable</MenuItem>
        </TextField>
      </Grid>
      <Grid container item xs={12} md={4} style={controlPadding.rightColumn}>
        <TextField
          id="billingRateMultiplier"
          name="billingRateMultiplier"
          fullWidth
          label="Billing Rate Multiplier"
          onChange={setBillingRateMultiplier}
          error={!isBillingMultiplierValid}
          margin="normal"
          defaultValue={item.billingRateMultiplier}
        />
      </Grid>
    </Grid>
  );

  return <>
    <Dialog
      open={open}
      title={`Update Custom Rate`}
      description={`Update A New Custom Rate`}
      confirmText={"Save Custom Rate"}
      disabled={
        !isBaseRateValid ||
        !isDescriptionValid ||
        !isBaseBillingRateValid ||
        !isNameValid ||
        !isLabelValid ||
        !isWageMultiplierValid ||
        !isRateTypeValid
      }
      size="sm"
      onClose={onClose}
      onConfirm={handleUpdateEmployerCustomRate}
      onCancel={onCancel}
      alertType="form"
      modalType="editEmployer">
      <RenderEditRateForm />
    </Dialog>
  </>;
}

export default EditCustomRateDialog;
