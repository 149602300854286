import React, { Fragment, useEffect } from "react";

import Dialog from "components/Dialog/Dialog";

function TransferReversalDialog(props) {
  const { open, onCancel, onConfirm, onClose, reversal, RenderContent } = props;

  useEffect(() => {
    // dispatch(FinanceActions.findRequestForFunds());
  }, [reversal]);

  return (
    <Fragment>
      <Dialog
        open={open}
        title={`Request eTransfer Reversal`}
        description={`   \n\n`}
        onClose={onClose}
        onConfirm={onConfirm}
        onCancel={onCancel}
        confirmText="Send Request"
        alertType="form"
        maxWidth="sm"
        size="sm"
        disableEscapeKeyDown
        disableRestoreFocus
        // style={{ top: "-200px", zIndex: 99999 }}
        fullWidth={false}>
          <RenderContent />
      </Dialog>
    </Fragment>
  );
}

export default TransferReversalDialog;
