import * as pagination from "utils/graphql-pagination";
import { createReducer } from "utils/redux";
import { validateSIN, verifyEmail, verifyPassword } from "utils/stringUtils";
import { paginationSettings } from "./constants";

import types from "./types";
const initialLoadingState = {
  futureJob: true,
};
const initialState = {
  loading: { ...initialLoadingState },
  unverifiedPageSort: [{ id: "createdAtUtc", desc: true }],
  unverifiedPageFilter: [],
  unverifiedDetails: {},
  addWorkerData: {},
  sinIsValid: null,
  sinIsTaken: null,
  emailIsValid: null,
  emailIsAvailable: null,
  isPasswordMatch: null,
  isPasswordValid: null,
  canSubmitNewWorkerForm: false,
  errors: [],
  pageSort: [{ id: "createdAtUtc", desc: true }],
  suspendedPageSort: [{ id: "createdAtUtc", desc: true }],
  appliedPageSort: [{ id: "createdAtUtc", desc: true }],
  verifiedPageSort: [{ id: "createdAtUtc", desc: true }],
  deactivatedPageSort: [{ id: "createdAtUtc", desc: true }],
  pageFilter: [],
  suspendedPageFilter: [],
  appliedPageFilter: [],
  verifiedPageFilter: [],
  deactivatedPageFilter: [],
  workerPayroll: [],
  details: {},
  suspendedDetails: {},
  verifiedDetails: {},
  deactivatedDetails: {},
  appliedDetails: {},
  bans: {},
  workerFutureJobsList: [],
  waitlist: {},
  waitlistFilter: [],
  waitlistToday: {},
  waitlistTodayFilter: [],
  waitlistTodayCriteria: {
    canDrive: true,
    canWorkMorning: true,
    canWorkEvening: true,
    onWaitlist: "Today",
  },
  waitlistCriteria: {},
  waitlistTodaySort: [],
  waitlistTomorrow: {},
  waitlistTomorrowCriteria: {
    canDrive: true,
    canWorkMorning: true,
    canWorkEvening: true,
    onWaitlist: "Tomorrow",
  },
  waitlistTomorrowFilter: [],
  waitlistTomorrowSort: [],
  preferred: {},
  editData: {},
  newWorker: {},
  workerStats: {},
  location: null,
  selectedLocation: null,
  ...pagination.initialState(paginationSettings),
};

const actionsMap = {
  [types.SET_LOADING_STATE]: (state, { payload }) => {
    const { key, value } = payload;
    return {
      ...state,
      loading: {
        ...state.loading,
        [key]: value,
      },
    };
  },
  [types.SET_LOCATION]: (state, { payload }) => {
    const { selectedLocation, location } = payload;
    return {
      ...state,
      addWorkerData: {
        ...state.addWorkerData,
        location: location,
      },
      location: location,
      selectedLocation: selectedLocation,
    };
  },

  [types.SET_VALID_EMAIL]: (state, { payload }) => {
    return {
      ...state,
      emailIsValid: verifyEmail(payload.email),
      addWorkerData: {
        ...state.addWorkerData,
        email: payload.email,
      },
    };
  },

  [types.SET_EMAIL_AVAILABLE]: (state, { payload }) => {
    return {
      ...state,

      emailIsAvailable: payload.availability,
    };
  },

  [types.SET_SIN_TAKEN]: (state, { payload }) => {
    return {
      ...state,
      sinIsTaken: payload.taken,
    };
  },

  [types.UPDATE_ADDWORKER_DATA]: (state, { payload }) => {
    const { field, value } = payload;
    return {
      ...state,
      addWorkerData: {
        ...state.addWorkerData,
        [field]: value,
      },
    };
  },

  [types.SET_PASSWORD_MATCH]: (state, { payload }) => {
    return {
      ...state,
      isPasswordMatch: state.addWorkerData.password === payload.password,
    };
  },

  [types.SET_PASSWORD]: (state, { payload }) => {
    return {
      ...state,
      isPasswordValid: verifyPassword(payload.password),
      addWorkerData: {
        ...state.addWorkerData,
        password: payload.password,
      },
    };
  },

  [types.SET_VALID_SIN]: (state, { payload }) => {
    return {
      ...state,
      sinIsValid: payload.value,
    };
  },

  [types.UPDATE_SIN]: (state, { payload }) => {
    return {
      ...state,
      sinIsValid: payload.sin.length === 9 ? validateSIN(payload.sin) : null,
      addWorkerData: {
        ...state.addWorkerData,
        sin: payload.sin,
      },
    };
  },

  [types.VALIDATE_WORKER]: (state, { payload }) => {
    return {
      ...state,
      sinIsValid: payload.sin.length === 9 ? validateSIN(payload.sin) : null,
      addWorkerData: {
        ...state.addWorkerData,
        sin: payload.sin,
      },
    };
  },

  [types.SET_WORKERS]: (state, action) => {
    const { workers } = action.payload;

    return {
      ...state,
      details: workers.reduce((acc, worker) => {
        acc[worker.id] = worker;
        return acc;
      }, {}),
    };
  },

  [types.SET_DEACTIVATED_WORKERS]: (state, action) => {
    const { workers } = action.payload;
    return {
      ...state,
      deactivatedDetails: workers.reduce((acc, worker) => {
        acc[worker.id] = worker;
        return acc;
      }, {}),
    };
  },

  [types.SET_VERIFIED_WORKERS]: (state, action) => {
    const { workers } = action.payload;
    return {
      ...state,
      verifiedDetails: workers.reduce((acc, worker) => {
        acc[worker.id] = worker;
        return acc;
      }, {}),
    };
  },

  [types.SET_UNVERIFIED_WORKERS]: (state, action) => {
    const { workers } = action.payload;
    return {
      ...state,
      unverifiedDetails: workers.reduce((acc, worker) => {
        acc[worker.id] = worker;
        return acc;
      }, {}),
    };
  },

  [types.SET_SUSPENDED_WORKERS]: (state, action) => {
    const { workers } = action.payload;
    return {
      ...state,
      suspendedDetails: workers.reduce((acc, worker) => {
        acc[worker.id] = worker;
        return acc;
      }, {}),
    };
  },
  [types.SET_APPLIED_WORKERS]: (state, action) => {
    const { workers } = action.payload;
    return {
      ...state,
      appliedDetails: workers.reduce((acc, worker) => {
        acc[worker.id] = worker;
        return acc;
      }, {}),
    };
  },

  [types.UPDATE_PAGE_SORT]: (state, { payload }) => {
    const { pageSort } = payload;
    return {
      ...state,
      pageSort,
    };
  },

  [types.UPDATE_VERIFIED_PAGE_SORT]: (state, { payload }) => {
    const { pageSort } = payload;
    return {
      ...state,
      verifiedPageSort: pageSort,
      ...pageSort,
    };
  },

  [types.UPDATE_SUSPENDED_PAGE_SORT]: (state, { payload }) => {
    const { pageSort } = payload;
    return {
      ...state,
      suspendedPageSort: pageSort,
      ...pageSort,
    };
  },

  [types.UPDATE_UNVERIFIED_PAGE_SORT]: (state, { payload }) => {
    const { pageSort } = payload;
    return {
      ...state,
      unverifiedPageSort: pageSort,
    };
  },

  [types.UPDATE_DEACTIVATED_PAGE_SORT]: (state, { payload }) => {
    const { pageSort } = payload;
    return {
      ...state,
      deactivatedPageSort: pageSort,
    };
  },
  [types.UPDATE_APPLIED_PAGE_SORT]: (state, { payload }) => {
    const { pageSort } = payload;
    return {
      ...state,
      appliedPageSort: pageSort,
    };
  },

  [types.UPDATE_PAGE_FILTER]: (state, { payload }) => {
    const { pageFilter } = payload;
    return {
      ...state,
      pageFilter,
    };
  },

  [types.UPDATE_VERIFIED_PAGE_FILTER]: (state, { payload }) => {
    const { pageFilter } = payload;
    return {
      ...state,
      verifiedPageFilter: pageFilter,
    };
  },

  [types.UPDATE_DEACTIVATED_PAGE_FILTER]: (state, { payload }) => {
    const { pageFilter } = payload;
    return {
      ...state,
      deactivatedPageFilter: pageFilter,
    };
  },

  [types.UPDATE_UNVERIFIED_PAGE_FILTER]: (state, { payload }) => {
    const { pageFilter } = payload;
    return {
      ...state,
      unverifiedPageFilter: pageFilter,
    };
  },

  [types.UPDATE_SUSPENDED_PAGE_FILTER]: (state, { payload }) => {
    const { pageFilter } = payload;
    return {
      ...state,
      suspendedPageFilter: pageFilter,
    };
  },
  [types.UPDATE_APPLIED_PAGE_FILTER]: (state, { payload }) => {
    const { pageFilter } = payload;
    return {
      ...state,
      appliedPageFilter: pageFilter,
    };
  },

  [types.FETCH_WORKER_STATS]: (state, { payload }) => {
    const { data } = payload;
    const stats = data.getWorkerStats;
    return {
      ...state,
      workerStats: stats,
    };
  },

  [types.UPDATE_WAITLIST_TODAY_TABLE_FILTER]: (state, { payload }) => {
    const { pageFilter } = payload;
    return {
      ...state,
      waitlistTodayFilter: pageFilter,
    };
  },

  [types.UPDATE_WAITLIST_TODAY_PAGE_SORT]: (state, { payload }) => {
    const { pageSort } = payload;
    return {
      ...state,
      waitlistTodaySort: pageSort,
    };
  },

  [types.UPDATE_WAITLIST_TODAY_CRITERIA]: (state, { payload }) => {
    const { criteria } = payload;
    return {
      ...state,
      waitlistTodayCriteria: criteria,
    };
  },
  [types.UPDATE_WAITLIST_TODAY_FILTER]: (state, { payload }) => {
    const { waitlistTodayFilter } = payload;
    return {
      ...state,
      waitlistTodayFilter,
    };
  },

  [types.UPDATE_WAITLIST_TOMORROW_TABLE_FILTER]: (state, { payload }) => {
    const { pageFilter } = payload;
    return {
      ...state,
      waitlistTomorrowFilter: pageFilter,
    };
  },

  [types.UPDATE_WAITLIST_TOMORROW_CRITERIA]: (state, { payload }) => {
    const { criteria } = payload;
    return {
      ...state,
      waitlistTomorrowCriteria: criteria,
    };
  },
  [types.UPDATE_WAITLIST_TOMORROW_FILTER]: (state, { payload }) => {
    const { waitlistTomorrowFilter } = payload;
    return {
      ...state,
      waitlistTomorrowFilter,
    };
  },
  [types.UPDATE_WAITLIST_TOMORROW_PAGE_SORT]: (state, { payload }) => {
    const { pageSort } = payload;
    return {
      ...state,
      waitlistTomorrowSort: pageSort,
    };
  },
  [types.UPDATE_WAITLIST_FILTER]: (state, { payload }) => {
    const { waitlistFilter } = payload;
    return {
      ...state,
      waitlistFilter,
    };
  },
  [types.SET_WORKER_PAYROLL]: (state, { payload }) => {
    const { deprecated_payroll } = payload;

    return {
      ...state,
      workerPayroll: deprecated_payroll,
    };
  },

  [types.SET_NEW_WORKER]: (state, { payload }) => {
    const { worker } = payload;
    return {
      ...state,
      newWorker: worker,
    };
  },

  [types.SET_ERRORS]: (state, { payload }) => {
    const { errors } = payload;
    return {
      ...state,
      errors: errors,
    };
  },

  [types.SET_WAITLIST]: (state, action) => {
    const { workers } = action.payload;
    return {
      ...state,
      waitlist: workers.reduce((acc, worker) => {
        acc[worker.id] = worker;
        return acc;
      }, {}),
    };
  },

  [types.SET_WAITLIST_TOMORROW]: (state, action) => {
    const { workers } = action.payload;
    return {
      ...state,
      waitlistTomorrow: workers.reduce((acc, worker) => {
        acc[worker.id] = worker;
        return acc;
      }, {}),
    };
  },

  [types.SET_WAITLIST_TODAY]: (state, action) => {
    const { workers } = action.payload;
    return {
      ...state,
      waitlistToday: workers.reduce((acc, worker) => {
        acc[worker.id] = worker;
        return acc;
      }, {}),
    };
  },

  [types.REMOVE_WORKER_REP]: (state, action) => {
    return {
      ...state,
      rep: action.payload,
    };
  },

  [types.SET_WORKER_BANS]: (state, action) => {
    return {
      ...state,
      bans: action.payload,
    };
  },

  [types.SET_WORKER_PREFERRED]: (state, action) => {
    return {
      ...state,
      preferred: action.payload,
    };
  },

  [types.SET_WORKER]: (state, { payload }) => {
    const { worker } = payload;
    return {
      ...state,
      details: {
        ...state.details,
        [worker.id]: worker,
      },
    };
  },

  [types.UPDATE_EDIT_DATA]: (state, { payload }) => {
    const { field, value } = payload;
    return {
      ...state,
      editData: {
        ...state.editData,
        [field]: value,
      },
    };
  },

  [types.IS_LOADING_INFO]: (state, { payload }) => {
    const { isInfoLoading } = payload;
    return {
      ...state,
      isInfoLoading,
    };
  },

  [types.SET_WORKER_SUSPENSION]: (state, { payload }) => {
    const { workerId, isSuspended } = payload;
    return {
      ...state,
      details: {
        ...state.details,
        [workerId]: {
          ...state.details[workerId],
          isSuspended,
        },
      },
    };
  },

  [types.ADD_WORKER_PREFERRED]: (state, { payload }) => {
    const { workerId, isSuspended } = payload;
    return {
      ...state,
      details: {
        ...state.details,
        [workerId]: {
          ...state.details[workerId],
          isSuspended,
        },
      },
    };
  },

  [types.SET_WORKER_SUSPENSION]: (state, { payload }) => {
    const { workerId, isSuspended } = payload;
    return {
      ...state,
      details: {
        ...state.details,
        [workerId]: {
          ...state.details[workerId],
          isSuspended,
        },
      },
    };
  },

  [types.ADD_WORKER_PREFERRED]: (state, { payload }) => {
    const { workerId, isSuspended } = payload;
    return {
      ...state,
      details: {
        ...state.details,
        [workerId]: {
          ...state.details[workerId],
          isSuspended,
        },
      },
    };
  },

  [types.ADD_WORKER_BAN]: (state, { payload }) => {
    const { workerId, isSuspended } = payload;
    return {
      ...state,
      details: {
        ...state.details,
        [workerId]: {
          ...state.details[workerId],
          isSuspended,
        },
      },
    };
  },

  [types.WORKER_FUTURE_JOBS_LIST]: (state, { payload }) => {
    const { data } = payload;
    return {
      ...state,
      workerFutureJobsList: data
    };
  },

  [types.IS_LOADING_DETAIL]: (state, { payload }) => {
    const { isDetailLoading } = payload;
    return {
      ...state,
      isDetailLoading,
    };
  },
  ...pagination.createPaginationActions(paginationSettings),
};

export default createReducer(initialState, actionsMap);
