import * as pagination from "utils/graphql-pagination";
import { createReducer } from "utils/redux";
import { paginationSettings } from "./constants";
import types from "./types";
const initialLoadingState = {
  profileSave: false,
};
const initialState = {
  profileDetail: {},
  loading: { ...initialLoadingState },
  ...pagination.initialState(paginationSettings),
};

const actionsMap = {
  [types.SET_LOADING_STATE]: (state, { payload }) => {
    const { key, value } = payload;
    return {
      ...state,
      loading: {
        ...state.loading,
        [key]: value,
      },
    };
  },
  [types.SET_PROFILE_DATA]: (state, { payload }) => {
    const { data } = payload;
    return {
      ...state,
      profileDetail: {...data},
    };
  },
  ...pagination.createPaginationActions(paginationSettings),
};

export default createReducer(initialState, actionsMap);
