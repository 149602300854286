export const paginationSettings = {
  allWorkers: {
    prefix: "pagination_AllWorkers",
    defaultPageSize: 5,
  },
  preferredWorkers: {
    prefix: "pagination_PreferredWorkers",
    defaultPageSize: 5,
  },
  waitlistWorkers: {
    prefix: "pagination_WaitlistWorkers",
    defaultPageSize: 5,
  },
};
