import React from "react";
import { useSelector } from "react-redux";
import Line from "components/Placeholders/Line";
import { selectors as JobDetailSelectors } from "store/modules/jobDetails";
import Can from "components/Authorization/Can";
import { PositionPermissions } from "helpers/constants/permissions";

export default function useCheckOutBy(disabled) {
    const canAddWorker = useSelector(JobDetailSelectors.getCanAddWorker);
    return {
        Header: "Check Out By",
        id: "checkedOutBy",
        // width: 150,
        sortable: false,
        Cell: ({ row: { original: { checkedOutBy } } }) => {
            if (!checkedOutBy) {
                return <Line />
            }
            return <div style={{ wordBreak: 'break-word' }}>{checkedOutBy}</div>;
        },
        Footer: (<Can perform={PositionPermissions.create.fill.value}>
            {allowed => {
                if (!allowed) {
                    return null;
                }
                return canAddWorker && !disabled ? <Line /> : null
            }}
        </Can>)
    };
}
