import jwtDecode from "jwt-decode";
import { createReducer } from "utils/redux";
import types from "./types";

const authResponseInitalState = {
  profile: {},
  rememberUser: false,
  email: "",
  password: "",
  auth0Id: null,
  accessToken: null,
  idToken: null,
  expiresIn: null,
  expiresAt: null,
  isCheckingSession: false,
};

const initialState = { ...authResponseInitalState };

const actionsMap = {
  [types.SET_LOGIN_EMAIL]: (state, { payload }) => {
    return { ...state, email: payload.email };
  },
  [types.SET_PASSWORD]: (state, { payload }) => {
    return { ...state, password: payload.password };
  },
  [types.RESET]: state => {
    return {
      ...state,
      ...initialState,
      email: state.email,
      password: "",
      rememberUser: false,
    };
  },
  [types.FORGET_USER]: state => {
    return {
      ...state,
      rememberUser: false,
      email: "",
    };
  },
  [types.SET_CREDENTIALS]: (state, { payload }) => {
    const { accessToken, idToken, expiresIn, idTokenPayload } = payload.auth;

    const { permissions, ...decodedAccessToken } = jwtDecode(accessToken);

    const profile = {
      name: idTokenPayload.nickname,
      email: idTokenPayload.email,
      lastIp: idTokenPayload["https://grizzlyforce.com/ip"],
      roles: idTokenPayload["https://grizzlyforce.com/user_authorization"].roles || ["Unknown"],
      locationData: idTokenPayload["https://example.com/geoip"],
      loginCount: idTokenPayload["https://grizzlyforce.com/stats"].loginsCount,
      email_verified: idTokenPayload.email_verified,
    };

    const expiresAt = new Date().getTime() + expiresIn * 1000;

    const newState = {
      ...state,
      accessToken,
      profile,
      idToken,
      expiresIn,
      permissions: permissions || [],
      roles: decodedAccessToken[process.env.REACT_APP_ROLES_ENDPOINT] || [],
      auth0Id: idTokenPayload.sub,
      expiresAt: expiresAt,
      password: "",
    };

    return newState;
  },
  [types.IS_CHECKING_SESSION]: (state, { payload }) => {
    const { isCheckingSession } = payload;
    return {
      ...state,
      isCheckingSession,
    };
  },
  [types.SET_ME]: (state, { payload }) => {
    const { me } = payload;
    return {
      ...state,
      me,
    };
  },
  [types.SET_TIMEZONE_LIST]: (state, { payload }) => {
    return { ...state, timezoneList: payload };
  },
};

export default createReducer(initialState, actionsMap);
