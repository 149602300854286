import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "@mui/material";
import withStyles from '@mui/styles/withStyles';
import DialogActions from "@mui/material/DialogActions";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { Lock, LockOpen } from "@mui/icons-material";
import { Form, Formik } from "formik";
import placeholder from "assets/img/placeholder.jpg";


import * as yup from "yup";
import { verifyEmail } from "utils/stringUtils";
import { actions as WorkerDetailActions, selectors as WorkerDetailSelectors } from "store/modules/workerDetails";
// import { setUpdateWorkerData } from "store/modules/workerDetails/actions";
const isMobile = false;
const styles = ({ spacing }) => ({
    modal: {
        padding: isMobile ? spacing(2) : spacing(6),
        paddingBottom: isMobile ? spacing(2) : spacing(1),
        margin: isMobile ? 0 : spacing(2),

        alignItems: "center",
        // width: spacing(60
        // overflowY: "unset",
    },

    title: {
        padding: `${spacing(2)} 0 ${spacing(1)} 0`,
    },
    content: {
        padding: `${spacing(1)} 0 ${spacing(1)} 0`,
        // overflowY: "auto",
        // overflowY: "unset",
        width: "100%",
    },
    footer: {
        margin: 0,
        textAlign: "center",
        justifyContent: "center",
    },
});

function WorkerProfileTab(props) {
    let worker = useSelector(WorkerDetailSelectors.getWorkerDetails).worker;
    let profileImage = worker && worker.signedProfileImageUrl ? worker.signedProfileImageUrl : "";
    let identificationImage = worker && worker.signedIdentificationImageUrl ? worker.signedIdentificationImageUrl : "";

    const {
        firstName,
        lastName,
        // sin,
        phone,
        email,
        street,
        city,
        region,
        country,
        postalCode,

        dcCustomerNumber,
    } = worker;

    const { value, setCanEdit } = props;

    const [emailProp] = useState(email ? email : "");
    const [emailValid] = useState(verifyEmail(emailProp));
    const [emailEditable] = useState(false);
    const [emailValidStatus] = useState("");

    const dispatch = useDispatch();
    const { classes } = props;
    const controlPadding = {
        leftColumn: { paddingRight: isMobile ? 0 : 4, textAlign: "left" },
        rightColumn: { paddingLeft: isMobile ? 0 : 4 },
    };

    const TabPanel = props => {
        const { children, value, index, height, ...other } = props;
        return (
            <div
                role="tabpanel"
                style={{ height: height ? height : "100%", paddingTop: 20 }}
                hidden={value !== index}
                id={`scrollable-auto-tabpanel-${index}`}
                aria-labelledby={`scrollable-auto-tab-${index}`}
                {...other}>
                {value === index && children}
            </div>
        );
    };

    // const TextMaskCustom = props => {
    //   const { inputRef, ...other } = props;
    //
    //   return (
    //     <MaskedInput
    //       {...other}
    //       // eslint-disable-next-line react/no-find-dom-node
    //       ref={ref => inputRef(ReactDOM.findDOMNode(ref))}
    //       mask={[/[A-Z]/i, /\d/, /[A-Z]/i, " ", /\d/, /[A-Z]/i, /\d/]}
    //       placeholderChar={"\u2000"}
    //     />
    //   );
    // };
    //
    // const handleSetSinEditable = () => {
    //   // checkSin(s);
    //   setSinEditable(!sinEditable);
    // };
    //
    // const handleSetEmailEditable = () => {
    //   dispatch(setUpdateWorkerData("email", null));
    //   // setEmailEditable(!emailEditable);
    //   setEmailProp(email);
    // };

    const onSubmit = data => {
        dispatch(WorkerDetailActions.saveWorkerProfile(data));
        setCanEdit();
    };

    const handleLogoFile = useCallback((file, isProfile) => {
        const item = new Blob([file], { type: file.type });
        item.name = `logo_${file.name}`;
        dispatch(WorkerDetailActions.uploadWorkerProfileImage(item, isProfile));
    }, [dispatch]);

    const RenderUploadTab = () => (
        <Grid item xs={12} style={{ textAlign: "center" }}>
            <Typography variant="h6" align="left" style={{ paddingLeft: 15, textAlign: "left" }}>
                Profile Photo
            </Typography>
            <img src={profileImage} alt="Profile picture" onError={(e) => e.target.src = placeholder} style={{ margin: 10, width: "60%" }} width={"80%"} />
            <Button color="secondary" variant="outlined" component="label">
                Update Profile Photo
                <input type="file" hidden onChange={files => handleLogoFile(files.target.files[0], true)} />
            </Button>
        </Grid>
    );
    const RenderDocumentUploadTab = () => (
        <Grid item xs={12} style={{ textAlign: "center" }}>
            <Typography variant="h6" align="left" style={{ paddingLeft: 15, textAlign: "left" }}>
                Identity Document
            </Typography>
            <img src={identificationImage} alt="Profile picture" onError={(e) => e.target.src = placeholder} style={{ margin: 10, width: "60%" }} width={"80%"} />
            <Button color="secondary" variant="outlined" component="label">
                Update Document
                <input type="file" hidden onChange={files => handleLogoFile(files.target.files[0], false)} />
            </Button>
        </Grid>
    );
    useEffect(() => {
        profileImage = worker && worker.signedProfileImageUrl ? worker.signedProfileImageUrl : "";
    }, [profileImage]);

    useEffect(() => {
        identificationImage = worker && worker.signedIdentificationImageUrl ? worker.signedIdentificationImageUrl : "";
    }, [identificationImage]);

    const RenderProfileEditForm = () => (
        <Grid
            container
            item
            xs={12}
            alignItems="flex-start"
            style={{ paddingLeft: 0, paddingRight: 0 }}>
            <Formik
                initialValues={{
                    email: email || "",
                    dcCustomerNumber: dcCustomerNumber || '',
                    firstName: firstName || '',
                    lastName: lastName || '',
                    postalCode: postalCode || '',
                    phone: phone || '',
                    // sin: sin || '',
                    street: street || '',
                    city: city || '',
                    region: region || '',
                    country: country || '',
                }}
                onSubmit={(values, actions) => {
                    onSubmit(values);

                    actions.setSubmitting(false);
                }}
                validationSchema={yup.object().shape({
                    email: yup.string().email().required("Required"),
                    firstName: yup.string().required("Required"),
                    lastName: yup.string().required("Required"),
                    postalCode: yup.string().required("Required").max(6, 'Must be exactly 6 character').min(6, 'Must be exactly 6 character'),
                    phone: yup.string().required("Required"),
                    // sin: yup.string().required("Required"),
                    street: yup.string().required("Required"),
                    city: yup.string().required("Required"),
                    region: yup.string().required("Required"),
                    country: yup.string().required("Required"),
                })}>
                {props => {
                    const {
                        values,
                        touched,
                        errors,
                        dirty,
                        isSubmitting,
                        handleChange,
                        handleBlur,
                        handleReset,
                    } = props;
                    //   handleFormState(touched);
                    return (
                        <Form>
                            <Grid
                                item
                                container
                                direction="row"
                                xs={12}
                                style={{ paddingLeft: 0, paddingRight: 0, width: "100%", textAlign: "left" }}>
                                <Grid
                                    item
                                    container
                                    xs={12}
                                    md={8}
                                    style={{ paddingLeft: 0, paddingRight: 0, textAlign: "left" }}>
                                    <Grid item xs={12} md={6} style={controlPadding.leftColumn}>
                                        <TextField

                                            label="FirstName"
                                            name="firstName"
                                            className={classes.textField}
                                            value={values.firstName}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            fullWidth
                                            error={errors.firstName && touched.firstName}
                                            helperText={errors.firstName && touched.firstName && errors.firstName}
                                            margin="normal"
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6} style={controlPadding.rightColumn}>
                                        <TextField
                                            error={errors.lastName && touched.lastName}

                                            label="LastName"
                                            name="lastName"
                                            className={classes.textField}
                                            value={values.lastName}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            fullWidth
                                            helperText={errors.lastName && touched.lastName && errors.lastName}
                                            margin="normal"
                                        />
                                    </Grid>

                                    <Grid
                                        container
                                        item
                                        xs={12}
                                        md={6}
                                        direction="column"
                                        style={controlPadding.leftColumn}>
                                        <TextField
                                            id="email"
                                            name="email"

                                            InputLabelProps={{ shrink: true, style: { width: "100%" } }}
                                            className={classes.textField}
                                            value={values.email}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            fullWidth
                                            error={errors.email && touched.email}
                                            helperText={errors.email && touched.email && errors.email}
                                            margin="normal"
                                            label={
                                                <div style={{ width: "100%", fontSize: 14 }}>
                                                    <div style={{ float: "left", width: "80%" }}>Email Address</div>
                                                    <div
                                                        style={{
                                                            width: "20%",
                                                            textAlign: "right",
                                                            float: "right",
                                                            color: emailValid ? "green" : "red",
                                                            paddingRight: 3,
                                                        }}>
                                                        {emailValidStatus}
                                                    </div>
                                                </div>
                                            }
                                            type="email"
                                            disabled={!emailEditable}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            // onClick={() =>
                                                            //   emailEditable
                                                            //     ? handleSetEmailEditable()
                                                            //     : handleSetEmailEditable()
                                                            // }
                                                            aria-label="toggle password visibility"
                                                            size="large">
                                                            {emailEditable ? <LockOpen style={{ color: "green" }} /> : <Lock />}
                                                        </IconButton>
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6} style={controlPadding.rightColumn}>
                                        <TextField
                                            name="phone"
                                            label="Phone"

                                            error={errors.phone && touched.phone}
                                            className={classes.textField}
                                            value={values.phone}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            fullWidth
                                            helperText={
                                                errors.phone &&
                                                touched.phone &&
                                                errors.phone
                                            }
                                            margin="normal"
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6} style={controlPadding.leftColumn}>
                                        <TextField
                                            name="dcCustomerNumber"

                                            label="DC Number"
                                            error={errors.dcCustomerNumber && touched.dcCustomerNumber}
                                            className={classes.textField}
                                            value={values.dcCustomerNumber}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            fullWidth
                                            helperText={
                                                errors.dcCustomerNumber &&
                                                touched.dcCustomerNumber &&
                                                errors.dcCustomerNumber
                                            }
                                            margin="normal"
                                        />
                                    </Grid>

                                    <Grid
                                        container
                                        item
                                        xs={12}
                                        md={6}
                                        direction="column"
                                        style={controlPadding.rightColumn}>
                                        {/*<TextField
                            id="sin"
                            name="sin"
                            InputLabelProps={{ shrink: true, style: { width: "100%" } }}
                            className={classes.textField}
                            value={values.sin}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            fullWidth
                            error={errors.sin && touched.sin}
                            helperText={errors.sin && touched.sin && errors.sin}
                            margin="normal"
                            label={
                                <div style={{ width: "100%", fontSize: 14 }}>
                                    <div style={{ float: "left", width: "80%" }}>Social Insurance Number (SIN)</div>
                                    <div
                                        style={{
                                            width: "20%",
                                            textAlign: "right",
                                            float: "right",
                                            color: sinValid ? "green" : "red",
                                            paddingRight: 3,
                                        }}>
                                        {sinValidStatus}
                                    </div>
                                </div>
                            }
                            type="text"
                            // disabled={!sinEditable}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={() =>
                                                sinEditable
                                                    ? handleSetSinEditable()
                                                    : handleSetSinEditable()
                                            }
                                        >
                                            {sinEditable ? <LockOpen style={{ color: "green" }} /> : <Lock />}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />*/}
                                        {/*         <NumberFormat
                        id="sin"
                        label={
                          <div style={{ width: "100%", fontSize: 14 }}>
                            <div style={{ float: "left", width: "80%" }}>
                              Social Insurance Number (SIN)
                            </div>
                            <div
                              style={{
                                width: "20%",
                                textAlign: "right",
                                float: "right",
                                color: sinValid ? "green" : "red",
                                paddingRight: 3,
                              }}>
                              {sinValidStatus}
                            </div>
                          </div>
                        }
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={() =>
                                  sinEditable
                                    ? handleSetSinEditable()
                                    : handleSetSinEditable(sinProp)
                                }>
                                {sinEditable ? <LockOpen style={{ color: "green" }} /> : <Lock />}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                        InputLabelProps={{ shrink: true, style: { width: "100%" } }}
                        name="sin"
                        className={classes.textField}
                        value={values.sin}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        fullWidth
                        error={errors.sin && touched.sin}
                        helperText={errors.sin && touched.sin && errors.sin}
                        margin="normal"
                        inputProps={{ min: 1 }}
                        customInput={props => (
                          <TextField
                            {...props}
                            value={values.sin}
                            error={!sinValid}
                            disabled={!sinEditable}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={() =>
                                      sinEditable
                                        ? handleSetSinEditable()
                                        : handleSetSinEditable(sinProp)
                                    }>
                                    {sinEditable ? (
                                      <LockOpen style={{ color: "green" }} />
                                    ) : (
                                      <Lock />
                                    )}
                                  </IconButton>
                                </InputAdornment>
                              ),
                            }}
                          />
                        )}
                        format="###-###-###"
                        mask=""
                      />*/}
                                    </Grid>
                                    <Grid item xs={12} md={6} style={controlPadding.leftColumn}>
                                        <TextField
                                            name="street"
                                            label="Street"
                                            error={errors.street && touched.street}
                                            className={classes.textField}

                                            value={values.street}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            fullWidth
                                            helperText={errors.street && touched.street && errors.street}
                                            margin="normal"
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6} style={controlPadding.rightColumn}>
                                        <TextField
                                            id="city"
                                            name="city"
                                            label="City"

                                            className={classes.textField}
                                            value={values.city}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            fullWidth
                                            error={errors.city && touched.city}
                                            helperText={errors.city && touched.city && errors.city}
                                            margin="normal"
                                        />
                                    </Grid>
                                    <Grid container item xs={12}>
                                        <Grid item xs={12} md={4} style={controlPadding.leftColumn}>
                                            <TextField
                                                select
                                                id="region"
                                                label="Region"
                                                name="region"

                                                className={classes.textField}
                                                value={values.region}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                fullWidth
                                                margin="normal"
                                                error={errors.region && touched.region}
                                                helperText={errors.region && touched.region && errors.region}>
                                                <MenuItem value="BC">British Columbia</MenuItem>
                                                <MenuItem value="AB">Alberta</MenuItem>
                                                <MenuItem value="ON">Ontario</MenuItem>
                                            </TextField>
                                        </Grid>
                                        <Grid
                                            item
                                            xs={12}
                                            md={4}
                                            style={{ ...controlPadding.rightColumn, ...controlPadding.leftColumn }}>
                                            <TextField
                                                id="postalCode"

                                                label="Postal Code"
                                                error={errors.postalCode && touched.postalCode}
                                                className={classes.textField}
                                                value={values.postalCode}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                fullWidth
                                                helperText={errors.postalCode && touched.postalCode && errors.postalCode}
                                                margin="normal"
                                            // InputProps={{
                                            //   inputComponent: TextMaskCustom,
                                            // }}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={4} style={controlPadding.rightColumn}>
                                            <TextField
                                                select
                                                id="country"
                                                name="country"
                                                label="Country"

                                                className={classes.textField}
                                                value={values.country}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                fullWidth
                                                margin="normal"
                                                error={errors.country && touched.country}
                                                helperText={errors.country && touched.country && errors.country}>
                                                <MenuItem value="Canada">Canada</MenuItem>
                                            </TextField>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} md={4} style={controlPadding.rightColumn}>
                                    <RenderUploadTab />
                                    <br />
                                    {/* <RenderDocumentUploadTab /> */}
                                </Grid>
                            </Grid>
                            <DialogActions className={classes.footer}>
                                <Button
                                    type="button"
                                    className="outline"
                                    onClick={handleReset}
                                    disabled={!dirty || isSubmitting}>
                                    Reset
                                </Button>
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    type="submit"
                                // disabled={!_.isEmpty(errors)}
                                >
                                    Update Profile
                                </Button>
                            </DialogActions>
                        </Form>
                    );
                }}
            </Formik>
        </Grid>
    );

    return (
        <TabPanel value={value} index={0}>
            <RenderProfileEditForm />
        </TabPanel>
    );
}
export default withStyles(styles)(WorkerProfileTab);
