import { gql } from "@apollo/client";

export default gql`
  mutation createEmployer($data: CreateEmployerInput!) {
    createEmployer(data: $data) {
      pk
      createdAt
      updatedAt
      createdAtUtc
      #   updatedAtUtc
      isDeleted
      id
      email
      firstName
      lastName
      isEmailVerified
      billingType
      consent
      street
      city
      region
      postalCode
      country
      authId
      identitiesProvider
      identitiesUserId
      identitiesIsSocial
      identitiesConnection
      companyName
      websiteUrl
      description
      cardId
      businessPhone
      mobilePhone
      title
      isActive
      stripeId
      isTestAccount
      #quickbooksId
      isApproved
      approvedAt
      approvedAtUtc
      profileImageUrl
      logoImageUrl
      payRate
      billingRate
      isMassCheckinEnabled
      massCheckinBase32
      massCheckinOTPAuthUrl
      isAutoConfirmEnabled
      isDailySummaryEmailEnabled
      allowJobWorkerDeletion
      parentId
      allowPreferredOnly
      jobCount
      fullName
    }
  }
`;
