// HelperMessage
import React from "react";
import PropTypes from "prop-types";
import withStyles from '@mui/styles/withStyles';
import ChevronRight from "@mui/icons-material/ChevronRight";
import Button from "components/Button/Button";

const styles = theme => ({
  footer: {
    position: "fixed",
    bottom: theme.spacing(4),
    right: theme.spacing(4),
    zIndex: 200,
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
  },
  button: {
    marginTop: "8px",
  },
});

function SummaryFooter({ canSubmitNewWorkerForm, registerWorker, classes }) {
  return (
    <div className={classes.footer}>
      <Button
        className={classes.button}
        icon={<ChevronRight />}
        label="Next"
        alignIcon="right"
        onClick={registerWorker}
        disabled={!canSubmitNewWorkerForm}
      />
    </div>
  );
}

SummaryFooter.propTypes = {
  classes: PropTypes.object.isRequired,
  totalCost: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  submitWorkerForm: PropTypes.func,
  canPost: PropTypes.bool,
};

SummaryFooter.defaultProps = {
  totalCost: 0,
  registerWorker: () => {},
  canSubmitNewWorkerForm: false,
};

export default withStyles(styles)(SummaryFooter);
