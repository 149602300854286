import { gql } from "@apollo/client";
import PayrollFields from "../fragments/payroll-fragment";

export default gql`
  mutation uninitializeJobPayroll($data: PayJobInput!) {
    uninitializeJobPayroll(data: $data) {
      ...PayrollFields
    }
  }
  ${PayrollFields}
`;
