import React from "react";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import withStyles from '@mui/styles/withStyles';
import Typography from "@mui/material/Typography";
import EmployerAutocomplete from "components/Autocomplete/EmployerAutocomplete";
import FadeHide from "components/Transitions/FadeHide";
import { formatMoney } from "utils/stringUtils";

const styles = {
  input: { marginBottom: "32px" },
  info: { minHeight: "110px" },
};

function EmployerSection(props) {
  const {
    companyName,
    firstName,
    lastName,
    email,
    mobilePhone,
    street,
    city,
    region,
    billingRate,
    showInfo,
    isEditing,
    selectedEmployer,
    onEmployerChange,
    classes,
  } = props;

  let address = `${street}, ${city}, ${region}`;
  if (!street || !city || !region) {
    address = <i>Missing address</i>;
  }

  return (
    <React.Fragment>
      {!isEditing && (
        <Grid container item xs={12} direction="column" className={classes.input}>
          <EmployerAutocomplete value={selectedEmployer} onChange={onEmployerChange} />
        </Grid>
      )}
      <FadeHide in={showInfo}>
        <Grid container style={{ minHeight: "110px" }}>
          <Grid container item xs={12}>
            <Grid item xs={6}>
              <Typography variant="h5">{companyName}</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography align="right" variant="body1">
                {showInfo ? address : null}
              </Typography>
            </Grid>
          </Grid>
          <Grid container item xs={12}>
            <Grid item xs={6}>
              <Typography variant="body1">{`${firstName} ${lastName}`}</Typography>
              <Typography variant="body1">{email}</Typography>
              <Typography variant="body1">{mobilePhone}</Typography>
            </Grid>
            <Grid item container direction="column" justifyContent="flex-end" alignItems="flex-end" xs={6}>
              <Typography variant="caption" color="textSecondary">
                {showInfo ? "Billable Rate" : null}
              </Typography>
              <Typography variant="h5">{billingRate ? formatMoney(billingRate) : null}</Typography>
            </Grid>
          </Grid>
        </Grid>
      </FadeHide>
    </React.Fragment>
  );
}

EmployerSection.propTypes = {
  classes: PropTypes.object.isRequired,
  companyName: PropTypes.string,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  email: PropTypes.string,
  mobilePhone: PropTypes.string,
  street: PropTypes.string,
  city: PropTypes.string,
  region: PropTypes.string,
  billingRate: PropTypes.number,
  showInfo: PropTypes.bool,
  isEditing: PropTypes.bool,
  selectedEmployer: PropTypes.object,
  onEmployerChange: PropTypes.func,
};

EmployerSection.defaultProps = {
  companyName: "",
  firstName: "",
  lastName: "",
  email: "",
  mobilePhone: "",
  street: "",
  city: "",
  region: "",
  billingRate: 0,
  showInfo: false,
  isEditing: false,
  selectedEmployer: null,
  onEmployerChange: () => {},
};

export default withStyles(styles)(EmployerSection);
