import React from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Dialog from "components/Dialog/Dialog";
import { formatMoney } from "utils/stringUtils";
import { selectors as JobDetailSelectors } from "store/modules/jobDetails";

function DecreasePositionsDialog(props) {
  const { open, onClose, onCancel, onConfirm, value, onChange } = props;

  const intValue = parseInt(value, 10);
  const positionsFilled = useSelector(JobDetailSelectors.getPositionsFilled);
  const desiredPositions = useSelector(JobDetailSelectors.getPeopleNeeded) - intValue;
  const billingRate = useSelector(JobDetailSelectors.getEmployerBillingRate);
  const durationMinsLessBreak = useSelector(JobDetailSelectors.getJobDurationMinsLessBreak);

  const decreaseBilling = (durationMinsLessBreak / 60) * billingRate * intValue;
  const isValidDecrease =
    positionsFilled <= desiredPositions && desiredPositions > 0 && intValue > 0;

  return (
    <Dialog
      open={open}
      title="How many fewer positions?"
      description={null}
      onClose={onClose}
      onConfirm={onConfirm}
      onCancel={onCancel}
      alertType="question"
      icon="exposure_neg_1"
      disabled={!isValidDecrease}>
      <Grid container direction="column" alignItems="center">
        <Typography variant="body1" align="center">
          {value !== ""
            ? `The bill will decrease by ${formatMoney(decreaseBilling)}`
            : `This action will affect billing.`}
        </Typography>
        <TextField
          placeholder="Decrease by..."
          helperText="You can only remove open positions."
          value={value}
          onChange={onChange}
          type="number"
          margin="normal"
          inputProps={{ min: 1 }}
        />
      </Grid>
    </Dialog>
  );
}

DecreasePositionsDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
};

export default DecreasePositionsDialog;
