import React, { useEffect, useState } from "react";
import { Editor } from "react-draft-wysiwyg";
import { makeStyles } from "@mui/styles";
import { EditorState, convertToRaw, convertFromHTML, ContentState, convertFromRaw } from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import Button from "components/Button/Button";
import { actions as FaqActions, selectors as FaqSelectors } from "store/modules/faqs";
import { useDispatch, useSelector } from "react-redux";

const useStyle = makeStyles({
  saveButton: {
    marginTop: 24,
    padding: 0,
    width: "fit-content",
  },
});

const TermsOfService = () => {
  const classes = useStyle();
  const dispatch = useDispatch();
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [htmlString, setHtmlString] = useState("");
  const termsOfService = useSelector(FaqSelectors.getTermsOfService);
  const isLoading = useSelector(FaqSelectors.getIsLoading);

  useEffect(() => {
    dispatch(FaqActions.fetchTermsOfService());
  }, []);

  useEffect(
    () => {
      setEditorState(
        EditorState.createWithContent(
          ContentState.createFromBlockArray(convertFromHTML(termsOfService)),
        ),
      );
    },
    [termsOfService],
  );

  const onEditorStateChange = e => {
    setEditorState(e);
    setHtmlString(draftToHtml(convertToRaw(e.getCurrentContent())));
  };

  const saveChange = () => {
    const request = draftToHtml(convertToRaw(editorState.getCurrentContent()));
    dispatch(FaqActions.updateTermsAndConditions(request));
  };

  const onHtmlChange = html => {
    setHtmlString(html);
    setEditorState(
      EditorState.createWithContent(ContentState.createFromBlockArray(convertFromHTML(html))),
    );
  };

  return (
    <div>
      <Editor
        wrapperStyle={{
          border: "1px solid #d5dde5",
          background: "#FFF",
        }}
        editorState={editorState}
        toolbarClassName="toolbarClassName"
        wrapperClassName="wrapperClassName"
        editorClassName="editorClassName"
        onEditorStateChange={onEditorStateChange}
      />
      <Button
        className={classes.saveButton}
        label="Save Change"
        alignIcon="left"
        onClick={saveChange}
        isLoading={isLoading}
      />
      {/* <textarea
          onChange={e => onHtmlChange(e.target.value)}
          value={htmlString}
        /> */}
    </div>
  );
};

export default TermsOfService;
