import { gql } from "@apollo/client";

export default gql`
  mutation editWorker($data: EditWorkerInput!) {
    editWorker(data: $data) {
      id
      createdAt
      updatedAt
      createdAtUtc
      #   updatedAtUtc
      isDeleted
      firstName
      lastName
      email
      isEmailVerified
      street
      city
      region
      postalCode
      country
      phone
      sin
      identitiesUserId
      profileImageUrl
      identificationImageUrl
      consent
      isActive
      isApproved
      isSuspended
      approvedAt
      noShowCount
    }
  }
`;
