import { gql } from "@apollo/client";

export const getQbTermsQuery = gql`
  query getQbTerms {
    getQbTerms
  }
`;
export const validateQbCustomerIdQuery = gql`
  query validateQbCustomerId($qbId: String!) {
    validateQbCustomerId(qbId: $qbId)
  }
`;
export const getQbPaymentMethodsQuery = gql`
  query getQbPaymentMethods {
    getQbPaymentMethods
  }
`;
export const qbCompanyInfoQuery = gql`
  query companyInfo {
    companyInfo {
      id
      companyName
      LegalName
      phone
      CreateTime
      SupportedLanguages
      Country
      FiscalYearStartMonth
      CompanyStartDate
      email
      SyncToken
      BillWithParent
      CustomerCommunicationAddress {
        Id
        Lat
        Long
        Line1
        Line2
        Line3
        Line4
        CountrySubDivisionCode
        PostalCode
      }
      CompanyAddress {
        Id
        Lat
        Long
        Line1
        Line2
        Line3
        Line4
        CountrySubDivisionCode
        PostalCode
      }
      LegalAddress {
        Id
        Lat
        Long
        Line1
        Line2
        Line3
        Line4
        CountrySubDivisionCode
        PostalCode
      }
    }
  }
`;
export const qbCustomerQuery = gql`
  query qbCustomer($qbId: String!) {
    qbCustomer(qbId: $qbId) {
      SalesTermRef
      CurrencyRef
      PaymentMethodRef
      id
      displayName
      companyName
      PrintOnCheckName
      FullyQualifiedName
      GivenName
      FamilyName
      contact
      Title
      PrimaryPhone
      AlternatePhone
      Mobile
      phone
      Notes
      WebAddr
      email
      LastUpdatedTime
      CreateTime
      PreferredDeliveryMethod
      SyncToken
      balance
      BalanceWithJobs
      sparse
      Active
      Job
      Taxable
      BillWithParent
      BillingAddress {
        Id
        Lat
        Long
        Line1
        Line2
        Line3
        Line4

        CountrySubDivisionCode
        PostalCode
      }
    }
  }
`;
export const qbInvoiceQuery = gql`
  query qbInvoice($invoiceId: String!) {
    qbInvoice(invoiceId: $invoiceId) {
      id
      InvoiceItems {
        Id
        DetailType
        ItemName
        ItemValue
        Description
        Qty
        UnitPrice
        Amount
      }
      TxnDate
      domain
      PrintStatus
      SalesTermRef
      DocNumber
      CustomerMemo
      total
      TotalTax
      Balance
      Deposit
      ApplyTaxAfterDiscount
      sparse
      DueDate
      LastUpdatedTime
      CreateTime
      CustomerName
      SyncToken
      EmailStatus
      BillEmail
      BillingAddress {
        Id
        Lat
        Long
        Line1
        Line2
        Line3
        Line4
        CountrySubDivisionCode
        PostalCode
      }
    }
  }
`;
export const qbInvoicesQuery = gql`
  query qbInvoices($params: QbQueryArgs!) {
    qbInvoices(params: $params) {
      id
      InvoiceItems {
        Id
        DetailType
        ItemName
        ItemValue
        Description
        Qty
        UnitPrice
        Amount
        # jobId
      }
      TxnDate
      domain
      PrintStatus
      SalesTermRef
      DocNumber
      CustomerMemo
      total
      TotalTax
      Balance
      Deposit
      ApplyTaxAfterDiscount
      sparse
      DueDate
      LastUpdatedTime
      CreateTime
      CustomerName
      SyncToken
      EmailStatus
      BillEmail
      #     BillingAddress {
      #       Id
      #       Lat
      #       Long
      #       Line1
      #       Line2
      #       Line3
      #       Line4
      #     }
    }
  }
`;
export const getQbInvoicePdfQuery = gql`
  query getQbInvoicePdf($invoiceId: String!) {
    getQbInvoicePdf(invoiceId: $invoiceId)
  }
`;
export const reportCustomerBalanceDetailQuery = gql`
  query reportCustomerBalanceDetail($data: JSON!) {
    reportCustomerBalanceDetail(data: $data)
  }
`;
export const employerQbInvoicesQuery = gql`
  query qbInvoices($qbId: String!) {
    qbInvoices(qbId: $qbId) {
      id
      InvoiceItems {
        Id
        DetailType
        ItemName
        ItemValue
        Description
        Qty
        jobId
        UnitPrice
        Amount
        payroll
      }
      TxnDate
      domain
      PrintStatus
      SalesTermRef
      DocNumber
      CustomerMemo
      total
      TotalTax
      Balance
      Deposit
      ApplyTaxAfterDiscount
      sparse
      DueDate
      LastUpdatedTime
      CreateTime
      CustomerName
      qbId
      SyncToken
      EmailStatus
      BillEmail

      BillingAddress {
        Id
        Lat
        Long
        Line1
        Line2
        Line3
        Line4
        CountrySubDivisionCode
        PostalCode
      }
    }
  }
`;
export const sendQbInvoicePdfQuery = gql`
  query sendQbInvoicePdf($sendTo: String!, $invoiceId: String!, $qbId: String!) {
    sendQbInvoicePdf(sendTo: $sendTo, invoiceId: $invoiceId, qbId: $qbId) {
      id
      InvoiceItems {
        Id
        DetailType
        ItemName
        ItemValue
        Description
        Qty
        UnitPrice
        Amount
      }
      TxnDate
      domain
      PrintStatus
      SalesTermRef
      DocNumber
      CustomerMemo
      total
      TotalTax
      Balance
      Deposit
      ApplyTaxAfterDiscount
      sparse
      DueDate
      LastUpdatedTime
      CreateTime
      CustomerName
      SyncToken
      EmailStatus
      BillEmail
    }
  }
`;
export const qbItemsQuery = gql`
  query qbItems {
    qbItems
  }
`;
export const qbPreferencesQuery = gql`
  query qbPreferences {
    qbPreferences
  }
`;
export const qbEmployerPaymentsQuery = gql`
  query qbCustomerPayments($qbId: String!) {
    qbCustomerPayments(qbId: $qbId)
  }
`;

export const createTimesheetQuery = gql`
  query createTimesheetStream($invoiceId: Int!, $attach: Boolean!) {
    createTimesheetStream(invoiceId: $invoiceId, attach: $attach)
  }
`;

export const getQbAttachablesQuery = gql`
  query getQbAttachables($invoiceId: String!) {
    getQbAttachables(invoiceId: $invoiceId)
  }
`;

export const getEmployerBillingRatesQuery = gql`
  query employerBillingRates($employerId: String!) {
    employerBillingRates(employerId: $employerId) {
      id
      createdAt
      updatedAt
      name
      order
      isActive
      baseRate
      baseBillingRate
    }
  }
`;

export default qbInvoicesQuery;
