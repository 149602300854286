import { gql } from "@apollo/client";
import PaymentFields from "../fragments/payment-fragment";

export default gql`
  query getPaymentBatches(
    $first: Int
    $last: Int
    $after: String
    $before: String
    $like: [FilterInput!]
    $order: [OrderInput!]
  ) {
    paymentBatches(
      first: $first
      last: $last
      after: $after
      before: $before
      like: $like
      order: $order
      excludeQueued: true
    ) {
      totalCount
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        node {
          id
          createdAt
          sentAt
          responseAt
          paymentProviderId
          requestFileUrl
          responseFileUrl
          totalCount
          unresolvedCount
          resolvedCount
          totalAmount
          paymentBatchStatus
          payments {
            ...PaymentFields
          }
          outstandingPayments {
            ...PaymentFields
          }
          unresolvedPayments {
            ...PaymentFields
          }
          resolvedPayments {
            ...PaymentFields
          }
          acceptedPayments {
            ...PaymentFields
          }
          paymentProvider {
            id
            code
            description
            isActive
            isDefault
          }
        }
      }
    }
    queuedPaymentBatch {
      id
      createdAt
      totalCount
      totalAmount
      paymentProvider {
        description
      }
      payments {
        id
        email
        amount
        workerId
        worker {
          fullName
        }
        payroll {
          position {
            jobId
          }
        }
      }
    }
  }
  ${PaymentFields}
`;
