import React, { useState } from "react";
import MainContent from "components/Containers/MainContent";
import SubNav from "../../../components/Navigation/SubNav";
import SubNavTab from "../../../components/Navigation/SubNavTab";
import { Assessment } from "@mui/icons-material";
import DCTransactionTable from "./components/DCTransactionTable";
import DCTransactionFilter from "./components/DCTransactionFilter";

function DCBankReportView() {
    const [tavValue, setTabValue] = useState(0);
    const handleTabChange = (event, value) => {
        setTabValue(value);
    }
    return (
        <React.Fragment>
            <SubNav
                value={tavValue}
                onChange={handleTabChange}
                tabs={[
                    <SubNavTab key="DCBankTransactions" label="DC Bank Transactions" icon={<Assessment />} count={null} />,
                ]}
            />
            <MainContent>
                <DCTransactionFilter />
                <DCTransactionTable />
            </MainContent>
        </React.Fragment>
    );
}

export default DCBankReportView;
