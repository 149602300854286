export const mapApiKey = process.env.REACT_APP_MAP_KEY;

export const formatMapUrl = (street, city, region, size = "900x200") => {
  const center = `${street},${city},${region}`;
  const opts = `&zoom=17&scale=1&size=${size}&maptype=roadmap`;

  return `https://maps.googleapis.com/maps/api/staticmap?center=${center}${opts}%7C${center}&key=${mapApiKey}`;
};

export const formatMapUrlByLatLong = (lat, lon, size = "480x300") => {
  const center = `${lat},${lon}`;
  const opts = `&zoom=12&scale=1&size=${size}&maptype=hybrid`;

  return `https://maps.googleapis.com/maps/api/staticmap?center=${center}${opts}%7C${center}&key=${mapApiKey}&markers=color:black%7Clabel:G%7C${lat},${lon}`;
};
