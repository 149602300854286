/* eslint-disable react/jsx-key */
/* eslint-disable react/jsx-no-undef */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */

import React, { useState } from "react";
import moment from "moment-timezone";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { InfoOutlined, Star } from "@mui/icons-material";
import AttachFile from "@mui/icons-material/AttachFile";
import { invoiceCardStyles } from "views/Finance/helpers";
import InvoiceCardActions from "./InvoiceCardActions/index";
import Button from "components/Button/Button";

function InvoiceCard(props) {
  const { invoices, hasAdjustments, classes, employer, week, getTableData, handleOpenDeletedDialog, deletedInvoices } = props;

  const [openModal, setOpenModal] = useState(false);
  const [openPdf, setOpenPdf] = useState(false);
  const [, setPdf] = React.useState(null);

  const handleShowModal = invoice => {
    setOpenModal(!openModal);
  };

  const handleShowPdf = invoice => {
    setOpenPdf(!openPdf);
  };

  const handleSavePdf = () => {
    setPdf();
  };

  const isActive = hasAdjustments => (
    <span
      style={{
        color: "green",
        fontWeight: 600,
        fontSize: 12,
        fontFamily: "Roboto",
        textAlign: "right",
        float: "right",
      }}>
      <span>
        <Star style={{ fontSize: 12 }} />
        <span>Active</span>
      </span>
      <br />
      {hasAdjustments && (
        <span
          style={{
            color: "blue",
            fontWeight: 600,
            fontSize: 12,
            fontFamily: "Roboto",
            float: "right",
          }}>
          <InfoOutlined style={{ fontSize: 12, marginRight: 5 }} />
          <span>Has Adjustments</span>
        </span>
      )}
    </span>
  );

  const InvoiceCardPlaceHolder = () => (
    // <Grow in={true} timeout={2000}>
    <Grid item xs={12} className={classes.placeholderContainer} elevation={0}>
      <Grid item xs={12} className={classes.placeholderCard}>
        <Card className={classes.cardRoot} elevation={0}>
          <CardHeader
            elevation={0} // className={classes.cardRoot.root}
            titleTypographyProps={{ className: classes.title }}
            // subheaderTypographyProps={{ className: classes.subHeader }}
            avatar={<AttachFile className={classes.avatar} />}
            title={`No Timesheets Yet`}
          />
        </Card>
      </Grid>
    </Grid>
    // </Grow>
  );
  const InvoiceCard = ({ invoice, isLast, getTableData, index }) => {
    const { createdAt, id } = invoice;

    return (
      <Grid item xs={12} md={4} className={classes.container} elevation={5}>
        <Grid item xs={12} className={classes.card}>
          <Card className={classes.cardRoot}>
            <CardHeader
              className={classes.cardRoot.root}
              titleTypographyProps={{ className: classes.title }}
              subheaderTypographyProps={{ className: classes.subHeader }}
              avatar={<AttachFile className={classes.avatar} />}
              action={
                invoice.qbInvoiceId ? (
                  <div style={{ textAlign: "right", justify: "right", alignItems: "flex-end" }}>
                    <InvoiceCardActions
                      invoice={invoice}
                      getTableData={getTableData}
                      week={week}
                      employer={employer}
                      viewAction={handleShowModal}
                      viewPdfAction={handleShowPdf}
                      deleteAction={handleShowModal}
                      savePdfAction={handleSavePdf}
                      setDefaultAction={handleShowModal}
                    />
                  </div>
                ) : (
                  <div style={{ textAlign: "right", justify: "right", alignItems: "flex-end" }}>
                    <InvoiceCardActions
                      invoice={invoice}
                      getTableData={getTableData}
                      week={week}
                      employer={employer}
                      viewAction={handleShowModal}
                      viewPdfAction={handleShowPdf}
                      deleteAction={handleShowModal}
                      savePdfAction={handleSavePdf}
                      setDefaultAction={handleShowModal}
                    />
                  </div>
                )
              }
              title={
                <React.Fragment>
                  <Typography style={{ fontWeight: 600, fontSize: 14, fontFamily: "Roboto" }}>
                    {`${employer.name} - Week${week}${index > 0 ? ' - A' + index : ''}`}
                    {!invoice.qbInvoiceId && isLast === id ? isActive(hasAdjustments) : ""}

                    {invoice.qbInvoiceId ? (
                      <span
                        style={{
                          color: "green",
                          fontWeight: 600,
                          fontSize: 12,
                          fontFamily: "Roboto",
                          textAlign: "right",
                          float: "right",
                        }}>
                        <span>
                          <Star style={{ fontSize: 12 }} />
                          <span>Completed</span>
                        </span>
                      </span>
                    ) : (
                      ""
                    )}
                  </Typography>
                </React.Fragment>
              }
              subheader={
                <React.Fragment>
                  <Typography
                    style={{
                      color: "#aebecd",
                      fontWeight: 500,
                      fontSize: 12,
                      fontFamily: "Roboto",
                    }}>{`Created ${moment(createdAt).format(
                    "MMM DD, YYYY h:mm a  z"
                  )}`}</Typography>
                </React.Fragment>
              }
            />
          </Card>
        </Grid>
      </Grid>
    );
  };
  return (
    <React.Fragment>
      <Grid container item justifyContent="flex-start" xs={12} className={classes.wrapper}>
        <Typography
          style={{ color: "#aebecd", fontWeight: 500, fontSize: 20, fontFamily: "Roboto", display: 'flex' }}>
          Generated Timesheets
          {deletedInvoices.length > 0 && (
            <Button
              label="Deleted Timesheet"
              size="small"
              onClick={handleOpenDeletedDialog}
              color="error"
              buttonClassName={classes.deletedButton}
            />
          )}
        </Typography>
      </Grid>
      <Grid container justifyContent="flex-start">
        {invoices && invoices.length ? (
          invoices.map((invoice, i) => (
            <InvoiceCard
              key={i}
              index={i}
              getTableData={getTableData}
              invoice={invoice}
              isLast={invoices.last().id}
            />
          ))
        ) : (
          <InvoiceCardPlaceHolder />
        )}
      </Grid>
    </React.Fragment>
  );
}

export default invoiceCardStyles(InvoiceCard);
