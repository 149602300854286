import React from "react";
import { connect } from "react-redux";
import Grid from "@mui/material/Grid";
import withStyles from '@mui/styles/withStyles';
import MainContent from "components/Containers/MainContent";
import { validateSIN } from "utils/stringUtils";
import { actions as WorkerActions, selectors as WorkerSelectors } from "store/modules/workers";
import AccountSection from "./components/AccountSection";
import Stage from "./components/Stage";
import SummaryFooter from "./components/SummaryFooter";

const styles = {};

class PostWorkerView extends React.Component {
  constructor(props) {
    super(props);
    this.state = { showPassword: false };
  }

  updateFormData = event => {
    this.props.setNewWorkerData(event.target.id, event.target.value);
  };

  checkSin = event => {
    let sin = event.target.value.replace(/\D/g, "");
    if (sin.length === 9 && validateSIN(sin)) this.props.workerCheckSin(sin).then(() => {});
  };
  handleRegisterWorker = () => {
    this.props.registerWorker().then(response => {
      this.props.history.push(`/workers/${response.registerWorker.id}`);
    });
  };
  render() {
    return (
      <MainContent>
        <Grid container>
          <Stage
            title="Account Setup"
            description="Input the worker’s name, email and a temporary password."
            contentSize={8}>
            <AccountSection
              {...this.props.addWorkerData}
              emailIsValid={this.props.emailIsValid}
              emailIsAvailable={this.props.emailIsAvailable}
              checkPassword={this.props.checkPassword}
              setFirstName={this.updateFormData}
              setEmail={this.props.setEmail}
              sinIsValid={this.props.sinIsValid}
              setSin={this.props.setSin}
              checkSin={this.checkSin}
              onAddressChange={this.props.onAddressChange}
              setPhone={this.updateFormData}
              selectedLocation={this.props.selectedLocation}
            />
          </Stage>
          <SummaryFooter
            // registerWorker={this.props.registerWorker}
            registerWorker={this.handleRegisterWorker}
            canSubmitNewWorkerForm={this.props.canSubmitNewWorkerForm}
          />
        </Grid>
      </MainContent>
    );
  }
}
const mapState = state => {
  const {
    sinIsValid,
    emailIsAvailable,
    emailIsValid,
    sinIsTaken,
    selectedLocation,
    addWorkerData,
  } = state.workers;

  return {
    addWorkerData: addWorkerData,
    sinIsValid: sinIsValid,
    emailIsValid: emailIsValid,
    selectedLocation: selectedLocation,
    emailIsAvailable: emailIsAvailable,
    sinIsTaken: sinIsTaken,
    canSubmitNewWorkerForm: WorkerSelectors.canSubmitForm(state),
  };
};

const mapActions = dispatch => ({
  setNewWorkerData: (id, value) => dispatch(WorkerActions.setNewWorkerData(id, value)),
  workerCheckSin: sin => dispatch(WorkerActions.workerCheckSin(sin)),
  setEmail: event => dispatch(WorkerActions.setEmail(event.target.value)),
  setSin: event => dispatch(WorkerActions.setSin(event.target.value)),
  validateForm: event => dispatch(WorkerActions.setSin(event.target.value)),
  registerWorker: () => dispatch(WorkerActions.registerWorker()),
  onAddressChange: (selectedLocation, location) => {
    dispatch(WorkerActions.setLocation(selectedLocation, location));
  },
});

export default connect(mapState, mapActions)(withStyles(styles)(PostWorkerView));
