/* eslint-disable react/prop-types */

import React from "react";
import { connect } from "react-redux";
import IconButton from "@mui/material/IconButton";
import Close from "@mui/icons-material/Close";
import { withSnackbar } from "notistack";
import { bindActionCreators } from "redux";
import { actions as MetaActions } from "store/modules/meta";

class Notifier extends React.Component {
    displayed = [];

    storeDisplayed = id => {
        this.displayed = [...this.displayed, id];
    };

    shouldComponentUpdate({ notifications: newSnacks = [] }) {
        const { notifications: currentSnacks } = this.props;
        let notExists = false;
        for (let i = 0; i < newSnacks.length; i += 1) {
            if (notExists) continue;
            notExists = notExists || !currentSnacks.filter(({ key }) => newSnacks[i].key === key).length;
        }
        return notExists;
    }

    componentDidUpdate() {
        const { notifications = [] } = this.props;

        notifications.forEach(notification => {
            // Do nothing if snackbar is already displayed
            if (this.displayed.includes(notification.key)) return;
            // Display snackbar using notistack
            this.props.enqueueSnackbar(notification.message, {
                action: key => (
                    <IconButton style={{ color: "white" }} onClick={() => this.props.closeSnackbar(key)} size="large">
                        <Close fontSize="small" />
                    </IconButton>
                ),
                ...notification.options,
            });
            // Keep track of snackbars that we've displayed
            this.storeDisplayed(notification.key);
            // Dispatch action to remove snackbar from redux store
            this.props.removeSnackbar(notification.key);
        });
    }

    render() {
        return null;
    }
}

const mapStateToProps = state => ({
    notifications: state.meta.notifications.app,
});

const mapDispatchToProps = dispatch =>
    bindActionCreators({ removeSnackbar: MetaActions.removeSnackbar }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(withSnackbar(Notifier));
