import { gql } from "@apollo/client";

export default gql`
  query importedPrivateJobById($id: String!){
    importedPrivateJobById(id: $id){
      id
      employerId
      jobDescription
      typeOfWork
      unit
      address
      city
      country
      province
      postalCode
      payRate
      billingRate
      isBreakPaid
      breakMins
      peopleNeeded
      startDate
      endDate
      startTime
      endTime
      dayOfWeekStart
      dayOfWeekEnd
      tags
      createdAt
    }
  }
`;
