import React from "react";
import { AdminPermissions } from "helpers/constants/permissions";
import Can from "components/Authorization/Can";
import AdminTable from "./components/AdminTable";

function AdminView() {
  return (
    <React.Fragment>
      <Can perform={AdminPermissions.read.list.value}>
        {allowed => {
          if (!allowed) {
            return null;
          }
          return <AdminTable />;
        }}
      </Can>
    </React.Fragment>
  );
}

export default AdminView;
