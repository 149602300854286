import * as pagination from "utils/graphql-pagination";
import { paginationSettings } from "./constants";
import { setActiveEmployerFilter } from "./actions";
// Loading selectors
export const getLoadingStateFactory = loader => state => {
  return state.reports.loading[loader] || false;
};

export const getIsDCReportLoading = getLoadingStateFactory("dcReport");
export const getIsDCReportDownloadLoading = getLoadingStateFactory("dcReportDownload");
/*
Worker payroll report
*/
export const getWorkerPayrollReportPagingData = state => {
  return {
    paging: state.reports[pagination.getPageInfoAttr(paginationSettings.workerPayrollReport)],
    sort: state.reports.pageSort,
    filter: state.reports.pageFilter,
    params: { },
    pagingVars: pagination.pagingVarsFactory(paginationSettings.workerPayrollReport),
  };
};

export const getWorkerPayrollReportData = state => state.reports.workerPayrollReportData;
export const getWorkerPayrollReportFilter = state => state.reports.workerPayrollReportFilter;

/*
Worker Wise payroll report
*/
export const getWorkerWisePayrollReportPagingData = state => {
  return {
    paging: state.reports[pagination.getPageInfoAttr(paginationSettings.workerWisePayrollReport)],
    sort: state.reports.pageSort,
    filter: state.reports.pageFilter,
    params: { },
    pagingVars: pagination.pagingVarsFactory(paginationSettings.workerWisePayrollReport),
  };
};

export const getWorkerWisePayrollReportData = state => state.reports.workerWisePayrollReportData;
export const getWorkerWisePayrollReportFilter = state => state.reports.workerWisePayrollReportFilter;

export const getWorkerJobReportPagingData = state => {
  return {
    paging: state.reports[pagination.getPageInfoAttr(paginationSettings.workerJobReport)],
    sort: state.reports.pageSort,
    filter: state.reports.pageFilter,
    params: { },
    pagingVars: pagination.pagingVarsFactory(paginationSettings.workerJobReport),
  };
};

export const getWorkerJobReportData = state => state.reports.workerJobReportData;
export const getWorkerJobReportFilter = state => state.reports.workerJobReportFilter;

/*
Active Employer report
*/
export const getActiveEmployerReportPagingData = state => {
  return {
    paging: state.reports[pagination.getPageInfoAttr(paginationSettings.activeEmployerReport)],
    sort: state.reports.pageSort,
    filter: state.reports.pageFilter,
    params: { },
    pagingVars: pagination.pagingVarsFactory(paginationSettings.activeEmployerReport),
  };
};

export const getActiveEmployerReportData = state => state.reports.activeEmployerReportData;
export const getActiveEmployerReportFilter = state => state.reports.activeEmployerReportFilter;

/*
WSBC report
*/
export const getWSBCReportPagingData = state => {
  return {
    paging: state.reports[pagination.getPageInfoAttr(paginationSettings.WSBCReport)],
    sort: state.reports.pageSort,
    filter: state.reports.pageFilter,
    params: { },
    pagingVars: pagination.pagingVarsFactory(paginationSettings.WSBCReport),
  };
};

export const getWSBCReportData = state => state.reports.WSBCReportData;
export const getWSBCReportFilter = state => state.reports.WSBCReportFilter;

/*
Worker job count report
*/
export const getWorkerJobCountReportPagingData = state => {
    return {
        paging: state.reports[pagination.getPageInfoAttr(paginationSettings.workerJobCountReport)],
        sort: state.reports.pageSort,
        filter: state.reports.pageFilter,
        params: { },
        pagingVars: pagination.pagingVarsFactory(paginationSettings.workerJobCountReport),
    };
};

export const getWorkerJobCountReportData = state => state.reports.workerJobCountReportData;
export const getWorkerJobCountReportFilter = state => state.reports.workerJobCountReportFilter;

/*
DC Bank Transaction report
*/
export const getDCBankTransactionReportData = state => state.reports.DCBankTransactionReportData;
export const getDCBankTransactionReportFilter = state => state.reports.DCBankTransactionReportFilter;