import React, { useCallback } from "react";
import { connect, useDispatch } from "react-redux";
import { withRouter } from "react-router-dom";
import _debounce from "lodash/debounce";
import { actions as JobActions, selectors as JobSelectors } from "store/modules/jobs";
import { createActionColumn, getTdPropsV7, sharedColumns, sharedProps, tagsColumn } from "./helpers/tableProps";
import ReactTableCustom from "components/ReactTable";

const TableWrapper = (props) => {
    const dispatch = useDispatch()
    const fetchData = useCallback(({ pageSize, pageIndex, filters, sortBy }) => {
        dispatch(() => props.onSortedChange(sortBy));
        dispatch(() => props.onFilteredChange(filters));
        dispatch(() => props.onPageSizeChange(pageSize));
        dispatch(() => props.onPageChange(pageIndex));
    }, []);
    return (
        <ReactTableCustom
            {...sharedProps}
            fetchData={fetchData}
            defaultFilter={props.pageFilter}
            currentPage={props.currentPage}
            defaultPageSize={props.pageSize}
            data={props.jobs}
            loading={props.isLoading}
            pageCount={props.totalCount}
            defaultSort={props.pageSort}
            columns={[...sharedColumns, tagsColumn, createActionColumn({ canEnable: true })]}
            style={{ marginTop: "16px" }}
            onRowClick={getTdPropsV7}
        />
    )
}
class DraftsTable extends React.Component {
    render() {
        return (
            <TableWrapper {...this.props} />
        );
    }
}

const mapState = state => {
    const { totalCount, pageCount, pageSize, currentPage, isLoading } = JobSelectors.getDraftsPaginationData(
        state
    );
    return {
        jobs: JobSelectors.getListData(state, {
            timeframe: "drafts",
            part: "data",
        }),
        pageFilter: JobSelectors.getListData(state, {
            timeframe: "drafts",
            part: "filter",
        }),
        pageSort: JobSelectors.getListData(state, {
            timeframe: "drafts",
            part: "sort",
        }),
        pageCount,
        pageSize,
        currentPage,
        isLoading,
        totalCount
    };
};
const mapAction = {
    fetchJobs: JobActions.fetchDraftsJobs,
    onPageChange: JobActions.fetchDraftsJobs,
    onSortedChange: JobActions.setDraftsSort,
    onFilteredChange: JobActions.setDraftsFilter,
    onPageSizeChange: JobActions.setDraftsPageSize,
};

export default connect(mapState, mapAction)(withRouter(DraftsTable));
