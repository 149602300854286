import React, { useState } from "react";
import TagsInput from "components/TagsInput";
import withStyles from "@mui/styles/withStyles";
import { Grid, TextField, FormControl, IconButton } from "@mui/material";
import Button from "components/Button/Button";
import Close from "@mui/icons-material/Close";
import { useDispatch } from "react-redux";
import { actions as PostingActions, selectors as PostingSelectors } from "store/modules/posting";
import { actions as MetaActions } from "store/modules/meta";
import FormRow from "views/Employer/PostEmployerView/components/FormRow";
import GearsDropdown from "views/JobTemplate/PostJobTemplateView/components/GearsDropdown";

const styles = {
    selectedSection: {
        '& p': {
            textTransform: 'uppercase'
        },
        '& .MuiChip-root': {
            marginRight: '10px'
        }
    },
    addedSkillList: {
        border: '1px solid #e3e3ec',
        padding: '10px',
        borderRadius: '5px',
        fontSize: '1rem',
        display: 'flex',
        alignItems: 'center',
        position: 'relative',
        marginBottom: '10px',
        '& .content': {
            width: 'calc(100% - 20px)',
        },
        '& .closeButton': {
            position: 'absolute',
            padding: '8px',
            right: '0',
            top: '3px'
        }
    },
    gears: {
        color: '#212a34',
        display: 'block',
        fontSize: '14px',
        paddingBottom: '3px'
    }
};

function GearsAndSkillsSection(props) {

    const {
        classes,
        gears,
        skills
    } = props;

    const dispatch = useDispatch();
    const [skill, setSkills] = useState('');

    const onGearsChange = (data) => {
        dispatch(PostingActions.setGears(data));
    }

    const handleChangeSkill = (e) => {
        setSkills(e.target.value)
    }

    const handleAddSkill = () => {
        const seleted = [...skills]
        if (seleted.indexOf(skill) < 0) {
            if (skill.replace(/^\s+|\s+$/gm, '')) {
                seleted.push(skill)
                addedSkill(seleted)
                setSkills("")
            }
        }
        else {
            dispatch(MetaActions.errorToast(`Skill already added`))
        }
    }

    const handleDeleteSkill = value => {
        const seleted = [...skills]
        seleted.splice(skills.indexOf(value), 1);
        addedSkill(seleted)
    };

    const addedSkill = (data) => {
        dispatch(PostingActions.setSkills(data))
    }

    return (
        <React.Fragment>
            <FormRow style={{ marginBottom: "8px", zIndex: 1051 }}>
                <FormControl fullWidth>
                    <div className={classes.gears}>Gears</div>
                    <GearsDropdown
                        selectedTags={onGearsChange}
                        fullWidth
                        variant="outlined"
                        id="gears"
                        name="gears"
                        placeholder="Add Gear"
                        defaultValue={gears}
                    />
                </FormControl>
            </FormRow>

            <FormRow>
                <Grid container>
                    <Grid item xs={10}>
                        <FormControl fullWidth style={{ marginTop: 32 }}>
                            <TextField
                                id="skill"
                                name="skill"
                                label="Skills"
                                placeholder="Enter skills individually"
                                value={skill}
                                onChange={handleChangeSkill}
                                fullWidth
                                onKeyPress={e => {
                                    if (e.key === "Enter" && e.target.value) {
                                        handleAddSkill();
                                    }
                                }}
                            />
                        </FormControl>
                    </Grid>

                    <Grid item xs={2} style={{ paddingLeft: 5, textAlign: "right" }} >
                        <FormControl fullWidth margin="normal">
                            <Button
                                disabled={!skill}
                                onClick={handleAddSkill}
                                label={"Add"}
                                style={{ padding: "0" }}
                            />
                        </FormControl>
                    </Grid>
                </Grid>
            </FormRow>

            {(skills.length > 0) &&
                <Grid
                    item
                    xs={8}
                    style={{ marginTop: "10px" }}
                >
                    <div className={classes.selectedSection}>
                        {skills &&
                            skills.map((n, index) => (
                                <div key={index} className={classes.addedSkillList}>
                                    <div className="content">{n}</div>
                                    <IconButton
                                        className="closeButton"
                                        color="primary"
                                        onClick={() => handleDeleteSkill(n)}
                                        aria-label="delete"
                                        component="span"
                                    >
                                        <Close />
                                    </IconButton>
                                </div>
                            ))}
                    </div>
                </Grid>
            }
        </React.Fragment>
    )
}

export default withStyles(styles)(GearsAndSkillsSection)
