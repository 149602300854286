import { gql } from "@apollo/client";

export default gql`
  mutation markPaymentResolved($paymentId: Int!) {
    markPaymentResolved(paymentId: $paymentId) {
      id
      # paymentBatch {
      #     ...PaymentBatchFields
      # }
    }
  }
`;
