import React, { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
    IconButton,
    CardHeader,
    Avatar,
    Divider,
    Typography,
    CircularProgress,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Delete } from "@mui/icons-material";
import Button from "components/Button/Button";
import MainContent from "components/Containers/MainContent";
import Dialog from "components/Dialog/Dialog";
import { sharedReactTableProps } from "utils/constant";
import { formatInitials } from "utils/stringUtils";
import { actions as AdminActions, selectors as AdminSelectors } from "store/modules/admins";
import AssignPermission from "./AssignPermission";
import { find } from "lodash";
import Can from "components/Authorization/Can";
import { AdminPermissions } from "helpers/constants/permissions";
import ReactTableCustom from "components/ReactTable";
export const sharedProps = {
    clases: "-highlight",
    rowsPerPageOptions: { rowsPerPageOptions: [10, 20, 40, 80, 100] } // No more than this
};
const useStyles = makeStyles(({ spacing }) => ({
    userCard: {
        paddingLeft: 0,
    },
    heading: {
        padding: `${spacing(2)} 0`,
        display: "flex",
        justifyContent: "space-between",
    },
    progress: {
        color: "#11b2a8",
        position: "absolute",
        top: "50%",
        left: "45%",
    },
}));
function PermissionTable() {
    const dispatch = useDispatch();
    const classes = useStyles();
    const { id } = useParams();
    const permissionList = useSelector(AdminSelectors.getAdminPermissionList);
    const adminData = useSelector(AdminSelectors.getAdminDetail);
    const isLoading = useSelector(AdminSelectors.getIsAdminPermissionLoading);
    const isOpenAssignDialog = useSelector(AdminSelectors.getIsAssignPermissionDialog);
    const { totalCount, currentPage, pageSize } = useSelector(AdminSelectors.getPermissionListPaginationData);
    useEffect(
        () => {
            if (id) {
                dispatch(AdminActions.fetchAdminById(id));
            }
        },
        [id],
    );
    const fetchData = useCallback(({ pageSize, pageIndex }) => {
        if (id) {
            dispatch(() => onPageSizeChange(pageSize));
            dispatch(() => onPageChange(pageIndex));
            dispatch(AdminActions.fetchPermissionByAdmin(id));
        }
    }, [id]);

    const onAssignPermission = () => {
        dispatch(AdminActions.setIsOpenAssignPermissionDialog(true));
    };
    const hideAssignDialog = () => {
        dispatch(AdminActions.setIsOpenAssignPermissionDialog(false));
    };

    const deletePermission = (original) => {
        dispatch(AdminActions.removeAdminPermissions(id, original));
    }

    const onPageSizeChange = (data) => {
        dispatch(AdminActions.setPermissionPageSize(data));
    }
    const onPageChange = (data) => {
        dispatch(AdminActions.setPermissionPageChange(data));
    }
    const column = [
        {
            Header: "Permission",
            id: "permission_name",
            accessor: ({ permission_name }) => permission_name,
            disableSortBy: true,
            className: "-cursor-pointer",
        },
        {
            Header: "Description",
            id: "description",
            accessor: ({ description }) => description,
            disableSortBy: true,
            className: "-cursor-pointer",
        },
        {
            Header: "Resource Name",
            id: "resource_server_name",
            accessor: ({ resource_server_name }) => resource_server_name,
            disableSortBy: true,
            className: "-cursor-pointer",
        },
        {
            Header: "Resource Name",
            id: "all_resource_server_name",
            accessor: ({ resource_server_name }) => resource_server_name,
            disableSortBy: true,
            className: "-cursor-pointer",
            Cell: ({ row: { original } }) => {
                return (
                    <div>
                        {original.sources.map(n => (
                            <div key={n.source_id}>{n.source_type}</div>
                        ))}
                    </div>
                );
            },
        },
        {
            id: "actions",
            disableSortBy: true,
            disableFilters: true,
            width: 100,
            className: "text-right",
            Cell: ({ row: { original } }) => {
                if (find(original.sources, { source_type: 'DIRECT' })) {
                    return (
                        <Can perform={AdminPermissions.delete.permissions.value}>
                            {allowed => {
                                if (!allowed) {
                                    return null;
                                }
                                return <IconButton
                                    onClick={() => deletePermission(original)}
                                    color="primary"
                                    aria-label="Delete Permission"
                                    component="span"
                                    size="small"
                                >
                                    <Delete />
                                </IconButton>

                            }}
                        </Can>
                    );
                }
                return null;
            },
        },
    ];

    return (
        <React.Fragment>
            <MainContent>
                {adminData && (
                    <CardHeader
                        className={classes.userCard}
                        avatar={
                            <Avatar src={adminData.signedProfileImageUrl}>
                                {formatInitials(adminData.firstName, adminData.lastName, "")}
                            </Avatar>
                        }
                        title={adminData.firstName + " " + adminData.lastName}
                        subheader={adminData.email}
                    />
                )}
                <Divider />
                <div className={classes.heading}>
                    <Typography variant="h6" component="h2">
                        List of permissions this user has
                    </Typography>

                    <Can perform={AdminPermissions.update.permissions.value}>
                        {allowed => {
                            if (!allowed) {
                                return null;
                            }
                            return <Button label="Assign Permissions" onClick={onAssignPermission} />;
                        }}
                    </Can>
                </div>
                <ReactTableCustom
                    {...sharedProps}
                    classes="-highlight"
                    hasFilter={false}
                    fetchData={fetchData}
                    pageCount={totalCount}
                    currentPage={currentPage}
                    defaultPageSize={pageSize}
                    data={permissionList}
                    loading={isLoading}
                    columns={column}
                />
            </MainContent>
            <Dialog
                open={isOpenAssignDialog}
                title="Assign Permission"
                description={null}
                onClose={hideAssignDialog}
                onConfirm={hideAssignDialog}
                onCancel={hideAssignDialog}
                modalType="editEmployer"
                alertType="form"
                size="md"
                hideActions
            >
                <AssignPermission
                    hideAssignDialog={hideAssignDialog}
                    permissionList={permissionList}
                    data={adminData}
                />
            </Dialog>
        </React.Fragment>
    );
}

export default PermissionTable;
