/* eslint-disable no-inner-declarations */
/* eslint-disable react/display-name */
/* eslint-disable react/prop-types */
import React, { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { MobileDateTimePicker } from '@mui/x-date-pickers';
import '@mui/lab';
import { AdapterMoment as MomentUtils } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import Line from "components/Placeholders/Line";
import { actions as JobDetailActions, selectors as JobDetailSelectors } from "store/modules/jobDetails";
import DisableCellOverlay from "../../components/Controls/DisableCellOverlay";
import { convertUTCToTimezone, convertUTCToTimezoneWithOffset } from "../../../../../../../../../utils/time";
import { TextField } from "@mui/material";
import { useStyles } from "views/Job/JobDetails/styles";
import Can from "components/Authorization/Can";
import { PositionPermissions } from "helpers/constants/permissions";

export default function useCheckOut(isCheckingOutAll, disabled, timezone) {
    const dispatch = useDispatch();
    const canAddWorker = useSelector(JobDetailSelectors.getCanAddWorker);
    const classes = useStyles();
    const jobEndUtc = useSelector(JobDetailSelectors.getJobEndUtc);
    const [batchCheckoutValue, setBatchCheckoutValue] = useState(convertUTCToTimezone(jobEndUtc, timezone));

    const handleBatchApply = time => {
        setBatchCheckoutValue(time);
        dispatch(JobDetailActions.batchUpdatePositionData("endShiftUtc")(time));
    };

    const renderCell = useCallback((p) => {
        return <RenederCell {...p} extraData={{ isCheckingOutAll, disabled, timezone }} />;
    }, [isCheckingOutAll, disabled, timezone]);


    return {
        Header: "Check out",
        id: "endShift",
        filterable: isCheckingOutAll,
        sortable: false,
        style: isCheckingOutAll ? { position: "unset" } : {},
        headerStyle: isCheckingOutAll ? { position: "unset" } : {},
        // width: 170,
        Filter: () => {
            const pickerProps = {
                hideTabs: true,
                error: false,
                autoOk: true,
                emptyLabel: ",",
                DialogProps: { className: classes.dateTimePicker },
                invalidLabel: ",",
                inputFormat: "hh:mm a",
                value: batchCheckoutValue,
                renderInput: (props) => <TextField {...props} />,
                onChange: date => handleBatchApply(date),
            };
            return (
                <LocalizationProvider dateAdapter={MomentUtils}>
                    <MobileDateTimePicker {...pickerProps} />
                </LocalizationProvider>
            );
        },
        Cell: renderCell,
        Footer: (<Can perform={PositionPermissions.create.fill.value}>
            {allowed => {
                if (!allowed) {
                    return null;
                }
                return canAddWorker && !disabled ? <Line /> : null
            }}
        </Can>)
    };
}


const RenederCell = ({ row: { original }, extraData: { isCheckingOutAll, disabled, timezone }, ...props }) => {
    const classes = useStyles();
    const positionUpdates = useSelector(JobDetailSelectors.getPositionUpdates);
    const dispatch = useDispatch();

    if (isCheckingOutAll && original.isEditable) {
        const updatePositionCheckout = JobDetailActions.updatePositionData("endShiftUtc");

        const { endShiftUtc } = positionUpdates.find(({ id }) => id === original.id);
        const onChange = (date) => {
            dispatch(updatePositionCheckout(original.id, date));
        };
        const pickerProps = {
            hideTabs: true,
            error: false,
            readOnly: !(isCheckingOutAll && original.isEditable),
            disabled: !(isCheckingOutAll && original.isEditable),
            DialogProps: { className: classes.dateTimePicker },
            autoOk: true,
            emptyLabel: ",",
            invalidLabel: ",",
            inputFormat: "hh:mm a",
            renderInput: (props) => <TextField {...props} />,
            value: convertUTCToTimezone(endShiftUtc, timezone),
            onChange: date => onChange(date),
        };
        return (
            <LocalizationProvider dateAdapter={MomentUtils}>
                <MobileDateTimePicker {...pickerProps} />
            </LocalizationProvider>
        );
    }

    return (
        <div style={{ position: "relative" }}>
            {!original.endShift ? (
                <Line />
            ) : (
                convertUTCToTimezoneWithOffset(original.endShiftUtc, timezone)
            )}
            <DisableCellOverlay visible={disabled || (isCheckingOutAll && !original.isEditable)} />
        </div>
    );
}
