/* eslint-disable react/display-name */
/* eslint-disable react/prop-types */
import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Avatar from "@mui/material/Avatar";
import Grid from "@mui/material/Grid";
import Grow from "@mui/material/Grow";
import List from "@mui/material/List";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";
import { componentStyles, CustomListItem } from "views/Finance/helpers";
import Loader from "components/Loader/Loader";
import { formatMoney, formatNumber } from "utils/stringUtils";
import { selectors as FinanceSelectors } from "store/modules/finance";

const PaymentTilesCard = () => {
  const classes = componentStyles();
  const paymentsOverview = useSelector(FinanceSelectors.getPaymentsOverview);
  const dcAccount = useSelector(FinanceSelectors.getDcAccount);
  const [account, setAccount] = useState(dcAccount);

  useEffect(() => setAccount(dcAccount), [dcAccount]);

  const RenderShiftsCard = useCallback(
    ({ title, value, subValue }) => (
      <Grid container item xs={12} className={classes.container}>
        <Grid item xs={12} className={classes.statsContainer}>
          <Grow in={true} timeout={1000}>
            <Grid item xs={12} className={classes.statsCard}>
              <Grid container item xs={12} direction="column">
                <Typography className={classes.tileTitle} variant="caption">{`${
                  title || ""
                }`}</Typography>
                <Grid container direction="row" justifyContent="flex-start">
                  <Typography variant="h5" className={classes.tileValue}>
                    {`${value || ""}`}
                    <Typography variant="caption" className={classes.subValue}>
                      {`${subValue || ""}`}
                    </Typography>
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grow>
        </Grid>
      </Grid>
    ),
    []
  );
  const RenderDcCard = useCallback(
    ({ account }) => (
      <Grid container item xs={12} className={classes.container}>
        <Grid item xs={12} className={classes.statsContainer}>
          <Grow in={true} timeout={1000}>
            <Grid item xs={12} className={classes.statsCard}>
              <Grid container item xs={12} direction="column" justifyContent="center">
                <Typography className={classes.tileTitle}>{`Account Status`}</Typography>
                <Typography>{`Account Balance: ${formatMoney(account.Balance)}`}</Typography>
                <Typography>{`Funds Available: ${formatMoney(
                  account.AvailableBalance
                )}`}</Typography>
              </Grid>
            </Grid>
          </Grow>
        </Grid>
      </Grid>
    ),
    []
  );

  const RenderReversalStatusesCard = useCallback(
    ({ statusSummary, title }) => (
      <Grid container item xs={12} className={classes.container}>
        {statusSummary && (
          <Grid item xs={12} className={classes.statsContainer}>
            <Grow in={true} timeout={1000}>
              <Grid item xs={12} className={classes.statsCard}>
                <Grid container item xs={12} direction="row" justifyContent="center">
                  <Typography className={classes.tileTitle}>{`${title}`}</Typography>
                  <Grid container item xs={12} md={6}>
                    <List component="nav" className={classes.root} aria-label="contacts">
                    <CustomListItem button disabled={!statusSummary.outstandingReversals}>
                      <ListItemAvatar className={classes.statusBadgeList}>
                        <Avatar className={classes.ok}>
                          {statusSummary.outstandingReversals}
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText primary="Pending" />
                    </CustomListItem>
                      <CustomListItem button disabled={!statusSummary.rejectedReversals}>
                        <ListItemAvatar className={classes.statusBadgeList}>
                          <Avatar className={classes.orange}>
                            {statusSummary.rejectedReversals}
                          </Avatar>
                        </ListItemAvatar>
                        <ListItemText primary="Rejected" />
                      </CustomListItem>
                      <CustomListItem button disabled={!statusSummary.expiredReversals}>
                        <ListItemAvatar className={classes.statusBadgeList}>
                          <Avatar className={classes.expired}>
                            {statusSummary.expiredReversals}
                          </Avatar>
                        </ListItemAvatar>
                        <ListItemText primary="Expired" />
                      </CustomListItem>
                    </List>
                  </Grid>
                  <Grid container item xs={12} md={6}>
                    <List component="nav" className={classes.root} aria-label="contacts">
                      <CustomListItem button disabled={!statusSummary.expiringReversals}>
                        <ListItemAvatar className={classes.statusBadgeList}>
                          <Avatar className={classes.warning}>
                            {statusSummary.expiringReversals}
                          </Avatar>
                        </ListItemAvatar>
                        <ListItemText primary="Expiring Soon" />
                      </CustomListItem>
                      <CustomListItem button disabled={!statusSummary.cancelledReversals}>
                        <ListItemAvatar className={classes.statusBadgeList}>
                          <Avatar className={classes.expired}>
                            {statusSummary.cancelledReversals +
                              statusSummary.manuallyResolvedReversals}
                          </Avatar>
                        </ListItemAvatar>
                        <ListItemText primary="Cancelled" />
                      </CustomListItem>
                      <CustomListItem button disabled={!statusSummary.failedReversals}>
                        <ListItemAvatar className={classes.statusBadgeList}>
                          <Avatar className={classes.expired}>
                            {statusSummary.failedReversals}
                          </Avatar>
                        </ListItemAvatar>
                        <ListItemText primary="Failed" />
                      </CustomListItem>
                    </List>
                  </Grid>
                </Grid>
              </Grid>
            </Grow>
          </Grid>
        )}
      </Grid>
    ),
    [paymentsOverview]
  );

  const RenderStatusesCard = useCallback(
    ({ statusSummary, title }) => (
      <Grid container item xs={12} className={classes.container}>
        {statusSummary && (
          <Grid item xs={12} className={classes.statsContainer}>
            <Grow in={true} timeout={1000}>
              <Grid item xs={12} className={classes.statsCard}>
                <Grid container item xs={12} direction="row" justifyContent="center">
                  <Typography className={classes.tileTitle}>{`${title}`}</Typography>
                  <Grid container item xs={12} md={6}>
                    <List component="nav" className={classes.root} aria-label="contacts">
                        <CustomListItem
                          button
                          disabled={!statusSummary.outstandingPayments}
                          onClick={() => handleClick("pending")}>
                          <ListItemAvatar className={classes.statusBadgeList}>
                            <Avatar className={classes.ok}>
                              {statusSummary.outstandingPayments}
                            </Avatar>
                          </ListItemAvatar>
                          <ListItemText primary="Pending" />
                        </CustomListItem>
                        <CustomListItem
                          button
                          disabled={!statusSummary.rejectedTransfers}
                          onClick={() => handleClick("rejected")}>
                          <ListItemAvatar className={classes.statusBadgeList}>
                            <Avatar className={classes.orange}>
                              {statusSummary.rejectedTransfers}
                            </Avatar>
                          </ListItemAvatar>
                          <ListItemText primary="Rejected" />
                        </CustomListItem>
                        <CustomListItem
                          button
                          disabled={!statusSummary.expiredTransfers}
                          onClick={() => handleClick("expired")}>
                          <ListItemAvatar className={classes.statusBadgeList}>
                            <Avatar className={classes.expired}>
                              {statusSummary.expiredTransfers}
                            </Avatar>
                          </ListItemAvatar>
                          <ListItemText primary="Expired" />
                        </CustomListItem>
                    </List>
                  </Grid>
                  <Grid container item xs={12} md={6}>
                    <List component="nav" className={classes.root} aria-label="contacts">
                        <CustomListItem
                          button
                          disabled={!statusSummary.expiringTransfers}
                          onClick={() => handleClick("expiring")}>
                          <ListItemAvatar className={classes.statusBadgeList}>
                            <Avatar className={classes.warning}>
                              {statusSummary.expiringTransfers}
                            </Avatar>
                          </ListItemAvatar>
                          <ListItemText primary="Expiring Soon" />
                        </CustomListItem>
                        <CustomListItem
                          button
                          disabled={!statusSummary.cancelledTransfers}
                          onClick={() => handleClick("cancelled")}>
                          <ListItemAvatar className={classes.statusBadgeList}>
                            <Avatar className={classes.expired}>
                              {statusSummary.cancelledTransfers +
                                statusSummary.manuallyResolvedTransfers}
                            </Avatar>
                          </ListItemAvatar>
                          <ListItemText primary="Cancelled" />
                        </CustomListItem>
                        <CustomListItem
                          button
                          disabled={!statusSummary.failedTransfers}
                          onClick={() => handleClick("failed")}>
                          <ListItemAvatar className={classes.statusBadgeList}>
                            <Avatar className={classes.expired}>
                              {statusSummary.failedTransfers}
                            </Avatar>
                          </ListItemAvatar>
                          <ListItemText primary="Failed" />
                        </CustomListItem>
                    </List>
                  </Grid>
                </Grid>
              </Grid>
            </Grow>
          </Grid>
        )}
      </Grid>
    ),
    []
  );

  const RenderTiles = useCallback(
    ({ account }) => {
      return paymentsOverview.paymentSummary ? (
        <Grid container direction="row">
          <Grid container item xs={12} md={3} lg={3}>
            <Grid container item xs={6} md={12}>
              <RenderStatusesCard
                title="eTransfer Statuses"
                statusSummary={paymentsOverview.paymentSummary}
              />
            </Grid>
          </Grid>
          <Grid container item xs={12} md={3} lg={3}>
            <RenderReversalStatusesCard
              title="Reversal Request Summary"
              statusSummary={paymentsOverview.paymentSummary}
            />
          </Grid>
          <Grid container item xs={12} md={6} lg={6}>
            <Grid container item xs={6} md={4}>
              <RenderDcCard account={account} />
            </Grid>
            <Grid container item xs={6} md={4}>
              <RenderShiftsCard
                title="Payments"
                value={formatNumber(paymentsOverview.outstandingPayments)}
                subValue={formatMoney(paymentsOverview.outstandingPaymentsBalance)}
              />
            </Grid>
            <Grid container item xs={6} md={4}>
              <RenderShiftsCard
                title="Outstanding Reversals"
                value={formatNumber(paymentsOverview.outstandingReversals)}
                subValue={formatMoney(paymentsOverview.outstandingReversalsBalance)}
              />
            </Grid>
          </Grid>
          {/* <PaymentsTrendCard /> */}
        </Grid>
      ) : (
        <Loader />
      );
    },
    [paymentsOverview.paymentSummary]
  );

  const handleClick = async () => {};

  const buildToolTip = content => content;
  return (
    <RenderTiles
      account={account}
      statusSummary={paymentsOverview.statusSummary}
      summary={paymentsOverview}
    />
  );
};
export default PaymentTilesCard;
