import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import CircularProgress from "@mui/material/CircularProgress";
import Fab from "@mui/material/Fab";
import withStyles from '@mui/styles/withStyles';

const styles = ({ spacing, palette }) => ({
    icon: {},
    wrapper: {
        position: "relative",
        paddingRight: spacing(1),
        paddingLeft: spacing(2),
    },
    label: {
        padding: `0px ${spacing(2)}`,
    },
    labelIconRight: {
        paddingRight: spacing(1),
        paddingLeft: spacing(2),
    },
    labelIconLeft: {
        paddingRight: spacing(2),
        paddingLeft: spacing(1),
    },
    noBorder: {
        border: "none",
    },
    error: {
        backgroundColor: palette.error.main,
        borderColor: palette.error.main,
        color: palette.common.white,
        "&:hover": {
            backgroundColor: palette.error.dark,
            borderColor: palette.error.dark,
        },
    },
    transparent: {
        backgroundColor: "transparent",
        border: "none",
        "&:hover": {
            backgroundColor: palette.grey[200],
            borderColor: "none",
        },
    },
    progress: {
        color: palette.secondary.main,
        position: "absolute",
        left: "calc(50% - 12px)",
        marginTop: 5,
        top: 0,
        "& svg":{
            marginTop:"0 !important"
        }
    },
});

function Button({
    className,
    buttonClassName,
    labelClassName,
    style,
    buttonStyle,
    classes,
    onClick,
    label,
    icon,
    alignIcon,
    color,
    disabled,
    size,
    isLoading,
}) {
    let labelClass = classNames(classes.label, labelClassName);
    if (icon && alignIcon === "left") {
        labelClass = classNames(classes.labelIconLeft, labelClassName);
    }

    if (icon && alignIcon === "right") {
        labelClass = classNames(classes.labelIconRight, labelClassName);
    }

    let content = icon;
    if (label) {
        content = (
            <React.Fragment>
                {alignIcon === "left" ? <span className={classes.icon}>{icon}</span> : null}
                <span className={labelClass}>{label}</span>
                {alignIcon === "right" ? <span className={classes.icon}>{icon}</span> : null}
            </React.Fragment>
        );
    }

    const isErrorColor = color === "error";
    const isTransparentColor = color === "transparent";

    let calculatedColor;
    if (!isErrorColor && !isTransparentColor) {
        calculatedColor = color;
    }

    return (
        <div className={classNames(classes.wrapper, className)} style={style}>
            <Fab
                variant={label ? "extended" : "circular"}
                aria-label={label}
                className={classNames(
                    {
                        [classes.error]: isErrorColor,
                        [classes.transparent]: isTransparentColor,
                    },
                    buttonClassName,
                    classes.noBorder
                )}
                onClick={onClick}
                style={{ ...buttonStyle }}
                color={calculatedColor}
                disabled={isLoading ? true : disabled}
                size={size}>
                {content}
                {isLoading && <CircularProgress size={24} className={classes.progress} />}
            </Fab>
        </div>
    );
}

Button.propTypes = {
    classes: PropTypes.object.isRequired,
    onClick: PropTypes.func.isRequired,
    label: PropTypes.string,
    className: PropTypes.string,
    buttonClassName: PropTypes.string,
    labelClassName: PropTypes.string,
    style: PropTypes.object,
    buttonStyle: PropTypes.object,
    icon: PropTypes.element,
    alignIcon: PropTypes.oneOf(["right", "left"]),
    color: PropTypes.oneOf(["default", "inherit", "primary", "secondary", "error", "transparent"]),
    disabled: PropTypes.bool,
    size: PropTypes.oneOf(["small", "medium", "large"]),
    isLoading: PropTypes.bool,
};

Button.defaultProps = {
    label: null,
    style: {},
    buttonStyle: {},
    icon: null,
    className: "",
    buttonClassName: "",
    labelClassName: "",
    alignIcon: "right",
    color: "secondary",
    disabled: false,
    size: "large",
    isLoading: false,
};

export default withStyles(styles)(Button);
