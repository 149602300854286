import { find, remove } from "lodash";
import isFunction from "lodash/isFunction";
import isPlainObject from "lodash/isPlainObject";
import ApolloClient from "utils/apollo";
import { flattenGraphQLArray } from "utils/graphql";
import * as pagination from "utils/graphql-pagination";
import { formatMoney } from "utils/stringUtils";
import { convertUTCToTimezoneWithOffset, getTimeZone } from "utils/time";
import { actions as MetaActions } from "store/modules/meta";
import { fetchPaymentsOverview } from "../finance/actions";
import { suspendWorker } from "../workers/actions";
import { paginationSettings } from "./constants";
import ActivateJobMutation from "./graphql/mutations/activate-job";
import AddPositionBonus from "./graphql/mutations/add-bonus";
import BanWorkerMutation from "./graphql/mutations/ban-worker";
import CancelJobMutation from "./graphql/mutations/cancel-job";
import CheckInAllMutation from "./graphql/mutations/checkin-all-workers";
import CheckInMutation from "./graphql/mutations/checkin-worker";
import CheckOutAllMutation from "./graphql/mutations/checkout-all-workers";
import CheckOutMutation from "./graphql/mutations/checkout-worker";
import ClearExceptionMutation from "./graphql/mutations/clear-exception";
import ConfirmWorkerMutation from "./graphql/mutations/confirm-worker";
import DecreaseJobPositionsMutation from "./graphql/mutations/decrease-positions";
import DeleteJobMutation from "./graphql/mutations/delete-job";
import DequeuePayMutation from "./graphql/mutations/dequeue-pay";
import EditAllPositionRates from "./graphql/mutations/edit-all-rates";
import EditJobDescriptionMutation from "./graphql/mutations/edit-description";
import EditPositionRates, { EditPositionRatesFromDialog } from "./graphql/mutations/edit-rates";
import EmployerJobReminderMutation from "./graphql/mutations/employer-job-reminder";
import FillPositionMutation from "./graphql/mutations/fill-position";
import IncreaseJobPositionsMutation from "./graphql/mutations/increase-positions";
import InitializeJobPayrollMutation, {
    initializeJobPayrollDetailMutation,
    initializePositionPayrollMutation,
} from "./graphql/mutations/initialize-job-payroll";
import NoShowPositionMutation from "./graphql/mutations/noshow-worker";
import payJobMutation, { payJobPaymentMutation } from "./graphql/mutations/pay-job";
import PreferWorkerMutation from "./graphql/mutations/prefer-worker";
import RateAllWorkersMutation from "./graphql/mutations/rate-all-workers";
import RateWorkerMutation from "./graphql/mutations/rate-worker";
import ReconfirmWorkerMutation from "./graphql/mutations/reconfirm-worker";
import RemovePositionMutation from "./graphql/mutations/remove-worker";
import UninitializeJobPayrollMutation from "./graphql/mutations/uninitialize-job-payroll";
import CheckPositionQuery from "./graphql/queries/check-position";
import FetchJobQuery from "./graphql/queries/fetch-job";
import FetchJobWorkersQuery from "./graphql/queries/fetch-job-workers";
import FetchPositionsQuery from "./graphql/queries/fetch-positions";
import FetchJobTimeQuery from "./graphql/queries/fetch-job-timeline";
import FetchAllWorkersQuery from "./graphql/queries/fetch-workers-picker";
import SendNotificationQuery from "./graphql/queries/send-notification";
import FetchRecurringJobQuery from "./graphql/queries/fetch-recurring-job";
import CancelRecurringJobMutation from "./graphql/mutations/cancel-recurring-job";
import {
    getAllWorkersPaginationData,
    getPreferredWorkersPaginationData,
    getWaitlistWorkersPaginationData,
    getWorkerListData,
} from "./selectors";
import types from "./types";

const setLoadingState = key => value => ({
    type: types.SET_LOADING_STATE,
    payload: { key, value },
});

const setIsPageLoading = setLoadingState("page");
const setIsAddingWorker = setLoadingState("addingWorker");
const setIsUpdatingPositions = setLoadingState("updatingPositions");
const setIsActivatingJob = setLoadingState("activatingJob");
const setIsApplyingAction = setLoadingState("actions");
export const setIsInitializingJobPayroll = setLoadingState("initializingJobPayroll");
const setIsUninitializingJobPayroll = setLoadingState("uninitializingJobPayroll");
export const setIsPayingJob = setLoadingState("payJob");
export const setJobTimelineLoading = setLoadingState("jobTimeline");

export const setIsCancelJobDialogState = value => ({
    type: types.SET_IS_CANCEL_JOB_DIALOG_STATE,
    payload: { value },
});

export const updateJobPayrolls = payrolls => ({
    type: types.UPDATE_PAYROLL,
    payload: { payrolls },
});

const setPayablePayrolls = data => ({
    type: types.SET_PAYABLE_PAYROLLS,
    payload: { payrolls: data },
});

export const initializeJobPayroll = jobId => async (dispatch, getState) => {
    // dispatch(setIsInitializingJobPayroll(true));
    dispatch(setIsPayingJob(true));
    return ApolloClient.mutate({
        mutation: InitializeJobPayrollMutation,
        variables: {
            data: {
                jobId: jobId ? jobId : getState().jobDetails.id,
            },
        },
    })
        .then(data => {
            // dispatch(setPayablePayrolls(data));
            // dispatch(setIsInitializingJobPayroll(false));
            dispatch(setIsPayingJob(false));
            return data;
        })
        .catch(e => {
            // dispatch(setIsPayingJob(false));
            dispatch(MetaActions.errorToast(`Failed to initialize payroll. (${e.message})`));
            throw e;
        });
};

export const initializePositionPayroll = positionId => async (dispatch, getState) => {
    // dispatch(setIsInitializingJobPayroll(true));
    dispatch(setIsPayingJob(true));
    return ApolloClient.mutate({
        mutation: initializePositionPayrollMutation,
        variables: {
            positionId,
        },
    })
        .then(data => {
            // dispatch(setPayablePayrolls(data));
            // dispatch(setIsInitializingJobPayroll(false));
            dispatch(setIsPayingJob(false));
            return data;
        })
        .catch(e => {
            // dispatch(setIsPayingJob(false));
            dispatch(MetaActions.errorToast(`Failed to initialize payroll. (${e.message})`));
            throw e;
        });
};

export const initializeJobPayrollDetail = jobId => async (dispatch, getState) => {
    dispatch(setIsInitializingJobPayroll(true));

    return ApolloClient.query({
        query: initializeJobPayrollDetailMutation,
        variables: {
            data: {
                jobId: jobId ? jobId : getState().jobDetails.id,
            },
        },
    })
        .then(data => {
            dispatch(setPayablePayrolls(data));
            dispatch(setIsInitializingJobPayroll(false));
        })
        .catch(e => {
            dispatch(setIsInitializingJobPayroll(false));
            dispatch(MetaActions.errorToast(`Failed to fetch payroll. (${e.message})`));
            throw e;
        });
};


export const uninitializeJobPayroll = () => async (dispatch, getState) => {
    dispatch(setIsUninitializingJobPayroll(true));
    const { id: jobId } = getState().jobDetails;
    return ApolloClient.mutate({
        mutation: UninitializeJobPayrollMutation,
        variables: { data: { jobId } },
    })
        .then(({ data: { uninitializeJobPayroll } }) => {
            dispatch(updateJobPayrolls(uninitializeJobPayroll));
            dispatch(setIsUninitializingJobPayroll(false));
        })

        .then(() => dispatch(fetchPaymentsOverview()))
        .catch(e => {
            dispatch(setIsUninitializingJobPayroll(false));
            dispatch(MetaActions.errorToast(`Failed to uninitialize payroll. (${e.message})`));
        });
};

export const payJob = () => async (dispatch, getState) => {
    const { id } = getState().jobDetails;
    dispatch(setIsPayingJob(true));

    return ApolloClient.mutate({
        mutation: payJobMutation,
        variables: { data: { jobId: id } },
    })
        .then(({ data, errors }) => {
            if (!data && errors.length > 0) {
                throw new Error(errors[0].message);
            }
            dispatch(updateJobPayrolls(data.payJob));
            //   dispatch(fetchPaymentsOverview());
            dispatch(fetchPositions());
        })
        .then(() => {
            dispatch(MetaActions.successToast(`Payroll eTransfers for job #${id} has been initiated.`))
            dispatch(setIsPayingJob(false));
        })
        .then(() => setTimeout(() => dispatch(fetchPaymentsOverview()), 2000))
        .catch(e => {
            dispatch(setIsPayingJob(false));
            dispatch(MetaActions.errorToast(`Failed to pay job #${id}. (${e.message})`));
        });
};
export const payJobPayment = (loading) => async (dispatch, getState) => {
    const { id } = getState().jobDetails;
    dispatch(setIsPayingJob(true));
    return ApolloClient.mutate({
        mutation: payJobPaymentMutation,
        variables: { jobId: id },
    })
        .then(({ data, errors }) => {
            dispatch(fetchPositions());
            if (!data && errors.length > 0) {
                throw new Error(errors[0].message);
            }
            dispatch(updateJobPayrolls(data.payJobPayment));
            //   dispatch(fetchPaymentsOverview());
        })
        .then(() => {
            dispatch(MetaActions.successToast(`Payroll eTransfers for job #${id} has been initiated.`))
        })
        .then(() => {
            dispatch(fetchPaymentsOverview());
        })
        .catch(e => {
            if (!loading) {
                dispatch(setIsPayingJob(false));
            }
            dispatch(MetaActions.errorToast(`Failed to pay job #${id}. (${e.message})`));
        });
};

export const dequeuePay = () => (dispatch, getState) => {
    dispatch(setIsApplyingAction(true));
    const { id: jobId } = getState().jobDetails;
    return ApolloClient.mutate({
        mutation: DequeuePayMutation,
        variables: {
            data: {
                jobId,
            },
        },
    })
        .then(({ data }) => {
            dispatch(updateJobPayrolls(data.dequeueJobPay));
            dispatch(setIsApplyingAction(false));
            return dispatch(fetchPositions());
        })
        .catch(() => {
            dispatch(setIsApplyingAction(false));
        });
};

const reset = () => ({
    type: types.RESET,
});

const setJob = job => ({
    type: types.SET_JOB,
    payload: { job },
});

const setJobWorkers = workers => ({
    type: types.SET_JOB_WORKERS,
    payload: { workers },
});

export const fetchJobWorkers = (jobId, employerId) => dispatch => {
    return ApolloClient.query({
        query: FetchJobWorkersQuery,
        variables: { jobId, employerId },
    }).then(({ data }) => {
        const workers = flattenGraphQLArray(data.workers);
        dispatch(setJobWorkers(workers));
    });
};

export const fetchJob = (id, isPageLoading = true) => dispatch => {
    dispatch(setIsPageLoading(isPageLoading));
    return ApolloClient.query({
        query: FetchJobQuery,
        variables: { id: id, timezone: getTimeZone() },
    })
        .then(({ data: { job } }) => {
            dispatch(setJob(job));
            return dispatch(fetchJobWorkers(job.id, job.employerId));
        })
        .then(() => {
            dispatch(setIsPageLoading(false));
        })
        .catch(e => {
            dispatch(setIsPageLoading(false));
            dispatch(MetaActions.errorToast(`Could not get job information: ${e.message}`));
        });
};

export const fetchWorkersListFactory = config => pageIndex => (dispatch, getState) => {
    const state = getState();
    const pageInfo = config.getPageInfo(state);
    const pagingVars = dispatch(config.getPagingVars(pageInfo, pageIndex));
    const filterInfo = [...getWorkerListData(state, { list: config.list, part: "filter" })]
    const vaccinated = find(filterInfo, { id: "vaccinationStatus" });
    let extraVars = {};

    if (isFunction(config.variables)) {
        extraVars = config.variables(state);
    }

    if (isPlainObject(config.variables)) {
        extraVars = config.variables;
    }
    if (vaccinated && vaccinated.value) {
        extraVars.vaccinationStatus = vaccinated.value
        remove(filterInfo, { id: "vaccinationStatus" });
    }

    return ApolloClient.query({
        query: config.query,
        variables: {
            ...pagingVars,
            ...extraVars,
            employerId: state.jobDetails.employerId,
            jobId: state.jobDetails.id,
            order: getWorkerListData(state, { list: config.list, part: "sort" }).map(sort => {
                return {
                    field: sort.id,
                    direction: sort.desc ? "DESC" : "ASC",
                };
            }),
            like: filterInfo.map(filter => {
                return {
                    field: filter.id,
                    value: filter.value,
                };
            }),
        },
    })
        .then(({ data: raw }) => {
            const { paginationData, data } = config.processData(raw);

            dispatch({
                type: types.SET_WORKER_PICKER_LIST_DATA,
                payload: {
                    data,
                    list: config.list,
                    part: "data",
                },
            });
            dispatch(pagination.updatePageInfo(config.paginationSetting, paginationData));
            dispatch(pagination.doneLoading(config.paginationSetting));
        })
        .catch(() => {
            dispatch(pagination.doneLoading(config.paginationSetting));
        });
};

export const setSortFactory = config => sort => dispatch => {
    dispatch({
        type: types.SET_WORKER_PICKER_LIST_DATA,
        payload: {
            data: sort,
            list: config.list,
            part: "sort",
        },
    });
    if (config.refresh)
        return dispatch(config.refresh());
};

export const setFilterFactory = config => filter => dispatch => {
    dispatch({
        type: types.SET_WORKER_PICKER_LIST_DATA,
        payload: {
            data: filter,
            list: config.list,
            part: "filter",
        },
    });
    if (config.refresh)
        return dispatch(config.refresh());
};

export const setPageSizeFactory = config => pageSize => dispatch => {
    dispatch(pagination.updatePageInfo(config.paginationSetting, { pageSize }));
    return dispatch(config.refresh());
};

// All workers
export const fetchAllWorkers = fetchWorkersListFactory({
    getPageInfo: getAllWorkersPaginationData,
    getPagingVars: pagination.pagingVarsFactory(paginationSettings.allWorkers),
    query: FetchAllWorkersQuery,
    paginationSetting: paginationSettings.allWorkers,
    list: "all",
    variables: state => ({
        jobTags: state.jobDetails.tags,
    }),
    processData: ({ workers }) => {
        return {
            paginationData: {
                ...workers.pageInfo,
                totalCount: workers.totalCount,
            },
            data: flattenGraphQLArray(workers),
        };
    },
});

export const setAllWorkersSort = setSortFactory({
    list: "all",
    //   refresh: fetchAllWorkers,
});

export const setAllWorkersFilter = setFilterFactory({
    list: "all",
    // refresh: fetchAllWorkers,
});

// Preferred workers
export const fetchPreferredWorkers = fetchWorkersListFactory({
    getPageInfo: getPreferredWorkersPaginationData,
    getPagingVars: pagination.pagingVarsFactory(paginationSettings.preferredWorkers),
    query: FetchAllWorkersQuery,
    variables: state => ({
        preferredBy: state.jobDetails.employerId,
    }),
    paginationSetting: paginationSettings.preferredWorkers,
    list: "preferred",
    processData: ({ workers }) => {
        return {
            paginationData: {
                ...workers.pageInfo,
                totalCount: workers.totalCount,
            },
            data: flattenGraphQLArray(workers),
        };
    },
});

export const setPreferredWorkersSort = setSortFactory({
    list: "preferred",
    refresh: fetchPreferredWorkers,
});

export const setPreferredWorkersFilter = setFilterFactory({
    list: "preferred",
    refresh: fetchPreferredWorkers,
});

// Waitlist workers
export const fetchWaitlistWorkers = fetchWorkersListFactory({
    getPageInfo: getWaitlistWorkersPaginationData,
    getPagingVars: pagination.pagingVarsFactory(paginationSettings.waitlistWorkers),
    query: FetchAllWorkersQuery,
    variables: state => ({
        onWaitlist: state.jobDetails.timeframe,
        canDrive: state.jobDetails.workerPickers.waitlist.canDrive,
        canWorkMorning: state.jobDetails.workerPickers.waitlist.canWorkMorning,
        canWorkEvening: state.jobDetails.workerPickers.waitlist.canWorkEvening,
    }),
    list: "waitlist",
    paginationSetting: paginationSettings.waitlistWorkers,
    processData: ({ workers }) => {
        return {
            paginationData: {
                ...workers.pageInfo,
                totalCount: workers.totalCount,
            },
            data: flattenGraphQLArray(workers),
        };
    },
});

export const setWaitlistWorkersSort = setSortFactory({
    list: "waitlist",
    refresh: fetchWaitlistWorkers,
});

export const setWaitlistWorkersFilter = setFilterFactory({
    list: "waitlist",
    refresh: fetchWaitlistWorkers,
});

export const setCanDrive = value => dispatch => {
    dispatch({
        type: types.UPDATE_WAITLIST_FILTER,
        payload: { field: "canDrive", value },
    });

    return dispatch(fetchWaitlistWorkers());
};

export const setCanWorkMorning = value => dispatch => {
    dispatch({
        type: types.UPDATE_WAITLIST_FILTER,
        payload: { field: "canWorkMorning", value },
    });

    return dispatch(fetchWaitlistWorkers());
};

export const setCanWorkEvening = value => dispatch => {
    dispatch({
        type: types.UPDATE_WAITLIST_FILTER,
        payload: { field: "canWorkEvening", value },
    });

    return dispatch(fetchWaitlistWorkers());
};

export const updatePositions = positions => ({
    type: types.UPDATE_POSITIONS,
    payload: { positions },
});

export const setJobTimelineData = data => ({
    type: types.SET_JOB_TIMELINE_DATA,
    payload: { data },
});

export const updatePosition = position => ({
    type: types.UPDATE_POSITION,
    payload: { position },
});

export const fetchPositions = () => (dispatch, getState) => {
    const { id: jobId, employerId } = getState().jobDetails;
    dispatch(setIsUpdatingPositions(true));
    return ApolloClient.query({
        query: FetchPositionsQuery,
        variables: {
            jobId,
            employerId,
        },
    })
        .then(({ data }) => {
            dispatch(updatePositions(data.job.positions));
            dispatch(setIsUpdatingPositions(false));
        })
        .catch(e => {
            dispatch(setIsUpdatingPositions(false));
            dispatch(MetaActions.errorToast(`Failed to update positions. (${e.message})`));
        });
};

export const checkPositionForADay = (workerId, jobId) => (dispatch) => {
    dispatch(setIsAddingWorker(true));
    dispatch(setIsUpdatingPositions(true));
    return ApolloClient.query({
        query: CheckPositionQuery,
        variables: {
            data: {
                workerId,
                jobId,
            },
        },
    })
        .then(({ data, errors }) => {
            if (!data && errors.length > 0) {
                throw new Error(errors[0].message);
            } else {
                dispatch(setIsAddingWorker(false));
                dispatch(setIsUpdatingPositions(false));
                return data.checkPositionForADay;
            }
        })
        .catch(e => {
            dispatch(setIsAddingWorker(false));
            dispatch(setIsUpdatingPositions(false));
            dispatch(MetaActions.errorToast(`Failed to check worker to job. (${e.message})`));
        });
};

export const fillPosition = (workerId, jobId) => (dispatch, getState) => {
    const { employerId } = getState().jobDetails;
    dispatch(setIsAddingWorker(true));
    dispatch(setIsUpdatingPositions(true));
    return ApolloClient.mutate({
        mutation: FillPositionMutation,
        variables: {
            employerId,
            data: {
                workerId,
                jobId,
            },
        },
    })
        .then(({ data, errors }) => {
            if (!data && errors.length > 0) {
                throw new Error(errors[0].message);
            }
            dispatch(updatePositions(data.fillPosition));
            dispatch(setIsAddingWorker(false));
            dispatch(setIsUpdatingPositions(false));
        })
        .catch(e => {
            dispatch(setIsAddingWorker(false));
            dispatch(setIsUpdatingPositions(false));
            dispatch(MetaActions.errorToast(`Failed to add worker to job. (${e.message})`));
        });
};

export const activateJob = (employerId, jobId) => dispatch => {
    dispatch(setIsActivatingJob(true));
    return ApolloClient.mutate({
        mutation: ActivateJobMutation,
        variables: {
            data: {
                employerId,
                jobId,
            },
            timezone: getTimeZone()
        },
    })
        .then(({ data: { activateJob } }) => {
            dispatch(setJob(activateJob));
            return dispatch(fetchJobWorkers(activateJob.id, activateJob.employerId));
        })
        .then(() => {
            dispatch(setIsActivatingJob(false));
            dispatch(MetaActions.successToast("The job is now active."));
        })
        .catch(e => {
            dispatch(setIsActivatingJob(false));
            dispatch(MetaActions.errorToast(`Could not activate job. (${e.message})`));
        });
};

export const editJobDescription = description => (dispatch, getState) => {
    const { id: jobId, employerId } = getState().jobDetails;
    dispatch(setIsApplyingAction(true));
    return ApolloClient.mutate({
        mutation: EditJobDescriptionMutation,
        variables: {
            data: {
                employerId,
                jobId,
                description,
            },
        },
    })
        .then(({ data: { editJobDescription } }) => {
            dispatch(setJob(editJobDescription));
            return dispatch(fetchJobWorkers(editJobDescription.id, editJobDescription.employerId));
        })
        .then(() => {
            dispatch(setIsApplyingAction(false));
            dispatch(MetaActions.successToast("The job is now active."));
        })
        .catch(e => {
            dispatch(setIsApplyingAction(false));
            dispatch(MetaActions.errorToast(`Could not activate job. (${e.message})`));
        });
};

export const sendNotification = sendTo => (dispatch, getState) => {
    const { id: jobId } = getState().jobDetails;
    dispatch(setIsApplyingAction(true));
    return ApolloClient.query({
        query: SendNotificationQuery,
        variables: {
            jobId,
            sendTo,
        },
    })
        // .then(() => {
        //     return dispatch(fetchJob(jobId, false));
        // })
        .then((response) => {
            if (response.data.notifyWorkerAboutJob.status === 'success') {
                dispatch(fetchJob(jobId, false));
                dispatch(MetaActions.successToast(response.data.notifyWorkerAboutJob.message));
            } else {
                dispatch(MetaActions.errorToast(response.data.notifyWorkerAboutJob.message));
            }
            dispatch(setIsApplyingAction(false));
        })
        .catch(e => {
            dispatch(setIsApplyingAction(false));
            dispatch(MetaActions.errorToast(`Could not send notification. (${e.message})`));
        });
};

const removeJobFactory = (mutation, type) => (employerId, jobId) => (dispatch, getState) => {
    const { positionsFilled } = getState().jobDetails;
    dispatch(setIsPageLoading(true));
    return ApolloClient.mutate({
        mutation,
        variables: {
            data: {
                employerId,
                jobId,
            },
        },
    })
        .then(() => {
            dispatch(reset());
            dispatch(setIsPageLoading(false));
            let message;
            if (type === "delete") {
                message = "Job was successfully deleted.";
            }

            if (type === "cancel") {
                message = "This job will be cancelled and deleted.";
                if (positionsFilled) {
                    message = `${message} ${positionsFilled} worker(s) will be notified.`;
                }
            }
            dispatch(MetaActions.successToast(message));
        })
        .catch(e => {
            dispatch(setIsPageLoading(false));
            dispatch(MetaActions.errorToast(`Failed to ${type} job. (${e.message})`));
            throw e;
        });
};

export const deleteJob = removeJobFactory(DeleteJobMutation, "delete");
export const cancelJob = removeJobFactory(CancelJobMutation, "cancel");

const setRecurringJobList = (data) => ({
    type: types.SET_RECURRING_JOB_LIST,
    payload: data
});

export const fetchJobRecurringJob = (jobId) => dispatch => {
    return ApolloClient.query({
        query: FetchRecurringJobQuery,
        variables: {
            jobId: jobId,
            isFuture: true
        },
    }).then(({ data: { recurringJobsByJobId } }) => {
        dispatch(setRecurringJobList(recurringJobsByJobId));
    }).catch(e => {
        dispatch(MetaActions.errorToast(`Could not get data: ${e.message}`));
    });
}

export const cancelRecurringJob = (recurringJobIds, jobId) => (dispatch, getState) => {
    const { positionsFilled } = getState().jobDetails;
    const { employer } = getState().jobDetails;
    return ApolloClient.mutate({
        mutation: CancelRecurringJobMutation,
        variables: {
            data: {
                employerId: employer.id,
                jobId: Number(jobId),
                recurringJobIds: [...recurringJobIds]
            },
        },
    })
        .then(({ data, errors }) => {
            if (!data && errors.length > 0) {
                throw new Error(errors[0].message);
            } else {
                dispatch(setIsCancelJobDialogState(false));
                let message;
                message = "This job will be cancelled and deleted.";
                if (positionsFilled) {
                    message = `${message} ${positionsFilled} worker(s) will be notified.`;
                }
                dispatch(MetaActions.successToast(message));
                return data
            }

        })
        .catch(e => {
            dispatch(setIsCancelJobDialogState(false));
            dispatch(MetaActions.errorToast(`${e.message}`));
        });
}

export const updatePositionData = field => (positionId, value) => {
    // console.log("updatePosition Data", field, positionId, value);
    return {
        type: types.UPDATE_POSITION_DATA,
        payload: { positionId, field, value },
    };
};

export const addPositionBonus = (positionId, bonus) => (dispatch, getState) => {
    const { id, employerId, positions, positionUpdates } = getState().jobDetails;
    const { fullName } = positions.find(info => info.id === positionId);
    const { workerId } = positionUpdates.find(info => info.id === positionId);

    console.log(fullName, workerId, bonus);

    dispatch(setIsUpdatingPositions(true));
    return ApolloClient.mutate({
        mutation: AddPositionBonus,
        variables: {
            employerId,
            data: {
                jobId: id,
                workerId,
                bonus,
                positionId
            },
        },
    })
        .then(({ data }) => {
            dispatch(updatePosition(data.addPositionBonus));
            dispatch(setIsUpdatingPositions(false));
            dispatch(
                MetaActions.successToast(`${fullName} has been given a ${formatMoney(bonus)} bonus`)
            );
        })
        .catch(e => {
            dispatch(setIsUpdatingPositions(false));
            // Failed so reset to actual data
            dispatch(resetPositionUpdates());
            dispatch(MetaActions.errorToast(`Failed to give ${fullName} a bonus. (${e.message})`));
        });
};
export const batchUpdatePositionData = field => value => ({
    type: types.BATCH_UPDATE_POSITION_DATA,
    payload: { field, value },
});

export const resetPositionUpdates = () => ({
    type: types.RESET_POSITION_UPDATES,
});

export const removePosition = workerId => ({
    type: types.REMOVE_POSITION,
    payload: { workerId },
});

export const removePositionByPositionId = positionId => ({
    type: types.REMOVE_POSITION_BY_POSITION_ID,
    payload: { positionId },
});

export const updateBanList = workerId => ({
    type: types.UPDATE_BAN_LIST,
    payload: { workerId },
});

export const updatePreferList = workerId => ({
    type: types.UPDATE_PREFER_LIST,
    payload: { workerId },
});

export const rateAllWorkers = () => (dispatch, getState) => {
    const { id, positionUpdates, employerId } = getState().jobDetails;
    dispatch(setIsUpdatingPositions(true));
    return ApolloClient.mutate({
        mutation: RateAllWorkersMutation,
        variables: {
            employerId,
            data: {
                jobId: id,
                ratings: positionUpdates.map(({ workerId, rating }) => ({ workerId, rating })),
            },
        },
    })
        .then(({ data }) => {
            dispatch(updatePositions(data.rateAllWorkers));
            dispatch(setIsUpdatingPositions(false));
            dispatch(MetaActions.successToast("Workers have been rated!"));
        })
        .catch(e => {
            dispatch(setIsUpdatingPositions(false));
            // Failed so reset to actual data
            dispatch(resetPositionUpdates());
            dispatch(MetaActions.errorToast(`Failed to rate workers. (${e.message})`));
        });
};

export const checkinAllWorkers = () => (dispatch, getState) => {
    const { id, positionUpdates, employerId } = getState().jobDetails;
    dispatch(setIsUpdatingPositions(true));
    return ApolloClient.mutate({
        mutation: CheckInAllMutation,
        variables: {
            employerId,
            data: {
                jobId: id,
                times: positionUpdates.map(({ workerId, startShift, startShiftUtc, id }) => ({
                    positionId: id,
                    workerId,
                    startShift,
                    startShiftUtc,
                })),
            },
        },
    })
        .then(({ data, errors }) => {
            if (!data && errors.length > 0) {
                throw new Error(errors[0].message);
            }
            dispatch(updatePositions(data.checkInAllWorkers));
            dispatch(setIsUpdatingPositions(false));
            dispatch(MetaActions.successToast("Workers have been checked in."));
        })
        .catch(e => {
            dispatch(setIsUpdatingPositions(false));
            // Failed so reset to actual data
            dispatch(resetPositionUpdates());
            dispatch(MetaActions.errorToast(`Failed to check in workers. (${e.message})`));
        });
};

export const checkoutAllWorkers = () => (dispatch, getState) => {
    const { id, positionUpdates, employerId } = getState().jobDetails;
    dispatch(setIsUpdatingPositions(true));
    return ApolloClient.mutate({
        mutation: CheckOutAllMutation,
        variables: {
            employerId,
            data: {
                jobId: id,
                times: positionUpdates.map(({ workerId, endShift, breakMins, endShiftUtc, id }) => ({
                    positionId: id,
                    workerId,
                    endShift,
                    breakMins,
                    endShiftUtc,
                })),
            },
        },
    })
        .then(({ data, errors }) => {
            if (data) {
                dispatch(updatePositions(data.checkOutAllWorkers));
                dispatch(fetchJob(id, false));
                dispatch(MetaActions.successToast("Workers have been checked out."));
            } else if (errors && errors.length > 0) {
                dispatch(MetaActions.errorToast(errors[0].message));
                dispatch(resetPositionUpdates());
            }
            dispatch(setIsUpdatingPositions(false));
        })
        .catch(e => {

            console.log(e)
            dispatch(setIsUpdatingPositions(false));
            // Failed so reset to actual data
            dispatch(resetPositionUpdates());
            dispatch(MetaActions.errorToast(`Failed to check out workers. (${e.message})`));
        });
};

export const editAllPositionRates = () => (dispatch, getState) => {
    const { id, positionUpdates, employerId } = getState().jobDetails;
    dispatch(setIsUpdatingPositions(true));
    const rates = positionUpdates.reduce((acc, { workerId, billingRate, payRate, isEditable }) => {
        if (isEditable) {
            acc.push({
                workerId,
                // billingRate,
                payRate,
            });
        }

        return acc;
    }, []);
    console.log(rates);
    return ApolloClient.mutate({
        mutation: EditAllPositionRates,
        variables: {
            employerId,
            data: {
                jobId: id,
                rates,
            },
        },
    })
        .then(({ data, errors }) => {
            if (!data && errors.length > 0) {
                throw new Error(errors[0].message);
            } else {
                dispatch(updatePositions(data.editAllPositionRates));
                dispatch(setIsUpdatingPositions(false));
                dispatch(MetaActions.successToast("Pay/Billing rates have been updated."));
            }
        })
        .catch(e => {
            dispatch(setIsUpdatingPositions(false));
            // Failed so reset to actual data
            dispatch(resetPositionUpdates());
            dispatch(MetaActions.errorToast(`Failed to update pay/billing rates. (${e.message})`));
        });
};

export const increasePositions = increaseBy => (dispatch, getState) => {
    const { id: jobId, employerId } = getState().jobDetails;
    dispatch(setIsUpdatingPositions(true));
    return ApolloClient.mutate({
        mutation: IncreaseJobPositionsMutation,
        variables: {
            data: {
                employerId,
                jobId,
                increaseBy,
            },
        },
    })
        .then(({ data: { increasePositions } }) => {
            dispatch(setJob(increasePositions));
            return dispatch(fetchJobWorkers(increasePositions.id, increasePositions.employerId));
        })
        .then(() => {
            const { peopleNeeded } = getState().jobDetails;
            dispatch(setIsUpdatingPositions(false));
            dispatch(MetaActions.successToast(`Job now has ${peopleNeeded} position(s) available.`));
        })
        .catch(e => {
            dispatch(setIsUpdatingPositions(false));
            dispatch(MetaActions.errorToast(`Could not increase positions for job. (${e.message})`));
        });
};

export const decreasePositions = decreaseBy => (dispatch, getState) => {
    const { id: jobId, employerId } = getState().jobDetails;
    dispatch(setIsUpdatingPositions(true));
    return ApolloClient.mutate({
        mutation: DecreaseJobPositionsMutation,
        variables: {
            data: {
                employerId,
                jobId,
                decreaseBy,
            },
        },
    })
        .then(({ data: { decreasePositions } }) => {
            dispatch(setJob(decreasePositions));
            return dispatch(fetchJobWorkers(decreasePositions.id, decreasePositions.employerId));
        })
        .then(() => {
            const { peopleNeeded } = getState().jobDetails;
            dispatch(setIsUpdatingPositions(false));
            dispatch(MetaActions.successToast(`Job now has ${peopleNeeded} position(s) available.`));
        })
        .catch(e => {
            dispatch(setIsUpdatingPositions(false));
            dispatch(MetaActions.errorToast(`Could not decrease positions for job. (${e.message})`));
        });
};

export const checkinWorker = (positionId) => (dispatch, getState) => {
    const { id, employerId, positions, positionUpdates, timezone } = getState().jobDetails;
    const { fullName } = positions.find(info => info.id === positionId);
    const { startShift, startShiftUtc, workerId } = positionUpdates.find(info => info.id === positionId);
    dispatch(setIsUpdatingPositions(true));
    return ApolloClient.mutate({
        mutation: CheckInMutation,
        variables: {
            employerId,
            data: {
                jobId: id,
                workerId,
                startShift,
                startShiftUtc,
                positionId
            },
        },
    })
        .then(({ data, errors }) => {
            if (!data && errors.length > 0) {
                throw new Error(errors[0].message);
            }
            dispatch(updatePosition(data.checkInWorker));
            dispatch(setIsUpdatingPositions(false));
            dispatch(
                MetaActions.successToast(
                    `${fullName} has been checked in at ${convertUTCToTimezoneWithOffset(startShiftUtc, timezone, 'MMM Do h:mm a')}.`
                )
            );
        })
        .catch(e => {
            dispatch(setIsUpdatingPositions(false));
            // Failed so reset to actual data
            dispatch(resetPositionUpdates());
            dispatch(MetaActions.errorToast(`Failed to check in ${fullName}. (${e.message})`));
        });
};

export const checkoutWorker = positionId => (dispatch, getState) => {
    const { id, positionUpdates, employerId, positions, timezone } = getState().jobDetails;
    const { fullName } = positions.find(info => info.id === positionId);
    const { endShift, endShiftUtc, breakMins, workerId } = positionUpdates.find(info => info.id === positionId);
    dispatch(setIsUpdatingPositions(true));
    return ApolloClient.mutate({
        mutation: CheckOutMutation,
        variables: {
            employerId,
            data: {
                jobId: id,
                workerId,
                endShift,
                endShiftUtc,
                breakMins,
                positionId
            },
        },
    })
        .then(({ data, errors }) => {
            if (!data && errors.length > 0) {
                throw new Error(errors[0].message);
            }
            dispatch(updatePosition(data.checkOutWorker));
            dispatch(fetchJob(id, false));
            dispatch(setIsUpdatingPositions(false));
            dispatch(
                MetaActions.successToast(
                    `${fullName} has been checked out at ${convertUTCToTimezoneWithOffset(endShiftUtc, timezone, 'MMM Do h:mm a')}.`,
                ),
            );
        })
        .catch(e => {
            dispatch(setIsUpdatingPositions(false));
            // Failed so reset to actual data
            dispatch(resetPositionUpdates());
            dispatch(MetaActions.errorToast(`Failed to check out ${fullName}. (${e.message})`));
        });
};

export const removeWorker = (positionId, suspend, ban = false) => (dispatch, getState) => {
    const { id, positions } = getState().jobDetails;
    const position = positions.find(info => info.id === positionId);
    dispatch(setIsUpdatingPositions(true));
    return ApolloClient.mutate({
        mutation: RemovePositionMutation,
        variables: {
            data: {
                jobId: id,
                workerId: position.workerId,
                suspend,
                positionId: position.id,
                ...(ban ? { ban } : {})
            },
        },
    })
        .then(() => {
            dispatch(removePositionByPositionId(positionId));
            dispatch(setIsUpdatingPositions(false));
            if (suspend) {
                dispatch(
                    MetaActions.successToast(
                        `${position.fullName} has been removed and suspended from taking jobs for 7 days.`,
                    ),
                );
            } else {
                dispatch(MetaActions.successToast(`${position.fullName} has been removed from the job.`));
            }
        })
        .catch(e => {
            dispatch(setIsUpdatingPositions(false));
            // Failed so reset to actual data
            dispatch(resetPositionUpdates());
            dispatch(MetaActions.errorToast(`Failed to remove ${position.fullName}. (${e.message})`));
        });
};

export const removeWorkerFromOtherJob = (jobId, workerId, positionId) => (dispatch) => {
    dispatch(setIsUpdatingPositions(true));
    return ApolloClient.mutate({
        mutation: RemovePositionMutation,
        variables: {
            data: {
                jobId,
                workerId,
                positionId,
                suspend: false
            },
        },
    })
        .then(({ data }) => {
            if (!data) {

            } else {
                dispatch(setIsUpdatingPositions(false));
                dispatch(MetaActions.successToast(`Worker has been removed from the job.`));
            }

        })
        .catch(e => {
            dispatch(setIsUpdatingPositions(false));
            dispatch(MetaActions.errorToast(`Failed to remove worker. (${e.message})`));
        });
};

export const editWorkerRates = (positionId, payRate, billRate) => (dispatch, getState) => {
    const { id, employerId, positions, positionUpdates } = getState().jobDetails;
    const { fullName } = positions.find(info => info.id === positionId);
    const { billingRate, workerId } = positionUpdates.find(info => info.id === positionId);
    dispatch(setIsUpdatingPositions(true));
    return ApolloClient.mutate({
        mutation: EditPositionRates,
        variables: {
            employerId,
            data: {
                jobId: id,
                workerId,
                payRate,
                positionId
                // billingRate: billRate ? Number(billRate) : Number(billingRate),
            },
        },
    })
        .then(({ data }) => {
            dispatch(updatePosition(data.editPositionRates));
            dispatch(setIsUpdatingPositions(false));
            dispatch(MetaActions.successToast(`${fullName}'s rate has been updated.`));
            return data;
        })
        .catch(e => {
            dispatch(setIsUpdatingPositions(false));
            // Failed so reset to actual data
            dispatch(resetPositionUpdates());
            dispatch(MetaActions.errorToast(`Failed to change ${fullName}'s rates. (${e.message})`));
        });
};

export const editWorkerRatesFromDialog = (positionId, payRate, jobId, workerId) => (dispatch, getState) => {
    console.log(positionId, payRate, jobId, workerId)
    return ApolloClient.mutate({
        mutation: EditPositionRatesFromDialog,
        variables: {
            data: {
                jobId,
                workerId,
                payRate,
                positionId
                // billingRate: billRate ? Number(billRate) : Number(billingRate),
            },
        },
    })
        .then((res) => {
            console.log(res);
            dispatch(fetchPositions());
            dispatch(MetaActions.successToast(`Rate has been updated.`));
            // return data;
        })
        .catch(e => {
            console.log(e);
            dispatch(MetaActions.errorToast(`Failed to change rates. (${e.message})`));
        });
};

export const noShowWorker = (workerId, ban) => (dispatch, getState) => {
    const { id, positions, employer } = getState().jobDetails;
    const { fullName } = positions.find(info => info.workerId === workerId);
    dispatch(setIsUpdatingPositions(true));
    return ApolloClient.mutate({
        mutation: NoShowPositionMutation,
        variables: {
            data: {
                jobId: id,
                workerId,
                ban,
            },
        },
    })
        .then(() => {
            dispatch(removePosition(workerId));
            dispatch(setIsUpdatingPositions(false));
            if (ban) {
                dispatch(
                    MetaActions.successToast(
                        `${fullName} has been removed, suspended, and banned from ${employer.companyName}'s job site`
                    )
                );
            } else {
                // dispatch(suspendWorker(workerId, 7, `Flagged as a no show. Job [${id}]`));
                dispatch(
                    MetaActions.successToast(
                        `${fullName} has been removed from the job and suspended for 7 days`
                    )
                );
            }
        })
        .catch(e => {
            dispatch(setIsUpdatingPositions(false));
            // Failed so reset to actual data
            dispatch(resetPositionUpdates());
            dispatch(MetaActions.errorToast(`Failed to flag ${fullName} as a no show. (${e.message})`));
        });
};

export const preferWorker = workerId => (dispatch, getState) => {
    const { positions, employerId, employer, id } = getState().jobDetails;
    const { fullName } = positions.find(info => info.workerId === workerId);
    dispatch(setIsUpdatingPositions(true));
    return ApolloClient.mutate({
        mutation: PreferWorkerMutation,
        variables: {
            data: {
                workerId,
                employerId,
            },
        },
    })
        .then(({ data }) => {
            return Promise.all([data, dispatch(fetchJobWorkers(id, employerId))]);
        })
        .then(([data]) => {
            dispatch(updatePreferList(data.preferWorker.workerId));
            dispatch(setIsUpdatingPositions(false));
            dispatch(
                MetaActions.successToast(`${fullName} has been preferred by ${employer.companyName}`)
            );
        })
        .catch(e => {
            dispatch(setIsUpdatingPositions(false));
            dispatch(MetaActions.errorToast(`Failed to prefer ${fullName}. (${e.message})`));
        });
};

export const banWorker = (workerId, reason) => (dispatch, getState) => {
    const { positions, employerId, employer, id } = getState().jobDetails;
    const { fullName } = positions.find(info => info.workerId === workerId);
    dispatch(setIsUpdatingPositions(true));
    return ApolloClient.mutate({
        mutation: BanWorkerMutation,
        variables: {
            data: {
                workerId,
                employerId,
                reason,
            },
        },
    })
        .then(({ data }) => {
            dispatch(fetchJob(id, false));
            dispatch(updateBanList(data.banWorker.workerId));
            dispatch(setIsUpdatingPositions(false));
            // dispatch(suspendWorker(workerId, 7, `Flagged as a no show. Job [${id}]`));
            dispatch(MetaActions.successToast(`You've banned ${fullName} from ${employer.companyName}`));
        })
        .catch(e => {
            dispatch(setIsUpdatingPositions(false));
            dispatch(MetaActions.errorToast(`Failed to ban ${fullName}. (${e.message})`));
        });
};

export const rateWorker = (workerId, rating) => (dispatch, getState) => {
    const { id, positions, employerId } = getState().jobDetails;
    const { fullName } = positions.find(info => info.workerId === workerId);
    //   const { rating } = positionUpdates.find(info => info.workerId === workerId);
    dispatch(setIsUpdatingPositions(true));
    return ApolloClient.mutate({
        mutation: RateWorkerMutation,
        variables: {
            employerId,
            data: {
                jobId: id,
                workerId,
                rating,
            },
        },
    })
        .then(({ data }) => {
            dispatch(updatePosition(data.rateWorker));
            dispatch(setIsUpdatingPositions(false));
            dispatch(MetaActions.successToast(`You've given ${fullName} a ${rating} star rating!`));
        })
        .catch(e => {
            dispatch(setIsUpdatingPositions(false));
            // Failed so reset to actual data
            dispatch(resetPositionUpdates());
            dispatch(MetaActions.errorToast(`Failed to rate ${fullName}. (${e.message})`));
        });
};

export const clearException = (workerId, type) => (dispatch, getState) => {
    const { positions, employerId, id } = getState().jobDetails;
    const { fullName } = positions.find(info => info.workerId === workerId);
    dispatch(setIsUpdatingPositions(true));
    return ApolloClient.mutate({
        mutation: ClearExceptionMutation,
        variables: {
            data: {
                workerId,
                employerId,
            },
        },
    })
        .then(() => {
            return dispatch(fetchJobWorkers(id, employerId));
        })
        .then(() => {
            dispatch({
                type: types.REMOVE_PREFER_BAN,
                payload: { workerId },
            });
            dispatch(setIsUpdatingPositions(false));
            dispatch(MetaActions.successToast(`${fullName} is no longer ${type}`));
        })
        .catch(e => {
            dispatch(setIsUpdatingPositions(false));
            dispatch(MetaActions.errorToast(`${fullName} is still ${type}. (${e.message})`));
        });
};

export const confirmWorker = (workerId, isGoing) => (dispatch, getState) => {
    const { id, positions, employerId } = getState().jobDetails;
    const { fullName } = positions.find(info => info.workerId === workerId);
    dispatch(setIsUpdatingPositions(true));
    return ApolloClient.mutate({
        mutation: ConfirmWorkerMutation,
        variables: {
            employerId,
            data: {
                jobId: id,
                workerId,
                isGoing,
            },
        },
    })
        .then(({ data }) => {
            dispatch(updatePosition(data.confirmWorker));
            dispatch(setIsUpdatingPositions(false));
            dispatch(MetaActions.successToast(`You've marked ${fullName} as confirmed for the job!`));
        })
        .catch(e => {
            dispatch(setIsUpdatingPositions(false));
            // Failed so reset to actual data
            dispatch(resetPositionUpdates());
            dispatch(MetaActions.errorToast(`Failed to mark ${fullName} as confirmed. (${e.message})`));
        });
};

export const reconfirmWorker = (workerId, isGoing) => (dispatch, getState) => {
    const { id, positions, employerId } = getState().jobDetails;
    const { fullName } = positions.find(info => info.workerId === workerId);
    dispatch(setIsUpdatingPositions(true));
    return ApolloClient.mutate({
        mutation: ReconfirmWorkerMutation,
        variables: {
            employerId,
            data: {
                jobId: id,
                workerId,
                isGoing,
            },
        },
    })
        .then(({ data }) => {
            dispatch(updatePosition(data.reconfirmWorker));
            dispatch(setIsUpdatingPositions(false));
            dispatch(MetaActions.successToast(`You've marked ${fullName} as reconfirmed for the job!`));
        })
        .catch(e => {
            dispatch(setIsUpdatingPositions(false));
            // Failed so reset to actual data
            dispatch(resetPositionUpdates());
            dispatch(MetaActions.errorToast(`Failed to mark ${fullName} as reconfirmed. (${e.message})`));
        });
};

export const setEmployerJobReminder = (jobId) => (dispatch) => {
    ApolloClient.mutate({
        mutation: EmployerJobReminderMutation,
        variables: {
            data: { jobId },
        },
    }).then(({ data }) => {
        dispatch(MetaActions.successToast(`Job reminder updated successfully`));
    })
        .catch(e => {
            console.log(e)
            dispatch(
                MetaActions.errorToast(`${e.message}`),
            );
        });
};

export const fetchJobTimeline = () => (dispatch, getState) => {
    const { id: jobId } = getState().jobDetails;
    dispatch(setJobTimelineLoading(true))
    return ApolloClient.query({
        query: FetchJobTimeQuery,
        variables: {
            jobId,
        },
    })
        .then(({ data }) => {
            if (data && data.jobTimelines) {
                dispatch(setJobTimelineData(data.jobTimelines));
            }
            dispatch(setJobTimelineLoading(false))
        })
        .catch(e => {
            dispatch(setJobTimelineLoading(false))
            dispatch(MetaActions.errorToast(`Failed to get data. (${e.message})`));
        });
};
