import { gql } from "@apollo/client";
import PayrollFields from "../fragments/payroll-fragment";

export default gql`
  mutation dequeueJobPay($data: DequeueJobPayInput!) {
    dequeueJobPay(data: $data) {
      ...PayrollFields
    }
  }
  ${PayrollFields}
`;
