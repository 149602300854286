/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import SwipeableViews from "react-swipeable-views";
import { Tab, Tabs } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import CircularProgress from "@mui/material/CircularProgress";
import Grid from "@mui/material/Grid";
import { isEmpty } from "lodash";
import MainContent from "components/Containers/MainContent";
import {
    actions as EmployerDetailActions,
    selectors as EmployerDetailSelectors,
} from "store/modules/employerDetails";
import { actions as JobActions, selectors as JobSelectors } from "store/modules/jobs";

import EmployerInfo from "./components/EmployerInfo";
import FillPositionDialog from "./components/FillPositionDialog";
import JobInfo from "./components/JobInfo";
import JobListTable from "./components/JobListTable";
import PositionTable from "./components/PositionTable";
import StateInfo from "./components/StateInfo";
import JobActionMenu from "./components/JobActionMenu";
import EditJobDialog from "./components/EditJobDialog";
import Dialog from "components/Dialog/Dialog";
import DeletePositionDialog from "./components/DeletePositionDialog";
const useStyles = makeStyles(({ spacing, palette, shape, customPalette }) => ({
    root: {
        backgroundColor: `${palette.common.white}`,
        border: `1px solid ${palette.grey[500]}`,
        borderRadius: shape.borderRadius / 2,
        padding: spacing(3),
        marginTop: spacing(2),
    },
    progress: {
        color: "#11b2a8",
        position: "absolute",
        top: "50%",
        left: "45%",
    },
    tabbedTableContainer: {
        marginTop: spacing(3),
    },
    tableContainer: {
        width: "100%",
    },
    tabIndicator: {
        display: "none !important",
    },
    tabRoot: {
        marginRight: spacing(1),
        borderRadius: `${shape.borderRadius}px`,
        opacity: 1,
        "&:hover": {
            border: `1px solid ${palette.secondary.main}`,
        },
    },
    tabLabelContainer: {
        padding: 0,
    },
    tabLabel: {
        fontSize: "1rem",
    },
    tabNav: {
        colorSecondary: {
            color: "#FFF",
        },
        boxShadow: "none",
        root: {
            "& > *": {
                margin: spacing(0),
                padding: spacing(0),
                width: "100%",
                // height: 400,
                boxShadow: "none",
            },
            boxShadow: "none",
        },
    },
    tabSelected: {
        border: `2px solid ${palette.secondary.main}`,
        backgroundColor: customPalette.primary[200],
        "&:hover": {
            border: `2px solid ${palette.secondary.main}`,
        },
    },
}));
const PrivateJobDetails = props => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const tabClass = {
        root: classes.tabRoot,
        selected: classes.tabSelected,
    };
    const [value, setValue] = useState(0);
    const showDeleteDialog = useSelector(JobSelectors.isOpenDeletePrivateJobPositionDialog);
    const open = useSelector(JobSelectors.isOpenPrivateJobFillPositionDialog);

    const { isApplyingAction, match } = props;
    const [workerId, setWorkerId] = useState('');

    const privateJobDetail = useSelector(JobSelectors.getImportedPrivateJobDetail);
    const isLoading = useSelector(JobSelectors.getPrivateJobLoading);
    const openEdit = useSelector(JobSelectors.isOpenEditPrivateJobDialog);
    const employerDetail = useSelector(EmployerDetailSelectors.getEmployer);

    useEffect(
        () => {
            if (!isEmpty(privateJobDetail)) {
                dispatch(EmployerDetailActions.fetchEmployer(privateJobDetail.employerId));
            }
        },
        [privateJobDetail],
    );
    useEffect(() => {
        dispatch(JobActions.fetchImportedPrivateJobDetail(match.params.id));
    }, []);

    const handelChangeTab = (event, value) => {
        setValue(value);
    };

    const openDialog = () => {
        dispatch(JobActions.openPrivateJobFillPositionDialog(true));
    };
    const closeDialog = () => {
        dispatch(JobActions.openPrivateJobFillPositionDialog(false));
        dispatch(JobActions.setFillPositionErrorData([]))
    };

    const openEditDialog = () => {
        dispatch(JobActions.openEditPrivateJobDialog(true));
    };
    const closeEditDialog = () => {
        dispatch(JobActions.openEditPrivateJobDialog(false));
        dispatch(JobActions.setUpdatePrivateJobErrorData([]))
    };

    const addWorker = (workerId) => {
        const request = {
            privateJobRecurringId: privateJobDetail.id,
            workerId
        }
        dispatch(JobActions.fillPositionByPrivateJobRecurringId(request));
    };

    const openDeleteDialog = (id) => {
        setWorkerId(id)
        dispatch(JobActions.openDeletePrivateJobPositionDialog(true))
    };

    const closeDeleteDialog = () => {
        setWorkerId('')
        dispatch(JobActions.openDeletePrivateJobPositionDialog(false))
        dispatch(JobActions.setDeletePrivateJObPositionErrorData([]))
    }

    if (isLoading) {
        return <CircularProgress classes={{ root: classes.progress }} />;
    }

    if (isEmpty(privateJobDetail)) {
        return null;
    }

    return (
        <React.Fragment>
            <MainContent isApplyingAction={isApplyingAction}>
                <Grid container>
                    <StateInfo id={privateJobDetail.id} />
                    <Grid container item xs={6} justifyContent="flex-end" alignItems="center">
                        <JobActionMenu
                            jobId={privateJobDetail.id}
                            openEditDialog={openEditDialog}
                        />
                    </Grid>
                </Grid>
                <Grid container className={classes.root}>
                    {!isEmpty(employerDetail) && (
                        <EmployerInfo employerDetail={employerDetail} detail={privateJobDetail} />
                    )}
                    <JobInfo detail={privateJobDetail} />
                </Grid>
                <Grid
                    container
                    className={classes.tabbedTableContainer}
                    alignItems="center"
                    justifyContent="flex-end"
                >
                    <Grid container item xs={12}>
                        <Tabs
                            value={value}
                            onChange={handelChangeTab}
                            classes={{ indicator: classes.tabIndicator }}
                        >
                            <Tab label="Jobs" classes={tabClass} />
                            <Tab label="Workers" classes={tabClass} />
                        </Tabs>
                    </Grid>
                </Grid>
                <Grid container item xs={12} className={classes.tabbedTableContainer}>
                    <SwipeableViews axis="x" index={value} className={classes.tableContainer}>
                        <JobListTable id={privateJobDetail.id} />
                        <PositionTable id={privateJobDetail.id} openDialog={openDialog} openDeleteDialog={openDeleteDialog} />
                    </SwipeableViews>
                </Grid>
            </MainContent>
            <FillPositionDialog open={open} onCancel={closeDialog} tags={privateJobDetail.tags} addWorker={addWorker} />
            <EditJobDialog open={openEdit} onCancel={closeEditDialog} privateJobDetail={privateJobDetail} />
            <DeletePositionDialog open={showDeleteDialog} onCancel={closeDeleteDialog} privateJobDetail={privateJobDetail} workerId={workerId} />
        </React.Fragment>
    );
};

export default PrivateJobDetails;
