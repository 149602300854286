/* eslint-disable react/prop-types */
/* eslint-disable react/display-name */
import React from "react";
import { useSelector } from "react-redux";
import Tooltip from "@mui/material/Tooltip";
import Line from "components/Placeholders/Line";
import { selectors as JobDetailSelectors } from "store/modules/jobDetails";
import DisableCellOverlay from "../../components/Controls/DisableCellOverlay";
import ConfirmMenu from "../../components/Menus/ConfirmMenu";
import Can from "components/Authorization/Can";
import { PositionPermissions } from "helpers/constants/permissions";

export default function useReConfirmed(disabled) {
    const canAddWorker = useSelector(JobDetailSelectors.getCanAddWorker);
    return {
        id: "reconfirmed",
        width: 60,
        Header: () => (
            <Tooltip title="ReConfirmed" placement="top">
                <span>R.</span>
            </Tooltip>
        ),
        Cell: ({ row: { original: { workerId, isReconfirmed, reconfirmedAt } } }) => {
            return (
                <>
                    <ConfirmMenu workerId={workerId} isConfirmed={isReconfirmed} isReconfirmed confirmedAt={reconfirmedAt} />
                    <DisableCellOverlay visible={disabled} />
                </>
            );
        },
        Footer: (<Can perform={PositionPermissions.create.fill.value}>
            {allowed => {
                if (!allowed) {
                    return null;
                }
                return canAddWorker && !disabled ? <Line /> : null
            }}
        </Can>)
    };
}
