export const paginationSettings = {
  workerPayrollReport: {
    prefix: "workerPayrollReport",
    defaultPageSize: 10,
  },
  workerWisePayrollReport: {
    prefix: "workerWisePayrollReport",
    defaultPageSize: 10,
  },
  workerJobReport: {
    prefix: "workerJobReport",
    defaultPageSize: 10,
  },
  activeEmployerReport: {
      prefix: "activeEmployerReport",
      defaultPageSize: 10,
  },
  WSBCReport: {
    prefix: "WSBCReport",
    defaultPageSize: 10,
},
  workerJobCountReport: {
      prefix: "workerJobCountReport",
      defaultPageSize: 40,
  }
};
