import { gql } from "@apollo/client";

export default gql`
  mutation addWorkerNote($data: WorkerNoteInput!) {
    addWorkerNote(data: $data) {
      id
      note
      noteType
      workerId
      createdAt
      agent
    }
  }
`;
