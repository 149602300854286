import { gql } from "@apollo/client";

export default gql`
  fragment EmployerFields on Employer {
    id
    # qbId
    companyName
    websiteUrl
    invoices {
      id
      employerId
      invoiceYear
      invoiceWeek
      invoiceFileUrl
      qbInvoiceId

      invoiceItems {
        id
        createdAt

        jobDate
        worker
        jobId
        positionId
        invoiceId
        rate
        checkIn
        # checkOutUtc
        checkInUtc
        checkOut
        breakDeductionMins
        totalUnits
        invoicableUnits
        isInvoicable
        hasAdjustments
        payroll {
            position{
                id
                jobId
                workerId
                job{
                    timezone
                }
                worker{
                    firstName
                    lastName
                }
            }
            payrollType{
                id
                name
            }
            createdAt
            grossEarnings
            netEarnings
            rate
            breakDeductionMins
            payrollTypeId
            positionId
            bonus
            totalUnits
            payableUnits
          }
      }
      createdAt
    }
    firstName
    lastName
    title
    email
    mobilePhone
    businessPhone
    street
    city
    region
    country
    postalCode
    isActive
    isApproved
    createdAt
    approvedAt
    allowPreferredOnly
    isMassCheckinEnabled
    isAutoConfirmEnabled
    positionCount
    jobCount
    rating
    hoursWorkedCount
    billingRate
    payRate
    createdAtUtc
    approvedAtUtc
    jobNotification
    canPostPrivateJob
    employerNote{
      note
      adminId
      adminName
      adminEmail
      createdAt
    }
    allowVaccinatedOnly
    banned {
      workerId
    }
    preferred {
      workerId
    }
    employerRateAuditLogs{
      id
      adminEmail
      adminName
      oldPayRate
      oldBillingRate
      newPayRate
      newBillingRate
      companyName
      createdAt
    }
  }
`;
