import { gql } from "@apollo/client";
import EmployerFields from "../fragments/employer-fragment";
import JobFields from "../fragments/job-fragment";
import PositionFields from "../fragments/position-fragment";

export default gql`
  mutation increasePositions($data: IncreaseJobPositionsInput!, $timezone: String) {
    increasePositions(data: $data, timezone: $timezone) {
      ...JobFields
      employer {
        ...EmployerFields
      }
      positions {
        ...PositionFields
      }
    }
  }
  ${JobFields}
  ${EmployerFields}
  ${PositionFields}
`;
