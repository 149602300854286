import { gql } from "@apollo/client";

export const UpdateEmployerProfileMutation = gql`
  mutation editEmployer($data: EditEmployerInput!) {
    editEmployer(data: $data) {
      qbId
      id
      createdAt
      createdAtUtc
      approvedAtUtc
      #   updatedAt
      email
      firstName
      lastName
      billingType
      street
      city
      region
      postalCode
      country
      companyName
      websiteUrl
      description
      businessPhone
      mobilePhone
      title
      isActive
      isApproved
      allowPreferredOnly
      approvedAtUtc
      profileImageUrl
      logoImageUrl
      signedLogoImageUrl
      payRate
      billingRate
      isMassCheckinEnabled
      isAutoConfirmEnabled
      isDailySummaryEmailEnabled
      invoices {
        id
        employerId
        invoiceYear
        invoiceWeek
        invoiceFileUrl
        qbInvoiceId

        invoiceItems {
          id
          createdAt
          jobDate
          worker
          jobId
          positionId
          invoiceId
          rate
          checkIn
          checkOut
          # checkOutUtc
          checkInUtc
          breakDeductionMins
          totalUnits
          invoicableUnits
          isInvoicable
          hasAdjustments
          payroll{
            position{
                id
                jobId
                workerId
                job{
                    timezone
                }
                worker{
                    firstName
                    lastName
                }
            }
            payrollType{
                id
                name
            }
            createdAt
            grossEarnings
            netEarnings
            rate
            breakDeductionMins
            payrollTypeId
            positionId
            bonus
            totalUnits
            payableUnits
          }
        }
        createdAt
      }
      #   dcCustomerNumber
    }
  }
`;
export default UpdateEmployerProfileMutation;
