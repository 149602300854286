import React from "react";
import Grid from "@mui/material/Grid";
import makeStyles from '@mui/styles/makeStyles';
import EmployerInfo from "./components/EmployerInfo";
import JobInfo from "./components/JobInfo";

const useStyles = makeStyles(({ spacing, palette, shape }) => ({
  root: {
    backgroundColor: `${palette.common.white}`,
    border: `1px solid ${palette.grey[500]}`,
    borderRadius: shape.borderRadius / 2,
    padding: spacing(3),
    marginTop: spacing(2),
  },
}));

function JobSummary() {
  const classes = useStyles();
  return (
    <Grid container className={classes.root}>
      <EmployerInfo />
      <JobInfo />
    </Grid>
  );
}

export default JobSummary;
