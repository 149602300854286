import { gql } from "@apollo/client";

export default gql`
  query checkPositionForADay($data: FillPositionInput!) {
    checkPositionForADay(data: $data) {
      id
      jobId
      workerId
      payrollStatus
      payrolls{
        id
      }
    }
  }
`;
