import { JobStatusesMap, PaymentConstants, PaymentStatusCode } from "helpers/constants/index";
import { JobPermissions } from "helpers/constants/permissions";
import Decimal from "decimal.js";
import { hasPermission } from "utils/authorize";
import * as pagination from "utils/graphql-pagination";
import { calculateMinimumTargetPrice } from "utils/money";
import { paginationSettings } from "./constants";
import _ from "lodash";

export const getAllWorkersPaginationData = state => {
  return state.jobDetails[pagination.getPageInfoAttr(paginationSettings.allWorkers)];
};

export const getPreferredWorkersPaginationData = state => {
  return state.jobDetails[pagination.getPageInfoAttr(paginationSettings.preferredWorkers)];
};

export const getWaitlistWorkersPaginationData = state => {
  return state.jobDetails[pagination.getPageInfoAttr(paginationSettings.waitlistWorkers)];
};

export const getWorkerListData = (state, { list, part }) => {
  return state.jobDetails.workerPickers[list][part];
};

export const getJobDetails = state => {
  return state.jobDetails;
};

// Loading selectors
export const getLoadingStateFactory = loader => state => {
  return state.jobDetails.loading[loader] || false;
};

export const getIsPageLoading = getLoadingStateFactory("page");
export const getIsAddingWorker = getLoadingStateFactory("addingWorker");
export const getIsActivatingJob = getLoadingStateFactory("activatingJob");
export const getIsUpdatingPositions = getLoadingStateFactory("updatingPositions");
export const getIsApplyingAction = getLoadingStateFactory("actions");
export const getIsInitializingJobPayroll = getLoadingStateFactory("initializingJobPayroll");
export const getIsUninitializingJobPayroll = getLoadingStateFactory("uninitializingJobPayroll");
export const getIsPayingJob = getLoadingStateFactory("payJob");
export const getJobTimelineLoading = getLoadingStateFactory("jobTimeline");

// Dialog selector
export const getCancelJobDialogState = (state) => {
  return state.jobDetails.isCancelJobDialog;
};

// Position Action States
export const getCanCheckInWorker = (positionId) => state => {
  const status = getJobStatus(state);

  return (
    (status === JobStatusesMap.InProgress || status === JobStatusesMap.Completed) &&
    getIsActive(state) &&
    getIsPositionEditable(positionId)(state)
  );
};

export const getCanCheckOutWorker = positionId => state => {
  const status = getJobStatus(state);
  const { startShift } = getPosition(positionId)(state);
  return (
    (status === JobStatusesMap.InProgress || status === JobStatusesMap.Completed) &&
    startShift &&
    getIsActive(state) &&
    getIsPositionEditable(positionId)(state)
  );
};

// Job Data accessors
export const getPositions = state => {
  return state.jobDetails.positions || [];
};

export const getPositionUpdates = state => {
  return state.jobDetails.positionUpdates || [];
};

export const getPreferred = state => {
  return state.jobDetails.preferred || [];
};

export const getBanned = state => {
  return state.jobDetails.banned || {};
};

export const getPayrollSummary = state => {
  return state.jobDetails.payrollSummary || [];
};

export const getEmployer = state => {
  return state.jobDetails.employer || {};
};

export const getJobId = state => {
  return state.jobDetails.id;
};

export const getPositionsFilled = state => {
  return state.jobDetails.positionsFilled;
};

export const getPeopleNeeded = state => {
  return state.jobDetails.peopleNeeded;
};

export const getIsActive = state => {
  return state.jobDetails.isActive;
};

export const getJobStatus = state => state.jobDetails.status;

export const getJobStartUtc = state => {
  return state.jobDetails.startUtc;
};

export const getJobEndUtc = state => {
  return state.jobDetails.endUtc;
};
export const getJobTimezone = state => {
  return state.jobDetails.timezone;
};
export const getIsBreakRequiredForJob = state => {
  return state.jobDetails.isBreakRequired;
};

export const getJobBreakMins = state => {
  return state.jobDetails.breakMins || 0;
};

export const getJobPaidBreakMins = state => {
  return state.jobDetails.paidBreakMins || 0;
};

export const getJobWorkType = state => {
  return state.jobDetails.workType || {};
};

export const getJobWorkTypeLabel = state => {
  return getJobWorkType(state).label || "";
};

export const getJobDescription = state => {
  return state.jobDetails.description || "";
};
export const getJobUnit = state => {
  return state.jobDetails.unit || "";
};

export const getJobStreet = state => {
  return state.jobDetails.street || "";
};

export const getJobCity = state => {
  return state.jobDetails.city || "";
};

export const getNotifyEmployer = state => {
  return state.jobDetails.notifyEmployer;
};

export const getJobRegion = state => {
  return state.jobDetails.region || "";
};

export const getJobAddress = state => {
  return `${getJobStreet(state)}, ${getJobCity(state)}, ${getJobRegion(state)}, Canada`;
};

export const getEmployerId = state => {
  return state.jobDetails.employerId;
};

export const getJobDurationMins = state => {
  return state.jobDetails.durationMins || 0;
};

export const getJobDurationMinsLessBreak = state => {
  return state.jobDetails.durationMinsLessBreak || 0;
};

export const getJobBreakInfoLabel = state => {
  const breakMins = getJobBreakMins(state);
  const paidBreakMins = getJobPaidBreakMins(state);
  return breakMins === 0 ? `Paid Break (${paidBreakMins} mins)` : `Unpaid Break (${breakMins} mins)`;
};

export const getCompanyName = state => {
  return getEmployer(state).companyName || "";
};

export const getEmployerContact = state => {
  const { firstName, lastName } = getEmployer(state);

  if (!firstName && !lastName) {
    return "";
  }

  return `${firstName || ""}${firstName ? " " : ""}${lastName || ""}`;
};

export const getEmployerEmail = state => {
  return getEmployer(state).email || "";
};

export const getEmployerMobilePhone = state => {
  return getEmployer(state).mobilePhone || "";
};

export const getEmployerRating = state => {
  return getEmployer(state).rating;
};

export const getEmployerJobCount = state => {
  return getEmployer(state).jobCount || 0;
};

export const getEmployerBillingRate = state => {
  return getEmployer(state).billingRate || 0;
};

export const getEmployerPayRate = state => {
  return getEmployer(state).payRate || 0;
};

export const getRecurringJobList = state => {
  return state.jobDetails.recurringJob || [];
};

// Helpers
export const getEstimatedBillableMins = state => {
  const durationMinsLessBreak = getJobDurationMinsLessBreak(state);
  return getPositions(state).reduce(acc => {
    return acc + durationMinsLessBreak;
  }, 0);
};

export const getEstimatedPayable = state => {
  const durationMinsLessBreak = getJobDurationMinsLessBreak(state);
  return getPositions(state).reduce((acc, { payRate }) => {
    return acc + durationMinsLessBreak * (payRate / 60);
  }, 0);
};

export const getEstimatedBillable = state => {
  const durationMinsLessBreak = getJobDurationMinsLessBreak(state);
  return getPositions(state).reduce((acc, { billingRate }) => {
    return acc + durationMinsLessBreak * (billingRate / 60);
  }, 0);
};

export const getActualBillableMins = state => {
  return getPositions(state).reduce((acc, { durationMins }) => {
    return acc + durationMins;
  }, 0);
};

export const getActualPayable = state => {
  return getPositions(state).reduce((acc, { durationMins, payRate, bonus }) => {
    return acc + durationMins * (payRate / 60) + bonus;
  }, 0);
};

export const getActualBillable = state => {
  return getPositions(state).reduce((acc, { durationMins, billingRate }) => {
    return acc + durationMins * (billingRate / 60);
  }, 0);
};

export const getIsValidPayRates = state => {
  return !getPositionUpdates(state).some(({ payRate }) => payRate < PaymentConstants.MINIMUM_WAGE);
};

export const getIsValidBillingRates = state => {
  return !getPositionUpdates(state).some(
    ({ payRate, billingRate }) => calculateMinimumTargetPrice(payRate) > billingRate,
  );
};

export const getEmployerMarginTarget = state => {
  const billingRate = new Decimal(getEmployerBillingRate(state));
  const payRate = new Decimal(getEmployerPayRate(state));

  return {
    percentage: billingRate
      .minus(payRate)
      .dividedBy(billingRate)
      .toNumber(),
    dollars: billingRate.minus(payRate).toNumber(),
  };
};

export const getIsJobFilled = state => {
  return getPositionsFilled(state) >= getPeopleNeeded(state);
};

export const getIsSearchingForWorkers = state => {
  const status = getJobStatus(state);
  return (
    !(status === JobStatusesMap.InProgress || status === JobStatusesMap.Completed) &&
    getIsActive(state) &&
    !getIsJobFilled(state)
  );
};

export const getIsAllCheckedIn = state => {
  return getPositions(state).every(({ startShift }) => !!startShift);
};

export const getIsAllCheckedOut = state => {
  return getPositions(state).every(({ endShift }) => !!endShift);
};

export const getIsBonusApplied = state => {
  return getPositions(state).some(({ bonus }) => !!bonus);
};

export const getIsJobEmpty = state => {
  return getPositionsFilled(state) === 0;
};

export const getUpdatedPosition = positionId => state => {
  return getPositionUpdates(state).find(info => info.id === positionId) || {};
};

export const getIsMissingShiftInfo = state => {
  const arr = getPositions(state);
  const durationSum = _.sumBy(arr, 'durationMins');
  return durationSum <= 0;
  // return getPositions(state).some(({ durationMins }) => durationMins === 0);
};

export const getHasEditablePosition = state => {
  const positions = getPositions(state);
  return positions.some(({ isEditable }) => isEditable);
};

export const getIsPaymentPending = state => {
  const everyPositionHasPayroll = getPositions(state).every(
    ({ payrolls }) => payrolls && payrolls.length > 0,
  );

  if (!everyPositionHasPayroll) {
    return false;
  }

  return state.jobDetails.positions.some(({ payrolls }) => {
    return payrolls.some(({ payments }) => {
      return payments.some(({ paymentStatus }) => paymentStatus.code === PaymentStatusCode.Queued);
    });
  });
};

export const getIsPaid = state => {
  if (!state.jobDetails.positions) {
    return false;
  }

  const everyPositionHasPayroll = state.jobDetails.positions.every(
    ({ payrolls }) =>
      payrolls && payrolls.length > 0 && payrolls.some(({ payments }) => payments.length !== 0),
  );

  if (!everyPositionHasPayroll) {
    return false;
  }

  return state.jobDetails.positions.every(({ payrolls }) => {
    return payrolls.every(({ payments }) => {
      return payments.every(({ paymentStatus }) => paymentStatus.code !== PaymentStatusCode.Queued);
    });
  });
};

export const getHasUnpaidPayroll = state => {
  if (!state.jobDetails.positions) {
    return false;
  }

  return state.jobDetails.positions.some(position => {
    if (!position.payroll) {
      return true;
    }
    return position.payroll.some(({ payments }) => payments.length === 0);
  });
};

// Action states
export const getCanDequeuePay = state => {
  // Any payroll has a queued payment
  return getPositions(state).some(({ payrolls }) => {
    return payrolls
      ? payrolls.some(({ payments }) => {
        return payments.some(
          ({ paymentStatus }) => paymentStatus.code === PaymentStatusCode.Queued,
        );
      })
      : [];
  });
};

export const getCanAddWorker = state => {
  return !getIsJobFilled(state) && hasPermission(JobPermissions.action.selectWorkers.value);
};

export const getCanRateAll = state => {
  const status = getJobStatus(state);
  return (
    (status === JobStatusesMap.InProgress || status === JobStatusesMap.Completed) &&
    !getIsJobEmpty(state) &&
    getIsAllCheckedIn(state) &&
    getIsAllCheckedOut(state) &&
    getIsActive(state)
  );
};

export const getCanCheckInAll = state => {
  return getIsActive(state) && !getIsJobEmpty(state) && getHasEditablePosition(state);
};

export const getCanCheckOutAll = state => {
  const status = getJobStatus(state);
  return (
    (status === JobStatusesMap.InProgress || status === JobStatusesMap.Completed) &&
    !getIsJobEmpty(state) &&
    getIsAllCheckedIn(state) &&
    getIsActive(state) &&
    getHasEditablePosition(state)
  );
};

export const getCanEditRates = state => {
  return !getIsJobEmpty(state) && getHasEditablePosition(state);
};

export const getCanIncreasePositions = state => {
  // return getJobStatus(state) !== JobStatusesMap.Completed && getIsActive(state);
  return getIsActive(state);
};

export const getCanDecreasePositions = state => {
  // return getJobStatus(state) === JobStatusesMap.NotStarted && getIsActive(state);
  return getIsActive(state);
};

export const getCanPayWorkers = state => {
  // If the job is completed or all info is logged then continue to the next checks
  if (getJobStatus(state) === JobStatusesMap.Completed || !getIsMissingShiftInfo(state)) {
    // If any positions either do not have payroll or have a payroll and no payment then we can pay
    return getPositions(state).some(({ isPayable }) => isPayable);
  }

  return false;
};

// Position Data accessors
export const getPosition = positionId => state => {
  return getPositions(state).find(position => position.id === positionId) || {};
};

export const getPositionPayrolls = positionId => state => {
  return getPosition(positionId)(state).payrolls || [];
};

export const getIsPositionDeletable = positionId => state => {
  return getPosition(positionId)(state).isDeletable || false;
};

export const getIsPositionEditable = positionId => state => {
  return getPosition(positionId)(state).isEditable || false;
};

export const getIsPositionPayable = positionId => state => {
  return getPosition(positionId)(state).isPayable || false;
};

export const getPositionPayrollStatus = positionId => state => {
  return getPosition(positionId)(state).payrollStatus || "Unknown";
};

// Position Helpers
// export const getIsPositonPaid = positionId => state => {
//     const payrolls = getPositionPayrolls(positionId)(state);

//     return payrolls.some(({ payments }) => {
//         return payments.length !== 0;
//     });
// };

export const getCanEditPositionRates = positionId => state => {
  return !getIsJobEmpty(state) && getIsPositionEditable(positionId)(state);
};

export const getCanAddWorkerBonus = positionId => state => {
  const status = getJobStatus(state);
  const { startShift, endShift } = getPosition(positionId)(state);
  return (
    (status === JobStatusesMap.InProgress || status === JobStatusesMap.Completed) &&
    startShift &&
    endShift &&
    getIsActive(state) &&
    getIsPositionEditable(positionId)(state)
  );
};

export const getCanRateWorker = positionId => state => {
  const status = getJobStatus(state);
  const { startShift, endShift } = getPosition(positionId)(state);
  return (
    (status === JobStatusesMap.InProgress || status === JobStatusesMap.Completed) &&
    startShift &&
    endShift &&
    getIsActive(state)
  );
};

export const getCanRemovePosition = positionId => state => {
  return getIsPositionDeletable(positionId)(state);
};

export const getCanFlagNoShow = positionId => state => {
  const status = getJobStatus(state);
  return (
    (status === JobStatusesMap.InProgress || status === JobStatusesMap.Completed) &&
    getIsPositionDeletable(positionId)(state) &&
    getIsActive(state)
  );
};

export const getCanPreferWorker = workerId => state => {
  return !getPreferred(state).includes(workerId) && getIsActive(state);
};

export const getCanUnpreferWorker = workerId => state => {
  return getPreferred(state).includes(workerId) && getIsActive(state);
};

export const getCanBanWorker = workerId => state => {
  return !getBanned(state).includes(workerId) && getIsActive(state);
};

export const getCanUnbanWorker = workerId => state => {
  return getBanned(state).includes(workerId) && getIsActive(state);
};

export const getJobTimelineData = state => {
  return state.jobDetails.jobTimelineData
};
