import React from "react";
import { useDispatch, useSelector } from "react-redux";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Datetime from "react-datetime";
import { GetApp, Search } from "@mui/icons-material";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import Button from "../../../../components/Button/Button";
import { actions as ReportsActions, selectors as ReportsSelectors } from "store/modules/reports";
import makeStyles from '@mui/styles/makeStyles';
import WorkerAutocomplete from "components/Autocomplete/WorkerAutocomplete";
import { transactionStatusList } from "utils/constant";
import moment from "moment";
const useStyles = makeStyles(() => ({
    buttonIcon: {
        marginTop: 10,
        marginLeft: 5,
    },
    searchButton: {
        paddingLeft: 0
    }
}));

function DCTransactionFilter() {
    const classes = useStyles();
    const dispatch = useDispatch();
    const aTag = React.useRef();
    const [csvURL, setCsvURL] = React.useState("");
    const {
        MinDateOfFunds,
        MaxDateOfFunds,
        TransactionStatusCode,
        ClientReferenceNumber,
        CustomerNumber,
        TransactionId,
        worker,
    } = useSelector(ReportsSelectors.getDCBankTransactionReportFilter);
    const isLoadingDownload = useSelector(ReportsSelectors.getIsDCReportDownloadLoading);
    const updateFormData = (field, value) => {
        dispatch(ReportsActions.setDCBankTransactionFilter(field, value));
    };
    const updateDateForm = (field, value) => {
        const date = moment(value).tz('UTC').set({ hour:0, minute:0, second:0, millisecond:0 })
        dispatch(ReportsActions.setDCBankTransactionFilter(field, date));
    };
    const onSearch = () => {
        dispatch(ReportsActions.fetchDCBankTransactionTableData());
    };
    const onDownload = () => {
        dispatch(ReportsActions.downloadDCBankTransactionTableData()).then(data => {
            if (data && data.url) {
                setCsvURL(data.url);
                aTag.current.click();
            }
            dispatch(ReportsActions.setIsDCReportDownloadLoading(false))
        });
    };

    return (
        <React.Fragment>
            <Grid container>
                <Grid item xs={12} md={12}>
                    <FormControl margin="normal">
                        <InputLabel htmlFor="startdate">Start Date</InputLabel>
                        <Datetime
                            timeFormat={false}
                            id="startdate"
                            input
                            disabled
                            dateFormat="MMMM Do YYYY"
                            closeOnSelect
                            onChange={value => updateDateForm("MinDateOfFunds", value)}
                            value={MinDateOfFunds}
                        />
                    </FormControl>
                    &nbsp;&nbsp;
                    <FormControl margin="normal">
                        <InputLabel htmlFor="date">End Date</InputLabel>
                        <Datetime
                            timeFormat={false}
                            id="date"
                            input
                            dateFormat="MMMM Do YYYY"
                            closeOnSelect
                            onChange={value => updateDateForm("MaxDateOfFunds", value)}
                            value={MaxDateOfFunds}
                        />
                    </FormControl>
                    &nbsp;&nbsp;
                    <FormControl margin="normal" style={{ minWidth: "188px" }}>
                        <InputLabel htmlFor="date">Status</InputLabel>
                        <Select
                            displayEmpty
                            value={TransactionStatusCode}
                            onChange={value => updateFormData("TransactionStatusCode", value.target.value)}
                            margin="normal"
                        >
                            {transactionStatusList.map(({ label, value }) => (
                                <MenuItem key={`status-${value}`} value={value}>
                                    {label}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    &nbsp;&nbsp;
                        <TextField
                            label={"Customer Number"}
                            id="CustomerNumber"
                            placeholder="Customer Number"
                            value={CustomerNumber}
                            onChange={value => updateFormData("CustomerNumber", value.target.value)}
                            type="number"
                            margin="normal"
                        />
                    &nbsp;&nbsp;
                        <TextField
                            label={"Client Reference Number"}
                            id="ClientReferenceNumber"
                            placeholder="Reference Number"
                            value={ClientReferenceNumber}
                            onChange={value => updateFormData("ClientReferenceNumber", value.target.value)}
                            type="text"
                            margin="normal"
                        />
                    &nbsp;&nbsp;
                        <TextField
                            label="Transaction Id"
                            id="TransactionId"
                            placeholder="Transaction Id"
                            value={TransactionId}
                            onChange={value => updateFormData("TransactionId", value.target.value)}
                            type="number"
                            margin="normal"
                        />
                    &nbsp;&nbsp;
                    <FormControl margin="normal" style={{ minWidth: "188px", zIndex: 100000 }}>
                        <WorkerAutocomplete
                            value={worker}
                            onChange={value => updateFormData("worker", value)}
                        />
                    </FormControl>
                    &nbsp;&nbsp;
                    <div>
                        <FormControl margin="normal" style={{ marginTop: 0 }}>
                            <Button
                                className={classes.searchButton}
                                icon={<Search className={classes.buttonIcon} />}
                                alignIcon="left"
                                label="Search"
                                onClick={onSearch}
                            />
                        </FormControl>
                        <FormControl margin="normal" style={{ marginTop: 0 }}>
                            <Button
                                icon={<GetApp className={classes.buttonIcon} />}
                                label="Download CSV"
                                alignIcon="left"
                                isLoading={isLoadingDownload}
                                onClick={onDownload}
                            />
                        </FormControl>
                    </div>
                </Grid>
            </Grid>
            <a ref={aTag} href={csvURL} target="_blank" rel="noreferrer" />
        </React.Fragment>
    );
}

export default DCTransactionFilter;
