import React from "react";
import { useSelector } from "react-redux";
import { matchPath } from "react-router";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import cx from "classnames";
// @mui/material components
import { Avatar, IconButton, Menu, MenuItem, Tooltip } from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Hidden from "@mui/material/Hidden";
import withStyles from "@mui/styles/withStyles";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import ChevronLeft from "@mui/icons-material/ChevronLeft";
// material-ui icons
import MenuIcon from "@mui/icons-material/Menu";
import headerStyle from "assets/jss/material-dashboard-pro-react/components/headerStyle.jsx";

import Button from "components/Button/Button.jsx";
import CustomButton from "components/CustomButtons/Button.jsx";
import history from "utils/history";
import { formatInitials } from "utils/stringUtils";
import { getMe } from "store/modules/login/selectors";

function getRoute(routes = [], pathname) {
    return routes.reduce(
        (acc, route) => {
            const { collapse, views, path } = route;

            const match = matchPath(pathname, {
                path: path,
                exact: true,
                strict: true,
            });

            if (collapse) {
                const sub = views.reduce((viewAcc, subRoute) => {
                    return subRoute.path === pathname ? subRoute : viewAcc;
                }, null);
                return sub || acc;
            }

            return match && match.isExact && acc.name === "Default" ? route : acc;
        },
        { name: "Default" },
    );
}

function Header(props) {
    const { classes, color, routes, location } = props;
    const route = getRoute(routes, location.pathname);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const { admin } = useSelector(getMe);
    const open = Boolean(anchorEl);
    const handleMenu = event => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const appBarClasses = cx({
        [" " + classes[color]]: color,
    });
    const gotoPage = url => {
        handleClose();
        history.push(url);
    };
    return (
        <AppBar className={classes.appBar + appBarClasses}>
            <Toolbar className={classes.container}>
                {route.backButton ? (
                    <Button
                        buttonClassName={classes.backButtonClass}
                        className={classes.backButton}
                        size="small"
                        icon={<ChevronLeft />}
                        onClick={() => props.history.goBack()} />
                ) : null}
                <div className={classes.flex}>
                    <Typography variant="h5">{route.name}</Typography>
                </div>
                <IconButton
                    className={classes.userButton}
                    onClick={handleMenu}
                    sx={{ p: 1.5 }}
                    disableRipple
                    aria-controls="menu-appbar"
                    aria-haspopup="true"
                    size="large">
                    <div>
                        <div className={classes.labelName}>{admin.firstName + " " + admin.lastName}</div>
                        <div className={classes.labelEmail}>{admin.email}</div>
                    </div>
                    &nbsp;
                    <Avatar src={admin.signedProfileImageUrl}>
                        {formatInitials(admin.firstName, admin.lastName, "")}
                    </Avatar>
                </IconButton>
                <Menu
                    id="menu-appbar"
                    anchorEl={anchorEl}
                    getContentAnchorEl={null}
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right",
                    }}
                    keepMounted
                    transformOrigin={{
                        vertical: "top",
                        horizontal: "right",
                    }}
                    open={open}
                    onClose={handleClose}
                >
                    <MenuItem onClick={() => gotoPage("/profile")}>Profile</MenuItem>
                    <MenuItem onClick={() => gotoPage("/logout")}>Logout</MenuItem>
                </Menu>
                <Hidden mdUp implementation="css">
                    <CustomButton
                        className={classes.appResponsive}
                        color="transparent"
                        justIcon
                        aria-label="open drawer"
                        onClick={props.handleDrawerToggle}
                    >
                        <MenuIcon />
                    </CustomButton>
                </Hidden>
            </Toolbar>
        </AppBar>
    );
}

Header.propTypes = {
    classes: PropTypes.object.isRequired,
    color: PropTypes.oneOf(["primary", "info", "success", "warning", "danger"]),
    handleDrawerToggle: PropTypes.func,
};

Header.defaultProps = {
    handleDrawerToggle: () => { },
};

export default withStyles(theme => ({
    ...headerStyle(theme),
    backButton: {
        marginRight: "24px",
        padding: 0
    },
    backButtonClass: {
        color:theme.palette.primary.dark,
        backgroundColor:"transparent",
        "&:hover":{
            backgroundColor:theme.palette.grey["A100"]
        }
    }
}))(withRouter(Header));
