export const banReasons = [
  { type: "LateOrNoShow", description: "Being late or not showing up" },
  { type: "NotPrepared", description: "Worker was unprepared for work" },
  { type: "Unprofessional", description: "Worker was unprofessional" },
  { type: "PoorPerformance", description: "Poor worker performance" },
  { type: "Other", description: "Other" },
];

export const BanReasonsMap = {
  LateOrNoShow: "LateOrNoShow",
  NotPrepared: "NotPrepared",
  Unprofessional: "Unprofessional",
  PoorPerformance: "PoorPerformance",
  Other: "Other",
};

export const BanReasonsArray = [
  "LateOrNoShow",
  "NotPrepared",
  "Unprofessional",
  "PoorPerformance",
  "Other",
];

export const BanReasonsLabel = {
  LateOrNoShow: "Being late or not showing up",
  NotPrepared: "Worker was unprepaired for work",
  Unprofessional: "Worker was unprofessional",
  PoorPerformance: "Poor worker performance",
  Other: "Other",
};

export const SuspensionDurationMap = {
  ThreeDays: 3,
  SevenDays: 7,
  FourteenDays: 14,
};

export const SuspensionDurationOptions = [
  {
    label: "3 days",
    value: 3,
  },
  {
    label: "7 days",
    value: 7,
  },
  {
    label: "14 days",
    value: 14,
  },
];

export const JobStatusesMap = {
  NotStarted: "NotStarted",
  InProgress: "InProgress",
  Completed: "Completed",
};

export const JobStatusesArray = ["NotStarted", "InProgress", "Completed"];

export const JobStatusesLabel = {
  NotStarted: "Not Started",
  InProgress: "In Progress",
  Completed: "Completed",
};

export const TimeframeMap = {
  Today: "Today",
  Tomorrow: "Tomorrow",
  Upcoming: "Upcoming",
  Past: "Past",
};

export const TimeframeArray = ["Today", "Tomorrow", "Upcoming", "Past"];

export const TimeframeLabel = {
  Today: "Today",
  Tomorrow: "Tomorrow",
  Upcoming: "Upcoming",
  Past: "History",
};

export const TimezoneList = [
  { name: "America/Vancouver", label: "Vancouver(-8)" },
  { name: "America/Toronto", label: "Toronto(-5)" },
  { name: "America/Regina", label: "Regina(-6)" },
  { name: "America/Halifax", label: "Halifax(-4)" },
  { name: "America/St_Johns", label: "St. John's(-3.30)" },
  { name: "America/Edmonton", label: "Edmonton(-7)" },
];
export const PaymentConstants = {
  MINIMUM_WAGE: 14,
  MIN_MARGIN_DOLLARS: 8.95,
  MIN_MARGIN_PERCENTAGE: 0.3,
  MAX_BONUS: 50,
  MIN_BONUS: 0,
};

export const PaymentStatusCode = {
  Queued: "Queued",
  Sending: "Sending",
  AwaitingResponse: "AwaitingResponse",
  ProcessingResponse: "ProcessingResponse",
  Delivered: "Delivered",
  Accepted: "Accepted",
  Declined: "Declined",
  Failed: "Failed",
  Retried: "Retried",
  RetryCancelled: "RetryCancelled",
  Cancelled: "Cancelled",
  ManualAction: "ManualAction",
  ManualResolved: "ManualResolved",
  Deferred: "Deferred",
  Unknown: "Unknown",
};

export const PayrollStatusCode = {
  ...PaymentStatusCode,
  ReadyForPay: "ReadyForPay",
  MissingInfo: "MissingInfo",
};

export const PaymentBatchStatusCode = {
  Queued: "Queued",
  Pending: "Pending",
  Completed: "Completed",
  Unresolved: "Unresolved",
  Resolved: "Resolved",
};

export const ManagerRoleLabel = {
  SiteManager: "Site manager",
  CheckInManager: "Check-in manager",
  CheckOutManager: "Check-out manager",
};

export const PayrollTypeCode = {
  Payment: "PAY",
  Adjustment: "Adjustment",
};
export const BillingRate = 24.95;
