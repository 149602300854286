import { gql } from "@apollo/client";
import WorkerFields from "../fragments/worker-fragment";

export default gql`
  mutation activateWorker($data: WorkerReasonInput!) {
    activateWorker(data: $data) {
      ...WorkerFields
    }
  }
  ${WorkerFields}
`;
