import React, { useState } from "react";
import MainContent from "components/Containers/MainContent";
import SubNav from "../../../components/Navigation/SubNav";
import SubNavTab from "../../../components/Navigation/SubNavTab";
import { Assessment } from "@mui/icons-material";
import ActiveEmployerTable from "./components/ActiveEmployerTable";
import ActiveEmployerFilter from "./components/ActiveEmployerFilter";
import WSBCTable from "./components/WSBCTable";
import WSBCFilter from "./components/WSBCFilter";

function ActiveEmployerView() {
    const [tavValue, setTabValue] = useState(0);
    const handleTabChange = (event, value) => {
        setTabValue(value);
    };
    return (
        <React.Fragment>
            <SubNav
                value={tavValue}
                onChange={handleTabChange}
                tabs={[
                    <SubNavTab
                        key="activeEmployer"
                        label="Active Employer"
                        icon={<Assessment />}
                        count={null}
                    />,
                    <SubNavTab key="WSBC-report" label="WSBC report" icon={<Assessment />} count={null} />,
                ]}
            />
            <MainContent>
                {tavValue == 0 && (
                    <>
                        <ActiveEmployerFilter />
                        <ActiveEmployerTable />
                    </>
                )}
                {tavValue == 1 && (
                    <>
                        <WSBCFilter />
                        <WSBCTable />
                    </>
                )}
            </MainContent>
        </React.Fragment>
    );
}

export default ActiveEmployerView;
