/* eslint-disable react/prop-types */
import React, { useState } from "react";
import Grid from "@mui/material/Grid";
import Grow from "@mui/material/Grow";
import Typography from "@mui/material/Typography";
import { upOrDown } from "helpers";
import { employerStatsCardStyles } from "views/Finance/helpers";
import { formatMoney, formatNumber } from "utils/stringUtils";
import EmployerStatsCardPopover from "./EmployerStatsPopover";
import { EmployerPopoverContent } from "./EmployerStatsPopover/EmployerPopovers";

function EmployerStatsCard(props) {
  const { classes, employerData, stats, employerWeekSummary } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const [popoverType] = useState("employerSummary");
  const [modalData] = useState({ ...stats });
  const renderEmployerCard = () => (
    <Grid item xs={12} className={classes.employerContainer}>
      { /*<EmployerStatsCardPopover
        modalData={modalData}
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        type={popoverType ? popoverType : "employerSummary"}
        width={400}
        data={{ ...props, ...modalData }}
      />
      */ }   
      <Grow in={employerData ? true : true} timeout={500}>
        <Grid item xs={12} className={classes.employerCard}>
          <EmployerPopoverContent
            employerData={{ ...props, ...modalData, ...employerData }}
          />
        </Grid>
      </Grow>
    </Grid>
  );

  const renderJobsCard = () => (
    <Grid item xs={12} md={6} className={classes.container}>
      <Grow in={employerData ? true : true} timeout={1000}>
        <Grid item xs={12} className={classes.card}>
          <Grid container item xs={12} direction="column">
            <Typography variant="caption">Jobs</Typography>
            <Grid container direction="row" justifyContent="flex-start">
              <Typography variant="h5" className={classes.mobileH5}>
                {formatNumber(employerWeekSummary.lastInvoiceJobs || 0)}
                <Typography variant="caption" className={classes.upOrDown}>
                  {upOrDown(employerWeekSummary.previousWeekJobs, employerWeekSummary.lastInvoiceJobs)}
                </Typography>
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grow>
    </Grid>
  );

  const renderShiftsCard = () => (
    <Grid item xs={12} md={6} className={classes.statsContainer}>
      <Grow in={employerData ? true : true} timeout={1000}>
        <Grid item xs={12} className={classes.card}>
          <Grid container item xs={12} direction="column">
            <Typography variant="caption">Shifts</Typography>
            <Grid container direction="row" justifyContent="flex-start">
              <Typography variant="h5" className={classes.mobileH5}>
                {formatNumber(employerWeekSummary.lastInvoiceShift || 0)}
                <Typography variant="caption" className={classes.upOrDown}>
                  {upOrDown(employerWeekSummary.previousWeekShift, employerWeekSummary.lastInvoiceShift)}
                </Typography>
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grow>
    </Grid>
  );

  const renderHoursCard = () => (
    <Grid item xs={12} md={6} className={classes.container}>
      <Grow in={employerData ? true : true} timeout={1500}>
        <Grid item xs={12} className={classes.card}>
          <Grid container item xs={12} direction="column" alignItems="flex-start">
            <Typography variant="caption">Total Hours</Typography>
            <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start">
              <Typography variant="h5" className={classes.mobileH5}>
                {formatNumber(employerWeekSummary.lastInvoiceTotalHours)}
                <Typography variant="caption" className={classes.upOrDown}>
                  {upOrDown(
                    employerWeekSummary.previousWeekInvoiceTotalHours,
                    employerWeekSummary.lastInvoiceTotalHours
                  )}
                </Typography>
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grow>
    </Grid>
  );

  const renderTotalCard = () => (
    <Grid item xs={12} md={6} className={classes.statsContainer}>
      <Grow in={employerData ? true : true} timeout={2000}>
        <Grid item xs={12} className={classes.card}>
          <Grid container item xs={12} direction="column" alignItems="flex-start">
            <Typography variant="caption">Total Billable</Typography>
            <Grid container direction="row" justifyContent="flex-start" alignItems="baseline">
              <Typography variant="h5" className={classes.mobileH5}>
              {formatNumber(employerWeekSummary.lastInvoiceTotalAmount || 0)}
                <Typography variant="caption" className={classes.upOrDown}>
                  {upOrDown(
                    employerWeekSummary.previousWeekInvoiceTotalAmount,
                    employerWeekSummary.lastInvoiceTotalAmount
                  )}
                </Typography>
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grow>
    </Grid>
  );
  return (
    <Grid container justifyContent="flex-start" className={classes.wrapper}>
      <Grid container item xs={12} md={4} className={classes.statsContainer}>
        {renderEmployerCard()}
      </Grid>
      <Grid container item xs={12} md={8} className={classes.statsContainer}>
        {renderJobsCard()}
        {renderShiftsCard()}
        {renderHoursCard()}
        {renderTotalCard()}
      </Grid>
    </Grid>
  );
}

export default employerStatsCardStyles(EmployerStatsCard);
