/* eslint-disable react/prop-types */
// findPayrollByPositionId
import React from "react";

import moment from "moment-timezone";
import Grid from "@mui/material/Grid";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import { invoiceModalTableStyles } from "views/Finance/helpers";
import { formatMoney, formatNumber } from "utils/stringUtils";
const isMobile = false;
function InvoiceModalTable(props) {
  const { payroll, classes } = props;
  const primaryPayroll =
    payroll && payroll.length > 0 ? payroll.find(pr => pr.payrollType.name === 'Payment') : {};

    const adjustments =
    payroll && payroll.length > 0 ? payroll.filter(item => item.payrollType.id !== null) : [];
  const cellStyleLeft = { paddingLeft: 2 };
  const lastRow = {
    paddingLeft: 2,
    border: isMobile ? "inherit" : "0px solid transparent",
    borderBottom: isMobile ? "1px solid rgb(224 224 224)" : "inherit",
  };

  const RenderPayrollAdjustments = () => {
    return (
      <React.Fragment>
        <Grid container item xs={12} style={{ padding: 0 }}>
          {adjustments &&
            adjustments.map((payroll, i) => (
              <Grid container key={i} item xs={12} style={{ padding: 0 }}>
                <Grid container item xs={12} style={{ padding: 0 }}>
                  <Typography variant="h6" style={{ fontSize: 14, marginTop: 15, width: "100%" }}>
                    {payroll.payrollType.name === "wage"
                      ? `Wage Adjustment`
                      : payroll.payrollType.name === "bonus"
                      ? `Bonus Adjustment`
                      : payroll.payrollType.name === "break"
                      ? `Break Adjustment`
                      : ``}
                    <span style={{ fontSize: 11, float: "right" }}>
                      {moment(payroll.createdAt).format("MMM Do, YYYY h:mm a")}
                    </span>
                  </Typography>
                </Grid>

                <Grid item xs={12} md={6} style={{ padding: 0 }}>
                  <Table
                    className={classes.table.root}
                    aria-labelledby="tableTitle2"
                    size={"small"}
                    aria-label="enhanced table2">
                    <TableBody>
                      {payroll.payrollType.name === "wage" && (
                        <React.Fragment>
                          <TableRow>
                            <TableCell style={lastRow}>Initial Rate</TableCell>
                            <TableCell style={lastRow} align="right">
                              {formatMoney(primaryPayroll.rate)}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell style={lastRow}>Difference</TableCell>
                            <TableCell style={lastRow} align="right">
                              {formatMoney(payroll.rate - primaryPayroll.rate)}
                            </TableCell>
                          </TableRow>
                        </React.Fragment>
                      )}

                      {payroll.payrollType.name === "bonus" && (
                        <React.Fragment>
                          <TableRow>
                            <TableCell style={lastRow}>Original Pay Rate</TableCell>
                            <TableCell style={lastRow} align="right">
                              {formatMoney(primaryPayroll.rate)}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell style={lastRow}>Billable Hours</TableCell>
                            <TableCell style={lastRow} align="right">
                              {formatNumber(payroll.payableUnits)} Hrs
                            </TableCell>
                          </TableRow>
                        </React.Fragment>
                      )}

                      {payroll.payrollType.name === "break" && (
                        <React.Fragment>
                          <TableRow>
                            <TableCell style={lastRow}>Break</TableCell>
                            <TableCell style={lastRow} align="right">
                              {payroll.breakDeductionMins ? payroll.breakDeductionMins + ' mins' : "No Break"}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell style={lastRow}>Billable Hours</TableCell>
                            <TableCell style={lastRow} align="right">
                              {formatNumber(payroll.payableUnits)} Hrs
                            </TableCell>
                          </TableRow>
                        </React.Fragment>
                      )}

                      <TableRow>
                        <TableCell style={lastRow}>Gross Adjustment</TableCell>
                        <TableCell style={lastRow} align="right">
                          {formatMoney(payroll.grossEarnings)}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </Grid>
                <Grid item xs={12} md={6} style={{ padding: 0 }}>
                  <Table
                    className={classes.table.root}
                    aria-labelledby="tableTitle2"
                    size={"small"}
                    aria-label="enhanced table2">
                    <TableBody>
                      {payroll.payrollType.name === "wage" && (
                        <React.Fragment>
                          <TableRow>
                            <TableCell style={lastRow}>New Hourly Rate</TableCell>
                            <TableCell style={lastRow} align="right">
                              {formatMoney(payroll.rate)}
                            </TableCell>
                          </TableRow>
                        </React.Fragment>
                      )}

                      {payroll.payrollType.name === "bonus" && (
                        <TableRow>
                          <TableCell style={lastRow}>Original Pay Rate</TableCell>
                          <TableCell style={lastRow} align="right">
                            {formatMoney(primaryPayroll.rate)}
                          </TableCell>
                        </TableRow>
                      )}

                      {payroll.payrollType.name === "break" && (
                        <TableRow>
                          <TableCell style={lastRow}>Break</TableCell>
                          <TableCell style={lastRow} align="right">
                            {payroll.breakDeductionMins ? payroll.breakDeductionMins + ' mins': "No Break"}
                          </TableCell>
                        </TableRow>
                      )}

                      <TableRow>
                        <TableCell style={lastRow}>Billable Hours</TableCell>
                        <TableCell style={lastRow} align="right">
                          {formatNumber(payroll.payableUnits)} Hrs
                        </TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell style={lastRow}>Net Adjustment</TableCell>
                        <TableCell style={lastRow} align="right">
                          {formatMoney(payroll.netEarnings)}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </Grid>
              </Grid>
            ))}
        </Grid>
      </React.Fragment>
    );
  };

  const RenderPayrollDetails = () => {
    return (
      <React.Fragment>
        <Grid container item xs={12} style={{ padding: 0 }}>
          <Typography variant="h6" style={{ fontSize: 14, width: "100%" }}>
            Payroll Details
            <span style={{ fontSize: 11, float: "right" }}>
              {moment(primaryPayroll?.createdAt).format("MMM Do, YYYY h:mm a")}
            </span>
          </Typography>
        </Grid>

        <Grid item xs={12} md={6} style={{ padding: 0 }}>
          <Table
            className={classes.table.root}
            aria-labelledby="tableTitle2"
            size={"small"}
            aria-label="enhanced table2">
            <TableBody>
              <TableRow>
                <TableCell size="small" style={cellStyleLeft}>
                  Worker
                </TableCell>
                <TableCell style={cellStyleLeft} size="small" align="right">
                  {primaryPayroll?.position.worker.firstName} {primaryPayroll?.position.worker.lastName}
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell style={cellStyleLeft} size="small">
                  Hours Worked
                </TableCell>
                <TableCell style={cellStyleLeft} size="small" align="right">
                  {formatNumber(primaryPayroll?.totalUnits)} Hrs
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell style={cellStyleLeft} size="small">
                  Billable Hours
                </TableCell>
                <TableCell style={cellStyleLeft} size="small" align="right">
                  {formatNumber(primaryPayroll?.payableUnits)} Hrs
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell style={{ ...lastRow }} size="small">
                  Gross Pay
                </TableCell>
                <TableCell size="small" style={{ ...lastRow }} align="right">
                  {formatMoney(primaryPayroll?.grossEarnings)}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Grid>
        <Grid item xs={12} md={6} style={{ padding: 0 }}>
          <Table
            className={classes.table.root}
            aria-labelledby="tableTitle2"
            size={"small"}
            aria-label="enhanced table2">
            <TableBody>
              <TableRow>
                <TableCell style={cellStyleLeft}>Position Id</TableCell>
                <TableCell style={cellStyleLeft} align="right">
                  {primaryPayroll?.position.id}
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell style={cellStyleLeft}>Break</TableCell>
                <TableCell style={cellStyleLeft} align="right">
                  {primaryPayroll.breakDeductionMins ? primaryPayroll?.breakDeductionMins + ' mins' : "No Break"}
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell style={cellStyleLeft}>Hourly Rate</TableCell>
                <TableCell style={cellStyleLeft} align="right">
                  {formatMoney(primaryPayroll?.rate)}
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell style={lastRow}>Net Pay</TableCell>
                <TableCell style={lastRow} align="right">
                  {formatMoney(primaryPayroll?.netEarnings)}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Grid>
      </React.Fragment>
    );
  };

  return (
    <Grid container item xs={12} style={{ padding: 0 }}>
      <RenderPayrollDetails />
      <RenderPayrollAdjustments />
    </Grid>
  );
}

export default invoiceModalTableStyles(InvoiceModalTable);
