import { gql } from "@apollo/client";

export default gql`
  mutation retryPayment($paymentId: Int!) {
    retryPayment(paymentId: $paymentId) {
      id
      createdAt
      sentAt
      responseAt
      workerId
      payrollId
      #   paymentBatchId
      retryPaymentId
      paymentStatusId
      dcTransferId
      paymentProviderReferenceCode
      email
      securityQuestion
      securityAnswer
      amount
      errorCode
      errorDescription

      payroll {
        id
        createdAt
        payrollTypeId
        positionId
        taxRegionId
        employmentStandardsRegionId
        rate
        checkIn
        checkOut
        breakDeductionMins
        bonus
        totalUnits
        payableUnits

        position {
          id
          createdAt
          updatedAt
          isDeleted
          jobId
          workerId
          appliedAt
          checkedInAt
          startShift
          endShift
          workerRating
          employerRating
          breakMins
          defaultWorkerRating
          isStandby
          isCancelled
          isConfirmed
          confirmedAt
          isReconfirmed
          reconfirmedAt
          regularHoursWorked
          doubletimeHoursWorked
          overtimeHoursWorked
          hoursWorkedLessBreak
          hoursWorked
          payRate
          billingRate
          bonus
          workerTotalPay
          checkinType
          checkoutType
          job {
            id
            createdAt
            updatedAt
            isDeleted
            employerId
            start
            end
            workTypeId
            peopleNeeded
            hourlyRate
            street
            city
            region
            description
            isActive
            breakMins
            visibility
            latitude
            longitude
            employer {
              companyName
            }
          }
          worker {
            pk
            createdAt
            updatedAt
            isDeleted
            id
            firstName
            lastName
            email
            isEmailVerified
            birthdate
            street
            city
            region
            postalCode
            country
            phone
            sin
            authId
            identitiesProvider
            identitiesUserId
            identitiesIsSocial
            identitiesConnection
            profileImageUrl
            identificationImageUrl
            consent
            isActive
            isApproved
            approvedAt
            isSuspended
            noShowCount
            rating
            isOnboarded
            latitude
            longitude
            unit
            isStudent
            employmentType
            travelRange
            isProfileComplete
            timezone
            dcCustomerNumber
            payrollCount
            positionCount
            grossPay
            totalDeductions
            fullName
            hoursWorkedCount
            banCount
            preferCount
          }
        }
      }
    }
  }
`;
