/* eslint-disable react/display-name */
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVert from "@mui/icons-material/MoreVert";
import BillingDialog from "components/Dialog/BillingDialog";
import Dialog from "components/Dialog/Dialog";
import { actions as FinanceActions } from "store/modules/finance";
import InvoiceModalTable from "./InvoiceModalTable";
import InvoicePdfDialog from "./InvoicePdfDialog";

function InvoiceCardActions(props) {
  const { invoice, classes, getTableData } = props;
  let adjustments = [];

  const [anchorEl, setAnchorEl] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [openDeleteInvoice, setOpenDeleteInvoice] = useState(false);
  const [openDownloadInvoice, setOpenDownloadInvoice] = useState(false);
  const [openCommitInvoice] = useState(false);
  const [openPdf, setOpenPdf] = useState(false);
  const [sheetLoading, setSheetLoading] = useState(false);
  const [uri, setUri] = React.useState(null);
  const aTag = React.useRef();
  const dispatch = useDispatch();

  const handleClick = event => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);
  const id = `action-menu-worker`;
  const RenderInvoiceModal = React.forwardRef((props, ref) => (
    <MenuItem
      innerRef={ref}
      onClick={() => {
        setOpenModal(true);
        setAnchorEl(null);
      }}>
      View Timesheet
    </MenuItem>
  ));

/*  const RenderCommitInvoiceModal = React.forwardRef((props, ref) => (
    <MenuItem
      innerRef={ref}
      onClick={() => {
        // setOpenCommitInvoice(true);
        setAnchorEl(null);
      }}>
      Commit Timesheet
    </MenuItem>
  ));*/

  const downloadWeeklyBillingTimesheet = (employerId, id) => {
    if (!sheetLoading) {
      setSheetLoading(true);
      dispatch(FinanceActions.downloadWeeklyBillingTimesheet(employerId, id)).then(url => {
          if(url){
              setUri(url);
              aTag.current.click();
          }

        setSheetLoading(false);
      });
    }
  };
  const downloadWeeklyBillingZip = (week, year) => {
    if (!sheetLoading) {
      setSheetLoading(true);
      dispatch(FinanceActions.downloadWeeklyBillingZip(week, year)).then(url => {
          if(url){
              setUri(url);
          }
        setSheetLoading(false);
      });
    }
  };
  const RenderDownloadInvoiceModal = React.forwardRef((props, ref) => (
    <MenuItem
      innerRef={ref}
      onClick={() => {
        // setOpenDownloadInvoice(true);
        downloadWeeklyBillingTimesheet(invoice.employerId, parseInt(invoice.id));
        setAnchorEl(null);
      }}>
      Download Timesheet
    </MenuItem>
  ));

  const RenderDeleteInvoiceModal = React.forwardRef((props, ref) => (
    <MenuItem
      innerRef={ref}
      onClick={() => {
        setOpenDeleteInvoice(true);
        setAnchorEl(null);
      }}>
      Delete Timesheet
    </MenuItem>
  ));

  const deleteInvoice = () => {
    dispatch(FinanceActions.deleteEmployerInvoice(invoice.employerId, parseInt(invoice.id)));
    setOpenDeleteInvoice(false);
    setTimeout(() => getTableData(), 3000);
  };

  const commitTimesheet = () => {
    // dispatch(FinanceActions.deleteEmployerInvoice(invoice.employerId, parseInt(invoice.id)));
    // setTimeout(() => getTableData(), 3000);
  };

  return (
    <React.Fragment>
      <IconButton
        aria-owns={anchorEl ? id : undefined}
        aria-haspopup="true"
        disableRipple
        onClick={handleClick}
        size="large">
        <MoreVert color="primary" />
      </IconButton>
      {anchorEl ? (
        <Menu
          id={`action-menu-worker`}
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}
          disableAutoFocusItem>
          <RenderInvoiceModal invoice={invoice} />
          {/*<RenderCommitInvoiceModal invoice={invoice} />*/}

          <RenderDownloadInvoiceModal invoice={invoice} />
          {!invoice.qbInvoiceId ? <RenderDeleteInvoiceModal invoice={invoice} /> : ""}
          {/* <CommitToQuickbooks invoice={invoice} /> */}
        </Menu>
      ) : null}
      <React.Fragment>
        <InvoicePdfDialog
          adjustments={adjustments}
          shifts={invoice.invoiceItems}
          invoice={invoice}
          open={openPdf}
          handleOpen={() => setOpenPdf(!openPdf)}
          handleClose={() => setOpenPdf(!openPdf)}
        />

        <Dialog
          open={openCommitInvoice}
          title={`Commit Timesheet ${invoice.id}?`}
          confirmText="Commit Timesheet"
          onCancel={() => setOpenDownloadInvoice(false)}
          onConfirm={() => commitTimesheet()}
          onClose={() => setOpenDownloadInvoice(false)}
          alertType="save"
          icon="attach_file" />
        <Dialog
          open={openDownloadInvoice}
          title={`Download Timesheet ${invoice.id}?`}
          confirmText="Download Timesheet"
          onCancel={() => setOpenDownloadInvoice(false)}
          onConfirm={() => downloadWeeklyBillingZip()}
          onClose={() => setOpenDownloadInvoice(false)}
          alertType="save"
          icon="attach_file" />
        <Dialog
          open={openDeleteInvoice}
          title={`Delete Timesheet ${invoice.id}?`}
          confirmText="Delete Timesheet"
          onCancel={() => setOpenDeleteInvoice(false)}
          onConfirm={() => deleteInvoice()}
          onClose={() => setOpenDeleteInvoice(false)}
          alertType="save"
          icon="attach_file" />
        <BillingDialog
          open={openModal}
          title={`Generate New Timesheet?`}
          confirmText="Save & Generate Timesheet"
          onClose={() => setOpenModal(!openModal)}
          onConfirm={() => setOpenModal(!openModal)}
          onCancel={() => setOpenModal(!openModal)}
          alertType="invoice"
          icon="attach_file"
          fullWidth
          maxWidth="lg"
          disableBackdropClick
          dialogProps={{ invoice, classes }}
          table={<InvoiceModalTable invoice={invoice} />}
        />
      </React.Fragment>
      <a ref={aTag} href={uri} target="_blank" rel="noopener noreferrer" />
    </React.Fragment>
  );
}

export default InvoiceCardActions;
