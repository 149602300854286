/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */

import React from "react";
import { useDispatch, useSelector } from "react-redux";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { RemoveCircleOutline, Settings } from "@mui/icons-material";
import ArchiveOutlinedIcon from "@mui/icons-material/ArchiveOutlined";
import { invoiceBuilderComponentStyles, invoiceBuilderStyles } from "views/Finance/helpers";
import { actions as FinanceActions, selectors as FinanceSelectors } from "store/modules/finance";
import { clearWeekInvoices } from "store/modules/finance/actions/billingActions";
import window from "auth0-js/src/helper/window";

const InvoiceBuilder = ({
    week,
    year,
    summary,
    handleFetchSummary,
    loading,
    setLoading,
    clearing,
    setClearing,
    setOpen,
}) => {
    const classes = invoiceBuilderComponentStyles();
    const dispatch = useDispatch();
    const [hasZip, setHasZip] = React.useState(true);
    const [zipURL, setZipURL] = React.useState('');
    const aTag = React.useRef();
    const generatedBillingSummary = useSelector(FinanceSelectors.getGeneratedBillingSummary);
    const [downloadLoading, setDownloadLoading] = React.useState(false);
    const ButtonComponent = props => {
        const { onClick, loading, label, loadingLabel, uri } = props;
        const downloadLinkProps = uri ? { download: "CloudDownloadRounded", href: uri } : {};
        return (
            <Button
                variant={loading ? "outlined" : "contained"}
                color="secondary"
                {...downloadLinkProps}
                className={classes.deleteButton}
                onClick={onClick}
                startIcon={
                    loading ? (
                        <CircularProgress size={18} color="secondary" />
                    ) : hasZip ? (
                        <ArchiveOutlinedIcon />
                    ) : (
                        <Settings />
                    )
                }>
                {!loading ? label : loadingLabel}
            </Button>
        );
    };
    function openInNewTab(url) {
        const win = window.open(url, '_blank');
        if (win != null) {
            win.focus();
        }
    }
    const downloadZip = () => {
        if (!loading) {
            setDownloadLoading(true);

            dispatch(FinanceActions.downloadWeeklyBillingZip(week, year)).then((data) => {
                if (typeof data === 'string') {
                    setZipURL(data);
                    aTag.current.click();
                    handleFetchSummary();
                }
                // setOpen(false);
                return setDownloadLoading(false);
            });
            //   setTimeout(() => setOpen(false), 4000);
        }
    };

    const handleButtonClick = () => {
        if (!loading) {
            setLoading(true);

            dispatch(FinanceActions.generateBilling(week, year)).then(() => {
                handleFetchSummary();
                // setOpen(false);

                return setLoading(false);
            });
        }
    };

    const handleClearInvoices = () => {
        if (!clearing) {
            setClearing(true);
            dispatch(clearWeekInvoices(week, year)).then(() => {
                handleFetchSummary();
                setClearing(false);
            });
        }
    };

    return (
        <Grid container item md={12} alignItems="stretch" className={classes.leftContainer}>
            <Grid container item md={12} alignItems="flex-start">
                <Typography variant="h6" component="h6" className={classes.leftContainer}>
                    Billing Week Generator
                </Typography>

                <Typography component="div" className={classes.subTitle}>
                    Generate Timesheets for the current billing week for all employers that do not have a
                    comitted timesheet.
                </Typography>

                {generatedBillingSummary.newInvoiceCount ? (
                    <Typography variant="body1" component="div" className={classes.bodyMessage}>
                        {generatedBillingSummary.newInvoiceCount} of {summary && summary.invoiceCount}
                        Successfully created
                    </Typography>
                ) : (
                    <Typography variant="body1" component="div" className={classes.bodyMessage}>
                        {summary && summary.invoiceCount} Pending Timesheets
                    </Typography>
                )}

                {generatedBillingSummary.shiftCount ? (
                    <Typography variant="body1" component="div" className={classes.bodyMessage}>
                        {generatedBillingSummary.shiftCount} Invoice Items Created created
                    </Typography>
                ) : (
                    ""
                )}
                <Typography variant="body1" component="div" className={classes.bodyMessage}>
                    {summary && summary.committedInvoiceCount} committed Timesheets not effected.
                </Typography>
            </Grid>
            <Grid container item className={classes.btnContainer} md={12} justifyContent="center">
                <Button
                    variant={clearing ? "outlined" : "contained"}
                    color="secondary"
                    className={classes.deleteButton}
                    onClick={handleClearInvoices}
                    startIcon={
                        clearing ? <CircularProgress size={18} color="secondary" /> : <RemoveCircleOutline />
                    }>
                    {clearing ? "Deleting..." : "Delete Timesheets"}
                </Button>

                <ButtonComponent
                    onClick={() => handleButtonClick()}
                    loading={loading}
                    label="Generate Timesheets"
                    loadingLabel="Working Please Wait"
                />

                <ButtonComponent
                    onClick={() => downloadZip()}
                    loading={loading || downloadLoading}
                    label="Download Zip File"
                    loadingLabel="Working Please Wait"
                />
            </Grid>
            <a ref={aTag} href={zipURL} target="_blank" rel="noopener noreferrer" />
        </Grid>
    );
};

export default invoiceBuilderStyles(InvoiceBuilder);
