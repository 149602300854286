/* eslint-disable react/prop-types */
/* eslint-disable react/display-name */
import React from "react";
import { useSelector } from "react-redux";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import isNil from "lodash/isNil";
import Line from "components/Placeholders/Line";
import { selectors as JobDetailSelectors } from "store/modules/jobDetails";
import DisableCellOverlay from "../../components/Controls/DisableCellOverlay";
import Can from "components/Authorization/Can";
import { PositionPermissions } from "helpers/constants/permissions";

export default function useNoShowCount(disabled) {
    const canAddWorker = useSelector(JobDetailSelectors.getCanAddWorker);
    return {
        id: "noShowCount",
        width: 70,
        Header: () => (
            <Tooltip title="No shows" placement="top">
                <span>NS.</span>
            </Tooltip>
        ),
        Cell: ({ row: { original: { noShowCount } } }) => (
            <>
                {isNil(noShowCount) ? <Line /> : <Typography variant="body1">{noShowCount}</Typography>}
                <DisableCellOverlay visible={disabled} />
            </>
        ),
        Footer: (<Can perform={PositionPermissions.create.fill.value}>
            {allowed => {
                if (!allowed) {
                    return null;
                }
                return canAddWorker && !disabled ? <Line /> : null
            }}
        </Can>)
    };
}
