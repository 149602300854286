/* eslint-disable react/display-name */
/* eslint-disable react/prop-types */
import React from "react";
import Grid from "@mui/material/Grid";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import { weeklySummaryPopoverStyles } from "views/Finance/helpers";

import WeeklyCard from "./WeeklyCard";

function WeeklySummaryPopover(props) {
  const { modalData, anchorEl, onClose, open, type, width } = props;
  const { week, lastWeek, calculated } = modalData;

  const renderDefaultContents = () => <Typography variant="h6">Default Content Yo!</Typography>;

  return (
    <Popover
      id="mouse-over-popover"
      className={weeklySummaryPopoverStyles().popover}
      classes={{ paper: weeklySummaryPopoverStyles().paper }}
      open={open}
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
      transformOrigin={{ vertical: "bottom", horizontal: "center" }}
      onClose={onClose}
      disableRestoreFocus>
      <Grid container item alignItems="center" style={{ width: width ? width : "inherit" }}>
        {type && type === "summary" ? (
          <WeeklyCard week={week} lastWeek={lastWeek} calculated={calculated} />
        ) : (
          renderDefaultContents()
        )}
      </Grid>
    </Popover>
  );
}

export default WeeklySummaryPopover;
