import { gql } from "@apollo/client";

export default gql`
  query getAllEmployers(
    $first: Int
    $last: Int
    $after: String
    $before: String
    $bannedWorkerId: String
    $order: [OrderInput!]
    $like: [FilterInput!]
  ) {
    employers(
      first: $first
      last: $last
      after: $after
      before: $before
      bannedWorkerId: $bannedWorkerId
      like: $like
      order: $order
      isDeleted: false
    ) {
      totalCount
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        node {
          id
          qbId
          companyName
          firstName
          lastName
          businessPhone
          mobilePhone
          city
          region
          rating
          jobCount
          createdAt
          createdAtUtc
        }
      }
    }
  }
`;
