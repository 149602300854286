import gql from "graphql-tag";

export const updateProfileMutation = gql`
  mutation($data: UpdateAdminProfileInput!) {
    editAdminProfile(data: $data) {
      id
      email
      firstName
      lastName
      title
      role
      addedBy
      signedProfileImageUrl
    }
  }
`;

export default updateProfileMutation;
