import React, { useCallback } from "react";
import { connect, useDispatch } from "react-redux";
import { withRouter } from "react-router-dom";
import _debounce from "lodash/debounce";
import {
    getTdProps,
    getTdPropsV7,
    privateJobColumns,
    sharedProps,
} from "views/Job/JobOverview/components/tables/helpers/tableProps";
import { actions as JobActions, selectors as JobSelectors } from "store/modules/jobs";
import ReactTableCustom from "components/ReactTable";


const TableWrapper = (props) => {
    const dispatch = useDispatch();
    const fetchData = useCallback(({ pageIndex, pageSize, filters, sortBy }) => {
        dispatch(() => props.onSortedChange(sortBy))
        dispatch(() => props.onFilteredChange(filters))
        dispatch(() => props.onPageSizeChange(pageSize))
        dispatch(() => props.onPageChange(pageIndex))

    }, []);
    return (
        <ReactTableCustom
            {...sharedProps}
            loading={props.isLoading}
            fetchData={fetchData}
            defaultFilter={props.pageFilter}
            currentPage={props.currentPage}
            defaultPageSize={props.pageSize}
            pageCount={props.totalCount}
            defaultSort={props.pageSort}
            data={props.jobs}
            columns={[...privateJobColumns]}
            onRowClick={getTdPropsV7}
        />
    )
}
class JobListTable extends React.Component {
    componentDidMount() {
        if (this.props.id) {
            this.props.onFilteredChange([{ id: "privateJobRecurringId", value: this.props.id }]);
        }
    }

    render() {
        return (
            <TableWrapper {...this.props} />
        );
    }
}

const mapState = state => {
    const { totalCount, pageSize, currentPage, isLoading } = JobSelectors.getPrivateJobPaginationData(
        state,
    );
    return {
        jobs: JobSelectors.getListData(state, {
            timeframe: "private",
            part: "data",
        }),
        pageFilter: JobSelectors.getListData(state, {
            timeframe: "private",
            part: "filter",
        }),
        pageSort: JobSelectors.getListData(state, {
            timeframe: "private",
            part: "sort",
        }),
        totalCount,
        pageSize,
        currentPage,
        isLoading,
    };
};
const mapAction = {
    fetchJobs: JobActions.fetchPrivateJobs,
    onPageChange: JobActions.fetchPrivateJobs,
    onSortedChange: JobActions.setPrivateJobSort,
    onFilteredChange: JobActions.setPrivateJobFilter,
    onPageSizeChange: JobActions.setPrivateJobPageSize,
};

export default connect(
    mapState,
    mapAction,
)(withRouter(JobListTable));
