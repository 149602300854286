import React, { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import Chip from "@mui/material/Chip";
import Grid from "@mui/material/Grid";
import makeStyles from '@mui/styles/makeStyles';
import Info from "@mui/icons-material/Info";
import MonetizationOn from "@mui/icons-material/MonetizationOn";
import { JobPermissions } from "helpers/constants/permissions";
import Can from "components/Authorization/Can";
import Button from "components/Button/Button";
import { actions as JobDetailActions, selectors as JobDetailSelectors } from "store/modules/jobDetails";
import DecreasePositionsDialog from "./components/DecreasePositionsDialog";
import DequeuePayDialog from "./components/DequeuePayDialog";
import IncreasePositionsDialog from "./components/IncreasePositionsDialog";
import PayrollSummary from "./components/PayrollSummary";
import PositionStatus from "./components/PositionStatus";
import PositionTableMenu from "./components/PositionTableMenu";

const useStyles = makeStyles(({ spacing, typography, customPalette }) => ({
  titleContainer: {
    padding: `${spacing(1)} ${spacing(2)}`,
    minHeight: 64,
  },
  pay: {
    marginRight: spacing(2),
    marginLeft: spacing(2),
    "& button": {
      paddingLeft: spacing(2),
      paddingRight: spacing(2),
    },
  },
  payLabel: {
    marginTop: 1,
  },
  errorIcon: {
    ...typography.body1,
    color: customPalette.red[700],
    marginRight: -spacing(0.5),
  },
  errorChip: {
    backgroundColor: customPalette.red[100],
    ...typography.body1,
    color: customPalette.red[900],
    padding: `${spacing(1)} ${spacing(0.5)}`,
    marginRight: spacing(2),
  },
}));

function PositionTableHeader(props) {
  const {
    isEditing,
    onEditSave,
    onEditCancel,
    canSaveEdit,
    editErrorMessage,
    handleRatingAll,
    handleCheckingInAll,
    handleCheckingOutAll,
    handleEditingRatesAll,
    handleAddWorker,
  } = props;

  const classes = useStyles();
  const dispatch = useDispatch();
  const [isIncreasePositions, setIsIncreasePositions] = useState(false);
  const [isDecreasePositions, setIsDecreasePositions] = useState(false);
  const [isDequeuePay, setIsDequeuePay] = useState(false);
  const [isReviewingPayroll, setIsReviewingPayroll] = useState(false);
  const [changeBy, setChangeBy] = useState("");

  const canPayWorkers = useSelector(JobDetailSelectors.getCanPayWorkers);
  const isMissingShiftInfo = useSelector(JobDetailSelectors.getIsMissingShiftInfo);
  const jobId = useSelector(JobDetailSelectors.getJobId);

  const toggleIncreasePositions = useCallback(() => {
    setIsIncreasePositions(v => !v);
    setChangeBy("");
  }, []);

  const toggleDecreasePositions = useCallback(() => {
    setIsDecreasePositions(v => !v);
    setChangeBy("");
  }, []);

  const toggleDequeuePay = useCallback(() => {
    setIsDequeuePay(v => !v);
  }, []);

  const onValueChange = useCallback(e => {
    setChangeBy(e.currentTarget.value);
  }, []);

  const toggleReviewingPayroll = useCallback(() => {
    setIsReviewingPayroll(v => !v);
  }, []);

  const handleIncreasePositions = () => {
    toggleIncreasePositions();
    dispatch(JobDetailActions.increasePositions(parseInt(changeBy, 10)));
  };

  const handleDecreasePositions = () => {
    toggleDecreasePositions();
    dispatch(JobDetailActions.decreasePositions(parseInt(changeBy, 10)));
  };

  const handlePayJob = async () => {
    await dispatch(JobDetailActions.payJobPayment()).then(() => {
      toggleReviewingPayroll();
    });
  };

  const handleDequeuePay = () => {
    toggleDequeuePay();
    dispatch(JobDetailActions.dequeuePay());
  };

  return (
    <>
      <Grid className={classes.titleContainer} container>
        <PositionStatus />
        <Grid container item xs={6} justifyContent="flex-end" alignItems="center">
          {isEditing ? (
            <>
              {editErrorMessage && (
                <Chip
                  icon={<Info className={classes.errorIcon} />}
                  label={editErrorMessage}
                  className={classes.errorChip}
                />
              )}
              <Button label="Cancel" onClick={onEditCancel} color="transparent" />
              <Button label="Save Changes" onClick={onEditSave} disabled={!canSaveEdit} />
            </>
          ) : (
            <>
              <Can perform={JobPermissions.action.payWorkers.value}>
                {allowed => {
                  return allowed && canPayWorkers ? (
                    <Button
                      label="Pay"
                      className={classes.pay}
                      labelClassName={classes.payLabel}
                      icon={<MonetizationOn />}
                      alignIcon="left"
                      onClick={toggleReviewingPayroll}
                      disabled={isMissingShiftInfo}
                    />
                  ) : null;
                }}
              </Can>
              <PositionTableMenu
                triggerRateAll={handleRatingAll}
                triggerCheckInAll={handleCheckingInAll}
                triggerCheckOutAll={handleCheckingOutAll}
                triggerEditAllRates={handleEditingRatesAll}
                triggerAddWorker={handleAddWorker}
                triggerIncreasePositions={toggleIncreasePositions}
                triggerDecreasePositions={toggleDecreasePositions}
                triggerDequeuePay={toggleDequeuePay}
              />
            </>
          )}
        </Grid>
      </Grid>
      <IncreasePositionsDialog
        open={isIncreasePositions}
        onClose={toggleIncreasePositions}
        onCancel={toggleIncreasePositions}
        onConfirm={handleIncreasePositions}
        value={changeBy}
        onChange={onValueChange}
      />
      <DecreasePositionsDialog
        open={isDecreasePositions}
        onClose={toggleDecreasePositions}
        onCancel={toggleDecreasePositions}
        onConfirm={handleDecreasePositions}
        value={changeBy}
        onChange={onValueChange}
      />
      <DequeuePayDialog
        open={isDequeuePay}
        onClose={toggleDequeuePay}
        onCancel={toggleDequeuePay}
        onConfirm={handleDequeuePay}
      />
      {/*
       */}
      <PayrollSummary
        visible={isReviewingPayroll}
        onCancel={toggleReviewingPayroll}
        onConfirm={handlePayJob}
      />
    </>
  );
}

PositionTableHeader.propTypes = {
  isEditing: PropTypes.bool.isRequired,
  onEditSave: PropTypes.func,
  onEditCancel: PropTypes.func,
  canSaveEdit: PropTypes.bool.isRequired,
  editErrorMessage: PropTypes.string,
  handleRatingAll: PropTypes.func.isRequired,
  handleCheckingInAll: PropTypes.func.isRequired,
  handleCheckingOutAll: PropTypes.func.isRequired,
  handleEditingRatesAll: PropTypes.func.isRequired,
  handleAddWorker: PropTypes.func.isRequired,
};

PositionTableHeader.defaultProps = {
  onEditSave: null,
  onEditCancel: null,
  editErrorMessage: null,
};

export default PositionTableHeader;
