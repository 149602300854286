export function getTermsOfService(state) {
  return state.faqs.termsOfService;
}

export function getIsLoading(state) {
    return state.faqs.isTermsOfServiceLoading;
}
export const getAdminSetting = state => {  
  return state.faqs.adminSettings;
};

// Loading selectors
export const getLoadingStateFactory = loader => state => {
return state.faqs.loading[loader] || false;
};

export const getIsAdminSettingSaveLoading = getLoadingStateFactory("adminSettingSave");