import React from "react";
import { useDispatch } from "react-redux";
import Datetime from "react-datetime";
import PropTypes from "prop-types";
import moment from "moment-timezone";
import { FormControlLabel, Switch } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import ErrorIcon from "@mui/icons-material/Error";
import InfoIcon from "@mui/icons-material/InfoOutlined";
import AddressAutocomplete from "components/Autocomplete/AddressAutocomplete";
import TagsInput from "components/TagsInput";
import FormRow from "./FormRow";
import { actions as PostingActions } from "store/modules/posting";
import HelperMessage from "./HelperMessage";

//x function isValidStartDate(current) {
//     return moment(current).isSameOrAfter(moment().format("YYYY-MM-DD"));
// }

function JobDetailsSection(props) {
    const {
        // isPast,
        jobTypeList,
        jobType,
        description,
        isPrivate,
        tags,
        canPostPrivateJob,
    } = props;

    const dispatch = useDispatch();

    const onJobTypeChange = (event) => {
        dispatch(PostingActions.setJobType(event.target.value))
    }

    const onDescriptionChange = (event) => {
        dispatch(PostingActions.setDescription(event.target.value))
    }

    const onIsPrivateChange = (isPrivate) => {
        dispatch(PostingActions.setIsPrivate(isPrivate))
    }

    const onAddTagsChange = (tags) => {
        dispatch(PostingActions.setJobTags(tags))
    }

    return (
        <React.Fragment>
            <FormRow style={{ marginTop: 12 }}>
                <FormControl fullWidth>
                    <TextField
                        select
                        label="Work Type"
                        value={jobType}
                        onChange={onJobTypeChange}
                    >
                        {jobTypeList.map(({ label, value }) => (
                            <MenuItem key={`jobType-${value}`} value={value}>
                                {label}
                            </MenuItem>
                        ))}
                    </TextField>
                </FormControl>
            </FormRow>
            {/* <Grid container item xs={12}>
                <Grid container item xs={4} direction="column" style={{ paddingRight: 4, zIndex: 100 }}>
                    <FormControl fullWidth margin="normal">
                        <InputLabel htmlFor="start">Start Time</InputLabel>
                        <Datetime
                            input
                            dateFormat={false}
                            id="start"
                            timeConstraints={{ minutes: { step: 15 } }}
                            timeFormat="h:mm a"
                            onChange={onStartTimeChange}
                            value={startTime}
                        />
                    </FormControl>
                </Grid>
                <Grid container item xs={4} direction="column" style={{ paddingLeft: 4, zIndex: 100 }}>
                    <FormControl fullWidth margin="normal">
                        <InputLabel htmlFor="end">End Time</InputLabel>
                        <Datetime
                            input
                            dateFormat={false}
                            id="end"
                            timeConstraints={{ minutes: { step: 15 } }}
                            timeFormat="h:mm a"
                            onChange={onEndTimeChange}
                            value={endTime}
                        />
                    </FormControl>
                </Grid>
                <Grid container item xs={4}>
                    <HelperMessage {...helperProps} />
                </Grid>
            </Grid>
            {requiresBreak && (
                <FormRow
                    helper={
                        <HelperMessage
                            icon={<InfoIcon color="disabled" />}
                            message={breakTypeList[breakType].message}
                        />
                    }
                >
                    <Grid container item xs={12}>
                        <Grid container item xs={6} direction="column" style={{ paddingRight: 4 }}>
                            <FormControl fullWidth margin="normal">
                                <TextField
                                    select
                                    label="Break Min"
                                    value={breakMins}
                                    onChange={onBreakMinsChange}
                                // margin="normal"
                                >
                                    {breakDurationList.map(({ label, value }) => (
                                        <MenuItem key={`breakMins-${value}`} value={value}>
                                            {label}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </FormControl>
                        </Grid>
                        <Grid container item xs={6} direction="column" style={{ paddingLeft: 4 }}>
                            <FormControl fullWidth margin="normal">
                                <TextField
                                    select
                                    label="Break Type"
                                    value={breakType}
                                    onChange={onBreakTypeChange}
                                // margin="normal"
                                >
                                    {breakTypeList.map(({ label, value }) => (
                                        <MenuItem key={`breakType-${value}`} value={value}>
                                            {label}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </FormControl>
                        </Grid>
                    </Grid>
                </FormRow>
            )}
            <FormRow helper={positions.length > 0 && <HelperMessage {...workersNeededHelperProps} />}>
                <TextField
                    id="positions"
                    label="Workers Needed"
                    value={workersNeeded}
                    onChange={onWorkersNeededChange}
                    onBlur={validateWorkersNeeded}
                    type="number"
                    margin="normal"
                    inputProps={{
                        min: 1,
                    }}
                />
            </FormRow> */}
            {/* <FormRow>
                <TextField
                    select
                    label="Work Type"
                    value={jobType}
                    onChange={onJobTypeChange}
                    margin="normal">
                    {jobTypeList.map(({ label, value }) => (
                        <MenuItem key={`jobType-${value}`} value={value}>
                            {label}
                        </MenuItem>
                    ))}
                </TextField>
            </FormRow> */}
            <FormRow
                // helper={
                //   description.length > 500 && (
                //     <HelperMessage
                //       icon={<ErrorIcon color="error" />}
                //       message={`${description.length - 500} character(s) too many`}
                //     />
                //   )
                // }
                style={{ marginTop: 32 }}
            >
                <FormControl fullWidth>
                    <TextField
                        id="description"
                        label="Description"
                        placeholder="Use this section to add any additional information that may be helpful to your worker(s)"
                        value={description}
                        multiline
                        rows="4"
                        onChange={onDescriptionChange}
                    // helperText={
                    //   description.length === 0
                    //     ? "500 characters max"
                    //     : `${500 - description.length} character(s) left`
                    // }
                    />
                </FormControl>
            </FormRow>

            {canPostPrivateJob && (
                <>
                    <FormRow style={{ marginTop: "10px" }}>
                        <FormControl fullWidth>
                            <FormControlLabel
                                labelPlacement="start"
                                control={
                                    <Switch
                                        id="isPrivate"
                                        defaultChecked={isPrivate}
                                        onChange={(e) => onIsPrivateChange(e.target.checked)}
                                        name="isPrivate"
                                        color="secondary"
                                    />
                                }
                                style={{ color: "rgb(33 42 52)", width: "fit-content", justifyContent: 'flex-end', marginLeft: '0' }}
                                label="Is Private Job"
                            />
                        </FormControl>
                    </FormRow>

                    {isPrivate && (
                        <FormRow style={{ marginTop: 32 }}>
                            {/* <InputLabel htmlFor="date">Tags</InputLabel> */}
                            <TagsInput
                                selectedTags={onAddTagsChange}
                                defaultValue={tags}
                                fullWidth
                                label="Tags"
                                variant="outlined"
                                id="tags"
                                name="tags"
                                placeholder="Add Tag"
                            />
                        </FormRow>
                    )}

                </>
            )}
        </React.Fragment>
    );
}

JobDetailsSection.propTypes = {
    // isPast: PropTypes.bool.isRequired,
    jobTypeList: PropTypes.array.isRequired,
    jobType: PropTypes.number.isRequired,
    description: PropTypes.string.isRequired,
};

export default JobDetailsSection;
