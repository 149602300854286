import { gql } from "@apollo/client";
import PayrollFields from "../fragments/payroll-fragment";

export default gql`
  mutation initializeJobPayroll($data: PayJobInput!) {
    initializeJobPayroll(data: $data) {
      ...PayrollFields
    }
  }
  ${PayrollFields}
`;
export const initializePositionPayrollMutation = gql`
  mutation initializePositionPayroll($positionId: Int!) {
    initializePositionPayroll(positionId: $positionId) {
      ...PayrollFields
    }
  }
  ${PayrollFields}
`;

export const initializeJobPayrollDetailMutation = gql`
  query initializeJobPayrollDetail($data: PayJobInput!) {
    initializeJobPayrollDetail(data: $data){
      id
      jobId
      workerId
      breakMins
      durationMins
      startShiftUtc
      endShiftUtc
      grossPay
      netPay
      payRate
      bonus
      worker{
        id
        firstName
        lastName
        signedProfileImageUrl
      }
    }
  }
`;
