import React from "react";
import PropTypes from "prop-types";
import Avatar from "@mui/material/Avatar";
import Chip from "@mui/material/Chip";
import withStyles from '@mui/styles/withStyles';
import Tooltip from "@mui/material/Tooltip";
import AccessTime from "@mui/icons-material/AccessTime";
import AssignmentTurnedIn from "@mui/icons-material/AssignmentTurnedIn";
import Cancel from "@mui/icons-material/Cancel";
import Check from "@mui/icons-material/Check";
import CheckCircleOutlined from "@mui/icons-material/CheckCircleOutlined";
import CloudUpload from "@mui/icons-material/CloudUpload";
import DoneAll from "@mui/icons-material/DoneAll";
import HelpOutlineOutlined from "@mui/icons-material/HelpOutlineOutlined";
import InfoOutlined from "@mui/icons-material/InfoOutlined";
import LowPriority from "@mui/icons-material/LowPriority";
import Redo from "@mui/icons-material/Redo";
import SmsFailed from "@mui/icons-material/SmsFailed";
import SpeakerNotes from "@mui/icons-material/SpeakerNotes";
import SpeakerNotesOff from "@mui/icons-material/SpeakerNotesOff";
import SyncDisabled from "@mui/icons-material/SyncDisabled";
import Textsms from "@mui/icons-material/Textsms";
import TouchApp from "@mui/icons-material/TouchApp";
import Warning from "@mui/icons-material/Warning";
import {
  PaymentStatusCode,
  PayrollStatusCode,
  PaymentBatchStatusCode,
} from "helpers/constants/index";

function Badge({ classes, Icon, iconOnly, label }) {
  if (iconOnly) {
    return (
      <Tooltip title={label} placement="top">
        <Avatar className={classes.avatarRoot}>
          <Icon fontSize="small" />
        </Avatar>
      </Tooltip>
    );
  }

  return (
    <Chip icon={<Icon className={classes.icon} />} label={label} className={classes.chipRoot} />
  );
}

Badge.propTypes = {
  classes: PropTypes.object.isRequired,
  Icon: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
  iconOnly: PropTypes.bool,
};

Badge.defaultProps = {
  iconOnly: false,
};

const styleFactory = color => ({ customPalette, typography, spacing }) => ({
  chipRoot: {
    backgroundColor: customPalette[color][100],
    ...typography.body1,
    color: customPalette[color][900],
    padding: `${spacing(1)} ${spacing(0.5)}`,
  },
  avatarRoot: {
    backgroundColor: customPalette[color][100],
    ...typography.body1,
    color: customPalette[color][700],
  },
  icon: {
    ...typography.body1,
    color: customPalette[color][700],
    marginRight: -spacing(0.5),
  },
});

export const MissingInfoBadge = withStyles(styleFactory("red"))(props => (
  <Badge Icon={Warning} label="Missing info" {...props} />
));

export const IncompleteBadge = withStyles(styleFactory("yellow"))(props => (
  <Badge Icon={InfoOutlined} label="Awaiting pay" {...props} />
));

export const QueuedBadge = withStyles(styleFactory("blue"))(props => (
  <Badge Icon={AccessTime} label="Queued" {...props} />
));

export const SendingBadge = withStyles(styleFactory("blue"))(props => (
  <Badge Icon={CloudUpload} label="Sending" {...props} />
));

export const AwaitingResponseBadge = withStyles(styleFactory("blue"))(props => (
  <Badge Icon={Textsms} label="Awaiting Responses" {...props} />
));

export const ProcessingResponseBadge = withStyles(styleFactory("blue"))(props => (
  <Badge Icon={SpeakerNotes} label="Processing Response" {...props} />
));

export const DeliveredBadge = withStyles(styleFactory("blue"))(props => (
  <Badge Icon={CheckCircleOutlined} label="Delivered" {...props} />
));

export const AcceptedBadge = withStyles(styleFactory("green"))(props => (
  <Badge Icon={Check} label="Accepted" {...props} />
));

export const MultiAcceptedBadge = withStyles(styleFactory("green"))(props => (
  <Badge Icon={DoneAll} label="Accepted all" {...props} />
));

export const DeclinedBadge = withStyles(styleFactory("red"))(props => (
  <Badge Icon={SpeakerNotesOff} label="Declined" {...props} />
));

export const FailedBadge = withStyles(styleFactory("red"))(props => (
  <Badge Icon={SmsFailed} label="Failed" {...props} />
));

export const RetriedBadge = withStyles(styleFactory("yellow"))(props => (
  <Badge Icon={Redo} label="Retried" {...props} />
));

export const RetriedCancelledBadge = withStyles(styleFactory("red"))(props => (
  <Badge Icon={SyncDisabled} label="Retry Cancelled" {...props} />
));

export const CancelledBadge = withStyles(styleFactory("red"))(props => (
  <Badge Icon={Cancel} label="Cancelled" {...props} />
));

export const ManualActionBadge = withStyles(styleFactory("red"))(props => (
  <Badge Icon={TouchApp} label="Manual action required" {...props} />
));

export const DeferredBadge = withStyles(styleFactory("yellow"))(props => (
  <Badge Icon={LowPriority} label="Deferred" {...props} />
));

export const UnknownBadge = withStyles(styleFactory("red"))(props => (
  <Badge Icon={HelpOutlineOutlined} label="Unknown" {...props} />
));

export const ManualResolvedBadge = withStyles(styleFactory("green"))(props => (
  <Badge Icon={AssignmentTurnedIn} label="Manually Resolved" {...props} />
));

const paymentStatusBadgeMap = {
  [PaymentStatusCode.Queued]: QueuedBadge,
  [PaymentStatusCode.Sending]: SendingBadge,
  [PaymentStatusCode.AwaitingResponse]: AwaitingResponseBadge,
  [PaymentStatusCode.ProcessingResponse]: ProcessingResponseBadge,
  [PaymentStatusCode.Delivered]: DeliveredBadge,
  [PaymentStatusCode.Accepted]: AcceptedBadge,
  [PaymentStatusCode.Declined]: DeclinedBadge,
  [PaymentStatusCode.Failed]: FailedBadge,
  [PaymentStatusCode.Retried]: RetriedBadge,
  [PaymentStatusCode.RetryCancelled]: RetriedCancelledBadge,
  [PaymentStatusCode.Cancelled]: CancelledBadge,
  [PaymentStatusCode.ManualAction]: ManualActionBadge,
  [PaymentStatusCode.ManualResolved]: ManualResolvedBadge,
  [PaymentStatusCode.Deferred]: DeferredBadge,
  [PaymentStatusCode.Unknown]: UnknownBadge,
};

const payrollStatusBadgeMap = {
  [PayrollStatusCode.MissingInfo]: MissingInfoBadge,
  [PayrollStatusCode.ReadyForPay]: IncompleteBadge,
  [PayrollStatusCode.Queued]: QueuedBadge,
  [PayrollStatusCode.Sending]: SendingBadge,
  [PayrollStatusCode.AwaitingResponse]: AwaitingResponseBadge,
  [PayrollStatusCode.ProcessingResponse]: ProcessingResponseBadge,
  [PayrollStatusCode.Delivered]: DeliveredBadge,
  [PayrollStatusCode.Accepted]: AcceptedBadge,
  [PayrollStatusCode.Declined]: DeclinedBadge,
  [PayrollStatusCode.Failed]: FailedBadge,
  [PayrollStatusCode.Retried]: RetriedBadge,
  [PayrollStatusCode.RetryCancelled]: RetriedCancelledBadge,
  [PayrollStatusCode.Cancelled]: CancelledBadge,
  [PaymentStatusCode.ManualResolved]: ManualResolvedBadge,
  [PayrollStatusCode.ManualAction]: ManualActionBadge,
  [PayrollStatusCode.Deferred]: DeferredBadge,
  [PayrollStatusCode.Unknown]: UnknownBadge,
};

const jobPayrollStatusBadgeMap = {
  [PayrollStatusCode.MissingInfo]: MissingInfoBadge,
  [PayrollStatusCode.ReadyForPay]: IncompleteBadge,
  [PayrollStatusCode.Queued]: QueuedBadge,
  [PayrollStatusCode.Sending]: SendingBadge,
  [PayrollStatusCode.AwaitingResponse]: AwaitingResponseBadge,
  [PayrollStatusCode.ProcessingResponse]: ProcessingResponseBadge,
  [PayrollStatusCode.Delivered]: DeliveredBadge,
  [PayrollStatusCode.Accepted]: AcceptedBadge,
  [PayrollStatusCode.Declined]: DeclinedBadge,
  [PayrollStatusCode.Failed]: FailedBadge,
  [PayrollStatusCode.Retried]: RetriedBadge,
  [PayrollStatusCode.RetryCancelled]: RetriedCancelledBadge,
  [PayrollStatusCode.Cancelled]: CancelledBadge,
  [PaymentStatusCode.ManualResolved]: ManualResolvedBadge,
  [PayrollStatusCode.ManualAction]: ManualActionBadge,
  [PayrollStatusCode.Deferred]: DeferredBadge,
  [PayrollStatusCode.Unknown]: UnknownBadge,
};

export function PaymentBadge({ paymentStatus, ...props }) {
  const PaymentStatusBadge = paymentStatusBadgeMap[paymentStatus];
  return <PaymentStatusBadge iconOnly {...props} />;
}

PaymentBadge.propTypes = {
  paymentStatus: PropTypes.string.isRequired,
};

export const JobPayrollBadge = ({ payrollStatus, ...props }) => {
  const PayrollStatusBadge = jobPayrollStatusBadgeMap[payrollStatus];
  return <PayrollStatusBadge {...props} />;
};

export function PayrollBadge({ payrollStatus, ...props }) {
  const PayrollStatusBadge = payrollStatusBadgeMap[payrollStatus];
  return <PayrollStatusBadge {...props} />;
}

PayrollBadge.propTypes = {
  payrollStatus: PropTypes.string.isRequired,
};

const paymentBatchStatusBadgeMap = {
  [PaymentBatchStatusCode.Queued]: QueuedBadge,
  [PaymentBatchStatusCode.Pending]: QueuedBadge,
  [PaymentBatchStatusCode.Completed]: AcceptedBadge,
  [PaymentBatchStatusCode.Unresolved]: MissingInfoBadge,
  [PaymentBatchStatusCode.Resolved]: MultiAcceptedBadge,
};

export function PaymentBatchBadge({ paymentBatchStatus, label, ...props }) {
  const PaymentBatchStatusBadge = paymentBatchStatusBadgeMap[paymentBatchStatus];
  let overrideLabel = label;
  if (paymentBatchStatus === PaymentBatchStatusCode.Unresolved) {
    overrideLabel = "Unresolved errors";
  } else if (paymentBatchStatus === PaymentBatchStatusCode.Resolved) {
    overrideLabel = "Resolved";
  } else if (paymentBatchStatus === PaymentBatchStatusCode.Completed) {
    overrideLabel = "Completed";
  } else if (paymentBatchStatus === PaymentBatchStatusCode.Pending) {
    overrideLabel = "Pending";
  }

  const passProps = { ...props };
  if (overrideLabel) {
    passProps.label = overrideLabel;
  }

  return <PaymentBatchStatusBadge iconOnly {...passProps} />;
}

PaymentBatchBadge.propTypes = {
  paymentBatchStatus: PropTypes.string.isRequired,
  label: PropTypes.string,
};

PaymentBatchBadge.defaultProps = {
  label: null,
};
