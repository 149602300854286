import { gql } from "@apollo/client";
import WorkerFields from "../fragments/worker-fragment";

export default gql`
  mutation suspendWorker($data: SuspendWorkerInput!) {
    suspendWorker(data: $data) {
      ...WorkerFields
    }
  }
  ${WorkerFields}
`;
