/* eslint-disable react/display-name */
/* eslint-disable react/prop-types */
import React, { useCallback, useEffect } from "react";
import { useSelector } from "react-redux";
import Grid from "@mui/material/Grid";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";

import { incompleteJobsTableStyles } from "views/Finance/helpers";
import Loader from "components/Loader/Loader";
import { selectors as FinanceSelectors } from "store/modules/finance";
import OverviewTableActions from "../../../Menus/OverviewTableActions";
import { convertUTCToLocal, convertUTCToTimezoneWithOffset } from "../../../../../../../utils/time";
import ReactTableSimple from "components/ReactTable/ReactTableSimple";

function IncompleteJobsTable() {
    const classes = incompleteJobsTableStyles();
    const paymentsOverview = useSelector(FinanceSelectors.getPaymentsOverview);
    useEffect(() => { }, [paymentsOverview]);

    const RenderJobsTable = () => {
        const columns = [
            {
                Header: "Employer",
                sortable: true,
                id: "CompanyName",
                accessor: ({ CompanyName }) => CompanyName,
                styles: { minWidth: 160, cursor: "pointer" },
                Cell: ({ row: { original: { CompanyName } } }) => CompanyName,
            },
            {
                Header: "Job",
                show: true,
                accessor: ({ jobId }) => jobId,
                sortable: true,
                id: "jobId",
                styles: { minWidth: 50, cursor: "pointer" },
                Cell: ({ row: { original: { jobId } } }) => jobId,
            },
            {
                Header: "Issues",
                sortable: true,
                disableFilters:true,
                id: "issues",
                headerStyle: { cursor: "pointer", textAlign: "center" },
                styles: { minWidth: 80, cursor: "pointer", textAlign: "center" },
                accessor: ({ issues }) => issues,
                Cell: ({ row: { original: { issues } } }) => (
                    <Tooltip title="Job positions that need attention before they can be paid.">
                        <Typography variant="body2" color={"inherit"}>
                            {issues}
                        </Typography>
                    </Tooltip>
                ),
            },
            {
                Header: "Ready",
                sortable: true,
                disableFilters:true,
                id: "ready",
                show: true,
                headerStyle: { cursor: "pointer", textAlign: "center" },
                styles: { minWidth: 100, cursor: "pointer", textAlign: "center" },
                accessor: ({ ready }) => ready,
                Cell: ({ row: { original: { ready } } }) => (
                    <Tooltip title="Job positions that are ready for payment.">
                        <Typography variant="body2" color={"inherit"}>
                            {ready}
                        </Typography>
                    </Tooltip>
                ),
            },
            {
                Header: "Date",
                sortable: true,
                id: "startDateUtc",
                accessor: ({ startDateUtc }) => startDateUtc,
                styles: { minWidth: 125, cursor: "pointer" },
                Cell: ({ row: { original: { startDateUtc } } }) => (
                    <Tooltip title="The date of the job.">
                        <Typography variant="body2" color={"inherit"}>
                            {convertUTCToTimezoneWithOffset(startDateUtc, '', 'MMM Do, YYYY')}
                        </Typography>
                    </Tooltip>
                ),
            },

            {
                Header: "Shift",
                sortable: true,
                id: "startDate",
                accessor: ({ startDate }) => startDate,
                styles: { minWidth: 125, cursor: "pointer" },
                Cell: ({ row: { original: { startDateUtc, endDateUtc } } }) =>
                    `${convertUTCToLocal(startDateUtc, "h:mm a")} - ${convertUTCToTimezoneWithOffset(endDateUtc, '', "h:mm a")}`,
            },

            {
                Header: "",
                id: "actions",
                collapse: true,
                styles: { cursor: "pointer" },
                Cell: ({ row: { original } }) => <OverviewTableActions position={original} />,
            },
        ];
        const RenderTrProps = (state, rowInfo) => {
            if (rowInfo) {
                return {
                    style: {
                        //   background: rowInfo.row.age > 20 ? 'red' : 'green',
                        //   color: 'white',
                        maxHeight: 25,
                        height: "25px !important",
                    },
                };
            }
            return {
                style: {
                    //   background: rowInfo.row.age > 20 ? 'red' : 'green',
                    //   color: 'white',
                    maxHeight: 25,
                    height: "25px !important",
                },
            };
        };

        const RenderTdProps = () => ({
            onClick: (e, handleOriginal) => {
                if (handleOriginal) {
                    handleOriginal();
                }
            },
            onMouseEnter: (e, handleOriginal) => {
                if (handleOriginal) {
                    handleOriginal();
                }
            },
            onMouseLeave: (e, handleOriginal) => {
                if (handleOriginal) {
                    handleOriginal();
                }
            },
        });

        return (
            <ReactTableSimple
                showPaginationBottom
                filterable={false}
                showPageJump={false}
                showPaginationTop={false}
                resizable={false}
                // loading={!dcPayrollIssues}
                className={classes.table}
                minRows={0}
                defaultPageSize={10}
                data={paymentsOverview.jobs}
                //   onExpandedChange={(newExpanded, index, event) => onExpandedChange(newExpanded, index, event)}
                //   expanded={expanded}
                getTdProps={(state, row, col) => RenderTdProps(state, row, col)}
                getTrProps={(state, row, col) => RenderTrProps(state, row, col)}
                defaultFilterMethod={(filter, row) => String(row[filter.id]) === filter.value}
                NoDataComponent={() => (
                    <Typography
                        variant="subtitle2"
                        className="rt-noData"
                        style={{
                            marginTop: 10,
                            width: "100%",
                            backgroundColor: "#fff",
                            textAlign: "center",
                        }}>{`No Results Found`}</Typography>
                )}
                columns={columns}
            />
        );
    };

    const RenderIncompleteJobs = useCallback(
        () => (
            <Grid item xs={12} className={classes.container}>
                <Grid container direction="row" justifyContent="flex-start">
                    <Grid item xs={12}>
                        <Grid container direction="row" justifyContent="flex-start" style={{ marginBottom: 6 }}>
                            <Typography variant="h6" className={classes.mobileH5}>
                                Incomplete Jobs
                            </Typography>
                            <Typography variant="caption">
                                Jobs that are due to be completed but have shifts that are not ready for payment.
                            </Typography>
                        </Grid>
                        <Grid container direction="row" justifyContent="flex-start">
                            {paymentsOverview.jobs ? (
                                <RenderJobsTable />
                            ) : (
                                <Loader
                                    isLoading={
                                        !paymentsOverview.jobs ||
                                        (paymentsOverview.jobs && !paymentsOverview.jobs.length)
                                    }
                                />
                            )}
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        ),
        [paymentsOverview.jobsPending]
    );

    return <RenderIncompleteJobs />;
}

export default IncompleteJobsTable;
