import { gql } from "@apollo/client";

export const deleteInvoiceItemMutation = gql`
  mutation deleteInvoiceItem($invoiceItemId: Int!, $employerId: String!) {
    deleteInvoiceItem(invoiceItemId: $invoiceItemId, employerId: $employerId) {
      id
      createdAt
      positionId
      invoiceId
      rate
      checkIn
      checkOut
      # checkOutUtc
      checkInUtc

      breakDeductionMins
      totalUnits
      invoicableUnits
      isInvoicable
    }
  }
`;
export const editEmployerMutation = gql`
  mutation editEmployer($data: EditEmployerInput!) {
    editEmployer(data: $data) {
      id
      qbId
      createdAt
      createdAtUtc
      approvedAtUtc
      #   updatedAt
      email
      firstName
      lastName
      billingType
      street
      city
      region
      postalCode
      country
      companyName
      websiteUrl
      description
      businessPhone
      mobilePhone
      title
      isActive
      isApproved
      approvedAt
      profileImageUrl
      logoImageUrl
      payRate
      billingRate
      isMassCheckinEnabled
      isAutoConfirmEnabled
      isDailySummaryEmailEnabled
      #   dcCustomerNumber
    }
  }
`;
export default editEmployerMutation;
