/*eslint-disable*/
import React from "react";

import MUIDialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Typography from "@mui/material/Typography";
import Button from "components/Button/Button";
import { resendDialogStyles } from "views/Finance/helpers";
import { AccountBalanceWalletTwoTone } from "@mui/icons-material";
const isMobile = false;
function ResendDialog(props) {
  const {
    classes,
    cancelText,
    disabled,
    confirmText,
    title,
    description,
    icon,
    onConfirm,
    onCancel,
    onClose,
    open,
    iconFontSize,
    maxWidth,
  } = props;

  const FooterActionsControl = () => (
    <DialogActions className={classes.footer}>
      <React.Fragment>
        <Button label={cancelText || "Close"} onClick={onCancel} color="transparent" />
        <Button
          disabled={disabled}
          label={confirmText || "Update"}
          onClick={onConfirm}
          color="secondary"
        />
      </React.Fragment>
    </DialogActions>
  );

  return (
    <MUIDialog
      className={classes.core}
      fullScreen={isMobile}
      open={open}
      disablePortal={false}
      style={{ top: "-200px" }}
      maxWidth={maxWidth ? maxWidth : "md"}
      disableBackdropClick
      onClose={onClose}>
      <DialogTitle className={classes.title} style={{ justifyContent: "center" }}>
        {icon ? (
          icon
        ) : (
          <AccountBalanceWalletTwoTone
            color="disabled"
            style={{ fontSize: iconFontSize ? iconFontSize : 96 }}
          />
        )}
        <Typography variant="h5" align="center">
          {title ? title : `Resend Payroll`}
        </Typography>
      </DialogTitle>
      <DialogContent className={classes.content}>
        <Typography variant="subtitle1" align="center">
          {description ? description : `Resend this cancelled transfer to the worker?`}
        </Typography>
      </DialogContent>
      <FooterActionsControl />
    </MUIDialog>
  );
}

export default resendDialogStyles(ResendDialog);
