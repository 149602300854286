/* eslint-disable react/display-name */
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVert from "@mui/icons-material/MoreVert";
import { actions as FinanceActions } from "store/modules/finance";

function DeletedInvoiceAction(props) {
    const { invoice } = props;

    const [anchorEl, setAnchorEl] = useState(null);
    const [sheetLoading, setSheetLoading] = useState(false);
    const [uri, setUri] = React.useState(null);
    const aTag = React.useRef();
    const dispatch = useDispatch();

    const handleClick = event => setAnchorEl(event.currentTarget);
    const handleClose = () => setAnchorEl(null);
    const id = `action-menu-worker`;

    const downloadDeletedTimesheet = (id) => {
        if (!sheetLoading) {
            setSheetLoading(true);
            dispatch(FinanceActions.downloadDeletedTimesheet(id)).then(url => {
                if (url) {
                    setUri(url);
                    aTag.current.click();
                }

                setSheetLoading(false);
            });
        }
    };

    const RenderDownloadInvoiceModal = React.forwardRef((props, ref) => (
        <MenuItem
            innerRef={ref}
            onClick={() => {
                // setOpenDownloadInvoice(true);
                downloadDeletedTimesheet(parseInt(invoice.id));
                setAnchorEl(null);
            }}>
            Download Timesheet
        </MenuItem>
    ));

    return (
        <React.Fragment>
            <IconButton
                aria-owns={anchorEl ? id : undefined}
                aria-haspopup="true"
                disableRipple
                onClick={handleClick}
                size="large">
                <MoreVert color="primary" />
            </IconButton>
            {anchorEl ? (
                <Menu
                    id={`action-menu-worker`}
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                    disableAutoFocusItem>
                    <RenderDownloadInvoiceModal invoice={invoice} />
                </Menu>
            ) : null}
            <a ref={aTag} href={uri} target="_blank" rel="noreferrer" />
        </React.Fragment>
    );
}

export default DeletedInvoiceAction;
