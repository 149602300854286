import React, { useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import withStyles from '@mui/styles/withStyles';
import Tooltip from "@mui/material/Tooltip";
import ChevronRight from "@mui/icons-material/ChevronRight";
import EventBusy from "@mui/icons-material/EventBusy";
import WorkOff from "@mui/icons-material/WorkOff";
import _debounce from "lodash/debounce";
import Button from "components/Button/Button";
import { actions as Actions, selectors as Selectors } from "store/modules/workers";
import { sharedProps, sharedWorkerColumns } from "./helpers/tableProps";
import Dialog from "components/Dialog/Dialog";
import ReactTableCustom from "components/ReactTable";
import { useCallback } from "react";
import NotVerifiedIcon from "assets/img/not_verified.svg";
import VerifiedIcon from "assets/img/verified.svg";
import history from "utils/history";


const styles = ({ spacing }) => ({
    profileImage: {
        marginLeft: spacing(2),
        width: "40px",
        height: "40px",
        borderRadius: "50%",
    },
    profileImageHide: {
        marginLeft: spacing(2),
        width: "40px",
        height: "40px",
        borderRadius: "50%",
        visibility: "hidden",
    },
    approvalIcon: {
        width: 21,
        height: 24,
        marginLeft: spacing(2),
    },
});
const WorkerListTable = (props) => {
    const {
        classes,
        workerList,
        onFilteredChange,
        onSortedChange,
        onPageSizeChange,
        onPageChange,
        tabIndex,
        toggleIsApproved,
        data,
        customTableCellProps,
        onFutureJobClick
    } = props;
    const fetchData = useCallback(({ pageIndex, pageSize, filters, sortBy }) => {
        onSortedChange(sortBy)
        onFilteredChange(filters)
        onPageSizeChange(pageSize)
        onPageChange(pageIndex)
    }, []);

    return (
        <ReactTableCustom
            {...sharedProps}
            fetchData={fetchData}
            defaultFilter={workerList.pageFilter}
            defaultPageSize={workerList.pageSize}
            loading={workerList.isLoading}
            data={data}
            pageCount={workerList.totalCount}
            currentPage={workerList.currentPage}
            defaultSort={workerList.pageSort}
            columns={[
                ...sharedWorkerColumns(
                    tabIndex,
                    onFutureJobClick,
                    classes.profileImage,
                    workerList.isLoading,
                    classes.profileImageHide,
                    toggleIsApproved
                ),
            ]}
            onRowClick={customTableCellProps}
        />
    );
}

class WorkerTable extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            value: 0,
            open: false
        };
    }

    customTableCellProps(row, cell, e) {
        if (row && row.original && cell.column.id !== "actions" && cell.column.id !== "futureJobCount" && cell.column.id !== "badge") {
            history.push("/workers/" + row.original.id);
        };
        return;
    };

    getWorkerList() {
        switch (this.props.tabIndex) {
            case 0: {
                return this.props.workerList;
            }
            case 1: {
                return this.props.verifiedWorkerList;
            }
            case 2: {
                return this.props.unverifiedWorkerList;
            }
            case 3: {
                return this.props.suspendedWorkerList;
            }
            case 4: {
                return this.props.deactivatedWorkerList;
            }
            case 5: {
                return this.props.appliedWorkerList;
            }
            default: {
                return {}
            }
        }
    }

    render() {
        const {
            classes,
            onFilteredChange,
            onSortedChange,
            onPageSizeChange,
            onPageChange,
            tabIndex,
            toggleIsApproved,
            onFutureJobClick
        } = this.props;
        return (
            <div>
                <WorkerListTable
                    {...this.props}
                    data={this.props.data}
                    customTableCellProps={this.customTableCellProps}
                    tabIndex={tabIndex}
                    toggleIsApproved={toggleIsApproved}
                    workerList={this.getWorkerList()}
                    classes={classes}
                    onFilteredChange={onFilteredChange}
                    onPageChange={onPageChange}
                    onPageSizeChange={onPageSizeChange}
                    onSortedChange={onSortedChange}
                    onFutureJobClick={onFutureJobClick}
                />
            </div>
        );
    }
}

const EditWorkerButton = ({ props, workerId }) => {
    return <Button icon={<ChevronRight />} color="transparent" size="small" onClick={() => { history.push("/workers/" + workerId); }} />;
};

const mapState = (state, ownProps) => {
    const {
        details,
        suspendedDetails,
        verifiedDetails,
        unverifiedDetails,
        deactivatedDetails,
        appliedDetails
    } = state.workers;

    const getbadge = (data) => {
        if (!data.isActive) {
            return (
                <Tooltip title="Deactivated" placement="top">
                    <WorkOff color="disabled" />
                </Tooltip>
            );
        }

        if (data.isSuspended) {
            return (
                <Tooltip title="Suspended" placement="top">
                    <EventBusy color="disabled" />
                </Tooltip>
            );
        }
        if (!data.isApproved) {
            return (
                <Tooltip title="Needs Verification" placement="top">
                    <img
                        alt="Not Verified Badge"
                        src={NotVerifiedIcon}
                        style={{
                            width: 21,
                            height: 24,
                        }}
                    />
                </Tooltip>
            );
        }

        if (data.isActive) {
            return (
                <Tooltip title="Verified" placement="top">
                    <img
                        alt="Verified Badge"
                        src={VerifiedIcon}
                        style={{
                            width: 21,
                            height: 24,
                        }}
                    />
                </Tooltip>
            );
        }
        return null;
    }

    // let listType;
    let data;
    switch (ownProps.tabIndex) {
        case 0:
            data = details;
            // listType = "workerList";
            break;
        case 1:
            data = verifiedDetails;
            // listType = "verifiedWorkerList";
            break;
        case 2:
            data = unverifiedDetails;
            // listType = "unverifiedWorkerList";
            break;
        case 3:
            data = suspendedDetails;
            // listType = "suspendedWorkerList";
            break;
        case 4:
            data = deactivatedDetails;
            // listType = "deactivatedWorkerList";
            break;
        case 5:
            data = appliedDetails;
            // listType = "appliedWorkerList";
            break;
        default:
            throw new Error("Unknown mapping for worker table");
    }
    const paginationFunctionList = {
        workerList: Selectors.getWorkerListPagingData,
        verifiedWorkerList: Selectors.getVerifiedWorkerListPagingData,
        unverifiedWorkerList: Selectors.getUnverifiedWorkerListPagingData,
        deactivatedWorkerList: Selectors.getDeactivatedWorkerListPagingData,
        suspendedWorkerList: Selectors.getSuspendedWorkerListPagingData,
        appliedWorkerList: Selectors.getAppliedWorkerListPagingData,
    };
    return {
        workerList: {
            pageSort: paginationFunctionList['workerList'](state).sort,
            pageFilter: paginationFunctionList['workerList'](state).filter,
            ...paginationFunctionList['workerList'](state).paging,
        },
        verifiedWorkerList: {
            pageSort: paginationFunctionList['verifiedWorkerList'](state).sort,
            pageFilter: paginationFunctionList['verifiedWorkerList'](state).filter,
            ...paginationFunctionList['verifiedWorkerList'](state).paging,
        },
        unverifiedWorkerList: {
            pageSort: paginationFunctionList['unverifiedWorkerList'](state).sort,
            pageFilter: paginationFunctionList['unverifiedWorkerList'](state).filter,
            ...paginationFunctionList['unverifiedWorkerList'](state).paging,
        },
        deactivatedWorkerList: {
            pageSort: paginationFunctionList['deactivatedWorkerList'](state).sort,
            pageFilter: paginationFunctionList['deactivatedWorkerList'](state).filter,
            ...paginationFunctionList['deactivatedWorkerList'](state).paging,
        },
        suspendedWorkerList: {
            pageSort: paginationFunctionList['suspendedWorkerList'](state).sort,
            pageFilter: paginationFunctionList['suspendedWorkerList'](state).filter,
            ...paginationFunctionList['suspendedWorkerList'](state).paging,
        },
        appliedWorkerList: {
            pageSort: paginationFunctionList['appliedWorkerList'](state).sort,
            pageFilter: paginationFunctionList['appliedWorkerList'](state).filter,
            ...paginationFunctionList['appliedWorkerList'](state).paging,
        },
        data: Object.keys(data).map(workerId => {
            return {
                ...data[workerId],
                actions: <EditWorkerButton props={ownProps} workerId={workerId} />,
                workerStatus: ownProps.tabIndex === 0 ? getbadge(data[workerId]) : null,
            };
        }),
    };
};

const mapActions = (dispatch, ownProps) => {
    const setTabType = () => {
        switch (ownProps.tabIndex) {
            case 0:
                return "workerList";
            case 1:
                return "verifiedWorkerList";
            case 2:
                return "unverifiedWorkerList";
            case 3:
                return "suspendedWorkerList";
            case 4:
                return "deactivatedWorkerList";
            case 5:
                return "appliedWorkerList";
            default:
                return;
        }
    };
    return {
        onPageSizeChange: size => dispatch(Actions.changePageSize(size, setTabType())),
        onPageChange: idx => dispatch(Actions.fetchTableData(idx, setTabType())),
        onSortedChange: sort => dispatch(Actions.changeSort(sort, setTabType())),
        onFilteredChange: data => {
            dispatch(Actions.changeFilter(data, setTabType()));
        },
        toggleIsApproved: (id, val) => dispatch(Actions.toggleIsApproved(id, !val)),
    };
};

export default connect(mapState, mapActions)(withStyles(styles)(withRouter(WorkerTable)));
