import React from "react";
import { useDispatch } from "react-redux";
import Table from "@mui/material/Table";
import Button from "components/Button/Button";
import Typography from "@mui/material/Typography";
import Dialog from "components/Dialog/Dialog";
import { formatMoney } from "utils/stringUtils";
import { convertUTCToLocal } from "utils/time";
import ReactTableSimple from "components/ReactTable/ReactTableSimple";
const tableProps = {
    defaultPageSize: 10,
    rowsPerPageOptions: { rowsPerPageOptions: [10] }
    // defaultFilterMethod: (filter, row) => String(row[filter.id]) === filter.value,
};
function BillingRateHistoryDialog(props) {
    const {
        onClose,
        onCancel,
        openDialog,
        employer
    } = props;

    return (
        <Dialog
            open={openDialog}
            title={
                <React.Fragment>
                    <Typography component="span" style={{ width: "100%", fontSize: 18 }}>
                        {`Employer Billing Rate History`}
                        <Button
                            style={{ float: "right", marginRight: -30 }}
                            label="X"
                            size="small"
                            onClick={onClose}
                            color="transparent" />
                    </Typography>
                </React.Fragment>
            }
            description={null}
            onClose={onClose}
            onCancel={onCancel}
            size="lg"
            hideActions
            alertType="form"
            modalType="billingratehistory">
            <ReactTableSimple
                data={employer.employerRateAuditLogs || []}
                classes="-highlight"
                {...tableProps}
                style={{ textAlign: 'left' }}
                columns={[
                    {
                        Header: "Name",
                        id: "adminName",
                        accessor: ({ adminName }) => {
                            return adminName;
                        },
                        disableSortBy: true,
                        className: "-cursor-pointer",
                        width: 100,
                    },
                    {
                        Header: "Added By Email",
                        id: "adminEmail",
                        accessor: ({ adminEmail }) => {
                            return adminEmail;
                        },
                        disableSortBy: true,
                        className: "-cursor-pointer",
                        width: 200,
                    },
                    {
                        Header: "Billing Rate",
                        id: "newBillingRate",
                        accessor: ({ newBillingRate }) => {
                            return formatMoney(newBillingRate);
                        },
                        disableSortBy: true,
                        className: "-cursor-pointer",
                        width: 100,
                    },
                    {
                        Header: "Pay Rate",
                        id: "newPayRate",
                        accessor: ({ newPayRate }) => {
                            return formatMoney(newPayRate);
                        },
                        disableSortBy: true,
                        className: "-cursor-pointer",
                        width: 100,
                    },
                    {
                        Header: "Date",
                        id: "createdAt",
                        // eslint-disable-next-line react/display-name, react/prop-types
                        accessor: ({ createdAt }) => {
                            const start = convertUTCToLocal(
                                createdAt,
                                "MMM Do, YYYY  h:mm a z"
                            );

                            return start;
                        },
                        disableSortBy: true,
                        className: "-cursor-pointer",
                        width: 200,
                    },
                ]}
            />
        </Dialog>
    );
}

export default BillingRateHistoryDialog;
