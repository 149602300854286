// import { JobPermissions } from "helpers/constants/permissions";
import rules from "helpers/constants/roles";
import { store } from "store/index";

export function hasPermission(action, data) {
  const { roles, permissions } = store.getState().login;
  if (!permissions) {
    return false;
  }

  if (!action) {
    return true;
  }

  return roles.reduce((acc, role) => {
    if (acc) {
      return acc;
    }

    // Do we have a dynamic permission set for the role
    const dynamicPermissions = rules[role] && rules[role].dynamic;
    if (dynamicPermissions) {
      // Do we have a dynamic permission for the given action
      const permissionCondition = dynamicPermissions[action];
      if (permissionCondition) {
        // Found a dynamic permission, offload checks and return result
        return permissionCondition(data);
      }
    }

    // No dynamic permissions were applied so lets check if they have static permission
    if (permissions.includes(action)) {
      // They have access to the resource
      return true;
    }

    return false;
  }, false);
}

/*const accountTypeModifiers = {
  employer: {
    [JobPermissions.read.list.value]: accountId => {
      return {
        employerId: accountId,
      };
    },
  },
};*/

export function applyActionPermissions(action) {
  //   const { permissions, me } = store.getState().login;

  //   if (!permissions) {
  //     throw new Error("No permissions defined!");
  //   }

  //   if (!action) {
  //     return {};
  //   }

  //   const dynamicPermissions = accountTypeModifiers[me.accountType];
  //   if (dynamicPermissions) {
  //     const permissionCondition = dynamicPermissions[action];
  //     if (permissionCondition) {
  //       return permissionCondition(me.accountId);
  //     }
  //   }

  return {};
}
