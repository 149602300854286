import { gql } from "@apollo/client";

export default gql`
  fragment PositionFields on Position {
    jobId
    startShift
    endShift
    startShiftUtc
    endShiftUtc
    durationMins
    workerRating
    defaultWorkerRating
    breakMins
    bonus
    payRate
    billingRate
    payrolls {
      id
      createdAt
      payrollTypeId
      positionId
      taxRegionId
      employmentStandardsRegionId
      rate
      checkIn
      checkOut
      breakDeductionMins
      bonus
      totalUnits
      payableUnits
      payrollType {
        id
        name
        code
      }

      earnings {
        id
        payrollId
        payrollEarningsTypeId
        rate
        units
        amount
      }
      wageEarnings {
        id
        payrollId
        payrollEarningsTypeId
        rate
        units
        amount
      }
      additionalEarnings {
        id
        payrollId
        payrollEarningsTypeId
        rate
        units
        amount
      }
      employeeDeductions {
        id
        payrollId
        amount
        payrollDeductionsTypeId
      }
      employerDeductions {
        id
        payrollId
        amount
        payrollDeductionsTypeId
      }
      totalEmployeeDeductions
      totalEmployerDeductions
      grossWages
      grossEarnings
      netEarnings
      payments {
        id
        createdAt
        positionId
        sentAt
        responseAt
        workerId
        payrollId
        retryPaymentId
        paymentStatusId
        dcTransferId
        email
        amount
        notes
        paymentStatus {
          id
          code
          description
        }
      }
    }
    deprecated_payroll {
      #   dcTransferId
      completedAt
      adjustmentType
      adjustedPayrollId
      netPay
    }
    job {
      isActive
      start
      end
      startUtc
      endUtc
      status
      timeframe
      timezone
      employerId
      employer {
        companyName
      }
    }
  }
`;
