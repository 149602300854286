import * as pagination from "utils/graphql-pagination";
import { selectors as MetaSelectors } from "store/modules/meta";
import { paginationSettings } from "./constants";
import { networkTypes } from "./types";
// Loading selectors
export const getLoadingStateFactory = loader => state => {
  return state.jobs.loading[loader] || false;
};

export const getPrivateJobLoading = getLoadingStateFactory("privateJob");
export const getImportPrivateJobLoading = getLoadingStateFactory("importPrivateJob");
export const getFillPositionLoading = getLoadingStateFactory("fillPosition");
export const isOpenEditPrivateJobDialog = getLoadingStateFactory("editPrivateJobDialog");
export const isOpenDeletePrivateJobPositionDialog = getLoadingStateFactory("deletePrivateJobPositionDialog");
export const isOpenPrivateJobFillPositionDialog = getLoadingStateFactory("privateJobFillPositionDialog");
export const isOpenUploadPrivateJobCSVDialog = getLoadingStateFactory("uploadPrivateJobCSVDialog");

export const createLoadingSelector = MetaSelectors.createLoadingSelector("jobs");
export const createErrorMessageSelector = MetaSelectors.createErrorMessageSelector("jobs");

export const getJobTimeframeCountsLoader = createLoadingSelector([
  networkTypes.FETCH_TIMEFRAME_STATS,
]);

export const getJobListStatsLoader = createLoadingSelector([networkTypes.FETCH_LIST_STATS]);

export const getTimeframeStats = state => state.jobs.timeframeStats;
export const getListStats = (state, timeframe) => state.jobs.listStats[timeframe];
export const getListData = (state, { timeframe, status, part }) => {
  if (!status) {
    return state.jobs[timeframe][part];
  }
  return state.jobs[timeframe][status][part];
};

export const getTodayTotalPaginationData = state => {
  return state.jobs[pagination.getPageInfoAttr(paginationSettings.todayTotal)];
};

export const getTodayActivePaginationData = state => {
  return state.jobs[pagination.getPageInfoAttr(paginationSettings.todayActive)];
};

export const getTodayUnfilledPaginationData = state => {
  return state.jobs[pagination.getPageInfoAttr(paginationSettings.todayUnfilled)];
};

export const getTodayFilledPaginationData = state => {
  return state.jobs[pagination.getPageInfoAttr(paginationSettings.todayFilled)];
};

export const getTodayCompletedPaginationData = state => {
  return state.jobs[pagination.getPageInfoAttr(paginationSettings.todayCompleted)];
};

export const getTomorrowTotalPaginationData = state => {
  return state.jobs[pagination.getPageInfoAttr(paginationSettings.tomorrowTotal)];
};

export const getTomorrowUnfilledPaginationData = state => {
  return state.jobs[pagination.getPageInfoAttr(paginationSettings.tomorrowUnfilled)];
};

export const getTomorrowFilledPaginationData = state => {
  return state.jobs[pagination.getPageInfoAttr(paginationSettings.tomorrowFilled)];
};

export const getUpcomingTotalPaginationData = state => {
  return state.jobs[pagination.getPageInfoAttr(paginationSettings.upcomingTotal)];
};

export const getUpcomingUnfilledPaginationData = state => {
  return state.jobs[pagination.getPageInfoAttr(paginationSettings.upcomingUnfilled)];
};

export const getUpcomingFilledPaginationData = state => {
  return state.jobs[pagination.getPageInfoAttr(paginationSettings.upcomingFilled)];
};

export const getHistoryPaginationData = state => {
  return state.jobs[pagination.getPageInfoAttr(paginationSettings.history)];
};

export const getDraftsPaginationData = state => {
  return state.jobs[pagination.getPageInfoAttr(paginationSettings.drafts)];
};

export const getQueuedPaginationData = state => {
  return state.jobs[pagination.getPageInfoAttr(paginationSettings.queued)];
};

export const getPrivateJobPaginationData = state => {
  return state.jobs[pagination.getPageInfoAttr(paginationSettings.privateJob)];
};

export const getImportedPrivateJob = state => state.jobs.importedPrivateJobList;
export const getPrivateJobPositions = state => state.jobs.privateJobPositionList;
export const getImportedPrivateJobDetail = state => state.jobs.importedPrivateJobDetail;
export const getImportCSVError = state => state.jobs.importCSVErrorList;
export const getfillPositionError = state => state.jobs.fillPositionErrorList;
export const getUpdatePrivateJobError = state => state.jobs.updatePrivateJobErrorList;
export const getDeletePrivateJobPositionError = state => state.jobs.deletePrivateJobPositionErrorList;

