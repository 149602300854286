import { gql } from "@apollo/client";

export default gql`
  fragment PositionWorkerFields on Worker {
    fullName
    firstName
    lastName
    email
    phone
    profileImageUrl
    signedProfileImageUrl
    vaccinationStatus
    fullName
    rating
    street
    city
    region
    country
    postalCode
    positionCount
    banCount
    noShowCount
    isSuspended
    dcCustomerNumber
    lateJobCancelCount
    tags
  }
`;
