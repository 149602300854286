import React, { useState } from "react";
import Fade from "@mui/material/Fade";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import Typography from "@mui/material/Typography";
import Call from "@mui/icons-material/Call";
import { employerPopoverStyles } from "views/Employer/helpers";
import Button from "components/Button/Button";

function EmployerPopover({
  width,
  children,
  title,
  copy,
  icon,
  onConfirm,
  onCancel,
  confirmLabel,
  position,
}) {
  const classes = employerPopoverStyles();
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  let timer = null;
  const id = open ? "simple-popper" : null;
  const handleEnter = event => {
    const anchorEl = event.currentTarget;
    timer = setTimeout(() => {
      setAnchorEl(anchorEl);
      setOpen(true);
    }, 0);
  };

  const handleLeave = () => {
    clearTimeout(timer);
    setAnchorEl(null);
    setOpen(false);
  };

  const RenderFooter = () => (
    <div className={classes.footer}>
      <Button
        className={classes.button}
        // icon={<Call />}
        label={confirmLabel ? confirmLabel : "Ok"}
        alignIcon="right"
        onClick={onConfirm}
        size="small"
        // disabled={!canSubmitNewEmployerForm}
      />
      <Button
        className={classes.cancelButton}
        // icon={<Call />}
        label="Cancel"
        alignIcon="right"
        size="small"
        isLoading
        color="error"
        onClick={onCancel}
      />
    </div>
  );

  return (
    <div
      style={{ width: width ? width : 48, float: "right" }}
      aria-describedby={id}
      onMouseEnter={handleEnter}
      onMouseLeave={handleLeave}>
      <span className={classes.label}>{children}</span>
      <Popper
        id={id}
        open={open}
        anchorEl={anchorEl}
        transition
        placement={position ? position : "top"}
        disablePortal={true}
        modifiers={{
          flip: {
            enabled: true,
          },
          preventOverflow: {
            enabled: true,
            boundariesElement: "scrollParent",
          },
          arrow: {
            enabled: true,
          },
        }}>
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <Paper className={classes.card}>
              <Typography className={classes.row} variant="subtitle2">
                {icon ? icon : <Call className={classes.icon} />}
                {title ? title : "Title prop not provided"}
              </Typography>

              <Typography className={classes.row} variant="body1">
                {copy ? copy : "copy prop not provided."}
              </Typography>
              {onConfirm && confirmLabel ? <RenderFooter /> : ""}
            </Paper>
          </Fade>
        )}
      </Popper>
    </div>
  );
}

export default EmployerPopover;
