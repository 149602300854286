import gql from "graphql-tag";

export default gql`
  query($id: Int!) {
     jobTemplateById(id: $id) {
      id
      templateName
      employerId
      managerId
      workTypeId
      peopleNeeded
      street
      city
      country
      region
      unit
      postalCode
      floor
      description
      isBreakPaid
      isActive
      breakMins
      latitude
      longitude
      locationInstruction
      gears
      skills
      employer {
        id
        firstName
        lastName
      }
      manager {
        id
        firstName
        lastName
      }
      workType {
        id
        label
      }
    }
  }
` 