/* eslint-disable react/prop-types */
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
// import Grid from "@mui/material/Grid";
// import Typography from "@mui/material/Typography";
import { billingLandingStyles } from "views/Finance/helpers";
import MainContent from "components/Containers/MainContent";
import { actions as FinanceActions } from "store/modules/finance";
import BillingWeeks from "./BillingWeeks";

function FinanceView(props) {
  // const { classes } = props;
  const dispatch = useDispatch();

  // const isApplyingAction = useSelector(FinanceSelectors.getIsApplyingAction);

  useEffect(() => {
    dispatch(FinanceActions.fetchEmployerBillingYearSummary());
  }, [dispatch]);

/*
  const renderHeader = () => (
    <Grid
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="baseline"
      className={classes.container}>
      <Grid
        container
        item
        xs={12}
        className={classes.card}
        style={{ padding: "0px !important", backgroundColor: "transparent" }}>
        <Grid container item xs={3} direction="column" alignItems="flex-start">
          <Typography
            style={{ color: "#aebecd", fontWeight: 500, fontSize: 20, fontFamily: "Roboto" }}>
            {new Date().getFullYear()}
          </Typography>
        </Grid>
        <Grid container item xs={9} direction="column" alignItems="flex-end" />
      </Grid>
    </Grid>
  );
*/

  return (
    <React.Fragment>
      <MainContent>
        {/*{renderHeader()}*/}
        <BillingWeeks />
      </MainContent>
    </React.Fragment>
  );
}
export default billingLandingStyles(FinanceView);
// export default FinanceView;
