import { gql } from "@apollo/client";

export default gql`
  query workerNotes($workerId: String!, $first: Int, $last: Int, $after: String, $before: String) {
    workerNotes(workerId: $workerId, first: $first, last: $last, after: $after, before: $before) {
      totalCount
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        node {
          id
          note
          noteType
          #   workerId
          createdAt
          createdAtUtc
          agent
        }
      }
    }
  }
`;
