import React from "react";
import { AdminPermissions } from "helpers/constants/permissions";
import Can from "components/Authorization/Can";
import PermissionTable from "./components/PermissionTable";

function AdminDetail() {
  return (
    <React.Fragment>
      <Can perform={AdminPermissions.read.assignpermissions.value}>
        {allowed => {
          if (!allowed) {
            return null;
          }
      return <PermissionTable />
        }}
        </Can>
    </React.Fragment>
  );
}

export default AdminDetail;
