/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */

import React, { useState } from "react";
import { useDispatch } from "react-redux";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Grid from "@mui/material/Grid";
import ListItemText from "@mui/material/ListItemText";
import Select from "@mui/material/Select";
import Typography from "@mui/material/Typography";
import { CloudDownloadRounded, GridOn } from "@mui/icons-material";
import { ThemeProvider, StyledEngineProvider } from "@mui/material/styles";
import { customButtonTheme, spreadsheetBuilderStyles, StyledMenuItem, weeks } from "views/Finance/helpers";
import { actions as FinanceActions } from "store/modules/finance";

const ButtonComponent = props => {
    const { onClick, loading, label, loadingLabel, uri } = props;
    const downloadLinkProps = uri ? { download: "CloudDownloadRounded", href: uri } : {};

    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={customButtonTheme}>
                <Button
                    variant={loading ? "outlined" : "contained"}
                    color="secondary"
                    {...downloadLinkProps}
                    style={{ height: 34 }}
                    onClick={onClick}
                    startIcon={
                        loading ? (
                            <CircularProgress size={18} color="secondary" />
                        ) : uri ? (
                            <CloudDownloadRounded />
                        ) : (
                            <GridOn />
                        )
                    }>
                    {!loading ? label : loadingLabel}
                </Button>
            </ThemeProvider>
        </StyledEngineProvider>
    );
};

const SpreadsheetBuilder = ({ week, year }) => {
    const dispatch = useDispatch();
    const [billingWeek, setWeek] = useState(week);
    const [sheetLoading, setSheetLoading] = React.useState(false);
    const [uri, setUri] = React.useState(null);
    const aTag = React.useRef();
    const handleWeeklyBillingSpreadsheetClick = () => {
        if (!sheetLoading) {
            setSheetLoading(true);
            dispatch(
                FinanceActions.createWeeklyBillingSpreadsheet(billingWeek, year)
            ).then(url => {
                setUri(url);
                setSheetLoading(false);
            });
        }
    };

    const handleChange = e => {
        setWeek(Number(e.target.value) || "");
        Number(e.target.value) !== billingWeek && setUri(null);
    };

    return (
        <Grid container item md={12} alignItems="stretch" style={{ paddingLeft: 15, paddingRight: 5 }}>
            <Grid container item md={12} alignItems="flex-start">
                <Typography
                    variant="h6"
                    component="h6"
                    style={{
                        paddingBottom: 0,
                        marginBottom: 0,
                        fontSize: 16,
                        fontWeight: 700,
                        width: "100%",
                    }}>
                    Generate Spreadsheet
                </Typography>
                <Typography component="div" style={{ fontSize: 13, paddingTop: 0, width: "100%" }}>
                    Generate invoices for the current billing week.
                </Typography>
            </Grid>
            <Grid container item md={12} style={{ paddingTop: 10, width: "100%" }} alignItems="flex-end">
                <Select
                    value={billingWeek || week}
                    onChange={handleChange}
                    style={{ marginBottom: 10, width: "100%" }}>
                    {weeks.reverse().map((week, i) => (
                        <StyledMenuItem key={i} value={week}>
                            <ListItemText primary={` Week ${week}`} />
                        </StyledMenuItem>
                    ))}
                </Select>
                <ButtonComponent
                    style={{ alignItems: "flex-end" }}
                    onClick={
                        uri ? aTag.current.click() : handleWeeklyBillingSpreadsheetClick
                    }
                    loading={sheetLoading}
                    label={uri ? `Download Spreadsheet` : `Build Spreadsheet`}
                    uri={uri ? uri : null}
                    loadingLabel="Working Please Wait"
                />
            </Grid>
            <a ref={aTag} href={uri} target="_blank" rel="noopener noreferrer" />
        </Grid>
    );
};

export default spreadsheetBuilderStyles(SpreadsheetBuilder);
