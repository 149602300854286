/* eslint-disable react/prop-types */
/* eslint-disable react/display-name */
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import Typography from "@mui/material/Typography";
import isNil from "lodash/isNil";
import Line from "components/Placeholders/Line";
import Rating from "components/Rating/Rating";
import { actions as JobDetailActions, selectors as JobDetailSelectors } from "store/modules/jobDetails";
import DisableCellOverlay from "../../components/Controls/DisableCellOverlay";
import Can from "components/Authorization/Can";
import { PositionPermissions } from "helpers/constants/permissions";

export default function useRating(isRatingAll, disabled) {
    const dispatch = useDispatch();
    const canAddWorker = useSelector(JobDetailSelectors.getCanAddWorker);
    const positionUpdates = useSelector(JobDetailSelectors.getPositionUpdates);

    const updatePositionRating = JobDetailActions.updatePositionData("rating");
    return {
        Header: "Rating",
        id: "rating",
        width: !isRatingAll ? 100 : undefined,
        sortable: !isRatingAll,
        Cell: ({ row: { original } }) => {
            if (isRatingAll) {
                const { rating } = positionUpdates.find(({ id }) => id === original.id);

                const onChange = rating => {
                    dispatch(updatePositionRating(original.id, rating));
                };

                return <Rating value={rating} onChange={onChange} size={40} />;
            }

            return (
                <>
                    {isNil(original.rating) ? (
                        <Line />
                    ) : (
                        <Typography variant="body1">{original.rating}</Typography>
                    )}
                    <DisableCellOverlay visible={disabled} />
                </>
            );
        },
        Footer: (<Can perform={PositionPermissions.create.fill.value}>
            {allowed => {
                if (!allowed) {
                    return null;
                }
                return canAddWorker && !disabled ? <Line /> : null
            }}
        </Can>)
    };
}
