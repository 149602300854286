import { gql } from "@apollo/client";

export default gql`
  query positionByPrivateJobRecurringId($privateJobRecurringId: String!){
    positionByPrivateJobRecurringId(privateJobRecurringId: $privateJobRecurringId){
      id
      fullName
      firstName
      lastName
      email
      rating
      city
      region
      sin
      signedProfileImageUrl
      vaccinationStatus
      tags
    }
  }
`;
