import React, { useState } from "react";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MoreVert from "@mui/icons-material/MoreVert";
import { loadCSS } from "fg-loadcss";

function QbDialog({ invoices, employer }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const invoice = invoices[0] || {};
  React.useEffect(() => {
    const node = loadCSS(
      "https://use.fontawesome.com/releases/v5.12.0/css/all.css",
      document.querySelector("#font-awesome-css")
    );
    return () => {
      node.parentNode.removeChild(node);
    };
  }, []);

  const menuId = `action-menu-i`;
  const openMenu = e => setAnchorEl(e.currentTarget);
  const closeMenu = () => setAnchorEl(null);

  return <>
    <IconButton
      aria-controls={menuId}
      aria-haspopup="true"
      onClick={openMenu}
      disabled={!invoice}
      disableRipple
      style={{
        float: "right",
        marginRight: -20,
        visibility: "hidden",
      }}
      size="large">
      <MoreVert color="primary" />
    </IconButton>
    {invoice ? (
      <Menu
        anchorEl={anchorEl}
        keepMounted
        disableAutoFocusItem
        open={Boolean(anchorEl)}
        onClose={closeMenu}>
        {/* <QbStats invoice={invoice} handleClose={closeMenu} employer={employer} /> */}
      </Menu>
    ) : (
      ""
    )}
  </>;
}

export default QbDialog;
