
import React, { useState } from "react";
import _debounce from "lodash/debounce";
import { actions as WorkerActions, selectors as WorkerSelectors } from "store/modules/workers";
import moment from "moment";
import { convertUTCToTimezoneWithOffset, displayLocalDateTime, formattedTimeWithOffset } from "utils/time";
import { useSelector } from "react-redux";
import makeStyles from '@mui/styles/makeStyles';
import ReactTableSimple from "components/ReactTable/ReactTableSimple";

const tableProps = {
    manual: false,
    minRows: 0,
    resizable: false,
    showPageSizeOptions: true,
    showPageJump: true,
    showPaginationTop: false,
    showPaginationBottom: true,
    breakLengthFilter: "all", // pageSize: 20, // page: 0,
    defaultPageSize: 5,
    filterable: false,
    disableSortBy: true,
    // defaultFilterMethod: (filter, row) => String(row[filter.id]) === filter.value,
};
const useStyles = makeStyles(() => ({
    textLeft: {
        textAlign: 'left'
    }
}));

const FutureJobTable = (props) => {
    const classes = useStyles();
    const data = useSelector(WorkerSelectors.getWorkerFutureJobData)
    const loading = useSelector(WorkerSelectors.getFutureJobLoading)
    return (
        <ReactTableSimple
            classes="-highlight"
            data={data}
            {...tableProps}
            loading={loading}
            columns={[
                {
                    Header: "Job Id",
                    id: "jobId",
                    accessor: ({ id }) => id,
                    className: classes.textLeft,
                    disableSortBy: true,
                    disableFilters: true,
                },
                {
                    Header: "Company",
                    id: "companyName",
                    width: 180,
                    accessor: ({ employer }) => (employer.companyName ? employer.companyName : <i>Empty</i>),
                    className: classes.textLeft,
                    disableSortBy: true,
                    disableFilters: true,
                },
                {
                    Header: "Start Date",
                    id: "startDate",
                    accessor: ({ startUtc }) => moment(startUtc).format("MMM Do, YYYY"),
                    Cell: ({ row: { original } }) => {
                        return (
                            <div>
                                {convertUTCToTimezoneWithOffset(original.startUtc, original.timezone, "MMM Do, YYYY")}
                                <br />
                                {displayLocalDateTime(original.startUtc, original.timezone, "MMM Do, YYYY")}
                            </div>
                        );
                    },
                    disableSortBy: true,
                    disableFilters: true,
                    width: 180,
                    className: classes.textLeft,
                },
                {
                    Header: "Shift",
                    id: "startUtc",
                    width: 200,
                    Cell: ({ row: { original } }) => {
                        return formattedTimeWithOffset(original.startUtc, original.endUtc, original.timezone);
                    },
                    accessor: ({ startUtc, endUtc }) => {
                        const startTime = moment(startUtc).format("h:mm a");
                        const endTime = moment(endUtc).format("h:mm a");
                        return `${startTime} - ${endTime}`;
                    },
                    disableSortBy: true,
                    disableFilters: true,
                    maxWidth: 200,
                    className: classes.textLeft,
                },
                {
                    Header: "Location",
                    id: "city",
                    accessor: ({ city, region }) => `${city}, ${region}`,
                    maxWidth: 200,
                    disableSortBy: true,
                    disableFilters: true,
                    className: classes.textLeft,
                },
            ]}
        />
    );
}

export default FutureJobTable;
