import ApolloClient from "utils/apollo";
import { actions as MetaActions } from "store/modules/meta";
import { checkSession } from "../login/actions";
import {
  updateProfileMutation,
} from "./graphql/mutations";
import { adminsByIdQuery } from "./graphql/queries";
import types from "./types";

const setLoadingState = key => value => ({
  type: types.SET_LOADING_STATE,
  payload: { key, value },
});

const setIsSaveProfileLoading = setLoadingState("profileSave");
/*
Admin list
*/
const setProfileData = data => ({ type: types.SET_PROFILE_DATA, payload: { data } });

export const updateProfileData = data => (dispatch) => {
  dispatch(setIsSaveProfileLoading(true));
  return ApolloClient.mutate({
    mutation: updateProfileMutation,
    variables: {
      data: {
        firstName: data.firstName,
        lastName: data.lastName,
        title: data.title,
        ...(data.profileImage ? { profileImage: data.profileImage } : {}),
      },
    },
  })
    .then(({ data, errors }) => {
      if (!data && errors.length > 0) {
        throw Error(errors[0].message);
      } else {
        dispatch(MetaActions.successToast("Update profile successfully."));
        dispatch(checkSession());
        dispatch(setIsSaveProfileLoading(false));
      }
    })
    .catch(e => {
      dispatch(setIsSaveProfileLoading(false));
      dispatch(MetaActions.errorToast(e.message));
    });
};

export const fetchAdminById = id => dispatch => {
  return ApolloClient.query({
    query: adminsByIdQuery,
    variables: {
      id,
    },
  })
    .then(({ data, errors }) => {
      if (!data && errors.length > 0) {
        throw Error(errors[0].message);
      } else {
        const { adminById } = data;
        dispatch(setProfileData(adminById));
      }
    })
    .catch(e => {
      dispatch(MetaActions.errorToast(e.message));
    });
};