import React from "react";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import withStyles from '@mui/styles/withStyles';
import Typography from "@mui/material/Typography";
import { Alarm, InfoOutlined, PendingActions, VisibilityOff } from "@mui/icons-material";
import {
    JobStatusesArray,
    JobStatusesLabel,
    JobStatusesMap,
    TimeframeArray,
    TimeframeLabel,
    TimeframeMap,
} from "helpers/constants/index";

const styles = ({ spacing }) => ({
  container: {
    "& p": {
      marginRight: spacing(2),
    },
    "& svg": {
      marginRight: spacing(1),
    },
  },
});

function StateInfo({ classes, jobId, status, timeframe, isActive,isQueued }) {
  return (
    <Grid container item xs={6} alignItems="center" className={classes.container}>
      <Typography variant="body1" color="textSecondary">
        {`#${jobId}`}
      </Typography>
      <Typography variant="body1" color="textSecondary">
        &#8226;
      </Typography>
      <React.Fragment>
        <Typography variant="body1" color="textSecondary">
          {TimeframeLabel[timeframe]}
        </Typography>
      </React.Fragment>
      {!isActive && !isQueued && (
        <React.Fragment>
          <Typography variant="body1" color="textSecondary">
            &#8226;
          </Typography>
          <VisibilityOff color="primary" />
          <Typography variant="body1" color="textSecondary">
            Draft
          </Typography>
        </React.Fragment>
      )}
      {!isActive && isQueued && (
        <React.Fragment>
          <Typography variant="body1" color="textSecondary">
            &#8226;
          </Typography>
          <PendingActions color="primary" />
          <Typography variant="body1" color="textSecondary">
            Queued
          </Typography>
        </React.Fragment>
      )}
      {timeframe === TimeframeMap.Today && isActive ? (
        <React.Fragment>
          <Typography variant="body1" color="textSecondary">
            &#8226;
          </Typography>
          {status === JobStatusesMap.NotStarted ? (
            <InfoOutlined color="primary" />
          ) : (
            <Alarm color="primary" />
          )}
          <Typography variant="body1" color="textSecondary">
            {JobStatusesLabel[status]}
          </Typography>
        </React.Fragment>
      ) : null}
    </Grid>
  );
}

StateInfo.propTypes = {
  classes: PropTypes.object.isRequired,
  jobId: PropTypes.number.isRequired,
  status: PropTypes.oneOf(JobStatusesArray).isRequired,
  timeframe: PropTypes.oneOf(TimeframeArray).isRequired,
  isActive: PropTypes.bool.isRequired,
};

export default withStyles(styles)(StateInfo);
