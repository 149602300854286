export default {
  IS_LOGGING_IN: "login/IS_LOGGING_IN",
  DONE_LOGGING_IN: "login/DONE_LOGGING_IN",
  SIGNIN_ERROR_EMAIL: "login/SIGNIN_ERROR_EMAIL",
  SIGNIN_ERROR_PASSWORD: "login/SIGNIN_ERROR_PASSWORD",
  SET_CREDENTIALS: "login/SET_CREDENTIALS",
  SET_ME: "login/SET_ME",
  CLEAR_CREDENTIALS: "login/CLEAR_CREDENTIALS",
  SET_PROFILE: "login/SET_PROFILE",
  SET_LOGIN_EMAIL: "login/SET_LOGIN_EMAIL",
  SET_PASSWORD: "login/SET_PASSWORD",
  RESET: "login/RESET",
  FORGET_USER: "login/FORGET_USER",
  IS_CHECKING_SESSION: "login/IS_CHECKING_SESSION",
  SET_TIMEZONE_LIST: "login/SET_TIMEZONE_LIST",
};
