/*eslint-disable*/
import React from "react";
import MUIDialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Typography from "@mui/material/Typography";
import Button from "components/Button/Button";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import { AccountBalanceWalletTwoTone } from "@mui/icons-material";
import { cancelTransferDialogStyles } from "views/Finance/helpers";
import { useDispatch, useSelector } from "react-redux";
import { actions as FinanceActions, selectors as FinanceSelectors } from "../../../../../store/modules/finance";
const isMobile = false;
function CancelTransferDialog(props) {
  const {
    classes,
    cancelText,
    disabled,
    confirmText,
    title,
    description,
    icon,
    onConfirm,
    onCancel,
    onClose,
    open,
    iconFontSize,
    maxWidth,
    pmt,
    transferData,
    isCancelLoading
  } = props;
    const dispatch = useDispatch();
    const cancelReason = useSelector(FinanceSelectors.getCancelTransferReason);

  const FooterActionsControl = () => (
    <DialogActions className={classes.footer}>
      <React.Fragment>
        <Button
            label={cancelText || "Close"}
            onClick={(e) => {
                onCancel();
            }}
            color="transparent"
        />
        <Button
          disabled={disabled}
          label={confirmText || "Update"}
          isLoading={isCancelLoading}
          onClick={() => {
              onConfirm(cancelReason)
          }}
          color="secondary"
        />
      </React.Fragment>
    </DialogActions>
  );

  const RenderContent = () => (
    <Grid container direction="row" alignItems="center">
      <Grid item xs={12}>
        <Typography align="center" variant="body1"></Typography>
      </Grid>
      <Grid item xs={12} md={12} className={classes.leftColumn}>
        <TextField
          label="Recipient"
          name="name"
          fullWidth
          defaultValue={
              (transferData && transferData.worker && typeof transferData.worker === 'object')
              ? transferData.worker.firstName + ' ' + transferData.worker.lastName
              : transferData && transferData.worker
              ? transferData.worker
              : ""
          }
          disabled={true}
        />
      </Grid>

      <Grid container item xs={12} md={12} justifyContent="center" className={classes.inputContainer}>
        <TextField
          label="Reason"
          autoFocus
          fullWidth
          defaultValue={cancelReason}
          helperText={`This will be included in the email notification.`}
          multiline
          rows={4}
          onChange={e => {
              dispatch(FinanceActions.setCancelTransferReason(e.target.value));
          }}
          onFocus={(e) => {
              let val = e.target.value;
              e.target.value = '';
              e.target.value = val;
          }}
        />
      </Grid>
    </Grid>
  );

  return (
    <MUIDialog
      className={classes.core}
      fullScreen={isMobile}
      open={open}
      disablePortal={false}
      style={{ top: "-200px" }}
      maxWidth={maxWidth ? maxWidth : "md"}
      disableBackdropClick
      onClose={onClose}>
      <DialogTitle className={classes.title} style={{ justifyContent: "center" }}>
        {icon ? (
          icon
        ) : (
          <AccountBalanceWalletTwoTone
            color="disabled"
            style={{ fontSize: iconFontSize ? iconFontSize : 96 }}
          />
        )}
        <Typography variant="h5" align="center">
          {title ? title : `Cancel Pending Transfer?`}
        </Typography>
      </DialogTitle>
      <DialogContent className={classes.content}>
        <Typography variant="subtitle1" align="center">
          {description ? description : `Resend this cancelled transfer to the worker?`}
        </Typography>
        <RenderContent />
      </DialogContent>
      <FooterActionsControl />
    </MUIDialog>
  );
}

export default cancelTransferDialogStyles(CancelTransferDialog);
