import { gql } from "@apollo/client";

export default gql`
  query workerFutureJobs(
    $workerId: String!
  ) {
    workerFutureJobs(
      workerId: $workerId
    ) {
      id
      startUtc
      endUtc
      timezone
      city
      region
      employer{
        email
        firstName
        lastName
        companyName
      }
    }
  }
`;
