import moment from "moment";
import { v4 as uuidv4 } from "uuid";
import { createReducer } from "utils/redux";
import { calcIsNextDayEnd, convertUTCToTimezone, getTimeZone } from "utils/time";
import types from "./types";
import { find } from "lodash";
import { weekDaysList } from "utils/constant";
// moment.tz.setDefault(getTimeZone());

const startTimeDefault = moment().add(1, "hours").minutes(0);
startTimeDefault.set({ seconds: 0 });
const endTimeDefault = moment().add(9, "hours").minutes(30);
endTimeDefault.set({ seconds: 0 });
const breakTypeList = [
    {
        value: 0,
        label: "Not Paid",
        message: "Worker(s) required to take a break.",
    },
    {
        value: 1,
        label: "Paid",
        message: "Worker(s) may be required to work through break.",
    },
];
const initialState = {
    isLoading: true,
    selectedEmployer: null,
    employer: null,
    date: moment(),
    startTime: startTimeDefault,
    endTime: endTimeDefault,
    requiresBreak: true,
    breakType: breakTypeList[0].value,
    breakMins: 30,
    isPast: false,
    isLessThanFour: false,
    isGreaterThanTen: false,
    isNextDayEnd: calcIsNextDayEnd(startTimeDefault, endTimeDefault),
    unit: "",
    floor: "",
    timezone: getTimeZone(),
    locationInstruction: "",
    workersNeeded: 1,
    jobType: 0,
    location: null,
    selectedLocation: null,
    description: "",
    isPrivate: false,
    isRecurring: false,
    recurringType: 'Daily',
    weekDays: [],
    repeatEvery: 1,
    repeatType: 'day',
    monthDate: [],
    recurringEndDate: moment().add(1, 'months'),
    tags: [],
    jobTypeList: [],
    gears: [],
    skills: [],
    managers: [],
    siteManager: {},
    checkInManager: {},
    checkOutManager: {},
    duration: {
        hours: 8,
        minutes: 30,
    },
    breakTypeList,
    isEditing: false,
    editType: null,
    isValidDate: true,
    isValidRecurringEndDate: true,
    isValidStartTime: true,
    isValidEndTime: true,
    positions: [],
    announcement: [],
};

const actionsMap = {
    [types.SET_IS_LOADING]: (state, { payload }) => {
        return {
            ...state,
            isLoading: payload.isLoading,
        };
    },
    [types.SET_EDIT]: (state, { payload }) => {

        const { job, editType } = payload;
        const { jobManager } = job;
        const today = moment();
        const tomorrow = moment().add(1, "day");

        let startTime = convertUTCToTimezone(job.startUtc, job.timezone);
        let endTime = convertUTCToTimezone(job.endUtc, job.timezone);

        const duration = endTime.diff(startTime, "minutes");

        const requiresBreak = duration > 60 * 5; // Greater than 5 hours *require break
        const isLessThanFour = duration < 60 * 4; // Duration is less than 4 *warning
        const isGreaterThanTen = duration > 60 * 10; // Duration is greater than 10 *warning

        const SiteManager = find(jobManager, n => n.role === "SiteManager")
        const CheckInManager = find(jobManager, n => n.role === "CheckInManager")
        const CheckOutManager = find(jobManager, n => n.role === "CheckOutManager")

        const durationObj = {
            hours: Math.floor(duration / 60),
            minutes: duration % 60,
        };

        if (editType === "duplicate") {
            startTime = moment({
                year: tomorrow.year(),
                month: tomorrow.month(),
                date: tomorrow.date(),
                hour: startTime.hour(),
                minute: startTime.minute(),
            });

            endTime = startTime.clone().add({
                hours: durationObj.hours,
                minutes: durationObj.minutes,
            });
        }

        const isNextDayEnd = calcIsNextDayEnd(startTime, endTime);
        const isPast = startTime.diff(today, "minutes") < 0;

        let location = null;
        let selectedLocation = null;
        if (job.street && job.city && job.region && job.latitude && job.longitude) {
            selectedLocation = {
                value: uuidv4(),
                label: `${job.street}, ${job.city}, ${job.region}, Canada`,
            };

            location = {
                street: job.street,
                city: job.city,
                region: job.region,
                country: "Canada",
                lat: job.latitude,
                lng: job.longitude,
            };
        }

        let recurringData = {};
        if (job.jobRecurring && job.isRecurring) {
            recurringData = {
                recurringType: job.jobRecurring.frequency,
                repeatEvery: job.jobRecurring.frequencyInterval,
                recurringEndDate: moment(job.jobRecurring.frequencyEndAt),
                weekDays: weekDaysList.filter(a => (job.jobRecurring.dayOrWeekDay?.includes?.(a.value))),
                monthDate: job.jobRecurring.dayOrWeekDay,
            }
        }

        return {
            ...initialState,
            ...recurringData,
            isRecurring: job.isRecurring,
            jobTypeList: state.jobTypeList,
            isEditing: true,
            editType,
            employer: {
                ...job.employer,
            },
            selectedEmployer: {
                label: job.employer.companyName,
                value: job.employer.id,
            },
            selectedLocation,
            location,
            breakType: job.breakMins === 0 ? 1 : 0,
            breakMins: job.breakMins === 0 ? job.paidBreakMins : job.breakMins,
            description: job.description || "",
            workersNeeded: job.peopleNeeded,
            jobType: job.workTypeId,
            date: startTime.clone(),
            startTime,
            endTime,
            isGreaterThanTen,
            isLessThanFour,
            isNextDayEnd,
            isPast,
            requiresBreak,
            duration: durationObj,
            isValidDate: true,
            isValidRecurringEndDate: true,
            isValidStartTime: true,
            isValidEndTime: true,
            unit: job.unit,
            floor: job.floor,
            locationInstruction: job.locationInstruction,
            managers: job.managers || [],
            announcement: (job.jobAnnouncement || []).reverse().map(a => {
                const time = moment({
                    year: startTime.year(),
                    month: startTime.month(),
                    date: startTime.date(),
                    hour: convertUTCToTimezone(moment(a.jobAnnouncementTime?.[0]?.sendAt)).hour(),
                    minute: convertUTCToTimezone(moment(a.jobAnnouncementTime?.[0]?.sendAt)).minute(),
                });
                return ({
                    title: a.title,
                    description: a.description,
                    isRecurring: "false",
                    time: convertUTCToTimezone(time, job.timezone),
                    repeatType: a.frequency,
                    before: a.minutes,
                    days: weekDaysList.filter(w => (a.dayOrWeekDay?.includes?.(w.value))),
                })
            }),
            siteManager: SiteManager !== undefined ? SiteManager.manager : {},
            checkInManager: CheckInManager !== undefined ? CheckInManager.manager : {},
            checkOutManager: CheckOutManager !== undefined ? CheckOutManager.manager : {},
            isPrivate: job.isPrivate || false,
            gears: job.gears || [],
            skills: job.skills || [],
            tags: job.tags || [],
            positions: job.positions || [],
            canPostPrivateJob: job.employer ? job.employer.canPostPrivateJob : false,
        };
    },
    [types.RESET]: () => {
        return {
            ...initialState,
            monthDate: [],
            weekDays: [],
            gears: [],
            announcement: []
        }
    },
    [types.SET_EMPLOYER]: (state, { payload }) => {
        const { employer, isEditing } = payload;

        let selectedEmployer = null;
        let selectedLocation = null;
        let location = null;
        if (employer) {
            selectedEmployer = {
                label: employer.companyName,
                value: employer.id,
            };

            if (
                employer.street &&
                employer.city &&
                employer.region &&
                employer.latitude &&
                employer.longitude
            ) {
                selectedLocation = {
                    value: uuidv4(),
                    label: `${employer.street}, ${employer.city}, ${employer.region}, Canada`,
                };

                location = {
                    street: employer.street,
                    city: employer.city,
                    region: employer.region,
                    country: "Canada",
                    lat: employer.latitude,
                    lng: employer.longitude,
                };
            }
        }

        return {
            ...state,
            isEditing,
            employer,
            location,
            selectedLocation,
            selectedEmployer,
            canPostPrivateJob: employer ? employer.canPostPrivateJob : false,
        };
    },
    [types.SET_BREAK_TYPE]: (state, { payload }) => {
        const { breakType } = payload;
        return {
            ...state,
            breakType,
        };
    },
    [types.SET_BREAK_MINS]: (state, { payload }) => {
        const { breakMins } = payload;
        return {
            ...state,
            breakMins,
        };
    },
    [types.SET_JOB_IS_RECURRING]: (state, { payload }) => {
        const { isRecurring } = payload;
        return {
            ...state,
            isRecurring,
        };
    },
    [types.SET_UNIT]: (state, { payload }) => {
        const { unit } = payload;
        return {
            ...state,
            unit,
        };
    },
    [types.SET_FLOOR]: (state, { payload }) => {
        const { floor } = payload;
        return {
            ...state,
            floor,
        };
    },
    [types.SET_LOCATION_INSTRUCTION]: (state, { payload }) => {
        const { locationInstruction } = payload;
        return {
            ...state,
            locationInstruction,
        };
    },
    [types.SET_SKILLS]: (state, { payload }) => {
        const { skills } = payload;
        return {
            ...state,
            skills,
        };
    },
    [types.SET_MANAGER_LIST]: (state, { payload }) => {
        const { managers } = payload;
        return {
            ...state,
            managers,
        };
    },
    [types.SET_JOB_ANNOUNCEMENT]: (state, { payload }) => {
        const { announcement } = payload;
        return {
            ...state,
            announcement,
        };
    },
    [types.SET_JOB_RECURRING_TYPE]: (state, { payload }) => {
        const { recurringType } = payload;
        return {
            ...state,
            recurringType,
        };
    },
    [types.SET_JOB_RECURRING_REPEAT]: (state, { payload }) => {
        const { repeatEvery } = payload;
        return {
            ...state,
            repeatEvery,
        };
    },
    [types.SET_JOB_RECURRING_END_DATE]: (state, { payload }) => {
        const { recurringEndDate } = payload;
        return {
            ...state,
            recurringEndDate,
            isValidRecurringEndDate: moment() < moment(recurringEndDate)
        };
    },
    [types.SET_JOB_RECURRING_WEEK_DAYS]: (state, { payload }) => {
        const { weekDays } = payload;
        return {
            ...state,
            weekDays,
        };
    },
    [types.SET_JOB_RECURRING_MONTH_DATE]: (state, { payload }) => {
        const { monthDate } = payload;
        return {
            ...state,
            monthDate,
        };
    },
    [types.SET_SITE_MANAGER]: (state, { payload }) => {
        const { siteManager } = payload;
        return {
            ...state,
            siteManager,
        };
    },
    [types.SET_CHECK_IN_MANAGER]: (state, { payload }) => {
        const { checkInManager } = payload;
        return {
            ...state,
            checkInManager,
        };
    },
    [types.SET_CHECK_OUT_MANAGER]: (state, { payload }) => {
        const { checkOutManager } = payload;
        return {
            ...state,
            checkOutManager,
        };
    },
    [types.SET_JOB_TYPE_LIST]: (state, { payload }) => {
        const { jobTypeList } = payload;

        return {
            ...state,
            jobTypeList,
            jobType: jobTypeList[0].value,
        };
    },
    [types.SET_DATE]: (state, { payload }) => {
        const { date } = payload;
        // Input was edited directly so it couldnt return a moment object
        if (!moment.isMoment(date)) {
            return {
                ...state,
                isValidDate: false,
            }; // Force state update so the UI can reflect the error
        }

        const start = moment({
            year: date.year(),
            month: date.month(),
            date: date.date(),
            hour: state.startTime.hour(),
            minute: state.startTime.minute(),
        });

        const end = start.clone().add({
            hours: state.duration.hours,
            minutes: state.duration.minutes,
        });

        const isNextDayEnd = calcIsNextDayEnd(start, end);
        const duration = end.diff(start, "minutes");
        // const isPast = start.diff(moment(), "minutes") < 0;

        const requiresBreak = duration > 60 * 5; // Greater than 5 hours *require break
        const isLessThanFour = duration < 60 * 4; // Duration is less than 4 *warning
        const isGreaterThanTen = duration > 60 * 10; // Duration is greater than 10 *warning
        return {
            ...state,
            date,
            isNextDayEnd,
            // isPast,
            requiresBreak,
            isLessThanFour,
            isGreaterThanTen,
            startTime: start,
            endTime: end,
            duration: {
                hours: Math.floor(duration / 60),
                minutes: duration % 60,
            },
            isValidDate: true,
            isValidStartTime: true,
            isValidEndTime: true,
        };
    },
    [types.SET_START_TIME]: (state, { payload }) => {
        const { time } = payload;

        // Input was edited directly so it couldnt return a moment object
        if (!moment.isMoment(time)) {
            return {
                ...state,
                isValidStartTime: false,
            }; // Force state update so the UI can reflect the error
        }

        const start = moment({
            year: state.date.year(),
            month: state.date.month(),
            date: state.date.date(),
            hour: time.hour(),
            minute: time.minute(),
        });
        const end = start.clone().add(8.5, "hours");
        // const isPast = start.diff(moment(), "minutes") < 0;
        const isNextDayEnd = calcIsNextDayEnd(start, end);

        return {
            ...state,
            // isPast,
            isNextDayEnd,
            requiresBreak: true,
            isLessThanFour: false,
            isGreaterThanTen: false,
            startTime: time,
            endTime: end,
            duration: {
                hours: 8,
                minutes: 30,
            },
            isValidStartTime: true,
            isValidEndTime: true,
        };
    },

    [types.SET_END_TIME]: (state, { payload }) => {
        const { time } = payload;

        // Input was edited directly so it couldnt return a moment object
        if (!moment.isMoment(time)) {
            return {
                ...state,
                isValidEndTime: false,
            }; // Force state update so the UI can reflect the error
        }

        const start = moment({
            year: state.date.year(),
            month: state.date.month(),
            date: state.date.date(),
            hour: state.startTime.hour(),
            minute: state.startTime.minute(),
        });

        const end = moment({
            year: state.date.year(),
            month: state.date.month(),
            date: state.date.date(),
            hour: time.hour(),
            minute: time.minute(),
        });

        const isNextDayEnd = calcIsNextDayEnd(start, end);
        const duration = isNextDayEnd
            ? end.add(1, "day").diff(start, "minutes")
            : end.diff(start, "minutes");

        const requiresBreak = duration > 60 * 5; // Greater than 5 hours *require break
        const isLessThanFour = duration < 60 * 4; // Duration is less than 4 *warning
        const isGreaterThanTen = duration > 60 * 10; // Duration is greater than 10 *warning
        return {
            ...state,
            isNextDayEnd,
            requiresBreak,
            isLessThanFour,
            isGreaterThanTen,
            endTime: time,
            duration: {
                hours: Math.floor(duration / 60),
                minutes: duration % 60,
            },
            isValidEndTime: true,
        };
    },
    [types.SET_WORKERS_NEEDED]: (state, { payload }) => {
        const { workersNeeded } = payload;
        return {
            ...state,
            workersNeeded: parseInt(workersNeeded, 10),
        };
    },
    [types.SET_JOB_TYPE]: (state, { payload }) => {
        const { jobType } = payload;
        return {
            ...state,
            jobType,
        };
    },
    [types.SET_LOCATION]: (state, { payload }) => {
        const { selectedLocation, location } = payload;
        return {
            ...state,
            location: location,
            selectedLocation,
        };
    },
    [types.SET_TIMEZONE]: (state, { payload }) => {
        const { timezone } = payload;
        return {
            ...state,
            timezone,
        };
    },
    [types.SET_DESCRIPTION]: (state, { payload }) => {
        const { description } = payload;
        return {
            ...state,
            description,
        };
    },
    [types.SET_JOB_IS_PRIVATE]: (state, { payload }) => {
        const { isPrivate } = payload;
        return {
            ...state,
            isPrivate,
        };
    },
    [types.SET_GEARS]: (state, { payload }) => {
        const { gears } = payload;
        return {
            ...state,
            gears,
        };
    },
    [types.SET_JOB_TAGS]: (state, { payload }) => {
        const { tags } = payload;
        return {
            ...state,
            tags,
        };
    },
};

export default createReducer(initialState, actionsMap);
