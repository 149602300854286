import React, { useState } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import { BanReasonsLabel } from "helpers/constants/index";
import Dialog from "components/Dialog/Dialog";
import { selectors as JobDetailSelectors } from "store/modules/jobDetails";

function BanDialog(props) {
  const { open, onClose, onCancel, onConfirm, position } = props;
  const [banReason, setBanReason] = useState("NA");

  const companyName = useSelector(JobDetailSelectors.getCompanyName);

  const onChange = event => {
    setBanReason(event.target.value);
  };

  const handleBan = () => {
    onConfirm(banReason);
    setBanReason("NA");
  };

  return (
    <Dialog
      open={open}
      title={`Ban ${position.fullName}?`}
      description={`You are about to ban ${position.fullName} from ${companyName}.`}
      onClose={onClose}
      onConfirm={handleBan}
      onCancel={onCancel}
      alertType="warning"
      icon="not_interested"
      disabled={banReason === "NA"}>
      <TextField select fullWidth value={banReason} onChange={onChange} margin="normal">
        <MenuItem value="NA">Select a reason...</MenuItem>
        {Object.keys(BanReasonsLabel).map(key => (
          <MenuItem key={key} value={BanReasonsLabel[key]}>
            {BanReasonsLabel[key]}
          </MenuItem>
        ))}
      </TextField>
    </Dialog>
  );
}

BanDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  position: PropTypes.object,
};

export default BanDialog;
