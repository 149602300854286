import { PaymentConstants } from "helpers/constants/index";
import Decimal from "decimal.js";

export function nearestFiveCents(number) {
  return new Decimal(number || 0)
    .toDecimalPlaces(2)
    .toNearest(0.05, Decimal.ROUND_UP)
    .toNumber();
}

export function calculateMinimumTargetPrice(value) {
  const target = new Decimal(value || 0).dividedBy(1 - PaymentConstants.MIN_MARGIN_PERCENTAGE);
  return nearestFiveCents(target);
}

export function calculateTargetPrice(value, margins, others = []) {
  const { percentage, dollars, minDollars, minimumPercentage } = margins;

  const valueDecimal = new Decimal(value || 0);
  const percentBased = percentage ? valueDecimal.dividedBy(1 - percentage) : 0;
  const dollarBased = dollars ? valueDecimal.plus(dollars) : 0;
  const minimumDollarBased = valueDecimal.plus(minDollars || PaymentConstants.MIN_MARGIN_DOLLARS);
  const minimumPercentBased = valueDecimal.dividedBy(
    1 - (minimumPercentage || PaymentConstants.MIN_MARGIN_PERCENTAGE),
  );

  const max = Decimal.max(
    percentBased,
    dollarBased,
    minimumDollarBased,
    minimumPercentBased,
    ...others,
  );

  return nearestFiveCents(max);
}

export function calculateMargins(payRate = 0, billingRate = 0) {
  const percentage =
    payRate && billingRate
      ? new Decimal(billingRate - payRate).dividedBy(billingRate).toDecimalPlaces(4)
      : 0;
  return {
    percentageDecimal: percentage ? percentage.toNumber() : 0,
    percentage: percentage > 0 ? percentage.times(100).toNumber() : 0,
    dollars:
      payRate && billingRate
        ? new Decimal(billingRate)
            .minus(payRate)
            .toDecimalPlaces(2)
            .toNumber()
        : 0,
  };
}
