import { gql } from "@apollo/client";

export default gql`
  mutation addAdminSetting($data: AdminSettingInput!) {
    addAdminSetting(data: $data) {
      id
    }
  }
`;

export const updateAdminSettingMutation =  gql`
  mutation editAdminSetting($data: EditAdminSettingInput!) {
    editAdminSetting(data: $data) {
      id
    }
  }
`;