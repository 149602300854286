import * as pagination from "utils/graphql-pagination";
import { createReducer } from "utils/redux";
import { paginationSettings } from "./constants";
import * as moment from "moment";
import types from "./types";
const initialLoadingState = {
  dcReport: false,
  dcReportDownload: false,
};
const defaultStartDate = moment().subtract(1, "month").tz('UTC').set({ hour:0, minute:0, second:0, millisecond:0 })
const defaultEndDate = moment().tz('UTC').set({ hour:0, minute:0, second:0, millisecond:0 })
const initialState = {
  workerPayrollReportData: [],
  workerPayrollReportFilter: { startDate: defaultStartDate, endDate: defaultEndDate },
  workerWisePayrollReportData: [],
  workerWisePayrollReportFilter: { startDate: defaultStartDate, endDate: defaultEndDate },
  workerJobReportData: [],
  workerJobReportFilter: {},
  activeEmployerReportData: [],
  activeEmployerReportFilter: { isAll: true },
  WSBCReportData: [],
  WSBCReportFilter: { startDate: defaultStartDate, endDate: defaultEndDate },
  workerJobCountReportData: [],
  workerJobCountReportFilter: {
    startDate: defaultStartDate,
    endDate: defaultEndDate,
    like: [],
  },
  DCBankTransactionReportData: [],
  DCBankTransactionReportFilter: {
    MinDateOfFunds: defaultEndDate,
    MaxDateOfFunds: defaultEndDate,
    TransactionStatusCode: '',
    ClientReferenceNumber: '',
    CustomerNumber:'',
    TransactionId: '',
    worker: ''
  },
  loading: { ...initialLoadingState },
  ...pagination.initialState(paginationSettings),
};

const actionsMap = {
  [types.SET_LOADING_STATE]: (state, { payload }) => {
    const { key, value } = payload;
    return {
      ...state,
      loading: {
        ...state.loading,
        [key]: value,
      },
    };
  },
  [types.SET_WORKER_PAYROLL_DATA]: (state, { payload }) => {
    const { data } = payload;
    return {
      ...state,
      workerPayrollReportData: [...data],
    };
  },
  [types.SET_WORKER_PAYROLL_FILTER]: (state, { payload }) => {
    const { field, value } = payload;
    return {
      ...state,
      workerPayrollReportFilter: {
        ...state.workerPayrollReportFilter,
        [field]: value,
      },
    };
  },
  [types.SET_WORKER_WISE_PAYROLL_DATA]: (state, { payload }) => {
    const { data } = payload;
    return {
      ...state,
      workerWisePayrollReportData: [...data],
    };
  },
  [types.SET_WORKER_JOB_DATA]: (state, { payload }) => {
    const { data } = payload;
    return {
      ...state,
      workerJobReportData: [...data],
    };
  },
  [types.SET_WORKER_JOB_FILTER]: (state, { payload }) => {
    const { field, value } = payload;
    return {
      ...state,
      workerJobReportFilter: {
        ...state.workerJobReportFilter,
        [field]: value,
      },
    };
  },
  [types.SET_WORKER_WISE_PAYROLL_FILTER]: (state, { payload }) => {
    const { field, value } = payload;
    return {
      ...state,
      workerWisePayrollReportFilter: {
        ...state.workerWisePayrollReportFilter,
        [field]: value,
      },
    };
  },
  [types.SET_ACTIVE_EMPLOYER_DATA]: (state, { payload }) => {
    const { data } = payload;
    return {
      ...state,
      activeEmployerReportData: [...data],
    };
  },
  [types.SET_ACTIVE_EMPLOYER_FILTER]: (state, { payload }) => {
    const { field, value } = payload;
    return {
      ...state,
      activeEmployerReportFilter: {
        ...state.activeEmployerReportFilter,
        [field]: value,
      },
    };
  },
  [types.SET_WSBC_DATA]: (state, { payload }) => {
    const { data } = payload;
    return {
      ...state,
      WSBCReportData: [...data],
    };
  },
  [types.SET_WSBC_FILTER]: (state, { payload }) => {
    const { field, value } = payload;
    return {
      ...state,
      WSBCReportFilter: {
        ...state.WSBCReportFilter,
        [field]: value,
      },
    };
  },
  [types.SET_WORKER_JOB_COUNT_DATA]: (state, { payload }) => {
    const { data } = payload;
    return {
      ...state,
      workerJobCountReportData: [...data],
    };
  },
  [types.SET_WORKER_JOB_COUNT_FILTER]: (state, { payload }) => {
    const { field, value, like } = payload;
    return {
      ...state,
      workerJobCountReportFilter: {
        ...state.workerJobCountReportFilter,
        [field]: moment(value),
        ...(like ? { like: like.map(n => ({ field: n.id, value: n.value })) } : {}),
      },
    };
  },
  [types.SET_DC_BANK_TRANSACTION_DATA]: (state, { payload }) => {
    const { data } = payload;
    return {
      ...state,
      DCBankTransactionReportData: [...data],
    };
  },
  [types.SET_DC_BANK_TRANSACTION_FILTER]: (state, { payload }) => {
    const { field, value, like } = payload;
    return {
      ...state,
      DCBankTransactionReportFilter: {
        ...state.DCBankTransactionReportFilter,
        [field]: value,
      },
    };
  },
  ...pagination.createPaginationActions(paginationSettings),
};

export default createReducer(initialState, actionsMap);
