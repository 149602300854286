import React from "react";
import PropTypes from "prop-types";
import Fade from "@mui/material/Fade";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import withStyles from '@mui/styles/withStyles';
import Typography from "@mui/material/Typography";
import AlternateEmail from "@mui/icons-material/AlternateEmail";
import Call from "@mui/icons-material/Call";
import LocationOn from "@mui/icons-material/LocationOn";

import { formatPhone } from "utils/stringUtils";

const styles = ({ spacing, palette }) => ({
  card: {
    padding: spacing(1),
    marginLeft: spacing(1),
  },
  row: {
    display: "flex",
    alignItems: "center",
    padding: spacing(1),
  },
  icon: {
    marginRight: spacing(1),
    color: palette.grey[500],
  },
  label: {
    "&:hover": {
      textDecoration: "underline",
    },
  },
});

class SimplePopper extends React.Component {
  state = {
    anchorEl: null,
    open: false,
  };

  timer = null;

  handleCopy = data => {
    window.clipboardData.setData("text", data);
  };

  handleEnter = event => {
    const anchorEl = event.currentTarget;
    this.timer = setTimeout(() => {
      this.setState({
        anchorEl,
        open: true,
      });
    }, 0);
  };

  handleLeave = () => {
    clearTimeout(this.timer);
    this.setState({
      anchorEl: null,
      open: false,
    });
  };

  render() {
    const { classes, children, phone, email, city, region } = this.props;
    const { anchorEl, open } = this.state;
    const id = open ? "simple-popper" : null;

    return (
      <div
        style={{ alignSelf: "flex-start" }}
        aria-describedby={id}
        onMouseEnter={this.handleEnter}
        onMouseLeave={this.handleLeave}>
        <span className={classes.label}>{children}</span>
        <Popper id={id} open={open} anchorEl={anchorEl} transition placement="right">
          {({ TransitionProps }) => (
            <Fade {...TransitionProps} timeout={350}>
              <Paper className={classes.card}>
                <Typography className={classes.row} variant="body1">
                  <Call className={classes.icon} />
                  {formatPhone(phone)}
                </Typography>
                <Typography className={classes.row} variant="body1">
                  <AlternateEmail className={classes.icon} />
                  {email}
                </Typography>
                <Typography className={classes.row} variant="body1">
                  <LocationOn className={classes.icon} />
                  {`${city}, ${region}`}
                </Typography>
              </Paper>
            </Fade>
          )}
        </Popper>
      </div>
    );
  }
}

SimplePopper.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(SimplePopper);
