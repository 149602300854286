/* eslint-disable import-helpers/order-imports */
import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import { Delete } from "@mui/icons-material";
import { ordinalSuffixOf } from "utils/stringUtils";
import { weekDaysList } from "utils/constant";
import MenuItem from "@mui/material/MenuItem";
import { find, isEqual, remove } from "lodash";
import makeStyles from '@mui/styles/makeStyles';
import moment from "moment-timezone";
import FormRow from "./FormRow";
import { IconButton } from "@mui/material";
import TextField from "@mui/material/TextField";
import Datetime from "react-datetime";
import { useDispatch, useSelector } from "react-redux";
import { actions as PostingActions, selectors as PostingSelectors } from "store/modules/posting";
import { getTotalTime, getDaysOfWeekBetweenDates } from "utils/time";

const useStyles = makeStyles(() => ({
    announcementHeading: {
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: '10px',
        '& p': {
            margin: '0',
            lineHeight: '2.6',
            fontWeight: 'bold',
        }
    },
    weekDays: {
        display: 'flex',
        flexWrap: 'wrap'
    },
    days: {
        padding: '5px 0',
        border: '1px solid',
        borderRadius: '100px',
        width: '30px',
        height: '30px',
        textAlign: 'center',
        marginRight: '10px',
        fontWeight: '300',
        borderColor: '#b2b2c4',
        color: '#848499',
        cursor: 'pointer',
        marginBottom: '10px'
    },
    selectedDays: {
        borderColor: '#00c8a9 !important',
        color: '#00c8a9 !important',
        background: '#FFF',
        fontWeight: '400'
    },
    addAnother: {
        fontWeight: 'bold',
        cursor: 'pointer',
        width: 'fit-content'
    },
    disabledDay: {
        cursor: "not-allowed",
        pointerEvents: "none",
        touchAction: "none",
        color: "#84849985"
    },
}));

function AnnouncementSection(props) {

    const {
        date,
        announcement,
        isRecurring,
        recurringEndDate,
        startTime
    } = props;

    const dispatch = useDispatch();
    const classes = useStyles();
    const timezone = useSelector(PostingSelectors.getTimeZone);
    const [selectedWeekDays, setSelectedWeekDays] = useState([]);
    const [totalTimeInHours, setTotalTimeInHours] = useState(0);

    // useEffect(() => {
    //     if(announcement.length) dispatch(PostingActions.setAnnouncement(announcement.map((n) => {
    //         n.time = moment(startTime);
    //         return n
    //     })));
    // }, [startTime]);


    useEffect(() => {
        if (selectedWeekDays && selectedWeekDays.length) {
            const data = announcement.map(a => {
                if (a.days && a.days.length) return { ...a, days: a.days.filter(d => selectedWeekDays.includes(d.value)) }
                return a;
            })
            dispatch(PostingActions.setAnnouncement([...data]));
        }
    }, [selectedWeekDays]);

    useEffect(() => {
        // moment.tz.setDefault(timezone);
        setTotalTimeInHours(getTotalTime(isRecurring ? recurringEndDate : startTime, moment()))
        setSelectedWeekDays(getDaysOfWeekBetweenDates(moment(), isRecurring ? recurringEndDate : startTime))
    }, [isRecurring, startTime, recurringEndDate]);

    const handleChangeAnnouncement = (index, key, e) => {
        announcement[index][key] = e;
        dispatch(PostingActions.setAnnouncement([...announcement]));
    };

    const addAnotherAnnouncement = () => {
        announcement.push({
            title: "",
            description: "",
            isRecurring: "false",
            time: moment(startTime),
            repeatType: "Once",
            before: 15,
            days: [],
        });
        dispatch(PostingActions.setAnnouncement([...announcement]));
    };

    const deleteAnnouncement = index => {
        announcement.splice(index, 1);
        dispatch(PostingActions.setAnnouncement([...announcement]));
    };

    const handleChangeRepeatDays = (index, e) => {
        const weekDays = announcement[index].days;
        if (find(weekDays, n => isEqual(n, e))) {
            remove(weekDays, n => isEqual(n, e));
        } else {
            weekDays.push(e);
        }
        announcement[index].days = weekDays;
        dispatch(PostingActions.setAnnouncement([...announcement]));
    };

    return (
        <React.Fragment>
            <FormRow>
                {announcement &&
                    announcement.map((n, index) => {
                        return (
                            <div key={index}>
                                <div className={classes.announcementHeading}>
                                    <p>{ordinalSuffixOf(index + 1)} announcement</p>
                                    <IconButton
                                        className="closeButton"
                                        color="primary"
                                        onClick={() => deleteAnnouncement(index)}
                                        aria-label="delete"
                                        component="span"
                                    >
                                        <Delete className="delete" />
                                    </IconButton>
                                </div>
                                <Grid container spacing={2}>
                                    <Grid item md={12} sm={12} xs={12} style={{ marginTop: 16 }}>
                                        <TextField
                                            id="title"
                                            name="title"
                                            label="Announcement Title"
                                            placeholder="Announcement Title"
                                            value={n.title}
                                            onChange={e => handleChangeAnnouncement(index, "title", e.target.value)}
                                            fullWidth
                                            margin="none"
                                        />
                                    </Grid>
                                    <Grid item md={12} sm={12} xs={12} style={{ marginTop: 16 }}>
                                        <TextField
                                            id="description"
                                            name="description"
                                            label="Description"
                                            placeholder="Use this section to add additional information for announcement"
                                            value={n.description}
                                            onChange={e => handleChangeAnnouncement(index, "description", e.target.value)}
                                            fullWidth
                                            margin="none"
                                            multiline
                                            minRows={3}
                                        />
                                    </Grid>

                                    <Grid container item xs={12}>
                                        <Grid container item xs={6} direction="column" style={{ paddingRight: 4, zIndex: 100 }}>
                                            <TextField
                                                select
                                                style={{ marginTop: 16 }}
                                                label="Repeat"
                                                value={n.repeatType}
                                                onChange={e => handleChangeAnnouncement(index, "repeatType", e.target.value)}
                                                margin="none"
                                                fullWidth
                                            >
                                                <MenuItem value={"Once"}>Once</MenuItem>
                                                <MenuItem value={"Weekly"}>Weekly</MenuItem>
                                            </TextField>
                                        </Grid>

                                        <Grid container item xs={6} direction="column" style={{ paddingLeft: 4, zIndex: 100 }}>
                                            {n.repeatType === "Once" && (
                                                <TextField
                                                    select
                                                    value={n.before}
                                                    onChange={e => handleChangeAnnouncement(index, "before", e.target.value)}
                                                    style={{ marginTop: "15px" }}
                                                    fullWidth
                                                >
                                                    <MenuItem value={15}>15 minutes before</MenuItem>
                                                    <MenuItem value={30}>30 minutes before</MenuItem>
                                                    <MenuItem value={45}>45 minutes before</MenuItem>
                                                    <MenuItem value={60}>60 minutes before</MenuItem>
                                                </TextField>
                                            )}
                                            {n.repeatType === "Weekly" && (
                                                <Datetime
                                                    inputProps={params => <TextField fullWidth {...params} />}
                                                    dateFormat={false}
                                                    id="weekly"
                                                    timeFormat="h:mm a"
                                                    onChange={e => handleChangeAnnouncement(index, "time", e)}
                                                    value={n.time}
                                                    style={{ marginTop: "-2px" }}
                                                />
                                            )}
                                        </Grid>
                                        <Grid item xs={12}>
                                            {n.repeatType === "Weekly" && (
                                                <div className={classes.weekDays} style={{ marginTop: "20px" }}>
                                                    {weekDaysList.map(day => (
                                                        <div
                                                            key={day.value}
                                                            onClick={() => handleChangeRepeatDays(index, day)}
                                                            className={`${classes.days} ${!selectedWeekDays.includes(day.value) && classes.disabledDay} ${n.days.indexOf(day) > -1 ? classes.selectedDays : ""
                                                                }`}
                                                        >
                                                            {day.label}
                                                        </div>
                                                    ))}
                                                </div>
                                            )}
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <br />
                            </div>
                        )
                    })
                }

                <p className={classes.addAnother} onClick={addAnotherAnnouncement}>
                    + Add Announcement
                </p>
            </FormRow>
        </React.Fragment >
    )
}

export default AnnouncementSection
