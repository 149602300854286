import { gql } from "@apollo/client";
import PositionFields from "../fragments/position-fragment";

export default gql`
  query getPositions($workerId: String!, $first: Int, $last: Int, $after: String, $before: String) {
    positions(workerId: $workerId, first: $first, last: $last, after: $after, before: $before) {
      totalCount
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        node {
          ...PositionFields
        }
      }
    }
  }
  ${PositionFields}
`;
