import { gql } from "@apollo/client";

export default gql`
    query($jobId: Int!, $sendTo: JobVisibility!) {
        notifyWorkerAboutJob(jobId: $jobId, sendTo: $sendTo){
            status
            message
        }
    }
`;
