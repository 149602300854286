import React from "react";
import { useDispatch, useSelector } from "react-redux";
import Typography from "@mui/material/Typography";
import { PaymentConstants } from "helpers/constants/index";
import Line from "components/Placeholders/Line";
import { calculateMargins, calculateTargetPrice } from "utils/money";
import { formatMoney } from "utils/stringUtils";
import { actions as JobDetailActions, selectors as JobDetailSelectors } from "store/modules/jobDetails";
import BatchCurrencyInput from "../../components/Controls/BatchCurrencyInput";
import CurrencyInput from "../../components/Controls/CurrencyInput";
import DisableCellOverlay from "../../components/Controls/DisableCellOverlay";
import Can from "components/Authorization/Can";
import { PositionPermissions } from "helpers/constants/permissions";

export default function usePayRate(isBatchEditingRates, disabled) {
    const dispatch = useDispatch();

    const canAddWorker = useSelector(JobDetailSelectors.getCanAddWorker);
    const positionUpdates = useSelector(JobDetailSelectors.getPositionUpdates);
    const marginTargets = useSelector(JobDetailSelectors.getEmployerMarginTarget);
    const basePayRate = useSelector(JobDetailSelectors.getEmployerPayRate);
    const baseBillingRate = useSelector(JobDetailSelectors.getEmployerBillingRate);

    const handleBatchApply = value => {
        const maxMargin = calculateTargetPrice(value, marginTargets, [baseBillingRate]);
        dispatch(JobDetailActions.batchUpdatePositionData("payRate")(value));
        dispatch(JobDetailActions.batchUpdatePositionData("billingRate")(maxMargin));
    };

    const updatePositionIsEditable = JobDetailActions.updatePositionData("isEditable");
    const updatePositionPayRate = JobDetailActions.updatePositionData("payRate");
    const updatePositionBillingRate = JobDetailActions.updatePositionData("billingRate");
    function cellRendrer({ row: { original } }) {
        if (isBatchEditingRates && original.isEditable) {
            const { payRate, billingRate, isEditable } = positionUpdates.find(
                ({ id }) => id === original.id,
            );
            if (!isEditable) {
                dispatch(updatePositionIsEditable(original.id, true));
            }
            const { percentage, dollars } = calculateMargins(payRate, billingRate);
            const tooltip = `Margin/hr: ${formatMoney(dollars)} (${percentage}%)`;

            const onChange = value => {
                const maxMargin = calculateTargetPrice(value, marginTargets, [original.billingRate]);
                dispatch(updatePositionPayRate(original.id, value));
                dispatch(updatePositionBillingRate(original.id, maxMargin));
            };

            return (
                <CurrencyInput
                    minValue={PaymentConstants.MINIMUM_WAGE}
                    value={payRate ||""}
                    onChange={onChange}
                    tooltip={tooltip}
                />
            );
        }

        return (
            <>
                {<Typography variant="body1">{formatMoney(original.payRate)}</Typography>}
                <DisableCellOverlay visible={disabled || (isBatchEditingRates && !original.isEditable)} />
            </>
        )
    }
    return {
        Header: "Pay Rate",
        id: "payRate",
        width: 80,
        filterable: isBatchEditingRates,
        Filter: () => {
            return (
                <BatchCurrencyInput
                    label="Pay rate"
                    minValue={PaymentConstants.MINIMUM_WAGE}
                    minValueHelperText={`${formatMoney(PaymentConstants.MINIMUM_WAGE)} min.`}
                    defaultValue={basePayRate}
                    onApply={handleBatchApply}
                />
            );
        },
        Cell: cellRendrer,
        Footer: (<Can perform={PositionPermissions.create.fill.value}>
            {allowed => {
                if (!allowed) {
                    return null;
                }
                return canAddWorker && !disabled ? <Line /> : null
            }}
        </Can>)
    };
}
