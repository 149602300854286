import { gql } from "@apollo/client";
import EmployerFields from "../fragments/employer-fragment";

export default gql`
    mutation editEmployer($data: EditEmployerInput!) {
        editEmployer(data: $data) {
            ...EmployerFields
        }
    }
    ${EmployerFields}
`;
