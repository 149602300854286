import React, { Fragment } from "react";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { Lock, LockOpen } from "@mui/icons-material";
import { createDcCustomerDialogStyles } from "views/Finance/helpers";
import Dialog from "components/Dialog/Dialog";

function CreateDcCustomerDialog(props) {
  const {
    openCreateDcCustomerDialog,
    handleShowDcCustomer,
    handleWorkerEmailTaken,
    dcEmail,
    emailValid,
    dcEmailError,
    emailEditable,
    setEmailEditable,
    handleCreateDcCustomer,
  } = props;
  const classes = createDcCustomerDialogStyles();

  const onChange = value => handleWorkerEmailTaken(value);

  const handleSetEmailEditable = () => setEmailEditable(!emailEditable);
  //   const onConfirm = () => setEmailEditable(!emailEditable);

  const RenderContent = () => (
    <Grid container direction="row" alignItems="center">
      <Grid item xs={12}>
        <Typography align="center" variant="body1">
          Validate email and create a Dc Bank customer profile.
        </Typography>
        <Typography align="center" variant="body1"></Typography>
      </Grid>
      <Grid container item xs={12} justifyContent="center" className={classes.inputContainer}>
        <TextField
          label="Email Address"
          autoFocus

          fullWidth
          error={!emailValid}
          helperText={dcEmailError}
          defaultValue={dcEmail}
          disabled={!emailEditable}
          type="email"
          onBlur={e => onChange(e.target.value)}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() =>
                    emailEditable ? handleSetEmailEditable() : handleSetEmailEditable()
                  }
                  size="large">
                  {emailEditable ? <LockOpen style={{ color: "green" }} /> : <Lock />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Grid>
    </Grid>
  );

  return (
    <Fragment>
      <Dialog
        open={openCreateDcCustomerDialog}
        title={`Manage DcBank Settings`}
        description={`   \n\n`}
        onClose={handleShowDcCustomer}
        onConfirm={handleCreateDcCustomer}
        onCancel={handleShowDcCustomer}
        confirmText="Create Dc Customer"
        alertType="form"
        maxWidth="sm"
        disableEscapeKeyDown
        disableRestoreFocus
        // style={{ top: "-200px", zIndex: 99999 }}
        disabled={!emailValid}
        fullWidth={false}>
        <RenderContent />
      </Dialog>
    </Fragment>
  );
}

export default CreateDcCustomerDialog;
