import React from "react";
import PropTypes from "prop-types";
import withStyles from '@mui/styles/withStyles';
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Line from "components/Placeholders/Line";
import { formatMoney } from "utils/stringUtils";

function WorkerFinanceCard(props) {
    const { classes, totalHoursWorked, grossPay, totalDeductions, netPay } = props;

    return (
        <Grid item xs={3}>
            <Grid container item xs={12} className={classes.card} justifyContent="space-between">
                <Grid container item xs={12} direction="column" alignItems="flex-end">
                    <Typography variant="caption" gutterBottom>
                        Hours Worked
                    </Typography>
                    <Typography variant="h6">{totalHoursWorked}</Typography>
                </Grid>
                <Grid container item xs={12} direction="column" alignItems="flex-end">
                    <Typography variant="caption" gutterBottom>
                        Total Earned
                    </Typography>
                    <Typography variant="h6">{formatMoney(grossPay)}</Typography>
                </Grid>
                <Grid container item xs={12} direction="column" alignItems="flex-end">
                    <Typography variant="caption" gutterBottom>
                        Total Deductions
                    </Typography>
                    <Typography variant="h6">{formatMoney(totalDeductions)}</Typography>
                </Grid>
                <Grid
                    container
                    item
                    xs={12}
                    direction="column"
                    alignItems="flex-end"
                    className={classes.netPayContainer}
                >
                    <Line className={classes.totalLine} />
                    <Typography variant="caption" gutterBottom>
                        Net Pay
                    </Typography>
                    <Typography variant="h4">{formatMoney(netPay)}</Typography>
                </Grid>
            </Grid>
        </Grid>
    );
}

WorkerFinanceCard.propTypes = {
    classes: PropTypes.object.isRequired,
    totalHoursWorked: PropTypes.number,
    grossPay: PropTypes.number,
    totalDeductions: PropTypes.number,
    netPay: PropTypes.number,
};

WorkerFinanceCard.defaultProps = {
    rating: 0,
    totalHoursWorked: 0,
    grossPay: 0,
    totalDeductions: 0,
    netPay: 0,
};

export default withStyles(({ palette, spacing, shape }) => ({
    card: {
        backgroundColor: palette.common.white,
        border: `1px solid ${palette.grey[500]}`,
        borderRadius: shape.borderRadius,
        padding: `${spacing(3)} !important`,
        height: "100%",
    },
    netPayContainer: {
        justifyContent: "flex-end",
    },
    totalLine: {
        height: 3,
        width: spacing(10),
        marginBottom: spacing(3),
    },
}))(WorkerFinanceCard);
