import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { CalendarToday, Receipt } from "@mui/icons-material";
import AccountCircle from "@mui/icons-material/AccountCircle";
import MoreVert from "@mui/icons-material/MoreVert";
import { shiftMenuStyles } from "views/Finance/helpers";

function ShiftMenu({ position, onClick, workerId, jobId }) {
  const classes = shiftMenuStyles();
  const history = useHistory();

  const [anchorEl, setAnchorEl] = useState(null);

  const menuId = `action-menu-${position.id}`;

  const openMenu = e => {
    setAnchorEl(e.currentTarget);
  };

  const closeMenu = () => {
    setAnchorEl(null);
  };
  const handleViewProfile = () => {
    history.push(`/workers/${workerId}`);
    closeMenu();
  };
  const handleViewJob = () => {
    history.push(`/jobs/${jobId}`);
    closeMenu();
  };

  return <>
    <IconButton
      aria-controls={menuId}
      aria-haspopup="true"
      onClick={openMenu}
      disableRipple
      size="large">
      <MoreVert color="primary" />
    </IconButton>
    <Menu
      id={menuId}
      anchorEl={anchorEl}
      keepMounted
      open={Boolean(anchorEl)}
      onClose={closeMenu}>
      <MenuItem onClick={onClick}>
        <Receipt className={classes.icon} color="action" />
        View Payroll
      </MenuItem>
      <MenuItem onClick={handleViewJob}>
        <CalendarToday className={classes.icon} color="action" />
        View Job
      </MenuItem>
      <MenuItem onClick={handleViewProfile}>
        <AccountCircle className={classes.icon} color="action" />
        View Profile
      </MenuItem>
    </Menu>
  </>;
}

export default ShiftMenu;
