import * as pagination from "utils/graphql-pagination";
import { createReducer } from "utils/redux";
import { paginationSettings } from "./constants";
import types from "./types";

const initialListState = {
  sort: [],
  filter: [],
  data: [],
};

const initialLoadingState = {
  page: true,
  actions: false,
  employerNotes: false,
};

const initialState = {
  //   editProfile: { ...initialEditProfileState },
  loading: { ...initialLoadingState },
  employer: {},
  managers: [],
  updateEmployerData: {},
  updateEmployerCustomRateData: {},
  editEmployerCustomRateData: {},
  newEmployerCustomRate: {},
  canUpdateEmployer: false,
  hoursTrendData: [],
  employerCustomRates: [],
  jobs: { ...initialListState, sort: [{ id: "start", desc: true }] },
  workerExceptions: {
    banned: { ...initialListState },
    preferred: { ...initialListState },
  },
  employerNotes: "",
  workerPicker: { ...initialListState },
  ...pagination.initialState(paginationSettings),
};

const actionsMap = {
  [types.RESET]: () => {
    return { ...initialState };
  },
  [types.SET_UPDATE_UPDATE_EMPLOYER_DATA]: (state, { payload }) => {
    const { field, value } = payload;
    return { ...state, updateEmployerData: { ...state.updateEmployerData, [field]: value } };
  },

  [types.SET_EDIT_EMPLOYER_CUSTOM_RATE_DATA]: (state, { payload }) => {
    const { field, value } = payload;
    return {
      ...state,
      editEmployerCustomRateData: { ...state.editEmployerCustomRateData, [field]: value },
    };
  },

  [types.SET_UPDATE_EMPLOYER_CUSTOM_RATE_DATA]: (state, { payload }) => {
    const { field, value } = payload;
    return {
      ...state,
      updateEmployerCustomRateData: { ...state.updateEmployerCustomRateData, [field]: value },
    };
  },

  [types.SET_NEW_EMPLOYER_CUSTOM_RATE_DATA]: (state, { payload }) => {
    const { field, value } = payload;
    return { ...state, newEmployerCustomRate: { ...state.newEmployerCustomRate, [field]: value } };
  },

  [types.CLEAR_CREATE_EMPLOYER_CUSTOM_RATE_DATA]: state => {
    return {
      ...state,

      newEmployerCustomRate: {},
    };
  },
  [types.CLEAR_UPDATE_EMPLOYER_CUSTOM_RATE_DATA]: state => {
    return {
      ...state,
      updateEmployerCustomRateData: { ...initialState.editEmployerCustomRateData },
      editEmployerCustomRateData: { ...initialState.editEmployerCustomRateData },
    };
  },
  [types.CLEAR_UPDATE_UPDATE_EMPLOYER_DATA]: (state, { payload }) => {
    return {
      ...state,
      canUpdateEmployer: false,
      updateEmployerData: { ...payload },
    };
  },

  [types.SET_LOADING_STATE]: (state, { payload }) => {
    const { key, value } = payload;
    return {
      ...state,
      loading: {
        ...state.loading,
        [key]: value,
      },
    };
  },

  [types.ADD_CHANGE]: (state, { payload }) => {
    const newForm = { ...state.data };
    newForm[payload.fieldName] = payload.fieldValue;
    return {
      ...state,
      editProfile: { ...state.editProfile, changed: true, data: payload },
    };
  },

  [types.SET_UP_EDIT_FORM]: (state, { payload }) => {
    return {
      ...state,
      editProfile: { ...state.editProfile, changed: true, data: payload },
    };
  },

  [types.EDIT_FORM_PENDING]: state => {
    return {
      ...state,
      editProfile: { ...state.editProfile, status: types.EDIT_FORM_PENDING },
    };
  },

  [types.EDIT_FORM_SUCCESS]: (state, { payload }) => {
    return {
      ...state,
      editProfile: {
        ...state.editProfile,
        changed: false,
        data: payload.form,
        status: types.EDIT_FORM_SUCCESS,
      },
    };
  },

  [types.SET_EMPLOYER_CUSTOM_RATES]: (state, { payload }) => {
    return {
      ...state,
      employerCustomRates: [...payload],
    };
  },

  [types.SET_EMPLOYER]: (state, { payload }) => {
    const { employer } = payload;
    return {
      ...state,
      employer,
      //   updateEmployerData: { ...employer },
    };
  },
  [types.SET_MANAGER]: (state, { payload }) => {
    const { managers } = payload;
    return {
      ...state,
      managers,
    };
  },

  [types.SET_HOURS_TREND_DATA]: (state, { payload }) => {
    const { hoursTrendData } = payload;
    return {
      ...state,
      hoursTrendData,
    };
  },
  [types.SET_WORKER_EXCEPTIONS_LIST_DATA]: (state, { payload }) => {
    const { data, list, part } = payload;

    return {
      ...state,
      workerExceptions: {
        ...state.workerExceptions,
        [list]: {
          ...state.workerExceptions[list],
          [part]: data,
        },
      },
    };
  },
  [types.SET_JOBS_LIST_DATA]: (state, { payload }) => {
    const { data, part } = payload;

    return {
      ...state,
      jobs: {
        ...state.jobs,
        [part]: data,
      },
    };
  },

  [types.SET_WORKER_PICKER_LIST_DATA]: (state, { payload }) => {
    const { data, part } = payload;

    return {
      ...state,
      workerPicker: {
        ...state.workerPicker,
        [part]: data,
      },
    };
  },
  [types.SET_EMPLOYER_NOTE]: (state, { payload }) => {
    return {
      ...state,
      employerNotes: payload,
    };
  },
  [types.RESET_EMPLOYER_NOTE]: state => {
    return {
      ...state,
      employerNotes: "",
    };
  },
  ...pagination.createPaginationActions(paginationSettings),
};

export default createReducer(initialState, actionsMap);
