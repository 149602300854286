/*eslint-disable*/
import React from "react";
import MUIDialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Typography from "@mui/material/Typography";
import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineOppositeContent from "@mui/lab/TimelineOppositeContent";
import TimelineDot from "@mui/lab/TimelineDot";

import { QueryBuilderTwoTone, UpdateSharp } from "@mui/icons-material";
import Paper from "@mui/material/Paper";

import { resendDialogStyles } from "views/Finance/helpers";
import { convertUTCToLocal } from "../../../../../utils/time";
const isMobile = false;
function TransferTimelineDialog(props) {
  const { classes, onClose, open, data, maxWidth, fullWidth, size } = props;
  return (
    <MUIDialog
      className={classes.core}
      fullScreen={isMobile}
      open={open}
      disablePortal={false}
      fullWidth={fullWidth ? fullWidth : false}
      style={{ top: "-200px" }}
      size={size ? size : "sm"}
      maxWidth={maxWidth ? maxWidth : "md"}
      disableBackdropClick={false}
      onClose={onClose}>
      <DialogContent className={classes.content}>
        {data && data.createdAt ? (
          <Timeline align="alternate">
            <TimelineItem>
              <TimelineOppositeContent alignRight className={classes.timelineAltContent}>
                <Typography variant="body2" color="textSecondary">
                  {data.createdAt ? convertUTCToLocal(data.createdAt, "MMM Do, YYYY h:mm a") : "-"}
                </Typography>
              </TimelineOppositeContent>
              <TimelineSeparator>
                <TimelineDot color={"secondary"}>
                  <QueryBuilderTwoTone />
                </TimelineDot>
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent className={classes.timelineContent}>
                <Paper
                  elevation={3}
                  variant="outlined"
                  className={classes.paper}
                  style={{ paddingTop: 0 }}>
                  <Typography variant="h6" style={{ fontSize: 16 }}>
                    Payroll Created
                  </Typography>
                  <Typography style={{ fontSize: 12 }}>Payroll created </Typography>
                </Paper>
              </TimelineContent>
            </TimelineItem>

            {data.payments &&
              data.payments.map((payment, i) => (
                <TimelineItem key={i} style={{ minHeight: 100 }}>
                  <TimelineOppositeContent alignRight className={classes.timelineAltContent}>
                    <Typography variant="body2" color="textSecondary">
                      {payment.createdAt
                        ? convertUTCToLocal(payment.createdAt, "MMM Do, YYYY h:mm a")
                        : "-"}
                    </Typography>
                  </TimelineOppositeContent>
                  <TimelineSeparator>
                    <TimelineDot color={i % 2 === 0 ? "primary" : "secondary"}>
                      <UpdateSharp />
                    </TimelineDot>
                    {i !== data.payments.length - 1 ? <TimelineConnector /> : ""}
                  </TimelineSeparator>
                  <TimelineContent className={classes.timelineContent}>
                    <Paper elevation={3} className={classes.paper} variant="outlined">
                       <Typography
                        variant="h6"
                        component="h6"
                        style={{ fontSize: 16, textTransform: "capitalize" }}>
                        {payment.paymentStatus && payment.paymentStatus.description}
                      </Typography>
                      <Typography style={{ fontSize: 13 }}>
                        {payment.paymentStatus && payment.paymentStatus.description}
                      </Typography>
                    </Paper>
                  </TimelineContent>
                </TimelineItem>
              ))}
          </Timeline>
        ) : (
          ""
        )}
      </DialogContent>
    </MUIDialog>
  );
}

export default resendDialogStyles(TransferTimelineDialog);
