import React, { useState } from "react";
import Datetime from "react-datetime";
import { useDispatch, useSelector } from "react-redux";
import { Typography, Grid, InputLabel } from "@mui/material";
import { makeStyles } from "@mui/styles";
import moment from "moment";
import Button from "components/Button/Button";
import Dialog from "components/Dialog/Dialog";
import { actions as JobActions, selectors as JobSelectors } from "store/modules/jobs";
import ReactTableSimple from "components/ReactTable/ReactTableSimple";

const useStyles = makeStyles(({ spacing }) => ({
    dialogTitle: {
        width: "100%",
        fontSize: 18,
        textAlign: "left",
    },
    container: {
        textAlign: "left",
    },
    addButton: {
        float: "left",
        paddingLeft: "0px",
        width: "fit-content",
    },
    datepicker: {
        width: "100%",
        marginTop: "-20px",
        "& input": {
            margin: "0 !important",
        },
    },
}));

function EditJobDialog({ privateJobDetail, open, onCancel }) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [startDate, setStartDate] = useState(privateJobDetail.startDate);
    const [endDate, setEndDate] = useState(privateJobDetail.endDate);
    const isLoading = useSelector(JobSelectors.getImportPrivateJobLoading);
    const errorList = useSelector(JobSelectors.getUpdatePrivateJobError);

    const isValidDate = () => {
        if (endDate && startDate) {
            return moment(endDate).isAfter(moment(startDate));
        }
        return true;
    }
    const closeDialog = () => {
        onCancel();
    };

    const setStartTime = (date) => {
        setStartDate(moment(date).format('YYYY/MM/DD'))
    }

    const setEndTime = (date) => {
        setEndDate(moment(date).format('YYYY/MM/DD'))
    }

    const updateJob = () => {
        dispatch(JobActions.UpdateimportPrivateJob({ id: privateJobDetail.id, startDate, endDate }))
    }

    const columns = [
        {
            Header: "Job Id",
            id: "id",
            accessor: ({ id }) => id,
            className: "-cursor-pointer",
        },
        {
            Header: "Error",
            id: "error",
            accessor: ({ error }) => error,
            className: "-cursor-pointer",
            Cell: ({ row: { original } }) => {
                return <div style={{ color: "red" }}>{original.error}</div>;
            },
        },
    ];

    return (
        <Dialog
            title={
                <React.Fragment>
                    <Typography component="h5" className={classes.dialogTitle}>
                        {`Edit Job`}
                        <Button
                            style={{ float: "right", marginRight: -30 }}
                            label="X"
                            size="small"
                            onClick={closeDialog}
                            color="transparent"
                        />
                    </Typography>
                </React.Fragment>
            }
            open={open}
            description={null}
            onClose={closeDialog}
            onCancel={closeDialog}
            size="lg"
            hideActions
            alertType="form"
        >
            <React.Fragment>
                <Grid container spacing={2} className={classes.container}>
                    <Grid item xs={6} sm={6} md={6} lg={6} style={{ marginTop: "25px" }}>
                        <InputLabel htmlFor="startdate">Start Date</InputLabel>
                        <Datetime
                            timeFormat={false}
                            id="startdate"
                            input
                            disabled
                            dateFormat="YYYY/MM/DD"
                            closeOnSelect
                            onChange={value => setStartTime(value)}
                            value={startDate}
                            className={classes.datepicker}
                        />
                    </Grid>

                    <Grid item xs={6} sm={6} md={6} lg={6} style={{ marginTop: "25px" }}>
                        <InputLabel htmlFor="date">End Date</InputLabel>
                        <Datetime
                            timeFormat={false}
                            id="date"
                            input
                            dateFormat="YYYY/MM/DD"
                            closeOnSelect
                            minDate={new Date()}
                            onChange={value => setEndTime(value)}
                            value={endDate}
                            className={classes.datepicker}
                        />
                    </Grid>
                </Grid>
                <br />
                <Button
                    className={classes.addButton}
                    buttonClassName={classes.addButton}
                    label="Update"
                    size="small"
                    disabled={!isValidDate()}
                    onClick={() => updateJob()}
                    color="secondary"
                    isLoading={isLoading}
                />
                <br />
                {errorList && errorList.length > 0 && (
                    <div style={{ textAlign: "left" }}>
                        <h3>We found below errors while update date</h3>
                        <ReactTableSimple
                            classes="-highlight"
                            style={{ width: "100%", marginTop: "16px" }}
                            data={errorList}
                            defaultPageSize={5}
                            rowsPerPageOptions={{ rowsPerPageOptions: [5] }}
                            columns={columns}
                        />
                    </div>
                )}
            </React.Fragment>
        </Dialog>
    );
}

export default EditJobDialog;
