import * as pagination from "utils/graphql-pagination";
import { createReducer } from "utils/redux";
import { paginationSettings } from "./constants";
import types from "./types";

const initialListState = {
  sort: [],
  filter: [],
  data: [],
};

const initialLoadingState = {
  page: true,
  positions: false,
  workerNotes: false,
  workerBannedFrom: false,
  actions: false,
  DCTransaction: false,
};

const initialState = {
  eTansfers: [],
  workerDCTRansactionList: [],
  loading: { ...initialLoadingState },
  positions: {
    ...initialListState,
  },
  workerNotes: {
    ...initialListState,
  },
  workerBannedFrom: {
    ...initialListState,
  },
  workerNote: "",
  workerTags: [],
  worker: {},
  updateWorkerData: {},
  transportationTypes: [],
  ...pagination.initialState(paginationSettings),
};

const actionsMap = {
  [types.RESET]: () => {
    return { ...initialState };
  },
  [types.SET_WORKER_ETRANSFERS]: (state, { payload }) => {
    return {
      ...state,
      eTansfers: payload,
      //   canUpdateEmployer: true,
    };
  },

  [types.SET_WORKER_DC_TRANSACTION_LIST]: (state, { payload }) => {
    return {
      ...state,
      workerDCTRansactionList: payload,
    };
  },

  [types.SET_TRANSPORTATION_TYPES]: (state, { payload }) => {
    return {
      ...state,
      transportationTypes: payload,
      //   canUpdateEmployer: true,
    };
  },

  [types.SET_UPDATE_UPDATE_WORKER_DATA]: (state, { payload }) => {
    const { field, value } = payload;
    return {
      ...state,

      updateWorkerData: {
        ...state.updateWorkerData,
        [field]: value,
      },
      //   canUpdateEmployer: true,
    };
  },

  [types.CLEAR_UPDATE_UPDATE_WORKER_DATA]: (state, { payload }) => {
    return {
      ...state,

      updateWorkerData: { ...payload },
    };
  },

  [types.SET_LOADING_STATE]: (state, { payload }) => {
    const { key, value } = payload;
    return {
      ...state,
      loading: {
        ...state.loading,
        [key]: value,
      },
    };
  },
  [types.SET_WORKER]: (state, { payload }) => {
    const { worker } = payload;
    return {
      ...state,
      worker,
    };
  },

  [types.SET_POSITIONS_LIST_DATA]: (state, { payload }) => {
    const { data, part } = payload;
    return {
      ...state,
      positions: {
        ...state.positions,
        [part]: data,
      },
    };
  },

  [types.SET_WORKER_NOTES_LIST_DATA]: (state, { payload }) => {
    const { data } = payload;
    return {
      ...state,
      workerNotes: {
        ...state.workerNotes,
        data,
      },
    };
  },

  [types.SET_WORKER_BANNED_FROM_LIST_DATA]: (state, { payload }) => {
    const { data } = payload;
    return {
      ...state,
      workerBannedFrom: {
        ...state.workerBannedFrom,
        data,
      },
    };
  },

  [types.SET_WORKER_NOTE]: (state, { payload }) => {
    return {
      ...state,
      workerNote: payload,
    };
  },
  [types.RESET_WORKER_NOTE]: state => {
    return {
      ...state,
      workerNote: "",
    };
  },

  [types.SET_WORKER_TAGS]: (state, { payload }) => {
    return {
      ...state,
      workerTags: payload,
    };
  },
  [types.RESET_WORKER_TAGS]: state => {
    return {
      ...state,
      workerTags: "",
    };
  },
  ...pagination.createPaginationActions(paginationSettings),
};

export default createReducer(initialState, actionsMap);
