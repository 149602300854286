import * as pagination from "utils/graphql-pagination";
import { createReducer } from "utils/redux";
import { paginationSettings } from "./constants";
import types from "./types";
const initialLoadingState = {
  privateJob: false,
  importPrivateJob: false,
  fillPosition: false,
  editPrivateJobDialog: false,
  deletePrivateJobPositionDialog: false,
  privateJobFillPositionDialog: false,
  uploadPrivateJobCSVDialog: false,
};
const initialBaseListStats = {
  count: 0,
  bookings: 0,
  revenue: 0,
  hours: 0,
};

const initialListStats = {
  total: { ...initialBaseListStats },
  active: { ...initialBaseListStats },
  filled: { ...initialBaseListStats },
  unfilled: { ...initialBaseListStats },
  completed: { ...initialBaseListStats },
};

const initialListState = {
  sort: [{ id: "start", desc: true }],
  filter: [],
  data: [],
};

const initialState = {
  loading: { ...initialLoadingState },
  importedPrivateJobList: [],
  privateJobPositionList: [],
  importedPrivateJobDetail: {},
  importCSVErrorList: [],
  updatePrivateJobErrorList: [],
  deletePrivateJobPositionErrorList: [],
  fillPositionErrorList: [],
  timeframeStats: {
    today: 0,
    tomorrow: 0,
    upcoming: 0,
    drafts: 0,
    queued: 0,
  },
  listStats: {
    today: { ...initialListStats },
    tomorrow: { ...initialListStats },
    upcoming: { ...initialListStats },
  },
  today: {
    total: { ...initialListState },
    active: { ...initialListState },
    unfilled: { ...initialListState },
    filled: { ...initialListState },
    completed: { ...initialListState },
  },
  tomorrow: {
    total: { ...initialListState },
    unfilled: { ...initialListState },
    filled: { ...initialListState },
  },
  upcoming: {
    total: { ...initialListState },
    unfilled: { ...initialListState },
    filled: { ...initialListState },
  },
  history: { ...initialListState },
  drafts: { ...initialListState },
  queued: { ...initialListState },
  private: { ...initialListState },
  ...pagination.initialState(paginationSettings),
};

const actionsMap = {
  [types.SET_LOADING_STATE]: (state, { payload }) => {
    const { key, value } = payload;
    return {
      ...state,
      loading: {
        ...state.loading,
        [key]: value,
      },
    };
  },
  [types.FETCH_TIMEFRAME_STATS_SUCCESS]: (state, { payload }) => {
    return {
      ...state,
      timeframeStats: payload,
    };
  },
  [types.FETCH_LIST_STATS_SUCCESS]: (state, { payload }) => {
    const { timeframe, data } = payload;
    return {
      ...state,
      listStats: {
        ...state.listStats,
        [timeframe]: data,
      },
    };
  },
  [types.SET_LIST_DATA]: (state, { payload }) => {
    const { data, timeframe, status, part } = payload;
    if (!timeframe) {
      return state;
    }

    if (!status) {
      if (state[timeframe].data) {
        return {
          ...state,
          [timeframe]: {
            ...state[timeframe],
            [part]: data,
          },
        };
      }

      return state;
    }

    if (!state[timeframe][status].data) {
      return state;
    }

    return {
      ...state,
      [timeframe]: {
        ...state[timeframe],
        [status]: {
          ...state[timeframe][status],
          [part]: data,
        },
      },
    };
  },
  [types.SET_IMPORTED_PRIVATE_JOB_DATA]: (state, { payload }) => {
    return {
      ...state,
      importedPrivateJobList: payload.data,
    };
  },
  [types.SET_IMPORT_CSV_ERROR_DATA]: (state, { payload }) => {
    return {
      ...state,
      importCSVErrorList: payload.data,
    };
  },
  [types.SET_FILL_POSITION_ERROR_DATA]: (state, { payload }) => {
    return {
      ...state,
      fillPositionErrorList: payload.data,
    };
  },
  [types.SET_IMPORTED_PRIVATE_JOB_DETAIL]: (state, { payload }) => {
    return {
      ...state,
      importedPrivateJobDetail: payload.data,
    };
  },
  [types.SET_PRIVATE_JOB_POSITIONS_DATA]: (state, { payload }) => {
    return {
      ...state,
      privateJobPositionList: payload.data,
    };
  },
  [types.SET_UPDATE_PRIVATE_JOB_ERROR_DATA]: (state, { payload }) => {
    return {
      ...state,
      updatePrivateJobErrorList: payload.data,
    };
  },
  [types.SET_DELETE_PRIVATE_JOB_POSITION_ERROR_DATA]: (state, { payload }) => {
    return {
      ...state,
      deletePrivateJobPositionErrorList: payload.data,
    };
  },

  ...pagination.createPaginationActions(paginationSettings),
};

export default createReducer(initialState, actionsMap);
