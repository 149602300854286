import gql from "graphql-tag";
export const addAdminMutation = gql`
  mutation($data: AddAdminInput!) {
    addAdmin(data: $data) {
      id
      email
      firstName
      lastName
      title
      role
      addedBy
      signedProfileImageUrl
    }
  }
`;

export const updateAdminMutation = gql`
  mutation($data: UpdateAdminInput!) {
    editAdmin(data: $data) {
      id
      email
      firstName
      lastName
      title
      role
      addedBy
      signedProfileImageUrl
    }
  }
`;

export const removeAdminMutation = gql`
  mutation($id: ID!) {
    removeAdmin(id: $id)
  }
`;

export const changeAdminPasswordMutation = gql`
  mutation($id: ID, $password: String!) {
    changeAdminPassword(id: $id, password: $password)
  }
`;

export const updateAdminPermissionsMutation = gql`
  mutation($id: ID!, $permissions: [PermissionInput!]!) {
    updateAdminPermissions(id: $id, permissions: $permissions)
  }
`;

export const removeAdminPermissionsMutation = gql`
  mutation($id: ID!, $permissions: [PermissionInput!]!) {
    removeAdminPermissions(id: $id, permissions: $permissions)
  }
`;
export default addAdminMutation;
