/* eslint-disable react/display-name */
/* eslint-disable react/prop-types */
import React from "react";
import { useSelector } from "react-redux";
import Typography from "@mui/material/Typography";
import Line from "components/Placeholders/Line";
import { formatDurationFromMins } from "utils/time";
import { selectors as JobDetailSelectors } from "store/modules/jobDetails";
import DisableCellOverlay from "../../components/Controls/DisableCellOverlay";
import Can from "components/Authorization/Can";
import { PositionPermissions } from "helpers/constants/permissions";

export default function useShiftDuration(isCheckingOutAll, disabled) {
    const canAddWorker = useSelector(JobDetailSelectors.getCanAddWorker);
    const positionUpdates = useSelector(JobDetailSelectors.getPositionUpdates);

    return {
        Header: "Duration",
        id: "shiftDuration",
        // width: 100,
        Cell: ({ row: { original } }) => {
            const positionHasPayment = original.payroll.some(({ payments }) => {
                return payments.length !== 0;
            });

            let duration = original.durationMins;
            if (isCheckingOutAll && !positionHasPayment) {
                const { durationMins } = positionUpdates.find(
                    ({ id }) => id === original.id,
                );
                duration = durationMins;
            }
            return (
                <>
                    {duration ? (
                        <Typography variant="body1">
                            {formatDurationFromMins(duration, {
                                hourLabel: "hr",
                                minuteLabel: "min",
                                separator: "",
                            })}
                        </Typography>
                    ) : (
                        <Line />
                    )}
                    <DisableCellOverlay
                        visible={(disabled && !isCheckingOutAll) || (isCheckingOutAll && positionHasPayment)}
                    />
                </>
            );
        },
        Footer: (<Can perform={PositionPermissions.create.fill.value}>
            {allowed => {
                if (!allowed) {
                    return null;
                }
                return canAddWorker && !disabled ? <Line /> : null
            }}
        </Can>)
    };
}
