import React from "react";
import PropTypes from "prop-types";
import moment from "moment-timezone";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { ResponsiveLine } from "@nivo/line";
import { employerTrendCardStyles } from "views/Employer/helpers";
import { convertUTCToLocal } from "utils/time";

function EmployerTrendCard(props) {
  const { classes, hoursTrendData } = props;

  let average = 0;
  const data = hoursTrendData.map(({ monthTimestamp, hoursWorked }) => {
    average += hoursWorked;
    return {
      x: moment(monthTimestamp).utc().format("YYYY-MM-DD"),
      y: hoursWorked,
    };
  });

  average = average / hoursTrendData.length;

  return (
    <Grid item xs={12} className={classes.container}>
      <Grid container item xs={12} className={classes.card}>
        <Typography variant="caption" align="center" style={{ width: "100%", marginTop: 16 }}>
          Hours Trend
        </Typography>
          {data.length > 0 && (
              <ResponsiveLine
                  data={[{ id: "hoursWorked", color: "#0b938b", data }]}
                  margin={{ top: 20, right: 30, bottom: 70, left: 60 }}
                  enablePoints={false}
                  lineWidth={2}
                  xScale={{
                      type: "time",
                      format: "%Y-%m-%d",
                      precision: "month",
                  }}
                  xFormat="time:%Y-%m-%d"
                  yScale={{
                      type: "linear",
                  }}
                  axisLeft={{
                      tickSize: 10,
                      tickPadding: 5,
                      tickRotation: 0,
                      tickValues: 3,
                  }}
                  curve="cardinal"
                  enableArea
                  colors="#0b938b"
                  areaOpacity={0.1}
                  isInteractive
                  useMesh
                  enableGridX={false}
                  enableGridY={true}
                  axisBottom={{
                      format: value => {
                          const date = moment(value).utc();
                          if (date.month() === 0) {
                              return date.year();
                          }
                          return null;
                      },
                      tickSize: 10,
                      tickPadding: 5,
                  }}
                  gridYValues={[0, average]}
                  tooltip={({ point }) => {
                      return (
                          <div className={classes.tooltip}>
                              <Typography style={{ color: "#FFF" }} variant="caption" gutterBottom>
                                  {convertUTCToLocal(point.data.x, "MMM YYYY")}
                              </Typography>
                              <Typography
                                  style={{ color: "#FFF" }}
                                  variant="body1">{`${point.data.y} hours`}</Typography>
                          </div>
                      );
                  }}
              />
          )}
      </Grid>
    </Grid>
  );
}

EmployerTrendCard.propTypes = {
  classes: PropTypes.object.isRequired,
  hoursTrendData: PropTypes.array,
};

EmployerTrendCard.defaultProps = {
  hoursTrendData: [],
};

export default employerTrendCardStyles(EmployerTrendCard);
