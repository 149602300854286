export const transferStatuses = [
    { id: 3, label: "Awaiting Response" },
    { id: 5, label: "Delivered" },
    { id: 6, label: "Accepted" },
    { id: 7, label: "Declined" },
    { id: 8, label: "Failed" },
    { id: 13, label: "Manually Resolved" },
    // { id: 9, label: "Retried" },
    { id: 11, label: "Cancelled" },
    { id: 5, label: "Completed" },
];
export const workerTypeListByRating = [
    { label: "Preferred", value: "Preferred" },
    { label: "4+ Star Rating", value: "FourPlus" },
    { label: "3+ Star Rating", value: "ThreePlus" },
    { label: "Less than 3 star", value: "Everyone" },
];

export const transactionStatusList = [
    { label: "All", value: "" },
    { label: "Pending", value: "P" },
    { label: "Sent", value: "S" },
    { label: "Error", value: "E" },
    { label: "Rejected", value: "RJ" },
    { label: "Completed", value: "C" },
    { label: "Cancelled", value: "V" },
    { label: "Insufficient Balance", value: "IB" },
];
export const bcHolidays = [
    {
        date: "2021-10-12",
        name: [
            {
                lang: "en",
                text: "Thanksgiving Day",
            },
            {
                lang: "fr",
                text: "Action de grâce",
            },
        ],
    },
    {
        date: "2021-11-11",
        name: [
            {
                lang: "en",
                text: "Remembrance Day",
            },
            {
                lang: "fr",
                text: "Jour du Souvenir",
            },
        ],
    },
    {
        date: "2021-12-25",
        name: [
            {
                lang: "en",
                text: "Christmas Day",
            },
            {
                lang: "fr",
                text: "Noël",
            },
        ],
    },
    {
        date: "2021-01-01",
        name: [
            {
                lang: "en",
                text: "New Year's Day",
            },
            {
                lang: "fr",
                text: "Jour de l'An",
            },
        ],
    },
    {
        date: "2021-2-15",
        name: [
            {
                lang: "en",
                text: "Family Day",
            },
            {
                lang: "fr",
                text: "Fête de la famille",
            },
        ],
    },
    {
        date: "2021-4-02",
        name: [
            {
                lang: "en",
                text: "Good Friday",
            },
            {
                lang: "fr",
                text: "Vendredi saint",
            },
        ],
    },
    {
        date: "2021-5-24",
        name: [
            {
                lang: "en",
                text: "Victoria Day",
            },
            {
                lang: "fr",
                text: "Fête de la Reine ou Journée nationale des Patriotes",
            },
        ],
    },
    {
        date: "2021-7-1",
        name: [
            {
                lang: "en",
                text: "Canada Day",
            },
            {
                lang: "fr",
                text: "Fête du Canada",
            },
        ],
    },
    {
        date: "2021-8-02",
        name: [
            {
                lang: "en",
                text: "British Columbia Day",
            },
            {
                lang: "fr",
                text: "Jour du Colombie-Britannique",
            },
        ],
    },
    {
        date: "2021-09-06",
        name: [
            {
                lang: "en",
                text: "Labour Day",
            },
            {
                lang: "fr",
                text: "Fête du travail",
            },
        ],
    },
    {
        date: "2021-10-11",
        name: [
            {
                lang: "en",
                text: "Thanksgiving Day",
            },
            {
                lang: "fr",
                text: "Action de grâce",
            },
        ],
    },
    {
        date: "2021-11-11",
        name: [
            {
                lang: "en",
                text: "Remembrance Day",
            },
            {
                lang: "fr",
                text: "Jour du Souvenir",
            },
        ],
    },
    {
        date: "2021-12-25",
        name: [
            {
                lang: "en",
                text: "Christmas Day",
            },
            {
                lang: "fr",
                text: "Noël",
            },
        ],
    },
    {
        date: "2021-09-23",
        name: [
            {
                lang: "en",
                text: "Parky Day",
            },
            {
                lang: "fr",
                text: "Noël",
            },
        ],
    },
];
export const sharedReactTableProps = {
    showPageSizeOptions: true,
    // manual: true,
    sortable: false,
    defaultSortDesc: false,
    multiSort: false,
    showPaginationBottom: true,
    showPageJump: false,
    showPaginationTop: false,
    resizable: false,
    classes: "-highlight",
    minRows: 0,
    rowsPerPageOptions: { rowsPerPageOptions: [10, 20, 40, 80, 100] }, // No more than this
};
export const weekDaysList = [
    { label: 'MO', value: 2 },
    { label: 'TU', value: 3 },
    { label: 'WE', value: 4 },
    { label: 'TH', value: 5 },
    { label: 'FR', value: 6 },
    { label: 'SA', value: 7 },
    { label: 'SU', value: 1 }
];
export const monthDaysList = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31];
