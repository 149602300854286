import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchWorkTypes } from "store/modules/workTypes/actions";
import { actions as workTypesActions, selectors as workTypesSelectors } from "store/modules/workTypes";
import WorkTypesActionMenu from "./WorkTypesActionMenu";
import { convertUTCToLocal } from "utils/time";
import ReactTableSimple from "components/ReactTable/ReactTableSimple";

function WorkTypesTable({ onDelete, onEdit }) {

    const dispatch = useDispatch();
    const data = useSelector(workTypesSelectors.getWorkTypesList);
    const isLoading = useSelector(workTypesSelectors.getIsTableLoading);

    useEffect(() => {
        dispatch(fetchWorkTypes());
    }, [])

    return (
        <ReactTableSimple
            classes="-highlight"
            loading={isLoading}
            data={data}
            columns={[
                {
                    Header: "Label",
                    id: "label",
                    disableFilters: true,
                    disableSortBy: true,
                    accessor: ({ label }) => label,
                },
                {
                    Header: "Created",
                    id: "createdAt",
                    disableFilters: true,
                    disableSortBy: true,
                    accessor: ({ createdAtUtc }) => convertUTCToLocal(createdAtUtc, "MMM Do, YYYY"),
                },
                {
                    id: "actions",
                    disableSortBy: true,
                    collapse: true,
                    disableFilters: true,
                    Cell: ({ row: { original } }) => {
                        const workType = {
                            id: original.id,
                            label: original.label,
                            order: original.order,
                            isActive: original.isActive,
                            baseRate: original.baseRate,
                            baseBillingRate: original.baseBillingRate
                        }
                        return (
                            <WorkTypesActionMenu workType={workType} onDelete={onDelete} onEdit={onEdit} />
                        );
                    },
                },
            ]}
        />
    )
}

export default WorkTypesTable
