export default {
  RESET: "jobDetails/RESET",
  SET_LOADING_STATE: "jobDetails/SET_LOADING_STATE",
  SET_IS_CANCEL_JOB_DIALOG_STATE: "jobDetails/SET_IS_CANCEL_JOB_DIALOG_STATE",
  SET_JOB: "jobDetails/SET_JOB",
  SET_JOB_WORKERS: "jobDetails/SET_JOB_WORKERS",
  SET_WORKER_PICKER_LIST_DATA: "jobDetails/SET_WORKER_PICKER_LIST_DATA",
  IS_LOADING_DETAIL: "jobDetails/IS_LOADING_DETAIL",
  UPDATE_POSITIONS: "jobDetails/UPDATE_POSITIONS",
  SET_JOB_TIMELINE_DATA: "jobDetails/SET_JOB_TIMELINE_DATA",
  UPDATE_POSITION: "jobDetails/UPDATE_POSITION",
  IS_ADDING_WORKER: "jobDetails/IS_ADDING_WORKER",
  UPDATE_POSITION_DATA: "jobDetails/UPDATE_POSITION_DATA",
  RESET_POSITION_UPDATES: "jobDetails/RESET_POSITION_UPDATES",
  BATCH_UPDATE_POSITION_DATA: "jobDetails/BATCH_UPDATE_POSITION_DATA",
  REMOVE_POSITION: "jobDetails/REMOVE_POSITION",
  REMOVE_POSITION_BY_POSITION_ID: "jobDetails/REMOVE_POSITION_BY_POSITION_ID",
  UPDATE_BAN_LIST: "jobDetails/UPDATE_BAN_LIST",
  UPDATE_PREFER_LIST: "jobDetails/UPDATE_PREFER_LIST",
  REMOVE_PREFER_BAN: "jobDetails/REMOVE_PREFER_BAN",
  UPDATE_WAITLIST_FILTER: "jobDetails/UPDATE_WAITLIST_FILTER",
  UPDATE_PAYROLL: "jobDetails/UPDATE_PAYROLL",
  UNINITIALIZE_PAYROLL: "jobDetails/UNINITIALIZE_PAYROLL",
  SET_PAYABLE_PAYROLLS: "jobDetails/SET_PAYABLE_PAYROLLS",
  SET_RECURRING_JOB_LIST: "jobDetails/SET_RECURRING_JOB_LIST",
};
