import React from "react";
import { withRouter } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Delete from "@mui/icons-material/Delete";
import MoreVert from "@mui/icons-material/MoreVert";
import { employerJobActionMenuStyles } from "views/Employer/helpers";

class EmployerManagerActionMenu extends React.Component {
  state = {
    anchorEl: null,
  };

  handleClick = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  handleDelete = () => {
    this.props.onDelete();
    this.handleClose();
  };

  render() {
    const { anchorEl } = this.state;
    const { classes } = this.props;

    const id = `action-menu-${this.props.id}`;

    return (
      <React.Fragment>
        <IconButton
          aria-owns={anchorEl ? id : undefined}
          aria-haspopup="true"
          disableRipple
          onClick={this.handleClick}
          size="large">
          <MoreVert color="primary" />
        </IconButton>
        {anchorEl ? (
          <Menu
            id={id}
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={this.handleClose}
            disableAutoFocusItem>
              <MenuItem onClick={this.handleDelete}>
                Delete
              </MenuItem>
          </Menu>
        ) : null}
      </React.Fragment>
    );
  }
}

export default employerJobActionMenuStyles(withRouter(EmployerManagerActionMenu));
