import { gql } from "@apollo/client";

export default gql`
  query getWorkTypes {
    workTypes {
      value: id
      label
    }
  }
`;
