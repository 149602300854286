import { gql } from "@apollo/client";
import PositionWorkerFields from "../fragments/position-worker-fragment";

export default gql`
  query getAllWorkers(
    $first: Int
    $last: Int
    $after: String
    $before: String
    $preferredBy: String
    $employerId: String!
    $jobId: Int!
    $onWaitlist: WaitlistTimeframes
    $canDrive: Boolean
    $canWorkMorning: Boolean
    $canWorkEvening: Boolean
    $order: [WorkerOrderInput!]
    $like: [WorkerFilterInput!]
    $vaccinationStatus: VaccinationStatus
    $tags: [String!]
    $jobTags: [String!]
  ) {
    workers(
      first: $first
      last: $last
      after: $after
      before: $before
      preferredBy: $preferredBy
      positionCountByEmployer: $employerId
      onWaitlist: $onWaitlist
      canDrive: $canDrive
      canWorkMorning: $canWorkMorning
      canWorkEvening: $canWorkEvening
      like: $like
      order: $order
      isActive: true
      isApproved: true
      isDeleted: false
      vaccinationStatus: $vaccinationStatus
      tags: $tags
      jobTags: $jobTags
    ) {
      totalCount
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        node {
          id
          positionCountByEmployer(employerId: $employerId)
          isAvailableForJob(jobId: $jobId)
          ...PositionWorkerFields
        }
      }
    }
  }
  ${PositionWorkerFields}
`;
