/* eslint-disable react/no-find-dom-node */
import React, { useCallback } from "react";
import ReactDOM from "react-dom";
import NumberFormat from "react-number-format";
import { useDispatch, useSelector } from "react-redux";
// import MaskedInput from "react-text-mask";
import { Button as Btn, Button } from "@mui/material";
import DialogActions from "@mui/material/DialogActions";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { CloudUpload } from "@mui/icons-material";

import placeholder from "assets/img/person_placeholder.jpg";
import { Form, Formik } from "formik";
import { profileTabStyles } from "views/Employer/helpers";
import * as yup from "yup";
import { actions as EmployerActions, selectors as EmployerSelectors } from "store/modules/employerDetails";
const isMobile = false;
const controlPadding = {
  leftColumn: { paddingRight: isMobile ? 0 : 4 },
  rightColumn: { paddingLeft: isMobile ? 0 : 4 },
};

const ProfileTab = props => {
  const dispatch = useDispatch();

  const { classes } = props;
  let employerr = useSelector(EmployerSelectors.getEmployerDetails).employer;
  let profilePic =
    employerr && employerr.signedProfileImageUrl ? `${employerr.signedProfileImageUrl}` : placeholder;
  let companyLogo = employerr && employerr.signedLogoImageUrl ? `${employerr.signedLogoImageUrl}` : placeholder;

  const {
    companyName,
    title,
    firstName,
    lastName,
    email,
    mobilePhone,
    street,
    city,
    region,
    country,
    postalCode,
    websiteUrl,
    businessPhone,
  } = employerr;
  const { handleSetCanEdit } = props;

//   const TextMaskCustom = props => {
//     const { inputRef, ...other } = props;

//     return (
//       <MaskedInput
//         {...other}
//         ref={ref => inputRef(ReactDOM.findDOMNode(ref))}
//         mask={[/[A-Z]/i, /\d/, /[A-Z]/i, " ", /\d/, /[A-Z]/i, /\d/]}
//         placeholderChar={"\u2000"}
//       />
//     );
//   };

  const handleLogoFile = useCallback(file => {
    const item = new Blob([file], { type: file.type });
    item.name = `logo_${file.name}`;
    dispatch(EmployerActions.uploadEmployerLogoImage(item));
  }, []);

  const handleUploadFile = useCallback(file => {
    const item = new Blob([file], { type: file.type });
    item.name = `profile_${file.name}`;
    dispatch(EmployerActions.uploadEmployerProfileImage(item));
  }, []);

  const RenderUploadTab = () => (
    <Grid item xs={12} style={{ textAlign: "center" }}>
      <Typography variant="h6" align="left" style={{ paddingLeft: 15, textAlign: "left" }}>
        Company Logo
      </Typography>
      <img src={companyLogo} className="respo" style={{ margin: 10, width: "60%" }} width={"80%"} />
      <Btn color="secondary" icon={<CloudUpload />} variant="outlined" component="label">
        Update Company Logo
        <input type="file" hidden onChange={files => handleLogoFile(files.target.files[0])} />
      </Btn>

      <Typography
        variant="h6"
        align="left"
        style={{ paddingLeft: 15, marginTop: 10, textAlign: "left" }}>
        Profile Photo
      </Typography>
      <img src={profilePic} alt="" style={{ margin: 10, width: "60%" }} />
      <Btn color="secondary" icon={<CloudUpload />} variant="outlined" component="label">
        Update Profile Image
        <input type="file" hidden onChange={files => handleUploadFile(files.target.files[0])} />
      </Btn>
    </Grid>
  );
  const onSubmit = data => {
    dispatch(EmployerActions.saveEmployerProfile(data));
    handleSetCanEdit();
  };
  // useEffect(() => {
  //   profilePic = employerr && employerr.signedProfileImageUrl ? employerr.signedProfileImageUrl : "";
  //   companyLogo = employerr && employerr.signedLogoImageUrl ? employerr.signedLogoImageUrl : "";
  // }, [companyLogo, profilePic]);
  return (
    <Grid
      container
      item
      xs={12}
      alignItems="flex-start"
      style={{ paddingLeft: 0, paddingRight: 0 }}>
      <Grid container item xs={12} alignItems="flex-start" style={controlPadding.leftColumn}>
        <Typography variant="h6" align="left" style={{ width: "100%", marginTop: 1 }}>
          Company Profile
        </Typography>

        <Grid item xs={12} style={{ width: "100%", paddingLeft: 0, paddingRight: 0 }}>
          <Formik
            initialValues={{
              companyName: companyName || '',
              title: title || '',
              firstName: firstName || '',
              lastName: lastName || '',
              email: email || '',
              mobilePhone: mobilePhone || '',
              street: street || '',
              city: city || '',
              region: region || '',
              country: country || '',
              postalCode: postalCode || '',
              websiteUrl: websiteUrl || "",
              businessPhone: businessPhone || '',
            }}
            onSubmit={(values, actions) => {
              onSubmit(values);
              actions.setSubmitting(false);
            }}
            validationSchema={yup.object().shape({
              email: yup.string().email().required("Required"),
              companyName: yup.string().required("Required"),
              title: yup.string().required("Required"),
              firstName: yup.string().required("Required"),
              lastName: yup.string().required("Required"),
              mobilePhone: yup.string().required("Required"),
              postalCode: yup.string().required("Required"),
              websiteUrl: yup.string().required("Required"),
              businessPhone: yup.string().required("Required"),
              street: yup.string().required("Required"),
              city: yup.string().required("Required"),
              region: yup.string().required("Required"),
              country: yup.string().required("Required"),
            })}>
            {props => {
              const {
                values,
                touched,
                errors,
                dirty,
                isSubmitting,
                handleChange,
                handleBlur,

                handleReset,
              } = props;
              return (
                <Form>
                  <Grid
                    item
                    container
                    direction="row"
                    xs={12}
                    style={{ paddingLeft: 0, paddingRight: 0, width: "100%", textAlign: "left" }}>
                    <Grid
                      item
                      container
                      xs={12}
                      md={8}
                      style={{ paddingLeft: 0, paddingRight: 0, textAlign: "left" }}>
                      <Grid item xs={12} md={6} style={controlPadding.leftColumn}>
                        <TextField
                          name="companyName"
                          label="Company Name"
                          className={classes.textField}
                          value={values.companyName}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          fullWidth
                          error={errors.companyName && touched.companyName}
                          helperText={
                            errors.companyName && touched.companyName && errors.companyName
                          }
                          margin="normal"
                        />
                      </Grid>
                      <Grid item xs={12} md={6} style={controlPadding.rightColumn}>
                        <TextField
                          id="title"
                          name="title"
                          label="Title"
                          className={classes.textField}
                          value={values.title}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          fullWidth
                          error={errors.title && touched.title}
                          helperText={errors.title && touched.title && errors.title}
                          margin="normal"
                        />
                      </Grid>
                      <Grid item xs={12} md={6} style={controlPadding.leftColumn}>
                        <TextField
                          id="firstName"
                          name="firstName"
                          label="First Name"
                          className={classes.textField}
                          value={values.firstName}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          fullWidth
                          error={errors.firstName && touched.firstName}
                          helperText={errors.firstName && touched.firstName && errors.firstName}
                          margin="normal"
                        />
                      </Grid>
                      <Grid item xs={12} md={6} style={controlPadding.rightColumn}>
                        <TextField
                          id="lastName"
                          name="lastName"
                          label="Last Name"
                          className={classes.textField}
                          value={values.lastName}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          fullWidth
                          error={errors.lastName && touched.lastName}
                          helperText={errors.lastName && touched.lastName && errors.lastName}
                          margin="normal"
                        />
                      </Grid>
                      <Grid container item xs={12} md={6} style={controlPadding.leftColumn}>
                        <TextField
                          id="email"
                          name="email"
                          label=" Email Address"
                          disabled
                          className={classes.textField}
                          value={values.email}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          fullWidth
                          error={errors.email && touched.email}
                          helperText={errors.email && touched.email && errors.email}
                          margin="normal"
                        />
                      </Grid>
                      <Grid item xs={12} md={6} style={controlPadding.rightColumn}>
                        <TextField
                          id="websiteUrl"
                          name="websiteUrl"
                          label="Website Url"
                          placeholder="https://example.com"
                          className={classes.textField}
                          value={values.websiteUrl}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          fullWidth
                          error={errors.websiteUrl && touched.websiteUrl}
                          helperText={errors.websiteUrl && touched.websiteUrl && errors.websiteUrl}
                          margin="normal"
                        />
                      </Grid>

                      <Grid container item xs={12} md={6} style={controlPadding.leftColumn}>
                      <TextField
                          name="mobilePhone"
                          label="Mobile Phone"
                          className={classes.textField}
                          value={values.mobilePhone}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          fullWidth
                          error={errors.mobilePhone && touched.mobilePhone}
                          helperText={
                            errors.mobilePhone && touched.mobilePhone && errors.mobilePhone
                          }
                          disabled
                          margin="normal"
                        />
                        {/* <NumberFormat
                          id="mobilePhone"
                          name="mobilePhone"
                          label="Mobile Phone"
                          className={classes.textField}
                          value={values.mobilePhone}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          fullWidth
                          error={errors.mobilePhone && touched.mobilePhone}
                          helperText={
                            errors.mobilePhone && touched.mobilePhone && errors.mobilePhone
                          }
                          margin="normal"
                          customInput={TextField}
                          format="+1##########"
                          mask="#"
                        /> */}
                      </Grid>

                      <Grid container item xs={12} md={6} style={controlPadding.rightColumn}>
                        <NumberFormat
                          id="businessPhone"
                          name="businessPhone"
                          label="Business Phone"
                          className={classes.textField}
                          value={values.businessPhone}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          fullWidth
                          error={errors.businessPhone && touched.businessPhone}
                          helperText={
                            errors.businessPhone && touched.businessPhone && errors.businessPhone
                          }
                          margin="normal"
                          customInput={TextField}
                          format="+1##########"
                          mask="#"
                        />
                      </Grid>

                      <Grid item xs={12} md={6} style={controlPadding.leftColumn}>
                        <TextField
                          name="street"
                          label="street"
                          error={errors.street && touched.street}
                          className={classes.textField}
                          value={values.street}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          fullWidth
                          helperText={errors.street && touched.street && errors.street}
                          margin="normal"
                        />
                      </Grid>
                      <Grid item xs={12} md={6} style={controlPadding.rightColumn}>
                        <TextField
                          id="city"
                          name="city"
                          label="City"
                          className={classes.textField}
                          value={values.city}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          fullWidth
                          error={errors.city && touched.city}
                          helperText={errors.city && touched.city && errors.city}
                          margin="normal"
                        />
                      </Grid>
                      <Grid container item xs={12}>
                        <Grid item xs={12} md={4} style={controlPadding.leftColumn}>
                          <TextField
                            select
                            id="region"
                            label="Region"
                            name="region"
                            className={classes.textField}
                            value={values.region}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            fullWidth
                            margin="normal"
                            error={errors.region && touched.region}
                            helperText={errors.region && touched.region && errors.region}>
                            <MenuItem value="BC">British Columbia</MenuItem>
                            <MenuItem value="AB">Alberta</MenuItem>
                            <MenuItem value="ON">Ontario</MenuItem>
                          </TextField>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          md={4}
                          style={{ ...controlPadding.rightColumn, ...controlPadding.leftColumn }}>
                          <TextField
                            id="postalCode"
                            label="Postal Code"
                            error={errors.postalCode && touched.postalCode}
                            className={classes.textField}
                            value={values.postalCode}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            fullWidth
                            helperText={
                              errors.postalCode && touched.postalCode && errors.postalCode
                            }
                            margin="normal"
                            // InputProps={{
                            //   inputComponent: TextMaskCustom,
                            // }}
                          />
                        </Grid>
                        <Grid item xs={12} md={4} style={controlPadding.rightColumn}>
                          <TextField
                            select
                            id="country"
                            name="country"
                            label="Country"
                            className={classes.textField}
                            value={values.country}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            fullWidth
                            margin="normal"
                            error={errors.country && touched.country}
                            helperText={errors.country && touched.country && errors.country}>
                            <MenuItem value="Canada">Canada</MenuItem>
                          </TextField>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} md={4} style={controlPadding.rightColumn}>
                      <RenderUploadTab />
                    </Grid>
                  </Grid>
                  <DialogActions className={classes.footer}>
                    <Button
                      type="button"
                      className="outline"
                      onClick={handleReset}
                      disabled={!dirty || isSubmitting}>
                      Reset
                    </Button>
                    <Button
                      variant="contained"
                      color="secondary"
                      type="submit"
                      // disabled={!_.isEmpty(errors)}
                    >
                      Update Profile
                    </Button>
                  </DialogActions>
                </Form>
              );
            }}
          </Formik>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default profileTabStyles(ProfileTab);
