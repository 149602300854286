import React, { useState } from "react";
import MainContent from "components/Containers/MainContent";
import SubNav from "../../../components/Navigation/SubNav";
import SubNavTab from "../../../components/Navigation/SubNavTab";
import { Assessment } from "@mui/icons-material";
import WorkerJobCountTable from "./components/WorkerJobCountTable";
import WorkerJobCountFilter from "./components/WorkerJobCountFilter";

function WorkerReportView() {
    const [tavValue, setTabValue] = useState(0);
    const handleTabChange = (event, value) => {
        setTabValue(value);
    }
    return (
        <React.Fragment>
            <SubNav
                value={tavValue}
                onChange={handleTabChange}
                tabs={[
                    <SubNavTab key="workerJobCount" label="Worker Job Count" icon={<Assessment />} count={null} />,
                ]}
            />
            <MainContent>
                <WorkerJobCountFilter />
                <WorkerJobCountTable />
            </MainContent>
        </React.Fragment>
    );
}

export default WorkerReportView;
