import React from "react";
import SwipeableViews from "react-swipeable-views";
import PropTypes from "prop-types";
import withStyles from '@mui/styles/withStyles';
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import { numberWithCommas } from "./tables/helpers/tableProps";
import { tabStyles } from "./tables/helpers/tabStyles";
import WaitListTable from "./tables/WaitListTable";
import WaitlistFilters from "./WaitlistFilters";
import WaitlistTabButton from "./WaitlistTabButton";

class WaitlistTabs extends React.Component {
  state = {
    value: 0,
  };

  handleChange = (event, value) => {
    this.setState({ value });
  };

  render() {
    const { classes } = this.props;
    const tabClass = {
      root: classes.tabRoot,
      selected: classes.tabSelected,
    };

    return (
      <React.Fragment>
        <div className={classes.tabWrapper}>
          <Tabs
            style={{ width: "100%" }}
            value={this.state.value}
            onChange={this.handleChange}
            classes={{ indicator: classes.tabIndicator }}>
            <Tab
              label={
                <WaitlistTabButton
                  title={!this.props.waitlistTodayIsLoading ? "Today" : "Today"}
                  isLoading={this.props.waitlistTodayIsLoading}
                  headerClass={classes.tabHeader}
                  labelClass={classes.tabLabel}
                  count={
                    this.props.waitlistTodayTotalCount
                      ? numberWithCommas(this.props.waitlistTodayTotalCount)
                      : 0
                  }
                />
              }
              classes={tabClass}
            />
            <Tab
              label={
                <WaitlistTabButton
                  title={!this.props.waitlistIsLoading ? "Tomorrow" : "Tomorrow"}
                  headerClass={classes.tabHeader}
                  labelClass={classes.tabLabel}
                  count={
                    this.props.waitlistTomorrowTotalCount
                      ? numberWithCommas(this.props.waitlistTomorrowTotalCount)
                      : 0
                  }
                  isLoading={this.props.waitlistTomorrowIsLoading}
                />
              }
              classes={tabClass}
            />
          </Tabs>
        </div>

        <div className={classes.contentContainerNoPad}>
          <div className={classes.createWorkerWrapper}>
            <WaitlistFilters className={classes.tabFilterWrapper} listType={this.state.value} />
          </div>

          <SwipeableViews axis="x" index={this.state.value} onChangeIndex={this.handleChangeIndex}>
            <WaitListTable listType={this.state.value} />
            <WaitListTable listType={this.state.value} />
          </SwipeableViews>
        </div>
      </React.Fragment>
    );
  }
}
WaitlistTabs.propTypes = {
  classes: PropTypes.object.isRequired,
  waitlistTotalCount: PropTypes.number.isRequired,
  waitlistTodayTotalCount: PropTypes.number.isRequired,
  waitlistTodayIsLoading: PropTypes.bool,
  waitlistIsLoading: PropTypes.bool,
};

WaitlistTabs.defaultProps = {
  className: null,
  waitlistTotalCount: 0,
  waitlistTodayTotalCount: 0,
  waitlistTodayIsLoading: false,
  waitlistIsLoading: false,
};
export default withStyles(tabStyles)(WaitlistTabs);
