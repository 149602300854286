import gql from "graphql-tag";

export default gql`
  query managerByEmployerId(
    $employerId: ID
    $isAll: Boolean
  ) {
    managerByEmployerId(
      employerId: $employerId
      isAll: $isAll
    ) {
        id
        title
        isActive
        employerId
        manager{
          id
          email
          firstName
          lastName
          countryCode
          mobilePhone
          signedProfileImageUrl
        }
    }
  }
`;