/* eslint-disable react/jsx-key */
import React from "react";
import { useSelector } from "react-redux";
import { Chip, Tooltip } from "@mui/material";
import Line from "components/Placeholders/Line";
import { selectors as JobDetailSelectors } from "store/modules/jobDetails";
import Can from "components/Authorization/Can";
import { PositionPermissions } from "helpers/constants/permissions";

export default function useTags(disabled, isPrivate) {
    const canAddWorker = useSelector(JobDetailSelectors.getCanAddWorker);
    return {
        Header: "Tag",
        id: "tags",
        filterable: false,
        sortable: false,
        show: isPrivate,
        accessor: ({ tags }) => {
            return tags
        },
        Cell: ({ row }) => {
            return (
                <div>
                    {row.original.tags && row.original.tags.map((n, index) => {
                        if (index < 2) {
                            return <Chip
                                size="small"
                                style={{ margin: '1px' }}
                                label={n}
                            />
                        }
                    })}
                    {row.original.tags && row.original.tags.length > 2 && (
                        <Tooltip title={row.original.tags.map((n, index) => `${index > 1 ? n + (index + 1 != row.original.tags.length ? ', ' : '') : ''}`)} placement="top">
                            <Chip
                                size="small"
                                style={{ margin: '1px' }}
                                label={'More'}
                            />
                        </Tooltip>
                    )}
                </div>
            )
        },
        style: {
            textAlign: "center",
        },
        headerStyle: {
            textAlign: "center",
        },
        Footer: (<Can perform={PositionPermissions.create.fill.value}>
            {allowed => {
                if (!allowed) {
                    return null;
                }
                return canAddWorker && !disabled ? <Line /> : null
            }}
        </Can>)
    };
}
