/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */

import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import SwipeableViews from "react-swipeable-views";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import { CloudDownloadRounded } from "@mui/icons-material";
import { billingServiceStyles, subTransfersActionsStyles } from "views/Finance/helpers";
import { checkWeekInvoices } from "store/modules/finance/actions/billingActions";
import InvoiceBuilder from "./InvoiceBuilder";
import SpreadsheetBuilder from "./SpreadsheetBuilder";
const isMobile = false;
Date.prototype.getWeek = function () {
  var onejan = new Date(this.getFullYear(), 0, 1);
  return Math.ceil(((this - onejan) / 86400000 + onejan.getDay() + 1) / 7) - 1;
};

const BillingService = ({ week, year, getTableData }) => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = React.useState(false);
  const [clearing, setClearing] = React.useState(false);
  const [, setAnchorEl] = React.useState(null);
  const [summary, setSummary] = React.useState(null);
  const [value, setValue] = React.useState(1);
  const currentWeek = new Date().getWeek();

  const handleChange = (event, value) => setValue(value);
  const classes = subTransfersActionsStyles();
  useEffect(() => {
    dispatch(checkWeekInvoices(week, year)).then(res => {
      setSummary(res);
    });
  }, [dispatch, week]);

  const handleFetchSummary = () => {
    dispatch(checkWeekInvoices(week, year)).then(res => setSummary(res));
    // getTableData();
  };
  const tabClass = {
    root: classes.tabRoot,
    // labelContainer: classes.tabLabelContainer,
    selected: classes.tabSelected,
  };

  //   console.log(new Date().getWeek());
  const handleClickOpen = () =>
    dispatch(checkWeekInvoices(week, year)).then(res => {
      setOpen(true);
      setSummary(res);
    });

  const handleClose = () => {
    setAnchorEl(null);
    setOpen(false);
  };

  const RenderLaunchButton = () =>
    isMobile ? (
      <IconButton onClick={handleClickOpen} color="secondary" size="large">
        <CloudDownloadRounded fontSize="large" />
      </IconButton>
    ) : (
      <Button
        variant="contained"
        color="secondary"
        startIcon={<CloudDownloadRounded />}
        size="small"
        onClick={handleClickOpen}>
        {isMobile ? ` ` : `Build Spreadsheet`}
      </Button>
    );

  const RenderCards = () => (
    <Grid container direction="row" alignItems="stretch">
      <Grid container item xs={12}>
        <Tabs value={value} onChange={handleChange}>
          <Tab label={`Weekly Timesheets`} />
          <Tab label="Spreadsheet Downloader" />
        </Tabs>
        <SwipeableViews axis="x" index={value} className={classes.tableContainer}>
          <InvoiceBuilder
            summary={summary}
            week={week ? week : currentWeek}
            year={year}
            handleFetchSummary={handleFetchSummary}
            loading={loading}
            setLoading={setLoading}
            clearing={clearing}
            setOpen={setOpen}
            setClearing={setClearing}
          />
          <SpreadsheetBuilder week={week} year={year} />
        </SwipeableViews>

        {/* <Tab label="Preferred Workers" classes={tabClass} /> */}
      </Grid>
    </Grid>
  );
  const RenderDialogTitle = () => <DialogTitle>Billing Data Manager</DialogTitle>;
  const RenderDialogActions = () => (
    <DialogActions>
      <Button onClick={handleClose} color="primary">
        Close
      </Button>
    </DialogActions>
  );

  return (
    <Grid container item xs={2} md={6} justifyContent="flex-end">
      <>
        <RenderLaunchButton />
        <Dialog disableEscapeKeyDown open={open} onClose={handleClose} maxWidth="md">
          <RenderDialogTitle /> <RenderCards /> <RenderDialogActions />
        </Dialog>
      </>
    </Grid>
  );
};

export default billingServiceStyles(BillingService);
