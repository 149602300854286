import { Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import ReactTableSimple from 'components/ReactTable/ReactTableSimple';
import React, { useEffect, useState } from 'react'
import { convertUTCToTimezoneWithOffset, displayLocalDateTime } from 'utils/time';
const useStyles = makeStyles
    (({ spacing, palette, shape }) => ({
        visibility: {
            padding: spacing(3),
            "@media (max-width: 600px)": {
                padding: spacing(1),
            },
        },
    }));
function Announcement({ announcement, timeZone }) {
    const classes = useStyles()
    const [flatData, setFlatData] = useState([]);

    useEffect(() => {
        setFlatData(announcement.flatMap((d) => d?.jobAnnouncementTime.map(j => ({
            ...j,
            title: d.title,
            description: d.description,
            timezone: timeZone,
            createdBy: d.managerId ? d.manager.firstName + " " + d.manager.lastName : d.employer.fullName
        }))));
    }, []);
    return (
        <Grid container className={classes.visibility}>
            <ReactTableSimple
                data={flatData}
                style={{ width: "100%" }}
                columns={[
                    {
                        Header: "Title",
                        accessor: "title",
                        disableSortBy: true,
                        className: "-cursor-pointer"
                    },
                    {
                        Header: "Description",
                        accessor: "description",
                        disableSortBy: true,
                        className: "-cursor-pointer"
                    },
                    {
                        Header: "Created By",
                        id: "createdBy",
                        Cell: ({ row: { original } }) => {
                            return original.createdBy || ''
                        },
                        accessor: "createdBy",
                        styles: {
                            minWidth: '125px'
                        },
                        disableSortBy: true,
                        className: "-cursor-pointer",
                    },
                    {
                        Header: "Send At",
                        id: "announcementStartDate",
                        Cell: ({ row: { original } }) => {
                            return (
                                <div>
                                    {convertUTCToTimezoneWithOffset(original.sendAt, original.timezone, "MMM Do, YYYY hh:mm a")}
                                    <br />
                                    {displayLocalDateTime(original.sendAt, original.timezone, "MMM Do, YYYY")}
                                </div>
                            )
                        },
                        accessor: "sendAt",
                        disableSortBy: true,
                        className: "-cursor-pointer",
                        styles: {
                            minWidth: '150px'
                        },
                    },
                ]}
            />
        </Grid>
    )
}

export default Announcement
