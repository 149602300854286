import React, { Fragment, useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import moment from "moment-timezone";
import classNames from "classnames";
import Avatar from "@mui/material/Avatar";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import EditIcon from '@mui/icons-material/Edit';
import Skeleton from '@mui/material/Skeleton';
import { payrollSummaryDialogStyles } from "views/Finance/helpers";
import Button from "components/Button/Button";
import Line from "components/Placeholders/Line";
import { formatMoney, isValidRateNumber } from "utils/stringUtils";
import { convertUTCToTimezoneWithOffset, formatDurationFromMins } from "utils/time";
import Popover from "@mui/material/Popover";
import TextField from "@mui/material/TextField";
import { actions as FinanceActions, selectors as FinanceSelectors } from "store/modules/finance";
import { actions as JobDetailActions, selectors as JobDetailSelectors } from "store/modules/jobDetails";
import ReactTableSimple from "components/ReactTable/ReactTableSimple";

function WarningTitle({ values }) {
    return (
        <>
            {values.map((v, i) => (
                <p key={i}>{v}</p>
            ))}
        </>
    );
}
function PayrollSummaryDialog({ visible, onCancel, onConfirm, jobId }) {
    const classes = payrollSummaryDialogStyles();
    const dispatch = useDispatch();
    const [position, setPosition] = useState(false);
    const [newPayRate, setNewPayRate] = useState(0);
    const companyName = useSelector(JobDetailSelectors.getCompanyName);
    const start = useSelector(JobDetailSelectors.getJobStartUtc);
    const end = useSelector(JobDetailSelectors.getJobEndUtc);
    const positions = useSelector(JobDetailSelectors.getPositions);
    const breakInfoLabel = useSelector(JobDetailSelectors.getJobBreakInfoLabel);
    const isInitializingPayroll = useSelector(JobDetailSelectors.getIsInitializingJobPayroll);
    const isUninitializingPayroll = useSelector(JobDetailSelectors.getIsUninitializingJobPayroll);
    const isPaying = useSelector(JobDetailSelectors.getIsPayingJob);
    const isPayingPosition = useSelector(FinanceSelectors.getIsSendingPay);
    const payrollSummary = useSelector(JobDetailSelectors.getPayrollSummary);
    const [isPayingPayroll, setIsPayingPayroll] = useState(isPayingPosition);

    // if (isInitializingPayroll) {
    //   if (positions.length > 15) {
    //     payrollSummary = [{}, {}, {}, {}, {}];
    //   }
    // }

    useEffect(() => {
        setIsPayingPayroll(isPayingPosition);
    }, [isPayingPosition]);

    useEffect(() => {
        if (visible && jobId) {
            dispatch(JobDetailActions.setIsInitializingJobPayroll(true));
            dispatch(JobDetailActions.fetchJob(jobId))
                .then(() => {
                    dispatch(JobDetailActions.initializeJobPayrollDetail(jobId)).catch(() => {
                        onCancel()
                        dispatch(JobDetailActions.setIsInitializingJobPayroll(false));
                    });
                })
                .catch(() => {
                    onCancel()
                    dispatch(JobDetailActions.setIsInitializingJobPayroll(false));
                });
        }
    }, [visible, dispatch]);

    const netAmount = useMemo(() => {
        return payrollSummary.reduce((acc, { netPay }) => acc + netPay, 0);
    }, [payrollSummary]);

    // const getWarningProps = useCallback(
    //   (_, { original }, column) => {
    //     const warning = original.warnings?.some(node => node.field === column.id);
    //     if (warning) {
    //       return { className: classes.warning };
    //     }
    //     return {};
    //   },
    //   [classes]
    // );

    async function handleCancel() {
        // await dispatch(JobDetailActions.uninitializeJobPayroll());
        onCancel();
    }

    async function payPosition(id) {
        await dispatch(FinanceActions.payPosition(id)).then(() => {
            dispatch(FinanceActions.fetchPaymentsOverview())
        }).catch(() => onCancel());

        await onCancel();
    }
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event, data) => {
        setPosition(data)
        setNewPayRate(Math.round(data.payRate * 100) / 100);
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;
    const handleEditRates = () => {
        dispatch(JobDetailActions.editWorkerRatesFromDialog(position.id, Number(newPayRate), position.jobId, position.workerId)).then(res => {
            dispatch(JobDetailActions.initializeJobPayrollDetail(jobId)).catch(() => onCancel());
            handleClose();
        });
    };

    const columns = [
        {
            // eslint-disable-next-line react/display-name, react/prop-types
            Header: () => {
                if (isInitializingPayroll) {
                    return <Skeleton variant="text" />;
                }
                return null;
            },
            id: "profileImage",
            // eslint-disable-next-line react/display-name, react/prop-types
            accessor: ({ worker }) => {
                if (isInitializingPayroll) {
                    return <Skeleton variant="circular" height={40} width={40} />;
                }
                return <Avatar src={worker.signedProfileImageUrl} />;
            },
            disableSortBy: true,
            diableFilters:true,
            width: 70,
        },
        {
            Header: () => {
                if (isInitializingPayroll) {
                    return <Skeleton variant="text" />;
                }
                return "Worker";
            },
            id: "firstName",
            accessor: ({ worker }) => {
                if (isInitializingPayroll) {
                    return <Skeleton variant="text" />;
                }
                return (worker.firstName ? worker.firstName : '') + ' ' + (worker.lastName ? worker.lastName : '');
            },
            disableSortBy: isInitializingPayroll,
        },
        {
            Header: () => {
                if (isInitializingPayroll) {
                    return <Skeleton variant="text" />;
                }
                return "Checked In";
            },
            id: "startShift",
            styles: { minWidth: 150, },
            accessor: ({ startShiftUtc }) => {
                if (isInitializingPayroll) {
                    return <Skeleton variant="text" />;
                }
                return convertUTCToTimezoneWithOffset(startShiftUtc, '', 'MMM Do h:mm a');;
            },
            disableSortBy: isInitializingPayroll,
        },
        {
            Header: () => {
                if (isInitializingPayroll) {
                    return <Skeleton variant="text" />;
                }
                return "Checked Out";
            },
            id: "endShiftUtc",
            styles: { minWidth: 150 },
            accessor: ({ endShiftUtc }) => {
                if (isInitializingPayroll) {
                    return <Skeleton variant="text" />;
                }
                const end = convertUTCToTimezoneWithOffset(endShiftUtc, '', "MMM Do h:mm a");
                return end;
            },
            disableSortBy: isInitializingPayroll,
        },
        {
            Header: () => {
                if (isInitializingPayroll) {
                    return <Skeleton variant="text" />;
                }
                return "Break";
            },
            id: "breakMins",
            styles: { minWidth: 80 },
            accessor: ({ breakMins }) => {
                if (isInitializingPayroll) {
                    return <Skeleton variant="text" />;
                }
                const breakLabel =
                    breakMins === null || breakMins === undefined ? "-" : `${breakMins} mins`;
                return breakLabel;
            },
            disableSortBy: isInitializingPayroll,
        },
        {
            Header: () => {
                if (isInitializingPayroll) {
                    return <Skeleton variant="text" />;
                }
                return "Duration";
            },
            id: "durationMins",
            minWidth: 100,
            accessor: ({ durationMins }) => {
                if (isInitializingPayroll) {
                    return <Skeleton variant="text" />;
                }
                const duration = !durationMins
                    ? "-"
                    : formatDurationFromMins(durationMins, {
                        hourLabel: "hrs",
                        minuteLabel: "mins",
                        separator: "",
                    });
                return duration;
            },
            disableSortBy: isInitializingPayroll,
        },
        {
            Header: () => {
                if (isInitializingPayroll) {
                    return <Skeleton variant="text" />;
                }
                return "Rate";
            },
            id: "payRate",
            styles: { minWidth: 150 },
            accessor: (row) => {
                const { payRate } = row;

                if (isInitializingPayroll) {
                    return <Skeleton variant="text" />;
                }
                return (
                    <div className={classes.rateSection}>
                        <EditIcon onClick={(e) => handleClick(e, row)} className={classes.editIcon} fontSize="small" />
                        <span className={classes.rateText}>{formatMoney(payRate)}</span>
                    </div>
                );
            },
            disableSortBy: isInitializingPayroll,
        },
        {
            Header: () => {
                if (isInitializingPayroll) {
                    return <Skeleton variant="text" />;
                }
                return "Bonus";
            },
            id: "bonus",
            styles: { minWidth: 80 },
            accessor: ({ bonus }) => {
                if (isInitializingPayroll) {
                    return <Skeleton variant="text" />;
                }
                const bonusLabel = !bonus ? "-" : formatMoney(bonus);
                return bonusLabel;
            },
            // show: showBonusColumn,
            disableSortBy: isInitializingPayroll,
        },
        {
            Header: () => {
                if (isInitializingPayroll) {
                    return <Skeleton variant="text" />;
                }
                return "Gross";
            },
            id: "grossPay",
            width: 90,
            accessor: ({ grossPay }) => {
                if (isInitializingPayroll) {
                    return <Skeleton variant="text" />;
                }
                return formatMoney(grossPay);
            },
            disableSortBy: isInitializingPayroll,
        },
        {
            Header: () => {
                if (isInitializingPayroll) {
                    return <Skeleton variant="text" />;
                }
                return "Net";
            },
            id: "netPay",
            width: 60,
            accessor: ({ netPay }) => {
                if (isInitializingPayroll) {
                    return <Skeleton variant="text" />;
                }
                return formatMoney(netPay);
            },
            disableSortBy: isInitializingPayroll,
        },
        {
            Header: () => {
                return "";
            },
            id: "id",
            width: 120,
            accessor: ({ id }) => {
                if (isInitializingPayroll) {
                    return <Skeleton variant="text" />;
                }
                return (
                    <Fragment>
                        <Button
                            label="Pay"
                            onClick={() => payPosition(id)}
                            color="secondary"
                            buttonClassName={classes.updateBtn}
                            isLoading={isPaying || isPayingPosition}
                            size={'small'}
                            disabled={isInitializingPayroll}
                        />
                        {/*<IconButton edge="end" onClick={() => payPosition(id)}>
              <Tooltip title={`Create payroll & Send eTransfer for this worker.`} placement="top">
                <MonetizationOnTwoTone className={classes.payIcon} />
              </Tooltip>
            </IconButton>*/}
                    </Fragment>
                );
            },
            disableSortBy: true,
        },
    ];

    return (
        <Fragment>
            <Dialog fullWidth classes={{ paper: classes.modal }} open={visible}>
                <DialogTitle className={classes.title}>
                    <Grid container justifyContent="space-between">
                        <Grid item>
                            <Typography variant="h5" align="left">
                                {`Payroll Summary for Job #${jobId}`}
                            </Typography>
                            <Typography variant="h4" align="left">
                                {companyName}
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography variant="subtitle1" align="right">
                                {moment(start).format("dddd, MMMM Do, YYYY")}
                            </Typography>
                            <Typography variant="body1" align="right">
                                {`${moment(start).format("h:mm a")} - ${moment(end).format("h:mm a")}`}
                            </Typography>
                            <span style={{ fontSize: "12px", fontWeight: "400" }}>initializeJobPayrollDetail</span>{breakInfoLabel && (
                                <Typography variant="body1" align="right">
                                    {breakInfoLabel}
                                </Typography>
                            )}
                        </Grid>
                    </Grid>
                </DialogTitle>
                <DialogContent className={classes.content}>
                    {payrollSummary ? (
                        <ReactTableSimple
                            hidePagination
                            style={{ boxShadow: "none", border: "none" }}
                            classes={classNames("-striped", classes.table)}
                            data={[...payrollSummary]}
                            columns={columns}
                        />
                    ) : (
                        "yo!"
                    )}
                </DialogContent>
                <Grid container justifyContent="flex-end" className={classes.totalContainer}>
                    <Grid item className={classes.totalLabel}>
                        <Typography variant="subtitle1" align="right">
                            {"Total"}
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Line className={classes.totalLine} />
                        <Typography variant="h4">{formatMoney(netAmount)}</Typography>
                    </Grid>
                </Grid>
                <DialogActions className={classes.footer}>
                    <Button
                        label="Cancel"
                        onClick={handleCancel}
                        color="transparent"
                        isLoading={isUninitializingPayroll}
                        disabled={isInitializingPayroll || isPaying || isPayingPayroll || isPayingPosition}
                    />
                    <Button
                        label="Confirm & Pay"
                        onClick={onConfirm}
                        color="secondary"
                        isLoading={isPaying || isPayingPayroll || isPayingPosition}
                        disabled={isInitializingPayroll || isUninitializingPayroll}
                    />
                </DialogActions>
            </Dialog>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <div style={{ padding: 10 }}>
                    <Typography className={classes.typography}>Payrate</Typography>
                    <TextField id="standard-basic" type="number" value={newPayRate} onChange={(e) => setNewPayRate(e.target.value)} />
                    <br />
                    <Button
                        label="Update"
                        disabled={!newPayRate}
                        onClick={() => handleEditRates()}
                        buttonClassName={classes.updateBtn}
                        color="secondary"
                        size={'small'}
                        style={{ paddingTop: 10, paddingLeft: 0 }}
                    />
                </div>
            </Popover>
        </Fragment>
    );
}
PayrollSummaryDialog.propTypes = {
    visible: PropTypes.bool,
    onCancel: PropTypes.func,
    onConfirm: PropTypes.func,
};
PayrollSummaryDialog.defaultProps = {
    visible: false,
    onCancel: () => { },
    onConfirm: () => { },
};
export default PayrollSummaryDialog;
