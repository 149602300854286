/* eslint-disable no-inner-declarations */
/* eslint-disable react/display-name */
/* eslint-disable react/prop-types */
import React, { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Line from "components/Placeholders/Line";
import { actions as JobDetailActions, selectors as JobDetailSelectors } from "store/modules/jobDetails";
import DisableCellOverlay from "../../components/Controls/DisableCellOverlay";
import "../../components/Controls/css.css";
import { convertUTCToTimezone, convertUTCToTimezoneWithOffset } from "../../../../../../../../../utils/time";
import { MobileDateTimePicker } from '@mui/x-date-pickers';
import { AdapterMoment as MomentUtils } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TextField } from "@mui/material";
import { useStyles } from "views/Job/JobDetails/styles";
import Can from "components/Authorization/Can";
import { PositionPermissions } from "helpers/constants/permissions";

export default function useCheckIn(isCheckingInAll, disabled, timezone) {
    const dispatch = useDispatch();
    const classes = useStyles();
    const canAddWorker = useSelector(JobDetailSelectors.getCanAddWorker);
    const jobStartUtc = useSelector(JobDetailSelectors.getJobStartUtc);
    const [batchCheckinValue, setBatchCheckinValue] = useState(convertUTCToTimezone(jobStartUtc, timezone));

    const handleBatchApply = time => {
        setBatchCheckinValue(time);
        dispatch(JobDetailActions.batchUpdatePositionData("startShiftUtc")(time));
    };

    const renderCell = useCallback((p) => {
        return <RenederCell {...p} extraData={{ isCheckingInAll, disabled, timezone }} />;
    }, [isCheckingInAll, disabled, timezone]);

    return {
        Header: "Check in",
        id: "startShift",
        filterable: isCheckingInAll,
        sortable: false,
        style: isCheckingInAll ? { position: "unset" } : {},
        headerStyle: isCheckingInAll ? { position: "unset" } : {},
        // width: 170,
        Filter: () => {
            const pickerProps = {
                hideTabs: true,
                error: false,
                autoOk: true,
                emptyLabel: ",",
                DialogProps: { className: classes.dateTimePicker },
                invalidLabel: ",",
                inputFormat: "hh:mm a",
                renderInput: (props) => <TextField {...props} />,
                value: batchCheckinValue,
                onChange: date => handleBatchApply(date),
            };
            return (
                <LocalizationProvider dateAdapter={MomentUtils}>
                    <MobileDateTimePicker {...pickerProps} />
                </LocalizationProvider>
            );
        },
        Cell: renderCell,
        Footer: (<Can perform={PositionPermissions.create.fill.value}>
            {allowed => {
                if (!allowed) {
                    return null;
                }
                return canAddWorker && !disabled ? <Line /> : null
            }}
        </Can>)
    };
};

const RenederCell = ({ row: { original }, extraData: { isCheckingInAll, disabled, timezone }, ...props }) => {
    const positionUpdates = useSelector(JobDetailSelectors.getPositionUpdates);
    const classes = useStyles();
    const dispatch = useDispatch();

    if (isCheckingInAll && original.isEditable) {

        const updatePositionCheckin = JobDetailActions.updatePositionData("startShiftUtc");

        function onChange(date, original) {
            dispatch(updatePositionCheckin(original.id, date));
        };

        const { startShiftUtc } = positionUpdates.find(
            ({ id }) => id === original.id,
        );

        const pickerProps = {
            hideTabs: true,
            closeOnSelect: false,
            error: false,
            readOnly: !(isCheckingInAll && original.isEditable),
            disabled: !(isCheckingInAll && original.isEditable),
            autoOk: true,
            emptyLabel: ",",
            invalidLabel: ",",
            DialogProps: { className: classes.dateTimePicker },
            inputFormat: "hh:mm a",
            renderInput: (props) => <TextField {...props} />,
            value: convertUTCToTimezone(startShiftUtc, timezone),
            onChange: date => onChange(date, original),
        };
        return (
            <>
                <LocalizationProvider dateAdapter={MomentUtils}>
                    <MobileDateTimePicker {...pickerProps} />
                </LocalizationProvider>
            </>
        );
    }

    return (
        <div style={{ position: "relative" }}>
            {!original.startShift ? (
                <Line />
            ) : convertUTCToTimezoneWithOffset(original.startShiftUtc, timezone)}
            <DisableCellOverlay visible={disabled || (isCheckingInAll && !original.isEditable)} />
        </div>
    );
}
