import { gql } from "@apollo/client";

// first is zero because we only want to return the totals and no specific info
export default gql`
  query getJobTimeframeStats($employerId: String, $timezone: String) {
    todayCount: jobs(
      first: 0
      timeframe: Today
      isActive: true
      employerId: $employerId
      timezone: $timezone
    ) {
      totalCount
    }
    tomorrowCount: jobs(
      first: 0
      timeframe: Tomorrow
      isActive: true
      employerId: $employerId
      timezone: $timezone
    ) {
      totalCount
    }
    upcomingCount: jobs(
      first: 0
      timeframe: Upcoming
      isActive: true
      employerId: $employerId
      timezone: $timezone
    ) {
      totalCount
    }
    draftCount: jobs(first: 0, isActive: false, isQueued: false, employerId: $employerId, timezone: $timezone) {
      totalCount
    }
    queueCount: jobs(first: 0, isActive: false, isQueued: true, employerId: $employerId, timezone: $timezone) {
      totalCount
    }
  }
`;
