/* eslint-disable react/display-name */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";
import "moment-timezone";
import { useDispatch, useSelector } from "react-redux";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { formatMoney } from "utils/stringUtils";
import { actions as FinanceActions, selectors as FinanceSelectors } from "store/modules/finance";

import { dcTransfersHeaderStyles } from "../../../helpers/styles";

function DcTransfersHeader() {
  const classes = dcTransfersHeaderStyles();
  const dispatch = useDispatch();

  const [searchTransactionId] = useState(null);
  const [searchCustomerNumber] = useState(null);
  const dcAccount = useSelector(FinanceSelectors.getDcAccount);
  const [searchStatus] = useState("All");

/*  const reversalStatuses = [
    { id: 3, label: "Awaiting Response" },
    // { id: 5, label: "Delivered" },
    { id: 6, label: "Accepted" },
    { id: 7, label: "Declined" },
    { id: 8, label: "Failed" },
    { id: 13, label: "Manually Resolved" },
    { id: 9, label: "Retried" },
    { id: 11, label: "Cancelled" },
    { id: 5, label: "Completed" },
  ];*/

  useEffect(() => {
    handleFindTransfers();
  }, [searchTransactionId, searchStatus, searchCustomerNumber]);

/*  const handleSetField = ({ target: { name, value } }) => {
    switch (name) {
      case "status": {
        setSearchStatus(value);
        return;
      }
      case "customerNumber": {
        setSearchCustomerNumber(value);
        return;
      }
      case "transactionId": {
        setSearchTransactionId(value);
        return;
      }

      default:
        return;
    }
  };*/

  const handleFindTransfers = () =>{
      dispatch(FinanceActions.setDcTransfersParams({
          customerNumber: searchCustomerNumber || null,
          status: searchStatus === "All" ? null : searchStatus,
          dcTransferId: searchTransactionId || null,
      }));
      dispatch(FinanceActions.fetchTransfersNew());
  }
  const RenderHeader = () => (
    <Grid className={classes.titleContainer} container item xs={12}>
      <Grid container item xs={6} md={6} direction="column" justifyContent="center">
        <Typography variant="h6">{`eTransfer Requests`}</Typography>
        <Typography>{`Account Balance: ${formatMoney(dcAccount.Balance)}`}</Typography>
        <Typography>{`Funds Available: ${formatMoney(dcAccount.AvailableBalance)}`}</Typography>
      </Grid>

      {/*<Grid container item xs={6} md={6} direction="row" justifyContent="flex-end" alignItems="flex-start">
        <Typography
          variant="subtitle2"
          className={classes.searchInputLabel}>{`Transfer Status`}</Typography>
        <Select
          labelId="Status"
          id="status"
          name="status"
          value={searchStatus}
          className={classes.input}
          style={{ maxWidth: 170, marginLeft: 8 }}
          onChange={handleSetField}>
          <MenuItem value="All">All</MenuItem>
          {reversalStatuses.map((status, i) => (
            <MenuItem key={i} value={status.id}>
              {status.label}
            </MenuItem>
          ))}
        </Select>
      </Grid>*/}
    </Grid>
  );

  return <RenderHeader />;
}

export default DcTransfersHeader;
