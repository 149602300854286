import React from "react";
import Slide from "@mui/material/Slide";
import makeStyles from '@mui/styles/makeStyles';
const isMobile = false;
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" forwardRef={ref} {...props} />;
});

export const pieData = [
  {
    id: "erlang",
    label: "erlang",
    value: 150,
    color: "hsl(225, 70%, 50%)",
  },
  {
    id: "lisp",
    label: "lisp",
    value: 537,
    color: "hsl(69, 70%, 50%)",
  },
  {
    id: "make",
    label: "make",
    value: 160,
    color: "hsl(353, 70%, 50%)",
  },
  {
    id: "c",
    label: "c",
    value: 841,
    color: "hsl(278, 70%, 50%)",
  },
  {
    id: "rust",
    label: "rust",
    value: 476,
    color: "hsl(324, 70%, 50%)",
  },
];

export const data = [
  {
    id: "erlang",
    label: "erlang",
    value: 110,
    color: "hsl(353, 70%, 50%)",
  },
  {
    id: "lisp",
    label: "lisp",
    value: 237,
    color: "hsl(69, 70%, 50%)",
  },
  {
    id: "make",
    label: "make",
    value: 360,
    color: "hsl(225, 70%, 50%)",
  },
  {
    id: "c",
    label: "c",
    value: 441,
    color: "hsl(324, 70%, 50%)",
  },
  {
    id: "rust",
    label: "rust",
    value: 576,
    color: "hsl(278, 70%, 50%)",
  },
];

export const qbIcons = {
  explorer: "https://static.developer.intuit.com/images/api-explorer.svg",
  invoice: "https://static.developer.intuit.com/images/group-19.svg",
  reports: "https://static.developer.intuit.com/images/business-analytics.svg",
  paidInvoice: "https://static.developer.intuit.com/images/pay-bills.svg",
  qbLogo:
    "https://lh3.googleusercontent.com/VeIdX5c44a0s2MNxtxW7R4nVXgg0cK_nYNB5sJCJUM82dLn4B9edVwQY3MQzmWBIgkA_=s128-rw",
};

export const dialogProps = {
  PaperProps: {
    style: {
      padding: 15,
      paddingBottom: 5,
      fontSize: 12,
      color: "#fff",
      fontWeight: 700,
      // backgroundColor: "transparent",
      //   boxShadow: "none",
    },
  },
  maxWidth: "md",
  fullScreen: isMobile,
  disableBackdropClick: true,
  fullWidth: true,
  TransitionComponent: Transition,
};

export const colors = ["#92edb9", "#b6f7cc", "#268c52", "#37c172", "#69dd9b", "#2ea862"];

export const chartProps = {
  margin: { top: 20, right: 30, bottom: 80, left: 30 },
  innerRadius: 0.2,

  padAngle: 2,
  valueFormat: " > $01,.0~s",

  cornerRadius: 3,
  //   colors: { scheme: "blue_green" },
  colors: colors,
  borderWidth: 1,
  borderColor: { from: "color", modifiers: [["darker", 0.2]] },
  radialLabelsSkipAngle: 10,
  radialLabelsTextColor: "#333333",
  radialLabelsLinkColor: { from: "color" },
  sliceLabelsSkipAngle: 10,
  sliceLabelsTextColor: "#333333",

  //   legends: [
  //     {
  //       anchor: "bottom",
  //       direction: "row",
  //       justify: true,
  //       translateX: 0,
  //       translateY: 56,
  //       itemsSpacing: 0,
  //       itemWidth: 100,
  //       itemHeight: 18,
  //       itemTextColor: "#999",
  //       itemDirection: "left-to-right",
  //       itemOpacity: 1,
  //       symbolSize: 18,
  //       symbolShape: "circle",
  //       effects: [
  //         {
  //           on: "hover",
  //           style: {
  //             itemTextColor: "#000",
  //           },
  //         },
  //       ],
  //     },
  //   ],
};

export const deductionTypes = [
  {
    id: 1,
    countryId: "1",
    regionId: "NULL",
    shortName: "EI",
    longName: "Employment Insurance",
    code: "EE",
  },
  {
    id: 2,
    countryId: "1",
    regionId: "NULL",
    shortName: "Employer EI",
    longName: "Employer Employment Insurance Contribution",
    code: "ER",
  },
  {
    id: 3,
    countryId: "1",
    regionId: "NULL",
    shortName: "CPP",
    longName: "Canadian Pension Plan",
    code: "EE",
  },
  {
    id: 4,
    countryId: "1",
    regionId: "NULL",
    shortName: "Employer CPP",
    longName: "Employer Canadian Pension Plan Contribution",
    code: "ER",
  },
  {
    id: 5,
    countryId: "1",
    regionId: "NULL",
    shortName: "Federal Tax",
    longName: "Federal Tax",
    code: "EE",
  },
  {
    id: 6,
    countryId: "1",
    regionId: "2",
    shortName: "BC Provincial Tax",
    longName: "British Columbia Provincial Tax",
    code: "EE",
  },
  {
    id: 7,
    countryId: "1",
    regionId: "2",
    shortName: "EHT",
    longName: "Employer Health Tax",
    code: "ER",
  },
  {
    id: 8,
    countryId: "1",
    regionId: "2",
    shortName: "WCB",
    longName: "WorkSafeBC",
    code: "ER",
  },
];

export const earningTypes = [
  {
    id: 1,
    name: "Standard",
    rateModifier: "1.000",
    code: "STD",
  },
  {
    id: 2,
    name: "Stat Standard",
    rateModifier: "1.500",
    code: "STATSTD",
  },
  {
    id: 3,
    name: "Overtime (1.5x)",
    rateModifier: "1.500",
    code: "TAAH",
  },
  {
    id: 4,
    name: "Double Time (2x)",
    rateModifier: "2.000",
    code: "DT",
  },
  {
    id: 5,
    name: "Vacation",
    rateModifier: "0.040",
    code: "STDVAC",
  },
  {
    id: 6,
    name: "Bonus",
    rateModifier: "1.000",
    code: "BNS",
  },
];

export const statuses = [
  {
    id: 1,
    dcCode: "P",
    code: "QUEUE",
    description: "Queued",
  },

  {
    id: 3,
    dcCode: "S",
    code: "WAIT_RESP",
    description: "Awaiting Response",
  },

  {
    id: 5,
    dcCode: "C",
    code: "DELIVERED",
    description: "Delivered",
  },

  {
    id: 7,
    dcCode: "RJ",
    code: "DECLINED",
    description: "Declined",
  },
  {
    id: 8,
    dcCode: "E",
    code: "FAILED",
    description: "Failed",
  },

  {
    id: 11,
    dcCode: "V",
    code: "CANCELLED",
    description: "Cancelled",
  },

  {
    id: 14,
    dcCode: "DF",
    code: "DEFERRED",
    description: "Deferred",
  },
  {
    id: 13,
    code: "MANUAL_RESOLVED",
    dcCode: "MR",
    description: "Manually Resolved",
  },
];

export const pdfDocumentDefaultProps = {
  title: "Grizzly Force Weekly Invoice",
  subject: "Weekly Invoice",
  keywords: "Weekly Invoice",
  creator: "Weekly Invoice",
  producer: "Weekly Invoice",
};

export const weeks = [
  1,
  2,
  3,
  4,
  5,
  6,
  7,
  8,
  9,
  10,
  11,
  12,
  13,
  14,
  15,
  16,
  17,
  18,
  19,
  20,
  21,
  22,
  23,
  24,
  25,
  26,
  27,
  28,
  29,
  30,
  31,
  32,
  33,
  34,
  35,
  36,
  37,
  38,
  39,
  40,
  41,
  42,
  43,
  44,
  45,
  46,
  47,
  48,
  49,
  50,
  51,
  52,
  53,
];
export const useStyles = makeStyles(
  ({ palette, shape, transitions, breakpoints, spacing, typography }) => ({
    tableStyles: {
      /* Styles applied to the root element. */
      root: {
        ...typography.body2,
        display: "table-cell",
        verticalAlign: "inherit",
        // Workaround for a rendering bug with spanned columns in Chrome 62.0.
        // Removes the alpha (sets it to 1), and lightens or darkens the theme color.
        borderBottom: `1px solid lightgrey`,
        textAlign: "left",
        padding: 16,
      },
      /* Styles applied to the root element if `variant="head"` or `context.table.head`. */
      head: {
        color: palette.text.primary,
        lineHeight: typography.pxToRem(24),
        fontWeight: typography.fontWeightMedium,
      },
      /* Styles applied to the root element if `variant="body"` or `context.table.body`. */
      body: {
        color: palette.text.primary,
      },
      /* Styles applied to the root element if `variant="footer"` or `context.table.footer`. */
      footer: {
        color: palette.text.secondary,
        lineHeight: typography.pxToRem(21),
        fontSize: typography.pxToRem(12),
      },
      /* Styles applied to the root element if `size="small"`. */
      sizeSmall: {
        padding: "6px 12px 6px 8px",
        "&:last-child": {
          paddingRight: 8,
        },
        "&$paddingCheckbox": {
          width: 24, // prevent the checkbox column from growing
          padding: "0 12px 0 16px",
          "&:last-child": {
            paddingLeft: 12,
            paddingRight: 16,
          },
          "& > *": {
            padding: 0,
          },
        },
      },
      /* Styles applied to the root element if `padding="checkbox"`. */
      paddingCheckbox: {
        width: 48, // prevent the checkbox column from growing
        padding: "0 0 0 4px",
        "&:last-child": {
          paddingLeft: 0,
          paddingRight: 4,
        },
      },
      /* Styles applied to the root element if `padding="none"`. */
      paddingNone: {
        padding: 0,
        "&:last-child": {
          padding: 0,
        },
      },
      /* Styles applied to the root element if `align="left"`. */
      alignLeft: {
        textAlign: "left",
      },
      /* Styles applied to the root element if `align="center"`. */
      alignCenter: {
        textAlign: "center",
      },
      /* Styles applied to the root element if `align="right"`. */
      alignRight: {
        textAlign: "right",
        flexDirection: "row-reverse",
      },
      /* Styles applied to the root element if `align="justify"`. */
      alignJustify: {
        textAlign: "justify",
      },
      /* Styles applied to the root element if `context.table.stickyHeader={true}`. */
      stickyHeader: {
        position: "sticky",
        top: 0,
        left: 0,
        zIndex: 2,
        backgroundColor: palette.background.default,
      },
    },
    qbPreferences: {
      listItem: { padding: 0, paddingBottom: 0 },
    },
    icon: {
      fontSize: typography.pxToRem(20),
      marginRight: spacing(0),
      root: {
        fontSize: 62,
      },
    },
    qbCommitInvoiceButton: {
      margin: spacing(1),
      float: "inline-end",
    },
    invoiceStatusIcon: {
      fontSize: typography.pxToRem(16),
      marginRight: spacing(0),
      root: {
        fontSize: 24,
        marginRight: spacing(0),
      },
      check: {
        fontSize: 18,
        color: "green",
        marginRight: spacing(0),
        root: {
          fontSize: 18,
          width: 18,
          height: 18,
          color: "green",
          marginRight: spacing(0),
        },
      },
    },

    qbPaymentItem: {
      margin: spacing(0),
      padding: spacing(0),
      color: "darkgrey",
      fontWeight: 400,
      fontSize: 13,
      width: "100%",
    },
    qbPaymentAmount: {
      margin: spacing(0),
      padding: spacing(0),
      color: "green",
      fontWeight: 600,
      fontSize: 14,
      width: "100%",
    },
    qbPaymentTitle: {
      margin: spacing(0),
      padding: spacing(0),
      top: "30%",
      color: "rgb(110 122 138)",
      fontWeight: 600,
      fontSize: 18,
      width: "100%",
      root: {
        paddingBottom: spacing(0),
        width: "100%",
      },
    },
    pieChart: {
      root: {
        flexGrow: 1,
        width: "100%",
        color: "#FFF",
        //   height: 400,
        backgroundColor: palette.background.paper,
      },
    },
    tabNav: {
      colorSecondary: {
        color: "#FFF",
      },
      boxShadow: "none",
      root: {
        "& > *": {
          margin: spacing(0),
          padding: spacing(0),
          width: "100%",
          // height: 400,
          boxShadow: "none",
        },
        boxShadow: "none",
      },
    },
    root: {
      padding: `${spacing(0)} !important`,
      width: "100%",
      "& > *": {
        margin: spacing(0),
        padding: spacing(0),
        width: "100%",
        //   height: 400,
      },
    },
    vertTabRoot: {
      root: {
        flexGrow: 1,
        backgroundColor: palette.background.paper,
        display: "flex",
        height: 224,
      },
    },
    tabs: {
      borderRight: `1px solid ${palette.divider}`,
      root: {
        "& > *": {
          margin: spacing(0),
          padding: spacing(0),
          width: "100%",
          // height: 400,
        },
      },
      textColorPrimary: { color: "#FFF" },
      textColorSecondary: { color: "#FFF" },
    },
    action: {
      margin: spacing(0),
      padding: spacing(0),
      top: "25%",
      fontSize: 42,
    },
    statsDialogTitle: { marginLeft: -20, marginTop: -20, paddingBottom: 8 },
    statsDialogTitleCopy: {
      fontWeight: 500,
      fontSize: 28,
      fontFamily: "Roboto",
      color: "rgb(110 122 138)",
    },
    copy: {
      margin: spacing(0),
      padding: spacing(0),
      paddingTop: spacing(0),
      color: "rgb(110 122 138)",
      fontWeight: 400,
      fontSize: 13,
    },
    card: {
      backgroundColor: palette.common.white,
      border: `0.7px solid ${palette.grey[400]}`,
      borderRadius: shape.borderRadius,
      boxShadow: "none",
      width: "100%",
      padding: `${spacing(0)} !important`,
      paddingRight: `${spacing(0)} !important`,
      marginRight: `${spacing(0.5)} !important`,
      marginLeft: `${spacing(0.5)} !important`,
      marginBottom: `${spacing(1)} !important`,
      [breakpoints.down("sm")]: {
        padding: `${spacing(1)} !important`,
        fontSize: 13,
        "&:h5": { fontSize: 13 },
        // "&:nth-child(2)": { paddingRight: spacing(1) },
        // "&:last-child": { paddingLeft: spacing(1) },
      },
    },
    invoiceListItem: {
      margin: spacing(0),
      padding: spacing(0),
      paddingTop: spacing(0),
      color: "rgb(110 122 138)",
      fontWeight: 400,
      fontSize: 13,
      width: "100%",
    },
    dialogContent: {
      margin: spacing(0),
      padding: spacing(0),
      minHeight: 300,
      width: "100%",
      color: "darkgrey",
    },
    title: {
      margin: spacing(0),
      padding: spacing(0),
      top: "30%",
      color: "rgb(110 122 138)",
      fontWeight: 600,
      fontSize: 18,
      width: "100%",
      root: {
        paddingBottom: spacing(0),
        width: "100%",
      },
    },
    actionIcon: {
      margin: spacing(0),
      padding: spacing(0),
      top: "30%",
      fontSize: 42,
      root: {
        fontSize: 42,
      },
    },
    placeholderContainer: {
      paddingRight: spacing(0),
      boxShadow: "none",
      marginTop: spacing(0),
      [breakpoints.down("sm")]: { paddingRight: spacing(0), paddingBottom: spacing(0) },
    },
    container: {
      paddingRight: spacing(2),
      boxShadow: "none",
      paddingBottom: spacing(0.7),
      // "&:last-child": { paddingRight: 0 },
      "&:nth-child(3n)": { paddingRight: 0 },
      marginTop: spacing(0),
      [breakpoints.down("sm")]: { paddingRight: spacing(0), paddingBottom: spacing(1) },
    },
    wrapper: {
      boxShadow: "none",
      width: "100%",
      padding: `${spacing(1)} ${spacing(0)} !important`,
    },
    cardStyle: {
      backgroundColor: palette.common.white,
      border: `1px solid ${palette.grey[500]}`,
      borderRadius: shape.borderRadius,
      padding: `${spacing(0)} !important`,
      height: "100%",
      boxShadow: "none",
    },
    placeholderCard: {
      backgroundColor: palette.common.white,
      border: `0px solid ${palette.grey[500]}`,
      borderRadius: shape.borderRadius,
      padding: `${spacing(0)} !important`,
      height: "100%",

      "&:last-child": { marginRight: 0 },
    },
    cardRoot: {
      boxShadow: "none",
      padding: `${spacing(0)} !important`,
      width: "100%",
    },
    star: {
      color: palette.text.primary,
    },
    availabilityContainer: {
      backgroundColor: palette.grey[500],
      width: 25,
      height: 25,
    },
    availableCell: {
      backgroundColor: palette.primary.main,
    },
    //   root: {
    //     boxShadow: "none",
    //     padding: `${spacing(0)} !important`,
    //     width: "100%",
    //   },

    paper: {
      width: "100%",
      margin: `${spacing(0)} !important`,
      padding: `${spacing(0)} !important`,
      marginBottom: spacing(2),
      boxShadow: "none",
    },
    table: {
      width: "100%",
      margin: `${spacing(0)} !important`,
      padding: `${spacing(0)} !important`,
    },

    visuallyHidden: {
      border: 0,
      clip: "rect(0 0 0 0)",
      height: 1,
      margin: -1,
      overflow: "hidden",
      padding: 0,
      position: "absolute",
      top: 20,
      width: 1,
    },
    media: {
      height: 0,
      paddingTop: "56.25%", // 16:9
    },
    expand: {
      transform: "rotate(0deg)",
      marginLeft: "auto",
      transition: transitions.create("transform", {
        duration: transitions.duration.shortest,
      }),
    },
    expandOpen: {
      transform: "rotate(180deg)",
    },
    avatar: {
      fontSize: 38,
      fontWeight: 500,
      color: palette.secondary.main,
    },

    subHeader: {
      fontSize: 13,
      boxShadow: "none",
      fontWeight: 500,
    },
  })
);
