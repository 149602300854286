import { makeStyles } from "@mui/styles";

const styles = ({ spacing, palette }) => ({
    stateInfo: {
        "& p": {
            marginRight: spacing(2),
        },
        "& svg": {
            marginRight: spacing(1),
        },
    },
    progress: {
        color: palette.secondary.light,
        position: "absolute",
        top: "50%",
        left: "45%",
    },
    timePicker: {
        "& table td": {
            paddingRight: '0 !important',
            paddingLeft: '0 !important'
        }
    },
    dateTimePicker: {
        "& .MuiDialogContent-root .MuiClockPicker-root": {
            "&>div": {
                "&>button": {
                    height: 35,
                    width: 35,
                    "&>span": {
                        fontWeight: "bold",
                        color: "inherit"
                    }
                }
            }
        }
    }
});

export const useStyles = makeStyles(styles);
export default styles;
