export default {
  SET_EMPLOYERS: "employers/SET_EMPLOYERS",
  UPDATE_PAGE_SORT: "employers/UPDATE_PAGE_SORT",
  UPDATE_UNAPPROVED_PAGE_SORT: "employers/UPDATE_UNAPPROVED_PAGE_SORT",
  UPDATE_DEACTIVATED_PAGE_SORT: "employers/UPDATE_DEACTIVATED_PAGE_SORT",
  UPDATE_PAGE_FILTER: "employers/UPDATE_PAGE_FILTER",
  UPDATE_DEACTIVATED_PAGE_FILTER: "employers/UPDATE_DEACTIVATED_PAGE_FILTER",
  UPDATE_UNAPPROVED_PAGE_FILTER: "employers/UPDATE_UNAPPROVED_PAGE_FILTER",
  UPDATE_LIST_FILTER: "employers/UPDATE_LIST_FILTER",
  SET_EMPLOYER: "employers/SET_EMPLOYER",
  SET_NEW_EMPLOYER: "employers/SET_NEW_EMPLOYER",
  IS_LOADING_DETAIL: "employers/IS_LOADING_DETAIL",
  UPDATE_EDIT_DATA: "employers/UPDATE_EDIT_DATA",
  RESET_EDIT_DATA: "employers/RESET_EDIT_DATA",
  IS_LOADING_INFO: "employers/IS_LOADING_INFO",
  SET_EMPLOYER_BANS: "employers/SET_EMPLOYER_BANS",
  SET_EMPLOYER_PREFERRED: "employers/SET_EMPLOYER_PREFERRED",
  ADD_EMPLOYER_BAN: "employers/ADD_EMPLOYER_BAN",
  ADD_EMPLOYER_PREFERRED: "employers/ADD_EMPLOYER_PREFERRED",
  REMOVE_EMPLOYER_REP: "employers/REMOVE_EMPLOYER_REP",
  SET_DEACTIVATED_EMPLOYERS: "employers/SET_DEACTIVATED_EMPLOYERS",
  SET_UNAPPROVED_EMPLOYERS: "employers/SET_UNAPPROVED_EMPLOYERS",
  UPDATE_ADDEMPLOYER_DATA: "employers/UPDATE_ADDEMPLOYER_DATA",
  RESET_ADDEMPLOYER_DATA: "employers/RESET_ADDEMPLOYER_DATA",
  SET_EMAIL_AVAILABLE: "employers/SET_EMAIL_AVAILABLE",
  SET_LOCATION: "employers/SET_LOCATION",
  SET_VALID_EMAIL: "employers/SET_VALID_EMAIL",
};
