import { gql } from "@apollo/client";

export default gql`
  query banned($first: Int, $last: Int, $after: String, $before: String, $workerId: String) {
    banned(first: $first, last: $last, after: $after, before: $before, workerId: $workerId) {
      totalCount
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        node {
          id
          workerId
          employerId
          type
          isNotified
          notifyAfter
          notifyAfterUtc
          reason
          createdAt
          createdAtUtc
          worker {
            pk

            id
          }
          employer {
            id

            companyName
          }
        }
      }
    }
  }
`;
