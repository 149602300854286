import PropTypes from "prop-types";

function PaymentStatus() {
  return null;
  // const isActive = useSelector(JobDetailSelectors.getIsActive);
  // const jobStatus = useSelector(JobDetailSelectors.getJobStatus);
  // const isMissingShiftInfo = useSelector(JobDetailSelectors.getIsMissingShiftInfo);

  // const isPaid = useSelector(JobDetailSelectors.getIsPaid);

  // if (!isActive) {
  //     return null;
  // }

  // if (jobStatus === JobStatusesMap.NotStarted) {
  //     return null;
  // }

  // if (jobStatus === JobStatusesMap.InProgress && isMissingShiftInfo) {
  //     return null;
  // }

  // let chip = null;
  // if (isPaid) {
  //     chip = <AcceptedBadge />;
  // } else if (isPaymentPending) {
  //     chip = <QueuedBadge />;
  // } else if (!isPaymentPending && !isMissingShiftInfo) {
  //     chip = <IncompleteBadge />;
  // } else if (isMissingShiftInfo) {
  //     chip = <MissingInfoBadge />;
  // } else {
  //     return null;
  // }

  // return (
  //     <Grid item className={className}>
  //         {chip}
  //     </Grid>
  // );
}

PaymentStatus.propTypes = {
  className: PropTypes.string,
};

PaymentStatus.defaultProps = {
  className: null,
};

export default PaymentStatus;
