// HelperMessage
import React from "react";
import PropTypes from "prop-types";
import withStyles from '@mui/styles/withStyles';
import Typography from "@mui/material/Typography";
import ChevronRight from "@mui/icons-material/ChevronRight";
import Button from "components/Button/Button";
import { formatMoney } from "utils/stringUtils";

const styles = theme => ({
    footer: {
        position: "fixed",
        bottom: theme.spacing(4),
        right: theme.spacing(4),
        zIndex: 99999,
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-end",
    },
    button: {
        marginTop: "8px",
    },
});

function SummaryFooter({ totalCost, onNext, canPost, classes }) {
    return (
        <div className={classes.footer}>
            <Typography variant="caption" color="textSecondary">
                Total
            </Typography>
            <Typography variant="h4">{formatMoney(totalCost)}</Typography>
            <Typography variant="caption" color="textSecondary">
                +GST
            </Typography>
            <Button
                className={classes.button}
                icon={<ChevronRight />}
                label="Next"
                alignIcon="right"
                onClick={onNext}
                disabled={!canPost}
            />
        </div>
    );
}

SummaryFooter.propTypes = {
    classes: PropTypes.object.isRequired,
    totalCost: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    onNext: PropTypes.func,
    canPost: PropTypes.bool,
};

SummaryFooter.defaultProps = {
    totalCost: 0,
    onNext: () => {},
    canPost: false,
};

export default withStyles(styles)(SummaryFooter);
