import { gql } from "@apollo/client";

export default gql`
  fragment PayrollFields on Payroll {
    id
    createdAt
    positionId
    rate
    checkIn
    checkOut
    breakDeductionMins
    totalUnits
    payableUnits
    grossWages
    grossEarnings
    netEarnings
    totalEmployeeDeductions
    payrollType {
      id
      name
      code
    }
    wageEarnings {
      id
      payrollId
      payrollEarningsTypeId
      rate
      units
      amount
    }
    additionalEarnings {
      id
      payrollId
      payrollEarningsTypeId
      rate
      units
      amount
    }
    earnings {
      id
      payrollId
      payrollEarningsTypeId
      rate
      units
      amount
    }
    employeeDeductions {
      id
      payrollId
      amount
      payrollDeductionsTypeId
    }
    employerDeductions {
      id
      payrollId
      amount
      payrollDeductionsTypeId
    }
    payments {
      id
      createdAt
      dcTransferId
      retryPaymentId
      #   paymentBatchId
      sentAt
      paymentProviderReferenceCode
      responseAt
      #   amount
      errorCode
      errorDescription
      paymentStatus {
        id
        description
        code
      }
    }
  }
`;
