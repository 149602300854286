import React from "react";
import PropTypes from "prop-types";
import Checkbox from "@mui/material/Checkbox";
import Grid from "@mui/material/Grid";
import withStyles from '@mui/styles/withStyles';
import Star from "@mui/icons-material/Star";

const styles = ({ palette }) => ({
    container: {
        flexWrap: "nowrap",
    },
    unchecked: {
        color: palette.grey[200],
    },
});

class Rating extends React.Component {
    static propTypes = {
        value: PropTypes.number,
        size: PropTypes.number,
        classes: PropTypes.object.isRequired,
        onChange: PropTypes.func,
    };

    static defaultProps = {
        value: 3,
        size: 0,
        onChange: () => null,
    };

    onChange = event => {
        this.props.onChange(parseInt(event.target.value, 10));
    };

    render() {
        const { classes, value, size } = this.props;

        const style = {};
        if (size) {
            style.fontSize = size;
        }

        return (
            <Grid container className={classes.container}>
                {[1, 2, 3, 4, 5].map(val => (
                    <Checkbox
                        key={val}
                        icon={<Star fontSize="large" className={classes.unchecked} style={style} />}
                        checkedIcon={<Star fontSize="large" style={style} />}
                        value={`${val}`}
                        checked={value >= val}
                        onChange={this.onChange}
                    />
                ))}
            </Grid>
        );
    }
}

export default withStyles(styles)(Rating);
