import React from "react";
import { withRouter } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import withStyles from '@mui/styles/withStyles';
import MoreVert from "@mui/icons-material/MoreVert";
import { themeColors } from "assets/jss/material-dashboard-pro-react.jsx";
import Can from "components/Authorization/Can";
import { JobPermissions } from "helpers/constants/permissions";

const styles = {
    button: {
        color: themeColors.grey[600],
        "&:hover": {
            backgroundColor: "transparent",
            color: themeColors.grey[900],
        },
    },
};
class JobListItemMenu extends React.Component {
    static defaultProps = {
        canEnable: false,
        canDelete: false,
    };

    state = {
        anchorEl: null,
    };

    handleClick = event => {
        this.setState({ anchorEl: event.currentTarget });
    };

    handleClose = () => {
        this.setState({ anchorEl: null });
    };

    handleEnable = () => {
        this.handleClose();
    };

    handleDuplicate = () => {
        const { history, jobId } = this.props;
        this.handleClose();
        history.push(`/jobs/post?jobId=${jobId}&type=duplicate`);
    };

    handleDelete = () => {
        this.handleClose();
    };

    render() {
        const { anchorEl } = this.state;
        const { classes } = this.props;

        const id = `action-menu-${this.props.jobId}`;
        const buttonClasses = { root: classes.button };
        const listItemClasses = { root: classes.listItem };

        return (
            <React.Fragment>
                <IconButton
                    classes={buttonClasses}
                    aria-owns={anchorEl ? id : undefined}
                    aria-haspopup="true"
                    disableRipple
                    onClick={this.handleClick}
                    size="large">
                    <MoreVert />
                </IconButton>
                {anchorEl ? (
                    <Menu
                        id={id}
                        anchorEl={anchorEl}
                        open={Boolean(anchorEl)}
                        onClose={this.handleClose}
                        disableAutoFocusItem>
                        {/* {this.props.canEnable && (
                            <MenuItem classes={listItemClasses} onClick={this.handleEnable}>
                                Enable
                            </MenuItem>
                        )} */}
                        <Can perform={JobPermissions.create.duplicate.value}>
                            {allowed => {
                                if (!allowed) {
                                    return null;
                                }
                                return <MenuItem classes={listItemClasses} onClick={this.handleDuplicate}>
                                    Duplicate
                                </MenuItem>
                            }}
                        </Can>

                        {/* {this.props.canDelete && (
                            <MenuItem classes={listItemClasses} onClick={this.handleDelete}>
                                Delete
                            </MenuItem>
                        )} */}
                    </Menu>
                ) : null}
            </React.Fragment>
        );
    }
}

export default withStyles(styles)(withRouter(JobListItemMenu));
