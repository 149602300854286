export default ({ spacing, shape, palette }) => ({
  pageContainer: {
    display: "flex",
    flexDirection: "row",
    alignContent: "center",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
    minHeight: "100%",
  },
  loginContainer: {
    alignContent: "center",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: palette.grey[900],
    height: "100%",
    width: "100%",
  },
  loginItemContainer: {
    alignContent: "center",
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
    width: "100%",
  },
  buttonContainer: {
    alignContent: "flex-end",
    alignItems: "flex-end",
    display: "flex",
    justifyContent: "flex-end",
    width: "100%",
  },
  logo: {
    padding: spacing(5),
    alignContent: "center",
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
    width: "100%",
  },
  labelStyle: {
    color: palette.grey[600],
  },
  inputStyle: {
    border: `1px solid ${palette.grey[800]}`,
    borderRadius: shape.borderRadius,
    backgroundColor: `${palette.grey[800]} !important`,
    outline: 0,
    "& input": {
      border: "none",
      color: palette.common.white,
      backgroundColor: `${palette.grey[800]} !important`,
      outline: 0,
    },
    "& input:-webkit-autofill, input:-webkit-autofill:hover, input:-webkit-autofill:focus": {
      color: `${palette.common.white} !important`,
      "-webkit-text-fill-color": palette.common.white,
      boxShadow: `0 0 0px 1000px ${palette.grey[800]} inset`,
      "-webkit-box-shadow": `0 0 0px 1000px ${palette.grey[800]} inset`,
      borderColor: "none !important",
      outline: 0,
      backgroundColor: `${palette.grey[800]} !important`,
    },
  },
  emailStyle: {
    color: "#fff !important",
  },

  linkStyle: {
    float: "right",
    paddingRight: 0,
    color: palette.secondary.light,
  },

  imageContainer: {
    alignContent: "center",
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
    backgroundColor: palette.background.default,
  },
});
