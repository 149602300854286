/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */

import React from "react";
import { connect } from "react-redux";
import moment from "moment-timezone";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { AddCircle, InfoOutlined, RemoveCircle } from "@mui/icons-material";
import { HtmlTooltip, invoiceItemTableStyles } from "views/Finance/helpers";
import Dialog from "components/Dialog/Dialog";
import "date-fns";
import PayrollDialog from "components/Dialog/PayrollDialog";
import { formatMoney, formatNumber } from "utils/stringUtils";
import { actions as BillingActions, selectors as FinanceSelectors } from "store/modules/finance";
import InvoiceModalTable from "../../components/table";
import ShiftMenu from "../../ShiftMenu";
import { convertLocalToUTC, convertUTCToTimezoneWithOffset } from "../../../../../../utils/time";
import { MobileDateTimePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { setUpdateEmployerBillingWeekData } from "../../../../../../store/modules/finance/actions";
import { BillingRate } from "../../../../../../helpers/constants";
import { InputAdornment, OutlinedInput } from "@mui/material";
import ReactTableSimple from "components/ReactTable/ReactTableSimple";
import Can from "components/Authorization/Can";
import { PositionPermissions } from "helpers/constants/permissions";
const isMobile = false;
const tableProps = {
    manual: false,
    minRows: 0,
    resizable: false,
    showPageSizeOptions: !isMobile ? true : false,
    showPageJump: false,
    showPaginationBottom: !isMobile ? true : false,
    showPaginationTop: isMobile ? true : false,
    classes: "-highlight",
    billingRateFilter: "all",
    breakLengthFilter: "all",
    defaultPageSize: isMobile ? 5 : 10,
    defaultSorted: [{ id: "hasAdjustments", desc: false }],
    defaultSortMethod: (a, b) => {
        // force null and undefined to the bottom
        a = a === null || a === undefined ? -Infinity : a;
        b = b === null || b === undefined ? -Infinity : b;
        // force any string values to lowercase
        a = typeof a === "string" ? a.toLowerCase() : a;
        b = typeof b === "string" ? b.toLowerCase() : b;
        // Return either 1 or -1 to indicate a sort priority
        if (a > b) {
            return 1;
        }
        if (a < b) {
            return -1;
        }
        // returning 0 or undefined will use any subsequent column sorting methods or the row index as a tiebreaker
        return 0;
    },
};
const columnStyles = { fontSize: isMobile ? 11 : 14, fontWeight: isMobile ? 500 : 700 };
const columnCellStyles = { fontSize: isMobile ? 11 : 12.25, fontWeight: isMobile ? 500 : 500 };

class InvoiceItemTable extends React.Component {
    constructor() {
        super();
        this.state = {
            value: 0,
            totalBillable: 0,
            theLen: 0,
            expanded: {},
            data: [],
            activeData: [],
            dataType: "activeInvoice",
            open: false,
            canEdit: false,
            selectedDate: new Date(),
            openPayrollDialog: false,
            alert: null,
        };
        this.renderEditable = this.renderEditable.bind(this);
        this.renderEditableDate = this.renderEditableDate.bind(this);
    }

    componentDidMount = () => this.getTableData();
    getTableData = () => {
        const { activeInvoiceItems } = this.props;

        this.setState({
            data: activeInvoiceItems,
            totalBillable: activeInvoiceItems.sum("total"),
            activeData: activeInvoiceItems ? activeInvoiceItems : [],
            dataType: "activeInvoice",
            employer: "this employer",
            canEdit: false,
            selected: null,
            selectedDate: new Date(),
        });
        // });
    };

    handleClose = () => this.setState({ open: false, openPayrollDialog: false });
    handleOpen = () => this.setState({ open: true });
    handleChange = (event, value) => this.setState({ value });
    getTdProps = (state, rowInfo) => {
        if (rowInfo && rowInfo.row) {
            return {
                style: {
                    background: rowInfo.index === this.state.selected ? "#00afec" : "white",
                    color: rowInfo.index === this.state.selected ? "white" : "black",
                },
            };
        } else {
            return {};
        }
    };

    getTrProps = (state, rowInfo) => {
        if (rowInfo && rowInfo.row) {
            return {
                onClick: () => {
                    this.setState({
                        selected: rowInfo.index,
                    });
                },
                style: {
                    background: rowInfo.index === this.state.selected ? "#00afec" : "white",
                    color: rowInfo.index === this.state.selected ? "white" : "black",
                },
            };
        } else {
            return {};
        }
    };

    renderEditable = cellInfo => {
        const { activeInvoiceItems } = this.props;
        return this.state.canEdit ? (
            <TextField
                name={cellInfo.column.id}
                id={cellInfo.column.id} //
                defaultValue={activeInvoiceItems[cellInfo.row.index][cellInfo.column.id] || ""}
                InputLabelProps={{ shrink: true }}
                onChange={e => {
                    const value = e.target.name === "breakValue" ? e.target.value : e.target.value;
                    const newD = { [e.target.name]: value };
                    const updateData = { ...activeInvoiceItems[cellInfo.row.index], ...newD };
                    this.props.setUpdateEmployerBillingWeekInvoiceData(updateData);
                    setTimeout(
                        () =>
                            this.setState({
                                data: activeInvoiceItems,
                                totalBillable: activeInvoiceItems && activeInvoiceItems.sum("total"),
                            }),
                        1000
                    );
                }}
            />
        ) : (
            cellInfo.value
        );
    };

    renderBreakSelect = cellInfo => {
        const { activeInvoiceItems } = this.props;
        let fieldValue = cellInfo.value ? `${cellInfo.value} Mins` : `No Breaks`;

        if (this.state.canEdit) {
            let val =
                activeInvoiceItems[cellInfo.row.index][cellInfo.column.id] > 0
                    ? activeInvoiceItems[cellInfo.row.index][cellInfo.column.id]
                    : 0;
            return (
                <Select
                    name={cellInfo.column.id}
                    id={cellInfo.column.id}
                    defaultValue={val}
                    disableUnderline={false}
                    value={val}
                    style={{ paddingRight: 20, width: "80%" }}
                    onChange={e => {
                        let updateData = {
                            [e.target.name]: e.target.name === "breakValue" ? e.target.value : e.target.value,
                        };
                        fieldValue = updateData[cellInfo.column.id];
                        this.props.setUpdateEmployerBillingWeekInvoiceData({
                            ...activeInvoiceItems[cellInfo.row.index],
                            ...updateData,
                        });
                        setTimeout(
                            () =>
                                this.setState({
                                    data: activeInvoiceItems,
                                    totalBillable: activeInvoiceItems && activeInvoiceItems.sum("total"),
                                }),
                            1000
                        );
                    }}>
                    <MenuItem value={0}>
                        <Typography variant="inherit">No Breaks</Typography>
                    </MenuItem>
                    <MenuItem value={15}>
                        <Typography variant="inherit"> 15 mins</Typography>
                    </MenuItem>
                    <MenuItem value={30}>
                        <Typography variant="inherit"> 30 mins</Typography>
                    </MenuItem>
                    <MenuItem value={45}>
                        <Typography variant="inherit"> 45 mins</Typography>
                    </MenuItem>
                    <MenuItem value={60}>
                        <Typography variant="inherit"> 60 mins</Typography>
                    </MenuItem>
                </Select>
            );
        }
        return fieldValue
    };

    renderEditableSelect = cellInfo => {
        const { activeInvoiceItems } = this.props;
        const { canEdit } = this.state;
        let fieldValue = cellInfo.value;
        const inputValue = activeInvoiceItems[cellInfo.row.index][cellInfo.column.id];
        return canEdit ? (
            <OutlinedInput
                key={`${cellInfo.column.id}-${cellInfo.row.index}`}
                error={!inputValue || inputValue <= 0}
                type="number"
                className={this?.props?.classes?.hideArrows}
                name={cellInfo.column.id}
                id={cellInfo.column.id}
                value={inputValue || ""}
                style={{ fontSize: 12, border: "none" }}
                startAdornment={<InputAdornment position="start">$</InputAdornment>}
                onChange={e => {
                    const value = e.target.name === "rate" ? Number(e.target.value) : e.target.value;
                    const newD = { [e.target.name]: value };
                    const updateData = { ...activeInvoiceItems[cellInfo.row.index], ...newD };
                    fieldValue = updateData[cellInfo.column.id];
                    this.props.setUpdateEmployerBillingWeekInvoiceData(updateData);
                    setTimeout(
                        () =>
                            this.setState({
                                data: activeInvoiceItems,
                                totalBillable: activeInvoiceItems && activeInvoiceItems.sum("total"),
                            }),
                        1000
                    );
                }}
            />
        ) : (
            formatMoney(fieldValue)
        );

        // const { activeInvoiceItems } = this.props;
        // const { canEdit } = this.state;
        // let fieldValue = cellInfo.value;
        // return canEdit ? (
        //     <Select
        //         disableUnderline={false}
        //         name={cellInfo.column.id}
        //         id={cellInfo.column.id}
        //         value={
        //             activeInvoiceItems[cellInfo.row.index][cellInfo.column.id] || "" //   value={age} //
        //         }
        //         onChange={e => {
        //             const value = e.target.name === "rate" ? Number(e.target.value) : e.target.value;
        //             const newD = { [e.target.name]: value };
        //             const updateData = { ...activeInvoiceItems[cellInfo.row.index], ...newD };
        //             fieldValue = updateData[cellInfo.column.id];
        //             this.props.setUpdateEmployerBillingWeekInvoiceData(updateData);
        //             setTimeout(
        //                 () =>
        //                     this.setState({
        //                         data: activeInvoiceItems,
        //                         totalBillable: activeInvoiceItems && activeInvoiceItems.sum("total"),
        //                     }),
        //                 1000
        //             );
        //         }}>
        //         {/*<MenuItem value={activeInvoiceItems[cellInfo.row.index][cellInfo.column.id]}>
        //   {activeInvoiceItems[cellInfo.row.index][cellInfo.column.id]}
        // </MenuItem>
        // <MenuItem value={activeInvoiceItems[cellInfo.row.index][cellInfo.column.id]}>
        //   {activeInvoiceItems[cellInfo.row.index][cellInfo.column.id]}
        // </MenuItem>*/}
        //         <MenuItem value={21.95}>21.95</MenuItem>
        //         <MenuItem value={24.95}>24.95</MenuItem>
        //         {/* <MenuItem value={"24.95"}>24.95</MenuItem> */}
        //     </Select>
        // ) : (
        //     formatMoney(fieldValue)
        // );
    };

    renderEditableDate = (cellInfo, permission) => {
        const {classes}=this.props;
        const pickerProps = {
            hideTabs: true,
            error: false,
            autoOk: true,
            emptyLabel: ",",
            invalidLabel: ",",
            inputFormat: "hh:mm a",
            DialogProps: { className: classes.dateTimePicker },
            renderInput: (props) => <TextField {...props} variant="outlined" />,
            value: cellInfo.row.original[cellInfo.column.id],
            onChange: date => {
                this.handleDateChange(date, cellInfo.column.id, cellInfo.row.original);
            },
        };
        return <Can perform={permission}>
            {allowed => {
                if (!allowed || !this.state.canEdit) {
                    return <Grid>
                        {convertUTCToTimezoneWithOffset(cellInfo.row.original[cellInfo.column.id], "", "hh:mm a")}
                    </Grid>;
                }
                return (<LocalizationProvider dateAdapter={AdapterDateFns}>
                    <MobileDateTimePicker {...pickerProps} />
                </LocalizationProvider>)
            }}
        </Can>
    };

    pickerProps = cellInfo => ({
        hideTabs: false,
        error: false,
        readOnly: false,
        disabled: false,
        autoOk: true,
        emptyLabel: "Date Required",
        invalidLabel: "Invalid Date",
        format: "hh:mm a",

        openTo: "hours",

        onChange: date => this.handleDateChange(date, cellInfo.column.id, cellInfo.original),
        value: moment(cellInfo.original[cellInfo.column.id] || cellInfo.value)
            //     .add(7, "h")
            //     .tz("America/Vancouver")
            .format(),
    });

    handleDateChange = (date, name, og) => {
        const { activeInvoiceItems } = this.props;
        const newD = { [name]: convertLocalToUTC(date) };
        // const newD = { [name]: moment(date).format() };
        const updateData = { ...og, ...newD };
        this.props.setUpdateEmployerBillingWeekInvoiceData({ ...updateData });
        setTimeout(
            () =>
                this.setState({
                    data: activeInvoiceItems,
                    // alert:null,
                    totalBillable: activeInvoiceItems && activeInvoiceItems.sum("total"),
                }),
            1000
        );
    };

    renderHeader = () => {
        const shift = this.props.activeInvoiceItems.filter(n => !n.isRemoved);
        return (
            <Grid container>
                <Grid item xs={6}>
                    {/* <Grow in={this.state.data && this.state.data.length ? true : false} timeout={500}> */}
                    <Grid container justifyContent="flex-start" className={this.props.classes.wrapper}>
                        <Typography
                            style={{ color: "#aebecd", fontWeight: 500, fontSize: 20, fontFamily: "Roboto" }}>
                            {this.state.dataType === "activeInvoice"
                                ? `Timesheet Details`
                                : `Pending Hours Overview`}
                        </Typography>
                    </Grid>
                    {/* </Grow> */}

                    {/* <Typography variant="h6">Billing Details</Typography> */}
                    {/* <Typography variant="h6">{formatMoney(this.state.totalBillable)}</Typography> */}
                </Grid>

                <Grid item xs={6}>
                    {this.state.canEdit ? (
                        <div
                            style={{
                                textAlign: "right",
                                fontSize: 14,
                                fontWeight: 700,
                                alignItems: "flex-end",
                            }}>
                            <Button
                                onClick={() =>
                                    this.setState(
                                        {
                                            canEdit: false,
                                            data: this.props.activeInvoiceItems,
                                            totalBillable: this.props.activeInvoiceItems.sum("total"),
                                        },
                                        () => {
                                            this.props.fetchEmployerInvoicesByWeek(
                                                this.props.employerId,
                                                this.props.billingWeek,
                                                this.props.billingYear
                                            );
                                            this.getTableData();
                                        }
                                    )
                                }
                                size="small"
                                variant="outlined"
                                color="primary"
                                style={{ textAlign: "right", marginRight: 10 }}>
                                Cancel
                            </Button>
                            <Button
                                onClick={() =>
                                    this.setState({
                                        open: true,
                                        //   dataType: "activeInvoice",
                                        data: this.props.activeInvoiceItems,
                                    })
                                }
                                disabled={shift.length === 0 || shift?.some(s => !s.rate || s.rate <= 0)}
                                size="small"
                                variant="contained"
                                color="secondary">
                                Create Timesheet
                            </Button>
                        </div>
                    ) : (
                        ""
                    )}
                </Grid>
            </Grid>
        );
    }

    renderInvoiceColumns = () => {
        const actionHeader = () => {
            const { isInvoiceCommitted } = this.props;

            const content = isInvoiceCommitted ? (
                <React.Fragment>
                    <Typography color="secondary" variant="subtitle1">
                        Invoice Is Committed
                    </Typography>
                    <Typography color="inherit" variant="body2">
                        This invoice has been submitted to Quickbooks. To edit this timesheet you must first
                        delete the commited invoice using the Quickbooks Manager.
                    </Typography>
                </React.Fragment>
            ) : (
                <React.Fragment>
                    <Typography color="secondary" variant="subtitle1">
                        Update Timesheet Details
                    </Typography>
                    <Typography color="inherit" variant="body2">
                        Update invoice details prior to committing it to Quickbooks.
                    </Typography>
                </React.Fragment>
            );
            return (
                <React.Fragment>
                    <HtmlTooltip title={content} placement="left">
                        {!this.state.canEdit ? (
                            <span>
                                <Button
                                    onClick={() => this.setState({ canEdit: !this.state.canEdit })}
                                    size="small"
                                    disabled={isInvoiceCommitted}
                                    style={{ textAlign: "right", justify: "right", alignItems: "flex-end" }}
                                    color="secondary">
                                    edit
                                </Button>
                            </span>
                        ) : (
                            <br />
                        )}
                    </HtmlTooltip>
                </React.Fragment>
            );
        };

        return [
            {
                Header: "Worker",
                id: "worker",
                accessor: ({ worker }) => worker,
                className: "-cursor-pointer",
                minWidth: isMobile ? 100 : 200,
                styles: { textAlign: "left", ...columnCellStyles },
                headerStyle: { textAlign: "left", ...columnStyles },
                sortMethod: (a, b) => {
                    if (a === b) {
                        return 0;
                    }
                    const aReverse = a.split("").reverse().join("");
                    const bReverse = b.split("").reverse().join("");
                    return aReverse > bReverse ? 1 : -1;
                },
            },
            {
                Header: "jobId",
                id: "jobId",
                disableFilters: true,
                accessor: ({ jobId }) => jobId,
                Cell: ({ row }) => {
                    return (row.original.payroll[0] && row.original.payroll[0].position) ? row.original.payroll[0].position.jobId : '';
                },
                className: "-cursor-pointer",
                minWidth: isMobile ? 70 : 100,
                styles: { textAlign: "left", ...columnCellStyles },
                headerStyle: { textAlign: "left", ...columnStyles },
            },
            {
                Header: "Job Date",
                id: "jobDate",
                disableFilters: true,
                accessor: ({ jobDate }) => jobDate,
                Cell: ({ row }) =>
                    isMobile
                        ? convertUTCToTimezoneWithOffset(row.original.jobDate, "", "MMM DD, 'YY")
                        : convertUTCToTimezoneWithOffset(row.original.jobDate, "", "MMM Do, YYYY"),
                className: "-cursor-pointer",
                minWidth: isMobile ? 70 : 100,
                styles: { textAlign: "left", ...columnCellStyles },
                headerStyle: { textAlign: "left", ...columnStyles },
            },
            {
                Header: "Check In",
                id: "checkInUtc",
                disableFilters: true,
                accessor: ({ checkInUtc }) => checkInUtc,
                className: "-cursor-pointer",
                Cell: (prop) => this.renderEditableDate(prop, PositionPermissions.update.checkIn.value),
                minWidth: isMobile ? 80 : 150,
                styles: { textAlign: "left", ...columnCellStyles },
                headerStyle: { textAlign: "left", ...columnStyles },
            },
            {
                Header: "Check Out",
                id: "checkOutUtc",
                sortable: false,
                disableFilters: true,
                accessor: ({ checkOutUtc }) => checkOutUtc,
                className: "-cursor-pointer",
                Cell: (prop) => this.renderEditableDate(prop, PositionPermissions.update.checkOut.value),
                minWidth: isMobile ? 80 : 150,
                styles: { textAlign: "left", ...columnCellStyles },
                headerStyle: { textAlign: "left", ...columnStyles },
            },
            {
                Header: "Break",
                id: "breakDeductionMins",
                // accessor: ({ breakValue }) => `${breakValue ? formatNumber(breakValue) : 0} mins`,
                accessor: info => info.breakDeductionMins,
                className: "-cursor-pointer",
                Cell: this.renderBreakSelect,
                minWidth: isMobile ? 80 : 150,
                styles: { textAlign: "left", ...columnCellStyles },
                headerStyle: { textAlign: "left", ...columnStyles },
            },
            {
                Header: "Duration",
                id: "duration",
                disableFilters: true,
                accessor: accessor => {
                    const duration =
                        (moment
                            .duration(moment(accessor.checkOutUtc).diff(moment(accessor.checkInUtc)))
                            .asMinutes() -
                            accessor.breakDeductionMins) /
                        60;

                    return duration.toFixed(2);
                },
                Cell: data => `${formatNumber(data.value)} hrs`,
                className: "-cursor-pointer",
                width: isMobile ? 70 : 120,
                styles: { textAlign: "left", ...columnCellStyles },
                headerStyle: { textAlign: "left", ...columnStyles },
            },
            {
                Header: "Rate",
                id: "rate",
                accessor: info => info.rate,
                className: "-cursor-pointer",
                Cell: this.renderEditableSelect,
                //   Cell: (data) =>      `${formatMoney(data.original.billingRate)}`,
                minWidth: isMobile ? 50 : 80,
                styles: { textAlign: "left" },
                headerStyle: { textAlign: "left", ...columnStyles },
            },
            {
                Header: "Total",
                id: "total",
                disableSortBy: true,
                sortable: false,
                disableFilters: true,
                accessor: info => {
                    const total =
                        ((moment.duration(moment(info.checkOutUtc).diff(moment(info.checkInUtc))).asMinutes() -
                            info.breakDeductionMins) /
                            60) *
                        info.rate;
                    return total.toFixed(2);
                },
                Cell: data => `${formatMoney(data.value)}`,
                className: "-cursor-pointer",
                width: isMobile ? 60 : 150,
                styles: { textAlign: "right", ...columnStyles },
                headerStyle: { textAlign: "right", ...columnStyles },
            },
            {
                Header: "Status",
                id: "adjustments",
                disableFilters: true,
                accessor: ({ hasAdjustments }) => hasAdjustments,

                Cell: ({ row: { original } }) => {
                    return (
                        <div aria-haspopup="true" style={{ textAlign: "center", justify: "center" }}>
                            {original.hasAdjustments ? (
                                <Tooltip title={`This invoice item had been adjusted.`} aria-label="add">
                                    <InfoOutlined
                                        className={this.props.classes.iconInfo}
                                        onClick={() => {
                                            setTimeout(() => {
                                                this.setState({
                                                    openPayrollDialog: !this.state.openPayrollDialog,
                                                    payrollData: original.payroll,
                                                });
                                            }, 1000);
                                        }}
                                    />
                                </Tooltip>
                            ) : (
                                ""
                            )}
                        </div>
                    );
                },
                width: isMobile ? 90 : 120,
                styles: { textAlign: "center", ...columnStyles },
                headerStyle: { textAlign: "center", ...columnStyles },
                className: "-cursor-pointer",
            },
            {
                sortable: false,
                disableFilters: true,
                id: "actions",
                Cell: data => {
                    const { setUpdateEmployerBillingWeekInvoiceData } = this.props;
                    let isRemoved = this.props.activeInvoiceItems[data.row.index]?.isRemoved ? true : false;
                    return this.state.canEdit ? (
                        isRemoved ? (
                            <AddCircle
                                color="action"
                                onClick={() => {
                                    setUpdateEmployerBillingWeekInvoiceData({
                                        ...this.props.activeInvoiceItems[data.row.index],
                                        isRemoved: null,
                                    });
                                    setTimeout(
                                        () =>
                                            this.setState({
                                                data: this.props.activeInvoiceItems,
                                                totalBillable: this.props.activeInvoiceItems
                                                    .filter(shift => shift.isRemoved !== true)
                                                    .sum("total"),
                                            }),
                                        1000
                                    );
                                }}
                            />
                        ) : (
                            <RemoveCircle
                                color="error"
                                onClick={() => {
                                    setUpdateEmployerBillingWeekInvoiceData({
                                        ...this.props.activeInvoiceItems[data.row.index],
                                        isRemoved: true,
                                    });
                                    setTimeout(() => {
                                        // this.getStats();
                                        this.setState({
                                            data: this.props.activeInvoiceItems,
                                            totalBillable: this.props.activeInvoiceItems
                                                .filter(shift => shift.isRemoved !== true)
                                                .sum("total"),
                                        });
                                    }, 1000);
                                }}
                            />
                        )
                    ) : (
                        <ShiftMenu
                            position={data.row.original}
                            workerId={data.row.original?.payroll[0]?.position?.workerId}
                            jobId={data.row.original?.payroll[0]?.position?.jobId}
                            onClick={() =>
                                this.setState({
                                    openPayrollDialog: !this.props.openPayrollDialog,
                                    payrollData: data.row.original.payroll,
                                })
                            }
                        />

                        //
                        //
                    );
                },
                Header: () => actionHeader(),
                collapse: true,
                styles: { textAlign: "right", alignItems: "flex-end", ...columnStyles },
                headerStyle: {
                    textAlign: "right",
                    ...columnStyles,
                    alignItems: "flex-end",
                    color: "rgb(11 147 139)",
                    textTransform: "capitalize",
                },
            },
        ];
    };

    renderTable = activeInvoiceItems => {
        const hiddenColumns = []
        if (!this.props.activeInvoiceItems.filter(item => item.hasAdjustments).length) hiddenColumns.push("adjustments")
        return (
            <ReactTableSimple
                data={activeInvoiceItems}
                columns={this.renderInvoiceColumns()}
                hiddenColumns={hiddenColumns}
                {...tableProps}
            />
        );
    };

    handleCreateInvoice = () => {
        this.setState({ canEdit: !this.state.canEdit, open: !this.state.open });
        this.props.createEmployerInvoiceFromInvoice();
    };

    renderPayrollDialog = () => {
        return (
            <PayrollDialog
                open={this.state.openPayrollDialog}
                title={`Generate New Timesheet?`}
                confirmText="Save & Generate Timesheet"
                onClose={this.handleClose}
                onConfirm={this.handleCreateInvoice}
                onCancel={this.handleClose}
                maxWidth="md"
                alertType="invoice"
                fullWidth={false}
                table={<InvoiceModalTable payroll={this.state.payrollData} />}
                disableBackdropClick
            />
        );
    };

    renderDialog = activeInvoiceItems => {
        const details = (
            <Grid container item xs={12}>
                <Typography variant="body1" align="center">
                    By saving these changes, you will be generating a new timesheet in the amount of
                    <strong>
                        {" "}
                        {formatMoney(
                            activeInvoiceItems &&
                            activeInvoiceItems.filter(shift => shift.isRemoved !== true).sum("total")
                        )}{" "}
                        plus taxes{" "}
                    </strong>
                    for {this.state.employer}
                </Typography>
            </Grid>
        );

        return (
            <Dialog
                open={this.state.open}
                title={`Generate New Timesheet?`}
                confirmText="Save & Generate Timesheet"
                onClose={this.handleClose}
                onConfirm={this.handleCreateInvoice}
                onCancel={this.handleClose}
                alertType="save"
                icon="attach_file"
                size="sm">
                {details}
            </Dialog>
        );
    };

    render = () => {
        const { classes, activeInvoiceItems, activeInvoice } = this.props;

        return (
            <Grid container item xs={12} className={classes.tabbedTableContainer}>
                {this.renderDialog(activeInvoiceItems)} {this.renderPayrollDialog()} {this.state.alert}
                {activeInvoice.length ? (
                    <Grid className={classes.tableContainer}>
                        {activeInvoiceItems && activeInvoiceItems.length ? (
                            <Grid>
                                {this.renderHeader()} {activeInvoiceItems && this.renderTable(activeInvoiceItems)}
                            </Grid>
                        ) : (
                            ""
                            //   <div className="spinner">
                            //     <div className="bounce1" />
                            //     <div className="bounce2" />
                            //     <div className="bounce3" />
                            //   </div>
                        )}
                    </Grid>
                ) : (
                    ""
                )}
            </Grid>
        );
    };
}

const mapState = (state, ownProps) => {
    const BILLING_RATE = BillingRate;
    const activeInvoice = FinanceSelectors.getEmployerActiveInvoice(state);
    const activeInvoiceItems = state.finance.activeInvoiceItems || {};
    const previousBillingWeekSummary = state.finance.previousBillingWeekSummary || [];
    let sum = 0;

    var jobStats = {};
    for (let { jobId } of activeInvoiceItems)
        jobStats[jobId] = { jobId, count: jobStats[jobId] ? jobStats[jobId].count + 1 : 1 };

    const stats = {
        shifts: {
            total: activeInvoice.length || 0,
            previousTotal: previousBillingWeekSummary.length || 0,
        },
        jobs: {
            stats: jobStats,
            total: Object.keys(jobStats).length || 0,
            previousTotal: Object.keys(jobStats).length * 0.7 || 0,
        },
        billing: {
            regular:
                Number((activeInvoiceItems.sum("regularHoursWorked") * BILLING_RATE).toFixed(2)) || 0,
            overtime:
                Number((activeInvoiceItems.sum("overtimeHoursWorked") * (BILLING_RATE * 1.5)).toFixed(2)) ||
                0,
            doubletime:
                Number((activeInvoiceItems.sum("doubletimeHoursWorked") * (BILLING_RATE * 2)).toFixed(2)) ||
                0,
            billingRate: BILLING_RATE,
            total:
                Number((activeInvoiceItems.sum("overtimeHoursWorked") * (BILLING_RATE * 1.5)).toFixed(2)) +
                Number((activeInvoiceItems.sum("doubletimeHoursWorked") * (BILLING_RATE * 2)).toFixed(2)) +
                Number((activeInvoiceItems.sum("regularHoursWorked") * BILLING_RATE).toFixed(2)),
            previousRegular:
                Number((previousBillingWeekSummary.sum("regularHoursWorked") * BILLING_RATE).toFixed(2)) ||
                0,
            previousOvertime:
                Number(
                    (previousBillingWeekSummary.sum("overtimeHoursWorked") * (BILLING_RATE * 1.5)).toFixed(2)
                ) || 0,
            previousDoubletime:
                Number(
                    (previousBillingWeekSummary.sum("doubletimeHoursWorked") * (BILLING_RATE * 2)).toFixed(2)
                ) || 0,
            pastTotal:
                previousBillingWeekSummary.sum("overtimeHoursWorked") * (BILLING_RATE * 1.5) +
                previousBillingWeekSummary.sum("doubletimeHoursWorked") * (BILLING_RATE * 2) +
                previousBillingWeekSummary.sum("regularHoursWorked") * BILLING_RATE,
        },
        hours: {
            regularHours: activeInvoiceItems.sum("regularHoursWorked") || 0,
            overtime: activeInvoiceItems.sum("overtimeHoursWorked") || 0,
            doubletime: activeInvoiceItems.sum("doubletimeHoursWorked") || 0,
            breaks: activeInvoiceItems.sum("breakValue") / 60 || 0,
            previousRegularHours: previousBillingWeekSummary.sum("regularHoursWorked") || 0,
            previousOvertime: previousBillingWeekSummary.sum("overtimeHoursWorked") || 0,
            previousDoubletime: previousBillingWeekSummary.sum("doubletimeHoursWorked") || 0,
            previousBreaks: previousBillingWeekSummary.sum("breakValue") / 60 || 0,
        },
    };

    return {
        employer: FinanceSelectors.BillingWeekEmployerSummary.employerDetails(state),
        activeInvoice,
        activeInvoiceItems,
        sum,
        previousBillingWeekSummary,
        employerData: ownProps.employerData,
        isLoading: false,
        isInvoiceCommitted: FinanceSelectors.isInvoiceCommitted(state),
        employerWeekInvoices: FinanceSelectors.getEmployerWeekInvoices(state),
        employerSummary: FinanceSelectors.BillingWeekEmployerSummary.employerSummary(state),
        stats,
    };
};

const mapActions = () => {
    const {
        fetchEmployerInvoice,
        fetchEmployerInvoiceItems,
        fetchEmployerInvoices,
        fetchEmployerInvoicesByWeek,
        fetchEmployerBillingWeekSummary,
        uploadFile,
        setUpdateEmployerBillingWeekData,
        fetchEmployerBillingWeekShifts,
        createEmployerInvoice,
        setUpdateData,
        setUpdateEmployerBillingWeekInvoiceData,
        createEmployerInvoiceFromInvoice,
    } = BillingActions;
    return {
        uploadFile,
        fetchEmployerBillingWeekSummary,
        fetchEmployerInvoice,
        fetchEmployerInvoiceItems,
        fetchEmployerInvoices,
        fetchEmployerInvoicesByWeek,
        setUpdateData,
        fetchEmployerBillingWeekShifts,
        createEmployerInvoice,
        setUpdateEmployerBillingWeekData,
        setUpdateEmployerBillingWeekInvoiceData,
        createEmployerInvoiceFromInvoice,
    };
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    return {
        billingWeek: Number(ownProps.match.params.week),
        employerId: ownProps.match.params.employer,
        ...stateProps,
        ...dispatchProps,
        ...ownProps,
    };
};

export default connect(
    mapState,
    mapActions(),
    mergeProps
)(invoiceItemTableStyles(InvoiceItemTable));
