import gql from "graphql-tag";

export const workTypesQuery = gql`
query{
  workTypes{
      id
      createdAt
      updatedAt
      isDeleted
      label
      order
      isActive
      baseRate
      baseBillingRate
      bannerImageUrl
      bannerImageUrl2x
      bannerImageUrl3x
      createdAtUtc
      updatedAtUtc
    }
  }
`;