export default {
  RESET: "workerDetails/RESET",
  SET_LOADING_STATE: "workerDetails/SET_LOADING_STATE",
  SET_WORKER: "workerDetails/SET_WORKER",
  SET_POSITIONS: "workerDetails/SET_POSITIONS",
  SET_POSITIONS_LIST_DATA: "workerDetails/SET_POSITIONS_LIST_DATA",
  SET_WORKER_NOTE: "workerDetails/SET_WORKER_NOTE",
  UPDATE_WORKER_NOTE: "workerDetails/UPDATE_WORKER_NOTE",
  RESET_WORKER_NOTE: "workerDetails/RESET_WORKER_NOTE",
  SET_WORKER_TAGS: "workerDetails/SET_WORKER_TAGS",
  RESET_WORKER_TAGS: "workerDetails/RESET_WORKER_TAGS",
  SET_WORKER_NOTES_LIST_DATA: "workerDetails/SET_WORKER_NOTES_LIST_DATA",
  SET_WORKER_BANNED_FROM_LIST_DATA: "workerDetails/SET_WORKER_BANNED_FROM_LIST_DATA",
  SET_UPDATE_UPDATE_WORKER_DATA: "workerDetails/SET_UPDATE_UPDATE_WORKER_DATA",
  CLEAR_UPDATE_UPDATE_WORKER_DATA: "workerDetails/CLEAR_UPDATE_UPDATE_WORKER_DATA",
  SET_TRANSPORTATION_TYPES: "workerDetails/SET_TRANSPORTATION_TYPES",
  SET_WORKER_ETRANSFERS: "workerDetails/SET_WORKER_ETRANSFERS",
  SET_WORKER_DC_TRANSACTION_LIST: "workerDetails/SET_WORKER_DC_TRANSACTION_LIST",
};
