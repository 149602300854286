import types from "./types";
import { createReducer } from "utils/redux";

const initialLoadingState = {
    tableLoading: true,
};

const initialDialogState = {
    deleteDialog: false,
    addGearDialog: false,
};

const initialState = {
    loading: { ...initialLoadingState },
    dialog: { ...initialDialogState },
    requiredGears: [],
};


const actionsMap = {
    [types.SET_LOADING_STATE]: (state, { payload }) => {
        const { key, value } = payload;
        return {
            ...state,
            loading: {
                ...state.loading,
                [key]: value,
            },
        };
    },
    [types.SET_REQUIRED_GEAR_DATA]: (state, { payload }) => {
        const { requiredGears } = payload;
        return {
            ...state,
            requiredGears,
        };
    },
    [types.SET_DIALOG_STATE]: (state, { payload }) => {
        const { key, value } = payload;
        return {
            ...state,
            dialog: {
                ...state.dialog,
                [key]: value,
            },
        };
    },
}

export default createReducer(initialState, actionsMap); 