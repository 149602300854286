import React from "react";
import Avatar from "@mui/material/Avatar";
import Badgee from "@mui/material/Badge";
import Chip from "@mui/material/Chip";
import withStyles from '@mui/styles/withStyles';
import Tooltip from "@mui/material/Tooltip";
import AccessTime from "@mui/icons-material/AccessTime";
import Cancel from "@mui/icons-material/Cancel";
import CancelScheduleSend from "@mui/icons-material/CancelScheduleSend";
import Check from "@mui/icons-material/Check";
import CheckCircleOutlined from "@mui/icons-material/CheckCircleOutlined";
import CloudUpload from "@mui/icons-material/CloudUpload";
import HelpOutlineOutlined from "@mui/icons-material/HelpOutlineOutlined";
import LowPriority from "@mui/icons-material/LowPriority";
import PriorityHigh from "@mui/icons-material/PriorityHigh";

import SmsFailed from "@mui/icons-material/SmsFailed";
import SpeakerNotes from "@mui/icons-material/SpeakerNotes";
import SpeakerNotesOff from "@mui/icons-material/SpeakerNotesOff";
import Textsms from "@mui/icons-material/Textsms";
// eslint-disable-next-line no-unused-vars
import Warning from "@mui/icons-material/Warning";
import bear from "assets/img/bear.png";
import { PaymentStatusCode } from "helpers/constants/index";

const SuccessBadge = withStyles(theme => ({
    badge: {
        backgroundColor: "#44b700",
        color: "#fff",
        minWidth: 16,
        width: 20,
        height: 20,
        boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
        "&.MuiBadge-anchorOriginBottomRightCircular":{
            bottom:-5,
            right:-5
        },
        "&::after": {
            position: "absolute",
            top: 0,
            fontSize: 0,
            left: 0,
            width: "100%",
            height: "100%",
            borderRadius: "50%",
            animation: "$ripple 1.2s infinite ease-in-out",
            border: "1px solid currentColor",
            content: '""',
        },
    },
}))(Badgee);

const WarningBadge = withStyles(theme => ({
    badge: {
        backgroundColor: "orange",
        color: "#fff",
        minWidth: 16,
        width: 20,
        height: 20,
        boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
        "&.MuiBadge-anchorOriginBottomRightCircular":{
            bottom:-5,
            right:-5
        },
        "&::after": {
            position: "absolute",
            top: 0,
            fontSize: 0,
            left: 0,
            width: "100%",
            height: "100%",
            borderRadius: "50%",
            animation: "$ripple 1.2s infinite ease-in-out",
            border: "1px solid currentColor",
            content: '""',
        },
    },
    "@keyframes ripple": {
        "0%": {
            transform: "scale(.8)",
            opacity: 1,
        },
        "100%": {
            transform: "scale(2.4)",
            opacity: 0,
        },
    },
}))(Badgee);

const StyledBadge = withStyles(theme => ({
    badge: {
        backgroundColor: "#44b700",
        color: "#fff",
        boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
        "&.MuiBadge-anchorOriginBottomRightCircular":{
            bottom:-5,
            right:-5
        },
        "&::after": {
            position: "absolute",
            top: 0,
            fontSize: 0,
            left: 0,
            width: "100%",
            height: "100%",
            borderRadius: "50%",
            animation: "$ripple 1.2s infinite ease-in-out",
            border: "1px solid currentColor",
            content: '""',
        },
    },
    "@keyframes ripple": {
        "0%": {
            transform: "scale(.8)",
            opacity: 1,
        },
        "100%": {
            transform: "scale(2.4)",
            opacity: 0,
        },
    },
}))(Badgee);

const ErrorBadge = withStyles(theme => ({
    badge: {
        backgroundColor: theme.customPalette.red[700],
        color: "#fff",
        boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
        "&.MuiBadge-anchorOriginBottomRightCircular":{
            bottom:-5,
            right:-5
        },
        "&::after": {
            position: "absolute",
            top: 0,
            fontSize: 0,
            left: 0,
            width: "100%",
            height: "100%",
            borderRadius: "50%",
            animation: "$ripple 1.2s infinite ease-in-out",
            border: "1px solid currentColor",
            content: '""',
        },
    },
    "@keyframes ripple": {
        "0%": {
            transform: "scale(.8)",
            opacity: 1,
        },
        "100%": {
            transform: "scale(2.4)",
            opacity: 0,
        },
    },
}))(Badgee);

function Badge({ classes, Icon, showLabel, label }) {
    if (!showLabel) {
        return (
            <Tooltip title={label} placement="top">
                <Avatar className={classes.avatarRoot}>
                    <Icon fontSize="default" />
                </Avatar>
            </Tooltip>
        );
    }

    return (
        <Chip icon={<Icon className={classes.icon} />} title={label} className={classes.chipRoot} />
    );
}

const styleFactory = color =>
    withStyles(({ customPalette, typography, spacing }) => ({
        chipRoot: {
            backgroundColor: customPalette[color][100],
            ...typography.body1,
            color: customPalette[color][900],
            padding: `${spacing(1)} ${spacing(0.5)}`,
        },
        avatarRoot: {
            backgroundColor: customPalette[color][100],
            ...typography.body1,
            color: customPalette[color][700],
        },
        icon: {
            ...typography.body1,
            color: customPalette[color][700],
            marginRight: -spacing(0.5),
        },
    }));

export const MissingInfoBadge = styleFactory("red")(props => (
    <Badge Icon={Warning} label="sxsxs" {...props} />
));

export const QueuedBadge = styleFactory("blue")(props => (
    <Badge Icon={AccessTime} label="eTransfer Queued" {...props} />
));

export const SendingBadge = styleFactory("blue")(props => (
    <Badge Icon={CloudUpload} label="Sending eTransfer " {...props} />
));

export const AwaitingResponseBadge = styleFactory("blue")(props => (
    <Badge Icon={Textsms} label="Awaiting Response" {...props} />
));

export const DeliveredBadge = styleFactory("green")(props => (
    <Badge Icon={CheckCircleOutlined} label="eTransfer Delivered" {...props} />
));

export const AcceptedBadge = styleFactory("green")(props => (
    <Badge Icon={Check} label="eTransfer Accepted" {...props} />
));

export const DeclinedBadge = styleFactory("red")(props => (
    <Badge Icon={SpeakerNotesOff} label="eTransfer Declined" {...props} />
));

export const FailedBadge = styleFactory("red")(props => (
    <Badge Icon={SmsFailed} label="eTransfer Failed" {...props} />
));

export const CancelledBadge = styleFactory("red")(props => (
    <Badge Icon={Cancel} label="eTransfer Cancelled" {...props} />
));

export const DeferredBadge = styleFactory("yellow")(props => (
    <Badge Icon={LowPriority} label="eTransfer Deferred" {...props} />
));

export const ResolvedBadge = styleFactory("yellow")(props => (
    <Badge Icon={CancelScheduleSend} label="eTransfer Manually Resolved" {...props} />
));

export const UnknownBadge = styleFactory("red")(props => (
    <Badge Icon={HelpOutlineOutlined} label="Unknown" {...props} />
));

export const WorkerAvatar = styleFactory("red")(({ data }) => {
    return (
        <div>
            {data.dcStatus === "Cancelled" || data.interacStatus === "Cancelled" ? (
                <ErrorBadge
                    overlap="circle"
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    badgeContent={data.dcTransactions.length}>
                    <Avatar src={data.dcTransactions[0].worker.signedProfileImageUrl} />
                </ErrorBadge>
            ) : (
                <StyledBadge
                    overlap="circle"
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    badgeContent={data.dcTransactions.length}>
                    <Avatar src={data.dcTransactions[0].worker.signedProfileImageUrl} />
                </StyledBadge>
            )}
        </div>
    );
});

//
//    marginTop: spacing(0),
// marginBottom: spacing(0),
//

export const ProcessingResponseBadge = styleFactory("blue")(props => (
    <Badge Icon={SpeakerNotes} label="Processing Response" {...props} />
));

const paymentStatusCodeBadgeMap = {
    U: AwaitingResponseBadge,
    P: ProcessingResponseBadge,
    S: DeliveredBadge,
    C: AcceptedBadge,
    RJ: DeclinedBadge,
    E: FailedBadge,
    V: CancelledBadge,
    BL: CancelledBadge,
    IB: CancelledBadge,
    DF: DeferredBadge,
    MR: ResolvedBadge,
    [PaymentStatusCode.Unknown]: UnknownBadge,
};
export function DcTransferStatusBadge({ code, ...props }) {
    const PaymentStatusBadge = paymentStatusCodeBadgeMap[code];
    return <PaymentStatusBadge iconOnly {...props} />;
}
export const RenderWorkerAvatar = ({ data, code }) => {
    return (
        <div>
            {code === "E" || code === "V" || code === "RJ" ? (
                <ErrorBadge
                    overlap="circle"
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    badgeContent={data.dcTransactions.length}>
                    <Avatar src={data.dcTransactions[0].worker.signedProfileImageUrl} />
                </ErrorBadge>
            ) : code === "S" || code === "C" ? (
                <SuccessBadge
                    overlap="circle"
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    badgeContent={<PriorityHigh style={{ width: 12, height: 12 }} />}>
                    <Avatar src={data.dcTransactions[0].worker.signedProfileImageUrl} />
                </SuccessBadge>
            ) : code === "DF" || code === "P" || code === "MR" ? (
                <WarningBadge
                    overlap="circle"
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    badgeContent={<PriorityHigh style={{ width: 12, height: 12 }} />}>
                    <Avatar src={data.dcTransactions[0].worker.signedProfileImageUrl} />
                </WarningBadge>
            ) : (
                <StyledBadge
                    overlap="circle"
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    badgeContent={data.dcTransactions.length}>
                    <Avatar src={data.dcTransactions[0].worker.signedProfileImageUrl} />
                </StyledBadge>
            )}
        </div>
    );
};
export const RenderWorkerReversalAvatar = ({ data, code }) => {
    return (
        <div>
            {code === "E" || code === "V" || code === "RJ" ? (
                <ErrorBadge
                    overlap="circle"
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    badgeContent={data.dcTransactions.length}>
                    <Avatar src={data.signedProfileImageUrl} />
                </ErrorBadge>
            ) : code === "S" || code === "C" ? (
                <SuccessBadge
                    overlap="circle"
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    badgeContent={<PriorityHigh style={{ width: 12, height: 12 }} />}>
                    <Avatar src={data.signedProfileImageUrl} />
                </SuccessBadge>
            ) : code === "DF" || code === "P" || code === "MR" ? (
                <WarningBadge
                    overlap="circle"
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    badgeContent={<PriorityHigh style={{ width: 12, height: 12 }} />}>
                    <Avatar src={data.signedProfileImageUrl} />
                </WarningBadge>
            ) : (
                <StyledBadge
                    overlap="circle"
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    badgeContent={1}>
                    <Avatar src={data.signedProfileImageUrl} />
                </StyledBadge>
            )}
        </div>
    );
};
export const RenderTransferAvatar = ({ data, code }) => {
    const pic =
        data && data.signedProfileImageUrl && data.signedProfileImageUrl.length ? data.signedProfileImageUrl : bear;
    return (
        <div>
            {code === "E" || code === "V" || code === "RJ" ? (
                <ErrorBadge
                    overlap="circle"
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    badgeContent={1}>
                    <Avatar src={data.signedProfileImageUrl} />
                </ErrorBadge>
            ) : code === "S" || code === "C" ? (
                <SuccessBadge
                    overlap="circle"
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    badgeContent={<PriorityHigh style={{ width: 12, height: 12 }} />}>
                    <Avatar src={data.signedProfileImageUrl} />
                </SuccessBadge>
            ) : code === "DF" || code === "P" || code === "MR" ? (
                <WarningBadge
                    overlap="circle"
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    badgeContent={<PriorityHigh style={{ width: 12, height: 12 }} />}>
                    <Avatar src={data.signedProfileImageUrl} />
                </WarningBadge>
            ) : (
                <StyledBadge
                    overlap="circle"
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    badgeContent={1}>
                    <Avatar src={pic} />
                </StyledBadge>
            )}
        </div>
    );
};
