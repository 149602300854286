import ApolloClient from "utils/apollo";
import { flattenGraphQLArray, reorderArray } from "utils/graphql";
import { actions as MetaActions } from "store/modules/meta";
import createFaqMutation from "./graphql/mutations/create-faq";
import reorderFaqMutation from "./graphql/mutations/reorder-faqs";
import updateFaqMutation from "./graphql/mutations/update-faq";
import { updateAdminSettingMutation } from "./graphql/mutations/admin-setting";
import updateTermsAndConditionMutation from "./graphql/mutations/update-terms-and-condition";
import fetchFaqsQuery from "./graphql/queries/fetch-faqs";
import fetchTermsAndConditionQuery from "./graphql/queries/terms-and-condition";
import fetchAdminSettingQuery from "./graphql/queries/fetch-admin-setting";
import types from "./types";

const setLoadingState = key => value => ({
  type: types.SET_LOADING_STATE,
  payload: { key, value },
});

const setIsAdminSettingSaveLoading = setLoadingState("adminSettingSave");

export const setIsInfoLoading = isInfoLoading => ({
  type: types.IS_LOADING_INFO,
  payload: { isInfoLoading },
});

export const setTermsOfServiceLoading = isTermsOfServiceLoading => ({
  type: types.IS_LOADING_TERMS_OF_SERVICE,
  payload: { isTermsOfServiceLoading },
});

export const setFaqs = faqs => ({
  type: types.SET_FAQS,
  payload: { faqs },
});

export const setAdminSetting = setting => ({
  type: types.SET_ADMIN_SETTING,
  payload: { setting },
});

export const updateFaqData = (field, value) => ({
  type: types.UPDATE_FAQ_EDIT_DATA,
  payload: { field, value },
});

export const setFaqEditableData = (faqId, type) => ({
  type: types.SET_FAQ_EDIT_DATA,
  payload: { faqId, type },
});

export const updateCreateFaqData = (field, value) => ({
  type: types.SET_FAQ_CREATE_DATA,
  payload: { field, value },
});

export const resetFaqCreateData = () => ({
  type: types.RESET_FAQ_CREATE_DATA,
});

export const setFaqTypeList = (list, type) => ({
  type: types.SET_FAQ_LIST,
  payload: {
    type,
    list,
  },
});
export const setTermsOfServiceData = (termsOfService) => ({
  type: types.SET_TERMS_OF_SERVICE_DATA,
  payload: {
    termsOfService
  },
});


export const fetchFaqs = () => dispatch => {
  dispatch(setIsInfoLoading(true));
  return ApolloClient.query({
    query: fetchFaqsQuery,
      fetchPolicy: "no-cache"
  })
    .then(({ data }) => {
      dispatch(setFaqs(flattenGraphQLArray(data.faqs)));
      dispatch(setIsInfoLoading(false));
    })
    .catch(() => {
      dispatch(MetaActions.errorToast("Failed to load FAQs."));
      dispatch(setIsInfoLoading(false));
    });
};

export const createFaq = faqType => (dispatch, getState) => {
  const { newFaqData } = getState().faqs;
  dispatch(setIsInfoLoading(true));
  return ApolloClient.mutate({
    mutation: createFaqMutation,
    variables: {
      data: { ...newFaqData, type: faqType.type },
    },
  })
    .then(() => {
      dispatch(resetFaqCreateData());
      dispatch(fetchFaqs());
      dispatch(setIsInfoLoading(false));

      dispatch(MetaActions.successToast("Successfully create a new FAQ."));
    })
    .catch(() => {
      dispatch(resetFaqCreateData());
      dispatch(setIsInfoLoading(false));
      dispatch(MetaActions.errorToast("   Failed to create this new FAQ."));
    });
};

export const toggleFaqSortOrder = (oldIndex, newIndex, type) => (dispatch, getState) => {
  const oldList = getState().faqs[type];
  const list = reorderArray(oldIndex, newIndex, getState().faqs[type]);

  dispatch(setFaqTypeList(list, type));
  return ApolloClient.mutate({
    mutation: reorderFaqMutation,
    variables: {
      ordering: list.map(({ id }) => id),
    },
  })
    .then(() => {
      dispatch(MetaActions.successToast("FAQ sort order updated."));
    })
    .catch(() => {
      dispatch(setFaqTypeList(oldList, type));
      dispatch(MetaActions.errorToast("   Failed to reorder FAQs."));
    });
};

export const toggleFaqStatus = (faqId, status) => dispatch => {
  return ApolloClient.mutate({
    mutation: updateFaqMutation,
    variables: { data: { id: faqId, isActive: status } },
  })
    .then(() => {
      dispatch(fetchFaqs());
      dispatch(MetaActions.successToast("FAQ status successfully updated."));
    })
    .catch(() => {
      dispatch(MetaActions.errorToast("   Failed to update FAQ sort order."));
    });
};

export const updateFaq = id => (dispatch, getState) => {
  const { question, answer } = getState().faqs.editFaqData;
  dispatch(setIsInfoLoading(true));
  return ApolloClient.mutate({
    mutation: updateFaqMutation,
    variables: {
      data: {
        id,
        question,
        answer,
      },
    },
  })
    .then(() => {
      dispatch(setIsInfoLoading(false));
      dispatch(fetchFaqs());
      dispatch(MetaActions.successToast("FAQ updated successfully."));
    })
    .catch(() => {
      dispatch(setIsInfoLoading(false));
      dispatch(MetaActions.errorToast("   Failed to update FAQ."));
    });
};

export const deleteFaq = faqId => dispatch => {
  return ApolloClient.mutate({
    mutation: updateFaqMutation,
    variables: { data: { id: faqId, isDeleted: true } },
  })
    .then(() => {
        dispatch(setIsInfoLoading(false));
        dispatch(fetchFaqs());
      dispatch(MetaActions.successToast("Successfully deleted."));
    })
    .catch(() => {
      dispatch(fetchFaqs());
      dispatch(setIsInfoLoading(false));
      dispatch(MetaActions.errorToast("   Failed to delete FAQ."));
    });
};

export const fetchTermsOfService = () => dispatch => {
  dispatch(setTermsOfServiceLoading(true));
  return ApolloClient.query({
    query: fetchTermsAndConditionQuery,
      fetchPolicy: "no-cache"
  })
    .then(({ data, errors }) => {
      if (!data && errors.length > 0) {
        throw new Error(errors[0].message);
      } else {
        dispatch(setTermsOfServiceLoading(false));
        dispatch(setTermsOfServiceData(data.termsAndConditions.termsAndConditions));
      }
    })
    .catch(() => {
      dispatch(setTermsOfServiceLoading(false));
      dispatch(MetaActions.errorToast("Failed to fetch terms of service."));
    });
};

export const updateTermsAndConditions = termsAndConditions => dispatch => {
  dispatch(setTermsOfServiceLoading(true));
  return ApolloClient.mutate({
    mutation: updateTermsAndConditionMutation,
    variables: { termsAndConditions },
  })
    .then(({ data, errors}) => {
      if (!data && errors.length > 0) {
          throw new Error(errors[0].message);
      } else {
        dispatch(setTermsOfServiceLoading(false));
        dispatch(MetaActions.successToast("Successfully updated."));
      }
    })
    .catch(() => {
      dispatch(setTermsOfServiceLoading(false));
      dispatch(MetaActions.errorToast("Failed to update terms of service."));
    });
};

export const fetchAdminSetting = (isReload) => dispatch => {
  return ApolloClient.query({
    query: fetchAdminSettingQuery,
      fetchPolicy: isReload ? "no-cache" : "cache-first"
  })
    .then(({ data }) => {
      if(data && data.adminSettings){
        dispatch(setAdminSetting(data.adminSettings));
      }
    })
    .catch(() => {
      dispatch(MetaActions.errorToast("Failed to load admin setting."));
    });
};

export const updateAdminSettings = (id, value) => (dispatch, getState) => {

  dispatch(setIsAdminSettingSaveLoading(true));
  return ApolloClient.mutate({
    mutation: updateAdminSettingMutation,
    variables: {
      data: {
        id,
        value
      },
    },
  })
    .then(({ data, errors }) => {
      if(!data && errors.length > 0){
        throw Error(errors[0].message);
      }
      dispatch(fetchAdminSetting(true));
      dispatch(MetaActions.successToast("Successfully update admin setting."));
      dispatch(setIsAdminSettingSaveLoading(false));
    })
    .catch((error) => {
      dispatch(setIsAdminSettingSaveLoading(false));
      dispatch(MetaActions.errorToast(`Failed: ${error.message}`));
    });
};