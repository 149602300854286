import React, { useEffect, useRef, useState } from "react";
import CurrencyFormat from "react-currency-format";
import PropTypes from "prop-types";
import Tooltip from "@mui/material/Tooltip";
import CurrencyTextField from "./CurrencyTextField";

function CurrencyInput({
    label,
    minValue,
    minValueHelperText,
    maxValue,
    value: inputValue,
    onChange,
    tooltip,
}) {
    const [value, setValue] = useState(inputValue);
    const inputEl = useRef(null);

    useEffect(() => {
        setValue(inputValue);
    }, [inputValue]);

    const updateRate = ({ floatValue }) => {
        setValue(floatValue);
    }

    function handleChange() {
        onChange(value);
    }

    function isAllowed({ floatValue }) {
        return floatValue <= maxValue;
    }

    const handleKeyDown = e => {
        e.preventDefault();
        if (e.key === "ArrowUp" || e.key === "ArrowDown") {
            e.preventDefault();
            const change = e.key === "ArrowUp" ? 0.05 : -0.05;
            const newValue = +(parseFloat(value) + change).toFixed(2);
            if (isAllowed({ floatValue: newValue })) {
                setValue(newValue);
                onChange(newValue);
            }
        }
    };

    const Content = () => (
        <CurrencyFormat
            ref={inputEl}
            label={label}
            minValue={minValue}
            minValueHelperText={minValueHelperText}
            isNumericString
            allowNegative
            decimalScale={2}
            fixedDecimalScale
            customInput={CurrencyTextField}
            value={value}
            isAllowed={isAllowed}
            onValueChange={updateRate}
            // onKeyDown={handleKeyDown}
            onBlur={handleChange}
        />
    );

    if (tooltip) {
        return (
            <Tooltip title={tooltip} placement="top">
                {<Content />}
            </Tooltip>
        );
    }

    return <Content />;
}

CurrencyInput.propTypes = {
    minValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    maxValue: PropTypes.number,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    tooltip: PropTypes.string,
    label: PropTypes.string,
    minValueHelperText: PropTypes.string,
    onChange: PropTypes.func.isRequired,
};

CurrencyInput.defaultProps = {
    maxValue: 100,
    tooltip: null,
    label: null,
};

export default CurrencyInput;
