import React from "react";
import { withRouter } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import withStyles from '@mui/styles/withStyles';
import Edit from "@mui/icons-material/Edit";
import MoreHoriz from "@mui/icons-material/MoreHoriz";
import Can from "components/Authorization/Can";
import { JobPermissions } from "helpers/constants/permissions";

const styles = ({ typography, spacing }) => ({
    icon: {
        fontSize: typography.pxToRem(20),
        marginRight: spacing(2),
    },
});

class JobActionMenu extends React.Component {

    state = {
        anchorEl: null,
    };

    handleClick = event => {
        this.setState({ anchorEl: event.currentTarget });
    };

    handleClose = () => {
        this.setState({ anchorEl: null });
    };


    handleEdit = () => {
        this.props.openEditDialog();
        this.handleClose();
    };

    render() {
        const { anchorEl } = this.state;
        const id = `action-menu-${this.props.jobId}`;

        return (
            <React.Fragment>
                <IconButton
                    aria-owns={anchorEl ? id : undefined}
                    aria-haspopup="true"
                    disableRipple
                    onClick={this.handleClick}
                    size="large">
                    <MoreHoriz color="primary" />
                </IconButton>
                <Can perform={JobPermissions.update.detail.value}>
                    {allowed => {
                        if (!allowed) {
                            return null;
                        }
                        return (anchorEl ? (
                            <Menu
                                id={id}
                                anchorEl={anchorEl}
                                open={Boolean(anchorEl)}
                                onClose={this.handleClose}
                                disableAutoFocusItem>
                                <MenuItem onClick={this.handleEdit}>
                                    <Edit color="action" />&nbsp;
                                    Edit Job
                                </MenuItem>
                            </Menu>
                        ) : null)
                    }}
                </Can>
            </React.Fragment>
        );
    }
}

export default withStyles(styles)(withRouter(JobActionMenu));
