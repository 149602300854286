import React, { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import Grid from "@mui/material/Grid";

import { actions as FinanceActions } from "store/modules/finance";
import PayrollSummaryDialog from "../../Dialogs/PayrollSummaryDialog";
import TransferActionDialog from "../../Dialogs/TransferActionDialog";
import PaymentTilesCard from "./Cards/PaymentTilesCard";
import IncompleteJobsTable from "./Tables/IncompleteJobsTable";
import JobsWithPendingPayTable from "./Tables/JobsWithPendingPayTable";
import { actions as JobDetailActions } from "../../../../../../store/modules/jobDetails";

function PaymentOverviewTab({ toggleShowTransferStatusActionsDialog }) {
  const dispatch = useDispatch();
  const [isReviewingPayroll, setIsReviewingPayroll] = useState(false);

  const [jobId, setJobId] = useState("");
  const [transferType] = useState("Expiring");

  useEffect(() => {
    // dispatch(FinanceActions.fetchPaymentsOverview());
  }, [dispatch]);

  const toggleReviewingPayroll = useCallback(
    jobId => {
      setJobId(jobId);
      setIsReviewingPayroll(v => !v);
    },
    [jobId, isReviewingPayroll]
  );

  const handlePayJob = () => {
      dispatch(FinanceActions.payPendingJob())
                .then(() => toggleReviewingPayroll());
  }

  return (
    <Grid container>
      <PaymentTilesCard
        toggleShowTransferStatusActionsDialog={toggleShowTransferStatusActionsDialog}
      />
      <Grid container item xs={12}>
        <Grid item xs={6}>
          <JobsWithPendingPayTable toggleReviewingPayroll={toggleReviewingPayroll} />
        </Grid>
        <Grid item xs={6}>
          <IncompleteJobsTable />
        </Grid>
      </Grid>
      {/* <RenderDrawer /> */}
      <PayrollSummaryDialog
        visible={isReviewingPayroll}
        onCancel={toggleReviewingPayroll}
        toggleReviewingPayroll={toggleReviewingPayroll}
        onConfirm={handlePayJob}
        jobId={jobId}
      />

      <TransferActionDialog
        visible={false}
        onCancel={toggleShowTransferStatusActionsDialog}
        toggleShowTransferStatusActionsDialog={toggleShowTransferStatusActionsDialog}
        onConfirm={toggleShowTransferStatusActionsDialog}
        jobId={jobId}
        transferType={transferType}
      />
    </Grid>
  );
}

export default PaymentOverviewTab;
