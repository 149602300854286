import React from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import makeStyles from '@mui/styles/makeStyles';
import { selectors as JobDetailSelectors } from "store/modules/jobDetails";
import useAvatar from "./columns/avatar";
import useName from "./columns/name";
import useRating from "./columns/rating";
import ReactTableSimple from "components/ReactTable/ReactTableSimple";

const useStyles = makeStyles(({ shape, typography, spacing }) => ({
    table: {
        flex: 1,
        border: "none",
        borderRadius: shape.borderRadius / 2,
        width: "100%",
    },
    icon: {
        fontSize: typography.pxToRem(20),
        marginRight: spacing(2),
    },
}));

function RatingAllTable(props) {
    const classes = useStyles();
    const isLoading = useSelector(JobDetailSelectors.getIsUpdatingPositions);
    const positions = useSelector(JobDetailSelectors.getPositions);

    const columns = [
        useAvatar(props.isRatingAll),
        useName(props.isRatingAll, props.isRatingAll),
        useRating(props.isRatingAll, props.isRatingAll),
    ];

    if (!props.isRatingAll) {
        return null;
    }

    return (
        <ReactTableSimple
            showPaginationBottom={true}
            classes={classes.table}
            data={positions}
            loading={isLoading}
            columns={columns}
            showFooter
        />
    );
}

RatingAllTable.propTypes = {
    onChooseWorker: PropTypes.func.isRequired,
    isWorkerPickerOpen: PropTypes.bool.isRequired,
    isRatingAll: PropTypes.bool.isRequired,
    isCheckingInAll: PropTypes.bool.isRequired,
    isCheckingOutAll: PropTypes.bool.isRequired,
    isEditingRatesAll: PropTypes.bool.isRequired,
};

export default RatingAllTable;
