import React from "react";
import { connect } from "react-redux";
import Grid from "@mui/material/Grid";
import LoggedInUserView from "./LoggedInUserView";

class Dashboard extends React.Component {
  render() {
    return (
      <Grid container justifyContent="center">
        <Grid item xs={12} lg={10}>
          <LoggedInUserView props={this.props} />
        </Grid>
      </Grid>
    );
  }
}
export default connect()(Dashboard);
