import React, { useCallback } from "react";
import { connect } from "react-redux";
import classNames from "classnames";
import Avatar from "@mui/material/Avatar";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import withStyles from '@mui/styles/withStyles';
import Tooltip from "@mui/material/Tooltip";
import AddCircle from "@mui/icons-material/AddCircle";
import Close from "@mui/icons-material/Close";
import RemoveCircle from "@mui/icons-material/RemoveCircle";
import _throttle from "lodash/throttle";
import { formatInitials, formatPhone } from "utils/stringUtils";
import { actions as EmployerDetailActions, selectors as EmployerDetailSelectors } from "store/modules/employerDetails";
import ReactTableCustom from "components/ReactTable";
import { MenuItem, Select } from "@mui/material";

const TableWrapper = ({
    onPageChange,
    data,
    isLoading,
    totalCount,
    currentPage,
    pageSize,
    pageSort,
    onFilteredChange,
    onSortedChange,
    currenList,
    classes,
    onAddWorker,
    onRemoveWorker,
    scrollToEnd,
    ...props
}) => {

    const fetchData = useCallback(({ pageIndex, filters, sortBy }) => {
        onFilteredChange(filters);
        onSortedChange(sortBy);
        onPageChange(pageIndex);
    }, []);

    return (
        <ReactTableCustom
            fetchData={fetchData}
            data={data}
            loading={isLoading}
            pageCount={totalCount}
            currentPage={currentPage}
            defaultPageSize={pageSize}
            defaultSort={pageSort}
            rowsPerPageOptions={{ rowsPerPageOptions: [pageSize] }}
            columns={[
                {
                    Header: "",
                    id: "profileImage",
                    // eslint-disable-next-line react/display-name, react/prop-types
                    accessor: ({ signedProfileImageUrl, firstName, lastName }) => {
                        return (
                            <Avatar src={signedProfileImageUrl}>
                                {formatInitials(firstName, lastName, "")}
                            </Avatar>
                        );
                    },

                    disableSortBy: true,
                    disableFilters: true,
                    width: 100,
                },
                {
                    Header: "First Name",
                    id: "firstName",
                    // eslint-disable-next-line react/display-name, react/prop-types
                    accessor: ({ firstName }) => {
                        if (!firstName) {
                            return <i>Missing first name</i>;
                        }

                        return firstName;
                    },
                },
                {
                    Header: "Last Name",
                    id: "lastName",
                    // eslint-disable-next-line react/display-name, react/prop-types
                    accessor: ({ lastName }) => {
                        if (!lastName) {
                            return <i>Missing last name</i>;
                        }

                        return lastName;
                    },
                },
                {
                    Header: "Email",
                    id: "email",
                    // eslint-disable-next-line react/display-name, react/prop-types
                    accessor: ({ email }) => {
                        if (!email) {
                            return <i>Missing email.</i>;
                        }

                        return email;
                    },
                },
                {
                    Header: "Phone",
                    id: "phone",
                    // eslint-disable-next-line react/display-name, react/prop-types
                    accessor: ({ phone }) => {
                        if (!phone) {
                            return <i>Empty.</i>;
                        }

                        return formatPhone(phone);
                    },
                },
                {
                    Header: "Location",
                    id: "city",
                    // eslint-disable-next-line react/display-name, react/prop-types
                    accessor: ({ city, region }) => {
                        if (!city || !region) {
                            return <i>Missing location</i>;
                        }

                        return `${city}, ${region}`;
                    },
                },
                {
                    // eslint-disable-next-line react/display-name, react/prop-types
                    Header: "Rating",
                    id: "rating",
                    // eslint-disable-next-line react/display-name, react/prop-types
                    accessor: ({ rating }) => {
                        if (!rating) {
                            return <i>Not rated.</i>;
                        }

                        return rating;
                    },
                    // eslint-disable-next-line react/display-name, react/prop-types
                    Filter: ({ setFilter, column }) => (
                        <div
                            style={{
                                textAlign: "center",
                            }}>
                            <Select
                                disableUnderline={false}
                                variant="standard"
                                onChange={event => setFilter(column.id, event.target.value.trim())}
                                value={column.filterValue ? column.filterValue || " " : " "}>
                                <MenuItem value=" ">All</MenuItem>
                                <MenuItem value="5">5</MenuItem>
                                <MenuItem value="4">4 +</MenuItem>
                                <MenuItem value="3">3 +</MenuItem>
                                <MenuItem value="2">2 +</MenuItem>
                            </Select>
                        </div>
                    ),

                    width: 100,
                    style: { textAlign: "center" },
                    headerStyle: { textAlign: "center" },
                },
                {
                    // eslint-disable-next-line react/display-name, react/prop-types
                    Header: () => (
                        <Tooltip title="Site History" placement="top">
                            <span>SH.</span>
                        </Tooltip>
                    ),
                    id: "positionCountByEmployer",
                    // eslint-disable-next-line react/display-name, react/prop-types
                    accessor: ({ positionCountByEmployer }) => {
                        if (
                            !positionCountByEmployer &&
                            positionCountByEmployer !== 0
                        ) {
                            return "-";
                        }

                        return positionCountByEmployer;
                    },

                    width: 70,
                    disableFilters: true,
                    style: { textAlign: "center" },
                    headerStyle: { textAlign: "center" },
                },
                {
                    // eslint-disable-next-line react/display-name, react/prop-types
                    Header: () => (
                        <Tooltip title="Jobs Worked" placement="top">
                            <span>JW.</span>
                        </Tooltip>
                    ),
                    id: "positionCount",
                    // eslint-disable-next-line react/display-name, react/prop-types
                    accessor: ({ positionCount }) => {
                        if (!positionCount && positionCount !== 0) {
                            return "-";
                        }

                        return positionCount;
                    },
                    //
                    width: 70,
                    disableFilters: true,
                    style: { textAlign: "center" },
                    headerStyle: { textAlign: "center" },
                },
                {
                    // eslint-disable-next-line react/display-name, react/prop-types
                    Header: () => (
                        <Tooltip title="Bans" placement="top">
                            <span>B.</span>
                        </Tooltip>
                    ),
                    id: "banCount",
                    // eslint-disable-next-line react/display-name, react/prop-types
                    accessor: ({ banCount }) => {
                        if (!banCount && banCount !== 0) {
                            return "-";
                        }

                        return banCount;
                    },

                    width: 70,
                    disableFilters: true,
                    style: { textAlign: "center" },
                    headerStyle: { textAlign: "center" },
                },
                {
                    // eslint-disable-next-line react/display-name, react/prop-types
                    Header: () => (
                        <Tooltip title="No Shows" placement="top">
                            <span>NS.</span>
                        </Tooltip>
                    ),
                    id: "noShowCount",
                    // eslint-disable-next-line react/display-name, react/prop-types
                    accessor: ({ noShowCount }) => {
                        if (!noShowCount && noShowCount !== 0) {
                            return "-";
                        }

                        return noShowCount;
                    },

                    width: 70,
                    disableFilters: true,
                    style: { textAlign: "center" },
                    headerStyle: { textAlign: "center" },
                },
                {
                    Header: "",
                    id: "actions",
                    collapse: true,
                    style: { textAlign: "right" },
                    disableSortBy: true,
                    disableFilters: true,
                    Cell: ({ row: { original } }) => {
                        // if (banned.includes(original.id)) {
                        //     return (
                        //         <Tooltip title="Banned" placement="top">
                        //             <Report className={classes.warning} />
                        //         </Tooltip>
                        //     );
                        // }
                        // if (
                        //     positions.some(
                        //         ({ workerId }) => workerId === original.id,
                        //     )
                        // ) {
                        //     return (
                        //         <Tooltip title="On this job" placement="top">
                        //             <CheckCircle className={classes.check} />
                        //         </Tooltip>
                        //     );
                        // }
                        // if (!original.isAvailableForJob) {
                        //     return (
                        //         <Tooltip
                        //             title="Already on a job"
                        //             placement="top"
                        //         >
                        //             <Error className={classes.warning} />
                        //         </Tooltip>
                        //     );
                        // }
                        // if (original.isSuspended) {
                        //     return (
                        //         <Tooltip title="Suspended" placement="top">
                        //             <EventBusy className={classes.warning} />
                        //         </Tooltip>
                        //     );
                        // }
                        if (
                            currenList.some(
                                ({ workerId }) => workerId === original.id,
                            )
                        ) {
                            return (
                                <Tooltip title="Remove" placement="top">
                                    <IconButton
                                        disableRipple
                                        onClick={_throttle(() => {
                                            onRemoveWorker(original);
                                            scrollToEnd();
                                        }, 2000)}
                                        size="large">
                                        <RemoveCircle className={classes.remove} />
                                    </IconButton>
                                </Tooltip>
                            );
                        }

                        return (
                            <Tooltip title="Add" placement="top">
                                <IconButton
                                    disableRipple
                                    onClick={_throttle(() => {
                                        onAddWorker(original);
                                        scrollToEnd();
                                    }, 2000)}
                                    size="large">
                                    <AddCircle className={classes.add} />
                                </IconButton>
                            </Tooltip>
                        );
                    },
                },
            ]}
            onRowClick={(row, { column }) => {
                if (row) {
                    if (column.id !== "actions") {
                        // if (
                        //     !rowInfo.original.isSuspended &&
                        //     rowInfo.original.isAvailableForJob &&
                        //     !banned.includes(rowInfo.original.id) &&
                        //     positions.some(
                        //         ({ workerId }) => workerId !== rowInfo.original.id,
                        //     )
                        // ) {
                        //     onAddWorker(rowInfo.original.id, jobId).then(scrollToEnd);
                        // }
                    }
                }
            }}

        />
    )
}

class WorkerPicker extends React.Component {
    state = {
        value: 0,
    };

    render() {
        const {
            classes,
            open,
            onClose,
        } = this.props;

        return (
            <Grid
                container
                className={classNames(classes.wrapper, {
                    [classes.wrapperOpen]: open,
                })}
                alignItems="flex-end"
            >
                <Grid
                    container
                    className={classNames(classes.picker, {
                        [classes.pickerOpen]: open,
                    })}
                    alignContent="flex-start"
                >
                    <Grid container item xs={12} justifyContent="flex-end" className={classes.header}>
                        <IconButton disableRipple onClick={onClose} size="large">
                            <Close />
                        </IconButton>
                    </Grid>
                    <Grid container item xs={12} className={classes.tableContainer}>
                        {this.props.open && <TableWrapper {...this.props} />}
                    </Grid>
                </Grid>
            </Grid>
        );
    }
}

const mapState = state => {
    // const { id, positions, timeframe, banned } = EmployerDetailSelectors.getJobDetails(state);
    const { data, filter, sort } = EmployerDetailSelectors.getWorkerPickerListData(state);
    return {
        ...EmployerDetailSelectors.getWorkerPickerPaginationData(state),
        data,
        pageFilter: filter,
        pageSort: sort,
    };
};

const mapActions = {
    fetchWorkerPicker: EmployerDetailActions.fetchWorkerPicker,
    onPageChange: EmployerDetailActions.fetchWorkerPicker,
    onSortedChange: EmployerDetailActions.setWorkerPickerSort,
    onFilteredChange: EmployerDetailActions.setWorkerPickerFilter,
};

export default connect(
    mapState,
    mapActions,
)(
    withStyles(({ palette, customPalette, spacing }) => ({
        wrapper: {
            // NOTE: Do not apply height to the wrapper
            alignSelf: "flex-end",
            width: "100%",
            overflow: "hidden",
        },
        wrapperOpen: {
            height: 650,
        },
        picker: {
            height: 0,
            opacity: 0,
            transition: `all 0.5s ease`,
            backgroundColor: palette.common.white,
            borderTop: `1px solid ${customPalette.primary[800]}`,
            overflow: "hidden",
            padding: `${spacing(1)} ${spacing(4)}`,
        },
        pickerOpen: {
            opacity: 1,
            height: "600px",
        },
        header: {
            height: "50px",
        },
        tabLabelContainer: {
            padding: 0,
        },
        tableContainer: {
            marginTop: spacing(3),
        },
        add: {
            color: palette.secondary.light,
        },
        remove: {
            color: customPalette.red[500],
        },
    }))(WorkerPicker),
);
