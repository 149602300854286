import React from "react";
import { withRouter } from "react-router";
import SwipeableViews from "react-swipeable-views";
import PropTypes from "prop-types";
import withStyles from '@mui/styles/withStyles';
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import { numberWithCommas } from "./tables/helpers/tableProps";
import { tabStyles } from "./tables/helpers/tabStyles";
import WorkerTable from "./tables/WorkerTable";
import WaitlistTabButton from "./WaitlistTabButton";
import Dialog from "components/Dialog/Dialog";

class WorkerTabs extends React.Component {
    state = {
        value: 0,
        open: false
    };
    componentDidMount() {
        if (this.props.history.location.search) {
            const params = new URLSearchParams(this.props.history.location.search);
            const workerframe = parseInt(params.get("workerframe"), 10);
            const listType = parseInt(params.get("listType"), 10);
            if (!isNaN(listType)) {
                this.setState({ value: parseInt(listType, 10) });
            }
        }
    }

    handleChange = (event, value) => {
        if (this.props.history.location.search) {
            const params = new URLSearchParams(this.props.history.location.search);
            params.set("workerframe", 0);
            params.set("listType", value);
            this.props.history.push(`/workers/overview?${params.toString()}`);
        }

        this.setState({ value });
    };

    render() {
        const { classes, workerStats, onFutureJobClick } = this.props;
        const { value } = this.state;
        const tabClass = {
            root: classes.tabRoot,
            selected: classes.tabSelected,
        };

        return (
            <React.Fragment>
                <div className={classes.tabWrapper} style={{ width: "100%" }}>
                    <Tabs
                        // variant="scrollable"
                        value={value}
                        style={{ width: "100%" }}
                        onChange={this.handleChange}
                        classes={{ indicator: classes.tabIndicator }}>
                        <Tab
                            label={
                                <WaitlistTabButton
                                    title="Total"
                                    headerClass={classes.tabHeader}
                                    labelClass={classes.tabLabel}
                                    count={numberWithCommas(workerStats ? workerStats.allWorkers : 0)}
                                    isLoading={false}
                                />
                            }
                            classes={tabClass}
                        />
                        <Tab
                            label={
                                <WaitlistTabButton
                                    title="Verified"
                                    headerClass={classes.tabHeader}
                                    labelClass={classes.tabLabel}
                                    count={numberWithCommas(workerStats ? workerStats.isVerified : 0)}
                                    isLoading={false}
                                />
                            }
                            classes={tabClass}
                        />
                        <Tab
                            label={
                                <WaitlistTabButton
                                    title="Unverified"
                                    headerClass={classes.tabHeader}
                                    labelClass={classes.tabLabel}
                                    count={numberWithCommas(workerStats ? workerStats.isUnverified : 0)}
                                    isLoading={false}
                                />
                            }
                            classes={tabClass}
                        />
                        <Tab
                            label={
                                <WaitlistTabButton
                                    title="Suspended"
                                    headerClass={classes.tabHeader}
                                    labelClass={classes.tabLabel}
                                    count={numberWithCommas(workerStats ? workerStats.isSuspended : 0)}
                                    isLoading={false}
                                />
                            }
                            classes={tabClass}
                        />
                        <Tab
                            label={
                                <WaitlistTabButton
                                    title="Deactivated"
                                    headerClass={classes.tabHeader}
                                    labelClass={classes.tabLabel}
                                    count={numberWithCommas(workerStats ? workerStats.isDeactivated : 0)}
                                    isLoading={false}
                                />
                            }
                            classes={tabClass}
                        />
                        <Tab
                            label={
                                <WaitlistTabButton
                                    title="Applied"
                                    headerClass={classes.tabHeader}
                                    labelClass={classes.tabLabel}
                                    count={numberWithCommas(workerStats ? workerStats.isApplied : 0)}
                                    isLoading={false}
                                />
                            }
                            classes={tabClass}
                        />
                    </Tabs>
                </div>

                <div className={classes.contentContainer}>
                    <SwipeableViews axis="x" index={value}>
                        <div>
                            {value === 0 && <WorkerTable tabIndex={value} onFutureJobClick={onFutureJobClick} />}
                        </div>
                        <div>
                            {value === 1 && <WorkerTable tabIndex={value} onFutureJobClick={onFutureJobClick} />}
                        </div>
                        <div>
                            {value === 2 && <WorkerTable tabIndex={value} onFutureJobClick={onFutureJobClick} />}
                        </div>
                        <div>
                            {value === 3 && <WorkerTable tabIndex={value} onFutureJobClick={onFutureJobClick} />}
                        </div>
                        <div>
                            {value === 4 && <WorkerTable tabIndex={value} onFutureJobClick={onFutureJobClick} />}
                        </div>
                        <div>
                            {value === 5 && <WorkerTable tabIndex={value} onFutureJobClick={onFutureJobClick} />}
                        </div>
                        <div>
                            {value === 6 && <WorkerTable tabIndex={value} onFutureJobClick={onFutureJobClick} />}
                        </div>
                    </SwipeableViews>
                </div>
            </React.Fragment>
        );
    }
}
WorkerTabs.propTypes = {
    classes: PropTypes.object.isRequired,
    workerStats: PropTypes.object,
};

WorkerTabs.defaultProps = {
    className: null,
    workerStats: null,
};
export default withStyles(tabStyles)(withRouter(WorkerTabs));
