/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */

import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment-timezone";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Grid from "@mui/material/Grid";
import ListItemText from "@mui/material/ListItemText";
import Select from "@mui/material/Select";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { CloudDownloadRounded } from "@mui/icons-material";
import { ThemeProvider, StyledEngineProvider } from "@mui/material/styles";
import { customButtonTheme, StyledMenuItem, topBarCardStyles } from "views/Finance/helpers";
import { actions as FinanceActions, selectors as FinanceSelectors } from "store/modules/finance";
import { checkWeekInvoices } from "store/modules/finance/actions/billingActions";
import BillingService from "./BillingService/BillingService";
import { weeks } from "./helpers/index";
import { getWeekDates, isHoliday } from "../../../../utils/time";

const ButtonComponent = props => {
    const { onClick, loading, label, loadingLabel, uri } = props;
    const downloadLinkProps = uri ? { download: "CloudDownloadRounded", href: uri } : {};

    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={customButtonTheme}>
                <Button
                    variant={loading ? "outlined" : "contained"}
                    color="secondary"
                    {...downloadLinkProps}
                    style={{ height: 34, width: "100%" }}
                    onClick={onClick}
                    startIcon={
                        loading ? <CircularProgress size={18} color="primary" /> : <CloudDownloadRounded />
                    }>
                    {!loading ? label : loadingLabel}
                </Button>
            </ThemeProvider>
        </StyledEngineProvider>
    );
};

const TopBarCard = ({ week, billingYear, classes, getTableData, employer }) => {
    const dispatch = useDispatch();
    const [open, setOpen] = useState(false);
    const [, setAnchorEl] = React.useState(null);
    const [billingWeek, setWeek] = useState(38);
    const [loading, setLoading] = React.useState(false);
    const [sheetLoading, setSheetLoading] = React.useState(false);
    const [uri, setUri] = React.useState(null);
    const [summary, setSummary] = React.useState(null);

    // console.log("emp", employer);
    const generatedBillingSummary = useSelector(FinanceSelectors.getGeneratedBillingSummary);

    const handleButtonClick = () => {
        if (!loading) {
            //   setSuccess(false);
            setLoading(true);

            dispatch(FinanceActions.generateBilling(week)).then(() => {
                return setLoading(false);
            });
        }
    };

    const handleWeeklyBillingSpreadsheetClick = () => {
        if (!sheetLoading) {
            setSheetLoading(true);
            dispatch(
                FinanceActions.createWeeklyBillingSpreadsheet(billingWeek, billingYear)
            ).then(url => {
                setUri(url);
                setSheetLoading(false);
            });
        }
    };

    const handleChange = e => {
        setWeek(Number(e.target.value) || "");
        Number(e.target.value) !== billingWeek && setUri(null);
    };

    const handleClickOpen = () => {
        dispatch(checkWeekInvoices(week, billingYear)).then(res => {
            setSummary(res);
        });
        setOpen(true);
    };
    const handleClose = () => {
        setAnchorEl(null);
        setOpen(false);
    };
    const makeHeaderDate = (week) => {
        const isHoli = isHoliday(week);
        const startEndDate = getWeekDates(week, billingYear);

        return (
            <div>
                <Typography variant="subtitle1" className={classes.title}>
                    {`Week ${week} -
        ${moment(startEndDate.start).format("ddd MMM Do")}
        -
        ${moment(startEndDate.end).format("ddd MMM Do, YYYY")}`}
                    {isHoli && (
                        <Tooltip
                            title={isHoli}
                            aria-label="add">
                            <span className={classes.statHoliday}>Stat Holiday</span>
                        </Tooltip>
                    )}
                </Typography>
            </div>
        );
    };
    const RenderSpreadsheetGeneratorCard = () => (
        <Grid container item md={6} alignItems="stretch" style={{ paddingLeft: 5, paddingRight: 5 }}>
            <Grid container item md={12} alignItems="flex-start">
                <Typography
                    variant="h6"
                    component="h6"
                    style={{
                        paddingBottom: 0,
                        marginBottom: 0,
                        fontSize: 16,
                        fontWeight: 700,
                        width: "100%",
                    }}>
                    Generate Spreadsheet
                </Typography>
                <Typography component="div" style={{ fontSize: 13, paddingTop: 0, width: "100%" }}>
                    Generate timesheets for the current billing week.
                </Typography>
            </Grid>
            <Grid container item md={12} style={{ paddingTop: 10, width: "100%" }} alignItems="flex-end">
                <Select
                    value={billingWeek || week}
                    onChange={handleChange}
                    style={{ marginBottom: 10, width: "100%" }}>
                    {weeks.reverse().map((week, i) => (
                        <StyledMenuItem key={i} value={week}>
                            <ListItemText primary={` Week ${week}`} />
                        </StyledMenuItem>
                    ))}
                </Select>
                <ButtonComponent
                    style={{ alignItems: "flex-end" }}
                    onClick={
                        uri ? console.log("checkWeekInvoices", uri) : handleWeeklyBillingSpreadsheetClick
                    }
                    loading={sheetLoading}
                    label={uri ? `Download Spreadsheet` : `Build Spreadsheet`}
                    uri={uri ? uri : null}
                    loadingLabel="Working Please Wait"
                />
            </Grid>
        </Grid>
    );
    const RenderGeneratorCard = () => (
        <Grid container item md={6} alignItems="stretch" style={{ paddingLeft: 5, paddingRight: 5 }}>
            <Grid container item md={12} alignItems="flex-start">
                <Typography
                    variant="h6"
                    component="h6"
                    style={{
                        paddingBottom: 0,
                        marginBottom: 0,
                        fontSize: 16,
                        fontWeight: 700,
                        width: "100%",
                    }}>
                    Billing Week Generator
                </Typography>

                <Typography component="div" style={{ fontSize: 13, paddingTop: 0, width: "100%" }}>
                    Generate invoices for the current billing week.
                </Typography>

                {generatedBillingSummary.newInvoiceCount ? (
                    <Typography
                        variant="body1"
                        component="div"
                        style={{ fontSize: 13, fontWeight: 700, width: "100%" }}>
                        {generatedBillingSummary.newInvoiceCount} of {summary && summary.invoiceCount}
                        Successfully created
                    </Typography>
                ) : (
                    <Typography
                        variant="body1"
                        component="div"
                        style={{ fontSize: 13, fontWeight: 700, width: "100%" }}>
                        {summary && summary.invoiceCount} Pending Timesheets
                    </Typography>
                )}

                {generatedBillingSummary.shiftCount ? (
                    <Typography
                        variant="body1"
                        component="div"
                        style={{ fontSize: 13, fontWeight: 700, width: "100%" }}>
                        {generatedBillingSummary.shiftCount} Invoice Items Created created
                    </Typography>
                ) : (
                    ""
                )}
                <Typography
                    variant="body1"
                    component="div"
                    style={{ fontSize: 13, fontWeight: 700, width: "100%" }}>
                    {summary && summary.committedInvoiceCount} committed Timesheets not effected.
                </Typography>
            </Grid>
            <Grid container item md={12} style={{ paddingTop: 30, width: "100%" }} alignItems="flex-end">
                <ButtonComponent
                    onClick={handleButtonClick}
                    loading={loading}
                    label="Generate Timesheets"
                    loadingLabel="Working Please Wait"
                />
            </Grid>
        </Grid>
    );

    return (
        <Grid container style={{ marginTop: 8, marginBottom: 8 }}>
            <Grid container item xs={10} md={6} className={classes.title} justifyContent="flex-start">
                {makeHeaderDate(week)}
            </Grid>
            {employer ? "" : <BillingService week={week} year={billingYear} getTableData={getTableData} />}
            {/* <Grid container item xs={2} md={6} justifyContent="flex-end">
        {!employer && <GenerateXlsx />}
      </Grid> */}
        </Grid>
    );
};

export default topBarCardStyles(TopBarCard);
