import { gql } from "@apollo/client";

export default gql`
  query getWorker($id: ID!) {
    worker(id: $id) {
      createdAt

      createdAtUtc
      #   updatedAtUtc
      id
      authId
      firstName
      lastName
      email
      isEmailVerified
      street
      city
      region
      postalCode
      country
      phone
      sin
      profileImageUrl
      signedProfileImageUrl
      identificationImageUrl
      vaccinationStatus
      signedIdentificationImageUrl
      isActive
      isApproved
      approvedAt
      approvedAtUtc
      isSuspended
      suspensionStart
      suspensionStartUtc
      suspensionEndUtc
      noShowCount
      grossPay
      payrollCount
      positionCount
      netPay
      totalDeductions
      timezone
    }
  }
`;
