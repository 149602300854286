import React from "react";
import { Link } from "react-router-dom";
import Grid from "@mui/material/Grid";
import makeStyles from '@mui/styles/makeStyles';
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import Star from "@mui/icons-material/Star";
import { isEmpty } from "lodash";
import { formatMapUrl } from "utils/map";
import { calculateMargins } from "utils/money";
import { formatMoney, formatPhone } from "utils/stringUtils";

const useStyles = makeStyles(({ shape, spacing, palette }) => ({
  container: {
    borderRight: `1px solid ${palette.grey[500]}`,
    paddingRight: spacing(3),
  },
  underlineHover: {
    textDecoration: "none",
    "&:hover": {
      textDecoration: "underline",
    },
  },
  map: {
    width: "350px",
    height: "120px",
    border: `1px solid ${palette.grey[500]}`,
    borderRadius: shape.borderRadius / 2,
    marginTop: spacing(2),
  },
  caption: {
    display: "block",
  },
  rating: {
    display: "flex",
    alignItems: "center",
  },
  ratesLine: {
    maxWidth: 100,
    height: 1,
    backgroundColor: palette.grey[500],
    marginTop: spacing(1),
    marginBottom: spacing(1),
  },
  ratesContainer: {
    cursor: "default",
  },
}));

function EmployerInfo({ employerDetail, detail }) {
  const classes = useStyles();
  const {
    id,
    companyName,
    mobilePhone,
    email,
    firstName,
    lastName,
    rating,
    jobCount,
    payRate,
    billingRate,
  } = employerDetail;
  const { address, city, province, country, postalCode, unit } = detail;

  const { percentage, dollars } = calculateMargins(payRate, billingRate);
  const tooltip = `Margin/hr: ${formatMoney(dollars)} (${percentage}%)`;
  if (isEmpty(employerDetail)) {
    return null;
  }
  return (
    <Grid container item xs={6} className={classes.container}>
      <Grid container item xs={9} direction="column" justifyContent="space-between">
        <div>
          <Link to={`/employers/${id}`} className={classes.underlineHover}>
            <Typography variant="h4">{companyName}</Typography>
          </Link>
          <Typography variant="body1" gutterBottom>
            {(firstName || "") + " " + (lastName || "")}
          </Typography>
          <Typography variant="body1" gutterBottom>
            {email}
          </Typography>
          <Typography variant="body1" gutterBottom>
            {formatPhone(mobilePhone)}
          </Typography>
          <Typography variant="body1" gutterBottom>
            {unit ? `${unit} - ` : ""}
            {`${address || ""}, ${city || ""}, ${province || ""}, ${country || ""}, ${postalCode ||
              ""}`}
          </Typography>
        </div>
        <img
          alt="Job Location Map"
          className={classes.map}
          src={formatMapUrl(address, city, province, "350x120")}
        />
      </Grid>
      <Grid
        container
        item
        xs={3}
        direction="column"
        alignItems="flex-end"
        justifyContent="space-between"
      >
        <div>
          <Typography
            color="textSecondary"
            variant="caption"
            align="right"
            className={classes.caption}
          >
            Rating
          </Typography>
          <Typography variant="h6" align="right" gutterBottom>
            {rating ? (
              <span className={classes.rating}>
                {rating}
                <Star fontSize="small" />
              </span>
            ) : (
              <i>Not Rated</i>
            )}
          </Typography>
          <Typography
            color="textSecondary"
            variant="caption"
            align="right"
            className={classes.caption}
          >
            Jobs
          </Typography>
          <Typography variant="h6" align="right">
            {jobCount}
          </Typography>
        </div>
        <Tooltip title={tooltip} placement="top">
          <div className={classes.ratesContainer}>
            <Typography
              color="textSecondary"
              variant="caption"
              align="right"
              className={classes.caption}
            >
              Base Pay
            </Typography>
            <Typography variant="h6" align="right">
              {`${formatMoney(payRate)}/hr`}
            </Typography>
            <div className={classes.ratesLine} />
            <Typography
              color="textSecondary"
              variant="caption"
              align="right"
              className={classes.caption}
            >
              Base Billable
            </Typography>
            <Typography variant="h6" align="right">
              {`${formatMoney(billingRate)}/hr`}
            </Typography>
          </div>
        </Tooltip>
      </Grid>
    </Grid>
  );
}

export default EmployerInfo;
