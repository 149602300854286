import { gql } from "@apollo/client";
import PayrollFields from "../fragments/payroll-fragment";

export const payJobMutation = gql`
  mutation payJob($data: PayJobInput!) {
    payJob(data: $data) {
      ...PayrollFields
    }
  }
  ${PayrollFields}
`;

export const payJobPaymentMutation =  gql`
mutation payJobPayment($positionId: Int, $jobId: Int) {
  payJobPayment(positionId: $positionId, jobId: $jobId) {
      ...PayrollFields
    }
  }
  ${PayrollFields}
`;

export default payJobMutation;
