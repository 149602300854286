import React, { useState } from "react";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { MoreVert, Delete } from "@mui/icons-material";
import Dialog from "components/Dialog/Dialog";

const PositionActionMenu = props => {
  const id = `action-menu-${props.id}`;
  const [anchorEl, setanchorEl] = useState(null);

  const handleClick = event => {
    setanchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setanchorEl(null);
  };

  const openDeleteDialog = () => {
    props.openDeleteDialog(props.id)
    handleClose();
  }

  return (
    <React.Fragment>
      <IconButton
        aria-owns={anchorEl ? id : undefined}
        aria-haspopup="true"
        disableRipple
        onClick={handleClick}
        size="large">
        <MoreVert color="primary" />
      </IconButton>
      {anchorEl ? (
        <Menu
          id={id}
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}
          disableAutoFocusItem
        >
          <MenuItem onClick={openDeleteDialog}>
            <Delete color="action" />
            &nbsp; Delete
          </MenuItem>
        </Menu>
      ) : null}

      
    </React.Fragment>
  );
};

export default PositionActionMenu;
