import React, { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Typography from "@mui/material/Typography";
import { actions as ReportsActions, selectors as ReportsSelectors } from "store/modules/reports";
import { sharedReactTableProps } from "../../../../utils/constant";
import { formatMoney } from "utils/stringUtils";
import Dialog from "components/Dialog/Dialog";
import Button from "components/Button/Button";
import { GetApp } from "@mui/icons-material";
import { convertUTCToTimezoneWithOffset } from "utils/time";
import ReactTableCustom from "components/ReactTable";

function WorkerJobTableDialog({ openDialog, onCloseDialog, worker }) {
    const dispatch = useDispatch();
    const aTag = React.useRef();
    const payrollData = useSelector(ReportsSelectors.getWorkerJobReportData);
    const PaginationData = useSelector(ReportsSelectors.getWorkerJobReportPagingData);
    const [csvURL, setCsvURL] = React.useState("");

    const fetchData = useCallback(({ pageIndex, pageSize }) => {
        dispatch(ReportsActions.setWorkerJobFilter("workerId", worker.id));
        dispatch(ReportsActions.changeWorkerJobTablePageSize(pageSize));
        dispatch(ReportsActions.fetchWorkerJobTableData(pageIndex));
    }, []);

    const onDownload = () => {
        dispatch(ReportsActions.downloadWorkerJobTableData()).then(data => {
            if (data && data.url) {
                setCsvURL(data.url);
                aTag.current.click();
            }
        });
    };

    const columns = [
        {
            Header: "Job Id",
            disableSortBy: true,
            disableFilters: true,
            width: 80,
            accessor: "JobId",
        },
        {
            Header: "Company Name",
            disableSortBy: true,
            disableFilters: true,
            accessor: "CompanyName",
        },
        {
            Header: "Job Date",
            disableSortBy: true,
            disableFilters: true,
            accessor: "JobStartUtc",
            Cell: ({ row: { original } }) => convertUTCToTimezoneWithOffset(original.JobStartUtc, '', 'MMMM Do, YYYY'),
        },
        {
            Header: <div className="text-right">Hours Worked</div>,
            disableSortBy: true,
            disableFilters: true,
            className: 'text-right',
            accessor: "HoursWorked",
            Cell: ({ row: { original } }) => original.HoursWorked + ' hr'
        },
        {
            Header: <div className="text-right">CPP</div>,
            disableSortBy: true,
            disableFilters: true,
            className: "text-right",
            accessor: "CPP",
            Cell: ({ row: { original } }) => formatMoney(original.CPP),
        },
        {
            Header: <div className="text-right">EI</div>,
            disableSortBy: true,
            disableFilters: true,
            className: "text-right",
            accessor: "EI",
            Cell: ({ row: { original } }) => formatMoney(original.EI),
        },
        {
            Header: <div className="text-right">Fed Tax</div>,
            disableSortBy: true,
            disableFilters: true,
            className: "text-right",
            accessor: "FederalTax",
            Cell: ({ row: { original } }) => formatMoney(original.FederalTax),
        },
        {
            Header: <div className="text-right">Grizzly CPP</div>,
            disableSortBy: true,
            disableFilters: true,
            className: "text-right",
            accessor: "GrizzlyCPP",
            Cell: ({ row: { original } }) => formatMoney(original.GrizzlyCPP),
        },
        {
            Header: <div className="text-right">Grizzly EI</div>,
            disableSortBy: true,
            disableFilters: true,
            className: "text-right",
            accessor: "GrizzlyEI",
            Cell: ({ row: { original } }) => formatMoney(original.GrizzlyEI),
        },
        {
            Header: <div className="text-right">Grizzly WCB</div>,
            disableSortBy: true,
            disableFilters: true,
            className: "text-right",
            accessor: "GrizzlyWCB",
            Cell: ({ row: { original } }) => formatMoney(original.GrizzlyWCB),
        },
        {
            Header: <div className="text-right">Prov. Tax</div>,
            disableSortBy: true,
            disableFilters: true,
            className: "text-right",
            accessor: "ProvTax",
            Cell: ({ row: { original } }) => formatMoney(original.ProvTax),
        },
        {
            Header: <div className="text-right">Bonus Value</div>,
            disableSortBy: true,
            disableFilters: true,
            className: "text-right",
            accessor: "BonusValue",
            Cell: ({ row: { original } }) => formatMoney(original.BonusValue),
        },
        {
            Header: <div className="text-right">Vacation Pay</div>,
            disableSortBy: true,
            disableFilters: true,
            className: "text-right",
            accessor: "VacationPay",
            Cell: ({ row: { original } }) => formatMoney(original.VacationPay),
        },
        {
            Header: <div className="text-right">Gross Pay</div>,
            disableSortBy: true,
            disableFilters: true,
            className: "text-right",
            accessor: "GrossPay",
            Cell: ({ row: { original } }) => formatMoney(original.GrossPay),
        },
        {
            Header: <div className="text-right">Net Pay</div>,
            disableSortBy: true,
            disableFilters: true,
            className: "text-right",
            accessor: "NetPay",
            Cell: ({ row: { original } }) => formatMoney(original.NetPay),
        },
    ];
    return <>
        <Dialog
            open={openDialog}
            title={
                <React.Fragment>
                    <Typography component="span" style={{ width: "100%", fontSize: 18 }}>
                        <div style={{ display: "flex" }}>
                            {`${worker.FirstName ? worker.FirstName : ''} ${worker.LastName ? worker.LastName : ''} Jobs Payroll Report`}
                            <Button
                                icon={<GetApp />}
                                label="Download CSV"
                                alignIcon="left"
                                onClick={onDownload}
                            />
                        </div>

                        <Button
                            style={{ position: "absolute", top: "40px", right: "20px" }}
                            label="X"
                            size="small"
                            onClick={onCloseDialog}
                            color="transparent"
                        />
                    </Typography>
                </React.Fragment>
            }
            description={null}
            onClose={onCloseDialog}
            onCancel={onCloseDialog}
            size="lg"
            hideActions
            alertType="form"
            modalType="editEmployer">
            <ReactTableCustom
                {...sharedReactTableProps}
                data={payrollData}
                fetchData={fetchData}
                hasFilter={false}
                loading={PaginationData.paging.isLoading}
                pageCount={PaginationData.paging.totalCount}
                currentPage={PaginationData.paging.currentPage}
                defaultPageSize={PaginationData.paging.pageSize}
                classes="reporta-table"
                columns={columns}
            />
        </Dialog>
        <a ref={aTag} href={csvURL} target="_blank" rel="noreferrer" />
    </>;
}

export default WorkerJobTableDialog;
