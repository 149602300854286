import React from "react";
import { useDispatch, useSelector } from "react-redux";
import Grid from "@mui/material/Grid";
import Datetime from "react-datetime";
import { GetApp, Search } from '@mui/icons-material';
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import Button from "../../../../components/Button/Button";
import { actions as ReportsActions, selectors as ReportsSelectors } from "store/modules/reports";
import makeStyles from '@mui/styles/makeStyles';
const useStyles = makeStyles(theme => ({
    buttonIcon: {
        marginTop: 10,
        marginLeft: 5
    }
}))
function ActiveEmployerFilter() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const aTag = React.useRef();
  const [csvURL, setCsvURL] = React.useState('');
  const { isAll } = useSelector(ReportsSelectors.getActiveEmployerReportFilter);
  const  updateFormData = (value) => {
    dispatch(ReportsActions.setActiveEmployerFilter('isAll', value))
  };
  const onSearch = () => {
      dispatch(ReportsActions.fetchActiveEmployerTableData(0));
  }
  const onDownload = () => {
      dispatch(ReportsActions.downloadActiveEmployerTableData()).then(data => {
          if(data && data.url) {
              setCsvURL(data.url);
              aTag.current.click();
          }
      });
  }
  return (
      <React.Fragment>
          <Grid container>
              <Grid item xs={12} md={12}>
              <FormControl margin="normal" style={{ minWidth: "188px" }}>
                    <InputLabel htmlFor="date">Filter</InputLabel>
                    <Select

                        value={isAll}
                        onChange={value => updateFormData(value.target.value)}
                        margin="normal"
                        >
                        <MenuItem value={true}>
                            All
                        </MenuItem>
                        <MenuItem value={false}>
                            Active
                        </MenuItem>
                    </Select>
                </FormControl>
                  &nbsp;&nbsp;
                  <FormControl margin="normal" style={{ marginTop: 30 }}>
                      <Button
                          icon={<Search className={classes.buttonIcon} />}
                          alignIcon="left"
                          label="Search"
                          onClick={onSearch}
                      />
                  </FormControl>
                  <FormControl margin="normal" style={{ marginTop: 30 }}>
                      <Button
                          icon={<GetApp className={classes.buttonIcon} />}
                          label="Download CSV"
                          alignIcon="left"
                          onClick={onDownload}
                      />
                  </FormControl>
              </Grid>
          </Grid>
          <a ref={aTag} href={csvURL} target="_blank" rel="noopener noreferrer" />
      </React.Fragment>
  );
}

export default ActiveEmployerFilter;
