import Badge from "@mui/material/Badge";
import ListItem from "@mui/material/ListItem";
import MenuItem from "@mui/material/MenuItem";
import withStyles from '@mui/styles/withStyles';
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Tooltip from "@mui/material/Tooltip";

export const CustomTableCell = withStyles({
  root: {
    border: "none",
    padding: "2px 4px",
    fontSize: 13,
  },
  head: {
    fontSize: 14,
    color: "#404b5a",
  },
  body: {
    fontSize: 12,
    padding: "4px",
  },
})(TableCell);

export const CustomTableRow = withStyles({
  head: {
    height: 24,
    borderBottom: `0.7px solid lightgrey`,
  },
})(TableRow);

export const StyledBadge = withStyles(theme => ({
  badge: {
    backgroundColor: "orange",
    color: "#fff",
    marginLeft: 6,
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    "&::after": {
      position: "absolute",
      top: 0,
      fontSize: 0,
      left: 0,
      width: "100%",
      height: "100%",
      borderRadius: "50%",
      animation: "$ripple 1.2s infinite ease-in-out",
      border: "1px solid currentColor",
      content: '""',
    },
  },
  "@keyframes ripple": {
    "0%": {
      transform: "scale(.8)",
      opacity: 1,
    },
    "100%": {
      transform: "scale(2.4)",
      opacity: 0,
    },
  },
}))(Badge);

export const StyledMenuItem = withStyles(theme => ({
  root: {
    paddingBottom: 0,
    paddingTop: 0,
    "&:focus": {
      backgroundColor: theme.palette.primary.main,
      "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
        color: theme.palette.common.white,
      },
    },
  },
}))(MenuItem);

export const CustomTableCellLast = withStyles({
  root: {
    border: "none",
  },
  head: {
    fontSize: 12,
    color: "#404b5a",
    display: "flex",
    justifyContent: "flex-end",
    fontWeight: 700,
  },
  body: {
    fontSize: 14,
    display: "flex",
    justifyContent: "flex-end",
  },
})(TableCell);

export const CustomListItem = withStyles({
  root: {
    border: "none",
    paddingTop: 3,
    paddingBottom: 3,
    fontSize: 13,
  },
})(ListItem);

export const HtmlTooltip = withStyles(theme => ({
  tooltip: {
    color: "rgba(0, 0, 0, 0.87)",
    width: 220,
    minHeight: 80,
    backgroundColor: theme.palette.common.white,
    border: `1px solid ${theme.palette.grey[500]}`,
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(1),
    fontSize: theme.typography.pxToRem(12),
  },
}))(Tooltip);

export const StyledTableCell = withStyles(() => ({
  head: {
    fontSize: 14,
    borderWidth: 0.7,
    // backgroundColor: theme.palette.common.black,
    // color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
    borderWidth: 0.7,
  },
}))(TableCell);

if (!Array.prototype.last) {
  Array.prototype.last = function () {
    return this[this.length - 1];
  };
}
