import React from "react";
import Grid from "@mui/material/Grid";
import withStyles from '@mui/styles/withStyles';
import Typography from "@mui/material/Typography";

const styles = ({ spacing }) => ({
  container: {
    "& p": {
      marginRight: spacing(2),
    },
    "& svg": {
      marginRight: spacing(1),
    },
  },
});

function StateInfo({ classes, id }) {
  return (
    <Grid container item xs={6} alignItems="center" className={classes.container}>
      <Typography variant="body1" color="textSecondary">
        {`# ${id || ""}`}
      </Typography>
    </Grid>
  );
}

export default withStyles(styles)(StateInfo);
