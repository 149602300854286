import gql from "graphql-tag";

export default gql`
mutation($data: CreateJobTemplateInput!) {
  createJobTemplate(data: $data) {
    id
    templateName
    employerId
    managerId
    workTypeId
    peopleNeeded
    street
    city
    region
    unit
    floor
    postalCode
    description
    isActive
    breakMins
    latitude
    longitude
    locationInstruction
    gears
    skills
    employer {
      id
      firstName
      lastName
    }
    manager {
      id
      firstName
      lastName
    }
    workType {
      id
      label
    }
  }
}
` 