import React from "react";
import { Container, Grid, Hidden } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import AppStore from "assets/img/appstore.png";
import SuccessIcon from "assets/img/success-icon.png";
import PhoneWorker from "assets/img/grizzly_phone_workers.png";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    container: {
        backgroundColor: '#FFF',
        maxWidth: '1000px'
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    borderTop: {
        borderTop: '1px solid #cccccc',
    },
    link: {
        color: '#337ab7'
    },
    boldFont: {
        fontWeight: "bold"
    },
    handimg: {
        width: '100%'
    }
}));
export default function EmailVerifySuccess() {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <Container fixed className={classes.container}>
                <Grid container>
                    <Grid item xs={12}>
                        <h1 className={classes.boldFont}>
                            <Hidden xlDown>
                            <img src={SuccessIcon} width="80"/>
                            </Hidden>
                                Welcome To The Grizzly Force Team!
                        </h1>
                    </Grid>
                    <Grid item xs={12}>
                        <h4 className={classes.boldFont}>
                            Nice work!<br />
                            We will review your profile as quickly as possible and email you when you are approved or if there are any issues.
                        </h4>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                        <h4 className={classes.boldFont}>
                            If you havn&apos;t already done so get started by downloading the Grizzly Force app at your favourite app storee
                        </h4>
                        <a href="https://itunes.apple.com/us/app/grizzly-force/id1205858904?ls=1&amp;mt=8" target="_blank" rel="noopener noreferrer">
                            <img src={AppStore} width="49%" alt="" />
                        </a>
                        <a href="https://play.google.com/store/apps/details?id=com.GrizzlyLC.Grizzly" target="_blank" rel="noopener noreferrer">
                            <img width="49%" src={"assets/img/gplay.svg"} alt="" />
                        </a>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                        <img src={PhoneWorker}
                             className={classes.handimg} />
                    </Grid>
                    <Grid item xs={12} className={classes.borderTop}>
                        <h4 className={classes.boldFont}>
                            We look forward to working with you to provide the best possible work experience, if you have any questions, ideas or concerns please drop us an email at <a href="mailto: admin@grizzlyforce.ca" className={classes.link}>support@grizzlyforce.ca.</a>
                        </h4>
                        <p>You can close this tab/window and return to your app!</p>
                    </Grid>
                </Grid>
            </Container>
        </div>
    );
}
