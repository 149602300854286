import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import admins from "store/modules/admins";
import employerDetails from "store/modules/employerDetails";
import employers from "store/modules/employers";
import faqs from "store/modules/faqs";
import finance from "store/modules/finance";
import jobDetails from "store/modules/jobDetails";
import jobs from "store/modules/jobs";
import login from "store/modules/login";
import meta from "store/modules/meta";
import posting from "store/modules/posting";
import profile from "store/modules/profile";
import reports from "store/modules/reports";
import workerDetails from "store/modules/workerDetails";
import workers from "store/modules/workers";
import jobTemplate from "store/modules/jobTemplate";
import requiredGear from "store/modules/requiredGear";
import workTypes from "./modules/workTypes";

const financeConfig = {
  key: "finance",
  storage: storage,
  whitelist: [
    "billingYearSummary",
    "billingWeekSummary",
    "paymentOverview",

    "previousBillingWeekSummary",
  ],
};

export default combineReducers({
  finance: persistReducer(financeConfig, finance),
  login,
  workers,
  employers,
  jobs,
  meta,
  posting,
  jobDetails,
  workerDetails,
  employerDetails,
  jobTemplate,
  requiredGear,
  workTypes,
  faqs,
  reports,
  admins,
  profile
});
