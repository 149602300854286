import React, { useState } from "react";
import Dialog from "components/Dialog/Dialog";
import { useDispatch, useSelector } from "react-redux";
import { TextField } from "@mui/material";
import withStyles from '@mui/styles/withStyles';
import { actions as requiredGearActions, selectors as requiredGearSelectors } from "store/modules/requiredGear";

const style = ({ spacing }) => ({
    container: {
        marginBottom: spacing(2)
    }
})

function AddGearDialog({ classes }) {

    const dispatch = useDispatch();
    const [gearName, setGearName] = useState("");
    const [gearStatus, setGearStatus] = useState(true);
    const isAddGearDialog = useSelector(requiredGearSelectors.getIsAddGearDialog);

    // const gearStatusValue = [
    //     {
    //         value: true,
    //         label: "Active"
    //     },
    //     {
    //         value: false,
    //         label: "Inactive"
    //     }
    // ]

    const hideAddGearDialog = () => {
        dispatch(requiredGearActions.setIsaddGearDialog(false));
    }

    const handleGear = (event) => {
        setGearName(event.target.value);
    }

    const AddGear = () => {
        const values = {
            gearName: gearName,
            isActive: gearStatus
        }
        dispatch(requiredGearActions.createRequiredGear(values));
        setGearName("");
        setGearStatus(true);
    }

    // const onGearStatusChange = (event) => {
    //     setGearStatus(event.target.value)
    // }


    return (
        <Dialog
            open={isAddGearDialog}
            title="Add Gear"
            onClose={hideAddGearDialog}
            onCancel={hideAddGearDialog}
            onConfirm={AddGear}
            disabled={!gearName}
            alertType="form"
            confirmText="Add Gear"
            size="sm"
            modalType="scrollable"
        >
            <div className={classes.container}>
                <TextField
                    id="gearName"
                    margin="normal"
                    label="Gear Name"
                    value={gearName}
                    onChange={handleGear}
                    required
                    fullWidth
                />
                {/* <TextField
                    select
                    label="Gear Status"
                    value={gearStatus}
                    onChange={onGearStatusChange}
                    margin="normal"
                    fullWidth
                    required
                >
                    {gearStatusValue.map(({ value, label }) => (
                        <MenuItem key={`gearStatus-${value}`} value={value}>
                            {label}
                        </MenuItem>
                    ))}
                </TextField> */}
            </div>
        </Dialog>
    )
}

export default (withStyles(style)(AddGearDialog))
