import { gql } from "@apollo/client";

export default gql`
  query jobTimelines($jobId: ID!) {
    jobTimelines(jobId: $jobId) {
      jobId
      workerId
      eventName
      eventSlug
      notes
      createdBy
      userType
      createdAt
      job{
          id
      }
      worker{
          firstName
          lastName
          signedProfileImageUrl
      }
    }
  }
`;
