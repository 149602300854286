/* eslint-disable react/jsx-key */
import { Chip, Tooltip } from "@mui/material";
import ReactTableSimple from "components/ReactTable/ReactTableSimple";
import moment from "moment";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { actions as JobActions, selectors as JobSelectors } from "store/modules/jobs";
import history from "utils/history";
import PrivateJobActionMenu from "./components/PrivateJobActionMenu";

const PrivateJob = () => {
    const dispatch = useDispatch();
    const isLoading = useSelector(JobSelectors.getPrivateJobLoading);
    const data = useSelector(JobSelectors.getImportedPrivateJob);

    useEffect(() => {
        dispatch(JobActions.fetchImportedPrivateJob());
    }, []);

    const columns = [
        {
            Header: "Employer Id",
            id: "employerId",
            accessor: ({ employerId }) => employerId,
            className: "-cursor-pointer",
        },
        {
            Header: "Type Of Work",
            id: "typeOfWork",
            accessor: ({ typeOfWork }) => typeOfWork,
            className: "-cursor-pointer",
        },
        {
            Header: "Job Date",
            id: "startDate",
            accessor: ({ startDate, endDate }) => `${startDate} - ${endDate}`,
            className: "-cursor-pointer",
        },
        {
            Header: "Time",
            id: "startTime",
            accessor: ({ startTime, endTime }) => `${moment(startTime, 'h:mm:ss A').format('h:mm A')} - ${moment(endTime, 'h:mm:ss A').format('h:mm A')}`,
            className: "-cursor-pointer",
        },
        {
            Header: "Address",
            id: "address",
            accessor: ({ address, city, province, postalCode }) => `${address}, ${city}, ${province}, ${postalCode}`,
            className: "-cursor-pointer",
        },
        {
            Header: "People Needed",
            id: "peopleNeeded",
            accessor: ({ peopleNeeded }) => peopleNeeded,
            className: "-cursor-pointer",
            style: {
                textAlign: "center",
            },
            headerStyle: {
                textAlign: "center",
            },
        },
        {
            Header: "Tags",
            id: "tags",
            accessor: ({ tags }) => tags,
            Cell: ({ row }) => {
                const tags = row.original.tags
                return (
                    <div>
                        {tags && tags.map((n, index) => {
                            if (index < 2) {
                                return <Chip
                                    size="small"
                                    style={{ margin: '1px' }}
                                    label={n}
                                />
                            }
                        })}
                        {tags && tags.length > 2 && (
                            <Tooltip title={tags.map((n, index) => `${index > 1 ? n + ((index + 1) != tags.length ? ', ' : '') : ''}`)} placement="top">
                                <Chip
                                    size="small"
                                    style={{ margin: '1px' }}
                                    label={'More'}
                                />
                            </Tooltip>
                        )}
                    </div>
                )
            },
        },
        {
            id: "actions",
            sortable: false,
            filterable: false,
            collapse: true,
            Cell: ({ row: { original } }) => {
                return (
                    <PrivateJobActionMenu
                        data={original}
                    />
                );
            },
        },
    ];
    return (
        <ReactTableSimple
            classes="-highlight"
            style={{ marginTop: "16px" }}
            data={data}
            loading={isLoading}
            showPaginationBottom={true}
            showPageJump={false}
            showPaginationTop={false}
            resizable={false}
            sortable={false}
            minRows={0}
            manual
            showPageSizeOptions={false}
            columns={columns}
            onRowClick={(row, cell, e) => {
                if (row) {
                    if (cell.column.id !== "actions") {
                        history.push(`/private-job/${row.original.id}`)
                    }
                }
                return;
            }}
        />
    );
};

export default PrivateJob;
