import ApolloClient from "utils/apollo";
import { flattenGraphQLArray } from "utils/graphql";
import * as pagination from "utils/graphql-pagination";
import { actions as MetaActions } from "store/modules/meta";
import { successToast } from "store/modules/meta/actions";
import { fetchPositions, payJob, payJobPayment, setIsPayingJob, uninitializeJobPayroll } from "../../jobDetails/actions";
import { UpdateWorkerEmailMutation } from "../../workerDetails/graphql/mutations";
import { paginationSettings } from "../constants";
import {
    cancelEtransferTransactionMutation,
    cancelReversalMutation,
    createDcCustomerMutation,
    createEtransferRequestForFundsMutation,
    createEtransferTransactionMutation,
    createPaystubMutation,
    downloadPaystubSummary,
    MarkReversalResolvedMutation,
    payPositionMutation,
    retryReversalMutation,
} from "../graphql/mutations";
import MarkPaymentResolvedMutation from "../graphql/mutations/mark-payment-resolved";
import RetryPaymentMutation from "../graphql/mutations/retry-payment";
import SendBatchMutation from "../graphql/mutations/send-batch";
import { uploadFileMutation } from "../graphql/mutations/upload";
import {
    findEtransferRequestForFundsQuery,
    findEtransferTransactionsQuery,
    findReversalsQuery,
    findTransfersQuery,
    getDcAccountQuery,
    listEtransferTransactionQuery,
    paymentAndBillingCountQuery,
    payrollByDcTransferStatusQuery,
    payrollByJobQuery,
    payrollByPositionQuery,
    payrollSummaryQuery,
    uninitializeAllUnprocessedPayrollQuery,
} from "../graphql/queries/dc";
import FetchPaymentBatchQuery from "../graphql/queries/fetch-payment-batch";
import FetchPaymentBatchesQuery from "../graphql/queries/fetch-payment-batches";
import { getPaymentBatchesPaginationData, getTransfersPaginationData } from "../selectors";
import types from "../types";
import {
    checkWeekInvoices,
    clearWeekInvoices,
    createEmployerInvoice,
    createEmployerInvoiceFromInvoice,
    createWeeklyBillingSpreadsheet,
    deleteEmployerInvoice,
    downloadWeeklyBillingTimesheet,
    downloadWeeklyBillingZip,
    fetchBillingWeekSummary,
    fetchEmployerBillingWeekShifts,
    fetchEmployerBillingWeekSummary,
    fetchEmployerBillingYearSummary,
    fetchEmployerInvoice,
    fetchEmployerInvoiceItems,
    fetchEmployerInvoices,
    fetchEmployerInvoicesByWeek,
    fetchPreviousBillingWeekSummary,
    fetchWeeklyBillingPerformance,
    generateBilling,
    setUpdateEmployerBillingWeekData,
    setUpdateEmployerBillingWeekInvoiceData,
    fetchDeletedInvoices,
    downloadDeletedTimesheet
} from "./billingActions";
import { setPageSizeFactory } from "../../jobs/actions";
import { getRelation } from "../../../../utils/redux";
import { payJobPaymentMutation } from "store/modules/jobDetails/graphql/mutations/pay-job";

const setLoadingState = key => value => ({
    type: types.SET_LOADING_STATE,
    payload: { key, value },
});
const setIsSendingPay = setLoadingState("sendingPay");
const setIsFetchingBatch = setLoadingState("fetchingBatch");
const setIsMarkingPaymentResolved = setLoadingState("markingPaymentResolved");
const setIsRetryingPayment = setLoadingState("retryingPayment");
export const setIsCancelTransaction = setLoadingState("cancelTransaction");
export const setIsDownloadingPaystubZip = setLoadingState("downloadingPaystubZip");
const setDcAccount = data => ({ type: types.SET_DC_ACCOUNT, payload: data });
const setDcTransfers = data => ({ type: types.SET_DC_STATUS_TRANSFERS, payload: { data } });
const setTransferTotalCount = data => ({ type: types.SET_TRANSFER_TOTAL_COUNT, payload: data });
const setDcTransactions = data => ({ type: types.SET_DC_TRANSFERS, payload: data });
const setPaymentsOverview = data => ({ type: types.SET_PAYMENT_OVERVIEW, payload: data });
const setDcPayrollIssues = data => ({ type: types.SET_DC_PAYROLL_ISSUES, payload: data });
const setDcPayroll = data => ({ type: types.SET_DC_PAYROLL_BY_STATUS, payload: data });
const setDcRequestForFunds = data => ({ type: types.SET_DC_REQUEST_FOR_FUNDS, payload: data });
const setDcReversals = data => ({ type: types.SET_DC_REVERSALS, payload: data });
const setPaymentAndBillingCount = data => ({
    type: types.SET_PAYMENT_BILLING_COUNT,
    payload: data,
});
export const setDcTransfersParams = data => ({ type: types.SET_DC_TRANSFERS_PARAMS, payload: { dcTransferParams: data } });
export const setDcTransfersFilter = data => ({ type: types.SET_DC_TRANSFERS_PARAMS, payload: { dcTransferFilter: data } });
export const setDcPayrollItem = data => ({ type: types.SET_DC_PAYROLL, payload: data });
export const setCancelTransferReason = data => ({ type: types.SET_CANCEL_TRANSFER_REASON, payload: data });
export const createAndSendPaystub = positionId => dispatch => {
    dispatch(setIsSendingPay(true));
    return ApolloClient.mutate({ mutation: createPaystubMutation, variables: { positionId } })
        .then(({ data }) => {
            dispatch(setIsSendingPay(false));
            dispatch(MetaActions.successToast(`Paystub for position #${positionId} has been sent.`));
            //   return data.payPosition;
        })
        .catch(e => {
            dispatch(setIsSendingPay(false));
            dispatch(
                MetaActions.errorToast(`Failed send paystub for position #${positionId}. (${e.message})`),
            );
        });
};
export const downloadPaystubSummaryZip = positionId => dispatch => {
    return ApolloClient.mutate({ mutation: downloadPaystubSummary, variables: { positionId } })
        .then(({ data, errors }) => {
            if (data) {
                return data.downloadPaystubSummary.url
            } else if (errors && errors.length > 0) {
                throw new Error(errors[0].message);
            }
        })
        .catch(e => {
            dispatch(MetaActions.errorToast(`Failed download paystub. (${e.message})`),);
        });
};
export const fetchReversals = ({ status, dcTransferId, customerNumber }) => dispatch => {
    return ApolloClient.query({
        query: findReversalsQuery,
        variables: { status, dcTransferId: (dcTransferId || '').toString(), customerNumber },
    })
        .then(({ data }) => dispatch(setDcReversals(data.reversals)))
        .catch(e => dispatch(MetaActions.errorToast(`Failed find reversal requests. - ${e}`)));
};
export const fetchPaymentAndBillingCount = () => dispatch => {
    return ApolloClient.query({
        query: paymentAndBillingCountQuery,
        variables: {},
    })
        .then(({ data } = {}) => {
            dispatch(setPaymentAndBillingCount(data.paymentAndBillingCount))
        })
        .catch(e => {
            dispatch(MetaActions.errorToast(`Failed requests. - ${e}`))
        });
};
export const fetchTransfersListFactory = config => pageIndex => (dispatch, getState) => {
    const state = getState();
    const pageInfo = config.getPageInfo(state);
    const pagingVars = dispatch(config.getPagingVars(pageInfo, pageIndex));
    const extraVars = state.finance.dcTransferParams || {};
    const filters = state.finance.dcTransferFilter || [];
    const relations = {
        Worker: ["firstName"],
    };
    return ApolloClient.query({
        query: config.query,
        variables: {
            ...pagingVars,
            ...extraVars,
            like: filters.map(filter => {
                return {
                    field: filter.id,
                    value: filter.value,
                    relation: getRelation(relations, filter.id),
                };
            })
        },
    }).then(({ data }) => {
        const { transfers } = data;
        const paginationData = {
            ...transfers.pageInfo,
            totalCount: transfers.totalCount,
        };
        dispatch(setDcTransfers(flattenGraphQLArray(transfers)));
        dispatch(setTransferTotalCount(transfers.totalCount));
        dispatch(pagination.updatePageInfo(paginationSettings.transfers, paginationData));
        dispatch(pagination.doneLoading(config.paginationSetting));
    }).catch(() => {
        dispatch(pagination.doneLoading(config.paginationSetting));
    });
}
export const fetchTransfers = ({ status, dcTransferId, customerNumber }) => dispatch => {
    //   status = status ? status : 5;
    return ApolloClient.query({
        query: findTransfersQuery,
        variables: { status, dcTransferId, customerNumber },
    })
        .then(({ data, errors }) => {
            if (data) {
                // dispatch(setDcTransfers(data.transfers));
            } else if (errors && errors.length > 0) {
                dispatch(MetaActions.errorToast(errors[0].message));
            }
        })
        .catch(e => {
            console.log(e);
            dispatch(MetaActions.errorToast(`Failed find reversal requests.`));
        });
};
export const fetchTransfersNew = fetchTransfersListFactory({
    getPageInfo: getTransfersPaginationData,
    getPagingVars: pagination.pagingVarsFactory(paginationSettings.transfers),
    query: findTransfersQuery,
    variables: {},
    paginationSetting: paginationSettings.transfers,
});
export const setFilterFactory = config => filter => dispatch => {
    dispatch(setDcTransfersFilter(filter));
    // return dispatch(config.refresh());
};
// export const setTransferSort = setSortFactory({ refresh: fetchTransfers })
export const setTransferPageSize = setPageSizeFactory({ paginationSetting: paginationSettings.transfers})
export const setTransferFilter = setFilterFactory({ refresh: fetchTransfersNew })
export const uninitializeAllUnprocessedPayroll = () => async dispatch =>
    ApolloClient.query({ query: uninitializeAllUnprocessedPayrollQuery, variables: {} })
        .then(({ data }) => {
            dispatch(
                MetaActions.successToast(
                    `Successfully uninitialized ${data.uninitializeAllUnprocessedPayroll[0].deletedPayrolls
                    } payrolls.`,
                ),
            );
            dispatch(fetchPaymentsOverview());
        })
        .catch(() => dispatch(MetaActions.errorToast(`Failed find reversal requests.`)));
export const findRequestForFunds = () => dispatch =>
    ApolloClient.query({ query: findEtransferRequestForFundsQuery, variables: {} })
        .then(({ data }) => dispatch(setDcRequestForFunds(data.findEtransferRequestForFunds)))
        .catch(() => dispatch(MetaActions.errorToast(`Failed to find request for funds`)));
export const fetchPaymentsOverview = () => async dispatch => {
    return ApolloClient.query({ query: payrollSummaryQuery, variables: {} })
        .then(({ data }) => {
            dispatch(setPaymentsOverview(data.payrollSummary));
            //   dispatch(MetaActions.successToast(`Got Summary`));
            return data.payPosition;
        })
        .catch(e => {
            dispatch(MetaActions.errorToast(`Failed to get DC Bank Transfers. (${e.message})`));
        });
};
export const createDcCustomer = (workerId, email) => dispatch => {
    return ApolloClient.mutate({ mutation: createDcCustomerMutation, variables: { workerId, email } })
        .then(({ data: { createDcCustomer } }) => {
            if (createDcCustomer.status === "success") {
                dispatch(
                    MetaActions.successToast(`Successfully created Dc customer, ${createDcCustomer.message}`),
                );
                dispatch(fetchPositions());
                return { status: "success" };
            }
            if (createDcCustomer.status !== "success") {
                dispatch(
                    MetaActions.errorToast(`Failed to created Dc customer, ${createDcCustomer.message}`),
                );
                return { status: "success" };
            }

            return { status: "unknown" };
        })
        .catch(e => {
            dispatch(MetaActions.errorToast(`Failed to created Dc customer, ${e}`));
            console.log("createDcCustomer e", e);
        });
};
export const updateDcCustomer = (workerId, email) => dispatch => {
    return ApolloClient.mutate({
        mutation: UpdateWorkerEmailMutation,
        variables: { data: { workerId, email } },
    })
        .then(({ data: { updateWorkerEmail } }) => {
            //   if (createDcCustomer.status === "success") {
            //     dispatch(
            //       MetaActions.successToast(`Successfully created Dc customer, ${createDcCustomer.message}`)
            //     );
            //   }
            //   if (createDcCustomer.status !== "success") {
            //     dispatch(
            //       MetaActions.errorToast(`Failed to created Dc customer, ${createDcCustomer.message}`)
            //     );
            //   }
            console.log("updateWorkerEmail", updateWorkerEmail);
        })
        .catch(e => {
            dispatch(MetaActions.errorToast(`Failed to created Dc customer, ${e}`));
            console.log("createDcCustomer e", e);
        });
};
export const uploadFile = picture => () => {
    return ApolloClient.mutate({ mutation: uploadFileMutation, ...picture })
        .then(res => {
            console.log("picture res", res);
        })
        .catch(e => console.log("picture e", e));
};
export const payPendingJob = () => async dispatch => {
    return dispatch(payJobPayment(true))
        .then(data => data)
        .then(() => dispatch(fetchTransfersNew()))
        .then(() => {
            dispatch(fetchPaymentsOverview())
            dispatch(setIsPayingJob(false));
        })
        .catch(e => {
            dispatch(setIsPayingJob(false));
            dispatch(MetaActions.errorToast(`Failed payment, ${e}`));
        });
};
export const payPosition = positionId => dispatch => {
    dispatch(setIsSendingPay(true));
    return ApolloClient.mutate({ mutation: payJobPaymentMutation, variables: { positionId } })
        .then(({ errors }) => {
            if (errors && errors.length > 0) {
                //  dispatch(uninitializeJobPayroll());
                throw new Error(errors[0].message);
            }
        })
        .then(() => setTimeout(() => dispatch(fetchPaymentsOverview()), 2000))
        .then(() => dispatch(fetchTransfersNew()))
        .then(() => {
            dispatch(setIsSendingPay(false));
            dispatch(successToast(`Payment for position ${positionId} is processing.`));
        })
        .catch(e => {
            console.log(e);
            dispatch(setIsSendingPay(false));
            dispatch(MetaActions.errorToast(`An Error Occurred -  #${positionId}. (${e.message})`));
        });
};
export const findWorkerEtransfers = args => dispatch => {
    const payload = {
        type: args.type || null,
        amount: args.amount || null,
        customerNumber: args.customerNumber || null,
        maxExpiryDate: args.maxExpiryDate || null,
        maxDate: args.maxDate || null,
        minDate: args.minDate || null,
        minExpiryDate: args.minExpiryDate || null,
        transactionId: Number(args.transactionId) || null,
    };
    return ApolloClient.query({ query: findEtransferTransactionsQuery, variables: { ...payload } })
        .then(({ data }) => dispatch(setDcPayroll(data.findEtransferTransactions)))
        .catch(() => dispatch(MetaActions.errorToast(`Failed find worker eTransfers`)));
};
export const findEtransfers = args => dispatch => {
    const payload = {
        type: args.type || null,
        amount: args.amount || null,
        customerNumber: args.customerNumber || null,
        maxExpiryDate: args.maxExpiryDate || null,
        maxDate: args.maxDate || null,
        minDate: args.minDate || null,
        minExpiryDate: args.minExpiryDate || null,
        transactionId: Number(args.transactionId) || null,
    };
    return ApolloClient.query({ query: findEtransferTransactionsQuery, variables: { ...payload } })
        .then(({ data }) => dispatch(setDcPayroll(data.findEtransferTransactions)))
        .catch(e => dispatch(MetaActions.errorToast(`Failed To Retrieve Payroll Transfers - ${e}`)));
};

export const payrollByPositionId = positionId => async dispatch =>
    ApolloClient.query({ query: payrollByPositionQuery, variables: { positionId } })
        .then(({ data }) => data.payrollByPosition)
        .catch(() => dispatch(MetaActions.errorToast(`Failed To Retrieve Payroll By Position`)));
export const payrollByJobId = jobId => async dispatch =>
    ApolloClient.query({ query: payrollByJobQuery, variables: { jobId } })
        .then(({ data }) => data.payrollByJob)
        .catch(() => dispatch(MetaActions.errorToast(`Failed To Retrieve Payroll By Job Id`)));
export const payrollByDcTransferStatus = type => dispatch =>
    ApolloClient.query({ query: payrollByDcTransferStatusQuery, variables: { type } })
        .then(({ data }) => dispatch(setDcPayroll(data.payrollByDcTransferStatus)))
        .catch(() => dispatch(MetaActions.errorToast(`Failed To Retrieve Payroll By Transfer Status`)));
export const dcTransferIssues = type => dispatch =>
    ApolloClient.query({ query: payrollByDcTransferStatusQuery, variables: { type } })
        .then(({ data }) => dispatch(setDcPayrollIssues(data.payrollByDcTransferStatus)))
        .catch(() => dispatch(MetaActions.errorToast(`Failed To Retrieve Payroll By With Issue`)));
//
export const createRequestForFunds = ({
    dcTransferId,
    reason,
    amount,
    dcCustomerNumber,
}) => async dispatch =>
        await ApolloClient.mutate({
            mutation: createEtransferRequestForFundsMutation,
            variables: { dcTransferId: parseInt(dcTransferId), reason, amount, customerNumber: dcCustomerNumber },
        })
            .then(({ data }) => {
                dispatch(
                    MetaActions.successToast(`Transfer #${dcTransferId} has been sent a reversal request.`),
                );
                dispatch(fetchPaymentsOverview());
                dispatch(fetchTransfersNew());
                dispatch(fetchReversals({ status: null }));
                return data.createEtransferRequestForFunds;
                // dispatch(setDcTransactions(data.createEtransferTransaction));
            })
            .catch(e => dispatch(MetaActions.errorToast(`Failed ${e.message} `)));
//
export const createPaymentTransaction = data => async dispatch => {
    return await ApolloClient.mutate({
        mutation: createEtransferTransactionMutation,
        variables: { ...data },
    })
        .then(({ data }) => {
            dispatch(MetaActions.successToast(`Payment eTransfer has been initiated.`));
            dispatch(fetchPaymentsOverview());
            dispatch(fetchTransfersNew());
            dispatch(fetchReversals({ status: null }));
            return data.createEtransferTransaction;
        })
        .catch(e => dispatch(MetaActions.errorToast(`Payment eTransfer has failed - ${e.message}`)));
}; //
export const retryReversal = reversalId => async dispatch => {
    dispatch(setIsRetryingPayment(true));
    try {
        const {
            data: { retryReversal },
        } = await ApolloClient.mutate({
            mutation: retryReversalMutation,
            variables: { reversalId: parseInt(reversalId) },
        });
        console.log("retryPayment", await retryReversal);
        dispatch(MetaActions.successToast(`Payment #${reversalId} has been retried`));
        dispatch(fetchPaymentsOverview());
        dispatch(fetchTransfersNew());
        dispatch(fetchReversals({ status: null }));
        return await retryReversal;
    } catch (e) {
        dispatch(MetaActions.errorToast(`Failed to retry ${reversalId}. (${e.message})`));
    } finally {
        dispatch(setIsRetryingPayment(false));
    }
};
export const cancelReversal = data => async dispatch =>
    await ApolloClient.mutate({
        mutation: cancelReversalMutation,
        variables: { ...data },
    })
        .then(({ data }) => {
            const { IsSucceeded, ErrorDescription } = data.cancelReversal;
            IsSucceeded
                ? dispatch(MetaActions.successToast(`Payment cancelled successfully.`))
                : dispatch(MetaActions.errorToast(`Payment cancellation failed, ${ErrorDescription}`));
            dispatch(setCancelTransferReason('Transaction cancel'));
            return { result: IsSucceeded ? "success" : ErrorDescription ? "failed" : "unknown" };
        })
        .then(() => dispatch(fetchPaymentsOverview()))
        .then(() => dispatch(fetchTransfersNew()))
        .then(() => dispatch(fetchReversals({ status: null })))
        .catch(() => dispatch(MetaActions.errorToast(`An Error Occurred -   `)));
//
export const markReversalResolved = reversalId => async dispatch => {
    dispatch(setIsMarkingPaymentResolved(true));
    try {
        const {
            data, errors
        } = await ApolloClient.mutate({
            mutation: MarkReversalResolvedMutation,
            variables: { reversalId: parseInt(reversalId) },
        });
        if (!data && errors) {
            throw new Error(errors[0].message);
        }
        dispatch(fetchPaymentsOverview());
        dispatch(fetchTransfersNew());
        dispatch(fetchReversals({ status: null }));
        console.log("markReversalResolved", markReversalResolved);
        dispatch(MetaActions.successToast(`reversal  #${reversalId} has been marked as resolved`));
    } catch (e) {
        dispatch(MetaActions.errorToast(`Failed to mark #${reversalId} as resolved. (${e.message})`));
    } finally {
        dispatch(setIsMarkingPaymentResolved(false));
    }
};
export const cancelPaymentTransaction = data => async dispatch =>
    await ApolloClient.mutate({
        mutation: cancelEtransferTransactionMutation,
        variables: { ...data },
    })
        .then(({ data }) => {
            const { IsSucceeded, ErrorDescription } = data.cancelEtransferTransaction;
            IsSucceeded
                ? dispatch(MetaActions.successToast(`Payment cancelled successfully.`))
                : dispatch(MetaActions.errorToast(`Payment cancellation failed, ${ErrorDescription}`));
            dispatch(setCancelTransferReason('Transaction cancel'));
            return { result: IsSucceeded ? "success" : ErrorDescription ? "failed" : "unknown" };
        })
        .then(() => dispatch(fetchPaymentsOverview()))
        .then(() => dispatch(fetchTransfersNew()))
        .then(() => dispatch(fetchReversals({ status: null })))
        .catch(() => dispatch(MetaActions.errorToast(`An Error Occurred -   `)));
//
export const fetchPaymentTransactions = type => dispatch =>
    ApolloClient.query({ query: listEtransferTransactionQuery, variables: { type } })
        .then(({ data }) => dispatch(setDcTransactions(data.listEtransferTransaction)))
        .catch(() => dispatch(MetaActions.errorToast(`An Error Occurred -   `)));
export const fetchPaymentBatches = pageIndex => async (dispatch, getState) => {
    const state = getState();
    const pageInfo = getPaymentBatchesPaginationData(state);
    console.log(pageInfo);
    const pagingVars = dispatch(
        pagination.pagingVarsFactory(paginationSettings.paymentBatches)(pageInfo, pageIndex),
    );
    try {
        const { data } = await ApolloClient.query({
            query: FetchPaymentBatchesQuery,
            variables: {
                ...pagingVars,
                order: state.finance.batches.sort.map(sort => {
                    return {
                        field: sort.id,
                        direction: sort.desc ? "DESC" : "ASC",
                    };
                }),
                like: state.finance.batches.filter.map(filter => {
                    return {
                        field: filter.id,
                        value: filter.value,
                    };
                }),
            },
        });
        const { paymentBatches, queuedPaymentBatch } = data;
        const paginationData = {
            ...paymentBatches.pageInfo,
            totalCount: paymentBatches.totalCount,
        };
        dispatch({
            type: types.SET_PAYMENT_BATCHES_LIST_DATA,
            payload: {
                data: flattenGraphQLArray(paymentBatches),
                part: "data",
            },
        });
        dispatch({
            type: types.SET_QUEUED_PAYMENT_BATCH,
            payload: { queuedPaymentBatch },
        });
        dispatch(pagination.updatePageInfo(paginationSettings.paymentBatches, paginationData));
        dispatch(pagination.doneLoading(paginationSettings.paymentBatches));
    } catch {
        dispatch(pagination.doneLoading(paginationSettings.paymentBatches));
    }
};
export const setUpdateData = setUpdateEmployerBillingWeekData;
// export const fetchEmployerBillingWeekShifts=fetchEmployerBillingWeekShifts;
export {
    setUpdateEmployerBillingWeekData,
    fetchEmployerBillingWeekShifts,
    fetchWeeklyBillingPerformance,
    fetchBillingWeekSummary,
    fetchEmployerBillingWeekSummary,
    fetchEmployerBillingYearSummary,
    fetchPreviousBillingWeekSummary,
    fetchEmployerInvoices,
    fetchEmployerInvoice,
    fetchEmployerInvoiceItems,
    fetchEmployerInvoicesByWeek,
    deleteEmployerInvoice,
    generateBilling,
    createEmployerInvoice,
    setUpdateEmployerBillingWeekInvoiceData,
    createEmployerInvoiceFromInvoice,
    createWeeklyBillingSpreadsheet,
    checkWeekInvoices,
    clearWeekInvoices,
    downloadWeeklyBillingZip,
    downloadWeeklyBillingTimesheet,
    fetchDeletedInvoices,
    downloadDeletedTimesheet
};
export const setPaymentBatchesSort = sort => dispatch => {
    dispatch({
        type: types.SET_PAYMENT_BATCHES_LIST_DATA,
        payload: {
            data: sort,
            part: "sort",
        },
    });
    return dispatch(fetchPaymentBatches());
};
export const setPaymentBatchesFilter = filter => dispatch => {
    dispatch({
        type: types.SET_PAYMENT_BATCHES_LIST_DATA,
        payload: {
            data: filter,
            part: "filter",
        },
    });
    return dispatch(fetchPaymentBatches());
};
export const setPaymentBatchesPageSize = pageSize => dispatch => {
    dispatch(pagination.updatePageInfo(paginationSettings.paymentBatches, { pageSize }));
    return dispatch(fetchPaymentBatches());
};
export const sendBatch = paymentBatchId => dispatch => {
    dispatch(setIsSendingPay(true));
    return ApolloClient.mutate({
        mutation: SendBatchMutation,
        variables: {
            paymentBatchId,
        },
    })
        .then(() => {
            dispatch(fetchPaymentBatches());
        })
        .then(() => {
            dispatch(setIsSendingPay(false));
            dispatch(
                MetaActions.successToast(
                    `Batch #${paymentBatchId} has been sent or is in the process of sending`,
                ),
            );
        })
        .catch(e => {
            dispatch(setIsSendingPay(false));
            dispatch(MetaActions.errorToast(`An Error Occurred -  #${paymentBatchId}. (${e.message})`));
        });
};
const setBatchDetails = paymentBatch => ({
    type: types.SET_BATCH_DETAILS,
    payload: { paymentBatch },
});
export const fetchDcAccount = () => async dispatch => {
    try {
        const {
            data: { getDcAccount },
        } = await ApolloClient.query({ query: getDcAccountQuery, variables: {} });
        dispatch(setDcAccount(getDcAccount));
    } catch (e) {
        // dispatch(MetaActions.errorToast(`Failed to get DC Bank Account Details. (${e.message})`));
    }
};
export const fetchBatch = id => async dispatch => {
    dispatch(setIsFetchingBatch(true));
    try {
        const {
            data: { paymentBatch },
        } = await ApolloClient.query({
            query: FetchPaymentBatchQuery,
            variables: { id },
        });
        dispatch(setBatchDetails(paymentBatch));
        dispatch(setIsFetchingBatch(false));
    } catch (e) {
        dispatch(setIsFetchingBatch(false));
    }
};
export const retryPayment = paymentId => async dispatch =>
    await ApolloClient.mutate({
        mutation: RetryPaymentMutation,
        variables: { paymentId },
    }).then(({ errors }) => {
        dispatch(fetchPaymentsOverview());
        dispatch(fetchTransfersNew());
        dispatch(fetchReversals({ status: null }));
        if (errors) {
            console.log("errors", errors);
            return;
        }
        dispatch(MetaActions.successToast(`Payment #${paymentId} has been retried`));
        return retryPayment;
    });

//
export const markPaymentResolved = paymentId => async dispatch => {
    dispatch(setIsMarkingPaymentResolved(true));
    try {
        await ApolloClient.mutate({
            mutation: MarkPaymentResolvedMutation,
            variables: { paymentId },
        });
        // dispatch(setBatchDetails(paymentBatch));
        dispatch(MetaActions.successToast(`Payment #${paymentId} has been marked as resolved`));
        dispatch(fetchPaymentsOverview());
        dispatch(fetchTransfersNew());
        dispatch(fetchReversals({ status: null }));
    } catch (e) {
        dispatch(MetaActions.errorToast(`Failed to mark #${paymentId} as resolved. (${e.message})`));
    } finally {
        dispatch(setIsMarkingPaymentResolved(false));
    }
};
// export { BillingActions };
