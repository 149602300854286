import { gql } from "@apollo/client";

export const preMadeJobTemplateQuery = gql`
query {
    preMadeJobTemplate {
      id
      templateName
      employerId
      managerId
      workTypeId
      peopleNeeded
      street
      city
      region
      country
      postalCode
      unit
      floor
      description
      isActive
      breakMins
      latitude
      longitude
      locationInstruction
      gears
      skills
      employer {
        id
        firstName
        lastName
      }
      manager {
        id
        firstName
        lastName
      }
      workType {
        id
        label
      }
    }
  }
`;

export const workTypesQuery = gql`
query {
    workTypes{
      id
      label
    }
  }
` 
