export const paginationSettings = {
  employerList: {
    prefix: "employerList",
    defaultPageSize: 10,
  },
  deactivatedEmployerList: {
    prefix: "deactivatedEmployerList",
    defaultPageSize: 10,
  },
  unapprovedEmployerList: {
    prefix: "unapprovedEmployerList",
    defaultPageSize: 10,
  },
  employerBannedBy: {
    prefix: "employerBannedBy",
    defaultPageSize: 10,
  },
  employerPreferredBy: {
    prefix: "employerPreferredBy",
    defaultPageSize: 10,
  },
};
