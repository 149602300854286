import { gql } from "@apollo/client";

export default gql`
  fragment JobFields on Job {
    isRecurring
    jobRecurring{
      id
      frequency
      frequencyEndAt
      dayOrWeekDay
      frequencyInterval
    }
    jobAnnouncement{
      id
      title
      employerId
      managerId
      description
      frequency
      dayOrWeekDay,
      minutes,
      jobAnnouncementTime{
        sendAt
      }
      workers{
        workerId
      }
      employer{
        id
        fullName
      }
      manager{
        id
        firstName
        lastName
      }
    }
  }
`;
