import { gql } from "@apollo/client";
import WorkerFields from "../fragments/worker-fragment";

export default gql`
  mutation deactivateWorker($data: WorkerReasonInput!) {
    deactivateWorker(data: $data) {
      ...WorkerFields
    }
  }
  ${WorkerFields}
`;
