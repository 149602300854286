/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React from "react";
import { connect } from "react-redux";
import SwipeableViews from "react-swipeable-views";
import { MenuItem, Select } from "@mui/material";
import Grid from "@mui/material/Grid";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { CheckCircle, Warning } from "@mui/icons-material";
import { billingDetailsViewStyles } from "views/Finance/helpers";
import MainContent from "components/Containers/MainContent";
import { formatMoney, formatNumber } from "utils/stringUtils";
import { actions as BillingActions, selectors as BillingSelectors } from "store/modules/finance";
import TopBarCard from "../components/TopBar";
import BillingSummaryStatsCard from "./components/BillingSummaryStatsCard";
import EmployerActionMenu from "./components/EmployerActionMenu";
// import ReactTable from "components/ReactTable"
import ReactTableSimple from "components/ReactTable/ReactTableSimple";
const isMobile = false;
const employerBillingTableProps = {
    manual: false,
    minRows: 0,
    resizable: false,
    showPageSizeOptions: isMobile ? false : true,
    showPageJump: isMobile ? false : true,
    showPaginationTop: !isMobile ? false : true,
    showPaginationBottom: isMobile ? false : true,
    classes: "-highlight",
    billingRateFilter: "all",
    breakLengthFilter: "all", // pageSize: 20, // page: 0,
    defaultPageSize: 5,
    defaultSorted: [
        {
            id: "count",
            desc: true,
        },
        {
            id: "shifts",
            desc: true,
        },
        {
            id: "jobs",
            desc: true,
        },
    ],
    defaultSortMethod: (a, b) => {
        // force null and undefined to the bottom
        a = a === null || a === undefined ? -Infinity : a;
        b = b === null || b === undefined ? -Infinity : b;
        // force any string values to lowercase
        a = typeof a === "string" ? a.toLowerCase() : a;
        b = typeof b === "string" ? b.toLowerCase() : b;
        // Return either 1 or -1 to indicate a sort priority
        if (a > b) {
            return 1;
        }
        if (a < b) {
            return -1;
        }
        // returning 0 or undefined will use any subsequent column sorting methods or the row index as a tiebreaker
        return 0;
    },
};
class BillingDetailsView extends React.Component {
    constructor() {
        super();

        this.state = {
            value: 0,
            anchorEl: null,
            expanded: {},
            qbInvoiceSummary: { invoiceCount: 0, committedInvoiceCount: 0 },
            showFilter: false,
        };
        this.workerCardRef = React.createRef();
    }

    componentDidMount = () => {
        const {
            billingWeek,
            billingYear,
            fetchBillingWeekSummary,
            fetchPreviousBillingWeekSummary,
            checkWeekInvoices,
        } = this.props;
        fetchPreviousBillingWeekSummary(billingWeek - 1 || 1, billingYear);
        fetchBillingWeekSummary(billingWeek || 1, billingYear);

        checkWeekInvoices(billingWeek, billingYear).then(qbInvoiceSummary => {
            this.setState({ qbInvoiceSummary });
        });
    };
    handleChange = (event, value) => this.setState({ value });
    onExpandedChange = expanded => this.setState({ expanded });
    render = () => {
        const { classes, billingWeek, billingYear, employerSummaryData, billingWeekSummary } = this.props;
        const headerStyles = isMobile
            ? { fontSize: 11, fontWeight: 500 }
            : { fontSize: 13, fontWeight: 500 };

        const open = Boolean(this.state.anchorEl);

        const renderBillingTableColumns = [
            {
                Header: "Employer",
                id: "employer",
                disableFilters: this.state.showFilter,
                accessor: ({ employer }) => employer,
                styles: { textAlign: "left" },
                headerStyle: { textAlign: "left", ...headerStyles },
                className: "-cursor-pointer",
                minWidth: isMobile ? 100 : 200,
                filterMethod: (filter, row) => {
                    return row[filter.id] === filter.value;
                },
                Filter: ({ id, onChange }) => (
                    <Select
                        name={id}
                        id={id}
                        value="Set All"
                        style={{ fontSize: 12, maxWidth: 200 }}
                        onChange={e => onChange(e.target.value)}>
                        <MenuItem value="Set All">All</MenuItem>
                        {billingWeekSummary?.map((emp, i) => (
                            <MenuItem key={i} value={emp?.employer}>
                                {emp?.employer}
                            </MenuItem>
                        ))}
                    </Select>
                ),
                Cell: ({ row: { original } }) => {
                    return (
                        <div aria-haspopup="true" style={{ textAlign: "left", justify: "left" }}>
                            <Typography style={{ textAlign: "left", paddingRight: 15 }} variant="caption">
                                {original?.employer}
                            </Typography>
                            {!original.qbId ? (
                                <Tooltip title={`Quickbooks setup required.`} aria-label="add">
                                    <span>
                                        {/*<StyledBadge
                      overlap="circle"
                      anchorOrigin={{ vertical: "top", horizontal: "right" }}
                      badgeContent={<PriorityHigh className={classes.iconBlink} />}
                    />*/}
                                    </span>
                                </Tooltip>
                            ) : (
                                ""
                            )}
                        </div>
                    );
                },
            },
            // {
            //     Header: "QB Enabled",
            //     id: "qbId",
            //     disableSortBy: false,
            //     show: false, //!isMobile,
            //     // filterable: this.state.showFilter,
            //     accessor: ({ qbId }) => qbId,
            //     width: 100,
            //     Cell: ({ row: { original } }) => {
            //         return (
            //             <div aria-haspopup="true" style={{ textAlign: "center", justify: "center" }}>
            //                 <Tooltip
            //                     title={`${original.qbId} ${original.qbId === 1 ? " Timesheet" : " Timesheet"}`}
            //                     aria-label="add">
            //                     <span>
            //                         {original.qbId ? (
            //                             <CheckCircle className={classes.iconCheck} />
            //                         ) : (
            //                             <Warning className={classes.iconWarning} />
            //                         )}
            //                     </span>
            //                 </Tooltip>
            //             </div>
            //         );
            //     },

            //     styles: { textAlign: "center", justify: "center" },
            //     headerStyle: { textAlign: "center", ...headerStyles },
            //     className: "-cursor-pointer",
            // },
            {
                Header: "Timesheet",
                id: "invoiceCount",
                disableSortBy: false,
                show: !isMobile,
                // disableFilters: this.state.showFilter,
                accessor: ({ invoiceCount }) => invoiceCount,
                width: 120,
                Cell: ({ row: { original } }) => {
                    return (
                        <div aria-haspopup="true" style={{ textAlign: "center", justify: "center" }}>
                            {original.invoiceCount > 0 ? (
                                <Tooltip
                                    title={`${original.invoiceCount} ${original.invoiceCount === 1 ? " Timesheet" : " Timesheets"
                                        }`}
                                    aria-label="add">
                                    <span>
                                        <CheckCircle className={classes.iconCheck} />
                                    </span>
                                </Tooltip>
                            ) : (
                                <Typography style={{ textAlign: "center" }} variant="h6">
                                    -
                                </Typography>
                            )}
                        </div>
                    );
                },

                styles: { textAlign: "center", justify: "center" },
                headerStyle: { textAlign: "center", ...headerStyles },
                className: "-cursor-pointer",
            },
            {
                Header: "Committed",
                id: "qbInvoiceCount",
                disableSortBy: false,
                show: !isMobile,
                width: 120,
                accessor: ({ qbInvoiceCount }) => qbInvoiceCount,

                Cell: ({ row: { original } }) => {
                    return (
                        <div aria-haspopup="true" style={{ textAlign: "center", justify: "center" }}>
                            {original.invoiceCount > 0 && original.qbInvoiceCount > 0 ? (
                                <Tooltip title={`Commited to Quickbooks`} aria-label="add">
                                    <span>
                                        <CheckCircle className={classes.iconCheck} />
                                    </span>
                                </Tooltip>
                            ) : original.invoiceCount > 0 && original.qbInvoiceCount === 0 ? (
                                <Tooltip title={`Commited to Quickbooks`} aria-label="add">
                                    <span>
                                        <Warning className={classes.iconWarning} />
                                    </span>
                                </Tooltip>
                            ) : (
                                <Typography style={{ textAlign: "center" }} variant="h6">
                                    -
                                </Typography>
                            )}
                        </div>
                    );
                },

                styles: { textAlign: "center", justify: "center" },
                headerStyle: { textAlign: "center", ...headerStyles },
                className: "-cursor-pointer",
            },
            {
                Header: "Adjustments",
                id: "count",
                disableSortBy: false,
                show: !isMobile,
                disableFilters: this.state.showFilter,
                accessor: ({ count }) => count,
                Filter: ({ id }) => (
                    <Select name={id} id={id} value="Set All" style={{ fontSize: 12 }} onChange={e => { }}>
                        <MenuItem value="Set All">All</MenuItem>
                        <MenuItem value="adjustments">With Adjustments</MenuItem>
                    </Select>
                ),
                Cell: ({ row: { original } }) => {
                    return (
                        <div aria-haspopup="true" style={{ textAlign: "center", justify: "center" }}>
                            {original.count > 0 ? (
                                <Tooltip
                                    title={`${original.count} ${original.count === 1 ? "Adjustment" : " Adjustments"
                                        }`}
                                    aria-label="add">
                                    <span>
                                        {/*<InfoOutlined className={classes.iconInfo} />*/}
                                        {original.count}
                                    </span>
                                </Tooltip>
                            ) : (
                                <Typography style={{ textAlign: "center" }} variant="h6">
                                    -
                                </Typography>
                            )}
                        </div>
                    );
                },
                maxWidth: 120,
                styles: { textAlign: "center", justify: "center" },
                headerStyle: { textAlign: "center", ...headerStyles },
                className: "-cursor-pointer",
            },
            {
                Header: "Jobs",
                id: "jobs",
                accessor: ({ jobs }) => jobs,
                Cell: ({ row: { original } }) => formatNumber(original.jobs),
                width: 110,
                show: !isMobile,
                styles: { textAlign: "center", paddingLeft: isMobile ? 0 : 15 },
                headerStyle: { textAlign: "center", ...headerStyles },
                className: "-cursor-pointer",
            },
            {
                Header: "Shifts",
                id: "shifts",
                accessor: ({ shifts }) => shifts,
                Cell: ({ row: { original } }) => formatNumber(original.shifts),
                width: isMobile ? 70 : 100,
                styles: { textAlign: "center", paddingLeft: isMobile ? 0 : 15 },
                headerStyle: { textAlign: "center", ...headerStyles },
                className: "-cursor-pointer",
            },
            {
                Header: isMobile ? "Hours" : "Total Hours",
                id: "hoursWorkedLessBreak",
                Cell: ({ row: { original } }) => (original.hoursWorkedLessBreak ? formatNumber(original.hoursWorkedLessBreak) : "-"),
                accessor: ({ hoursWorkedLessBreak }) => hoursWorkedLessBreak,
                width: isMobile ? 60 : 110,
                styles: { textAlign: "center", paddingLeft: isMobile ? 0 : 25 },
                headerStyle: { textAlign: "center", ...headerStyles },
                className: "-cursor-pointer",
            },
            // {
            //     Header: "Regular",
            //     id: "regularHoursWorked",
            //     Cell: ({ row: { original } }) =>
            //         original.regularHoursWorked ? (
            //             formatNumber(original.regularHoursWorked)
            //         ) : (
            //             <Typography style={{ textAlign: "left", paddingLeft: 10 }} variant="h6">
            //                 -
            //             </Typography>
            //         ),
            //     accessor: ({ regularHoursWorked }) => regularHoursWorked,
            //     width: 110,
            //     styles: { textAlign: "left", paddingLeft: 25 },
            //     headerStyle: { textAlign: "left", fontSize: 13, fontWeight: 500 },
            //     className: "-cursor-pointer",
            //     show: false, //!isMobile,
            // },
            // {
            //     Header: "Overtime",
            //     id: "overtimeHoursWorked",
            //     Cell: ({ row: { original } }) =>
            //         original.overtimeHoursWorked ? (
            //             formatNumber(original.overtimeHoursWorked)
            //         ) : (
            //             <Typography style={{ textAlign: "left", paddingLeft: 10 }} variant="h6">
            //                 -
            //             </Typography>
            //         ),
            //     accessor: ({ overtimeHoursWorked }) => overtimeHoursWorked,
            //     width: 110,
            //     styles: { textAlign: "left", paddingLeft: 25 },
            //     headerStyle: { textAlign: "left", fontSize: 13, fontWeight: 500 },
            //     className: "-cursor-pointer",
            //     show: false, //!isMobile,
            // },
            // {
            //     Header: "Doubletime",
            //     id: "doubletimeHoursWorked",
            //     Cell: ({ row: { original } }) =>
            //         original.shifts.doubletimehoursworked ? (
            //             formatNumber(original.doubletimeHoursWorked)
            //         ) : (
            //             <Typography style={{ textAlign: "left", paddingLeft: 10 }} variant="h6">
            //                 -
            //             </Typography>
            //         ),
            //     accessor: ({ doubletimeHoursWorked }) => doubletimeHoursWorked,
            //     width: 100,
            //     styles: { textAlign: "left", paddingLeft: 25 },
            //     headerStyle: { textAlign: "left", fontSize: 13, fontWeight: 500 },
            //     className: "-cursor-pointer",
            //     show: false, //!isMobile,
            // },
            {
                Header: "Total",
                id: "total",
                disableSortBy: false,
                accessor: ({ billing }) => billing.totalBilling,
                Cell: ({ row: { original } }) => formatMoney(original.billing.totalBilling),
                width: isMobile ? 70 : 100,
                styles: { textAlign: "center" },
                headerStyle: { textAlign: "center", ...headerStyles, paddingRight: isMobile ? 0 : 15 },
                className: "-cursor-pointer",
            },

            /* {
               disableSortBy: false,
               filterable: false,
               width: isMobile ? 70 : 70,
               id: "actions",
               accessor: ({ employerId }) => employerId,

               Cell: ({ row: { original }}) => {
                 return (
                   <div
                     style={{
                       textAlign: "right",
                       justify: "right",
                       alignItems: "flex-end",
                       paddingRight: isMobile ? 0 : 15,
                       marginRight: isMobile ? 5 : 15,
                       marginLeft: isMobile ? 0 : 15,
                     }}>
                     <EmployerActionMenu employerId={original.employerId} week={billingWeek} />
                   </div>
                 );
               },
               Header: () => {
                 return (
                   <div
                     style={{
                       textAlign: "right",
                       justify: "right",
                       alignItems: "flex-end",
                       paddingTop: 8,
                       paddingRight: isMobile ? 0 : 0,
                       marginRight: isMobile ? 5 : 0,
                       marginLeft: isMobile ? 0 : 15,
                     }}>
                   </div>
                 );
               },
               styles: { textAlign: "right" },
               // headerStyle: { textAlign: "right"},
               className: "-cursor-pointer",
             },*/
        ];
        const RenderTable = () => {
            return <ReactTableSimple
                {...employerBillingTableProps}
                columns={renderBillingTableColumns}
                data={billingWeekSummary}
                onRowClick={(row) => {
                    this.props.history.push(`/finance/billingdetails/employer/${row.original.employerId}/${billingWeek}/${billingYear}`);
                }}
            />
        };
        return (
            <MainContent noTopPadding>
                <Grid container>
                    <TopBarCard week={billingWeek} billingYear={billingYear} getTableData={this.getTableData} />
                    <BillingSummaryStatsCard data={employerSummaryData} />
                </Grid>
                <Grid container>
                    <Typography className={classes.subTitle}>
                        Employers {billingWeekSummary.length}
                    </Typography>
                </Grid>

                <Grid container item xs={12} className={classes.tabbedTableContainer}>
                    <SwipeableViews axis="x" index={this.state.value} className={classes.tableContainer}>
                        <RenderTable />
                    </SwipeableViews>
                </Grid>
            </MainContent>
        );
    };
}

const mapState = state => {
    const employerSummaryData = BillingSelectors.BillingWeekEmployerSummary.employerSummary(state);
    const billingWeekSummary = BillingSelectors.getWeeklySummary(state);

    let ss = "";
    billingWeekSummary &&
        billingWeekSummary.map(week => {
            ss = ss + `+ ${week.shifts}`;
        });

    return { employerSummaryData, billingWeekSummary };
};

const mapActions = {
    fetchPreviousBillingWeekSummary: BillingActions.fetchPreviousBillingWeekSummary,
    fetchBillingWeekSummary: BillingActions.fetchBillingWeekSummary,
    checkWeekInvoices: BillingActions.checkWeekInvoices,
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const billingWeek = Number(ownProps.match.params.week);
    const billingYear = Number(ownProps.match.params.year);

    return {
        billingWeek,
        billingYear,
        // test:
        ...stateProps,
        ...dispatchProps,
        ...ownProps,
    };
};

export default connect(
    mapState,
    mapActions,
    mergeProps
)(billingDetailsViewStyles(BillingDetailsView));
