import { PaymentStatusCode } from "helpers/constants/index";
import * as pagination from "utils/graphql-pagination";
import { paginationSettings } from "./constants";
import { BillingRate } from "../../../helpers/constants";
//   ***** DC *****

export const getReversals = state => state.finance.reversals || [];
export const getDcReversals = state => state.finance.dcReversals || [];
export const getPaymentAndBillingCount = state => state.finance.paymentAndBillingCount || {};
export const getDcTransfers = state => state.finance.dcTransfers || [];
export const getDcTransferTotal = state => state.finance.dcTransferTotal || 0;
export const getDcPayrollIssues = state => state.finance.dcPayrollIssues || [];
export const getDcAccount = state => state.finance.dcAccount || {};
export const getPaymentsOverview = state => state.finance.paymentsOverview || {};
// export const getCanSaveQb = state => state.finance.canSaveQb;
export const getEmployerActiveInvoice = state => state.finance.activeInvoice || {};
export const getEmployerActiveInvoiceItems = state => state.finance.activeInvoiceItems || [];
export const isInvoiceCommitted = state => (state.finance.activeInvoice.qbInvoiceId ? true : false);

export const getEmployerWeekSummary = state => state.finance.employerWeekSummary || [];
export const getEmployerWeekInvoices = state => state.finance.employerInvoices || [];
export const getDeletedInvoices = state => state.finance.deletedInvoices || [];
export const getGeneratedBillingSummary = state => state.finance.generatedBillingSummary || [];
export const getEmployerBillingWeek = state => {
  return state.finance.employerBillingWeekSummary.map(item => {
    item.adjustments = item.payroll.length > 1 ? true : false;

    return item;
  });
};
//
// billingYearByWeek
export const getQbInvoiceNote = state => state.finance.qbInvoiceNote || {};
export const getQbEmployer = state => state.finance.qbEmployer || {};
export const getQbEmployerCreateData = state => state.finance.qbEmployerCreateData || {};
export const getQbEmployerUpdateData = state => state.finance.qbEmployerUpdateData || {};
export const getQbPaymentMethods = state => state.finance.qbPaymentMethods || [];
export const getQbTerms = state => state.finance.qbTerms || [];
export const getQbItems = state => state.finance.qbItems || [];
export const getQbPreferences = state => state.finance.qbPreferences || {};
export const getQbEmployerPayments = state => state.finance.qbEmployerPayments || [];
export const getEmployerQbInvoices = state => state.finance.employerQbInvoices || [];
export const getEmployerQbInvoiceAttachments = state => state.finance.qbInvoiceAttachments || [];
export const getQbPdf = state => state.finance.qbInvoicePdf || "";
export const getYearlyReportData = state => state.finance.billingYearSummary || [];
export const getCancelTransferReason = state => state.finance.cancelTransferReason || 'Transaction cancel';

export const getWeeklySummary = state =>
  state.finance.billingWeekSummary.map(employer => ({
    ...employer,
    billing: {
      totalBilling: employer.totalBilling,
      total:
        Number(
          (
            employer.hoursWorkedLessBreak * BillingRate +
            employer.doubletimeHoursWorked * (BillingRate * 2) +
            employer.overtimeHoursWorked * (BillingRate * 1.5)
          ).toFixed(2),
        ) || 0,
      regular: Number((employer.hoursWorkedLessBreak * BillingRate).toFixed(2)) || 0,
      overtime: Number((employer.overtimeHoursWorked * (BillingRate * 1.5)).toFixed(2)) || 0,
      doubletime: Number((employer.doubletimeHoursWorked * (BillingRate * 2)).toFixed(2)) || 0,
      billingRate: BillingRate,
    },
  }));

export const BillingWeekEmployerSummary = {
  root: state => state.finance || {},
  employerSummary: state => {
    const data = BillingWeekEmployerSummary.root(state).billingWeekSummary;
    const previousData = BillingWeekEmployerSummary.root(state).previousBillingWeekSummary;
    return (
      {
        shifts: { total: data.sum("shifts") || 0, previousTotal: previousData.sum("shifts") || 0 },
        employerStats: { count: data.length || 0, previousCount: previousData.length || 0 },
        billing: {
          totalBilling: data.sum("totalBilling"),
          previousTotalBilling: previousData.sum("totalBilling"),
          total:
            Number(
              (
                data.sum("hoursWorkedLessBreak") * BillingRate +
                data.sum("doubletimeHoursWorked") * (BillingRate * 2) +
                data.sum("overtimeHoursWorked") * (BillingRate * 1.5)
              ).toFixed(2),
            ) || 0,
          regular: Number((data.sum("hoursWorkedLessBreak") * BillingRate).toFixed(2)) || 0,
          overtime:
            Number((data.sum("overtimeHoursWorked") * (BillingRate * 1.5)).toFixed(2)) || 0,
          doubletime:
            Number((data.sum("doubletimeHoursWorked") * (BillingRate * 2)).toFixed(2)) || 0,
          billingRate: BillingRate,
          previousTotal:
            Number(
              (
                previousData.sum("hoursWorkedLessBreak") * BillingRate +
                previousData.sum("doubletimeHoursWorked") * (BillingRate * 2) +
                previousData.sum("overtimeHoursWorked") * (BillingRate * 1.5)
              ).toFixed(2),
            ) || 0,
          previousRegular: Number((previousData.sum("hoursWorkedLessBreak") * BillingRate).toFixed(2)) || 0,
          previousOvertime:
            Number((previousData.sum("overtimeHoursWorked") * (BillingRate * 1.5)).toFixed(2)) ||
            0,
          previousDoubletime:
            Number((previousData.sum("doubletimeHoursWorked") * (BillingRate * 2)).toFixed(2)) ||
            0,
        },
        hours: {
          total:
            Number(
              (
                data.sum("hoursWorkedLessBreak") +
                data.sum("doubletimeHoursWorked") +
                data.sum("overtimeHoursWorked")
              ).toFixed(2),
            ) || 0,
          regularHours: data.sum("hoursWorkedLessBreak") || 0,
          overtime: data.sum("overtimeHoursWorked") || 0,
          doubletime: data.sum("doubletimeHoursWorked") || 0,
          breaks: data.sum("breakValue") / 60 || 0,
          previousTotal:
            Number(
              (
                previousData.sum("hoursWorkedLessBreak") +
                previousData.sum("doubletimeHoursWorked") +
                previousData.sum("overtimeHoursWorked")
              ).toFixed(2),
            ) || 0,
          previousRegularHours: previousData.sum("hoursWorkedLessBreak") || 0,
          previousOvertime: previousData.sum("overtimeHoursWorked") || 0,
          previousDoubletime: previousData.sum("doubletimeHoursWorked") || 0,
          previousBreaks: previousData.sum("breakValue") / 60 || 0,
        },
      } || {}
    );
  },
  employerPerformance: state =>
    ({
      count: BillingWeekEmployerSummary.root(state).billingWeekSummary.length || 0,
      shifts: BillingWeekEmployerSummary.root(state).billingWeekSummary.sum("shifts"),
      hours: {
        total: BillingWeekEmployerSummary.root(state).billingWeekSummary.sum("shifts"),
        regular: BillingWeekEmployerSummary.root(state).billingWeekSummary.sum("hoursWorked"),
        overtime: BillingWeekEmployerSummary.root(state).billingWeekSummary.sum(
          "overtimeHoursWorked",
        ),
        doubletime: BillingWeekEmployerSummary.root(state).billingWeekSummary.sum(
          "doubletimeHoursWorked",
        ),
        breaks: BillingWeekEmployerSummary.root(state).billingWeekSummary.sum("breakValue") / 60,
      },
    } || {}),
  employerDetails: state => {
    const { employerBillingWeekSummary = [] } = BillingWeekEmployerSummary.root(state);

    return (
      {
        name: (employerBillingWeekSummary.length && employerBillingWeekSummary[0].employer) || "",
        address: (employerBillingWeekSummary.length && employerBillingWeekSummary[0].city) || "",
      } || {}
    );
  },
};

export const getSortedBillingWeekSummaryData = state => {
  const data = state.finance.billingWeekSummary;
  const payload = {
    employerCount: data.length,
    shifts: data.sum("shifts"),
    hours: {
      total: data.sum("shifts"),
      regular: data.sum("hoursWorked"),
      overtime: data.sum("overtimeHoursWorked"),
      doubletime: data.sum("doubletimeHoursWorked"),
      breaks: data.sum("breakValue"),
    },
  };
  // console.log("payload", payload);
  return payload;
};

// Loading selectors
export const getLoadingStateFactory = loader => state => state.finance.loading[loader] || false;

export const getIsApplyingAction = getLoadingStateFactory("actions");
export const getIsSendingPay = getLoadingStateFactory("sendingPay");
export const getIsFetchingBatch = getLoadingStateFactory("fetchingBatch");
export const getIsMarkingPaymentResolved = getLoadingStateFactory("markingPaymentResolved");
export const getIsRetryingPayment = getLoadingStateFactory("retryingPayment");
export const getIsCancelTransaction = getLoadingStateFactory("cancelTransaction");
export const getIsdownloadingPaystubZip = getLoadingStateFactory("downloadingPaystubZip");

export const getPaymentBatchesPaginationData = state =>
  state.finance[pagination.getPageInfoAttr(paginationSettings.paymentBatches)];
export const getTransfersPaginationData = state => state.finance[pagination.getPageInfoAttr(paginationSettings.transfers)];
export const getTransfersFilters = state => state.finance.dcTransferFilter || [];
export const getPaymentBatches = state => state.finance.batches.data;
export const getQueuedPaymentBatch = state => state.finance.queuedBatch;
// Billing
export const getBillingWeeks = state => [...state.finance.billingWeeks].reverse();
export const getBillingWeekEmployers = state => [...state.finance.employers];
export const getBillingWeekEmployerShifts = state => state.finance.employerBillingWeekShifts;
export const getWeeklyReportData = state => state.finance.weeklyBilling;
export const getDcPayroll = state => state.finance.dcPayroll;
export const getDcPayrollByStatus = state => state.finance.dcPayrollByStatus || [];
export const getIsLoading = state => state.finance.isLoading;

export const getSortedEmployerBilling = state => {
  const unique = [...new Set(state.finance.weeklyBilling.map(item => item.employerId))].map(
    employerId => {
      const emp = state.finance.weeklyBilling.filter(e => e.employerId === employerId);

      const employer = { employer: emp[0].employer, employerId: emp[0].employerId, shifts: emp };
      return employer;
    },
  );
  return unique;
  // console.log("test unique", unique);
};

export const WeeklyReportData = {
  root: state => state.finance.weeklyBilling || {},
  jobId(state) {
    return WeeklyReportData.root(state).jobid || 0;
  },
};

export const BatchDetails = {
  root: state => {
    return state.finance.batchDetails || {};
  },
  id(state) {
    return BatchDetails.root(state).id || 0;
  },
  createdAt(state) {
    return BatchDetails.root(state).createdAt;
  },
  sentAt(state) {
    return BatchDetails.root(state).createdAt;
  },
  responseAt(state) {
    return BatchDetails.root(state).createdAt;
  },
  lastUpdateAt(state) {
    return (
      BatchDetails.responseAt(state) || BatchDetails.sentAt(state) || BatchDetails.createdAt(state)
    );
  },
  status(state) {
    return BatchDetails.root(state).paymentBatchStatus;
  },
  totalCount(state) {
    return BatchDetails.root(state).totalCount || 0;
  },
  totalAmount(state) {
    return BatchDetails.root(state).totalAmount || 0;
  },
  outstandingCount(state) {
    return BatchDetails.root(state).outstandingCount || 0;
  },
  outstandingAmount(state) {
    return BatchDetails.root(state).outstandingAmount || 0;
  },
  unresolvedCount(state) {
    return BatchDetails.root(state).unresolvedCount || 0;
  },
  unresolvedAmount(state) {
    return BatchDetails.root(state).unresolvedAmount || 0;
  },
  resolvedCount(state) {
    return BatchDetails.root(state).resolvedCount || 0;
  },
  resolvedAmount(state) {
    return BatchDetails.root(state).resolvedAmount || 0;
  },
  acceptedCount(state) {
    return BatchDetails.root(state).acceptedCount || 0;
  },
  acceptedAmount(state) {
    return BatchDetails.root(state).acceptedAmount || 0;
  },
  payments(state) {
    return BatchDetails.root(state).payments || [];
  },
  outstandingPayments(state) {
    return BatchDetails.root(state).outstandingPayments || [];
  },
  unresolvedPayments(state) {
    return BatchDetails.root(state).unresolvedPayments || [];
  },
  resolvedPayments(state) {
    return BatchDetails.root(state).resolvedPayments || [];
  },
  acceptedPayments(state) {
    return BatchDetails.root(state).acceptedPayments || [];
  },
  statusFilteredPayments(filter) {
    return state => {
      if (filter === "All") {
        return BatchDetails.payments(state);
      }

      if (filter === "Outstanding") {
        return BatchDetails.outstandingPayments(state);
      }

      if (filter === "Unresolved") {
        return BatchDetails.unresolvedPayments(state);
      }

      if (filter === "Resolved") {
        return BatchDetails.resolvedPayments(state);
      }

      if (filter === "Accepted") {
        return BatchDetails.acceptedPayments(state);
      }

      return [];
    };
  },
  payment(payment = {}) {
    const Payment = {
      id() {
        return payment.id || 0;
      },
      email() {
        return payment.email || "";
      },
      paymentProviderReferenceCode() {
        return payment.paymentProviderReferenceCode || "";
      },
      amount() {
        return payment.amount || 0;
      },
      errorCode() {
        return payment.amount || "";
      },
      errorDescription() {
        return payment.amount || "";
      },
      error() {
        const code = Payment.errorCode();
        const desc = Payment.errorDescription();
        if (code && desc) {
          return `${Payment.errorCode()} - ${Payment.errorDescription()}`;
        }

        if (code) {
          return code;
        }

        if (desc) {
          return desc;
        }

        return "";
      },
      paymentStatus() {
        return payment.paymentStatus || {};
      },
      paymentStatusCode() {
        return Payment.paymentStatus().code || PaymentStatusCode.Unknown;
      },
      canRetry() {
        return [
          PaymentStatusCode.Failed,
          PaymentStatusCode.Declined,
          PaymentStatusCode.RetryCancelled,
          PaymentStatusCode.Cancelled,
          PaymentStatusCode.ManualAction,
          PaymentStatusCode.Unknown,
        ].includes(Payment.paymentStatusCode());
      },
      payroll() {
        return payment.payroll || {};
      },
      payrollType() {
        return Payment.payroll().payrollType || {};
      },
      payrollTypeName() {
        return Payment.payrollType().name || "";
      },
      position() {
        return Payment.payroll().position || {};
      },
      jobId() {
        return Payment.position().jobId || 0;
      },
      lastAcceptedPayroll() {
        return Payment.position().lastAcceptedPayroll || {};
      },
      worker() {
        return Payment.position().worker || {};
      },
      workerId() {
        return Payment.worker().id || "";
      },
      workerFullName() {
        return Payment.worker().fullName || "";
      },
      workerPhone() {
        return Payment.worker().phone || "";
      },
      workerStreet() {
        return Payment.worker().street || "";
      },
      workerCity() {
        return Payment.worker().city || "";
      },
      workerRegion() {
        return Payment.worker().region || "";
      },
      workerCountry() {
        return Payment.worker().country || "";
      },
      workerPostalCode() {
        return Payment.worker().postalCode || "";
      },
    };

    return Payment;
  },
};
