import React, { useCallback, useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import SwipeableViews from "react-swipeable-views";
import moment from "moment-timezone";
import Grid from "@mui/material/Grid";
import InputAdornment from "@mui/material/InputAdornment";

import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { AccountBalance, AccountCircle, Comment, EmailRounded, MonetizationOn } from "@mui/icons-material";
import SendTwoTone from "@mui/icons-material/SendTwoTone";
import TableChart from "@mui/icons-material/TableChart";
import { financeViewStyles } from "views/Finance/helpers";
import ResendStubDialog
    from "views/Job/JobDetails/components/JobPositions/components/PositionTable/components/Dialogs/ResendStubDialog";
import MainContent from "components/Containers/MainContent";
import SubNav from "components/Navigation/SubNav";
import SubNavTab from "components/Navigation/SubNavTab";
import { actions as FinanceActions, selectors as FinanceSelectors } from "store/modules/finance";
import TransferReversalDialog from "./components/Dialogs/TransferReversalDialog";

import TransfersTab from "./components/Tabs/ActiveTransfersTab/TransfersTab";
import PaymentOverviewTab from "./components/Tabs/PayrollOverviewTab/PaymentOverviewTab";

import ReversalsTab from "./components/Tabs/ReversalsTab/ReversalsTab";

function FinanceView() {
    const dispatch = useDispatch();
    const [view, setView] = useState(0);
    const [open, setOpen] = useState(false);
    const [payment, setPayment] = useState({});
    const dcTransfers = useSelector(FinanceSelectors.getDcTransfers);
    const dcTransferTotal = useSelector(FinanceSelectors.getDcTransferTotal);
    const paymentsOverview = useSelector(FinanceSelectors.getPaymentsOverview);
    const dcReversals = useSelector(FinanceSelectors.getDcReversals);
    const reversals = useSelector(FinanceSelectors.getReversals);
    const [emailEditable, setEmailEditable] = useState(true);
    const classes = financeViewStyles();
    const [reversal, setReversal] = useState({});
    const [openResendStub, setOpenResendStub] = useState(false);

    const nameRef = React.useRef(null);
    const reasonRef = React.useRef(null);
    const [, setTransferType] = useState("Expiring");
    const [setShowTransferStatusActionsDialog] = useState(false);
    const [stubPosition, setStubPosition] = React.useState({});
    const toggleLock = useCallback(() => {
        console.log("callajjadgh")
        setEmailEditable(v => !v);
    }, []);



    const handleCreateRequestForFunds = useCallback(() => {
        const {
            reason,
            amount,
            dcCustomerNumber,
            dcTransferId,
            positionId,
            payrollId,
            paymentId,
        } = reversal;

        dispatch(
            FinanceActions.createRequestForFunds({
                reason,
                amount: Number(amount),
                dcCustomerNumber,
                dcTransferId,
                positionId,
                payrollId,
                paymentId,
            })
        );
        toggleOpenDcReversal();
    }, [reversal, dispatch]);

    useEffect(() => {
        if (view === 0) {
            dispatch(FinanceActions.fetchDcAccount());
            dispatch(FinanceActions.fetchPaymentsOverview());
            // dispatch(FinanceActions.fetchTransfers({ status: null }));
            //   dispatch(FinanceActions.fetchReversals({ status: null }));
            //   dispatch(FinanceActions.fetchTransfers({ status: null }));
            //   setTransfers(dcPayrollByStatus);
        }
        if (view === 2) {
            dispatch(FinanceActions.fetchReversals({ status: null }));
        }
    }, [view]);

    useEffect(() => {
        setReversal({
            dcCustomerNumber: (payment && payment.dcCustomerNumber) || null,
            amount:
                (payment && payment.transfer && payment.transfer[0] && payment.transfer[0].Amount) || 0,
            dcTransferId: (payment && payment.dcTransferId) || null,
            paymentId: (payment && payment.eTransferHistory && payment.eTransferHistory[0].id) || null,
            payrollId:
                (payment && payment.eTransferHistory && payment.eTransferHistory[0].payrollId) || null,
            positionId: (payment && payment.positionId) || null,
            reason: `This request is a payment reversal for the payroll sent to with errors on ${moment(
                payment.available
            ).format(
                "MMM Do, YYYY"
            )}.  Kindly send the balance owing in a timely fashion in order to keep your account active.`,
        });
    }, [payment]);

    const handleFindRequestForFunds = useCallback(
        () => dispatch(FinanceActions.findRequestForFunds({})),
        [dcReversals, dispatch]
    );

    const toggleOpenDcReversal = useCallback(payment => {
        setOpen(v => !v);
        payment && setPayment(payment);
    }, []);

    const handleViewChange = (_, value) => setView(value);

    const onChange = useCallback(value => setReversal({ ...reversal, ...value }), [reversal]);

    const tabs = [
        <SubNavTab key="payments" label="Payroll Payment Overview" icon={<TableChart />} />,
        <SubNavTab
            key="ind_payrolls"
            label="Payroll Transfers"
            icon={<SendTwoTone />}
            count={dcTransferTotal || 0}
        />,
        <SubNavTab
            key="ind_Reversals"
            label="Payroll Reversals"
            icon={<SendTwoTone />}
            count={reversals.length || 0}
        />,
    ];

    const RenderContent = ({ results }) => {
        return (
            <Grid container alignItems="center">
                <Grid item xs={12}>
                    <Typography align="center" variant="body1">
                        Request An eTransfer Reversal
                    </Typography>
                    <Typography align="center" variant="body1" />
                </Grid>

                <Grid item xs={12} md={6} className={classes.leftColumn}>
                    <TextField
                        label="Worker"
                        name="name"
                        fullWidth
                        defaultValue={payment.worker?.firstName + ' ' + payment.worker?.lastName || ""}
                        disabled={true}
                        onChange={e => onChange({ [e.target.name]: e.target.value })}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <AccountCircle style={{ color: "green" }} />
                                </InputAdornment>
                            ),
                        }}
                    />
                </Grid>
                <Grid item xs={12} md={6} className={classes.rightColumn}>
                    <TextField
                        label="Email"
                        name="email"
                        type="email"
                        fullWidth
                        defaultValue={(payment && payment.email) || ""}
                        disabled={true}
                        onChange={e => onChange({ [e.target.name]: e.target.value })}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <EmailRounded style={{ color: "green" }} />
                                </InputAdornment>
                            ),
                        }}
                    />
                </Grid>
                <Grid item xs={12} md={6} className={classes.leftColumn}>
                    <TextField
                        label="dc Transfer Id"
                        name="dcTransferId"
                        type="number"
                        fullWidth
                        defaultValue={payment.dcTransferId || 0}
                        disabled={true}
                        onChange={e => onChange({ [e.target.name]: e.target.value })}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <AccountBalance style={{ color: "green" }} />
                                </InputAdornment>
                            ),
                        }}
                    />
                </Grid>
                <Grid item xs={12} md={6} className={classes.rightColumn}>
                    <TextField
                        label="Amount"
                        name="amount"
                        type="number"
                        fullWidth
                        step="0.5"
                        min={0}
                        inputRef={nameRef}
                        // inputProps={{
                        //   onKeyPress: event => {
                        //     const { key } = event;
                        //
                        //     nameRef.current.focus();
                        //     if (key === "Enter") {
                        //       nameRef.current.focus();
                        //     }
                        //   },
                        // }}
                        max={(payment.transfer && payment.transfer[0].amount) || 300}
                        defaultValue={reversal.amount}
                        disabled={false}
                        onBlur={e => onChange({ [e.target.name]: e.target.value })}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <MonetizationOn style={{ color: "green" }} />
                                </InputAdornment>
                            ),
                        }}
                    />
                </Grid>
                {results}
                <Grid item xs={12} className={classes.inputContainer}>
                    <TextField
                        label="Message"
                        name="reason"
                        fullWidth
                        inputRef={reasonRef}
                        rows={3}
                        defaultValue={reversal.reason || ""}
                        multiline
                        onBlur={e => onChange({ [e.target.name]: e.target.value })}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <Comment style={{ color: "green" }} />
                                </InputAdornment>
                            ),
                        }}
                    />
                </Grid>
            </Grid>
        );
    };
    const handleResendStub = () => {
        dispatch(FinanceActions.createAndSendPaystub(stubPosition.positionId));
        setOpenResendStub(v => !v);
    };

    const handleUninitializeAllUnprocessedPayroll = () =>
        dispatch(FinanceActions.uninitializeAllUnprocessedPayroll());

    const toggleResendStubDialog = useCallback(position => {
        //
        setStubPosition(position);
        setOpenResendStub(v => !v);
    });

    const toggleShowTransferStatusActionsDialog = useCallback(type => {
        setShowTransferStatusActionsDialog(v => !v);
        setTransferType(type || "Pending");
    }, []);

    return (
        <React.Fragment>
            <SubNav value={view} onChange={handleViewChange} tabs={tabs} />
            <MainContent style={{ height: "100%" }}>
                <SwipeableViews
                    axis="x"
                    index={view}
                    containerStyle={{ height: "100%" }}
                    style={{ height: "100%" }}
                    slideStyle={{ height: "100%" }}>
                    <PaymentOverviewTab
                        paymentsOverview={paymentsOverview}
                        toggleShowTransferStatusActionsDialog={toggleShowTransferStatusActionsDialog}
                        reversals={dcReversals}
                        dcReversals={dcReversals}
                        toggleOpenDcReversal={toggleOpenDcReversal}
                        handleFindRequestForFunds={handleFindRequestForFunds}
                        handleUninitializeAllUnprocessedPayroll={handleUninitializeAllUnprocessedPayroll}
                    />
                    <TransfersTab
                        toggleOpenDcReversal={toggleOpenDcReversal}
                        toggleResendStubDialog={toggleResendStubDialog}
                    />
                    <ReversalsTab
                        dcReversals={dcReversals}
                        toggleOpenDcReversal={toggleOpenDcReversal}
                        handleFindRequestForFunds={handleFindRequestForFunds}
                    />
                </SwipeableViews>
            </MainContent>

            {payment && (
                <TransferReversalDialog
                    emailEditable={emailEditable}
                    open={open}
                    payment={payment || {}}
                    setEmailEditable={setEmailEditable}
                    toggleLock={toggleLock}
                    onCancel={toggleOpenDcReversal}
                    onClose={toggleOpenDcReversal}
                    onConfirm={handleCreateRequestForFunds}
                    RenderContent={RenderContent}
                />
            )}
            {stubPosition && (
                <ResendStubDialog
                    open={openResendStub}
                    onClose={toggleResendStubDialog}
                    onCancel={toggleResendStubDialog}
                    onConfirm={handleResendStub}
                    position={stubPosition}
                />
            )}
        </React.Fragment>
    );
}

export default FinanceView;
