/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */

import React from "react";
import { connect } from "react-redux";
import moment from "moment-timezone";
import { InputAdornment, Link, OutlinedInput, TextField } from "@mui/material";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { AddCircle, InfoOutlined, RemoveCircle } from "@mui/icons-material";
import { MobileDateTimePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { employerBillingDetailsViewStyles, getTrProps } from "views/Finance/helpers";
import MainContent from "components/Containers/MainContent";
import Dialog from "components/Dialog/Dialog";
import PayrollDialog from "components/Dialog/PayrollDialog";
//
import { formatMoney, formatNumber } from "utils/stringUtils";
import { actions as BillingActions, selectors as FinanceSelectors } from "store/modules/finance";
import { popErrorAlert } from "store/modules/finance/actions/billingActions";
import TopBarCard from "../components/TopBar";
import EmployerStatsCard from "./components/EmployerStatsCard";
import InvoiceCard from "./components/InvoiceCard";
import InvoiceItemTable from "./components/InvoiceItemTable/InvoiceItemTable";
import InvoiceModalTable from "./components/table";
import ShiftMenu from "./ShiftMenu";
import { convertLocalToTimezone, convertLocalToUTC, convertUTCToTimezoneWithOffset } from "../../../../utils/time";
import { BillingRate } from "../../../../helpers/constants";
import DeletedInvoiceCard from "./components/DeletedInvoiceCard";
import ReactTableSimple from "components/ReactTable/ReactTableSimple";
import { PositionPermissions } from "helpers/constants/permissions";
import Can from "components/Authorization/Can";
const isMobile = false;
if (!Array.prototype.last) {
    Array.prototype.last = function () {
        return this[this.length - 1];
    };
}

const tableProps = {
    manual: false,
    minRows: 0,
    resizable: false,
    showPageSizeOptions: !isMobile ? true : false,
    showPageJump: false,
    showPaginationBottom: !isMobile ? true : false,
    showPaginationTop: isMobile ? true : false,
    classes: "-highlight",
    billingRateFilter: "all",
    breakLengthFilter: "all",
    // page: 0,
    // pageSize: 20,
    defaultPageSize: isMobile ? 5 : 10,
    disableFilters: !isMobile ? false : false,
    defaultSorted: [
        { id: "adjustments", desc: true },
        { id: "hasAdjustments", desc: true },
    ],
    defaultSortMethod: (a, b) => {
        // force null and undefined to the bottom
        a = a === null || a === undefined ? -Infinity : a;
        b = b === null || b === undefined ? -Infinity : b;
        // force any string values to lowercase
        a = typeof a === "string" ? a.toLowerCase() : a;
        b = typeof b === "string" ? b.toLowerCase() : b;
        // Return either 1 or -1 to indicate a sort priority
        if (a > b) {
            return 1;
        }
        if (a < b) {
            return -1;
        }
        // returning 0 or undefined will use any subsequent column sorting methods or the row index as a tiebreaker
        return 0;
    },
};
const BILLING_RATE = BillingRate;
const pricePoints = [20.95, 21.95, 22.95, 24.95, 25.95];
const columnStyles = { fontSize: isMobile ? 11 : 14, fontWeight: isMobile ? 500 : 700 };
const columnCellStyles = { fontSize: isMobile ? 11 : 12.25, fontWeight: isMobile ? 500 : 500 };

class EmployerBillingDetailsView extends React.Component {
    constructor() {
        super();
        this.state = {
            value: 0,
            totalBillable: 0,
            theLen: 0,
            view: 0,
            checkoutError: null,
            expanded: {},
            data: [],
            activeData: [],
            activeInvoice: [],
            payrollData: [],
            dataType: "employerBillingWeek",
            open: false,
            openPayrollDialog: false,
            canEdit: false,
            showShiftView: true,
            selectedDate: new Date(),
            openDeletedDialog: false,
            allBillingRateError: false,
            billingRateDefaultValue: ""
        };
    }

    componentDidMount = () => this.getTableData();

    validateChange = () => {
        const { popErrorAlert } = this.props;

        // popErrorAlert("aert message");
        // const { employerBillingWeek } = this.props;
        // const newD = { [name]: moment(date).format() };
        // const updateData = { ...og, ...newD };
        // var total = ((moment.duration(moment(updateData.checkout).diff(moment(updateData.checkin))).asMinutes() - updateData.breakValue) / 60) * updateData.billingRate;
        // this.props.setUpdateData({ ...updateData });
        // popErrorAlert("aert message");
        // setTimeout(
        //   () =>
        //     this.setState({
        //       data: employerBillingWeek,
        //       totalBillable: employerBillingWeek && employerBillingWeek.sum("total"),
        //     }),
        //   1000
        // );
    };

    handleInvoiceCheckoutChange = (date, name, og) => {
        const { employerBillingWeek } = this.props;
        const newD = { [name]: moment(date).format() };
        const updateData = { ...og, ...newD };
        // this.validateChange(date, name, og, "checkout");
        // this.setState({ checkoutError: "employerBillingWeek" });
        // var total = ((moment.duration(moment(updateData.checkout).diff(moment(updateData.checkin))).asMinutes() - updateData.breakValue) / 60) * updateData.billingRate;
        this.props.setUpdateData({ ...updateData });

        setTimeout(
            () =>
                this.setState({
                    data: employerBillingWeek,
                    totalBillable: this.props.employerBillingWeek
                        .filter(shift => shift.isRemoved !== true)
                        .sum("total"),
                }),
            1000
        );
    };

    //        
    renderEditableDate = (cellInfo, permission) => {
        const { classes } = this.props;
        const pickerProps = {
            hideTabs: true,
            error: false,
            readOnly: !this.state.canEdit,
            disabled: !this.state.canEdit,
            autoOk: true,
            emptyLabel: ",",
            invalidLabel: ",",
            inputFormat: "hh:mm a",
            DialogProps: { className: classes.dateTimePicker },
            renderInput: (props) => <TextField {...props} variant="outlined" />,
            value: convertLocalToTimezone(
                cellInfo.row.original[cellInfo.column.id],
                cellInfo.row.original.payroll[0].position.job.timezone
            ),
            onChange: date => {
                this.handleCheckinChange(date, cellInfo.column.id, cellInfo.row.original);
            },
        };
        return (
            <Can perform={permission}>
                {allowed => {
                    if (!allowed || !this.state.canEdit) {
                        return <Grid>
                            {convertUTCToTimezoneWithOffset(cellInfo.row.original[cellInfo.column.id], "", "hh:mm a")}
                        </Grid>;
                    }
                    return (<LocalizationProvider dateAdapter={AdapterDateFns}>
                        <MobileDateTimePicker {...pickerProps} />
                    </LocalizationProvider >)
                }}
            </Can>
        );
    };
    handleInvoiceCheckinChange = (date, name, og) => {
        const { employerBillingWeek } = this.props;
        const newD = { [name]: moment(date).format() };
        const updateData = { ...og, ...newD };
        this.props.setUpdateData({ ...updateData });
        this.validateChange(date, name, og, "checkin");
        setTimeout(
            () =>
                this.setState({
                    data: employerBillingWeek,
                    totalBillable: this.props.employerBillingWeek
                        .filter(shift => shift.isRemoved !== true)
                        .sum("total"),
                }),
            1000
        );
    };

    handleCheckoutChange = (date, name, og) => {
        const { employerBillingWeek } = this.props;
        const newD = { [name]: moment(date).format("MMM Do, YYYY h:mm a") };
        const updateData = { ...og, ...newD };
        this.validateChange(date, name, og, "checkout");
        // this.setState({ checkoutError: "employerBillingWeek" });
        this.props.setUpdateData({ ...updateData });
        setTimeout(
            () =>
                this.setState({
                    data: employerBillingWeek,
                    totalBillable: this.props.employerBillingWeek
                        .filter(shift => shift.isRemoved !== true)
                        .sum("total"),
                }),
            1000
        );
    };

    getStats = () => {
        const { employerBillingWeek, previousBillingWeekSummary } = this.props;
        var jobStats = {};
        for (let { jobId } of employerBillingWeek)
            jobStats[jobId] = { jobId, count: jobStats[jobId] ? jobStats[jobId].count + 1 : 1 };

        const stats = {
            shifts: {
                total: this.props.employerBillingWeek.filter(shift => shift.isRemoved !== true).length || 0,
                previousTotal: previousBillingWeekSummary.length || 0,
            },
            jobs: {
                stats: jobStats,
                total: Object.keys(jobStats).length || 0,
                previousTotal: Object.keys(jobStats).length * 0.7 || 0,
            },
            billing: {
                regular:
                    Number(
                        (
                            this.props.employerBillingWeek
                                .filter(shift => shift.isRemoved !== true)
                                .sum("regularHoursWorked") * BILLING_RATE
                        ).toFixed(2)
                    ) || 0,
                overtime:
                    Number(
                        (
                            this.props.employerBillingWeek
                                .filter(shift => shift.isRemoved !== true)
                                .sum("overtimeHoursWorked") *
                            (BILLING_RATE * 1.5)
                        ).toFixed(2)
                    ) || 0,
                doubletime:
                    Number(
                        (
                            this.props.employerBillingWeek
                                .filter(shift => shift.isRemoved !== true)
                                .sum("doubletimeHoursWorked") *
                            (BILLING_RATE * 2)
                        ).toFixed(2)
                    ) || 0,
                billingRate: BILLING_RATE,
                total:
                    Number(
                        (
                            this.props.employerBillingWeek
                                .filter(shift => shift.isRemoved !== true)
                                .sum("overtimeHoursWorked") *
                            (BILLING_RATE * 1.5)
                        ).toFixed(2)
                    ) +
                    Number(
                        (
                            this.props.employerBillingWeek
                                .filter(shift => shift.isRemoved !== true)
                                .sum("doubletimeHoursWorked") *
                            (BILLING_RATE * 2)
                        ).toFixed(2)
                    ) +
                    Number(
                        (
                            this.props.employerBillingWeek
                                .filter(shift => shift.isRemoved !== true)
                                .sum("regularHoursWorked") * BILLING_RATE
                        ).toFixed(2)
                    ),
                previousRegular:
                    Number(
                        (previousBillingWeekSummary.sum("regularHoursWorked") * BILLING_RATE).toFixed(2)
                    ) || 0,
                previousOvertime:
                    Number(
                        (previousBillingWeekSummary.sum("overtimeHoursWorked") * (BILLING_RATE * 1.5)).toFixed(
                            2
                        )
                    ) || 0,
                previousDoubletime:
                    Number(
                        (previousBillingWeekSummary.sum("doubletimeHoursWorked") * (BILLING_RATE * 2)).toFixed(
                            2
                        )
                    ) || 0,
                pastTotal:
                    previousBillingWeekSummary.sum("overtimeHoursWorked") * (BILLING_RATE * 1.5) +
                    previousBillingWeekSummary.sum("doubletimeHoursWorked") * (BILLING_RATE * 2) +
                    previousBillingWeekSummary.sum("regularHoursWorked") * BILLING_RATE,
            },
            hours: {
                regularHours:
                    this.props.employerBillingWeek
                        .filter(shift => shift.isRemoved !== true)
                        .sum("regularHoursWorked") || 0,
                overtime:
                    this.props.employerBillingWeek
                        .filter(shift => shift.isRemoved !== true)
                        .sum("overtimeHoursWorked") || 0,
                doubletime:
                    this.props.employerBillingWeek
                        .filter(shift => shift.isRemoved !== true)
                        .sum("doubletimeHoursWorked") || 0,
                breaks:
                    this.props.employerBillingWeek
                        .filter(shift => shift.isRemoved !== true)
                        .sum("breakValue") / 60 || 0,
                previousRegularHours: previousBillingWeekSummary.sum("regularHoursWorked") || 0,
                previousOvertime: previousBillingWeekSummary.sum("overtimeHoursWorked") || 0,
                previousDoubletime: previousBillingWeekSummary.sum("doubletimeHoursWorked") || 0,
                previousBreaks: previousBillingWeekSummary.sum("breakValue") / 60 || 0,
            },
        };
        return stats;
    };

    handleCheckinChange = (date, name, og) => {
        const { employerBillingWeek } = this.props;
        const newD = { [name]: convertLocalToUTC(date).format() };
        const updateData = { ...og, ...newD };
        this.props.setUpdateData({ ...updateData });
        // this.validateChange(date, name, og, "checkInUtc");
        this.setState({
            data: employerBillingWeek,
            totalBillable: this.props.employerBillingWeek
                .filter(shift => shift.isRemoved !== true)
                .sum("total"),
        });
        setTimeout(() => {
            // this.setState({
            //     data: employerBillingWeek,
            //     totalBillable: this.props.employerBillingWeek
            //         .filter(shift => shift.isRemoved !== true)
            //         .sum("total"),
            // })
        }, 1000);
    };

    getTableData = () => {
        const {
            fetchEmployerBillingWeekSummary,
            fetchEmployerInvoicesByWeek,
            fetchDeletedInvoices,
            employerId,
            billingWeek,
            billingYear,
        } = this.props;

        fetchEmployerInvoicesByWeek(employerId, billingWeek, billingYear).then(invoices => {
            let activeInvoice = invoices && invoices.length > 0 ? JSON.parse(JSON.stringify(invoices.last().invoiceItems)) : null;
            let sum = 0;

            if (activeInvoice) {
                activeInvoice = activeInvoice.map(inv => {
                    const total =
                        ((moment.duration(moment(inv.checkOut).diff(moment(inv.checkIn))).asMinutes() -
                            inv.breakDeductionMins) /
                            60) *
                        inv.rate;
                    sum = sum + total;
                    inv.total = total;
                    return inv;
                });
                this.setState({
                    activeInvoice: activeInvoice ? activeInvoice : [],
                    showShiftView: false
                });
            }

            fetchEmployerBillingWeekSummary(employerId, billingWeek, billingYear).then(
                employerBillingWeek => {
                    this.setState({
                        data: employerBillingWeek,
                        totalBillable:
                            activeInvoice && this.state.dataType === "activeInvoice"
                                ? activeInvoice.sum("total")
                                : employerBillingWeek.sum("total"),
                        activeData: activeInvoice ? activeInvoice : [],
                        dataType: activeInvoice ? "activeInvoice" : "employerBillingWeek",
                        employer: "this employer",
                        canEdit: false,
                        showShiftView: activeInvoice && activeInvoice.length ? false : true,
                        selected: null,
                        selectedDate: new Date(),
                    });
                }
            );
        });
        fetchDeletedInvoices(employerId, billingWeek, billingYear)
    };

    handleViewChange = view => this.setState({ view: view === 1 ? 0 : 1 });
    handleClose = () => this.setState({ open: false, openPayrollDialog: false });
    handleOpen = () => this.setState({ open: true });

    renderBreakSelect = cellInfo =>
        this.state.canEdit && !this.props.employerBillingWeek[cellInfo.row.index].isRemoved ? (
            <Select
                name={cellInfo.column.id}
                id={cellInfo.column.id}
                defaultValue={this.props.employerBillingWeek[cellInfo.row.index][cellInfo.column.id] || 0}
                value={this.props.employerBillingWeek[cellInfo.row.index][cellInfo.column.id] || 0}
                style={{ fontSize: 12 }}
                // InputProps={{
                //     startAdornment: <InputAdornment position="start">Kg</InputAdornment>,
                // }}
                onChange={e => {
                    let updateData = {
                        [e.target.name]: e.target.name === "breakValue" ? e.target.value : e.target.value,
                    };
                    //   fieldValue = updateData[cellInfo.column.id];
                    this.props.setUpdateData({
                        ...this.props.employerBillingWeek[cellInfo.row.index],
                        ...updateData,
                    });
                    setTimeout(
                        () =>
                            this.setState({
                                data: this.props.employerBillingWeek,
                                totalBillable:
                                    this.props.employerBillingWeek && this.props.employerBillingWeek.sum("total"),
                            }),
                        1000
                    );
                }}>
                <MenuItem value={0}>
                    {/* <AvTimer fontSize="small" style={{ marginRight: 10 }} /> */}
                    No Breaks
                </MenuItem>
                <MenuItem value={15}>
                    {/* <AvTimer fontSize="small" style={{ marginRight: 10 }} /> */}
                    <Typography variant="inherit"> 15 mins</Typography>
                </MenuItem>
                <MenuItem value={30}>
                    {/* <AvTimer fontSize="small" style={{ marginRight: 10 }} /> */}
                    <Typography variant="inherit"> 30 mins</Typography>
                </MenuItem>
                <MenuItem value={45}>
                    {/* <AvTimer fontSize="small" style={{ marginRight: 10 }} /> */}
                    <Typography variant="inherit"> 45 mins</Typography>
                </MenuItem>
                <MenuItem value={60}>
                    {/* <AvTimer fontSize="small" style={{ marginRight: 10 }} /> */}
                    <Typography variant="inherit"> 60 mins</Typography>
                </MenuItem>
            </Select>
        ) : this.props.employerBillingWeek[cellInfo.row.index].isRemoved ? (
            <span style={{ fontWeight: 400 }}>No Breaks</span>
        ) : cellInfo.value ? (
            `${cellInfo.value} mins`
        ) : (
            `No Breaks`
        );

    renderEditableSelect = cellInfo => {
        const { employerBillingWeek } = this.props;
        const { canEdit } = this.state;
        let fieldValue = cellInfo.value;
        return canEdit && !this.props.employerBillingWeek[cellInfo.row.index].isRemoved ? (
            <Select
                name={cellInfo.column.id}
                id={cellInfo.column.id}
                value={
                    employerBillingWeek[cellInfo.row.index][cellInfo.column.id] || "" //   value={age} //
                }
                style={{ fontSize: 12 }}
                onChange={e => {
                    const value = e.target.name === "billingRate" ? Number(e.target.value) : e.target.value;
                    const newD = { [e.target.name]: value };
                    const updateData = { ...employerBillingWeek[cellInfo.row.index], ...newD };
                    fieldValue = updateData[cellInfo.column.id];
                    this.props.setUpdateData(updateData);
                    setTimeout(
                        () =>
                            this.setState({
                                data: employerBillingWeek,
                                totalBillable: this.props.employerBillingWeek
                                    .filter(shift => shift.isRemoved !== true)
                                    .sum("total"),
                            }),
                        1000
                    );
                }}>
                <MenuItem value={20.95}>$20.95</MenuItem>
                <MenuItem value={21.95}>$21.95</MenuItem>
                <MenuItem value={24.95}>$24.95</MenuItem>
                {/* <MenuItem value={"24.95"}>24.95</MenuItem> */}
            </Select>
        ) : this.props.employerBillingWeek[cellInfo.row.index].isRemoved ? (
            <span style={{ fontWeight: 400 }}> {formatMoney(fieldValue)}</span>
        ) : (
            formatMoney(fieldValue)
        );
    };

    rendreEditableBillingRateColumn = cellInfo => {
        const { canEdit } = this.state;
        let fieldValue = cellInfo.value;
        const inputValue = this.props.employerBillingWeek[cellInfo.row.index][cellInfo.column.id] || "";
        return canEdit && !this.props.employerBillingWeek[cellInfo.row.index].isRemoved ? (
            <OutlinedInput
                key={`${cellInfo.column.id}-${cellInfo.row.index}`}
                type="number"
                className={this?.props?.classes?.hideArrows}
                error={!inputValue || inputValue <= 0}
                name={cellInfo.column.id}
                id={cellInfo.column.id}
                value={inputValue}
                style={{ fontSize: 12, border: "none" }}
                startAdornment={<InputAdornment position="start">$</InputAdornment>}
                onChange={e => {
                    const value = e.target.name === "billingRate" ? Number(e.target.value) : e.target.value;
                    const newD = { [e.target.name]: value };
                    const updateData = { ...this.props.employerBillingWeek[cellInfo.row.index], ...newD };
                    this.props.setUpdateData(updateData);
                    setTimeout(
                        () =>
                            this.setState({
                                data: this.props.employerBillingWeek,
                                totalBillable: this.props.employerBillingWeek
                                    .filter(shift => shift.isRemoved !== true)
                                    .sum("total"),
                            }),
                        1000
                    );
                }}
            />
        ) : this.props.employerBillingWeek[cellInfo.row.index].isRemoved ? (
            <span style={{ fontWeight: 400 }}> {formatMoney(fieldValue)}</span>
        ) : (
            formatMoney(fieldValue)
        );
    }

    renderHeader = () => {
        const shift = this.props.employerBillingWeek.filter(n => !n.isRemoved);
        return (
            <Grid container>
                <Grid item xs={6}>
                    {/* <Grow in={this.state.data && this.state.data.length ? true : false} timeout={500}> */}
                    <Grid container justifyContent="flex-start" className={this.props.classes.wrapper}>
                        <Typography
                            style={{ color: "#aebecd", fontWeight: 500, fontSize: 20, fontFamily: "Roboto" }}>
                            {this.state.dataType === "activeInvoice" ? `Timesheet` : `Pending Hours Overview`}
                        </Typography>
                    </Grid>
                    {/* </Grow> */}

                    {/* <Typography variant="h6">Billing Details</Typography> */}
                    {/* <Typography variant="h6">{formatMoney(this.state.totalBillable)}</Typography> */}
                </Grid>
                <Grid item xs={6}>
                    {this.state.canEdit ? (
                        <div
                            style={{
                                textAlign: "right",
                                fontSize: 14,
                                fontWeight: 700,
                                alignItems: "flex-end",
                            }}>
                            <Button
                                onClick={() =>
                                    this.setState(
                                        {
                                            canEdit: false,
                                            allBillingRateError: false,
                                            data:
                                                this.state.dataType === "activeInvoice"
                                                    ? this.props.employerWeekInvoices.last().invoiceItems
                                                    : this.props.employerBillingWeek,
                                            totalBillable:
                                                this.state.dataType === "activeInvoice"
                                                    ? this.state.sum
                                                    : this.props.employerBillingWeek.sum("total"),
                                        },
                                        () => this.getTableData()
                                    )
                                }
                                size="small"
                                variant="outlined"
                                color="primary"
                                style={{ textAlign: "right", marginRight: 10 }}>
                                Cancel
                            </Button>
                            <Button
                                onClick={() => {
                                    this.setState({
                                        open: !this.state.open,
                                        allBillingRateError: false
                                        //   dataType: "activeInvoice",
                                        // data:
                                        //     this.state.dataType === "activeInvoice"
                                        //         ? this.props.employerWeekInvoices.last().invoiceItems
                                        //         : this.props.employerBillingWeek,
                                    });
                                }}
                                disabled={shift.length === 0 || shift?.some(s => !s.billingRate || s.billingRate <= 0)}
                                size="small"
                                variant="contained"
                                color="secondary">
                                Create Timesheet
                            </Button>
                        </div>
                    ) : (
                        ""
                    )}
                </Grid>
            </Grid>
        );
    }

    renderDefaultColumns = () => {
        const { canEdit } = this.state;

        const { classes } = this.props;

        return [
            {
                Header: "Worker",
                id: "worker",
                sortable: true,
                accessor: ({ worker }) => worker,
                disableFilters: true,
                disableResizing: true,
                Cell: ({ row: data }) =>
                    this.props.employerBillingWeek[data.index].isRemoved ? (
                        <span style={{ fontWeight: 400 }}> {data.original.worker}</span>
                    ) : (
                        `${data.original.worker}`
                    ),
                className: "-cursor-pointer",
                minWidth: isMobile ? 100 : 200,
                style: { textAlign: "left", ...columnCellStyles },
                headerStyle: { textAlign: "left", ...columnStyles },
                sortMethod: (a, b) => {
                    if (a === b) {
                        return 0;
                    }
                    const aReverse = a.split("").reverse().join("");
                    const bReverse = b.split("").reverse().join("");
                    return aReverse > bReverse ? 1 : -1;
                },
            },
            {
                Header: "JobId",
                id: "jobId",
                sortable: true,
                accessor: ({ jobId }) => jobId,
                disableFilters: true,
                Cell: ({ row: data }) =>
                    this.props.employerBillingWeek[data.index].isRemoved ? (
                        <span style={{ fontWeight: 400 }}> {data.original.jobId}</span>
                    ) : (
                        `${data.original.jobId}`
                    ),
                className: "-cursor-pointer",
                width: isMobile ? 0 : 60,
                show: !isMobile,
                style: { textAlign: "left", ...columnCellStyles },
                headerStyle: { textAlign: "left", ...columnStyles },
                // sortMethod: (a, b) => {
                //   if (a === b) {
                //     return 0;
                //   }
                //   const aReverse = a.split("").reverse().join("");
                //   const bReverse = b.split("").reverse().join("");
                //   return aReverse > bReverse ? 1 : -1;
                // },
            },
            {
                Header: "Job Date",
                id: "jobDate",
                sortable: true,
                disableFilters: true,
                accessor: ({ jobDate }) => jobDate,
                Cell: ({ row: data }) =>
                    this.props.employerBillingWeek[data.index].isRemoved ? (
                        <span style={{ fontWeight: 400 }}>
                            {isMobile
                                ? convertUTCToTimezoneWithOffset(data.original.jobDate, "", "MMM DD, 'YY")
                                : convertUTCToTimezoneWithOffset(data.original.jobDate, "", "MMM Do, YYYY")}
                        </span>
                    ) : isMobile ? (
                        convertUTCToTimezoneWithOffset(data.original.jobDate, "", "MMM DD, 'YY")
                    ) : (
                        convertUTCToTimezoneWithOffset(data.original.jobDate, "", "MMM Do, YYYY")
                    ),
                className: "-cursor-pointer",
                minWidth: isMobile ? 70 : 120,
                style: { textAlign: "left", ...columnCellStyles },
                headerStyle: { textAlign: "left", ...columnStyles },
            },
            {
                Header: "Check In",
                id: "checkInUtc",
                sortable: true,
                disableFilters: true,
                accessor: ({ checkInUtc }) => checkInUtc,
                className: "-cursor-pointer",
                Cell: (prop) => this.renderEditableDate(prop, PositionPermissions.update.checkIn.value),
                minWidth: isMobile ? 80 : 120,
                style: { textAlign: "left", ...columnCellStyles },
                headerStyle: { textAlign: "left", ...columnStyles },
            },
            {
                Header: "Check Out",
                id: "checkOutUtc",
                sortable: true,
                disableFilters: true,
                accessor: ({ checkOutUtc }) => checkOutUtc,
                className: "-cursor-pointer",
                Cell: (prop) => this.renderEditableDate(prop, PositionPermissions.update.checkOut.value),
                minWidth: isMobile ? 80 : 120,
                style: { textAlign: "left", ...columnCellStyles },
                headerStyle: { textAlign: "left", ...columnStyles },
            },
            {
                Header: "Break",
                id: "breakValue",
                sortable: true,
                disableFilters: canEdit,
                accessor: info => info.breakValue,
                className: "-cursor-pointer",
                Cell: ({ row, column }) =>
                    this.state.canEdit && !this.props.employerBillingWeek[row.index].isRemoved ? (
                        <Select
                            name={column.id}
                            id={column.id}
                            disableUnderline={false}
                            variant="outlined"
                            defaultValue={this.props.employerBillingWeek[row.index][column.id] || 0}
                            value={this.props.employerBillingWeek[row.index][column.id] || 0}
                            style={{ fontSize: 12 }}
                            // InputProps={{
                            //     startAdornment: <InputAdornment position="start">Kg</InputAdornment>,
                            // }}
                            onChange={e => {
                                let updateData = {
                                    [e.target.name]: e.target.name === "breakValue" ? e.target.value : e.target.value,
                                };
                                //   fieldValue = updateData[cellInfo.column.id];
                                this.props.setUpdateData({
                                    ...this.props.employerBillingWeek[row.index],
                                    ...updateData,
                                });
                                setTimeout(
                                    () =>
                                        this.setState({
                                            data: this.props.employerBillingWeek,
                                            totalBillable:
                                                this.props.employerBillingWeek &&
                                                this.props.employerBillingWeek.sum("total"),
                                        }),
                                    1000
                                );
                            }}>
                            <MenuItem value={0}>
                                {/* <AvTimer fontSize="small" style={{ marginRight: 10 }} /> */}
                                No Breaks
                            </MenuItem>
                            <MenuItem value={15}>
                                {/* <AvTimer fontSize="small" style={{ marginRight: 10 }} /> */}
                                <Typography variant="inherit"> 15 mins</Typography>
                            </MenuItem>
                            <MenuItem value={30}>
                                {/* <AvTimer fontSize="small" style={{ marginRight: 10 }} /> */}
                                <Typography variant="inherit"> 30 mins</Typography>
                            </MenuItem>
                            <MenuItem value={45}>
                                {/* <AvTimer fontSize="small" style={{ marginRight: 10 }} /> */}
                                <Typography variant="inherit"> 45 mins</Typography>
                            </MenuItem>
                            <MenuItem value={60}>
                                {/* <AvTimer fontSize="small" style={{ marginRight: 10 }} /> */}
                                <Typography variant="inherit"> 60 mins</Typography>
                            </MenuItem>
                        </Select>
                    ) : this.props.employerBillingWeek[row.index].isRemoved ? (
                        <span style={{ fontWeight: 400 }}>No Breaks</span>
                    ) : row.value ? (
                        `${row.value} mins`
                    ) : (
                        `No Breaks`
                    ),
                width: isMobile ? 100 : 100,
                style: { textAlign: "left", ...columnCellStyles },
                headerStyle: { textAlign: "left", ...columnStyles },
                Filter: ({ id }) => (
                    <Select
                        name={id}
                        disableUnderline={false}
                        variant="outlined"
                        id={id}
                        value="Set All"
                        style={{ fontSize: 12 }}
                        onChange={e => {
                            this.props.employerBillingWeek.map(shift => {
                                shift.breakValue = e.target.value;
                                this.props.setUpdateData({ ...shift });
                            });
                            this.setState({
                                data: this.props.employerBillingWeek,
                                totalBillable: this.props.employerBillingWeek
                                    .filter(shift => shift.isRemoved !== true)
                                    .sum("total"),
                            });
                        }}>
                        <MenuItem value="Set All">Set All</MenuItem>
                        <MenuItem value={0}>No Breaks</MenuItem>
                        <MenuItem value={15}>
                            <Typography variant="inherit"> 15 mins</Typography>
                        </MenuItem>
                        <MenuItem value={30}>
                            <Typography variant="inherit"> 30 mins</Typography>
                        </MenuItem>
                        <MenuItem value={45}>
                            <Typography variant="inherit"> 45 mins</Typography>
                        </MenuItem>
                        <MenuItem value={60}>
                            <Typography variant="inherit"> 60 mins</Typography>
                        </MenuItem>
                    </Select>
                ),
            },
            {
                Header: "Duration",
                id: "duration",
                sortable: true,
                disableFilters: true,
                accessor: accessor => {
                    const duration =
                        (moment
                            .duration(moment(accessor.checkOutUtc).diff(moment(accessor.checkInUtc)))
                            .asMinutes() -
                            accessor.breakValue) /
                        60;

                    return duration.toFixed(2);
                },
                Cell: ({ row: data, value, ...rest }) => {
                    return this.props.employerBillingWeek[data.index].isRemoved ? (
                        <span style={{ fontWeight: 400 }}> 0 hrs</span>
                    ) : (
                        `${formatNumber(value)} hrs`
                    )
                },
                className: "-cursor-pointer",
                width: isMobile ? 70 : 100,
                style: { textAlign: "left", ...columnCellStyles, paddingLeft: 10 },
                headerStyle: { textAlign: "left", ...columnStyles },
            },
            {
                Header: "Rate",
                id: "billingRate",
                sortable: true,
                disableFilters: canEdit,
                accessor: info => info.billingRate,
                className: "-cursor-pointer",
                Cell: this.rendreEditableBillingRateColumn,
                width: isMobile ? 50 : 90,
                styles: { textAlign: "left" },
                headerStyle: { textAlign: "left", ...columnStyles },
                Filter: ({ id }) => (
                    <OutlinedInput
                        type="number"
                        name={id}
                        defaultValue={this.state.allBillingRateError ? this.state.billingRateDefaultValue : ''}
                        id={id}
                        placeholder="Set All"
                        error={this.state.allBillingRateError}
                        style={{ fontSize: 12, border: "none" }}
                        className={this?.props?.classes?.hideArrows}
                        startAdornment={<InputAdornment position="start">$</InputAdornment>}
                        onBlur={e => {
                            this.props.employerBillingWeek.map(shift => {
                                shift.billingRate = Number(e.target.value);
                                this.props.setUpdateData({ ...shift });
                            });
                            this.setState({
                                allBillingRateError: !e.target.value || e.target.value <= 0,
                                billingRateDefaultValue: Number(e.target.value),
                                data: this.props.employerBillingWeek,
                                totalBillable: this.props.employerBillingWeek
                                    .filter(shift => shift.isRemoved !== true)
                                    .sum("total"),
                            });
                        }}
                    />
                ),
            },
            {
                Header: "Total",
                id: "total",
                disableSortBy: true,
                disableFilters: true,
                accessor: info => {
                    const total =
                        ((moment.duration(moment(info.checkOutUtc).diff(moment(info.checkInUtc))).asMinutes() -
                            info.breakValue) /
                            60) *
                        info.billingRate;
                    return total.toFixed(2);
                },

                Cell: ({ row: data, value }) =>
                    this.props.employerBillingWeek[data.index].isRemoved ? (
                        <span style={{ fontWeight: 400 }}> $0.00</span>
                    ) : (
                        `${formatMoney(value)}`
                    ),
                className: "-cursor-pointer",
                width: isMobile ? 60 : 70,
                styles: { textAlign: "right", ...columnStyles },
                headerStyle: { textAlign: "right", ...columnStyles },
                pivot: true,
            },
            {
                Header: "Status",
                id: "adjustments",
                sortable: true,
                show: true,
                disableFilters: true,
                accessor: ({ adjustments }) => adjustments,
                Filter: ({ id }) => (
                    <Select disableUnderline={false}
                        variant="outlined"
                        name={id} id={id} value="Set All" style={{ fontSize: 12 }} onChange={() => { }}>
                        <MenuItem value="Set All">All</MenuItem>
                        <MenuItem value="adjustments">With Adjustments</MenuItem>
                    </Select>
                ),
                Cell: ({ row: { original } }) => {
                    return (
                        <div aria-haspopup="true" style={{ textAlign: "center", justify: "center" }}>
                            {original.adjustments ? (
                                <Tooltip title={`Adjustments`} aria-label="add">
                                    <InfoOutlined
                                        className={classes.iconInfo}
                                        onClick={() => {
                                            setTimeout(() => {
                                                this.setState({
                                                    openPayrollDialog: !this.props.openPayrollDialog,
                                                    payrollData: original.payroll,
                                                });
                                            }, 1000);
                                        }}
                                    />
                                </Tooltip>
                            ) : (
                                ""
                            )}
                        </div>
                    );
                },
                width: isMobile ? 90 : 120,
                style: { textAlign: "center", ...columnStyles },
                headerStyle: { textAlign: "center", ...columnStyles },
                className: "-cursor-pointer",
            },
            {
                disableSortBy: true,
                disableFilters: true,
                accessor: info => {
                    return info.isRemoved ? true : false;
                },
                id: "actions",
                Cell: ({ row: data }) => {
                    const { setUpdateData } = this.props;
                    let isRemoved = this.props.employerBillingWeek[data.index].isRemoved ? true : false;

                    return canEdit ? (
                        isRemoved ? (
                            <AddCircle
                                color="action"
                                onClick={() => {
                                    setUpdateData({ ...this.props.employerBillingWeek[data.index], isRemoved: null });
                                    setTimeout(
                                        () =>
                                            this.setState({
                                                data: this.props.employerBillingWeek,
                                                totalBillable: this.props.employerBillingWeek
                                                    .filter(shift => shift.isRemoved !== true)
                                                    .sum("total"),
                                            }),
                                        1000
                                    );
                                }}
                            />
                        ) : (
                            <RemoveCircle
                                color="error"
                                onClick={() => {
                                    setUpdateData({ ...this.props.employerBillingWeek[data.index], isRemoved: true });
                                    // setTimeout(() => {
                                    //   this.setState({
                                    //     data: this.props.employerBillingWeek,
                                    //     totalBillable: this.props.employerBillingWeek
                                    //       .filter(shift => shift.isRemoved !== true)
                                    //       .sum("total"),
                                    //   });
                                    // }, 1000);
                                }}
                            />
                        )
                    ) : (
                        <ShiftMenu
                            position={data.original}
                            workerId={data.original?.payroll[0]?.position?.workerId}
                            jobId={data.original?.payroll[0]?.position?.jobId}
                            onClick={() =>
                                this.setState({
                                    openPayrollDialog: !this.props.openPayrollDialog,
                                    payrollData: data.original.payroll,
                                })
                            }
                        />
                    );
                },
                Header: () => (
                    <React.Fragment>
                        {!canEdit ? (
                            <Link
                                href="#"
                                onClick={() => this.setState({ canEdit: !canEdit })}
                                size="small"
                                color="secondary"
                                style={{ textAlign: "right", justify: "right", alignItems: "flex-end" }}>
                                edit
                            </Link>
                        ) : (
                            ""
                        )}
                    </React.Fragment>
                ),
                collapse: true,
                styles: { textAlign: "right", alignItems: "flex-end", ...columnStyles },
                headerStyle: {
                    textAlign: "right",
                    ...columnStyles,
                    alignItems: "flex-end",
                    color: "rgb(11 147 139)",
                    textTransform: "capitalize",
                },
            },
        ];
    };

    renderTable = data => {
        const { employerBillingWeek } = this.props;
        const { canEdit } = this.state;
        const oldTable =
            data && data.length ? (
                <ReactTableSimple
                    columns={this.renderDefaultColumns()}
                    hasFilter={canEdit}
                    {...tableProps}
                    data={employerBillingWeek}
                />
                // <ReactTable
                //     data={employerBillingWeek}
                //     columns={this.renderDefaultColumns()}
                //     getHeaderProps={(state, rowInfo) => getTrProps(rowInfo, employerBillingWeek)}
                //     {...tableProps}
                //     disableFilters={canEdit}
                // />
            ) : (
                " "
            );
        return oldTable;
    };

    handleCreateInvoice = async () => {
        this.setState({ canEdit: !this.state.canEdit, open: !this.state.open });
        await this.props.createEmployerInvoice();
        this.getTableData();
    };

    renderPayrollDialog = () => {
        return (
            <PayrollDialog
                open={this.state.openPayrollDialog}
                title={`Generate New Timesheet?`}
                confirmText="Save & Generate Timesheet"
                onClose={this.handleClose}
                onConfirm={this.handleCreateInvoice}
                onCancel={this.handleClose}
                maxWidth="lg"
                alertType="invoice"
                fullWidth={false}
                table={<InvoiceModalTable payroll={this.state.payrollData} />}
                disableBackdropClick
            />
        );
    };

    renderDialog = employerBillingWeek => {
        const details = (
            <Grid container item xs={12}>
                <Typography variant="body1" align="center">
                    By saving these changes, you will be generating a new Timesheet in the amount of
                    <strong>
                        {" "}
                        {formatMoney(
                            employerBillingWeek &&
                            employerBillingWeek.filter(shift => shift.isRemoved !== true).sum("total")
                        )}{" "}
                        plus taxes{" "}
                    </strong>
                    for {this.state.employer}
                </Typography>
            </Grid>
        );

        return (
            <Dialog
                open={this.state.open}
                title={`Generate New Timesheet?`}
                confirmText="Save & Generate Timesheet"
                onClose={this.handleClose}
                onConfirm={this.handleCreateInvoice}
                onCancel={this.handleClose}
                alertType="save"
                icon="attach_file"
                size="sm">
                {details}
            </Dialog>
        );
    };

    handleCloseDeletedDialog = () => {
        this.setState({ openDeletedDialog: false })
    }

    handleOpenDeletedDialog = () => {
        this.setState({ openDeletedDialog: true })
    }

    render = () => {
        const {
            classes,
            generatedInvoices,
            shifts,
            employerWeekInvoices,
            employer,
            billingWeek,
            billingYear,
            employerData,
            employerBillingWeek,
            employerSummary,
            employerWeekSummary,
            isLoading,
            deletedInvoices
        } = this.props;
        const { data, activeInvoice, showShiftView } = this.state;
        return (
            <MainContent noTopPadding>
                <TopBarCard
                    generatedInvoices={generatedInvoices}
                    week={billingWeek}
                    billingYear={billingYear}
                    shifts={shifts}
                    employer={employer}
                    isLoading={isLoading}
                    getTableData={this.getTableData}
                />
                {this.renderDialog(employerBillingWeek)} {this.renderPayrollDialog()}
                <React.Fragment>
                    <EmployerStatsCard
                        employer={employer}
                        employerData={employerData}
                        employerWeekInvoices={employerWeekInvoices}
                        billingWeek={billingWeek}
                        employerBillingWeekData={data}
                        employerSummary={employerSummary}
                        employerWeekSummary={employerWeekSummary}
                        stats={this.getStats()}
                    />
                    {/**/}
                    <InvoiceCard
                        getTableData={this.getTableData}
                        employerData={employerData}
                        employer={employer}
                        week={billingWeek}
                        invoices={employerWeekInvoices}
                        deletedInvoices={deletedInvoices}
                        handleOpenDeletedDialog={this.handleOpenDeletedDialog}
                    />

                    <Grid container item xs={12} className={classes.tabbedTableContainer}>
                        <Grid className={classes.tableContainer}>
                            {this.props.employerBillingWeek && this.props.employerBillingWeek.length ? (
                                <Grid>
                                    {activeInvoice ? (
                                        <InvoiceItemTable {...this.props} activeInvoice={activeInvoice} />
                                    ) : (
                                        ""
                                    )}
                                    {data && showShiftView && this.renderHeader()}
                                    {data && showShiftView && this.renderTable(data)}
                                </Grid>
                            ) : (
                                <div className="spinner">
                                    <div className="bounce1" />
                                    <div className="bounce2" />
                                    <div className="bounce3" />
                                </div>
                            )}
                        </Grid>
                    </Grid>
                </React.Fragment>
                <Dialog
                    open={this.state.openDeletedDialog}
                    title={`Deleted Timesheet`}
                    onClose={this.handleCloseDeletedDialog}
                    onConfirm={this.handleCloseDeletedDialog}
                    onCancel={this.handleCloseDeletedDialog}
                    alertType="form"
                    size="lg"
                    hideActions
                    modalType="editEmployer"
                    isCancelIconButtonRequired
                >
                    {deletedInvoices && deletedInvoices.length > 0 && (
                        <DeletedInvoiceCard
                            employerData={employerData}
                            employer={employer}
                            week={billingWeek}
                            invoices={employerWeekInvoices}
                            deletedInvoices={deletedInvoices}
                        />
                    )}
                </Dialog>
            </MainContent>
        );
    };
}

const mapState = (state, ownProps) => {
    const {
        match: {
            params: { employer: emp },
        },
    } = ownProps;

    const employerBillingWeek = FinanceSelectors.getEmployerBillingWeek(state);
    const employerWeekInvoices = FinanceSelectors.getEmployerWeekInvoices(state);
    const deletedInvoices = FinanceSelectors.getDeletedInvoices(state);
    const employerWeekSummary = FinanceSelectors.getEmployerWeekSummary(state);
    const employer = FinanceSelectors.BillingWeekEmployerSummary.employerDetails(state);
    const employerSummary = FinanceSelectors.BillingWeekEmployerSummary.employerSummary(state);
    const activeInvoice = state.finance.activeInvoice || {};

    let sum = 0;

    const employerData = state.employers.details && emp && state.employers.details[emp];
    const previousBillingWeekSummary = state.finance.previousBillingWeekSummary || [];
    const generatedInvoices = state.finance.generatedInvoices;

    var jobStats = {};
    for (let { jobId } of employerBillingWeek)
        jobStats[jobId] = { jobId, count: jobStats[jobId] ? jobStats[jobId].count + 1 : 1 };
    return {
        employer,
        activeInvoice,
        sum,
        previousBillingWeekSummary,
        employerData,

        employerBillingWeek,
        employerWeekInvoices,
        employerSummary,
        generatedInvoices,
        isLoading: state.finance.isLoading ? true : false,
        employerWeekSummary,
        deletedInvoices
        // stats,
    };
};

const mapActions = () => {
    const {
        fetchEmployerInvoice,
        fetchEmployerInvoiceItems,
        fetchEmployerInvoices,
        fetchEmployerInvoicesByWeek,
        checkWeekInvoices,
        fetchDeletedInvoices
    } = BillingActions;
    return {
        uploadFile: BillingActions.uploadFile,
        fetchEmployerBillingWeekSummary: BillingActions.fetchEmployerBillingWeekSummary,
        fetchEmployerInvoice,
        fetchEmployerInvoiceItems,
        fetchEmployerInvoices,
        fetchEmployerInvoicesByWeek,
        popErrorAlert,
        checkWeekInvoices,
        setUpdateData: BillingActions.setUpdateEmployerBillingWeekData,
        fetchEmployerBillingWeekShifts: BillingActions.fetchEmployerBillingWeekShifts,
        createEmployerInvoice: BillingActions.createEmployerInvoice,
        fetchDeletedInvoices
    };
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    return {
        billingWeek: Number(ownProps.match.params.week),
        billingYear: Number(ownProps.match.params.year),
        employerId: ownProps.match.params.employer,
        // employerData: () => stateProps.employers && stateProps.employers.details[ownProps.match.params.employer],
        // invoices: [...stateProps.employerWeekInvoices],
        ...stateProps,
        ...dispatchProps,
        ...ownProps,
    };
};

export default connect(
    mapState,
    mapActions(),
    mergeProps
)(employerBillingDetailsViewStyles(EmployerBillingDetailsView));
