import React from "react";
import { withRouter } from "react-router-dom";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import withStyles from '@mui/styles/withStyles';
import Tooltip from "@mui/material/Tooltip";
import AddCircle from "@mui/icons-material/AddCircle";
import CheckCircle from "@mui/icons-material/CheckCircle";
import Error from "@mui/icons-material/Error";
import EventBusy from "@mui/icons-material/EventBusy";
import Report from "@mui/icons-material/Report";
import _debounce from "lodash/debounce";
import _throttle from "lodash/throttle";
import { formatInitials, formatPhone } from "utils/stringUtils";
import ReactTableCustom from "components/ReactTable";
import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { MenuItem, Select } from "@mui/material";
import Can from "components/Authorization/Can";
import { PositionPermissions } from "helpers/constants/permissions";

function AllWorkersTable(props) {
    const {
        classes,
        currentPage,
        totalCount,
        pageSize,
        data,
        pageSort,
        isLoading,
        onPageChange,
        onSortedChange,
        onFilteredChange,
        onAddWorker,
        positions,
        jobId,
        scrollToEnd,
        banned,
    } = props;
    const [disableIndex, setDisableIndex] = React.useState([]);
    const dispatch = useDispatch();
    const fetchData = useCallback(({ pageSize, pageIndex, filters, sortBy }) => {
        setDisableIndex([]);
        dispatch(() => onFilteredChange(filters));
        dispatch(() => onSortedChange(sortBy));
        dispatch(() => onPageChange(pageIndex));
    }, []);

    return (
        <ReactTableCustom
            data={data}
            fetchData={fetchData}
            loading={isLoading}
            pageCount={totalCount}
            currentPage={currentPage}
            defaultPageSize={pageSize}
            defaultSort={pageSort}
            hasFilter={true}
            rowsPerPageOptions={{ rowsPerPageOptions: [5] }}
            columns={[
                {
                    Header: "",
                    id: "profileImage",
                    // eslint-disable-next-line react/display-name, react/prop-types
                    accessor: ({ signedProfileImageUrl, firstName, lastName, vaccinationStatus }) => {
                        return <Avatar src={signedProfileImageUrl} style={vaccinationStatus === 'Approved' ? { border: '3px solid #3e938b' } : {}}>{formatInitials(firstName, lastName, "")}</Avatar>;
                    },

                    disableSortBy: true,
                    disableFilters: true,
                    width: 100,
                },
                {
                    Header: "First Name",
                    id: "firstName",
                    // eslint-disable-next-line react/display-name, react/prop-types
                    accessor: ({ firstName }) => {
                        if (!firstName) {
                            return <i>Missing first name</i>;
                        }

                        return firstName;
                    },
                },
                {
                    Header: "Last Name",
                    id: "lastName",
                    // eslint-disable-next-line react/display-name, react/prop-types
                    accessor: ({ lastName }) => {
                        if (!lastName) {
                            return <i>Missing last name</i>;
                        }

                        return lastName;
                    },
                },
                {
                    Header: "Email",
                    id: "email",
                    // eslint-disable-next-line react/display-name, react/prop-types
                    accessor: ({ email }) => {
                        if (!email) {
                            return <i>Missing email.</i>;
                        }

                        return email;
                    },
                },
                {
                    Header: "Phone",
                    id: "phone",
                    // eslint-disable-next-line react/display-name, react/prop-types
                    accessor: ({ phone }) => {
                        if (!phone) {
                            return <i>Empty.</i>;
                        }

                        return formatPhone(phone);
                    },
                },
                {
                    Header: "Location",
                    id: "city",
                    // eslint-disable-next-line react/display-name, react/prop-types
                    accessor: ({ city, region }) => {
                        if (!city || !region) {
                            return <i>Missing location</i>;
                        }

                        return `${city}, ${region}`;
                    },
                },
                {
                    // eslint-disable-next-line react/display-name, react/prop-types
                    Header: "Rating",
                    id: "rating",
                    // eslint-disable-next-line react/display-name, react/prop-types
                    accessor: ({ rating }) => {
                        if (!rating) {
                            return <i>Not rated.</i>;
                        }

                        return rating;
                    },
                    // eslint-disable-next-line react/display-name, react/prop-types
                    Filter: ({ setFilter, column }) => (
                        <div
                            style={{
                                marginTop: "-3px",
                            }}>
                            <Select
                                disableUnderline={false}
                                variant="standard"
                                fullWidth
                                onChange={event => setFilter(column.id, event.target.value.trim())}
                                value={column.filterValue ? column.filterValue || " " : " "}>
                                <MenuItem value=" ">All</MenuItem>
                                <MenuItem value="5">5</MenuItem>
                                <MenuItem value="4">4 +</MenuItem>
                                <MenuItem value="3">3 +</MenuItem>
                                <MenuItem value="2">2 +</MenuItem>
                            </Select>
                        </div>
                    ),

                    width: 100,
                    style: { textAlign: "center" },
                    headerStyle: { textAlign: "center" },
                },
                // {
                //     // eslint-disable-next-line react/display-name, react/prop-types
                //     Header: "Vaccination Status",
                //     id: "vaccinationStatus",
                //     accessor: ({ vaccinationStatus }) => {
                //         if (vaccinationStatus === 'NotVerified') {
                //             return 'Not Verified'
                //         }
                //         if (vaccinationStatus === 'PendingApproval') {
                //             return 'Pending Approval'
                //         }
                //         return vaccinationStatus
                //     },
                //     Filter: ({ setFilter, column }) => (
                //         <div
                //             style={{
                //                 marginTop: "-3px",
                //             }}>
                //             <Select
                //                 disableUnderline={false}
                //                 variant="standard"
                //                 fullWidth
                //                 onChange={event => setFilter(column.id, event.target.value.trim())}
                //                 value={column.filterValue ? column.filterValue || " " : " "}
                //                 >
                //                 <MenuItem value=" ">All</MenuItem>
                //                 <MenuItem value="NotVerified">Not Verified</MenuItem>
                //                 <MenuItem value="PendingApproval">Pending Approval</MenuItem>
                //                 <MenuItem value="Approved">Approved</MenuItem>
                //                 <MenuItem value="Decline">Decline</MenuItem>
                //             </Select>
                //         </div>
                //     ),
                //     width: 80,
                //     style: { textAlign: "center" },
                //     headerStyle: { textAlign: "center" },
                // },
                {
                    // eslint-disable-next-line react/display-name, react/prop-types
                    Header: () => (
                        <Tooltip title="Site History" placement="top">
                            <span>SH.</span>
                        </Tooltip>
                    ),
                    id: "positionCountByEmployer",
                    // eslint-disable-next-line react/display-name, react/prop-types
                    accessor: ({ positionCountByEmployer }) => {
                        if (!positionCountByEmployer && positionCountByEmployer !== 0) {
                            return "-";
                        }

                        return positionCountByEmployer;
                    },

                    width: 70,
                    disableFilters: true,
                    style: { textAlign: "center" },
                    headerStyle: { textAlign: "center" },
                },
                {
                    // eslint-disable-next-line react/display-name, react/prop-types
                    Header: () => (
                        <Tooltip title="Jobs Worked" placement="top">
                            <span>JW.</span>
                        </Tooltip>
                    ),
                    id: "positionCount",
                    // eslint-disable-next-line react/display-name, react/prop-types
                    accessor: ({ positionCount }) => {
                        if (!positionCount && positionCount !== 0) {
                            return "-";
                        }

                        return positionCount;
                    },
                    //
                    width: 70,
                    disableFilters: true,
                    style: { textAlign: "center" },
                    headerStyle: { textAlign: "center" },
                },
                {
                    // eslint-disable-next-line react/display-name, react/prop-types
                    Header: () => (
                        <Tooltip title="Bans" placement="top">
                            <span>B.</span>
                        </Tooltip>
                    ),
                    id: "banCount",
                    // eslint-disable-next-line react/display-name, react/prop-types
                    accessor: ({ banCount }) => {
                        if (!banCount && banCount !== 0) {
                            return "-";
                        }

                        return banCount;
                    },

                    width: 70,
                    disableFilters: true,
                    style: { textAlign: "center" },
                    headerStyle: { textAlign: "center" },
                },
                {
                    // eslint-disable-next-line react/display-name, react/prop-types
                    Header: () => (
                        <Tooltip title="No Shows" placement="top">
                            <span>NS.</span>
                        </Tooltip>
                    ),
                    id: "noShowCount",
                    // eslint-disable-next-line react/display-name, react/prop-types
                    accessor: ({ noShowCount }) => {
                        if (!noShowCount && noShowCount !== 0) {
                            return "-";
                        }

                        return noShowCount;
                    },

                    width: 70,
                    disableFilters: true,
                    style: { textAlign: "center" },
                    headerStyle: { textAlign: "center" },
                },
                {
                    Header: "",
                    id: "actions",
                    collapse: true,
                    style: { textAlign: "right" },
                    disableSortBy: true,
                    disableFilters: true,
                    // eslint-disable-next-line react/display-name, react/prop-types
                    Cell: ({ row: { original, index } }) => {
                        if (banned.includes(original.id)) {
                            return (
                                <Tooltip title="Banned" placement="top">
                                    <diV className={classes.actionsButton}>
                                        <Report className={classes.warning} />
                                    </diV>
                                </Tooltip>
                            );
                        }

                        // if (positions.some(({ workerId }) => workerId === original.id)) {
                        //   return (
                        //     <Tooltip title="On this job" placement="top">
                        //       <CheckCircle className={classes.check} />
                        //     </Tooltip>
                        //   );
                        // }

                        // if (!original.isAvailableForJob) {
                        //   return (
                        //     <Tooltip title="Already on a job" placement="top">
                        //       <Error className={classes.warning} />
                        //     </Tooltip>
                        //   );
                        // }

                        if (original.isSuspended) {
                            return (
                                <Tooltip title="Suspended" placement="top">
                                    <diV className={classes.actionsButton}>
                                        <EventBusy className={classes.warning} />
                                    </diV>
                                </Tooltip>
                            );
                        }

                        return (
                            <Tooltip title="Add to this job" placement="top">
                                <IconButton
                                    disabled={disableIndex[index] === true}
                                    disableRipple
                                    className={classes.add}
                                    onClick={() => {
                                        const temp = [...disableIndex]
                                        temp[index] = true;
                                        setDisableIndex(temp);
                                        onAddWorker(original.id, jobId).then(scrollToEnd)
                                    }}
                                    size="large">
                                    <AddCircle />
                                </IconButton>
                            </Tooltip>
                        );
                    },
                },
            ]}
            onRowClick={(row, { column }) => {
                if (row) {
                    if (column.id !== "actions") {
                        if (
                            !row.original.isSuspended &&
                            row.original.isAvailableForJob &&
                            !banned.includes(row.original.id) &&
                            positions.some(({ workerId }) => workerId !== row.original.id)
                        ) {
                            onAddWorker(row.original.id, jobId).then(scrollToEnd);
                        }
                    }
                };
            }}
        />
    );
};

export default withStyles(({ spacing, palette, customPalette }) => ({
    placeholderImage: {
        width: "40px",
        height: "40px",
        borderRadius: "50%",
        backgroundColor: palette.grey[200],
    },
    profileImage: {
        width: "40px",
        height: "40px",
        borderRadius: "50%",
    },
    check: {
        color: customPalette.green[500],
    },
    warning: {
        color: palette.grey[500],
    },
    add: {
        color: palette.secondary.main,
    },
    actionsButton: {
        padding: spacing(1.5)
    }
}))(withRouter(AllWorkersTable));
