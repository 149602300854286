import ApolloClient from "utils/apollo";
import types from "./types";
import { actions as MetaActions } from "store/modules/meta";
import { workTypesQuery } from "./graphql/queries/index";
import createWorkTypeMutation from "./graphql/mutations/create-work-type";
import updateWorkTypeMutation from "./graphql/mutations/update-work-type";
import deleteWorkTypeMutation from "./graphql/mutations/delete-work-type";

const setLoadingState = key => value => ({
    type: types.SET_LOADING_STATE,
    payload: { key, value },
});

const setIsTableLoading = setLoadingState("tableLoading");

const setDialogState = key => value => ({
    type: types.SET_DIALOG_STATE,
    payload: { key, value },
});

export const setIsAddWorkTypeDialog = setDialogState("addWorkTypeDialog");
export const setIsDeleteWorkTypeDialog = setDialogState("deleteWorkTypeDialog");
export const setIsEditWorkTypeDialog = setDialogState("editWorkTypeDialog");

const setWorkTypes = workTypes => ({
    type: types.SET_WORK_TYPES_DATA,
    payload: { workTypes }
});

export const fetchWorkTypes = () => (dispatch, getState) => {
    dispatch(setIsTableLoading(true));
    return ApolloClient.query({
        query: workTypesQuery,
    })
        .then(({ data, errors }) => {
            if (!data && errors.length > 0) {
                throw new Error(errors[0].message);
            }
            else {
                dispatch(setIsTableLoading(false));
                dispatch(setWorkTypes(data.workTypes));
            }
        })
        .catch(e => {
            dispatch(setIsTableLoading(false));
            dispatch(MetaActions.errorToast(`Could not find data: ${e.message}`));
        });
};

export const createWorkType = (values) => (dispatch, getState) => {
    const payload = {
        label: values,
        order: 2,
        isActive: true,
        baseRate: 15,
        baseBillingRate: 22.95,
    };
    return ApolloClient.mutate({
        mutation: createWorkTypeMutation,
        variables: { data: payload },
    })
        .then(({ data, errors }) => {
            if (!data && errors.length > 0) {
                throw new Error(errors[0].message);
            }
            dispatch(fetchWorkTypes());
            dispatch(setIsAddWorkTypeDialog(false));
            return data;
        }).catch((e) => {
            dispatch(MetaActions.errorToast(e.message));
            dispatch(setIsAddWorkTypeDialog(false))
        });
};

export const updateWorkType = (workType) => (dispatch, getState) => {
    return ApolloClient.mutate({
        mutation: updateWorkTypeMutation,
        variables: { data: workType },
    })
        .then(({ data, errors }) => {
            if (!data && errors.length > 0) {
                throw new Error(errors[0].message);
            }
            dispatch(fetchWorkTypes());
            dispatch(setIsEditWorkTypeDialog(false));
            return data;
        }).catch((e) => {
            dispatch(MetaActions.errorToast(e.message));
            dispatch(setIsEditWorkTypeDialog(false))
        });
};


export const deleteWorkType = (WorkTypeId) => (dispatch) => {
    const id = parseInt(WorkTypeId)
    return ApolloClient.mutate({
        mutation: deleteWorkTypeMutation,
        variables: { id }
    })
        .then(({ data, errors }) => {
            if (!data && errors.length > 0) {
                throw new Error(errors[0].message);
            } else {
                dispatch(setIsDeleteWorkTypeDialog(false))
                dispatch(fetchWorkTypes());
                dispatch(MetaActions.successToast("Work type has been deleted."));
            }
        })
        .catch(e => {
            dispatch(setIsDeleteWorkTypeDialog(false))
            dispatch(
                MetaActions.errorToast(`${e.message}`),
            );
        });
};
