import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import withStyles from '@mui/styles/withStyles';
import Avatar from "@mui/material/Avatar";
import Chip from "@mui/material/Chip";
import Grid from "@mui/material/Grid";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import EventBusy from "@mui/icons-material/EventBusy";
import WorkOff from "@mui/icons-material/WorkOff";
import { formatInitials, formatPhone } from "utils/stringUtils";
import { convertUTCToLocal } from "utils/time";
import NotVerifiedIcon from "assets/img/not_verified.svg";
import VerifiedIcon from "assets/img/verified.svg";


function WorkerProfileCard(props) {
    const {
        classes,
        fullName,
        signedProfileImageUrl,
        vaccinationStatus,
        firstName,
        lastName,
        sin,
        phone,
        email,
        street,
        city,
        region,
        country,
        postalCode,
        isSuspended,
        isActive,
        isApproved,
        suspensionStartUtc,
        suspensionEndUtc,
        approvedAtUtc,
        createdAtUtc,
        tags,
        handleWorkerTagClick,
        handleRemoveWorkerTags
    } = props;

    const profileCardStyles = classNames(classes.card, {
        [classes.suspendedCard]: isSuspended,
        [classes.deactivatedCard]: !isActive,
    });
    const approvalIcon = isApproved ? (
        <Tooltip
            title={`Verified - ${convertUTCToLocal(approvedAtUtc, "h:mm a - DD MMM YYYY")}`}
            placement="top">
            <img alt="Verified Badge" src={VerifiedIcon} className={classes.approvalIcon} />
        </Tooltip>
    ) : (
        <Tooltip title="Needs Verification" placement="top">
            <img alt="Needs Verification Badge" src={NotVerifiedIcon} className={classes.approvalIcon} />
        </Tooltip>
    );

    let chip = null;
    if (!isActive) {
        chip = (
            <Chip
                icon={<WorkOff className={classes.deactivatedIcon} />}
                label="Deactivated"
                className={classes.deactivatedChip}
            />
        );
    } else if (isSuspended) {
        chip = (
            <Chip
                icon={<EventBusy className={classes.suspendedIcon} />}
                label={`Suspended at ${convertUTCToLocal(suspensionStartUtc).fromNow()} ${suspensionEndUtc ? 'and over in ' + convertUTCToLocal(suspensionEndUtc).fromNow(true) : ''}`}
                className={classes.suspendedChip}
            />
        );
    }

    return (
        <Grid item xs={6} className={classes.container}>
            <Grid container item xs={12} className={profileCardStyles}>
                <Grid container item xs={12}>
                    <Grid item xs={6}>
                        <Avatar className={classes.avatar} src={signedProfileImageUrl} style={vaccinationStatus === 'Approved' ? { border: '3px solid #3e938b' } : {}}>
                            {formatInitials(firstName, lastName, "")}
                        </Avatar>
                    </Grid>
                    <Grid container item xs={6} justifyContent="flex-end">
                        {chip}
                    </Grid>
                </Grid>
                <Grid container item xs={12} alignItems="center" className={classes.name}>
                    <Typography variant="h4">{fullName}</Typography>
                    {approvalIcon}
                </Grid>
                <Grid container item xs={12} alignItems="center" className={classes.name}>
                    <Tooltip
                        title={`${convertUTCToLocal(createdAtUtc, "h:mm a - DD MMM YYYY")}`}
                        placement="top">
                        <Typography variant="body1">
                            Joined {convertUTCToLocal(createdAtUtc, "MMMM, YYYY")}
                        </Typography>
                    </Tooltip>
                </Grid>
                <Grid container item xs={12} direction="column">
                    <Typography variant="body1" className={classes.sin}>
                        {sin}
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        {formatPhone(phone)}
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        {email}
                    </Typography>
                    <Typography
                        variant="body1"
                        gutterBottom>{`${street}, ${city}, ${region}, ${country}`}</Typography>
                    <Typography variant="body1">{postalCode}</Typography>
                    <br />
                    <div className={classes.root}>
                        {tags && tags.map(n => <Chip
                            size="small"
                            key={n}
                            label={n}
                            onClick={() => handleWorkerTagClick(n)}
                            onDelete={() => handleRemoveWorkerTags(n)}
                        />)}
                    </div>
                </Grid>
            </Grid>
        </Grid>
    );
}

WorkerProfileCard.propTypes = {
    classes: PropTypes.object.isRequired,
    //   isSuspended: PropTypes.bool.isRequired,
    //   isActive: PropTypes.bool.isRequired,
    //   isApproved: PropTypes.bool.isRequired,
    fullName: PropTypes.string,
    signedProfileImageUrl: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    sin: PropTypes.string,
    phone: PropTypes.string,
    email: PropTypes.string,
    street: PropTypes.string,
    city: PropTypes.string,
    region: PropTypes.string,
    country: PropTypes.string,
    postalCode: PropTypes.string,
    cardRef: PropTypes.func,
};

WorkerProfileCard.defaultProps = {
    fullName: "",
    signedProfileImageUrl: "",
    firstName: "",
    lastName: "",
    sin: "",
    phone: "",
    email: "",
    street: "",
    city: "",
    region: "",
    country: "",
    postalCode: "",
    cardRef: () => { },
};

export default withStyles(({ palette, spacing, shape, customPalette, typography }) => ({
    container: {
        paddingRight: spacing(2),
    },
    card: {
        backgroundColor: palette.common.white,
        border: `1px solid ${palette.grey[500]}`,
        borderRadius: shape.borderRadius,
        padding: `${spacing(3)} !important`,
        height: "100%",
    },
    suspendedCard: {
        borderColor: customPalette.yellow[500],
    },
    deactivatedCard: {
        borderColor: customPalette.red[500],
    },
    deactivatedIcon: {
        ...typography.body1,
        color: customPalette.red[700],
        marginRight: -spacing(0.5),
    },
    deactivatedChip: {
        backgroundColor: customPalette.red[100],
        ...typography.body1,
        color: customPalette.red[900],
        padding: `${spacing(1)} ${spacing(0.5)}`,
    },
    suspendedChip: {
        backgroundColor: customPalette.yellow[100],
        ...typography.body1,
        color: customPalette.yellow[900],
        padding: `${spacing(1)} ${spacing(0.5)}`,
        '& .MuiChip-label': {
            lineHeight: 1,
            whiteSpace: 'normal'
        }
    },
    suspendedIcon: {
        ...typography.body1,
        color: customPalette.yellow[700],
        marginRight: -spacing(0.5),
    },
    avatar: {
        ...typography.h3,
        width: 104,
        height: 104,
    },
    name: {
        marginTop: spacing(1),
    },
    sin: {
        marginTop: spacing(0.5),
        marginBottom: spacing(12),
    },
    approvalIcon: {
        width: 21,
        height: 24,
        marginLeft: spacing(2),
    },
    root: {
        display: 'flex',
        justifyContent: 'left',
        flexWrap: 'wrap',
        '& > *': {
            margin: spacing(0.5),
        },
    },
}))(WorkerProfileCard);
