/* eslint-disable react/prop-types */
import React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import withStyles from '@mui/styles/withStyles';

const styles = ({ palette }) => ({
  progress: {
    color: palette.secondary.light,
    position: "absolute",
    left: "45%",
    top: "50%",
  },
});

function Loader({ classes, isLoading }) {
  return (
    <div
      style={{
        marginTop: 40,
        minHeight: 300,
        left: "45%",
        textAlign: "center",
      }}>
      {isLoading ? <CircularProgress classes={{ root: classes.progress }} /> : null}
    </div>
  );
}

export default withStyles(styles)(Loader);
