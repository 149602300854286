import React from "react";
import { Chip } from "@mui/material";
import Grid from "@mui/material/Grid";
import makeStyles from '@mui/styles/makeStyles';
import Typography from "@mui/material/Typography";
import { isEmpty } from "lodash";
import moment from "moment";

const useStyles = makeStyles(({ spacing, palette }) => ({
  container: {
    paddingLeft: spacing(3),
  },
  totalLine: {
    background: palette.grey[500],
    marginBottom: spacing(2),
    width: "50%",
    height: "1px",
  },
  paymentStatusContainer: {
    paddingTop: spacing(2),
  },
  tagChip: {
    marginRight: "5px",
    marginBottom: "5px",
  },
}));

function JobInfo({ detail }) {
  const classes = useStyles();
  const {
    billingRate,
    breakMins,
    dayOfWeekEnd,
    dayOfWeekStart,
    endDate,
    endTime,
    isBreakPaid,
    jobDescription,
    payRate,
    peopleNeeded,
    startDate,
    startTime,
    tags,
    typeOfWork,
  } = detail;

  const getDuration = () => {
    const start = moment(startTime, "HH:mm:ss a");
    const end = moment(endTime, "HH:mm:ss a");
    if (start > end) {
      end.add(1, "day");
    }
    const jobTotalDuration = end.diff(start, "minutes");
    return Math.round((jobTotalDuration / 60) * 100) / 100
  };

  if (isEmpty(detail)) {
    return null;
  }
  return (
    <Grid container item xs={6} className={classes.container}>
      <Grid container item xs={7} direction="column">
        <Typography variant="subtitle1" gutterBottom>
          {`${startDate} - ${endDate}`} <br />
        </Typography>
        <Typography variant="body1">{`${moment(startTime, 'h:mm:ss A').format('h:mm A')} - ${moment(endTime, 'h:mm:ss A').format('h:mm A')}`}</Typography>
        <Typography variant="body1">
          <b>Start day Of Week:</b> {dayOfWeekStart}
        </Typography>
        <Typography variant="body1">
          <b>End day Of Week:</b> {dayOfWeekEnd}
        </Typography>
        <Typography variant="body1">{getDuration()} Hours Duration</Typography>
        {isBreakPaid ? <Typography variant="body1">Paid Break ({breakMins} mins)</Typography> : null}
        {!isBreakPaid ? <Typography variant="body1">Unpaid Break ({breakMins} mins)</Typography> : null}
        <Typography variant="body1" gutterBottom>
          {typeOfWork}
        </Typography>

        <div style={{ marginTop: 16 }}>
          <Typography variant="caption" color="textSecondary" gutterBottom>
            Description
          </Typography>
          <Typography variant="body1">
            {jobDescription ? jobDescription : <i>No description</i>}
          </Typography>
        </div>
        <div style={{ marginTop: 16 }}>
          {tags &&
            tags.map(n => <Chip key={n} size="small" label={n} className={classes.tagChip} />)}
        </div>
      </Grid>
      <Grid
        container
        item
        xs={5}
        direction="column"
        alignItems="flex-end"
        justifyContent="space-between"
      >
        <Grid style={{ flex: 1 }} container item xs={5} direction="column" alignItems="flex-end">
          <Typography variant="caption" color="textSecondary">
            People Needed
          </Typography>
          <Typography variant="h6" gutterBottom>
            {`${peopleNeeded}`}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default JobInfo;
