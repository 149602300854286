import React from "react";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import withStyles from '@mui/styles/withStyles';
import StageLabel from "./StageLabel";

const styles = {
  stage: {
    padding: "24px 0px",
  },
};

function Stage({ title, description, style, classes, children, contentSize }) {
  return (
    <Grid container item spacing={1} className={classes.stage} style={style}>
      <Grid container item xs={3}>
        <StageLabel title={title} description={description} />
      </Grid>
      <Grid container item xs={contentSize}>
        {children}
      </Grid>
    </Grid>
  );
}

Stage.propTypes = {
  classes: PropTypes.object.isRequired,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  style: PropTypes.object,
  contentSize: PropTypes.number,
};

Stage.defaultProps = {
  style: null,
  contentSize: 9,
};

export default withStyles(styles)(Stage);
