import { gql } from "@apollo/client";

export const addQbCustomerByIdMutation = gql`
  mutation addQbCustomerById($qbId: String!, $employerId: String!) {
    addQbCustomerById(qbId: $qbId, employerId: $employerId)
  }
`;

export const createDcCustomerMutation = gql`
  mutation createDcCustomer($email: String!, $workerId: String!) {
    createDcCustomer(email: $email, workerId: $workerId)
  }
`;

export const createEtransferRequestForFundsMutation = gql`
  mutation createEtransferRequestForFunds(
    $reason: String!
    $dcTransferId: Int!
    $amount: Float!
    $customerNumber: String!
  ) {
    createEtransferRequestForFunds(
      reason: $reason
      dcTransferId: $dcTransferId
      amount: $amount
      customerNumber: $customerNumber
    )
  }
`;

export const createQbCustomerMutation = gql`
  mutation createQbCustomer($data: QbCustomerInputType!) {
    createQbCustomer(data: $data) {
      id
      SalesTermRef
      CurrencyRef
      PaymentMethodRef
      displayName
      companyName
      PrintOnCheckName
      FullyQualifiedName
      GivenName
      FamilyName
      contact
      Title
      PrimaryPhone
      AlternatePhone
      Mobile
      phone
      Notes
      WebAddr
      email
      LastUpdatedTime
      CreateTime
      PreferredDeliveryMethod
      SyncToken
      balance
      BalanceWithJobs
      sparse
      Active
      Job
      Taxable
      BillWithParent
      BillingAddress {
        Id
        Lat
        Long

        Line1
        Line2
        Line3
        Line4
        CountrySubDivisionCode
        PostalCode
      }
    }
  }
`;

export const updateQbCustomerMutation = gql`
  mutation updateQbCustomer($data: QbCustomerInputType!) {
    updateQbCustomer(data: $data) {
      id
      SalesTermRef
      CurrencyRef
      PaymentMethodRef
      displayName
      companyName
      PrintOnCheckName
      FullyQualifiedName
      GivenName
      FamilyName
      contact
      Title
      PrimaryPhone
      AlternatePhone
      Mobile
      phone
      Notes
      WebAddr
      email
      LastUpdatedTime
      CreateTime
      PreferredDeliveryMethod
      SyncToken
      balance
      BalanceWithJobs
      sparse
      Active
      Job
      Taxable
      BillWithParent
      BillingAddress {
        Id
        Lat
        Long

        Line1
        Line2
        Line3
        Line4
        CountrySubDivisionCode
        PostalCode
      }
    }
  }
`;

export const createEmployerInvoiceMutation = gql`
  mutation createEmployerInvoice($data: CreateInvoiceInput!) {
    createEmployerInvoice(data: $data) {
      id
      employerId
      invoiceYear
      invoiceWeek
      invoiceFileUrl
      invoiceItems {
        id
        createdAt
        worker
        jobId
        positionId
        invoiceId
        rate
        checkIn
        checkOut
        checkInUtc
        checkOutUtc
        breakDeductionMins
        totalUnits
        invoicableUnits
        isInvoicable
      }
      createdAt
    }
  }
`;

export const createQbInvoiceMutation = gql`
  mutation createQbInvoice($invoiceData: JSON!, $invoiceId: Int!) {
    createQbInvoice(invoiceData: $invoiceData, invoiceId: $invoiceId)
  }
`;

export const deleteQbInvoiceAttachmentMutation = gql`
  mutation deleteQbInvoiceAttachment($id: String!, $SyncToken: String!) {
    deleteQbInvoiceAttachment(id: $id, SyncToken: $SyncToken)
  }
`;

export const AddInvoiceNoteMutation = gql`
  mutation addInvoiceNote($qbInvoiceId: String!, $value: Boolean!, $note: String!) {
    addInvoiceNote(qbInvoiceId: $qbInvoiceId, value: $value, note: $note)
  }
`;

export const toggleAttachAttachmentToInvoiceMutation = gql`
  mutation toggleAttachAttachmentToInvoice(
    $id: String!
    $SyncToken: String!
    $qbInvoiceId: String!
    $value: Boolean!
  ) {
    toggleAttachAttachmentToInvoice(
      id: $id
      SyncToken: $SyncToken
      qbInvoiceId: $qbInvoiceId
      value: $value
    )
  }
`;

export const deleteQbInvoiceMutation = gql`
  mutation deleteQbInvoice($id: String!, $SyncToken: String!) {
    deleteQbInvoice(id: $id, SyncToken: $SyncToken)
  }
`;

export const commitInvoiceToQuickbooksMutation = gql`
  mutation commitInvoiceToQuickbooks($invoiceId: Int!, $employerId: String!) {
    commitInvoiceToQuickbooks(invoiceId: $invoiceId, employerId: $employerId) {
      id
      employerId
      invoiceYear
      invoiceWeek
      invoiceFileUrl
      invoiceItems {
        id
        createdAt
        positionId
        invoiceId
        rate
        checkIn
        checkOut
        breakDeductionMins
        totalUnits
        invoicableUnits
        isInvoicable
      }
    }
  }
`;

export const deleteInvoiceMutation = gql`
  mutation deleteInvoice($invoiceId: Int!, $employerId: String!) {
    deleteInvoice(invoiceId: $invoiceId, employerId: $employerId) {
      id
      employerId
      invoiceYear
      invoiceWeek
      invoiceFileUrl
      invoiceItems {
        id
        createdAt
        positionId
        invoiceId
        rate
        checkIn
        checkOut
        breakDeductionMins
        totalUnits
        invoicableUnits
        isInvoicable
      }
    }
  }
`;

export const deleteInvoiceItemMutation = gql`
  mutation deleteInvoiceItem($invoiceItemId: Int!, $employerId: String!) {
    deleteInvoiceItem(invoiceItemId: $invoiceItemId, employerId: $employerId) {
      id
      createdAt
      positionId
      invoiceId
      rate
      checkIn
      checkOut
      breakDeductionMins
      totalUnits
      invoicableUnits
      isInvoicable
    }
  }
`;
export const generateBillingMutation = gql`
  mutation generateBilling($week: Int!, $year: Int!) {
    generateBillingWeek(week: $week, year: $year)
  }
`;

export const clearWeekInvoicesQuery = gql`
  mutation clearWeekInvoices($week: Int!, $year: Int!) {
    clearWeekInvoices(week: $week, year: $year)
  }
`;

export const createEtransferTransactionMutation = gql`
  mutation createEtransferTransaction($amount: Int!, $customerNumber: String!) {
    createEtransferTransaction(customerNumber: $customerNumber, amount: $amount)
  }
`;

export const retryReversalMutation = gql`
  mutation retryReversal($reversalId: Int!) {
    retryReversal(reversalId: $reversalId)
  }
`;
export const MarkReversalResolvedMutation = gql`
  mutation markReversalResolved($reversalId: Int!) {
    markReversalResolved(reversalId: $reversalId)
  }
`;
export const cancelReversalMutation = gql`
  mutation cancelReversal($transactionId: Int!, $customerNumber: String!, $reason: String) {
    cancelReversal(
      customerNumber: $customerNumber
      transactionId: $transactionId
      reason: $reason
    ) {
      Item
      ErrorCode
      ErrorDescription
      IsSucceeded
    }
  }
`;
export const cancelEtransferTransactionMutation = gql`
  mutation cancelEtransferTransaction(
    $transactionId: Int!
    $customerNumber: String!
    $reason: String
  ) {
    cancelEtransferTransaction(
      customerNumber: $customerNumber
      transactionId: $transactionId
      reason: $reason
    ) {
      Item
      ErrorCode
      ErrorDescription
      IsSucceeded
    }
  }
`;

export const payPositionMutation = gql`
  mutation payPosition($positionId: Int!) {
    payPosition(positionId: $positionId) {
      id
      createdAt
      payrollTypeId
      positionId
      taxRegionId
      employmentStandardsRegionId
      rate
      checkIn
      checkOut
      breakDeductionMins
      bonus
      totalUnits
      payableUnits
      totalEmployeeDeductions
      totalEmployerDeductions
      grossWages
      grossEarnings
      netEarnings
    }
  }
`;

export const createPaystubMutation = gql`
  mutation createPaystub($positionId: Int!) {
    createPaystub(positionId: $positionId) {
      id
      createdAt
      payrollTypeId
      positionId
      taxRegionId
      employmentStandardsRegionId
      rate
      checkIn
      checkOut
      breakDeductionMins
      bonus
      totalUnits
      payableUnits
      position {
        id
        jobId
        workerId
        breakMins
        payRate
        billingRate
        bonus
        job {
          id
          employerId
          start
          end
          hourlyRate
        }
        worker {
          id
          firstName
          lastName
          email
        }
        isEditable
        isDeletable
        isPayable
      }
      totalEmployeeDeductions
      totalEmployerDeductions
      grossWages
      grossEarnings
      netEarnings
    }
  }
`;
export const downloadPaystubSummary = gql`
  mutation($positionId: Int, $workerId: String, $startDate:  DateTime, $endDate: DateTime) {
  downloadPaystubSummary(positionId: $positionId,workerId:$workerId, startDateUtc: $startDate, endDateUtc: $endDate)
}
`;
