import React, { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Typography from "@mui/material/Typography";
import { actions as ReportsActions, selectors as ReportsSelectors } from "store/modules/reports";
import { convertUTCToLocal } from "../../../../utils/time";
import { sharedReactTableProps } from "../../../../utils/constant";
import { formatMoney } from "utils/stringUtils";
import { Link } from "react-router-dom";
import ReactTableCustom from "components/ReactTable";
import classNames from "classnames";
import { useStyles } from "views/Reports/styles";

function ActiveEmployerTable() {
    const dispatch = useDispatch();
    const employerData = useSelector(ReportsSelectors.getActiveEmployerReportData)
    const PaginationData = useSelector(ReportsSelectors.getActiveEmployerReportPagingData)
    const fetchData = useCallback(({ pageIndex, pageSize }) => {
        dispatch(ReportsActions.changeActiveEmployerPageSize(pageSize))
        dispatch(ReportsActions.fetchActiveEmployerTableData(pageIndex))
    }, []);
    const classes = useStyles();
    const headerStyle={textAlign:"right"};
    const columns = [
        {
            Header: "Grizzly Employer Id",
            disableSortBy: true,
            accessor: "GrizzlyEmployerId",
            minWidth: 150,
        },
        {
            Header: "Company Name",
            disableSortBy: true,
            disableFilters: true,
            accessor: "CompanyName",
            Cell: ({ value, row: { original } }) => {
                return <Link to={`/employers/${original.GrizzlyEmployerId}`}>{value}</Link>;
            },
        },
        {
            Header: "First Name",
            disableSortBy: true,
            disableFilters: true,
            accessor: "FirstName",
        },
        {
            Header: "Last Name",
            disableSortBy: true,
            disableFilters: true,
            accessor: "LastName",
        },
        {
            Header: "Email",
            disableSortBy: true,
            disableFilters: true,
            accessor: "Email",
            minWidth: 200,
        },
        {
            Header: <div className="text-right">Pay Rate</div>,
            disableSortBy: true,
            disableFilters: true,
            accessor: "PayRate",
            headerStyle,
            className: "text-right",
            maxWidth: 100,
            Cell: ({ row: { original } }) => formatMoney(original.PayRate),
        },
        {
            Header: <div className="text-right">Billing Rate</div>,
            disableSortBy: true,
            disableFilters: true,
            accessor: "BillingRate",
            className: "text-right",
            headerStyle,
            maxWidth: 100,
            Cell: ({ row: { original } }) => formatMoney(original.BillingRate),
        },
        {
            Header: <span className="text-right">YTD Revenue</span>,
            disableSortBy: true,
            accessor: "YTDRevenue",
            className: "text-right",
            maxWidth: 100,
            headerStyle,
            Cell: ({ row: { original } }) => formatMoney(original.YTDRevenue),
        },
        {
            Header: <span className="text-right">Total Revenue</span>,
            disableSortBy: true,
            accessor: "TotalRevenue",
            className: "text-right",
            headerStyle,
            maxWidth: 100,
            Cell: ({ row: { original } }) => formatMoney(original.TotalRevenue),
        },
        {
            Header: <div className="text-right">Last Job Posted</div>,
            disableSortBy: true,
            disableFilters: true,
            accessor: "LastJobPosted",
            headerStyle,
            className: "text-right",
            width: 150,
            Cell: ({ row: { original } }) => convertUTCToLocal(original.LastJobPosted, "DD MMM YYYY")
        },
    ];
    return (
        <React.Fragment>
            <ReactTableCustom
                {...sharedReactTableProps}
                fetchData={fetchData}
                data={employerData}
                hasFilter={false}
                loading={PaginationData.paging.isLoading}
                pageCount={PaginationData.paging.totalCount}
                currentPage={PaginationData.paging.currentPage}
                defaultPageSize={PaginationData.paging.pageSize}
                columns={columns}
                classes={classNames(sharedReactTableProps.classes, classes.table)}
            />
        </React.Fragment>
    );
}

export default ActiveEmployerTable;
