import { gql } from "@apollo/client";

export default gql`
  fragment WorkerFields on Worker {
    createdAt
    approvedAt
    createdAtUtc

    approvedAtUtc
    suspensionEndUtc
    suspensionStartUtc
    id
    firstName
    lastName
    fullName
    profileImageUrl
    signedProfileImageUrl
    signedIdentificationImageUrl
    vaccinationStatus
    sin
    isSuspended
    isActive
    isApproved
    isOnboarded
    sin
    timezone
    dcCustomerNumber
    travelRange
    isEmailVerified
    isStudent
    employmentType
    phone
    email
    street
    city
    region
    country
    postalCode
    rating
    positionCount
    hoursWorkedCount
    banCount
    preferCount
    noShowCount
    grossPay
    netPay
    totalDeductions
    tags
    workTypePreferences {
      workType {
        id
        label
      }
    }
    availability {
      id
      dayOfWeek
      timeOfDay
      startTime
      endTime
    }
    temporaryAvailability {
      id
      startDt
      startDtUtc
      endDt
      endDtUtc
    }
    transportationPreferences {
      transportation {
        id
        label
      }
    }
  }
`;
