/* eslint-disable use-isnan */
import _ from "lodash";

import { validateSIN, verifyEmail } from "utils/stringUtils";

export const sanitizePayload = data => {
  const payload = {};
  const cleanVals = value => {
    return value === undefined ||
      value === null ||
      value === "" ||
      value === NaN ||
      (typeof value === "object" && Object.keys(value).length === 0)
      ? false
      : true;
  };

  const checkSin = sin => validateSIN(sin);

  _.mapKeys(data, (value, key) => {
    if (cleanVals(value) && key === "sin" && checkSin(value)) {
      console.log("sanitizePayload sin success");
      payload[key] = value;
    } else if (cleanVals(value) && key === "email" && verifyEmail(value)) {
      console.log("sanitizePayload email success");
      //   payload[key] = value;
    } else if (
      (cleanVals(value) || typeof value === "boolean") &&
      key !== "email" &&
      key !== "transType" &&
      key !== "data" &&
      key !== "sin"
    )
      payload[key] = value;
  });

  return payload;
};
