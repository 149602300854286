// ##############################
// // // Header styles
// #############################

import {
  containerFluid,
  defaultFont,
  primaryColor,
  defaultBoxShadow,
  infoColor,
  successColor,
  warningColor,
  dangerColor,
} from "assets/jss/material-dashboard-pro-react.jsx";

const headerStyle = () => ({
  appBar: {
    backgroundColor: "#FFF",
    // boxShadow: "none",
    borderBottom: "0",
    marginBottom: "0",
    position: "absolute",
    width: "100%",
    paddingTop: "8px",
    zIndex: "1029",
    color: "#555555",
    border: "0",
    boxShadow: "0 0 2px 0 rgba(0, 0, 0, 0.5)",
    borderRadius: "3px",
    padding: "8px 0",
    transition: "all 150ms ease 0s",
    minHeight: "80px",
    display: "block",
  },
  userButton: {
    textAlign: 'right',
    '&:hover': {
      background: 'transparent'
    },
    
  },
  labelName: {
    fontSize: '16px',
    color: '#000'
  },
  labelEmail: {
    fontSize: '12px'
  },
  container: {
    ...containerFluid,
    paddingRight: "32px",
    paddingLeft: "32px",
    minHeight: "50px",
  },
  flex: {
    flex: 1,
  },
  title: {
    ...defaultFont,
    lineHeight: "30px",
    fontSize: "32px",
    borderRadius: "3px",
    textTransform: "none",
    color: "inherit",
    paddingTop: "0.625rem",
    paddingBottom: "0.625rem",
    margin: "0 !important",
    fontWeight: "bold",
    "&:hover,&:focus": {
      background: "transparent",
    },
  },
  primary: {
    backgroundColor: primaryColor,
    color: "#FFFFFF",
    ...defaultBoxShadow,
  },
  info: {
    backgroundColor: infoColor,
    color: "#FFFFFF",
    ...defaultBoxShadow,
  },
  success: {
    backgroundColor: successColor,
    color: "#FFFFFF",
    ...defaultBoxShadow,
  },
  warning: {
    backgroundColor: warningColor,
    color: "#FFFFFF",
    ...defaultBoxShadow,
  },
  danger: {
    backgroundColor: dangerColor,
    color: "#FFFFFF",
    ...defaultBoxShadow,
  },
  sidebarMinimize: {
    float: "left",
    padding: "0 0 0 15px",
    display: "block",
    color: "#555555",
  },
  sidebarMinimizeRTL: {
    padding: "0 15px 0 0 !important",
  },
  sidebarMiniIcon: {
    width: "20px",
    height: "17px",
  },
});

export default headerStyle;
