import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { actions as ReportsActions, selectors as ReportsSelectors } from "store/modules/reports";
import { convertUTCToTimezoneWithOffset } from "../../../../utils/time";
import { sharedReactTableProps } from "../../../../utils/constant";
import { formatMoney } from "utils/stringUtils";
import ReactTableCustom from "components/ReactTable";
import { useStyles } from "views/Reports/styles";
import classNames from "classnames";

function WSBCTable() {
    const dispatch = useDispatch();
    const employerData = useSelector(ReportsSelectors.getWSBCReportData);
    const PaginationData = useSelector(ReportsSelectors.getWSBCReportPagingData);
    const fetchData = useCallback(({ pageIndex, pageSize }) => {
        dispatch(ReportsActions.changeWSBCPageSize(pageSize));
        dispatch(ReportsActions.fetchWSBCTableData(pageIndex));
    }, []);
    const classes=useStyles();

    const columns = [
        {
            Header: "Job Id",
            disableSortBy: true,
            disableFilters: true,
            accessor: "JobId",
        },
        {
            Header: "Position Id",
            disableSortBy: true,
            disableFilters: true,
            accessor: "PositionId",
        },
        {
            Header: "Job Start Date",
            disableSortBy: true,
            disableFilters: true,
            accessor: "JobStartDateUtc",
            Cell: ({ row: { original } }) => convertUTCToTimezoneWithOffset(original.JobStartDateUtc, '', 'DD MMM YYYY h:mm a'),
        },
        {
            Header: "Job End Date",
            disableSortBy: true,
            disableFilters: true,
            accessor: "JobEndDateUtc",
            Cell: ({ row: { original } }) => convertUTCToTimezoneWithOffset(original.JobEndDateUtc, '', "DD MMM YYYY h:mm a"),
        },
        {
            Header: "Company Name",
            disableSortBy: true,
            disableFilters: true,
            accessor: "CompanyName",
        },
        {
            Header: "Worker Name",
            disableSortBy: true,
            disableFilters: true,
            accessor: "WorkerName",
        },

        {
            Header: <div className="text-right">Gross Pay</div>,
            disableSortBy: true,
            disableFilters: true,
            className: 'text-right',
            accessor: "GrossPay",
            Cell: ({ row: { original } }) => formatMoney(original.GrossPay),
        },
    ];
    return (
        <React.Fragment>
            <ReactTableCustom
                {...sharedReactTableProps}
                fetchData={fetchData}
                data={employerData}
                hasFilter={false}
                loading={PaginationData.paging.isLoading}
                pageCount={PaginationData.paging.totalCount}
                currentPage={PaginationData.paging.currentPage}
                classes={classNames(sharedReactTableProps.classes,classes.table)}
                defaultPageSize={PaginationData.paging.pageSize}
                columns={columns}
            />
        </React.Fragment>
    );
}

export default WSBCTable;
