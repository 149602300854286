import { gql } from "@apollo/client";

export default gql`
  query getEmployer($id: ID!) {
    employer(id: $id) {
      id
      qbId
      createdAt
      updatedAt
      createdAtUtc
      #   updatedAtUtc
      email
      firstName
      lastName
      billingType
      street
      city
      region
      postalCode
      country
      companyName
      websiteUrl
      description
      businessPhone
      mobilePhone
      title
      isActive
      isApproved
      approvedAt
      approvedAtUtc
      profileImageUrl
      signedProfileImageUrl
      logoImageUrl
      signedLogoImageUrl
      payRate
      billingRate
      isMassCheckinEnabled
      isAutoConfirmEnabled
      isDailySummaryEmailEnabled
    }
  }
`;
