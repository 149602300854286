export default {
    SET_LOADING_STATE: "workersReports/SET_LOADING_STATE",

    SET_WORKER_PAYROLL_DATA: "workersReports/SET_WORKER_PAYROLL_DATA",
    SET_WORKER_PAYROLL_FILTER: "workersReports/SET_WORKER_PAYROLL_FILTER",
    SET_WORKER_PAYROLL_TOTAL_COUNT: "workersReports/SET_WORKER_PAYROLL_TOTAL_COUNT",

    SET_WORKER_WISE_PAYROLL_DATA: "workersReports/SET_WORKER_WISE_PAYROLL_DATA",
    SET_WORKER_WISE_PAYROLL_FILTER: "workersReports/SET_WORKER_WISE_PAYROLL_FILTER",
    SET_WORKER_WISE_PAYROLL_TOTAL_COUNT: "workersReports/SET_WORKER_WISE_PAYROLL_TOTAL_COUNT",

    SET_WORKER_JOB_DATA: "workersReports/SET_WORKER_JOB_DATA",
    SET_WORKER_JOB_FILTER: "workersReports/SET_WORKER_JOB_FILTER",

    SET_ACTIVE_EMPLOYER_DATA: "employerReports/SET_ACTIVE_EMPLOYER_DATA",
    SET_ACTIVE_EMPLOYER_FILTER: "workersReports/SET_ACTIVE_EMPLOYER_FILTER",
    SET_ACTIVE_EMPLOYER_TOTAL_COUNT: "workersReports/SET_ACTIVE_EMPLOYER_TOTAL_COUNT",

    SET_WSBC_DATA: "employerReports/SET_WSBC_DATA",
    SET_WSBC_FILTER: "workersReports/SET_WSBC_FILTER",
    SET_WSBC_TOTAL_COUNT: "workersReports/SET_WSBC_TOTAL_COUNT",

    SET_WORKER_JOB_COUNT_DATA: "workerJobCountReports/SET_WORKER_JOB_COUNT_DATA",
    SET_WORKER_JOB_COUNT_FILTER: "workersReports/SET_WORKER_JOB_COUNT_FILTER",
    SET_WORKER_JOB_COUNT_TOTAL_COUNT: "workerJobCountReports/SET_WORKER_JOB_COUNT_TOTAL_COUNT",

    SET_DC_BANK_TRANSACTION_DATA: "workerJobCountReports/SET_DC_BANK_TRANSACTION_DATA",
    SET_DC_BANK_TRANSACTION_FILTER: "workersReports/SET_DC_BANK_TRANSACTION_FILTER",
};
