import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import SwipeableViews from "react-swipeable-views";
import Typography from "@mui/material/Typography";
import { Add, LocationOff, LocationOn, NotListedLocation } from "@mui/icons-material";
import MainContent from "components/Containers/MainContent";
import SubNav from "components/Navigation/SubNav";
import SubNavTab from "components/Navigation/SubNavTab";
import EmployerDeactivatedTable from "./tables/EmployerDeactivatedTable";
import EmployerTable from "./tables/EmployerTable";
import EmployerUnapprovedTable from "./tables/EmployerUnapprovedTable";
import Button from "../../components/Button/Button";

class EmployerListView extends React.Component {
    state = {
        value: 0,
    };
    componentDidMount() {
        if (this.props.history.location.search) {
            const params = new URLSearchParams(this.props.history.location.search);
            const employerframe = parseInt(params.get("employerframe"), 10);
            if (!isNaN(employerframe)) {
                this.setState({ value: parseInt(employerframe, 10) });
            }
        } else {
            this.props.history.push(`/employers/overview?employerframe=${this.state.value}`);
        }
    }
    handleChange = (event, value) => {
        if (this.props.history.location.search) {
            const params = new URLSearchParams(this.props.history.location.search);
            params.append("employerframe", value);
            this.props.history.push(`/employers/overview?${params.toString()}`);
        }
        this.props.history.push(`/employers/overview?employerframe=${value}`);
        this.setState({ value });
    };
    createEmployer = () => {
        this.props.history.push("/employers/add");
    };
    customTableProps = (state, rowInfo) => {
        if (rowInfo && rowInfo.row) {
            return {
                onClick: (e, handleOriginal) => {
                    this.props.history.push("/employers/" + rowInfo.original.id);
                    if (handleOriginal) {
                        handleOriginal();
                    }
                },
            };
        } else {
            return {};
        }
    };

    render() {
        return (
            <React.Fragment>
                <SubNav
                    value={this.state.value}
                    onChange={this.handleChange}
                    tabs={[
                        <SubNavTab
                            key="active"
                            label="Active"
                            icon={<LocationOn />}
                            count={this.props.employerListPageInfo.totalCount}
                        />,
                        <SubNavTab
                            key="deactivated"
                            label="Deactivated"
                            icon={<LocationOff />}
                            count={this.props.deactivatedEmployerListPageInfo.totalCount}
                        />,
                        <SubNavTab
                            key="unapproved"
                            label="Unapproved"
                            icon={<NotListedLocation />}
                            count={this.props.unapprovedEmployerListPageInfo.totalCount}
                        />,
                    ]}
                />
                <MainContent>
                    <Typography
                        variant="caption"
                        style={{ alignSelf: "flex-start", width: "50%", marginTop: 0 }}>
                        {this.state.value === 0
                            ? "Active employers. "
                            : this.state.value === 1
                                ? "These accounts have been deactivated by a Grizzly Admin."
                                : "These accounts need approval before they can post jobs."}
                    </Typography>
                    <Button
                        style={{ alignSelf: "flex-end", marginTop: "-20px" }}
                        label="Create an Employer"
                        icon={<Add />}
                        alignIcon="left"
                        onClick={this.createEmployer}
                    />

                    <SwipeableViews axis="x" index={this.state.value}>
                        <EmployerTable tabIndex={this.state.value} />
                        <EmployerDeactivatedTable tabIndex={this.state.value} />
                        <EmployerUnapprovedTable tabIndex={this.state.value} />
                    </SwipeableViews>
                </MainContent>
            </React.Fragment>
        );
    }
}

const mapState = state => {
    const {
        deactivatedEmployerListPageInfo,
        unapprovedEmployerListPageInfo,
        employerListPageInfo,
    } = state.employers;

    return {
        deactivatedEmployerListPageInfo,
        unapprovedEmployerListPageInfo,
        employerListPageInfo,
    };
};

export default connect(mapState)(withRouter(EmployerListView));
