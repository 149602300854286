import React from "react";
import PropTypes from "prop-types";
import Dialog from "components/Dialog/Dialog";

function DequeuePayDialog(props) {
    const { open, onClose, onCancel, onConfirm } = props;

    return (
        <Dialog
            open={open}
            title="Are you sure?"
            description="This will remove any queued payments.  This action can not be undone."
            onClose={onClose}
            onConfirm={onConfirm}
            onCancel={onCancel}
            alertType="warning"
            icon="remove_circle"
        />
    );
}

DequeuePayDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    onConfirm: PropTypes.func.isRequired,
};

export default DequeuePayDialog;
