import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Grid from "@mui/material/Grid";
import Select from "@mui/material/Select";
import ButtonCustom from "components/Button/Button";
import Dialog from "components/Dialog/Dialog";
import { find } from "lodash";
import { actions as JobDetailActions, selectors as JobDetailSelectors } from "store/modules/jobDetails";
import MenuItem from "@mui/material/MenuItem";
import { workerTypeListByRating } from "../../../../../utils/constant";
import { withStyles } from '@mui/styles';
import NotificationsIcon from "@mui/icons-material/Notifications";
import Typography from "@mui/material/Typography";
import classNames from "classnames";
import { convertUTCToTimezoneWithOffset } from "../../../../../utils/time";
import Tooltip from "@mui/material/Tooltip";
import ExpandMore from "@mui/icons-material/ExpandMore";
import ExpandLess from "@mui/icons-material/ExpandLess";
import { Button, Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import ReactTableSimple from "components/ReactTable/ReactTableSimple";


const styles = ({ spacing, palette, shape }) => {
    return ({
        root: {
            backgroundColor: `${palette.common.white}`,
            // border: `1px solid ${palette.grey[500]}`,
            borderRadius: `${shape.borderRadius / 2}px`,
            padding: `${spacing(3)}`,
            // marginTop: `${spacing(2)}`,
        },
        visibility: {
            paddingTop: spacing(2),
            "@media (max-width: 600px)": {
                padding: `${spacing(1)}`,
            },
        },
        visibilityFont: {
            color: "#6e7a8a",
            paddingLeft: spacing(1),
        },
        table: {
            flex: 1,
            border: "none",
            borderRadius: `${shape.borderRadius / 2}px`,
        },
        expand: {
            width: "100%",
            boxShadow: "none",
        },
        expandSummery: {
            cursor: "default !important",
        },
        paddingZero: {
            padding: "0 !important",
        },
        button: {
            textTransform: "capitalize",
            "@media (max-width: 600px)": {
                marginTop: "10px"
            },
        },

        notification: {
            "@media (max-width: 600px)": {
                display: "flex",
                justifyContent: "space-between"
            },
        },
        notificationBtn: {
            "@media (max-width: 600px)": {
                paddingLeft: "3px"
            },
        }
    })
};
const column = [
    {
        Header: "Worker Type",
        id: "workerRating",
        accessor: "workerRating",
        disableSortBy: true,
    },
    {
        Header: "Sent Date",
        id: "sentOn",
        accessor: "sentOn",
        disableSortBy: true,
        Cell: ({ value, row: { original } }) => {
            return (
                <div>
                    {" "}
                    {convertUTCToTimezoneWithOffset(original.sentOn, "", "MMM Do, YYYY  h:mm a")}
                </div>
            );
        },
    },
    {
        Header: "Success Notification",
        id: "totalSuccess",
        accessor: "totalSuccess",
        disableSortBy: true,
    },
    {
        Header: "Failed Notification",
        id: "totalFailed",
        accessor: "totalFailed",
        disableSortBy: true,
    },
    {
        Header: "Sent From",
        id: "sentFrom",
        accessor: "sentFrom",
        disableSortBy: true,
    },
];
class SendNotification extends React.Component {
    static propTypes = {
        classes: PropTypes.object.isRequired,
        job: PropTypes.object.isRequired,
        onSendNotification: PropTypes.func.isRequired,
        isEnabling: PropTypes.bool.isRequired,
    };

    constructor(props) {
        super(props);
        this.state = {
            workerType: workerTypeListByRating[0].value,
            showSendNotificationDialog: false,
            showNotificationHistory: false,
        };
    }

    // Send notification to worker
    handleSendNotification = () => {
        this.setState({
            showSendNotificationDialog: true,
        });
    };

    onWorkerTypeChange = event => {
        this.setState({
            workerType: event.target.value,
        });
    };

    hideSendNotificationDialog = () => {
        this.setState({
            showSendNotificationDialog: false,
        });
    };

    handleConfirmSendNotification = () => {
        this.setState(
            {
                showSendNotificationDialog: false,
            },
            () => this.props.onSendNotification(this.state.workerType),
        );
    };

    handleShowNotificationHistory = () => {
        this.setState({
            showNotificationHistory: !this.state.showNotificationHistory,
        });
    };

    renderSendNotificationButton = () => {
        const { isEnabling, job, classes } = this.props;
        const enableButton = (
            <ButtonCustom
                label="Send Notification"
                isLoading={isEnabling}
                disabled={job.positionsFilled >= job.peopleNeeded || job.status === "Completed"}
                onClick={this.handleSendNotification}
                className={classes.notificationBtn}
            />
        );
        const selectDropdown = (
            <Select
                select
                label="Worker Type"
                value={this.state.workerType}
                onChange={this.onWorkerTypeChange}
            // margin="normal"
            >
                {workerTypeListByRating.map(({ label, value }) => (
                    <MenuItem key={`${value}`} value={value}>
                        {label}
                    </MenuItem>
                ))}
            </Select>
        );
        if (job.positionsFilled >= job.peopleNeeded || job.status === "Completed") {
            return (
                <Grid container className={classes.notification}>
                    {!job.isPrivate && (
                        selectDropdown
                    )}
                    &nbsp;&nbsp;
                    <Tooltip
                        title="Can't send notification to filled or completed job"
                        placement="right"
                    >
                        <div>{enableButton}</div>
                    </Tooltip>
                </Grid>
            );
        }

        return (
            <Grid container className={classes.notification}>
                {!job.isPrivate && (
                    selectDropdown
                )}
                &nbsp;&nbsp;
                {enableButton}
            </Grid>
        );
    };

    render() {
        const { classes, job } = this.props;
        const { showNotificationHistory } = this.state;
        const message = `Are you sure to send notification to ${find(workerTypeListByRating, { value: this.state.workerType }).label
            } worker?`
        const privateMessage = `Are you sure to send notification to worker who has "${job.tags}" tag?`
        return (
            <>
                {/* <Grid container alignItems="center" className={classes.visibility}>
                    <NotificationsIcon color="action" />
                    <Typography variant="body1" className={classes.visibilityFont}>
                        Notification
                    </Typography>
                </Grid> */}
                <Grid container className={classes.root}>
                    <Accordion expanded={showNotificationHistory} className={classes.expand}>
                        <AccordionSummary
                            className={classNames(classes.expandSummery, classes.paddingZero)}
                        >
                            <Grid container className={classes.paddingZero}>
                                <Grid item xs={6}>
                                    {this.renderSendNotificationButton()}
                                </Grid>
                                <Grid item xs={6} style={{ textAlign: "right" }}>
                                    <Button
                                        className={classes.button}
                                        onClick={this.handleShowNotificationHistory}
                                        disabled={
                                            !job.notificationLog || job.notificationLog.length === 0
                                        }
                                    >
                                        {showNotificationHistory ? "Collapse" : "Expand"}
                                        {showNotificationHistory ? <ExpandLess /> : <ExpandMore />}
                                    </Button>
                                </Grid>
                            </Grid>
                        </AccordionSummary>
                        <AccordionDetails className={classes.paddingZero}>
                            {job.notificationLog &&
                                job.notificationLog.length > 0 && (
                                    <Grid container className={classes.visibility}>
                                        <Grid item xs={12}>
                                            <ReactTableSimple
                                            style={{boxShadow:'none',border:"none"}}
                                                defaultPageSize={5}
                                                classes={classNames({
                                                    [classes.table]: true,
                                                })}
                                                data={job.notificationLog}
                                                columns={column}
                                                getTdProps={this.getTdProps}
                                            />
                                        </Grid>
                                    </Grid>
                                )}
                        </AccordionDetails>
                    </Accordion>
                    <Dialog
                        open={this.state.showSendNotificationDialog}
                        title={job.isPrivate ? privateMessage : message}
                        description=""
                        confirmText="Yes"
                        onClose={this.hideSendNotificationDialog}
                        onConfirm={this.handleConfirmSendNotification}
                        onCancel={this.hideSendNotificationDialog}
                        alertType="warning"
                    />
                </Grid>
                <Dialog
                    open={this.state.showSendNotificationDialog}
                    title={job.isPrivate ? privateMessage : message}
                    description=""
                    confirmText="Yes"
                    onClose={this.hideSendNotificationDialog}
                    onConfirm={this.handleConfirmSendNotification}
                    onCancel={this.hideSendNotificationDialog}
                    alertType="warning"
                />
            </>
        );
    }
}

const mapState = state => {
    return {
        job: state.jobDetails,
        isEnabling: JobDetailSelectors.getIsApplyingAction(state),
    };
};

const mapActions = {
    onSendNotification: JobDetailActions.sendNotification,
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const { onSendNotification } = dispatchProps;
    return {
        ...stateProps,
        onSendNotification,
        ...ownProps,
    };
};
export default connect(
    mapState,
    mapActions,
    mergeProps,
)(withRouter(withStyles(styles)(SendNotification)));
