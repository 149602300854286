import ReactTableSimple from "components/ReactTable/ReactTableSimple";
import moment from "moment";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { actions as JobActions, selectors as JobSelectors } from "store/modules/jobs";

const CSVErrorTable = ({ data }) => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(JobActions.fetchImportedPrivateJob());
    }, []);

    const columns = [
        {
            Header: "Employer Id",
            id: "employerId",
            accessor: ({ data }) => data.GrizzlyEmployerId,
            className: "-cursor-pointer",
        },
        {
            Header: "Type Of Work",
            id: "typeOfWork",
            accessor: ({ data }) => data.TypeOfWork,
            className: "-cursor-pointer",
        },

        {
            Header: "Address",
            id: "address",
            accessor: ({ data }) => data.Address,
            className: "-cursor-pointer",
        },
        {
            Header: "Start Date",
            id: "StartDate",
            accessor: ({ data }) => moment(data.StartDate).format("MMM Do, YYYY"),
            className: "-cursor-pointer",
        },
        {
            Header: "Error",
            id: "error",
            accessor: ({ error }) => error,
            className: "-cursor-pointer",
            Cell: ({ row: { original } }) => {
                return <div style={{ color: 'red' }}>{original.error}</div>
            }
        },
    ];
    return (
        <React.Fragment>
            <div style={{ textAlign: 'left' }}>
                <h3>We found below errors in uploaded CSV</h3>
                <ReactTableSimple
                    classes="-highlight"
                    style={{ marginTop: "16px" }}
                    data={data}
                    columns={columns}
                />
            </div>
        </React.Fragment>
    );
};

export default CSVErrorTable;
