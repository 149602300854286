/* eslint-disable react/prop-types */
/* eslint-disable react/display-name */
import React from "react";
import { useSelector } from "react-redux";
import Line from "components/Placeholders/Line";
import { formatMoney } from "utils/stringUtils";
import { selectors as JobDetailSelectors } from "store/modules/jobDetails";
import DisableCellOverlay from "../../components/Controls/DisableCellOverlay";
import Can from "components/Authorization/Can";
import { PositionPermissions } from "helpers/constants/permissions";

export default function useBonus(disabled) {
    const canAddWorker = useSelector(JobDetailSelectors.getCanAddWorker);

    return {
        Header: "Bonus",
        id: "bonus",
        width: 60,
        sortable: false,
        // show: showBonusColumn,
        Cell: ({ row: { original: { bonus } } }) => {
            return (
                <>
                    {formatMoney(bonus)}
                    <DisableCellOverlay visible={disabled} />
                </>
            );
        },
        Footer: (<Can perform={PositionPermissions.create.fill.value}>
            {allowed => {
                if (!allowed) {
                    return null;
                }
                return canAddWorker && !disabled ? <Line /> : null
            }}
        </Can>)
    };
}
