import React from "react";
import PropTypes from "prop-types";
import Dialog from "components/Dialog/Dialog";

function ResendStubDialog(props) {
  const { open, onClose, onCancel, onConfirm } = props;

  return (
    <>
      <Dialog
        open={open}
        title="Resend Paystub"
        confirmText="Send Paystub"
        description={`Gererate and resend paystub.`}
        onClose={onClose}
        onConfirm={onConfirm}
        onCancel={onCancel}
        alertType="warning"
        icon="mail_outline"
      />
    </>
  );
}

ResendStubDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  position: PropTypes.object,
};

export default ResendStubDialog;
