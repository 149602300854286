import { gql } from "@apollo/client";

export default gql`
  mutation updateTermsAndConditions($termsAndConditions: String!) {
    updateTermsAndConditions(termsAndConditions: $termsAndConditions) {
      id
      termsAndConditions
    }
  }
`;
