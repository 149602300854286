import { gql } from "@apollo/client";

export default gql`
  query getMe($authId: String!) {
    me(authId: $authId) {
      accountId
      accountType
      admin{
        id
        email
        firstName
        lastName
        title
        signedProfileImageUrl
        role
      }
    }
  }
`;
