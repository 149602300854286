import { gql } from "@apollo/client";

export const getTransportationListQuery = gql`
  query transportation {
    transportation {
      id
      createdAt
      updatedAt
      isDeleted
      isActive
      order
      label
    }
  }
`;
export const findWorkerEtransfersQuery = gql`
  query findEtransferTransactions(
    $type: String
    $amount: Float
    $customerNumber: String
    $maxExpiryDate: DateTime
    $maxDate: DateTime
    $minDate: DateTime
    $transactionId: Int
    $minExpiryDate: DateTime
  ) {
    findEtransferTransactions(
      type: $type
      amount: $amount
      customerNumber: $customerNumber
      maxExpiryDate: $maxExpiryDate
      maxDate: $maxDate
      minDate: $minDate
      minExpiryDate: $minExpiryDate
      transactionId: $transactionId
    )
  }
`;
