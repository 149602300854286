import React, { useState } from "react";
import MainContent from "components/Containers/MainContent";
import JobTemplateTable from "./components/JobTemplateTable";
import Button from "../../components/Button/Button";
import { useDispatch, useSelector } from "react-redux";
import { Add } from "@mui/icons-material";
import { actions as JobTemplateActions, selectors as JobTemplateSelectors } from "store/modules/jobTemplate";
import Dialog from "components/Dialog/Dialog";
import { removeJobTemplate, fetchJobTemplate } from "store/modules/jobTemplate/actions";

function JobTemplateOverview(props) {

  const dispatch = useDispatch();
  const isremoveDialog = useSelector(JobTemplateSelectors.getIsRemoveDialog);
  const [deleteJobTemplateId, setDeleteJobTemplateId] = useState(null);

  const createJobTemplate = () => {
    dispatch(JobTemplateActions.resetFormData());
    props.history.push("/job-template/add");
  };

  const handleJobTemplateEdit = (id) => {
    dispatch(fetchJobTemplate(id))
      .then(() => {
        props.history.push(`/job-template/${id}`);
      })
  }

  const showJobTemplateDeleteDialog = (id) => {
    dispatch(JobTemplateActions.setIsRemoveDialog(true));
    setDeleteJobTemplateId(id);
  }

  const hideJobTemplateDeleteDialog = () => {
    dispatch(JobTemplateActions.setIsRemoveDialog(false));
  }

  const handleDeleteJobTemplate = () => {
    dispatch(removeJobTemplate(deleteJobTemplateId));
    hideJobTemplateDeleteDialog();
  }

  return (
    <MainContent>
      <Button
        style={{ alignSelf: "flex-end", marginBottom: "20px" }}
        label="Create a Job Template"
        icon={<Add />}
        alignIcon="left"
        onClick={createJobTemplate}
      />
      <JobTemplateTable onDelete={showJobTemplateDeleteDialog} onEdit={handleJobTemplateEdit} />
      <Dialog
        open={isremoveDialog}
        title="Are you sure?"
        onClose={hideJobTemplateDeleteDialog}
        onConfirm={handleDeleteJobTemplate}
        onCancel={hideJobTemplateDeleteDialog}
        alertType="warning"
        icon="remove_circle"
      />
    </MainContent>
  )
}

export default JobTemplateOverview
