import { StyleSheet } from "@react-pdf/renderer";

export const styles = ({ spacing, typography, shape, palette, customPalette }) => ({
  tabbedTableContainer: { marginTop: spacing(3) },
  tableContainer: { width: "100%" },
  tabIndicator: { display: "none !important" },
  tabRoot: {
    marginRight: spacing(1),
    borderRadius: `${shape.borderRadius}px`,
    opacity: 1,
    "&:hover": { border: `1px solid ${palette.secondary.main}` },
  },
  tabLabelContainer: { padding: 0 },
  tabLabel: { fontSize: "1rem" },
  tabSelected: {
    border: `2px solid ${palette.secondary.main}`,
    backgroundColor: customPalette.primary[200],
    "&:hover": { border: `2px solid ${palette.secondary.main}` },
  },
  documents: { color: palette.secondary.main, fontSize: "1rem" },
  answerStyle: { padding: spacing(3), backgroundColor: "#f8f9fa" },
  subtableHead: { backgroundColor: "#e1e8ec", height: 24, fontSize: 12, color: "#404b5a" },
  dialogTitle: { backgroundColor: "#fff", borderRadius: 4, fontSize: 12, color: "#000" },
  dialogActions: {
    backgroundColor: "transparent !important",
    fontSize: 12,
    color: "#000",
  },
  dialogContent: {
    // backgroundColor: "#fff",
    backgroundColor: "transparent !important",
    fontSize: 12,
    color: "#000",
  },
  dialog: {
    backgroundColor: "transparent !important",
    fontSize: 12,
    color: "#000",
  },
  dialogTopActions: {
    backgroundColor: "transparent !important", // padding: 15,
    paddingBottom: 0,
    fontSize: 12,
    color: "#fff",
    fontWeight: 700,
    alignSelf: "flex-end",
  },
  whiteTitle: { color: "#fff", fontSize: 16, alignSelf: "flex-end" },
  white: { color: "#fff" },
  icon: { fontSize: typography.pxToRem(20), marginRight: spacing(2) },
  root: { margin: 0, backgroundColor: "transparent !important", padding: spacing(2) },
  iconButton: {
    backgroundColor: "transparent !important",
    paddingBottom: 5,
    paddingLeft: 5,
    paddingTop: 0,
    paddingRight: 0,
  },
});

export const pdfTemplateStyles = StyleSheet.create({
  body: {
    padding: 15,
    marginVertical: 30,
    // marginHorizontal: 15,
    backgroundColor: "white",
  },
  header: {
    minHeight: 60,
    width: "100%",
    backgroundColor: "red",
    marginHorizontal: 30,

    paddingVertical: 4,
  },
  billingAddressLg: {
    color: "#fff",
    fontSize: 12,
    textAlign: "right",
    marginVertical: 4,
  },

  billingAddress: {
    color: "#fff",
    fontSize: 10,
    textAlign: "right",
  },

  employerName: {
    color: "#000",
    fontSize: 18,
    fontWeight: 700,
  },
  employerAddress: {
    color: "#000",
    fontSize: 14,
  },
  employerBlock: {
    width: "100%",
    marginVertical: 0,
    marginHorizontal: 20,
  },
  leftBlock: {
    width: "50%",

    paddingHorizontal: 20,
    marginVertical: 0,
  },
  rightBlock: {
    width: "50%",

    textAlign: "right",
    paddingHorizontal: 20,
    marginVertical: 0,
  },
  content: {
    padding: 2,
    "@media max-width: 400": {
      flexDirection: "column",
    },
    "@media min-width: 400": {
      flexDirection: "row",
    },
  },
  logo: {
    height: 40,
    width: 40,
    marginVertical: 10,
    backgroundColor: "#0b938b",
  },
  section: {
    color: "white",
    textAlign: "center",
    margin: 30,
  },
  pageNumber: {
    position: "absolute",
    fontSize: 12,
    bottom: 20,
    left: 0,
    right: 0,
    textAlign: "center",
    color: "grey",
    paddingTop: 30,
  },
});
export const pdfPayStubTableStyles = StyleSheet.create({
  table: {
    marginHorizontal: 20,
    // marginRight: 10,
    display: "table",
    // width: "100%",
    fontSize: 9,
    borderStyle: "solid",
    borderWidth: 0,
    borderRightWidth: 0,
    borderBottomWidth: 0,
    textAlign: "left",
    marginBottom: 5,
  },

  header: {
    paddingBottom: 10,
    width: "100%",
    backgroundColor: "#0b938b",
    "@media max-width: 400": {
      flexDirection: "column",
    },
    "@media min-width: 400": {
      flexDirection: "row",
    },
  },
  innerContent: {
    marginHorizontal: 10,
  },
  workerName: {
    color: "#000",
    fontSize: 12,
    fontWeight: 700,
    marginVertical: 4,
  },
  workerAddress: {
    color: "#000",
    fontSize: 10,
  },
  workerBlock: {
    width: "100%",
    marginVertical: 0,
    marginHorizontal: 20,
  },
  contentLeft: {
    width: "50%",
    borderStyle: "solid",
    borderWidth: 0,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    textAlign: "left",
  },

  contentRight: {
    width: "50%",
    borderStyle: "solid",
    borderWidth: 0,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    textAlign: "left",
  },

  tableColLeft: {
    width: "40%",
    borderStyle: "solid",
    borderWidth: 0,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    textAlign: "left",
  },

  tableColEarningsType: {
    width: "40%",
    borderStyle: "solid",
    borderWidth: 0,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    textAlign: "left",
  },
  tableColEarnings: {
    width: "20%",
    borderStyle: "solid",
    borderWidth: 0,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    textAlign: "left",
  },
  tableColRight: {
    width: "60%",
    borderStyle: "solid",
    borderWidth: 0,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    textAlign: "right",
  },

  //    tableRow: { margin: "auto", textAlign: "left", flexDirection: "row" },
  tableFooterRow: {
    borderTopWidth: 1,
    margin: "auto",
    borderColor: "lightgrey",
    fontSize: 10,
    fontWeight: "bold",
    textAlign: "left",
    flexDirection: "row",
    marginTop: 15,
  },
  tableTaxRow: {
    borderTopWidth: 0,
    margin: "auto",
    borderColor: "lightgrey",
    fontSize: 10,
    fontWeight: "bold",
    textAlign: "left",
    flexDirection: "row",
    marginTop: 2,
  },
  tableHeaderRow: {
    borderBottomWidth: 1,
    margin: "auto",
    borderColor: "lightgrey",
    fontSize: 16,
    fontWeight: "bold",
    textAlign: "left",
    flexDirection: "row",
  },
  tableRow: {
    borderBottomWidth: 0,
    margin: "auto",
    fontSize: 16,
    fontWeight: "bold",
    textAlign: "left",
    flexDirection: "row",
    marginTop: 2,
  },
  tableCell: { marginTop: 5, fontSize: 9 },
  tableHeaderCell: { fontWeight: "bold", paddingVertical: 5, marginTop: 5, fontSize: 9 },

  tableCol1: {
    width: "8%",
    borderStyle: "solid",
    borderWidth: 0,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    textAlign: "left",
    flexDirection: "column",
    alignItems: "flex-start",
  },
  tableCol2: {
    width: "14%",
    borderStyle: "solid",
    borderWidth: 0,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    textAlign: "left",
    flexDirection: "column",
    alignItems: "flex-start",
  },
  tableCol3: {
    width: "15%",
    borderStyle: "solid",
    borderWidth: 0,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    textAlign: "left",
    flexDirection: "column",
    alignItems: "flex-start",
  },
  tableCol4: {
    width: "15%",
    borderStyle: "solid",
    borderWidth: 0,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    textAlign: "left",
    // alignSelf: "flex-end",
    // justifySelf: "flex-end",
    flexDirection: "column",
    alignItems: "flex-start",
  },
  tableCol5: {
    width: "10%",
    borderStyle: "solid",
    borderWidth: 0,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    textAlign: "left",
    flexDirection: "column",
    alignItems: "flex-start",
  },
  tableCol6: {
    width: "10%",
    borderStyle: "solid",
    borderWidth: 0,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    textAlign: "left",
    flexDirection: "column",
  },
  tableCol7: {
    width: "10%",
    borderStyle: "solid",
    borderWidth: 0,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    textAlign: "left",
    flexDirection: "column",
  },
  colWidth10: {
    width: "9%",
    borderStyle: "solid",
    borderWidth: 0,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    textAlign: "right",
    flexDirection: "column",
  },
});
export const pdfTableStyles = StyleSheet.create({
  table: {
    marginHorizontal: 30,
    marginRight: 30,
    display: "table",
    // width: "100%",
    fontSize: 9,
    borderStyle: "solid",
    borderWidth: 0,
    borderRightWidth: 0,
    borderBottomWidth: 0,
    textAlign: "left",
    marginBottom: 35,
  },
  //    tableRow: { margin: "auto", textAlign: "left", flexDirection: "row" },
  tableFooterRow: {
    borderTopWidth: 1,
    margin: "auto",
    borderColor: "lightgrey",
    fontSize: 10,
    fontWeight: "bold",
    textAlign: "left",
    flexDirection: "row",
    marginTop: 15,
  },
  tableTaxRow: {
    borderTopWidth: 0,
    margin: "auto",
    borderColor: "lightgrey",
    fontSize: 10,
    fontWeight: "bold",
    textAlign: "left",
    flexDirection: "row",
    marginTop: 2,
  },
  tableHeaderRow: {
    borderBottomWidth: 1,
    margin: "auto",
    borderColor: "lightgrey",
    fontSize: 16,
    fontWeight: "bold",
    textAlign: "left",
    flexDirection: "row",
  },
  tableRow: {
    borderBottomWidth: 0,
    margin: "auto",
    fontSize: 16,
    fontWeight: "bold",
    textAlign: "left",
    flexDirection: "row",
    marginTop: 5,
  },
  tableCell: { marginTop: 5, fontSize: 9 },
  tableHeaderCell: { fontWeight: "bold", paddingVertical: 5, marginTop: 5, fontSize: 9 },

  tableCol1: {
    width: "8%",
    borderStyle: "solid",
    borderWidth: 0,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    textAlign: "left",
    flexDirection: "column",
    alignItems: "flex-start",
  },
  tableCol2: {
    width: "14%",
    borderStyle: "solid",
    borderWidth: 0,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    textAlign: "left",
    flexDirection: "column",
    alignItems: "flex-start",
  },
  tableCol3: {
    width: "15%",
    borderStyle: "solid",
    borderWidth: 0,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    textAlign: "left",
    flexDirection: "column",
    alignItems: "flex-start",
  },
  tableCol4: {
    width: "15%",
    borderStyle: "solid",
    borderWidth: 0,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    textAlign: "left",
    // alignSelf: "flex-end",
    // justifySelf: "flex-end",
    flexDirection: "column",
    alignItems: "flex-start",
  },
  tableCol5: {
    width: "10%",
    borderStyle: "solid",
    borderWidth: 0,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    textAlign: "left",
    flexDirection: "column",
    alignItems: "flex-start",
  },
  tableCol6: {
    width: "10%",
    borderStyle: "solid",
    borderWidth: 0,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    textAlign: "left",
    flexDirection: "column",
  },
  tableCol7: {
    width: "10%",
    borderStyle: "solid",
    borderWidth: 0,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    textAlign: "left",
    flexDirection: "column",
  },
  colWidth10: {
    width: "9%",
    borderStyle: "solid",
    borderWidth: 0,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    textAlign: "right",
    flexDirection: "column",
  },
});
export const viewerStyle = { width: "100%", height: "100%", minHeight: 800 };

export const styless = StyleSheet.create({
  page: {
    padding: 30,
  },
  container: {
    flex: 1,
    flexDirection: "row",
    "@media max-width: 400": {
      flexDirection: "column",
    },
  },
  image: {
    marginBottom: 10,
  },
  imageStyle: {
    width: 80,
    height: 80,
  },
  leftColumn: {
    flexDirection: "column",
    width: 170,
    paddingTop: 30,
    paddingRight: 15,
    "@media max-width: 400": {
      width: "100%",
      paddingRight: 0,
    },
    "@media orientation: landscape": {
      width: 200,
    },
  },
  footer: {
    fontSize: 12,
    fontFamily: "Lato Bold",
    textAlign: "center",
    marginTop: 25,
    paddingTop: 10,
    borderWidth: 3,
    borderColor: "gray",
    borderStyle: "dashed",
    "@media orientation: landscape": {
      marginTop: 10,
    },
  },
  title: {
    fontFamily: "Lato Bold",
    fontSize: 14,
    marginBottom: 10,
    textTransform: "uppercase",
  },
  container2: {
    flexDirection: "row",
    borderBottomWidth: 2,
    borderBottomColor: "#112131",
    borderBottomStyle: "solid",
    alignItems: "stretch",
  },
  detailColumn: {
    flexDirection: "column",
    flexGrow: 9,
    textTransform: "uppercase",
  },
  linkColumn: {
    flexDirection: "column",
    flexGrow: 2,
    alignSelf: "flex-end",
    justifySelf: "flex-end",
  },
  name: {
    fontSize: 24,
    fontFamily: "Lato Bold",
  },
  subtitle: {
    fontSize: 10,
    justifySelf: "flex-end",
    fontFamily: "Lato",
  },
  link: {
    fontFamily: "Lato",
    fontSize: 10,
    color: "black",
    textDecoration: "none",
    alignSelf: "flex-end",
    justifySelf: "flex-end",
  },
  tableHeader: {
    padding: 10,
    borderWidth: 0,
    // fontFamily: "Lato",
    fontSize: 10,
    // color: "black",
    // textDecoration: "none",
    // alignSelf: "flex-end",
    // justifySelf: "flex-end",
  },
  corpHeader: {
    padding: 10,
    borderWidth: 0,
    // fontFamily: "Lato",
    fontSize: 10,
    // color: "black",
    // textDecoration: "none",
    // alignSelf: "flex-end",
    // justifySelf: "flex-end",
  },

  corpHeaderLeft: {
    padding: 10,
    borderWidth: 0,
    width: 200,
    fontSize: 10,
    // color: "black",
    // textDecoration: "none",
    // alignSelf: "flex-end",
    // justifySelf: "flex-end",
  },

  corpHeaderRight: {
    padding: 10,
    borderWidth: 0,
    // fontFamily: "Lato",
    width: "auto",
    textAlign: "right",
    fontSize: 10,
    // color: "black",
    // textDecoration: "none",
    // alignSelf: "flex-end",
    // justifySelf: "flex-end",
  },

  thStyle: {
    padding: 10,
    borderWidth: 0,
    // fontFamily: "Lato",
    fontSize: 14,
    textTransform: "capitalize",
    // color: "black",
    // textDecoration: "none",
    // alignSelf: "flex-end",
    // justifySelf: "flex-end",
  },
  tdStyle: {
    padding: 10,
    paddingLeft: 40,
    borderWidth: 0.5,
    // fontFamily: "Lato",
    fontSize: 10,
    // color: "black",
    // textDecoration: "none",
    // alignSelf: "flex-end",
    // justifySelf: "flex-end",
  },
  corpHeaderTitle: {
    paddingTop: 10,
    borderWidth: 0,
    // fontFamily: "Lato",
    fontSize: 24,
    color: "black",
    textDecoration: "none",
    // alignSelf: "flex-end",
    // justifySelf: "flex-end",
  },
  tableStyle: {
    paddingTop: 40,
    borderWidth: 0,
    // // fontFamily: "Lato",
    // fontSize: 24,
    // color: "black",
    // textDecoration: "none",
    // alignSelf: "flex-end",
    // justifySelf: "flex-end",
  },
});

export const getTrProps = (state, rowInfo) => {
  if (rowInfo && rowInfo.row) {
    return {
      onClick: () => {
        this.setState({ selected: rowInfo.index });
      },
      style: {
        background: rowInfo.index === this.state.selected ? "#00afec" : "white",
        color: rowInfo.index === this.state.selected ? "white" : "black",
      },
    };
  } else {
    return {};
  }
};

export const invoiceSpreadsheetHeaders = [
  { title: "Job Id", dataIndex: "jobId", dataType: "string" },
  { title: "Job Date", dataIndex: "date", dataType: "date" },
  { title: "Worker", dataIndex: "worker", dataType: "string" },
  { title: "Check In", dataIndex: "checkIn", dataType: "date" },
  { title: "Check Out", dataIndex: "checkOut", dataType: "date" },
  { title: "Break", dataIndex: "breakLength", dataType: "number" },
  { title: "Duration", dataIndex: "hours", dataType: "number" },
  { title: "Billing Rate", dataIndex: "billingRate", dataType: "number" },
  { title: "Total", dataIndex: "total", dataType: "number" },
];
