import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
// import { Manager, Target, Popper } from "react-popper";

// @mui/material components
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Grow from "@mui/material/Grow";
import Hidden from "@mui/material/Hidden";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import withStyles from '@mui/styles/withStyles';
import Dashboard from "@mui/icons-material/Dashboard";

// @mui/icons-material
import Notifications from "@mui/icons-material/Notifications";
import Person from "@mui/icons-material/Person";

import headerLinksStyle from "assets/jss/material-dashboard-pro-react/components/headerLinksStyle";
import Button from "components/CustomButtons/Button.jsx";

class HeaderLinks extends React.Component {
  state = {
    open: false,
  };
  handleClick = () => {
    this.setState({ open: !this.state.open });
  };
  handleClose = () => {
    this.setState({ open: false });
  };
  render() {
    const { classes, rtlActive } = this.props;
    const { open } = this.state;

    const dropdownItem = classNames(classes.dropdownItem, classes.primaryHover, {
      [classes.dropdownItemRTL]: rtlActive,
    });
    const wrapper = classNames({
      [classes.wrapperRTL]: rtlActive,
    });
    const managerClasses = classNames({
      [classes.managerClasses]: true,
    });
    return (
      <div className={wrapper}>
        <Button
          color="transparent"
          simple
          aria-label="Dashboard"
          justIcon
          className={rtlActive ? classes.buttonLinkRTL : classes.buttonLink}
          muiClasses={{
            label: rtlActive ? classes.labelRTL : "",
          }}>
          <Dashboard
            className={
              classes.headerLinksSvg +
              " " +
              (rtlActive ? classes.links + " " + classes.linksRTL : classes.links)
            }
          />
          <Hidden mdUp implementation="css">
            <span className={classes.linkText}>{rtlActive ? "لوحة القيادة" : "Dashboard"}</span>
          </Hidden>
        </Button>
        <div className={managerClasses}>
          <Button
            color="transparent"
            justIcon
            aria-label="Notifications"
            aria-owns={open ? "menu-list" : null}
            aria-haspopup="true"
            onClick={this.handleClick}
            className={rtlActive ? classes.buttonLinkRTL : classes.buttonLink}
            muiClasses={{
              label: rtlActive ? classes.labelRTL : "",
            }}
            buttonRef={node => {
              this.anchorEl = node;
            }}>
            <Notifications
              className={
                classes.headerLinksSvg +
                " " +
                (rtlActive ? classes.links + " " + classes.linksRTL : classes.links)
              }
            />
            <span className={classes.notifications}>5</span>
            <Hidden mdUp implementation="css">
              <span onClick={this.handleClick} className={classes.linkText}>
                {rtlActive ? "إعلام" : "Notification"}
              </span>
            </Hidden>
          </Button>
          <Popper
            open={open}
            anchorEl={this.anchorEl}
            transition
            disablePortal
            placement="bottom"
            className={classNames({
              [classes.popperClose]: !open,
              [classes.pooperResponsive]: true,
              [classes.pooperNav]: true,
            })}>
            {({ TransitionProps }) => (
              <Grow {...TransitionProps} id="menu-list" style={{ transformOrigin: "0 0 0" }}>
                <Paper className={classes.dropdown}>
                  <ClickAwayListener onClickAway={this.handleClose}>
                    <MenuList role="menu">
                      <MenuItem onClick={this.handleClose} className={dropdownItem}>
                        {rtlActive
                          ? "إجلاء أوزار الأسيوي حين بل, كما"
                          : "Mike John responded to your email"}
                      </MenuItem>
                      <MenuItem onClick={this.handleClose} className={dropdownItem}>
                        {rtlActive ? "شعار إعلان الأرضية قد ذلك" : "You have 5 new tasks"}
                      </MenuItem>
                      <MenuItem onClick={this.handleClose} className={dropdownItem}>
                        {rtlActive
                          ? "ثمّة الخاصّة و على. مع جيما"
                          : "You're now friend with Andrew"}
                      </MenuItem>
                      <MenuItem onClick={this.handleClose} className={dropdownItem}>
                        {rtlActive ? "قد علاقة" : "Another Notification"}
                      </MenuItem>
                      <MenuItem onClick={this.handleClose} className={dropdownItem}>
                        {rtlActive ? "قد فاتّبع" : "Another One"}
                      </MenuItem>
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
        </div>
        <Button
          color="transparent"
          aria-label="Person"
          justIcon
          className={rtlActive ? classes.buttonLinkRTL : classes.buttonLink}
          muiClasses={{
            label: rtlActive ? classes.labelRTL : "",
          }}>
          <Person
            className={
              classes.headerLinksSvg +
              " " +
              (rtlActive ? classes.links + " " + classes.linksRTL : classes.links)
            }
          />
          <Hidden mdUp implementation="css">
            <span className={classes.linkText}>{rtlActive ? "الملف الشخصي" : "Profile"}</span>
          </Hidden>
        </Button>
      </div>
    );
  }
}

HeaderLinks.propTypes = {
  classes: PropTypes.object.isRequired,
  rtlActive: PropTypes.bool,
};

export default withStyles(headerLinksStyle)(HeaderLinks);
