import ApolloClient from "utils/apollo";
import * as pagination from "utils/graphql-pagination";
import { actions as MetaActions } from "store/modules/meta";
import { paginationSettings } from "./constants";
import addAdminMutation, {
    changeAdminPasswordMutation,
    removeAdminMutation,
    removeAdminPermissionsMutation,
    updateAdminMutation,
    updateAdminPermissionsMutation,
} from "./graphql/mutations";
import adminsQuery, { adminsByIdQuery, getAllAdminPermissionQuery, getUnAssignAdminPermissionQuery, permissionByAdminIdQuery } from "./graphql/queries";
import { getPermissionListPaginationData } from "./selectors";
import types from "./types";

const setLoadingState = key => value => ({
    type: types.SET_LOADING_STATE,
    payload: { key, value },
});

const setIsAdminLoading = setLoadingState("admins");
const setIsSaveAdminLoading = setLoadingState("adminsSave");
const setIsAdminPermissionLoading = setLoadingState("adminPermission");
const setIsUpdatePermissionLoading = setLoadingState("adminPermissionUpdate");
export const setIsOpenAddDialog = setLoadingState("isOpenAddDialog");
export const setIsOpenResetPasswordDialog = setLoadingState("isOpenResetPasswordDialog");
export const setIsOpenAssignPermissionDialog = setLoadingState("isOpenAssignPermissionDialog");
export const setUnAssignedPermissionsLoading = setLoadingState("unAssignedPermissions");
/*
Admin list
*/
const setAdminListData = data => ({ type: types.SET_ADMIN_DATA, payload: { data } });
const setAdminDetailData = data => ({ type: types.SET_ADMIN_DETAIL, payload: { data } });
const setAdminPermissionData = data => ({
    type: types.SET_ADMIN_PERMISSION_DATA,
    payload: { data },
});
const setAllPermissionData = data => ({ type: types.SET_ALL_PERMISSION_DATA, payload: { data } });
const setUnAssignedAdminPermissionData = data => ({ type: types.SET_UNASSIGNED_ADMIN_PERMISSION_DATA, payload: { data } });

export const fetchAdminData = () => (dispatch) => {
    dispatch(setIsAdminLoading(true));
    return ApolloClient.query({
        query: adminsQuery,
        variables: {},
    })
        .then(({ data, errors }) => {
            if (!data && errors.length > 0) {
                throw Error(errors[0].message);
            } else {
                const { admins } = data;
                dispatch(setAdminListData(admins));
                dispatch(setIsAdminLoading(false));
            }
        })
        .catch(e => {
            dispatch(setIsAdminLoading(false));
            dispatch(MetaActions.errorToast(e.message));
        });
};

export const addAdminData = data => (dispatch) => {
    dispatch(setIsSaveAdminLoading(true));
    return ApolloClient.mutate({
        mutation: addAdminMutation,
        variables: {
            data: {
                ...data,
            },
        },
    })
        .then(({ data, errors }) => {
            if (!data && errors.length > 0) {
                throw Error(errors[0].message);
            } else {
                dispatch(MetaActions.successToast("Successfully added admin user."));
                dispatch(fetchAdminData());
                dispatch(setIsSaveAdminLoading(false));
                dispatch(setIsOpenAddDialog(false));
            }
        })
        .catch(e => {
            dispatch(setIsSaveAdminLoading(false));
            dispatch(MetaActions.errorToast(e.message));
        });
};

export const updateAdminData = data => (dispatch) => {
    dispatch(setIsSaveAdminLoading(true));
    return ApolloClient.mutate({
        mutation: updateAdminMutation,
        variables: {
            data: {
                id: data.id,
                firstName: data.firstName,
                lastName: data.lastName,
                title: data.title,
                role: data.role,
                profileImage: data.profileImage,
            },
        },
    })
        .then(({ data, errors }) => {
            if (!data && errors.length > 0) {
                throw Error(errors[0].message);
            } else {
                dispatch(MetaActions.successToast("Successfully update admin user."));
                dispatch(fetchAdminData());
                dispatch(setIsSaveAdminLoading(false));
                dispatch(setIsOpenAddDialog(false));
            }
        })
        .catch(e => {
            dispatch(setIsSaveAdminLoading(false));
            dispatch(MetaActions.errorToast(e.message));
        });
};

export const removeAdminData = id => (dispatch, getState) => {
    const state = getState();
    dispatch(setIsSaveAdminLoading(true));
    return ApolloClient.mutate({
        mutation: removeAdminMutation,
        variables: {
            id,
        },
    })
        .then(({ data, errors }) => {
            if (!data && errors.length > 0) {
                throw Error(errors[0].message);
            } else {
                dispatch(MetaActions.successToast("Admin deleted successfully"));
                dispatch(fetchAdminData());
                dispatch(setIsSaveAdminLoading(false));
            }
        })
        .catch(e => {
            dispatch(setIsSaveAdminLoading(false));
            dispatch(MetaActions.errorToast(e.message));
        });
};

export const resetAdminPassword = data => (dispatch, getState) => {
    const state = getState();
    dispatch(setIsSaveAdminLoading(true));
    return ApolloClient.mutate({
        mutation: changeAdminPasswordMutation,
        variables: {
            id: data.id,
            password: data.password,
        },
    })
        .then(({ data, errors }) => {
            if (!data && errors.length > 0) {
                throw Error(errors[0].message);
            } else {
                dispatch(MetaActions.successToast("Password reset successfully."));
                dispatch(setIsSaveAdminLoading(false));
                dispatch(setIsOpenResetPasswordDialog(false));
            }
        })
        .catch(e => {
            dispatch(setIsSaveAdminLoading(false));
            dispatch(MetaActions.errorToast(e.message));
        });
};

export const fetchAdminById = id => dispatch => {
    return ApolloClient.query({
        query: adminsByIdQuery,
        variables: {
            id,
        },
    })
        .then(({ data, errors }) => {
            if (!data && errors.length > 0) {
                throw Error(errors[0].message);
            } else {
                const { adminById } = data;
                dispatch(setAdminDetailData(adminById));
            }
        })
        .catch(e => {
            dispatch(MetaActions.errorToast(e.message));
        });
};
export const setPermissionPageSize = (pageSize) => (dispatch) => {
    dispatch(pagination.updatePageInfo(paginationSettings.permissionList, { pageSize, currentPage: 0 }));
}
export const setPermissionPageChange = (currentPage) => (dispatch) => {
    dispatch(pagination.updatePageInfo(paginationSettings.permissionList, { currentPage }));
}
export const fetchPermissionByAdmin = (id) => (dispatch, getState) => {
    const state = getState()
    dispatch(setIsAdminPermissionLoading(true));
    const { currentPage, pageSize } = getPermissionListPaginationData(state);
    return ApolloClient.query({
        query: permissionByAdminIdQuery,
        variables: {
            id,
            perPage: pageSize,
            page: currentPage
        },
    })
        .then(({ data, errors }) => {
            if (!data && errors.length > 0) {
                throw Error(errors[0].message);
            } else {
                const { getAdminPermissions } = data;
                dispatch(setAdminPermissionData(getAdminPermissions.permissions));
                const paginationData = {
                    totalCount: getAdminPermissions.total,
                    // currentPage: getAdminPermissions.start,
                    pageSize: getAdminPermissions.limit,
                };
                dispatch(
                    pagination.updatePageInfo(paginationSettings.permissionList, paginationData)
                );
                dispatch(setIsAdminPermissionLoading(false))
            }
        })
        .catch(e => {
            dispatch(setIsAdminPermissionLoading(false))
            dispatch(MetaActions.errorToast(e.message));
        });
};

export const fetchAllPermissions = () => dispatch => {
    return ApolloClient.query({
        query: getAllAdminPermissionQuery,
        variables: {},
    })
        .then(({ data, errors }) => {
            if (!data && errors.length > 0) {
                throw Error(errors[0].message);
            } else {
                const { getAllAdminPermissions } = data;
                dispatch(setAllPermissionData(getAllAdminPermissions));
            }
        })
        .catch(e => {
            dispatch(MetaActions.errorToast(e.message));
        });
};

export const fetchUnAssignedAdminPermissions = (id) => dispatch => {
    dispatch(setUnAssignedPermissionsLoading(true));
    return ApolloClient.query({
        query: getUnAssignAdminPermissionQuery,
        variables: { id },
    })
        .then(({ data, errors }) => {
            if (!data && errors.length > 0) {
                throw Error(errors[0].message);
            } else {
                const { getUnAssignAdminPermissions } = data;
                dispatch(setUnAssignedPermissionsLoading(false));
                dispatch(setUnAssignedAdminPermissionData(getUnAssignAdminPermissions));
            }
        })
        .catch(e => {
            dispatch(setUnAssignedPermissionsLoading(false));
            dispatch(MetaActions.errorToast(e.message));
        });
};

export const updateAdminPermissions = (id, permissions) => (dispatch) => {
    dispatch(setIsUpdatePermissionLoading(true));
    return ApolloClient.mutate({
        mutation: updateAdminPermissionsMutation,
        variables: {
            id,
            permissions,
        },
    })
        .then(({ data, errors }) => {
            if (!data && errors.length > 0) {
                throw Error(errors[0].message);
            } else {
                dispatch(fetchPermissionByAdmin(id));
                dispatch(MetaActions.successToast("Permission updated successfully"));
                dispatch(setIsUpdatePermissionLoading(false));
            }
        })
        .catch(e => {
            dispatch(setIsUpdatePermissionLoading(false));
            dispatch(MetaActions.errorToast(e.message));
        });
};

export const removeAdminPermissions = (id, permissions) => (dispatch) => {
    console.log(permissions);
    dispatch(setIsUpdatePermissionLoading(true));
    return ApolloClient.mutate({
        mutation: removeAdminPermissionsMutation,
        variables: {
            id,
            permissions: [{
                permission_name: permissions.permission_name,
                resource_server_identifier: permissions.resource_server_identifier
            }],
        },
    })
        .then(({ data, errors }) => {
            if (!data && errors.length > 0) {
                throw Error(errors[0].message);
            } else {
                dispatch(fetchPermissionByAdmin(id));
                dispatch(MetaActions.successToast("Permission removed successfully"));
                dispatch(setIsUpdatePermissionLoading(false));
            }
        })
        .catch(e => {
            dispatch(setIsUpdatePermissionLoading(false));
            dispatch(MetaActions.errorToast(e.message));
        });
};
