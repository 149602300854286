import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import MainContent from "components/Containers/MainContent";
import RequiredGearTable from "./components/RequiredGearTable";
import Dialog from "components/Dialog/Dialog";
import { Add } from "@mui/icons-material";
import { actions as requiredGearActions, selectors as requiredGearSelectors } from "store/modules/requiredGear";
import { deleteGear } from "store/modules/requiredGear/actions";
import Button from "components/Button/Button";
import AddGearDialog from "./components/AddGearDialog";

function RequiredGearOverview() {

  const dispatch = useDispatch()
  const isdeleteDialog = useSelector(requiredGearSelectors.getIsdeleteDialog);
  const [deleteGearId, setDeleteGearId] = useState(null);

  const createGear = () => {
    dispatch(requiredGearActions.setIsaddGearDialog(true));
  }

  const showGearDeleteDialog = (id) => {
    dispatch(requiredGearActions.setIsDeleteDialog(true));
    setDeleteGearId(id);
  }

  const hideGearDeleteDialog = () => {
    dispatch(requiredGearActions.setIsDeleteDialog(false));
  }

  const handleDeleteGear = () => {
    dispatch(deleteGear(deleteGearId));
    hideGearDeleteDialog();
  }

  return (
    <>
      <MainContent>
        <Button
          style={{ alignSelf: "flex-end", marginBottom: 24 }}
          label="Add"
          icon={<Add />}
          alignIcon="left"
          onClick={createGear}
        />
        <RequiredGearTable onDelete={showGearDeleteDialog} />
        <Dialog
          open={isdeleteDialog}
          title="Are you sure?"
          onClose={hideGearDeleteDialog}
          onConfirm={handleDeleteGear}
          onCancel={hideGearDeleteDialog}
          alertType="warning"
          icon="help_outline"
        />
      </MainContent>
      <AddGearDialog />
    </>
  )
}

export default RequiredGearOverview
