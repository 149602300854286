import React, { useState } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import Dialog from "components/Dialog/Dialog";
import { isWithinSuspensionTimeframe } from "utils/time";
import { selectors as JobDetailSelectors } from "store/modules/jobDetails";

function RemoveDialog(props) {
  const { open, onClose, onCancel, onConfirm, position } = props;
  const [showRemoveSuspend, setShowRemoveSuspend] = useState(false);
  const [showBan, setShowBan] = useState(false);

  const jobStart = useSelector(JobDetailSelectors.getJobStartUtc);
  const { isPrivate, isActive } = useSelector(JobDetailSelectors.getJobDetails);

  const handleRemove = () => {
    if (isWithinSuspensionTimeframe(jobStart) && isActive) {
      if(isPrivate) {
        if(position.lateJobCancelCount && position.lateJobCancelCount > 0) {
          setShowBan(true);
        } else {
          onConfirm(false, false);    
        }
      } else {
        setShowRemoveSuspend(true);
      }
    } else {
      onConfirm(false, false);
    }
  };

  const handleRemoveOnly = () => {
    setShowRemoveSuspend(false, false);
    setShowBan(false);
    onConfirm(false, false);
  };

  const handleRemoveAndSuspend = () => {
    setShowRemoveSuspend(false, false);
    onConfirm(true, false);
  };

  const handleRemoveBan = () => {
    setShowBan(false);
    onConfirm(false, true);
  };

  const handleClose = (e) => {
    setShowBan(false);
    setShowRemoveSuspend(false);
    onClose(e)
  }
  

  return (
    <>
      <Dialog
        open={open && !showRemoveSuspend}
        title="Are you sure?"
        description={`${position.fullName} will be removed from this job`}
        onClose={handleClose}
        onConfirm={handleRemove}
        onCancel={onCancel}
        alertType="warning"
        icon="remove_circle"
      />
      <Dialog
        open={open && showRemoveSuspend}
        title="Do you also want to suspend them?"
        description={`${position.fullName} will be suspended from Grizzly Force for 7 days.`}
        onClose={handleClose}
        onConfirm={handleRemoveAndSuspend}
        onCancel={handleRemoveOnly}
        cancelText="No, Remove Only"
        alertType="warning"
        icon="event_busy"
      />
      <Dialog
        open={open && showBan}
        title="Do you also want to ban them?"
        description={`${position.fullName} will be ban for this employer.`}
        onClose={handleClose}
        onConfirm={handleRemoveBan}
        onCancel={handleRemoveOnly}
        cancelText="No, Remove Only"
        alertType="warning"
        icon="event_busy"
      />
    </>
  );
}

RemoveDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  position: PropTypes.object,
};

export default RemoveDialog;
