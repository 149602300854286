import React, { useState } from "react";
import Datetime from "react-datetime";
import { useDispatch, useSelector } from "react-redux";
import { Typography, Grid, InputLabel } from "@mui/material";
import { makeStyles } from "@mui/styles";
import moment from "moment";
import Button from "components/Button/Button";
import Dialog from "components/Dialog/Dialog";
import { actions as JobActions, selectors as JobSelectors } from "store/modules/jobs";
import ReactTableSimple from "components/ReactTable/ReactTableSimple";

const useStyles = makeStyles(({ spacing }) => ({
    dialogTitle: {
        width: "100%",
        fontSize: 18,
        textAlign: "left",
    },
    container: {
        textAlign: "left",
    },
    addButton: {
        float: "left",
        paddingLeft: "0px",
        width: "fit-content",
    },
}));

function DeletePositionDialog({ privateJobDetail, open, onCancel, workerId }) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const isLoading = useSelector(JobSelectors.getFillPositionLoading);
    const errorList = useSelector(JobSelectors.getDeletePrivateJobPositionError);

    const closeDialog = () => {
        onCancel();
    };

    const deletePosition = () => {
        dispatch(JobActions.removePositionByPrivateJobRecurringId({ privateJobRecurringId: privateJobDetail.id, workerId }));
    }

    const columns = [
        {
            Header: "Job Id",
            id: "jobId",
            accessor: ({ jobId }) => jobId,
            className: "-cursor-pointer",
        },
        {
            Header: "Error",
            id: "error",
            accessor: ({ error }) => error,
            className: "-cursor-pointer",
            Cell: ({ row: { original } }) => {
                return <div style={{ color: "red" }}>{original.error}</div>;
            },
        },
    ];

    return (
        <Dialog
            title={
                <React.Fragment>
                    <Typography component="h5" className={classes.dialogTitle}>
                        {`Are you sure to remove worker?`}
                    </Typography>
                </React.Fragment>
            }
            open={open}
            description={null}
            onClose={closeDialog}
            onCancel={closeDialog}
            size="lg"
            hideActions
            alertType="form"
            modalType="editEmployer"
        >
            <React.Fragment>
                <Grid container spacing={2} className={classes.container}>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        Worker will remove from all future job
                    </Grid>
                </Grid>
                <br />
                <Button
                    className={classes.addButton}
                    buttonClassName={classes.addButton}
                    label="No"
                    size="small"
                    onClick={() => closeDialog()}
                    color="primary"
                />
                <Button
                    className={classes.addButton}
                    buttonClassName={classes.addButton}
                    label="Yes"
                    size="small"
                    onClick={() => deletePosition()}
                    color="secondary"
                    isLoading={isLoading}
                />
                <br />
                {errorList && errorList.length > 0 && (
                    <div style={{ textAlign: "left" }}>
                        <h3>We found below errors while delete position</h3>
                        <ReactTableSimple
                            classes="-highlight"
                            style={{ width: "100%", marginTop: "16px" }}
                            data={errorList}
                            defaultPageSize={5}
                            columns={columns}
                        />
                    </div>
                )}
            </React.Fragment>
        </Dialog>
    );
}

export default DeletePositionDialog;
