export const paginationSettings = {
  workerList: {
    prefix: "workerList",
    defaultPageSize: 10,
  },
  suspendedWorkerList: {
    prefix: "suspendedWorkerList",
    defaultPageSize: 10,
  },
  appliedWorkerList: {
    prefix: "appliedWorkerList",
    defaultPageSize: 10,
  },
  deactivatedWorkerList: {
    prefix: "deactivatedWorkerList",
    defaultPageSize: 10,
  },
  verifiedWorkerList: {
    prefix: "verifiedWorkerList",
    defaultPageSize: 10,
  },
  unverifiedWorkerList: {
    prefix: "unverifiedWorkerList",
    defaultPageSize: 10,
  },
  waitlistToday: {
    prefix: "waitlistToday",
    defaultPageSize: 10,
  },
  waitlistTomorrow: {
    prefix: "waitlistTomorrow",
    defaultPageSize: 10,
  },
  workerBannedBy: {
    prefix: "workerBannedBy",
    defaultPageSize: 20,
  },
  workerPreferredBy: {
    prefix: "workerPreferredBy",
    defaultPageSize: 20,
  },
};
