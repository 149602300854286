import { gql } from "@apollo/client";

export const dcBankTransactionReportQuery = gql`
  query workerDcBankHistory(
    $workerId: String
    $TransactionStatusCode: String
    $MinAmount: Int
    $MaxAmount: Int
    $MinDateOfFunds: DateTime
    $MaxDateOfFunds: DateTime
    $MinMoneyReqExpDate: DateTime
    $MaxMoneyReqExpDate: DateTime
    $ClientReferenceNumber: String
    $TransactionId: Int
    $CustomerNumber: String
  ) {
    workerDcBankHistory(
      workerId: $workerId
      TransactionStatusCode: $TransactionStatusCode
      MinAmount: $MinAmount
      MaxAmount: $MaxAmount
      MinDateOfFunds: $MinDateOfFunds
      MaxDateOfFunds: $MaxDateOfFunds
      MinMoneyReqExpDate: $MinMoneyReqExpDate
      MaxMoneyReqExpDate: $MaxMoneyReqExpDate
      ClientReferenceNumber: $ClientReferenceNumber
      TransactionId: $TransactionId
      CustomerNumber: $CustomerNumber
    )
  }
`;

export const dcBankTransactionReportCSV = gql`
  query workerDcBankHistoryCSV(
    $workerId: String
    $TransactionStatusCode: String
    $MinAmount: Int
    $MaxAmount: Int
    $MinDateOfFunds: DateTime
    $MaxDateOfFunds: DateTime
    $MinMoneyReqExpDate: DateTime
    $MaxMoneyReqExpDate: DateTime
    $ClientReferenceNumber: String
    $TransactionId: Int
    $CustomerNumber: String
  ) {
    workerDcBankHistoryCSV(
      workerId: $workerId
      TransactionStatusCode: $TransactionStatusCode
      MinAmount: $MinAmount
      MaxAmount: $MaxAmount
      MinDateOfFunds: $MinDateOfFunds
      MaxDateOfFunds: $MaxDateOfFunds
      MinMoneyReqExpDate: $MinMoneyReqExpDate
      MaxMoneyReqExpDate: $MaxMoneyReqExpDate
      ClientReferenceNumber: $ClientReferenceNumber
      TransactionId: $TransactionId
      CustomerNumber: $CustomerNumber
    )
  }
`;
export default dcBankTransactionReportQuery;
