import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Avatar, Typography } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import { isNil } from "lodash";
import Button from "components/Button/Button";
import Line from "components/Placeholders/Line";
import WorkerPopover from "components/Popovers/WorkerPopover";
import { actions as JobActions, selectors as JobSelectors } from "store/modules/jobs";
import PositionActionMenu from "./PositionActionMenu";
import ReactTableSimple from "components/ReactTable/ReactTableSimple";
const useStyles = makeStyles(() => ({
    addButton: {
        textAlign: 'right'
    }
}))
const PositionTable = ({ id, openDialog, openDeleteDialog }) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const data = useSelector(JobSelectors.getPrivateJobPositions);

    useEffect(() => {
        dispatch(JobActions.fetchPrivateJobPositionWorker(id));
    }, []);

    const columns = [
        {
            Header: "",
            width: 80,
            disableSortBy: true,
            id: "profileImage",
            Cell: ({ row: { original: { fullName, signedProfileImageUrl, vaccinationStatus } } }) => (
                <>
                    <Avatar
                        alt={fullName}
                        src={signedProfileImageUrl}
                        style={vaccinationStatus === "Approved" ? { border: "3px solid #3e938b" } : {}}
                    />
                </>
            ),
        },
        {
            Header: "Worker",
            id: "fullName",
            Cell: ({ row: { original: { fullName, phone, email, city, region } } }) => {
                return (
                    <>
                        <WorkerPopover phone={phone} email={email} city={city} region={region}>
                            <Typography variant="body1">{fullName}</Typography>
                        </WorkerPopover>
                    </>
                );
            },
        },
        {
            Header: "Location",
            id: "location",
            disableSortBy: true,
            Cell: ({ row: { original: { city, region } } }) => {
                return (
                    <>
                        <Typography variant="body1">
                            {!city || !region ? <Line /> : `${city}, ${region}`}
                        </Typography>
                    </>
                );
            },
        },
        {
            Header: "Rating",
            id: "rating",
            Cell: ({ row: { original } }) => {
                return (
                    <>
                        {isNil(original.rating) ? (
                            <Line />
                        ) : (
                            <Typography variant="body1">{original.rating}</Typography>
                        )}
                    </>
                );
            },
        },
        {
            Header: () => (
                <div className={classes.addButton}>
                    <Button
                        label="Add"
                        color="primary"
                        onClick={openDialog}
                    />
                </div>
            ),
            id: "action",
            collapse: true,
            style: { textAlign: "right" },
            Cell: ({ row: { original } }) => {
                return (
                    <div className={classes.addButton}>
                        <PositionActionMenu id={original.id} openDeleteDialog={openDeleteDialog} />
                    </div>
                );
            },
        },
    ];
    return (
        <React.Fragment>
            <ReactTableSimple
                classes="-highlight"
                style={{ marginTop: "16px" }}
                data={data}
                loading={false}
                columns={columns}
            />

        </React.Fragment>
    );
};

export default PositionTable;
