export default {
  SET_LOADING_STATE: "finance/SET_LOADING_STATE",
  SET_LOADING_STATE_BILLING: "billing/SET_LOADING_STATE_BILLING",
  SET_PAYMENT_BATCHES_LIST_DATA: "finance/SET_PAYMENT_BATCHES_LIST_DATA",
  SET_QUEUED_PAYMENT_BATCH: "finance/SET_QUEUED_PAYMENT_BATCH",
  SET_BATCH_DETAILS: "finance/SET_BATCH_DETAILS",
  UPDATE_EMPLOYER_BILLING_WEEK_DATA: "finance/UPDATE_EMPLOYER_BILLING_WEEK_DATA",
  UPDATE_EMPLOYER_BILLING_WEEK_INVOICE_DATA: "finance/UPDATE_EMPLOYER_BILLING_WEEK_INVOICE_DATA",
  SET_WEEKLY_BILLING_PERFORMANCE: "finance/SET_WEEKLY_BILLING_PERFORMANCE",
  SET_BILLING_WEEK_SUMMARY: "finance/SET_BILLING_WEEK_SUMMARY",
  SET_EMPLOYER_BILLING_WEEK_SUMMARY: "finance/SET_EMPLOYER_BILLING_WEEK_SUMMARY",
  SET_BILLING_YEAR_SUMMARY: "finance/SET_BILLING_YEAR_SUMMARY",
  SET_PREVIOUS_BILLING_WEEK_SUMMARY: "finance/SET_PREVIOUS_BILLING_WEEK_SUMMARY",
  SET_EMPLOYER_INVOICEITEMS: "finance/SET_EMPLOYER_INVOICEITEMS",
  SET_EMPLOYER_INVOICES: "finance/SET_EMPLOYER_INVOICES",
  SET_DELETED_INVOICES: "finance/SET_DELETED_INVOICES",
  SET_EMPLOYER_WEEK_SUMMERY: "finance/SET_EMPLOYER_WEEK_SUMMERY",
  SET_EMPLOYER_INVOICE: "finance/SET_EMPLOYER_INVOICE",
  SET_EMPLOYER_QB_INVOICES: "finance/SET_EMPLOYER_QB_INVOICES",
  SET_QB_EMPLOYER: "finance/qb/SET_QB_EMPLOYER",
  SET_QB_COMPANY_INFO: "finance/qb/SET_QB_COMPANY_INFO",
  SET_QB_INVOICE_PDF: "finance/qb/SET_QB_INVOICE_PDF",
  SET_QB_INVOICE: "finance/qb/SET_QB_INVOICE",
  SET_INVOICE_ITEM_PAYROLL: "finance/qb/SET_INVOICE_ITEM_PAYROLL",
  SET_GENERATED_BILLING: "finance/SET_GENERATED_BILLING",
  SET_GENERATED_BILLING_LOADING: "finance/SET_GENERATED_BILLING_LOADING",
  SET_DC_TRANSFERS: "finance/SET_DC_TRANSFERS",
  SET_DC_PAYROLL: "finance/SET_DC_PAYROLL",
  SET_CANCEL_TRANSFER_REASON: "finance/SET_CANCEL_TRANSFER_REASON",
  SET_QB_ITEMS: "finance/SET_QB_ITEMS",
  SET_DC_ACCOUNT: "finance/SET_DC_ACCOUNT",
  SET_QB_PREFERENCES: "finance/SET_QB_PREFERENCES",
  SET_QB_EMPLOYER_PAYMENTS: "finance/SET_QB_EMPLOYER_PAYMENTS",
  SET_DC_PAYROLL_BY_STATUS: "finance/SET_DC_PAYROLL_BY_STATUS",
  SET_QB_INVOICE_ATTACHMENTS: "finance/SET_QB_INVOICE_ATTACHMENTS",
  SET_NEW_QB_INVOICE_NOTE: "finance/SET_NEW_QB_INVOICE_NOTE",
  SET_UPDATE_QB_EMPLOYER_DATA: "finance/SET_UPDATE_QB_EMPLOYER_DATA",
  SET_CREATE_QB_EMPLOYER_DATA: "finance/SET_CREATE_QB_EMPLOYER_DATA",
  SET_QB_TERMS: "finance/SET_QB_TERMS",
  SET_QB_PAYMENT_METHODS: "finance/SET_QB_PAYMENT_METHODS",
  SET_INIT_QB_EMPLOYER_DATA: "finance/SET_INIT_QB_EMPLOYER_DATA",
  SET_PAYMENT_OVERVIEW: "finance/SET_PAYMENT_OVERVIEW",
  SET_DC_PAYROLL_ISSUES: "finance/SET_DC_PAYROLL_ISSUES",
  SET_DC_REQUEST_FOR_FUNDS: "finance/SET_DC_REQUEST_FOR_FUNDS",
  SET_DC_REVERSALS: "finance/SET_DC_REVERSALS",
  SET_PAYMENT_BILLING_COUNT: "finance/SET_PAYMENT_BILLING_COUNT",
  SET_DC_STATUS_TRANSFERS: "finance/SET_DC_STATUS_TRANSFERS",
  UPDATE_TRANSFER_LIST_FILTER: "finance/UPDATE_TRANSFER_LIST_FILTER",
  UPDATE_TRANSFER_LIST_SORT: "finance/UPDATE_TRANSFER_LIST_SORT",
  SET_DC_TRANSFERS_PARAMS: "finance/SET_DC_TRANSFERS_PARAMS",
  SET_TRANSFER_TOTAL_COUNT: "finance/SET_TRANSFER_TOTAL_COUNT",
  SET_DC_TRANSFERS_FILTER: "finance/SET_DC_TRANSFERS_FILTER",
};
