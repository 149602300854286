import ApolloClient from "utils/apollo";
import { actions as MetaActions } from "store/modules/meta";
import types from "./types";
import createJobTemplateMutation from "./graphql/mutations/create-job-template";
import removeJobTemplateMutation from "./graphql/mutations/remove-job-template";
import EditJobTemplateMutation from "./graphql/mutations/edit-job-template";
import fetchJobTemplateQuery from "./graphql/queries/fetch-job-template";
import { preMadeJobTemplateQuery, workTypesQuery } from "./graphql/queries/index";

const setLoadingState = key => value => ({
    type: types.SET_LOADING_STATE,
    payload: { key, value },
});
const setIsTableLoading = setLoadingState("tableLoading");

const setDialogState = key => value => ({
    type: types.SET_DIALOG_STATE,
    payload: { key, value },
});

export const setIsRemoveDialog = setDialogState("removeDialog");

const setJobTemplates = jobTemplates => ({
    type: types.SET_JOB_TEMPLATE_DATA,
    payload: { jobTemplates }
});

const setJobTypeList = jobTypeList => ({
    type: types.SET_JOB_TYPE_LIST,
    payload: { jobTypeList }
});

const setJobTemplate = jobTemplate => ({
    type: types.SET_JOB_TEMPLATE,
    payload: { jobTemplate },
});

export const resetFormData = () => ({
    type: types.RESET_FORM_DATA
});

export const setPostJobTemplates = (field, value) => {
    return {
        type: types.SET_NEW_JOB_TEMPLATE,
        payload: { field, value },
    };
};

export const fetchJobTemplates = () => (dispatch, getState) => {
    // const { jobTemplates } = getState().jobTemplate;
    dispatch(setIsTableLoading(true));
    return ApolloClient.query({
        query: preMadeJobTemplateQuery,
    })
        .then(({ data, errors }) => {
            if (!data && errors.length > 0) {
                throw new Error(errors[0].message);
            }
            else {
                dispatch(setIsTableLoading(false));
                dispatch(setJobTemplates(data.preMadeJobTemplate));
            }
        })
        .catch(e => {
            dispatch(setIsTableLoading(false));
            dispatch(MetaActions.errorToast(`Could not find data: ${e.message}`));
        });
};

export const postJobTemplate = (data) => (dispatch, getState) => {
    const payload = {
        templateName: data.templateName,
        peopleNeeded: data.peopleNeeded || 1,
        street: data.address.street || "",
        city: data.address.city || "",
        region: data.address.region || "",
        country: data.address.country || "",
        postalCode: data.address.postalCode,
        latitude: data.address.lat || 0,
        longitude: data.address.lng || 0,
        description: data.description,
        isBreakPaid: data.isBreakPaid,
        locationInstruction: data.locationInstruction,
        gears: data.gears,
        skills: data.skills,
        breakMins: data.breakMins,
        workTypeId: data.workType.value,
        ...(data.unit ? { unit: data.unit } : {}),
        ...(data.floor ? { floor: data.floor } : {}),
    };
    return ApolloClient.mutate({
        mutation: createJobTemplateMutation,
        variables: { data: payload },
    })
        .then(({ data, errors }) => {
            if (!data && errors.length > 0) {
                throw new Error(errors[0].message);
            }
            return data;
        }).catch((e) => {
            dispatch(MetaActions.errorToast(e.message));
            throw new Error(e.message);
        });
};

export const fetchJobTypeList = () => (dispatch, getState) => {
    return ApolloClient.query({
        query: workTypesQuery,
    })
        .then(({ data, errors }) => {
            if (!data && errors.length > 0) {
                throw new Error(errors[0].message);
            }
            else {
                dispatch(setJobTypeList(data.workTypes));
            }
        })
        .catch(e => {
            dispatch(MetaActions.errorToast(`Could not find data: ${e.message}`));
        });

};

export const removeJobTemplate = (id) => (dispatch) => {
    ApolloClient.mutate({
        mutation: removeJobTemplateMutation,
        variables: { id }
    })
        .then(() => {
            dispatch(setIsRemoveDialog(false));
            dispatch(fetchJobTemplates());
            dispatch(MetaActions.successToast("Job template has been deleted."));
        })
        .catch(e => {
            dispatch(setIsRemoveDialog(false));
            dispatch(
                MetaActions.errorToast(`Could not remove: ${e.message}`),
            );
        });
};

export const fetchJobTemplate = (id) => dispatch => {
    return ApolloClient.query({
        query: fetchJobTemplateQuery,
        variables: {
            id: id,
        },
    })
        .then(({ data, errors }) => {
            if (!data && errors.length > 0) {
                throw new Error(errors[0].message);
            }
            else {
                dispatch(setJobTemplate(data.jobTemplateById));
            }
        })
        .catch(e => {
            dispatch(MetaActions.errorToast(`Could not find data: ${e.message}`));
        });
};

export const editJobTemplate = (JobTemplateId, data) => (dispatch, getState) => {
    const payload = {
        id: JobTemplateId,
        templateName: data.templateName,
        peopleNeeded: data.peopleNeeded || 1,
        street: data.address.street || "",
        city: data.address.city || "",
        region: data.address.region || "",
        country: data.address.country || "",
        postalCode: data.address.postalCode,
        latitude: data.address.lat || 0,
        longitude: data.address.lng || 0,
        description: data.description,
        isBreakPaid: data.isBreakPaid,
        locationInstruction: data.locationInstruction,
        gears: data.gears,
        skills: data.skills,
        breakMins: data.breakMins,
        workTypeId: data.workType.value,
        ...(data.unit ? { unit: data.unit } : {}),
        ...(data.floor ? { floor: data.floor } : {}),
    };
    return ApolloClient.mutate({
        mutation: EditJobTemplateMutation,
        variables: { data: payload },
    })
        .then(({ data, errors }) => {
            if (!data && errors.length > 0) {
                throw new Error(errors[0].message);
            }
            return data;
        })
        .catch((e) => {
            dispatch(MetaActions.errorToast(e.message));
            throw new Error(e.message);
        });
};
