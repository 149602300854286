import { gql } from "@apollo/client";

// $id: ID!
export const getEmployerBillingRatesQuery = gql`
  query employerBillingRates($employerId: String!) {
    employerBillingRates(employerId: $employerId) {
      id
      createdAt
      #   updatedAt
      employerId
      name
      billingLabel
      rateType
      description
      order
      isActive
      baseRate
      baseBillingRate
      wageMultiplier
      billingRateMultiplier
    }
  }
`;

export default getEmployerBillingRatesQuery;
