// HelperMessage
import React from "react";
import PropTypes from "prop-types";
import withStyles from '@mui/styles/withStyles';
import Typography from "@mui/material/Typography";

const styles = theme => ({
    container: {
        flex: 1,
        display: "flex",
        flexDirection: "row",
        margin: `0 ${theme.spacing(2)}`,
        alignItems: "center",
    },
    message: {
        marginLeft: theme.spacing(1),
    },
});

function HelperMessage({ icon, message, caption, classes }) {
    return (
        <div className={classes.container}>
            {icon}
            <div className={classes.message}>
                <Typography variant="body1" color="textSecondary">
                    {message}
                </Typography>
                {caption && (
                    <Typography variant="caption" color="textSecondary">
                        {caption}
                    </Typography>
                )}
            </div>
        </div>
    );
}

HelperMessage.propTypes = {
    classes: PropTypes.object.isRequired,
    icon: PropTypes.node.isRequired,
    message: PropTypes.string.isRequired,
    caption: PropTypes.string,
};

HelperMessage.defaultProps = {
    caption: null,
};

export default withStyles(styles)(HelperMessage);
