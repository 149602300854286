import { gql } from "@apollo/client";

// employerId: ______;
// before: ______;
// after: ______;
// first: ______;
// last: ______;
// canPost: ______;
// isActive: ______;
// isApproved: ______;
// isDeleted: ______;
// like: ______;
// order: ______;

export default gql`
  query employers(
    $first: Int
    $last: Int
    $after: String
    $before: String
    $order: [OrderInput!]
    $like: [FilterInput!]
    $isActive: Boolean
    $isApproved: Boolean
  ) {
    employers(
      first: $first
      last: $last
      after: $after
      before: $before
      order: $order
      like: $like
      isActive: $isActive
      isApproved: $isApproved
      isDeleted: false
    ) {
      totalCount
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        node {
          id
          qbId
          companyName
          firstName
          lastName
          email
          businessPhone
          mobilePhone
          city
          region
          rating
          jobCount
          createdAt
          createdAtUtc
        }
      }
    }
  }
`;
