import * as pagination from "utils/graphql-pagination";
import { paginationSettings } from "./constants";

// Loading selectors
export const getLoadingStateFactory = loader => state => {
  return state.admins.loading[loader] || false;
};
export const getPermissionListPaginationData = state => {
  return {
    ...state.admins[pagination.getPageInfoAttr(paginationSettings.permissionList)],
  };
};
export const getIsAdminsLoading = getLoadingStateFactory("admins");
export const getIsSaveAdminsLoading = getLoadingStateFactory("adminsSave");
export const getIsOpenAddDialog = getLoadingStateFactory("isOpenAddDialog");
export const getIsOpenResetPasswordDialog = getLoadingStateFactory("isOpenResetPasswordDialog");
export const getIsUpdatePermissionLoading = getLoadingStateFactory("adminPermissionUpdate");
export const getIsAdminPermissionLoading = getLoadingStateFactory("adminPermission");
export const getIsAssignPermissionDialog = getLoadingStateFactory("isOpenAssignPermissionDialog");
export const getUnAssignedPermissionsLoading = getLoadingStateFactory("unAssignedPermissions");
/*
Admin Data
*/

export const getAdminList = state => state.admins.adminList;
export const getAdminDetail = state => state.admins.adminDetail;
export const getAdminPermissionList = state => state.admins.adminPermissions;
export const getAllPermissionList = state => state.admins.allPermissions;
export const getUnAssignedAdminPermissionList = state => state.admins.unAssignedAdminPermissions;
