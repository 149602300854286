/*eslint-disable*/
import React, { useState } from "react";

import withStyles from '@mui/styles/withStyles';
import MUIDialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Typography from "@mui/material/Typography";
import Button from "components/Button/Button";
import IconButton from "@mui/material/IconButton";
import MoreVert from "@mui/icons-material/MoreVert";
import Grid from "@mui/material/Grid";
import { CloudDownloadRounded } from "@mui/icons-material";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
const billingDialogStyles = ({ spacing }) => ({
  header: {
    paddingTop: spacing(1),
    paddingBottom: spacing(1),
    paddingLeft: spacing(2),
    paddingRight: spacing(2),
    marginLeft: spacing(1),
    marginRight: spacing(1),
    // alignItems: "flex-start",
  },
  core: {
    paper: {
      paddingTop: spacing(1),
      paddingBottom: spacing(1),
      paddingLeft: spacing(0),
      paddingRight: spacing(0),
      marginLeft: spacing(1),
      marginRight: spacing(1),
      // alignItems: "center",
      width: spacing(60),
      overflowY: "auto",
    },
    // scrollPaper: {},
    paperScrollPaper: {
      marginLeft: spacing(1),
      marginRight: spacing(1),
    },
    // paperWidthXs: {},
    // paperWidthMd: {},
    // paperFullWidth: {},
    // paperFullScreen: {},
  },

  title: {
    padding: `${spacing(4)} 0 ${spacing(0)} 0`,
    textAlign: "left",
    // alignItems: "flex-end",
  },
  content: {
    padding: `${spacing(0)} ${spacing(2)} ${spacing(0)} ${spacing(2)}`,
    overflow: "auto",
    overflowY: "unset",
    width: "100%",
  },
  footer: {
    margin: 0,
    marginLeft: spacing(1),
    marginRight: spacing(1),
  },
  topActions: {
    margin: 0,
    justifyContent: "flex-end",
  },
});

function PayrollDialog(props) {
  const { classes, cancelText, disabled, confirmText, title, description, icon, onConfirm, onCancel, onClose, open, disableBackdropClick, alertType, maxWidth, children, table, dialogProps } = props;

  const ActionsControl = () => (
    <div className={classes.topActions}>
      <Button label={"Delete Invoice Version"} onClick={onCancel} color="transparent" />
      <Button startIcon={<CloudDownloadRounded color="secondary" />} label={"Download Pdf"} onClick={onCancel} />
      <Button label={"Close"} onClick={onCancel} color="transparent" />
      {/* <Button disabled={disabled} label={"Update"} onClick={onConfirm} color="secondary" /> */}
    </div>
  );

  const HeaderComponent = () => (
    <Grid container item xs={12} className={classes.header}>
      <Grid container item md={6}>
        <Grid container item>
          <Typography style={{ color: "#aebecd", fontWeight: 700, fontSize: 24, fontFamily: "Roboto" }}>Left</Typography>
        </Grid>
      </Grid>
      <Grid container item md={6}>
        <Grid container item>
          <Typography style={{ color: "#aebecd", fontWeight: 700, fontSize: 24, fontFamily: "Roboto" }}>Right</Typography>
        </Grid>
      </Grid>
    </Grid>
  );

  const FooterActionsControl = () => (
    <DialogActions className={classes.footer}>
      <React.Fragment>
        <Button label={cancelText || "Close"} onClick={onCancel} color="transparent" />
        {/* <Button disabled={disabled} label={confirmText || "Update"} onClick={onConfirm} color="secondary" /> */}
      </React.Fragment>
    </DialogActions>
  );

  return (
    <MUIDialog className={classes.core} fullScreen={false} open={open} maxWidth={maxWidth ? maxWidth : "md"} disableBackdropClick={disableBackdropClick} onClose={onClose}>
      <DialogTitle className={classes.title}>
        <Typography variant="h5" align="center">
          {dialogProps && dialogProps.invoice && dialogProps.invoice
            ? ` ${dialogProps.invoice.employer ? dialogProps.invoice.employer.companyName : ""} Week ${dialogProps.invoice.invoiceWeek} Invoice`
            : ""}
        </Typography>
      </DialogTitle>
      <DialogContent className={classes.content}>
        {/* <HeaderComponent /> */}
        {/* <ActionsControl /> */}
        {table ? table : null}
      </DialogContent>
      <FooterActionsControl />
    </MUIDialog>
  );
}

export default withStyles(billingDialogStyles)(PayrollDialog);
