import React, { useCallback, useState } from "react";
import { useHistory } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

import { Receipt } from "@mui/icons-material";
import MoreVert from "@mui/icons-material/MoreVert";
import { overviewTableActionsStyles } from "views/Finance/helpers";

function OverviewTableActions({ position, showPayrollSummary, toggleReviewingPayroll }) {
  const classes = overviewTableActionsStyles();
  const history = useHistory();
  const [anchorEl, setAnchorEl] = useState(null);
  const menuId = `action-menu-`;

  const openMenu = useCallback(e => {
    setAnchorEl(e.currentTarget);
  }, []);

  const closeMenu = useCallback(() => {
    setAnchorEl(null);
  }, []);

  return (
    <>
      <IconButton
        aria-controls={menuId}
        aria-haspopup="true"
        onClick={openMenu}
        size="small"
        disableRipple={false}>
        <MoreVert color="primary" />
      </IconButton>
      <Menu
        id={menuId}
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={closeMenu}>
        {showPayrollSummary ? (
          <MenuItem
            onClick={() => {
              toggleReviewingPayroll(position.jobId);
              //   closeMenu();
            }}>
            <Receipt className={classes.icon} color="action" />
            Create Payroll
          </MenuItem>
        ) : (
          ""
        )}
        <MenuItem
          onClick={() => {
            history.push(`/jobs/${position.jobId}`);
            closeMenu();
          }}>
          <Receipt className={classes.icon} color="action" />
          View Job Details
        </MenuItem>
      </Menu>
    </>
  );
}

export default OverviewTableActions;
