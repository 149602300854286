import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import Grid from "@mui/material/Grid";
import withStyles from '@mui/styles/withStyles';
import Typography from "@mui/material/Typography";
import { JobPermissions } from "helpers/constants/permissions";
import Can from "components/Authorization/Can";
import { formatMoney } from "utils/stringUtils";

const styles = ({ spacing }) => ({
  header: {},
  label: {
    lineHeight: 1.5,
    marginRight: spacing(2),
    "& + span": {
      fontWeight: 500,
    },
  },
});

function CategoryTab({
  classes,
  className,
  title,
  count,
  bookings,
  revenue,
  hours,
  headerClass,
  labelClass,
}) {
  return (
    <Grid className={className} container direction="column" alignItems="flex-start">
      <Typography
        className={classNames(classes.header, headerClass)}
        variant="overline"
        color="textSecondary">
        {title}
      </Typography>
      <Typography variant="h4">{count}</Typography>
      <Can perform={JobPermissions.read.systemFinancials.value}>
        {allowed =>
          allowed ? (
            <React.Fragment>
              <Grid container item justifyContent="space-between" alignItems="center">
                <Typography
                  className={classNames(classes.label, labelClass)}
                  variant="overline"
                  color="textSecondary">
                  Hours
                </Typography>
                <Typography variant="caption">{hours}</Typography>
              </Grid>
              <Grid container item justifyContent="space-between" alignItems="center">
                <Typography
                  className={classNames(classes.label, labelClass)}
                  variant="overline"
                  color="textSecondary">
                  Booked
                </Typography>
                <Typography variant="caption">{formatMoney(bookings || 0)}</Typography>
              </Grid>
              <Grid container item justifyContent="space-between" alignItems="center">
                <Typography
                  className={classNames(classes.label, labelClass)}
                  variant="overline"
                  color="textSecondary">
                  Revenue
                </Typography>
                <Typography variant="caption">{formatMoney(revenue || 0)}</Typography>
              </Grid>
            </React.Fragment>
          ) : null
        }
      </Can>
    </Grid>
  );
}

CategoryTab.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  headerClass: PropTypes.string,
  labelClass: PropTypes.string,
  title: PropTypes.string,
  count: PropTypes.number,
  bookings: PropTypes.number,
  revenue: PropTypes.number,
  hours: PropTypes.number,
};

CategoryTab.defaultProps = {
  className: null,
  headerClass: null,
  labelClass: null,
  title: "",
  count: 0,
  bookings: 0,
  revenue: 0,
  hours: 0,
};

export default withStyles(styles)(CategoryTab);
