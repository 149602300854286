/* eslint-disable react/no-find-dom-node */
import React from "react";
import ReactDOM from "react-dom";
import NumberFormat from "react-number-format";
import { Button as Btn } from "@mui/material";
import DialogActions from "@mui/material/DialogActions";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { Form, Formik } from "formik";
import _ from "lodash";
import { customRateStyles } from "views/Employer/helpers";
import * as yup from "yup";
import Dialog from "components/Dialog/Dialog";
import EmployerPopover from "../EmployerPopover";
const isMobile = false;
function CreateCustomRateDialog(props) {
  const { open, onClose, onConfirm, onCancel } = props;
  const classes = customRateStyles();

  const controlPadding = {
    leftColumn: { paddingRight: isMobile ? 0 : 4 },
    rightColumn: { paddingLeft: isMobile ? 0 : 4 },
  };
  const component = `Create a new custom rate.`;
  const popperTitle = `Create a new custom rate.`;
  const copy = `Create a new custom rate.`;

  const RateNumberFormatCustom = props => {
    const { inputRef, onChange, ...other } = props;

    return (
      <NumberFormat
        {...other}
        ref={ref => inputRef(ReactDOM.findDOMNode(ref))}
        onValueChange={values => {
          onChange({
            target: {
              value: values.value,
            },
          });
        }}
        decimalScale={2}
        fixedDecimalScale
        prefix="Base Rate x "
      />
    );
  };
  const NumberFormatCustom = props => {
    const { onChange } = props;

    return (
      <NumberFormat
        placeholder="Number Format Input looses focus"
        isNumericString={true}
        // {...other}
        // ref={ref => inputRef(ReactDOM.findDOMNode(ref))}
        onValueChange={values => {
          onChange({
            target: {
              value: values.value,
            },
          });
        }}
        decimalScale={2}
        fixedDecimalScale
        prefix="$"
      />
    );
  };
  const RenderCreateRateForm = () => (
    <Grid container item xs={12} style={{ width: "100%", marginTop: 0 }}>
      <Formik
        initialValues={{
          name: " name test",
          billingRateMultiplier: 1,
          wageMultiplier: 1,
          isActive: true,
          rateType: "REG",
          baseRate: 15,
          baseBillingRate: 25,
          billingLabel: " name test",
          description: " name test",
        }}
        onSubmit={(values, actions) => {
          //   handleUpdateQbEmployer(values);

          actions.setSubmitting(false);
        }}
        validationSchema={yup.object().shape({
          name: yup.string().required("Required"),
          billingLabel: yup.string().required("Required"),
          description: yup.string().required("Required"),
        })}>
        {props => {
          const {
            values,
            touched,
            errors,
            dirty,
            isSubmitting,
            handleChange,
            handleBlur,
            setFieldValue,
            handleReset,
          } = props;

          return (
            <Form>
              <Grid
                container
                item
                xs={12}
                style={{ paddingLeft: 0, paddingRight: 0, alignItems: "flex-start" }}>
                <Grid
                  container
                  item
                  xs={12}
                  style={{
                    paddingLeft: 0,
                    paddingRight: 0,

                    alignItems: "flex-start",
                  }}>
                  <Typography variant="h6" align="left" style={{ width: "100%", marginTop: 1 }}>
                    Quickbooks Manager
                  </Typography>
                </Grid>
                <Grid container item xs={12} md={6} style={controlPadding.leftColumn}>
                  <TextField
                    id="name"
                    name="name"
                    label="Rate Name"
                    placeholder="Rate Name"
                    className={classes.textField}
                    value={values.name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    fullWidth
                    error={errors.name && touched.name}
                    helperText={errors.name && touched.name && errors.name}
                    margin="normal"
                    min={2}
                  />
                </Grid>
                <Grid container item xs={12} md={6} style={controlPadding.rightColumn}>
                  <TextField
                    id="billingLabel"
                    name="billingLabel"
                    label="Rate Billing Label"
                    placeholder="Rate Name"
                    className={classes.textField}
                    value={values.billingLabel}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    fullWidth
                    error={errors.billingLabel && touched.billingLabel}
                    helperText={errors.billingLabel && touched.billingLabel && errors.billingLabel}
                    margin="normal"
                    min={2}
                  />
                </Grid>

                <Grid container item xs={12}>
                  <EmployerPopover
                    component={component}
                    title={popperTitle}
                    copy={copy}
                    width={"100%"}
                    position={"top"}
                    confirmLabel={"component"}>
                    <TextField
                      id="description"
                      name="description"
                      label="description"
                      placeholder="describe the rule"
                      className={classes.textField}
                      value={values.description}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      fullWidth
                      error={errors.description && touched.description}
                      helperText={errors.description && touched.description && errors.description}
                      margin="normal"
                      multiline
                      rows={4}
                    />
                  </EmployerPopover>
                </Grid>

                <Grid container item xs={12} md={4} style={controlPadding.leftColumn}>
                  <NumberFormat
                    // {...other}
                    name="baseRate"
                    value={values.baseRate}
                    label="Base Hourly Pay Rate"
                    placeholder="Base Hourly Pay Rate"
                    customInput={inputProps =>
                      console.log(inputProps) || (
                        <TextField
                          value={values.baseRate}
                          {...inputProps}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                      )
                    }
                    onValueChange={val => {
                      setFieldValue("baseRate", val.floatValue);
                    }}
                    // ref={ref => inputRef(ReactDOM.findDOMNode(ref))}
                    // onValueChange={val => setFieldValue("baseRate", val.value)}
                    //   onValueChange={values => {
                    //     onChange({
                    //       target: {
                    //         value: values.value,
                    //       },
                    //     });
                    //   }}
                    decimalScale={2}
                    fixedDecimalScale
                    prefix="$"
                  />

                  {/* <TextField
                      id="baseRate"
                      name="baseRate"
                      label="Base Hourly Pay Rate"
                      placeholder="Base Hourly Pay Rate"
                      className={classes.textField}
                      value={values.baseRate}
                      onChange={handleChange}
                      type="number"
                      onBlur={handleBlur}
                      fullWidth
                      error={errors.baseRate && touched.baseRate}
                      helperText={errors.baseRate && touched.baseRate && errors.baseRate}
                      margin="normal"
                      InputProps={{
                        fullWidth: true,
                        inputComponent: NumberFormatCustom,
                        min: 14,
                        step: 0.5,
                        value: values.baseRate,
                      }}
                    /> */}
                </Grid>

                <Grid container item xs={12} md={4} style={controlPadding.rightColumn}>
                  <TextField
                    id="baseBillingRate"
                    name="baseBillingRate"
                    className={classes.textField}
                    value={values.baseBillingRate}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    fullWidth
                    error={errors.baseBillingRate && touched.baseBillingRate}
                    helperText={
                      errors.baseBillingRate && touched.baseBillingRate && errors.baseBillingRate
                    }
                    margin="normal"
                    type="number"
                    label="Base Billing Rate"
                    placeholder="Base Billing Rate"
                    InputProps={{
                      fullWidth: true,
                      inputComponent: NumberFormatCustom,
                      min: 14,
                      step: 0.5,
                    }}
                    //       defaultValue={24.95}
                  />
                </Grid>

                <Grid container item xs={12} md={4} style={controlPadding.rightColumn}>
                  <TextField
                    id="wageMultiplier"
                    name="wageMultiplier"
                    className={classes.textField}
                    value={values.wageMultiplier}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    fullWidth
                    error={errors.wageMultiplier && touched.wageMultiplier}
                    helperText={
                      errors.wageMultiplier && touched.wageMultiplier && errors.wageMultiplier
                    }
                    margin="normal"
                    type="number"
                    label="Wage Multiplier"
                    placeholder="Wage Multiplier"
                    InputProps={{
                      fullWidth: true,
                      inputComponent: RateNumberFormatCustom,
                      min: 1,
                      step: 0.25,
                    }}
                    //     defaultValue={1}
                  />
                </Grid>
                <Grid container item xs={12} md={4} style={controlPadding.leftColumn}>
                  <TextField
                    select
                    name="rateType"
                    id="rateType"
                    label="Rate Type"
                    className={classes.textField}
                    value={values.rateType}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    fullWidth
                    error={errors.rateType && touched.rateType}
                    helperText={errors.rateType && touched.rateType && errors.rateType}
                    margin="normal"
                    placeholder="Rate Name"
                    //      defaultValue={"REG"}
                  >
                    <MenuItem value={"REG"}>Regular Rate</MenuItem>
                    <MenuItem value={"OT"}>Overtime Rate</MenuItem>
                    <MenuItem value={"DT"}>Doubletime Rate</MenuItem>
                  </TextField>
                </Grid>

                <Grid
                  container
                  item
                  xs={12}
                  md={4}
                  style={{ ...controlPadding.rightColumn, ...controlPadding.leftColumn }}>
                  <EmployerPopover
                    component={component}
                    title={popperTitle}
                    copy={copy}
                    width={"100%"}
                    position={"top"}
                    confirmLabel={"component"}>
                    <TextField
                      select
                      name="isActive"
                      id="isActive"
                      label="Status"
                      className={classes.textField}
                      value={values.isActive}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      fullWidth
                      error={errors.isActive && touched.isActive}
                      helperText={errors.isActive && touched.isActive && errors.isActive}
                      margin="normal"
                      placeholder="Is Active">
                      <MenuItem value={true}>Active</MenuItem>
                      <MenuItem value={false}>Non Taxable</MenuItem>
                    </TextField>
                  </EmployerPopover>
                </Grid>

                <Grid container item xs={12} md={4} style={controlPadding.rightColumn}>
                  <EmployerPopover
                    component={component}
                    title={popperTitle}
                    copy={copy}
                    width={"100%"}
                    position={"top"}
                    confirmLabel={"component"}>
                    <TextField
                      id="billingRateMultiplier"
                      name="billingRateMultiplier"
                      placeholder="Billing Rate Multiplier"
                      label="Billing Rate Multiplier"
                      className={classes.textField}
                      value={values.billingRateMultiplier}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      fullWidth
                      error={errors.billingRateMultiplier && touched.billingRateMultiplier}
                      helperText={
                        errors.billingRateMultiplier &&
                        touched.billingRateMultiplier &&
                        errors.billingRateMultiplier
                      }
                      margin="normal"
                      InputProps={{
                        fullWidth: true,
                        inputComponent: RateNumberFormatCustom,
                        min: 1,
                        step: 0.25,
                      }}
                      //     defaultValue={1}
                    />
                  </EmployerPopover>
                </Grid>

                <DialogActions
                  className={classes.footer}
                  style={{ width: "100%", textAlign: "center" }}>
                  <Btn
                    type="button"
                    className="outline"
                    onClick={handleReset}
                    disabled={!dirty || isSubmitting}>
                    Reset
                  </Btn>
                  <Btn
                    variant="contained"
                    color="secondary"
                    type="submit"
                    disabled={!_.isEmpty(errors)}>
                    Update Quickbooks Profile
                  </Btn>
                </DialogActions>

                <pre style={{ textAlign: "left" }}>{JSON.stringify(errors, null, 2)}</pre>
              </Grid>
            </Form>
          );
        }}
      </Formik>
    </Grid>
  );

  return <>
    <Dialog
      open={open}
      title={`Create A New Custom Rate`}
      description={`Create A New Custom Rate`}
      confirmText={"Create Custom Rate"}
      size="sm"
      onClose={onClose}
      onConfirm={onConfirm}
      onCancel={onCancel}
      alertType="form"
      modalType="editEmployer">
      <RenderCreateRateForm />
    </Dialog>
  </>;
}

export default CreateCustomRateDialog;
