import React, { useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import makeStyles from '@mui/styles/makeStyles';
import { PaymentConstants } from "helpers/constants/index";
import isEmpty from "lodash/isEmpty";
import Dialog from "components/Dialog/Dialog";
import { calculateMargins, calculateMinimumTargetPrice } from "utils/money";
import { formatMoney } from "utils/stringUtils";
import { actions as JobDetailActions, selectors as JobDetailSelectors } from "store/modules/jobDetails";
import CurrencyInput from "./CurrencyInput";

const useStyles = makeStyles(({ spacing }) => ({
  container: {
    marginTop: spacing(2),
  },
  inputContainer: {
    minHeight: 70,
  },
}));

function EditRatesDialog(props) {
  const { open, onClose, onCancel, position } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const [newPayRate, setNewPayRate] = useState(position.payRate || 0);
  const [newBillingRate, setNewBillingRate] = useState(position.billingRate || 0);
  const updatedPosition = useSelector(JobDetailSelectors.getUpdatedPosition(position.id));

  if (isEmpty(updatedPosition)) {
    return null;
  }

  const { percentage, dollars } = calculateMargins(newPayRate, newBillingRate);
  const tooltip = `Margin/hr: ${formatMoney(dollars)} (${percentage}%)`;
  const minTargetPrice = calculateMinimumTargetPrice(newPayRate);

  const onPayRateChange = value => {
    setNewPayRate(value);
  };

  const onBillingRateChange = value => {
    setNewBillingRate(value);
  };

  const handleClose = () => {
    dispatch(JobDetailActions.resetPositionUpdates());
    onClose();
  };

  const handleCancel = () => {
    dispatch(JobDetailActions.resetPositionUpdates());
    onCancel();
  };

  const canSave = newPayRate >= PaymentConstants.MINIMUM_WAGE;
  const handleUpdateRates = () => {
    dispatch(JobDetailActions.editWorkerRates(position.id, newPayRate, newBillingRate));
  };

  return (
    <Dialog
      open={open}
      title={`Edit ${position.fullName}'s rate`}
      description={null}
      onClose={handleClose}
      onConfirm={handleUpdateRates}
      onCancel={handleCancel}
      confirmText="Update"
      alertType="question"
      icon="local_atm"
      disabled={!canSave}>
      <Grid container direction="row" alignItems="center" className={classes.container}>
        <Grid container direction="row" alignItems="center">
          <Grid item xs={12} className={classes.inputContainer}>
            <CurrencyInput
              label="Pay rate"
              minValue={PaymentConstants.MINIMUM_WAGE}
              minValueHelperText={`Minimum wage is: ${formatMoney(PaymentConstants.MINIMUM_WAGE)}`}
              value={newPayRate}
              onChange={onPayRateChange}
              tooltip={tooltip}
            />
          </Grid>
          {/* <Grid item xs={6} className={classes.inputContainer} style={{ paddingLeft: 6 }}>
            <CurrencyInput
              label="Billing Rate"
              minValue={minTargetPrice}
              minValueHelperText={`Minimum billing rate: ${formatMoney(minTargetPrice)}`}
              value={newBillingRate}
              onChange={onBillingRateChange}
              tooltip={tooltip}
            />
          </Grid> */}
        </Grid>
      </Grid>
    </Dialog>
  );
}

EditRatesDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  position: PropTypes.object,
};

export default EditRatesDialog;
