/* eslint-disable no-unused-vars */
import ApolloClient from "utils/apollo";

import { fetchEmployer } from "store/modules/employerDetails/actions";

import { actions as MetaActions } from "store/modules/meta";
// import * as FormData  from 'form-data'
import {
    AddInvoiceNoteMutation,
    addQbCustomerByIdMutation,
    commitInvoiceToQuickbooksMutation,
    createQbCustomerMutation,
    createQbInvoiceMutation,
    deleteQbInvoiceAttachmentMutation,
    deleteQbInvoiceMutation,
    toggleAttachAttachmentToInvoiceMutation,
    updateQbCustomerMutation,
} from "../graphql/mutations";
import { findPayrollByPositionIdQuery } from "../graphql/queries/billing.weeklyBilling";
import {
    createTimesheetQuery,
    employerQbInvoicesQuery,
    getQbAttachablesQuery,
    getQbInvoicePdfQuery,
    getQbPaymentMethodsQuery,
    getQbTermsQuery,
    qbCompanyInfoQuery,
    qbCustomerQuery,
    qbEmployerPaymentsQuery,
    qbInvoiceQuery,
    qbInvoicesQuery,
    qbItemsQuery,
    qbPreferencesQuery,
    reportCustomerBalanceDetailQuery,
    sendQbInvoicePdfQuery,
    validateQbCustomerIdQuery,
} from "../graphql/queries/qb";
import types from "../types";

const setEmployerQbInvoices = data => ({ type: types.SET_EMPLOYER_QB_INVOICES, payload: data });
const setQbEmployer = data => ({ type: types.SET_QB_EMPLOYER, payload: data });
const setQbCompanyInfo = data => ({ type: types.SET_QB_COMPANY_INFO, payload: data });
export const setInvoicePdf = data => ({ type: types.SET_QB_INVOICE_PDF, payload: data });
const setQbInvoice = data => ({ type: types.SET_QB_INVOICE, payload: data });
const setQbItems = data => ({ type: types.SET_QB_ITEMS, payload: data });
const setQbEmployerPayments = data => ({ type: types.SET_QB_EMPLOYER_PAYMENTS, payload: data });
const setQbPreferences = data => ({ type: types.SET_QB_PREFERENCES, payload: data });
const setInvoiceItemPayroll = data => ({ type: types.SET_INVOICE_ITEM_PAYROLL, payload: data });
const setInvoiceAttachments = data => ({ type: types.SET_QB_INVOICE_ATTACHMENTS, payload: data });
const setQbTerms = data => ({ type: types.SET_QB_TERMS, payload: data });
const setQbPaymentMethods = data => ({ type: types.SET_QB_PAYMENT_METHODS, payload: data });
export const setInvoiceNote = data => ({ type: types.SET_NEW_QB_INVOICE_NOTE, payload: data });

export const setInitQbCustomer = data => ({ type: types.SET_INIT_QB_EMPLOYER_DATA, payload: data });

export const validateQbCustomerId = qbId => async dispatch =>
  ApolloClient.query({
    query: validateQbCustomerIdQuery,
    variables: { qbId: qbId.trim().toString() },
  })
    .then(({ data }) => data.validateQbCustomerId)
    .catch(e =>
      dispatch(
        MetaActions.errorToast(
          `Failed to fetch this Employer's Quickbooks profile. (${e.message})`,
        ),
      ),
    );

export const addQbCustomerById = (qbId, employerId) => async dispatch =>
  ApolloClient.mutate({
    mutation: addQbCustomerByIdMutation,
    variables: { qbId: qbId.trim().toString(), employerId },
  })
    .then(({ data }) => {
      dispatch(MetaActions.successToast(`Invoice  successfully commited to Quickbooks}`));
      dispatch(fetchQbEmployer(qbId.trim().toString()));
      dispatch(fetchEmployer(employerId));
      console.log("addQbCustomerById", data.addQbCustomerById);
      return data.addQbCustomerById;
    })
    .catch(e =>
      dispatch(
        MetaActions.errorToast(
          `Failed to fetch this Employer's Quickbooks profile. (${e.message})`,
        ),
      ),
    );

export const initQbCustomer = data => async (dispatch, getState) => {
  let companyName,
    id,
    firstName,
    lastName,
    title,
    email,
    mobilePhone,
    businessPhone,
    street,
    city,
    postalCode;

  if (data) {
    companyName = data.companyName;
    id = data.id;
    firstName = data.firstName;
    lastName = data.lastName;
    title = data.title && data.title < 17 ? data.title : "Main Contact";
    email = data.email;
    mobilePhone = data.mobilePhone;
    businessPhone = data.businessPhone;
    street = data.street;
    city = data.city;

    postalCode = data.postalCode;
  } else {
    companyName = getState().employerDetails.employer.companyName;
    id = getState().employerDetails.employer.id;
    firstName = getState().employerDetails.employer.firstName;
    lastName = getState().employerDetails.employer.lastName;
    title =
      getState().employerDetails.employer.title &&
      getState().employerDetails.employer.title.length < 17
        ? getState().employerDetails.employer.title
        : "Main Contact";
    email = getState().employerDetails.employer.email;
    mobilePhone = getState().employerDetails.employer.mobilePhone;
    businessPhone =
      getState().employerDetails.employer.businessPhone ||
      getState().employerDetails.employer.businessPhone;
    street = getState().employerDetails.employer.street;
    city = getState().employerDetails.employer.city;

    postalCode = getState().employerDetails.employer.postalCode;
  }

  if (companyName) {
    const newCustomer = {
      employerId: id || null,
      SalesTermRef: "11",
      CurrencyRef: "CAD",
      PaymentMethodRef: "10",
      displayName: companyName || null,
      companyName: companyName || null,
      PrintOnCheckName: companyName || null,
      FullyQualifiedName: companyName || null,
      GivenName: firstName || null,
      FamilyName: lastName || null,
      Title: title || null,
      PrimaryPhone: businessPhone || null,
      AlternatePhone: mobilePhone || null,
      Mobile: mobilePhone || null,
      phone: businessPhone || null,
      Notes: "note\nadded note",
      email: email || null,
      PreferredDeliveryMethod: "Email",
      Taxable: false,
      BillWithParent: false,
      Line1: street || null,
      Line2: city || null,
      City: city || null,
      CountrySubDivisionCode: "British Columbia",
      PostalCode: postalCode || null,
      BillingAddress: {
        Line1: street || null,
        Line2: city || null,
        City: city || null,
        CountrySubDivisionCode: "British Columbia",
        PostalCode: postalCode || null,
      },
    };

    return await dispatch(createQbCustomer(newCustomer));
  }
};

export const saveQbCustomer = data => async (dispatch, getState) => {
  const { qbEmployer } = getState().finance;
  const payload = {
    ...data,
    Active: "true",
    qbId: qbEmployer.id,
    sparse: true,
    SyncToken: qbEmployer.SyncToken,
    billingAddressId: qbEmployer.BillingAddress.Id,
  };
  return ApolloClient.mutate({ mutation: updateQbCustomerMutation, variables: { data: payload } })
    .then(({ data }) => {
      dispatch(fetchQbEmployer(qbEmployer.id));
      dispatch(MetaActions.successToast(`Employer's Quickbooks Profile Updated.`));
      return data.createQbInvoice;
    })
    .catch(e =>
      dispatch(
        MetaActions.errorToast(`Failed To Update Employer's Quickbooks Profile.(${e.message})`),
      ),
    );
};
export const updateQbCustomer = () => async (dispatch, getState) => {
  const { qbEmployerUpdateData, qbEmployer } = getState().finance;
  const payload = {
    ...qbEmployerUpdateData,
    qbId: qbEmployer.id,
    sparse: true,
    SyncToken: qbEmployer.SyncToken,
    billingAddressId: qbEmployer.BillingAddress.Id,
  };
  return ApolloClient.mutate({ mutation: updateQbCustomerMutation, variables: { data: payload } })
    .then(({ data }) => {
      dispatch(fetchQbEmployer(qbEmployer.id));
      dispatch(MetaActions.successToast(`Employer's Quickbooks Profile Updated.`));
      return data.createQbInvoice;
    })
    .catch(e =>
      dispatch(
        MetaActions.errorToast(`Failed To Update Employer's Quickbooks Profile.(${e.message})`),
      ),
    );
};
export const createQbCustomer = data => async dispatch => {
  const employerId = data.employerId;
  return ApolloClient.mutate({
    mutation: createQbCustomerMutation,
    variables: { data },
  })
    .then(({ data }) => {
      dispatch(MetaActions.successToast(`Invoice  successfully commited to Quickbooks}`));
      dispatch(fetchQbEmployer(data.createQbCustomer.id));
      dispatch(fetchEmployer(employerId));
      return data.createQbCustomer;
    })
    .catch(e =>
      dispatch(MetaActions.errorToast(`Failed to create Quickbooks customer. (${e.message})`)),
    );
};

//
export const fetchQbEmployer = qbId => async dispatch =>
  ApolloClient.query({ query: qbCustomerQuery, variables: { qbId } })
    .then(({ data }) => {
      dispatch(setQbEmployer(data.qbCustomer));
      dispatch(fetchQbTerms());
      dispatch(fetchQbPaymentMethods());
    })
    .catch(e =>
      dispatch(
        MetaActions.errorToast(
          `Failed to fetch this Employer's Quickbooks profile. (${e.message})`,
        ),
      ),
    );

export const updateQbPreferences = (type, value) => async (dispatch, getState) => {
  const qbPrefs = getState().finance.qbPreferences;

  const payload = {
    SyncToken: qbPrefs.SyncToken,
    sparse: false,
    [type]: {
      [value]: !qbPrefs[type][value],
    },
    Id: qbPrefs.Id,
  };

  //   return ApolloClient.query({
  //     query: commitInvoiceToQuickbooksMutation,
  //     variables: { employerId, invoiceId },
  //   })
  //     .then(({ data }) => {
  //       dispatch(
  //         MetaActions.successToast(`Invoice ${invoiceId} successfully commited to Quickbooks})`)
  //       );
  //     })
  //     .catch(e => dispatch(MetaActions.errorToast(`Failed send invoice  . (${e.message})`)));
};
//
export const setUpdateQbEmployerData = (field, value) => {
  if (field === "phone") {
    value = value.replace(/\D/g, "");
  }

  return {
    type: types.SET_UPDATE_QB_EMPLOYER_DATA,
    payload: { field, value },
  };
};

export const setCreateQbEmployerData = (field, value) => {
  if (field === "phone") {
    value = value.replace(/\D/g, "");
  }

  return {
    type: types.SET_CREATE_QB_EMPLOYER_DATA,
    payload: { field, value },
  };
};

export const fetchQbPaymentMethods = () => async dispatch =>
  ApolloClient.query({ query: getQbPaymentMethodsQuery, variables: {} })
    .then(({ data }) => dispatch(setQbPaymentMethods(data.getQbPaymentMethods)))
    .catch(e => dispatch(MetaActions.errorToast(`An Error Occurred -   . (${e.message})`)));

export const fetchQbTerms = () => async dispatch =>
  ApolloClient.query({ query: getQbTermsQuery, variables: {} })
    .then(({ data }) => dispatch(setQbTerms(data.getQbTerms)))
    .catch(e => dispatch(MetaActions.errorToast(`An Error Occurred -   . (${e.message})`)));

export const fetchQbEmployerPayments = qbId => async dispatch =>
  ApolloClient.query({ query: qbEmployerPaymentsQuery, variables: { qbId } })
    .then(({ data }) => dispatch(setQbEmployerPayments(data.qbCustomerPayments.payments)))
    .catch(e =>
      dispatch(
        MetaActions.errorToast(
          `Failed to fetch this Employer's Quickbooks payment history. (${e.message})`,
        ),
      ),
    );

export const fetchEmployerQuickbooksInvoices = () => async dispatch =>
  ApolloClient.query({ query: qbInvoicesQuery, variables: { params: { fetchAll: true } } })
    .then(({ data }) => dispatch(setEmployerQbInvoices(data.qbInvoices)))
    .catch(e =>
      dispatch(MetaActions.errorToast(`Failed to fetch employer invoices. (${e.message})`)),
    );

export const fetchQuickbooksPreferences = () => async dispatch =>
  ApolloClient.query({ query: qbPreferencesQuery, variables: {} })
    .then(({ data }) => dispatch(setQbPreferences(data.qbPreferences)))
    .catch(e => dispatch(MetaActions.errorToast(`An Error Occurred -   . (${e.message})`)));

export const fetchQuickbooksItems = () => async dispatch =>
  ApolloClient.query({ query: qbItemsQuery, variables: {} })
    .then(({ data }) => dispatch(setQbItems(data.qbItems)))
    .catch(e => dispatch(MetaActions.errorToast(`An Error Occurred -   . (${e.message})`)));

export const fetchQbCompanyInfo = () => async dispatch =>
  ApolloClient.query({ query: qbCompanyInfoQuery })
    .then(({ data }) => dispatch(setQbCompanyInfo(data.companyInfo)))
    .catch(e => dispatch(MetaActions.errorToast(`An Error Occurred -   . (${e.message})`)));

export const sendQbInvoice = (qbId, invoiceId, sendTo) => async dispatch =>
  ApolloClient.query({ query: sendQbInvoicePdfQuery, variables: { qbId, invoiceId, sendTo } })
    .then(() =>
      dispatch(
        MetaActions.successToast(
          `Invoice ${invoiceId} successfully emailed to ${sendTo ? sendTo : "the client"}`,
        ),
      ),
    )
    .catch(e => dispatch(MetaActions.errorToast(`Failed send invoice  . (${e.message})`)));

export const getQbInvoice = invoiceId => async dispatch => {
  console.log("getQbInvoice", invoiceId);
  return ApolloClient.query({ query: getQbInvoicePdfQuery, variables: { invoiceId } })
    .then(() => {
      //   dispatch(setInvoicePdf(data.getQbInvoicePdf));
      //   dispatch(MetaActions.successToast(`Invoice ${invoiceId} successfully emailed to ${sendTo ? sendTo : "the client"})`));
    })
    .catch(e => dispatch(MetaActions.errorToast(`Failed to get invoice. (${e.message})`)));
};
export const fetchQbInvoice = invoiceId => async dispatch =>
  ApolloClient.query({ query: qbInvoiceQuery, variables: { invoiceId } })
    .then(({ data }) => {
      dispatch(setQbInvoice(data.qbInvoice));
    })
    .catch(e => dispatch(MetaActions.errorToast(`Failed fetch invoice  . (${e.message})`)));

//

export const createQbInvoice = (data, invoiceId) => async dispatch => {
  return ApolloClient.mutate({
    mutation: createQbInvoiceMutation,
    variables: { invoiceData: data, invoiceId },
  })
    .then(({ data }) => {
      data.createQbInvoice.invoice.CustomerRef &&
        dispatch(
          fetchEmployerQbInvoices(data.createQbInvoice.invoice.CustomerRef.value.toString()),
        );
      dispatch(MetaActions.successToast(`Invoice  successfully commited to Quickbooks}`));
      return data.createQbInvoice;
    })
    .catch(e => dispatch(MetaActions.errorToast(`Failed send invoice  . (${e.message})`)));
};

export const addInvoiceNote = (qbInvoiceId, value, note) => async dispatch => {
  return ApolloClient.mutate({
    mutation: AddInvoiceNoteMutation,
    variables: { qbInvoiceId, value, note },
  })
    .then(() => {
      dispatch(getQbAttachables(qbInvoiceId));
      dispatch(MetaActions.successToast(`Invoice note successfully added.`));
    })
    .catch(e => dispatch(MetaActions.errorToast(`Failed send invoice  . (${e.message})`)));
};

export const toggleAttachAttachmentToInvoice = (
  id,
  SyncToken,
  qbInvoiceId,
  value,
) => async dispatch => {
  return ApolloClient.mutate({
    mutation: toggleAttachAttachmentToInvoiceMutation,
    variables: { id, SyncToken, qbInvoiceId, value },
  })
    .then(() => {
      dispatch(getQbAttachables(qbInvoiceId));
      dispatch(
        MetaActions.successToast(
          `Attachment will ${
            !value ? "not " : ""
          } be attached when this invoice is sent to the employer.`,
        ),
      );
    })
    .catch(e => dispatch(MetaActions.errorToast(`Failed send invoice  . (${e.message})`)));
};

export const deleteQbInvoiceAttachment = (id, SyncToken, invoiceId) => async dispatch => {
  return ApolloClient.mutate({
    mutation: deleteQbInvoiceAttachmentMutation,
    variables: { id, SyncToken },
  })
    .then(() => {
      dispatch(getQbAttachables(invoiceId));
      dispatch(MetaActions.successToast(`Attachment successfully deleted.`));
    })
    .catch(e => dispatch(MetaActions.errorToast(`Failed send invoice  . (${e.message})`)));
};

export const deleteQbInvoice = (id, SyncToken, qbId) => async dispatch => {
  console.log("deleteQbInvoice", id, SyncToken, qbId);
  return ApolloClient.mutate({
    mutation: deleteQbInvoiceMutation,
    variables: { id, SyncToken },
  })
    .then(() => {
      dispatch(fetchEmployerQbInvoices(qbId.toString()));
      dispatch(MetaActions.successToast(`Invoice ${id} has been successfully deleted.`));
    })
    .catch(e => dispatch(MetaActions.errorToast(`Failed send invoice  . (${e.message})`)));
};

export const getQbAttachables = invoiceId => async dispatch =>
  ApolloClient.query({
    query: getQbAttachablesQuery,
    variables: { invoiceId: invoiceId.toString() },
  })
    .then(({ data }) => dispatch(setInvoiceAttachments(data.getQbAttachables)))
    .catch(e => dispatch(MetaActions.errorToast(`Failed to get attachables. (${e.message})`)));

export const commitInvoiceToQuickbooks = (employerId, invoiceId) => async dispatch =>
  ApolloClient.query({
    query: commitInvoiceToQuickbooksMutation,
    variables: { employerId, invoiceId },
  })
    .then(() => {
      dispatch(
        MetaActions.successToast(`Invoice ${invoiceId} successfully commited to Quickbooks`),
      );
    })
    .catch(e => dispatch(MetaActions.errorToast(`Failed send invoice  . (${e.message})`)));

export const commitTimesheetToQuickbooks = (invoiceId, qbInvoiceId, attach) => async dispatch => {
  return ApolloClient.query({
    query: createTimesheetQuery,
    variables: { invoiceId: Number(invoiceId), attach },
  })
    .then(() => {
      dispatch(getQbAttachables(qbInvoiceId));
      dispatch(
        MetaActions.successToast(
          `Timesheet for invoice ${qbInvoiceId} successfully commited to Quickbooks.`,
        ),
      );
    })
    .catch(e => dispatch(MetaActions.errorToast(`Failed send invoice  . (${e.message})`)));
};

export const findPayrollByPositionId = positionId => async dispatch =>
  ApolloClient.query({ query: findPayrollByPositionIdQuery, variables: { positionId } })
    .then(({ data }) => {
      dispatch(setInvoiceItemPayroll(data.findPayrollByPositionId));
      return data.findPayrollByPositionId;
    })
    .catch(e => dispatch(MetaActions.errorToast(`Failed send invoice  . (${e.message})`)));

//

export const reportCustomerBalanceDetail = () => async dispatch =>
  ApolloClient.query({
    query: reportCustomerBalanceDetailQuery,
    variables: { data: { date_macro: "This Fiscal Year", reportType: "reportCustomerSales" } },
  })
    .then(({ data }) => data.reportCustomerBalanceDetail)
    .catch(e => dispatch(MetaActions.errorToast(`Failed send invoice  . (${e.message})`)));

export const fetchEmployerQbInvoices = qbId => async dispatch =>
  ApolloClient.query({
    query: employerQbInvoicesQuery,
    variables: { qbId },
  })
    .then(({ data }) => {
      dispatch(setEmployerQbInvoices(data.qbInvoices));
      return data.qbInvoices;
    })
    .catch(e =>
      dispatch(
        MetaActions.errorToast(
          `Failed to fetch this employer's Quickbooks invoices. (${e.message})`,
        ),
      ),
    );

//
export const getQbInvoicePdf = invoiceId => async => {
  console.log("getQbInvoicePdf", invoiceId);

  //   ApolloClient.query({ query: getQbInvoicePdfQuery, variables: { invoiceId } })
  //     .then(({ data }) => {
  //       console.log(data);
  //       //   dispatch(setInvoicePdf(data.getQbInvoicePdf));
  //       //   dispatch(MetaActions.successToast(`Invoice ${invoiceId} successfully emailed to `));
  //     })
  //     .catch(e => dispatch(MetaActions.errorToast(`Failed send invoice  . (${e.message})`)));
};
