import React from "react";
import PropTypes from "prop-types";
import Fade from "@mui/material/Fade";

class FadeHide extends React.PureComponent {
    componentWillUnmount() {
        clearTimeout(this.exitTimeout);
    }

    onExit = el => {
        const duration = this.props.exit - this.props.exit * 0.2;
        this.exitTimeout = setTimeout(() => (el.style.display = "none"), duration);
    };

    onEnter = el => {
        el.style.display = this.props.displayType;
    };

    render() {
        const { children, in: inProp, enter, exit } = this.props;
        return (
            <Fade in={inProp} timeout={{ enter, exit }} onExit={this.onExit} onEnter={this.onEnter}>
                {children}
            </Fade>
        );
    }
}

FadeHide.propTypes = {
    children: PropTypes.node.isRequired,
    in: PropTypes.bool.isRequired,
    displayType: PropTypes.string,
    enter: PropTypes.number,
    exit: PropTypes.number,
};

FadeHide.defaultProps = {
    displayType: "block",
    enter: 225,
    exit: 195,
};

export default FadeHide;
