import React from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { JobStatusesMap } from "helpers/constants/index";
import { selectors as JobDetailSelectors } from "store/modules/jobDetails";

import CompletedTable from "./tables/CompletedTable";
import DraftTable from "./tables/DraftTable";
import InProgressTable from "./tables/InProgressTable";
import NotStartedTable from "./tables/NotStartedTable";
import RatingAllTable from "./tables/RatingAllTable";

function PositionTable(props) {
  const isActive = useSelector(JobDetailSelectors.getIsActive);
  const status = useSelector(JobDetailSelectors.getJobStatus);
  const isMissingShiftInfo = useSelector(JobDetailSelectors.getIsMissingShiftInfo);

  if (props.isRatingAll) {
    return <RatingAllTable {...props} />;
  }

  if (!isActive) {
    return <DraftTable {...props} />;
  }

  if (status === JobStatusesMap.NotStarted) {
    return <NotStartedTable {...props} />;
  }

  if (status === JobStatusesMap.InProgress && isMissingShiftInfo) {
    return <InProgressTable {...props} />;
  }

  if (
    (status === JobStatusesMap.InProgress && !isMissingShiftInfo) ||
    status === JobStatusesMap.Completed
  ) {
    return <CompletedTable {...props} />;
  }

  return null;
}

PositionTable.propTypes = {
  onChooseWorker: PropTypes.func.isRequired,
  isWorkerPickerOpen: PropTypes.bool.isRequired,
  isRatingAll: PropTypes.bool.isRequired,
  isCheckingInAll: PropTypes.bool.isRequired,
  isCheckingOutAll: PropTypes.bool.isRequired,
  isEditingRatesAll: PropTypes.bool.isRequired,
};

export default PositionTable;
