import React, { useState } from "react";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import moment from "moment-timezone";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import makeStyles from '@mui/styles/makeStyles';
import Tooltip from "@mui/material/Tooltip";
import Cancel from "@mui/icons-material/Cancel";
import CheckCircle from "@mui/icons-material/CheckCircle";
import MoreHoriz from "@mui/icons-material/MoreHoriz";
import { actions as JobDetailActions } from "store/modules/jobDetails";
import { reconfirmWorker } from "../../../../../../../../../store/modules/jobDetails/actions";

const useStyles = makeStyles(({ spacing, typography }) => ({
    icon: {
        fontSize: typography.pxToRem(20),
        marginRight: spacing(2),
    },
}));

function ConfirmMenu({ workerId, isConfirmed, isReconfirmed, confirmedAt }) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [anchorEl, setAnchorEl] = useState(null);

    const menuId = `confirm-action-menu-${workerId}`;

    const openMenu = e => {
        setAnchorEl(e.currentTarget);
    };

    const closeMenu = () => {
        setAnchorEl(null);
    };

    const handleConfirm = confirmation => () => {
        if(isReconfirmed){
            dispatch(JobDetailActions.reconfirmWorker(workerId, confirmation));
        } else {
            dispatch(JobDetailActions.confirmWorker(workerId, confirmation));
        }
        closeMenu();
    };

    let buttonIcon = <MoreHoriz color="action" />;
    if (isConfirmed === true) {
        buttonIcon = (
            <Tooltip title={moment(confirmedAt).format("MMM Do h:mm a")} placement="top">
                <CheckCircle style={{ color: "#11b2a8" }} />
            </Tooltip>
        );
    } else if (isConfirmed === false) {
        buttonIcon = (
            <Tooltip title={moment(confirmedAt).format("MMM Do h:mm a")} placement="top">
                <Cancel style={{ color: "#ef504e" }} />
            </Tooltip>
        );
    }

    return <>
        <IconButton
            aria-controls={menuId}
            aria-haspopup="true"
            onClick={openMenu}
            disableRipple
            size="large">
            {buttonIcon}
        </IconButton>
        <Menu
            id={menuId}
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={closeMenu}
        >
            <MenuItem onClick={handleConfirm(true)}>
                <CheckCircle className={classes.icon} color="action" />
                Going
            </MenuItem>
            <MenuItem onClick={handleConfirm(false)}>
                <Cancel className={classes.icon} color="action" />
                Not Going
            </MenuItem>
        </Menu>
    </>;
}

ConfirmMenu.propTypes = {
    workerId: PropTypes.string.isRequired,
    isConfirmed: PropTypes.bool,
    confirmedAt: PropTypes.string,
};

ConfirmMenu.defaultProps = {
    isConfirmed: null,
    confirmedAt: null,
};

export default ConfirmMenu;
